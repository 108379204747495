///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';
import {StaticImageController} from '../../helper/StaticImageController';

export class GridComponent extends AbstractBlockComponent {

    private _grid:any;
    private _onCenterDistanceUpdateHandler:()=>void;

    get grid():any{
        return this._grid;
    }

    protected onInit():void{
        this._params = _.defaults(this._params, {
            class: "grid_item",
            contentclass: "grid_content",
            // align: "top",
            widthPtAs: 0.1,
            heightPtAs: 0.1,
            loadImages: false,
            silent:false,
            toggle:"visibility"
        });

        // if(RED.Platform.get().isMobile){
        //     this._params.toggle = "visibility";
        // }

        //console.log("Grid", this._params);

        const loadBackgroundImage = (domElement:HTMLElement, asyncImageId:number, height:number, baseUrl:string = '') => {
            return StaticImageController.getImage(asyncImageId, height, baseUrl).then((asyncImageData)=>{
                let asyncImage = new Image();
                asyncImage.addEventListener('load',()=>{
                    domElement.style.backgroundImage = `url('${asyncImageData.url}')`;
                });
                asyncImage.src = asyncImageData.url;
            });
        }

        this._grid = new Gridifier( this._block.domElement.querySelector('.'+this._params.contentclass), this._params);


        this._onCenterDistanceUpdateHandler = (distance:number = 0) => {
            if(this._params.silent === true){
                let silentItems = this._grid.getSilent(true) as Array<HTMLElement>;

                if(silentItems.length > 0){
                    _.each(silentItems, (silentItem) => {
                        let asyncImageHolder = silentItem.querySelector('[data-asyncimage]') as HTMLElement;
                        if(asyncImageHolder){
                            const asyncImageId = parseInt(asyncImageHolder.getAttribute("data-asyncimage"));
                            const asyncImageBaseUrl = asyncImageHolder.getAttribute("data-asyncimagebase") || '';
                            const applyImage = () => {
                                let requestHeight = Math.min(asyncImageHolder.getBoundingClientRect().height,512);
                                if(requestHeight> 100){
                                    loadBackgroundImage(asyncImageHolder, asyncImageId, requestHeight, asyncImageBaseUrl);
                                }

                                asyncImageHolder.parentElement.removeEventListener("transitionend", applyImage);
                            }
                            asyncImageHolder.parentElement.addEventListener("transitionend", applyImage);
                        }
                    });

                    this._grid.silentRender(silentItems,1,150);
                }
            }
        }
        this._block.onCenterDistanceUpdate.subscribe(this._onCenterDistanceUpdateHandler);


        let insertCheckInterval = setInterval(()=>{
            if(this._grid.all().length > 0) {
                clearInterval(insertCheckInterval);
                this.state = ComponentState.READY;
            }
        },100);

        // //Memory leak :(
        // this._grid.onInsert((items) => {
        //
        // });

        // this._grid.onGridResize(function() {
        //     console.log("onGridResize");
        // });




        if(this._params.silent === true){
            this._grid.silentAppend(this._grid.collectNew());
        }
        else {
            this._grid.append(this._grid.collectNew());
        }

    }

    public appear():void{
        this._onCenterDistanceUpdateHandler();
    }

    protected onDestroy():void{
        this._block.onCenterDistanceUpdate.unsubscribe(this._onCenterDistanceUpdateHandler);
        this._grid.destroy();

        this.state = ComponentState.DESTROYED;
    }
}