///<reference path="../js_bindings.d.ts"/>

export class Sidebar{
    private _onOpenStateChange: SimpleEventDispatcher<boolean> = new SimpleEventDispatcher();

    get onOpenStateChange(): ISimpleEvent<boolean> {
        return this._onOpenStateChange.asEvent();
    }

    private _sidebar:any;
    private _isOpen:boolean = false;

    get isOpen():boolean {
        return this._isOpen;
    }

    set isOpen(value:boolean) {
        if(this._isOpen != value){
            this._isOpen = value;

            if(this._isOpen){
                this._sidebar.classList.add('opened');
            }
            else{
                this._sidebar.classList.remove('opened');
            }

            this._onOpenStateChange.dispatch(this._isOpen );
        }
    }

    constructor(){
        this._sidebar = document.querySelector('.sidebar');
        this.isOpen = this._sidebar.classList.contains('opened');
    }

    public toggle():void{
        this.isOpen = !this.isOpen;
    }
}



