///<reference path="../js_bindings.d.ts"/>

export interface StaticValue{
    key:string;
    type:string;
    value:string;
}

export class StaticValueController  {
    public static getValues(keys:Array<string>):Promise<Array<StaticValue>>
    {
        return new Promise<Array<StaticValue>>((resolve, reject) => {
            jQuery.ajax({
                url: "staticvalue",
                type: 'post',
                data: {
                    keys: keys
                },
                dataType: 'json',
                async: true,
                success: (data, status, xhr) => {
                    // console.log("StaticValueController",data);
                    let result = [];
                    _.each(data.values,(value:StaticValue)=>{
                        result.push(value);
                    });

                    result = _.sortBy(result, [(value)=>{return value.key;}]);

                    resolve(result);
                },
                error: (xhr, status, error) => {
                    reject(error);
                }
            });
        });
    }

    public static getValue(key:string,values:Array<StaticValue>):StaticValue{
        return _.find(values,(value:StaticValue)=>{
            return value.key == key;
        }) || {
            type:"text",
            key:key,
            value:"Missing value"
        };
    }
}
