/**
 * Line.ts: Generic Line code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Line as THREELine } from "../../lib/threejs/objects/Line";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { InstancedBufferGeometry } from "../../lib/threejs/core/InstancedBufferGeometry";
import { InstancedBufferAttribute } from "../../lib/threejs/core/InstancedBufferAttribute";
import { BufferGeometry } from "../../lib/threejs/core/BufferGeometry";
import { Box3 } from "../../lib/threejs/math/Box3";
import { Sphere } from "../../lib/threejs/math/Sphere";
import { MaterialLibrary, MaterialLibSettings } from "../framework/MaterialLibrary";
import { RedMaterial, MaterialTemplate, MaterialTemplateNamed } from "../render/Material";
import { MaterialRef } from "../render/Geometry";
import { GraphicsDisposeSetup } from "../core/Globals";
import { ShaderLibrary } from "../render/ShaderLibrary";
import { Render } from "../render/Render";
import { ShaderVariant, clearShaderState, clearFixedFunctionState, variantIsShadow } from "../render/Shader";
import { RenderState } from "../render/State";
import { IRenderSystem, queryRenderSystem } from "../framework/RenderAPI";
import { hash } from "../core/Hash";

// BUILTIN SHADER (auto include)
import "../render-line/LineShader";

/** shader callbacks */
function Line_onBeforeRender(this:Line, renderer:any, scene:any, camera:any, geometry:any, material:any, group:any) {
    if(self && material.__redShader && material.__redShader.onPreRender) {
        material.__redShader.onPreRender(renderer.redRender, camera, material, this, this.redMaterial || {});
    } else {
        clearShaderState();
        clearFixedFunctionState(renderer.redRender);
    }
}

/** shader callbacks */
function Line_onAfterRender(this:Line, renderer:any, scene:any, camera:any, geometry:any, material:any, group:any) {
    if(material.__redShader && material.__redShader.onPostRender) {
        material.__redShader.onPostRender(renderer.redRender, camera, material, this);
    }
}

/**
 * custom red three.js mesh implementation
 */
export class Line extends THREELine {

    public isRedMesh:boolean = true;
    public isRedLine:boolean = true;

    /** name id */
    public get nameId() {
        if(this._nameId === undefined) {
            this._nameId = hash(this.name);
        }
        return this._nameId;
    }

    /** world reference */
    public get renderSystem() : IRenderSystem {
        return queryRenderSystem();
    }

    public get materialRef() : MaterialRef {
        return this._materialRef;
    }

    public get positionWorld() {
        if(!this._worldPosTemp) {
            this._worldPosTemp = new Vector3();
        }
        // make sure world matrix is correct
        this._worldPosTemp.setFromMatrixPosition(this.matrixWorld);
        return this._worldPosTemp;
    }

    public get localBounds() {
        if(!this.geometry.boundingBox) {
            this.buildLocalBounds();
        }
        return this.geometry.boundingBox;
    }

    /** current material variant */
    public get shaderVariant() {
        return this.redShaderVariant || ShaderVariant.DEFAULT;
    }
    /** set explicit material variant */
    public set shaderVariant(value:ShaderVariant) {
        if(this.redShaderVariant !== value) {
            this.redShaderVariant = value;
            this.setMaterialTemplate(this.redMaterial, true);
        }
    }

    /** get mesh shader variant code */
    public get meshVariant() : ShaderVariant {
        // activate instancing on instanced buffer geometry
        if(this.geometry instanceof InstancedBufferGeometry) {
            return ShaderVariant.INSTANCED;
        }
        return ShaderVariant.DEFAULT;
    }

    /** material template */
    // public set redMaterial(value:MaterialTemplate) {
    //     if(this._redMaterial !== value) {
    //         //TODO: update material ref?!
    //         this._setMaterialTemplate(value);
    //     }
    // }
    public get redMaterial() {
        return this._redMaterial;
    }

    /** resolved material name */
    public get materialName() : string {
        if(MaterialLibrary.isGroup(this._materialRef.ref)) {
            return MaterialLibrary.getCurrentGroupMaterialName(this._materialRef.ref);
        } else {
            return this._materialRef.ref;
        }
    }

    /** override material variant (force) */
    public redShaderVariant:ShaderVariant;

    /** name id (hashed) */
    private _nameId:number;

    /** material template */
    private _redMaterial:MaterialTemplate;

    /** internal material ref */
    private _materialRef:MaterialRef;

    /** internal render id */
    private _renderId: number;

    /** temporary */
    private _worldPosTemp:Vector3;
    private _tmpVisibleState:boolean;

    constructor(geometry:any, material:string|RedMaterial|MaterialTemplate, defaultVariant?:ShaderVariant) {
        // give him the wrong material stuff or add some debug default material?
        super(geometry, material as any);
        this.redShaderVariant = defaultVariant || undefined;
        this._tmpVisibleState = undefined;
        this._worldPosTemp = new Vector3();
        this._nameId = undefined;
        this.onBeforeRender = Line_onBeforeRender;
        this.onAfterRender = Line_onAfterRender;
        // setup material
        this._materialRef = { name: null, ref: null };
        let name = "default_line";
        let template:MaterialTemplate = null;
        if(material && typeof material === "string") {
            name = material;
            template = MaterialLibrary.findMaterialByName(material);
        } else if(material && material instanceof RedMaterial) {
            template = null;
            name = "instance";
        } else if(material) {
            template = material as MaterialTemplate;
            name = template.name || "unknown_material_template";
        }

        // default material ref
        this._materialRef = this._materialRef || { name: null, ref: null };
        this._materialRef.name = this._materialRef.name || name;
        this._materialRef.ref = this._materialRef.ref || this._materialRef.name;

        //TODO: material switch
        if(template) {
            this.setMaterialTemplate(template);
        } else {
            this.setMaterialTemplate(MaterialLibSettings.defaultDebugMaterial);
        }

        MaterialLibrary.OnMaterialChanged.on(this._materialChanged);

        // link to render system
        this._renderId = this.renderSystem.registerCallback(this.prepareRendering, null, null);
    }

    /**
     * cleaning
     * @param dispose graphics dispose setup
     */
    public destroy(dispose?:GraphicsDisposeSetup) {
        // remove from render system
        this.renderSystem.removeCallback(this._renderId);
        // remove events
        MaterialLibrary.OnMaterialChanged.off(this._materialChanged);
        // remove self from hierarchy
        if(this.parent) {
            this.parent.remove(this);
        }
        // dispose data
        if(dispose && !dispose.noGeometry) {
            this.geometry.dispose();
        }
        this.geometry = null;
        this.material = null;
        this.onBeforeRender = function() {};
        this.onAfterRender = function() {};
    }

    /**
     * callback function for preparing rendering
     * @param render renderer
     * @param scene scene instance (FIXME: replace with world?!)
     * @param camera camera instance
     * @param pipeState pipeline render state
     */
    public prepareRendering = (render:Render, scene:any, camera:any, pipeState:RenderState) => {
        if(camera.isRedCamera) {
            // restore visible state
            if(this._tmpVisibleState !== undefined) {
                this.visible = this._tmpVisibleState;
                this._tmpVisibleState = undefined;
            }

            const lastShader = this.material;

            // resolve to new shader
            if(pipeState.overrideShaderVariant) {
                // shadow casting
                if(variantIsShadow(pipeState.overrideShaderVariant)) {
                    // use shader name for now -> new API
                    this.material = this.castShadow ? ShaderLibrary.findOrCreateShader(this.redMaterial.shader, this, pipeState.overrideShaderVariant | this.meshVariant) : null;
                } else {

                    // use shader name for now -> new API
                    this.material = ShaderLibrary.findOrCreateShader(this.redMaterial.shader, this, pipeState.overrideShaderVariant | this.meshVariant);
                }
            } else {
                // reset to original shader
                // use shader name for now -> new API
                this.material = ShaderLibrary.findOrCreateShader(this.redMaterial.shader, this, this.shaderVariant | this.meshVariant);
            }

            // cannot fulfill wish here
            if(!this.material) {
                //console.warn("missing right shader....");
                this.material = lastShader;
                // hide object for rendering (and remember last state)
                this._tmpVisibleState = this.visible;
                this.visible = false;
            }
        }
    }

    /**
     * build a material connection
     * @param ref new material reference or array of refs
     */
    public setMaterialRef(reference:MaterialRef|MaterialRef[]) {
        if(Array.isArray(reference)) {
            for(const ref of reference) {
                this.setMaterialRef(ref);
            }
        } else {
            if(this.materialRef.name !== reference.name) {
                console.warn("Line: cannot apply materialRef, name does not match");
                return;
            }

            // apply material ref
            this.materialRef.ref = reference.ref || this.materialRef.ref || this.materialRef.name;

            const nameIsGroup = MaterialLibrary.isGroup(this.materialRef.name);
            const refIsGroup = MaterialLibrary.isGroup(this.materialRef.ref);

            // update template (no material groups)
            if(!nameIsGroup && !refIsGroup) {
                // material reference could be a material group so this returns the current template
                // or the concrete template if material ref is not a group
                const template = MaterialLibrary.findMaterialByName(this.materialRef.ref, this.name);
                // apply but do not change material ref setup
                this._setMaterialTemplate(template);
            } else {
                const ref = nameIsGroup ? this.materialRef.name : this.materialRef.ref;
                // get current template and directly apply
                const template = MaterialLibrary.getCurrentGroupMaterial(ref);
                // apply but do not change material ref setup
                this._setMaterialTemplate(template);

            }
        }
    }

    /**
     * setup material through material template
     * @param material template
     * @param force
     */
    public setMaterialTemplate(material:MaterialTemplate|string, force?:boolean) {
        // resolve template
        let materialName:string;
        if(typeof material === "string") {
            materialName = material;
            //FIXME: check groups?!
            material = MaterialLibrary.findMaterialByName(material, this.name);
        } else {
            // material.name should be undefined...
            materialName = material.name;
        }

        this._setMaterialTemplate(material, force);

        // update material reference
        if(materialName && this.materialRef.ref !== materialName) {
            this.materialRef.ref = materialName;
        }
    }

    /**
     * this functions assumes that model has been converted to use
     * instancing
     * @param count new count (must be smaller than buffer size)
     */
    public setInstanceCount(count:number) {
        let applyable = false;

        const instanceGeometry = this.geometry;
        if(instanceGeometry instanceof InstancedBufferGeometry || instanceGeometry['isInstancedBufferGeometry']) {
            applyable = true;
        }

        console.assert(applyable, "Model: not instancing geometry applied");
        let currentBufferSize = 0;

        if(this.geometry instanceof InstancedBufferGeometry || this.geometry['isInstancedBufferGeometry']) {
            const tmpGeometry = this.geometry as InstancedBufferGeometry;
            // find all attributes that are using instanced ones
            for(const attrKey in tmpGeometry.attributes) {
                if(tmpGeometry.attributes[attrKey] instanceof InstancedBufferAttribute || tmpGeometry.attributes[attrKey]['isInstancedBufferAttribute']) {
                    currentBufferSize = Math.min(currentBufferSize, tmpGeometry.attributes[attrKey].count);
                }
            }
            if(currentBufferSize > count) {
                console.error("Model: current instancing size smaller than count to render");
                return;
            }
            tmpGeometry.maxInstancedCount = count;
        }

    }

    /**
     * update local bounds
     * @param forceUpdate force a new rebuilt
     */
    public buildLocalBounds(forceUpdate?:boolean) {
        const meshGeometry = this.geometry as BufferGeometry;
        console.assert(meshGeometry.attributes, "Mesh: not a valid geometry buffer object");

        // make sure bounding box is already calculated
        // based on local vertices
        if(!meshGeometry.boundingBox || forceUpdate) {
            //FIXME: reset?
            if(forceUpdate) {
                meshGeometry.boundingBox = null;
                meshGeometry.boundingSphere = null;
            }

            meshGeometry.boundingBox = new Box3();
            meshGeometry.boundingSphere = new Sphere();

            //TODO: custom code (not ignoring draw range)
            const position = meshGeometry.attributes['position'];

            if(position !== undefined) {

                let minX = + Infinity;
                let minY = + Infinity;
                let minZ = + Infinity;

                let maxX = - Infinity;
                let maxY = - Infinity;
                let maxZ = - Infinity;

                if(meshGeometry.index) {
                    const indices = meshGeometry.index;

                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, indices.count) + start;
                    const itemSize = position.itemSize;
                    const indexSize = indices.itemSize;

                    for(let i = start; i < end; ++i) {
                        const index = indices.array[i*indexSize];

                        const x = position.array[index * itemSize];
                        const y = position.array[index * itemSize+1];
                        const z = position.array[index * itemSize+2];

                        if ( x < minX ) { minX = x; }
                        if ( y < minY ) { minY = y; }
                        if ( z < minZ ) { minZ = z; }

                        if ( x > maxX ) { maxX = x; }
                        if ( y > maxY ) { maxY = y; }
                        if ( z > maxZ ) { maxZ = z; }

                    }
                } else {
                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, position.count) + start;
                    const itemSize = position.itemSize;

                    for(let i = start; i < end; ++i) {

                        const x = position.array[i * itemSize];
                        const y = position.array[i * itemSize+1];
                        const z = position.array[i * itemSize+2];

                        if ( x < minX ) { minX = x; }
                        if ( y < minY ) { minY = y; }
                        if ( z < minZ ) { minZ = z; }

                        if ( x > maxX ) { maxX = x; }
                        if ( y > maxY ) { maxY = y; }
                        if ( z > maxZ ) { maxZ = z; }
                    }
                }

                meshGeometry.boundingBox.min.set( minX, minY, minZ );
                meshGeometry.boundingBox.max.set( maxX, maxY, maxZ );

                meshGeometry.boundingBox.getBoundingSphere(meshGeometry.boundingSphere);
            } else {
                meshGeometry.boundingBox.makeEmpty();
            }
        }
    }

    /**
     * raycast against triangles in local space
     * @param raycaster local space ray
     */
    public rayCastLocal(raycaster:any) {
        // reset to identity matrix (so this is in local space)
        const tmpWorldMatrix = this.matrixWorld.clone();
        // FIXME: use this.matrix
        //this.matrixWorld.identity();
        this.matrixWorld.copy(this.matrix);

        const result = [];
        raycaster.intersectObject(this, false, result);

        // restore matrix
        this.matrixWorld.copy(tmpWorldMatrix);
        return result;
    }

    private _setMaterialTemplate(material:MaterialTemplate, force?:boolean) {
        if(!material) {
            //FIXME?!
            this._redMaterial = null;
            return;
        }

        // no change (force must be set when internal values have changed)
        if(!force && this._redMaterial && this._redMaterial.name === material.name) {
            return;
        }

        // material switch
        this._redMaterial = material;

        // shader switch
        if(this.material['shaderType'] !== material.shader) {
            // use shader name for now -> new API
            this.material = ShaderLibrary.findRuntimeShader(material.shader, this, this.redShaderVariant);

            if(!this.material) {
                this.material = ShaderLibrary.createShader(material.shader, this, this.redShaderVariant);
            }

            // fallback to a debug shader
            if(!this.material) {
                this.material = ShaderLibrary.createShader(ShaderLibrary.DefaultShader, this, ShaderVariant.DEFAULT, true);
            }
        }

        //deactivate shadows for transparent objects
        if(material.transparent) {
            this.receiveShadow = false;
            //TODO: support this for better visual quality
            this.castShadow = false;
        }

        // shadow setup
        if(material.forceCastShadow !== undefined) {
            this.castShadow = material.forceCastShadow;
        }

        if(material.forceReceiveShadow !== undefined) {
            this.receiveShadow = material.forceReceiveShadow;
        }
    }

    /** a runtime material has been changed */
    private _materialChanged = (material:MaterialTemplateNamed, mesh:number|undefined) => {
        if(!material || !this.redMaterial) {
            return;
        }
        // not for this line
        if(mesh && mesh !== this.nameId) {
            return;
        }
        //FIXME: use materialRef?!
        if(this.redMaterial.name === material.name) {
            // apply
            this.setMaterialTemplate(material.template, true);
        }
    }

}
