/**
 * Model.ts: Generic Model/Mesh code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { InstancedBufferGeometry } from "../../lib/threejs/core/InstancedBufferGeometry";
import { InstancedBufferAttribute } from "../../lib/threejs/core/InstancedBufferAttribute";
import { Box3 } from "../../lib/threejs/math/Box3";
import { BufferGeometry } from "../../lib/threejs/core/BufferGeometry";
import { Sphere } from "../../lib/threejs/math/Sphere";
import { BufferAttribute } from "../../lib/threejs/core/BufferAttribute";
import { ShaderMaterial } from "../../lib/threejs/materials/ShaderMaterial";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { Triangle } from "../../lib/threejs/math/Triangle";
import { Intersection } from "../../lib/threejs/core/Raycaster";
import { Face3 } from "../../lib/threejs/core/Face3";
import { MaterialLibrary, MaterialLibSettings } from "../framework/MaterialLibrary";
import { RedMaterial, MaterialTemplate, MaterialTemplateNamed } from "./Material";
import { MaterialRef, InstanceBufferRef } from "./Geometry";
import { GraphicsDisposeSetup } from "../core/Globals";
import { ShaderLibrary } from "./ShaderLibrary";
import { Render } from "./Render";
import { ShaderVariant, clearShaderState, clearFixedFunctionState, Shader, variantIsShadow } from "./Shader";
import { RenderState } from "./State";
import { tick } from "../framework/Tick";
import { RENDER_ORDER_USER_MASK, RENDER_ORDER_SHADER_BITS, RENDER_ORDER_SHADER_MASK, RENDER_ORDER_MATERIAL_BITS, RENDER_ORDER_MATERIAL_MASK } from "./Layers";
import { hash } from "../core/Hash";
import { IRenderSystem, queryRenderSystem } from "../framework/RenderAPI";
import { math } from "../core/Math";

/** shader callbacks */
function Mesh_onBeforeRender(this:Mesh, renderer:any, scene:any, camera:any, geometry:any, material:any, group:any) {
    if(self && material.__redShader && material.__redShader.onPreRender) {
        material.__redShader.onPreRender(renderer.redRender, camera, material, this, this.redMaterial || {});
    } else {
        clearShaderState();
        clearFixedFunctionState(renderer.redRender);
    }
}

/** shader callbacks */
function Mesh_onAfterRender(this:Mesh, renderer:any, scene:any, camera:any, geometry:any, material:any, group:any) {
    if(material.__redShader && material.__redShader.onPostRender) {
        material.__redShader.onPostRender(renderer.redRender, camera, material, this);
    }
}

/**
 * custom red three.js mesh implementation
 * FIXME: add multi material support?!
 */
export class Mesh extends THREEMesh {
    public static RenderStateDirty:number = -1;

    public isRedMesh:boolean = true;
    public static isMesh(obj: any): obj is Mesh {
        return obj.isRedMesh;
    }

    /** name id */
    public get nameId() {
        if(this._nameId === undefined) {
            this._nameId = hash(this.name);
        }
        return this._nameId;
    }

    /** world reference */
    public get renderSystem() : IRenderSystem {
        return queryRenderSystem();
    }

    public get localBounds() {
        if(!this.geometry.boundingBox) {
            this.buildLocalBounds();
        }
        return this.geometry.boundingBox;
    }

    public get materialRef() : MaterialRef {
        return this._materialRef;
    }

    public get positionWorld() {
        if(!this._worldPosTemp) {
            this._worldPosTemp = new Vector3();
        }
        // make sure world matrix is correct
        this._worldPosTemp.setFromMatrixPosition(this.matrixWorld);
        return this._worldPosTemp;
    }

    /** current material variant */
    public get shaderVariant() {
        return this.redShaderVariant || ShaderVariant.DEFAULT;
    }
    /** set explicit material variant */
    public set shaderVariant(value:ShaderVariant) {
        if(this.redShaderVariant !== value) {
            this.redShaderVariant = value;
            this.setMaterialTemplate(this.redMaterial, true);
        }
    }

    /** get mesh shader variant code */
    public get meshVariant() : ShaderVariant {
        // activate instancing on instanced buffer geometry
        if(this.geometry instanceof InstancedBufferGeometry) {
            return ShaderVariant.INSTANCED;
        }
        return ShaderVariant.DEFAULT;
    }

    /** visible state */
    public get visible() {
        return this._visible;
    }
    public set visible(value:boolean) {
        if(this._visible !== value) {
            this._visible = value;
            if(this._renderId) {
                if(this._visible) {
                    this.renderSystem.activate(this._renderId);
                } else {
                    this.renderSystem.deactivate(this._renderId);
                }
            }
            Mesh.RenderStateDirty = tick.frameCount;
        }
    }

    /** render order setup */
    public get renderOrder() {
        return this._renderOrder;
    }
    public set renderOrder(value:number) {
        this._generateRenderOrder(value);
    }
    public get customRenderOrder() {
        return this._renderOrder & RENDER_ORDER_USER_MASK;
    }

    /** material template */
    // public set redMaterial(value:MaterialTemplate) {
    //     if(this._redMaterial !== value) {
    //         //TODO: update material ref?!
    //         this._setMaterialTemplate(value);
    //     }
    // }
    public get redMaterial() {
        return this._redMaterial;
    }

    /** resolved material name */
    public get materialName() : string {
        if(MaterialLibrary.isGroup(this._materialRef.ref)) {
            return MaterialLibrary.getCurrentGroupMaterialName(this._materialRef.ref, this.name);
        } else {
            return this._materialRef.ref;
        }
    }

    /** override material variant (force) */
    public redShaderVariant:ShaderVariant;

    /** name id (hashed) */
    private _nameId:number;

    /** material template */
    private _redMaterial:MaterialTemplate;

    /** material to reference */
    private _materialRef:MaterialRef;

    /** visible state */
    private _visible:boolean;
    /** internal render order */
    private _renderOrder: number;
    /** internal render id */
    private _renderId: number;

    /** temporary */
    private _worldPosTemp:Vector3;
    private _tmpVisibleState:boolean;

    /** construction */
    constructor(geometry:any, material:MaterialTemplate|RedMaterial|string|MaterialRef, defaultVariant?:ShaderVariant) {
        // give him the wrong material stuff or add some debug default material?
        super(geometry, material as any);
        this._visible = true;
        this.redShaderVariant = defaultVariant || undefined;
        this._worldPosTemp = new Vector3();
        this._tmpVisibleState = undefined;
        this.renderOrder = 0;
        this._nameId = undefined;

        this._materialRef = { name: null, ref: null };
        this.onBeforeRender = Mesh_onBeforeRender;
        this.onAfterRender = Mesh_onAfterRender;

        // resolve material input
        let name = "default_mesh";
        let template:MaterialTemplate = null;
        if(material && typeof material === "string") {
            name = material;
            template = MaterialLibrary.findMaterialByName(material);
        } else if(material && material instanceof RedMaterial) {
            template = null;
            name = "instance";
        } else if(material && material['ref'] !== undefined && material['name'] !== undefined) {
            name = material['name'];
            this._materialRef.name = material['name'];
            this._materialRef.ref = material['ref'] || material['name'];
            template = MaterialLibrary.findMaterialByName(this._materialRef.ref);
        } else if(material) {
            template = material as MaterialTemplate;
            name = template.name || "unknown_material_template";
        }

        // default material ref
        this._materialRef = this._materialRef || { name: null, ref: null };
        this._materialRef.name = this._materialRef.name || name;
        this._materialRef.ref = this._materialRef.ref || this._materialRef.name;

        // material switch
        if(template) {
            this.setMaterialTemplate(template);
        } else {
            this.setMaterialTemplate(MaterialLibSettings.defaultDebugMaterial);
        }

        // link to material system
        MaterialLibrary.OnMaterialChanged.on(this._materialChanged);

        // link to render system
        this._renderId = this.renderSystem.registerCallback(this.prepareRendering,null, null, this.name || "Mesh");

        // rebuilt local boundings from geometry
        this.buildLocalBounds();
    }

    /**
     * cleaning
     * @param dispose graphics dispose setup
     */
    public destroy(dispose?:GraphicsDisposeSetup) {
        // remove from render system
        this.renderSystem.removeCallback(this._renderId);

        // remove events
        MaterialLibrary.OnMaterialChanged.off(this._materialChanged);
        // remove self from hierarchy
        if(this.parent) {
            this.parent.remove(this);
        }
        // dispose data
        if(dispose && !dispose.noGeometry) {
            this.geometry.dispose();
        }
        this.geometry = null;
        this.material = null;
        this.onBeforeRender = function() {};
        this.onAfterRender = function() {};
    }

    /**
     * callback function for preparing rendering
     * @param render renderer
     * @param scene scene instance (FIXME: replace with world?!)
     * @param camera camera instance
     * @param pipeState pipeline render state
     */
    public prepareRendering = (render:Render, scene:any, camera:any, pipeState:RenderState) => {
        if(camera.isRedCamera) {
            // restore visible state
            if(this._tmpVisibleState !== undefined) {
                this._visible = this._tmpVisibleState;
                this._tmpVisibleState = undefined;
            }

            const lastShader = this.material;

            // resolve to new shader
            if(pipeState.overrideShaderVariant) {
                // shadow casting
                if(variantIsShadow(pipeState.overrideShaderVariant)) {
                    // use shader name for now -> new API
                    this.material = this.castShadow ? ShaderLibrary.findOrCreateShader(this.redMaterial.shader, this, pipeState.overrideShaderVariant | this.meshVariant) : null;
                } else {

                    // use shader name for now -> new API
                    this.material = ShaderLibrary.findOrCreateShader(this.redMaterial.shader, this, pipeState.overrideShaderVariant | this.meshVariant);
                }
            } else {
                // FIX ME: TMP FIX
                if(!this._redMaterial) {
                    this._redMaterial = MaterialLibrary.findMaterialByName(MaterialLibSettings.defaultDebugMaterial);
                }

                // reset to original shader
                // use shader name for now -> new API
                this.material = ShaderLibrary.findOrCreateShader(this.redMaterial.shader, this, this.shaderVariant | this.meshVariant);
            }

            // cannot fulfill wish here
            if(!this.material) {
                //console.warn("missing right shader....");
                this.material = lastShader;
                // hide object for rendering (and remember last state)
                this._tmpVisibleState = this._visible;
                this._visible = false;
            }
        }
    }

    /**
     * build a material connection
     * @param ref new material reference or array of refs
     */
    public setMaterialRef(reference:MaterialRef|MaterialRef[]) {
        if(Array.isArray(reference)) {
            for(const ref of reference) {
                this.setMaterialRef(ref);
            }
        } else {
            if(this.materialRef.name !== reference.name) {
                console.warn("Mesh: cannot apply materialRef, name does not match");
                return;
            }

            // apply material ref
            this.materialRef.ref = reference.ref || this.materialRef.ref || this.materialRef.name;

            const nameIsGroup = MaterialLibrary.isGroup(this.materialRef.name);
            const refIsGroup = MaterialLibrary.isGroup(this.materialRef.ref);

            // update template (no material groups)
            if(!nameIsGroup && !refIsGroup) {
                // material reference could be a material group so this returns the current template
                // or the concrete template if material ref is not a group
                const template = MaterialLibrary.findMaterialByName(this.materialRef.ref, this.name);
                // apply but do not change material ref setup
                this._setMaterialTemplate(template);
            } else {
                const ref = nameIsGroup ? this.materialRef.name : this.materialRef.ref;
                // get current template and directly apply
                const template = MaterialLibrary.getCurrentGroupMaterial(ref);
                // apply but do not change material ref setup
                this._setMaterialTemplate(template);
            }
        }
    }

    /**
     * setup material through material template
     * @param material template
     * @param force
     */
    public setMaterialTemplate(material:MaterialTemplate|string, force?:boolean) {
        // resolve template
        let materialName:string;
        if(typeof material === "string") {
            materialName = material;
            //FIXME: check groups?!
            material = MaterialLibrary.findMaterialByName(material, this.name);
        } else {
            // material.name should be undefined...
            materialName = material.name;
        }

        // apply
        this._setMaterialTemplate(material, force);

        // update material reference
        if(materialName && this.materialRef.ref !== materialName) {
            this.materialRef.ref = materialName;
        }
    }

    /**
     * apply instancing to this model
     * @param instances instance number
     * @param buffers instance buffers to use
     */
    public setInstancing(instances:number, buffers:InstanceBufferRef[]) {

        // replace all geometry buffers with new instance buffers
        const tmpGeometry = this.geometry;

        // generate new buffers
        const geometry = new InstancedBufferGeometry().copy(this.geometry as InstancedBufferGeometry);

        geometry.maxInstancedCount = instances;

        // add instance buffers to this
        for(const buffer of buffers) {
            geometry.addAttribute(buffer.name, buffer.buffer);
        }

        // apply to mesh
        this.geometry = geometry;

        //TODO: calculate bounding from all instances (merge them and apply to all)

        // for now, frustum culling with instances is not supported
        this.frustumCulled = false;

        // free old geometry
        tmpGeometry.dispose();

    }

    /**
     * this functions assumes that model has been converted to use
     * instancing
     * @param count new count (must be smaller than buffer size)
     */
    public setInstanceCount(count:number) {
        let applyable = false;

        const instanceGeometry = this.geometry;
        if(instanceGeometry instanceof InstancedBufferGeometry || instanceGeometry['isInstancedBufferGeometry']) {
            applyable = true;
        }

        console.assert(applyable, "Model: not instancing geometry applied");
        let currentBufferSize = 0;

        if(this.geometry instanceof InstancedBufferGeometry || this.geometry['isInstancedBufferGeometry']) {
            const tmpGeometry = this.geometry as InstancedBufferGeometry;
            // find all attributes that are using instanced ones
            for(const attrKey in tmpGeometry.attributes) {
                if(tmpGeometry.attributes[attrKey] instanceof InstancedBufferAttribute || tmpGeometry.attributes[attrKey]['isInstancedBufferAttribute']) {
                    currentBufferSize = Math.min(currentBufferSize, tmpGeometry.attributes[attrKey].count);
                }
            }
            if(currentBufferSize > count) {
                console.error("Model: current instancing size smaller than count to render");
                return;
            }
            tmpGeometry.maxInstancedCount = count;
        }
    }

    /**
     * setup material through material template
     * @param material template
     * @param force force apply
     */
    private _setMaterialTemplate(material:MaterialTemplate, force?:boolean) {
        if(!material) {
            console.warn(`Mesh(${this.name}): cannot resolve material ${this._materialRef.name} template with reference ${this._materialRef.ref||"unknown"}`);
            //FIXME?! get debug or default template?!
            this._redMaterial = MaterialLibrary.findMaterialByName(MaterialLibSettings.defaultDebugMaterial);
            return;
        }

        // no change (force must be set when internal values have changed)
        if(!force && this._redMaterial && this._redMaterial.name === material.name) {
            return;
        }

        // material switch
        this._redMaterial = material;

        // shader switch
        if(this.material['shaderType'] !== material.shader || force) {
            // use shader name for now -> new API
            this.material = ShaderLibrary.findRuntimeShader(material.shader, this, this.redShaderVariant);

            if(!this.material) {
                this.material = ShaderLibrary.createShader(material.shader, this, this.redShaderVariant);
            }

            // fallback to a debug shader
            if(!this.material) {
                this.material = ShaderLibrary.createShader(ShaderLibrary.DefaultShader, this, ShaderVariant.DEFAULT, true);
            }
        }

        //deactivate shadows for transparent objects
        if(material.transparent) {
            this.receiveShadow = false;
            //TODO: support this for better visual quality
            this.castShadow = false;
        }

        // shadow setup
        if(material.forceCastShadow !== undefined) {
            this.castShadow = material.forceCastShadow;
        }

        if(material.forceReceiveShadow !== undefined) {
            this.receiveShadow = material.forceReceiveShadow;
        }

        // update render order
        this.renderOrder = this.customRenderOrder;
    }

    /** a runtime material has been changed */
    private _materialChanged = (material:MaterialTemplateNamed, mesh:number|undefined) => {
        if(!material || !this.redMaterial) {
            return;
        }
        // not for this mesh
        if(mesh && mesh !== this.nameId) {
            return;
        }
        //FIXME: use materialRef?!
        if(this.redMaterial.name === material.name) {
            // apply
            this.setMaterialTemplate(material.template, true);
        }
    }

    /**
     * raycast against triangles in local space
     * @param raycaster local space ray
     */
    public rayCastLocal(raycaster:any) {
        // reset to identity matrix (so this is in local space)
        const tmpWorldMatrix = this.matrixWorld.clone();
        // FIXME: use this.matrix
        //this.matrixWorld.identity();
        this.matrixWorld.copy(this.matrix);

        const result = [];
        raycaster.intersectObject(this, false, result);

        // restore matrix
        this.matrixWorld.copy(tmpWorldMatrix);
        return result;
    }

    /**
     * bounds check against triangles in local space
     * @param bounds local bounding box
     */
    public boundsCheckLocal(bounds:Box3) {
        // reset to identity matrix (so this is in local space)
        const tmpWorldMatrix = this.matrixWorld.clone();
        // FIXME: use this.matrix
        //this.matrixWorld.identity();
        this.matrixWorld.copy(this.matrix);

        const result = [];
        this._checkIntersectBounds(bounds, result);

        // restore matrix
        this.matrixWorld.copy(tmpWorldMatrix);
        return result;
    }

    /** generate world boundings */
    public worldBounds() {
        if(!this.geometry.boundingBox) {
            this.buildLocalBounds();
        }

        // WORLD BOUNDING
        const boundingBox = this.geometry.boundingBox;
        const newBounding = new Box3();
        const tempVector = new Vector3();

        // need to refresh world matrix
        let needsWorldMatrixUpdate = false;
        let first:Object3D = this;
        while(first.matrixWorldNeedsUpdate && first.parent) {
            first = first.parent;
            needsWorldMatrixUpdate = true;
        }

        if(first && needsWorldMatrixUpdate) {
            first.updateMatrixWorld(false);
        }

        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z)));
        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z)));
        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z)));
        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z)));
        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z)));
        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z)));
        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z)));
        newBounding.expandByPoint(this.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z)));

        return newBounding;
    }

    /**
     * update local bounds
     * @param forceUpdate force a new rebuilt
     */
    public buildLocalBounds(forceUpdate?:boolean) {
        const meshGeometry = this.geometry as BufferGeometry;
        console.assert(meshGeometry.attributes, "Mesh: not a valid geometry buffer object");

        // make sure bounding box is already calculated
        // based on local vertices
        if(!meshGeometry.boundingBox || forceUpdate) {
            //FIXME: reset?
            if(forceUpdate) {
                meshGeometry.boundingBox = null;
                meshGeometry.boundingSphere = null;
            }

            meshGeometry.boundingBox = new Box3();
            meshGeometry.boundingSphere = new Sphere();

            //TODO: custom code (not ignoring draw range)
            const position = meshGeometry.attributes['position'];

            if(position !== undefined) {

                let minX = + Infinity;
                let minY = + Infinity;
                let minZ = + Infinity;

                let maxX = - Infinity;
                let maxY = - Infinity;
                let maxZ = - Infinity;

                if(meshGeometry.index) {
                    const indices = meshGeometry.index;

                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, indices.count) + start;
                    const itemSize = position.itemSize;
                    const indexSize = indices.itemSize;

                    for(let i = start; i < end; ++i) {
                        const index = indices.array[i*indexSize];

                        const x = position.array[index * itemSize];
                        const y = position.array[index * itemSize+1];
                        const z = position.array[index * itemSize+2];

                        if ( x < minX ) { minX = x; }
                        if ( y < minY ) { minY = y; }
                        if ( z < minZ ) { minZ = z; }

                        if ( x > maxX ) { maxX = x; }
                        if ( y > maxY ) { maxY = y; }
                        if ( z > maxZ ) { maxZ = z; }

                    }
                } else {
                    const start = meshGeometry.drawRange.start;
                    const end = Math.min(meshGeometry.drawRange.count, position.count) + start;
                    const itemSize = position.itemSize;

                    for(let i = start; i < end; ++i) {

                        const x = position.array[i * itemSize];
                        const y = position.array[i * itemSize+1];
                        const z = position.array[i * itemSize+2];

                        if ( x < minX ) { minX = x; }
                        if ( y < minY ) { minY = y; }
                        if ( z < minZ ) { minZ = z; }

                        if ( x > maxX ) { maxX = x; }
                        if ( y > maxY ) { maxY = y; }
                        if ( z > maxZ ) { maxZ = z; }
                    }
                }

                meshGeometry.boundingBox.min.set( minX, minY, minZ );
                meshGeometry.boundingBox.max.set( maxX, maxY, maxZ );

                meshGeometry.boundingBox.getBoundingSphere(meshGeometry.boundingSphere);
            } else {
                meshGeometry.boundingBox.makeEmpty();
            }
        }
    }

    /**
     * check local bounds (object space) against bounds
     * @param bounds
     */
    private _checkIntersectBounds(bounds:Box3, intersects:any[]) {
        const geometry = this.geometry as BufferGeometry;
        let a; let b; let c;
        const index = geometry.index;
        const position = geometry.attributes.position as BufferAttribute;
        const morphPosition = geometry.morphAttributes.position;
        const uv = geometry.attributes.uv as BufferAttribute;
        const groups = geometry.groups;
        const drawRange = geometry.drawRange;
        let i; let il;
        let start; let end;

        if ( index !== null ) {
            // indexed buffer geometry
            start = Math.max( 0, drawRange.start );
            end = Math.min( index.count, ( drawRange.start + drawRange.count ) );

            for ( i = start, il = end; i < il; i += 3 ) {

                a = index.getX( i );
                b = index.getX( i + 1 );
                c = index.getX( i + 2 );

                const intersection = checkBufferGeometryIntersection(this, this.material as ShaderMaterial, bounds, position, morphPosition, uv, a, b, c);

                if ( intersection ) {
                    intersection.faceIndex = Math.floor( i / 3 ); // triangle number in indexed buffer semantics
                    intersects.push( intersection );
                }
            }
        } else if ( position !== undefined ) {
            // non-indexed buffer geometry
            start = Math.max( 0, drawRange.start );
            end = Math.min( position.count, ( drawRange.start + drawRange.count ) );

            for ( i = start, il = end; i < il; i += 3 ) {

                a = i;
                b = i + 1;
                c = i + 2;

                const intersection = checkBufferGeometryIntersection(this, this.material as ShaderMaterial, bounds, position, morphPosition, uv, a, b, c);

                if ( intersection ) {

                    intersection.faceIndex = Math.floor( i / 3 ); // triangle number in non-indexed buffer semantics
                    intersects.push( intersection );
                }
            }
        }
    }

    private _generateRenderOrder(userValue:number) {
        this._renderOrder = userValue & RENDER_ORDER_USER_MASK;

        // shader sort id
        if(this.material) {
            const order = this.material['_sortID'] || 0;
            this._renderOrder |= (order << RENDER_ORDER_SHADER_BITS) & RENDER_ORDER_SHADER_MASK;
        }

        // do not generate order id from material for transparent objects
        // their order is z dependent
        //FIXME: also remove render order from shader so 2 transparent shader get sorted by depth
        if(this.material && this.material['transparent']) {
            return;
        }

        // material template sort id
        if(this.redMaterial) {
            const id = this.redMaterial._sortID || 0;
            this._renderOrder |= (id << RENDER_ORDER_MATERIAL_BITS) & RENDER_ORDER_MATERIAL_MASK;
        }
    }

    /** clone support */
    public clone() {
        //FIXME: use materialRef.ref as name?!
        const cloned = new Mesh(this.geometry, this._materialRef.name, this.redShaderVariant);
        THREEMesh.prototype.copy.call(cloned, this);
        cloned._visible = this._visible;
        cloned.setMaterialRef(this._materialRef);
        cloned._generateRenderOrder(this.customRenderOrder);
        return cloned as this;
    }
}

/**
 * check object triangle vs AABB
 * @param object
 * @param bounds
 * @param tri
 * @param point
 */
function checkIntersectionBox( object:Object3D, bounds:Box3, tri:Triangle|any, point:Vector3 ) {
    const intersect = tri.intersectsBox(bounds);

    if ( !intersect ) {
        return null;
    }

    // always center of triangle
    tri.getMidpoint(point);

    const intersectionPointWorld:Vector3 = math.tmpVec3();
    intersectionPointWorld.copy( point );
    intersectionPointWorld.applyMatrix4( object.matrixWorld );

    const distance = bounds.distanceToPoint( intersectionPointWorld );

    return {
        distance: distance,
        point: intersectionPointWorld.clone(),
        object: object
    };
}

const checkBufferGeometryIntersectionTriangle = new Triangle();
function checkBufferGeometryIntersection( object, material:ShaderMaterial, bounds:Box3, positions:BufferAttribute, morphPosition:BufferAttribute[], uv:BufferAttribute, a:number, b:number, c:number) {
    //TODO: get cached one?!
    const tri = checkBufferGeometryIntersectionTriangle;
    const intersectionPoint = math.tmpVec3();

    tri.a.fromBufferAttribute(positions, a);
    tri.b.fromBufferAttribute(positions, b);
    tri.c.fromBufferAttribute(positions, c);

    const morphInfluences = object.morphTargetInfluences;

    if ( material.morphTargets && morphPosition && morphInfluences ) {
        const morphA:Vector3 = math.tmpVec3();
        const morphB:Vector3 = math.tmpVec3();
        const morphC:Vector3 = math.tmpVec3();

        morphA.set( 0, 0, 0 );
        morphB.set( 0, 0, 0 );
        morphC.set( 0, 0, 0 );

        const tempA:Vector3 = math.tmpVec3();
        const tempB:Vector3 = math.tmpVec3();
        const tempC:Vector3 = math.tmpVec3();

        for ( let i = 0, il = morphPosition.length; i < il; i ++ ) {

            const influence = morphInfluences[ i ];
            const morphAttribute = morphPosition[ i ];

            if ( influence === 0 ) { continue ; }

            tempA.fromBufferAttribute( morphAttribute, a );
            tempB.fromBufferAttribute( morphAttribute, b );
            tempC.fromBufferAttribute( morphAttribute, c );

            morphA.addScaledVector( tempA.sub( tri.a ), influence );
            morphB.addScaledVector( tempB.sub( tri.b ), influence );
            morphC.addScaledVector( tempC.sub( tri.c ), influence );

        }

        tri.a.add( morphA );
        tri.b.add( morphB );
        tri.c.add( morphC );
    }

    const intersection:Intersection = checkIntersectionBox(object, bounds, tri, intersectionPoint);

    if ( intersection ) {
        //TODO: re-add
        // if ( uv ) {
        //     const uvA:Vector2 = math.tmpVec2();
        //     const uvB:Vector2 = math.tmpVec2();
        //     const uvC:Vector2 = math.tmpVec2();

        //     uvA.fromBufferAttribute( uv, a );
        //     uvB.fromBufferAttribute( uv, b );
        //     uvC.fromBufferAttribute( uv, c );

        //     intersection.uv = Triangle.getUV( intersectionPoint, vA, vB, vC, uvA, uvB, uvC, new Vector2() );

        // }

        const face = new Face3( a, b, c );
        Triangle.getNormal( tri.a, tri.b, tri.c, face.normal );

        intersection.face = face;
    }

    return intersection;
}
