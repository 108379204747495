/**
 * SpatialAPI.ts: spatial API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { WorldSystem } from "./WorldAPI";
import { ComponentId } from "./Component";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Box3 } from "../../lib/threejs/math/Box3";
import { queryAPI, makeAPI } from "../plugin/Plugin";

/**
 * spatial location of objects
 * [[include:sourceDoc/Spatial.md]]
 */

export enum ESpatialType {
    PROBE = 1,
    LIGHT = 2,
    GEOMETRY = 3,
    CAMERA = 4
}

export enum ESpatialIntersectionType {
    NOT_INTERSECTED = 0,
    INTERSECTED = 1,
    CONTAINED = 2
}

export interface SpatialObject {
    id: ComponentId;
    position: Vector3;
    target: any;
    type: ESpatialType | number;
    boundingBox: Box3;
}

export interface SpatialResponse {
    object: any;
    intersectionType?: ESpatialIntersectionType;
    distance?: number;
}

export interface ISpatialSystem extends WorldSystem {
    /**
     * return position of component id
     * @param id component id of object
     */
    position(id: ComponentId): Vector3;

    /** get all objects that are registered sorted by Distance */
    getObjectsIn(boundingBox: Box3, type?: ESpatialType | number);

    /** get all objects that are registered sorted by Distance */
    getNearestObjects(origin: Vector3, type?: ESpatialType | number);

    /** get nearest object */
    getNearestObject(origin: Vector3, type?: ESpatialType | number);

    updateTransform(id: ComponentId, position: Vector3, boundingBox?: Box3);

    /**
     * add a new spatial object to this list
     * @param object generic object
     * @param position optional position
     */
    registerObject(target: any, position: Vector3, type: ESpatialType | number, boundingBox?: Box3): ComponentId;

    /**
     * remove object from global list
     * @param id component id
     */
    removeObject(id: ComponentId);
}
export const SPATIALSYSTEM_API = makeAPI("ISpatialSystem");

let _globalSpatialSystem:ISpatialSystem;
export function querySpatialSystem() {
    if(_globalSpatialSystem === undefined) {
        _globalSpatialSystem = queryAPI<ISpatialSystem>(SPATIALSYSTEM_API);
    }
    return _globalSpatialSystem;
}
