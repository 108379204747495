/**
 * ModelBuilder.ts: Generic Model Scene code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { ModelData, ModelNode, MODELMESH_PRIMITIVE_LINE, ModelMesh } from "../framework-types/ModelFileFormat";
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { Line as THREELine } from "../../lib/threejs/objects/Line";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Quaternion } from "../../lib/threejs/math/Quaternion";
import { Euler } from "../../lib/threejs/math/Euler";
import { MaterialDesc } from "../render/Material";
import { WorldFileNode, WorldFileComponent } from "../framework-types/WorldFileFormat";
import { MeshComponentParams } from "../framework-components/MeshComponent";
import { math } from "../core/Math";

function fixNodeName(name:string, fixNodeNames:boolean = true) {
    if(fixNodeNames) {
        return name.replace("$AssimpFbx$", "AssimpFbx");
    } else {
        return name;
    }
}

export type CreateNodeCallback = (name:string, modelNode:ModelNode, transform:Matrix4) => Object3D;
export type CreateMeshCallback = (name:string, meshNode:ModelMesh, material:MaterialDesc, parent:Object3D) => Object3D;

function defaultCreateNode(name:string, modelNode:ModelNode, transform:Matrix4) {
    // new node
    const obj = new Object3D();

    obj.name = fixNodeName(name) || "Unknown";
    obj.matrix.copy(transform);
    obj.matrix.decompose(obj.position, obj.quaternion, obj.scale);
    return obj;
}

function defaultCreateMesh(name:string, meshNode:ModelMesh, material:MaterialDesc, parent:Object3D) {
    //FIXME: always export as line??
    if(meshNode.primitiveType === MODELMESH_PRIMITIVE_LINE) {
        const mesh = new THREELine(meshNode.geometry, material as any);

        mesh.name = "Line_" + name;
        return mesh;
    } else {
        //FIXME: also use RED.Mesh ?!
        const mesh = new THREEMesh(meshNode.geometry, material as any);
        mesh.name = "Mesh_" + name;

        // add to parent
        return mesh;
    }
    return null;
}

function createNodeRecursive(node:ModelNode, modelData:ModelData, transform:Matrix4, createNodeCallback?:CreateNodeCallback, createMeshCallback?:CreateMeshCallback, autoShrink?:boolean) {
    const nodeName = node.name;
    const numChildrens = node.children.length;
    const numMeshes = node.meshes.length;
    const nodeMatrix = new Matrix4().compose(node.position, node.quaternion, node.scale);

    // auto shrink support
    if(autoShrink && nodeName.indexOf('$AssimpFbx$') > -1 && numChildrens === 1 && numMeshes === 0) {
        // shrink node
        //console.log("AssimpJSONLoader: shrinking " + node.name);
        const matrix = new Matrix4();

        matrix.multiplyMatrices(nodeMatrix, transform);

        // return child0
        return createNodeRecursive(node.children[0], modelData, matrix, createNodeCallback, createMeshCallback, autoShrink);
    } else {
        // // new node
        // const obj = new Object3D();

        // if(autoShrink) {
        //     obj.name = nodeName || "AssimpJSONLoader Node";
        // } else {
        //     obj.name = fixNodeName(nodeName) || "Unknown";
        // }
        // obj.matrix = new Matrix4();
        // obj.matrix.multiplyMatrices(nodeMatrix, transform).transpose();
        // obj.matrix.decompose(obj.position, obj.quaternion, obj.scale);

        const objMatrix = new Matrix4();
        objMatrix.multiplyMatrices(nodeMatrix, transform);

        let obj:Object3D = null;

        if(createNodeCallback) {
            obj = createNodeCallback(nodeName, node, objMatrix);
        } else {
            obj = defaultCreateNode(nodeName, node, objMatrix);
        }

        // don't want this node... stop
        if(!obj) {
            return null;
        }

        // make sure createMeshCallback can ask matrixWorld

        // add meshes
        for(let i = 0; i < numMeshes; ++i) {
            const idx = node.meshes[i];
            const matIdx = modelData.meshes[idx].materialIndex;

            if(createMeshCallback) {
                const mesh = createMeshCallback(nodeName, modelData.meshes[idx], modelData.materials[matIdx], obj);

                // add to parent
                if(mesh) {
                    obj.add(mesh);
                }
            } else {
                const mesh = defaultCreateMesh(nodeName, modelData.meshes[idx], modelData.materials[matIdx], obj);

                // add to parent
                if(mesh) {
                    obj.add(mesh);
                }
            }
        }
        // process children
        for(let i = 0; i < numChildrens; ++i) {
            const child = createNodeRecursive(node.children[i], modelData, new Matrix4(), createNodeCallback, createMeshCallback, autoShrink);
            if(child) {
                obj.add(child);
            }
        }

        return obj;
    }
}

export function createHierarchyFromModelData(modelData:ModelData, createNodeCallback?:CreateNodeCallback, createMeshCallback?:CreateMeshCallback, autoShrink?:boolean) {
    return createNodeRecursive(modelData.nodes, modelData, new Matrix4(), createNodeCallback, createMeshCallback, autoShrink);
}

/**
 * recursive create nodes and components
 * @param node
 * @param modelData
 * @param transform
 * @param autoShrink
 */
function createWorldNodeRecursive(filename:string, node:ModelNode, modelData:ModelData, transform:Matrix4, autoShrink?:boolean) : WorldFileNode {
    const nodeName = node.name;
    const numChildrens = node.children.length;
    const numMeshes = node.meshes.length;
    const nodeMatrix = new Matrix4().compose(node.position, node.quaternion, node.scale);

    // auto shrink support
    if(autoShrink && nodeName.indexOf('$AssimpFbx$') > -1 && numChildrens === 1 && numMeshes === 0) {
        // shrink node
        //console.log("AssimpJSONLoader: shrinking " + node.name);
        const matrix = new Matrix4();

        matrix.multiplyMatrices(nodeMatrix, transform);

        // return child0
        return createWorldNodeRecursive(filename, node.children[0], modelData, matrix, autoShrink);
    } else {
        const pos = new Vector3();
        const quat = new Quaternion();
        const scale = new Vector3();
        const rot = new Euler();

        const objMatrix = new Matrix4();
        objMatrix.multiplyMatrices(nodeMatrix, transform);
        objMatrix.decompose(pos, quat, scale);
        rot.setFromQuaternion(quat);

        const obj:WorldFileNode = {
            children: [],
            components: [],
            name: nodeName,
            flags: 0,
            translation: [pos.x, pos.y, pos.z],
            rotation: [math.toDegress(rot.x), math.toDegress(rot.y), math.toDegress(rot.z)],
            scaling: [scale.x, scale.y, scale.z],
            type: "node"
        };

        // don't want this node... stop
        if(!obj) {
            return null;
        }

        // make sure createMeshCallback can ask matrixWorld

        // add meshes
        for(let i = 0; i < numMeshes; ++i) {
            const idx = node.meshes[i];
            const meshNode = modelData.meshes[idx];

            //FIXME: always export as line??
            if(meshNode.primitiveType === MODELMESH_PRIMITIVE_LINE) {
                // line component
                const lineComponent:WorldFileComponent = {
                    module: "RED",
                    type: "LineComponent",
                    parameters: null
                };
            } else {
                // mesh component
                const parameters:MeshComponentParams = {
                    filename: filename + "@" + idx
                };

                const meshComponent:WorldFileComponent = {
                    module: "RED",
                    type: "MeshComponent",
                    parameters: parameters
                };
                obj.components.push(meshComponent);
            }
        }
        // process children
        for(let i = 0; i < numChildrens; ++i) {
            const child = createWorldNodeRecursive(filename, node.children[i], modelData, new Matrix4(), autoShrink);
            if(child) {
                obj.children.push(child);
            }
        }

        return obj;
    }
}

/**
 * create prefab data from model data
 * @param modelData
 * @param autoShrink
 */
export function createPrefabFromModelData(filename:string, modelData:ModelData, prefabId:string, autoShrink?:boolean) {
    const root = createWorldNodeRecursive(filename, modelData.nodes, modelData, new Matrix4(), autoShrink);
    root.type = "prefab";
    root.id = prefabId;
    return root;
}
