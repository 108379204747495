///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class MapComponent extends AbstractBlockComponent {

    private _markersAdded:boolean = false;
    private _map:google.maps.Map;
    private _mapsScriptCallbackRef:()=>void;
    private _mapContentElement;
    private _mapStyle:google.maps.StyledMapType;
    private _clickListeners:Array<any> = [];
    private static _mapsScript;
    private static _mapsScriptLoaded:boolean;
    private static _mapsScriptCallbacks:Array<()=>void> = [];

    protected onInit():void{
        this._params = _.defaults(this._params, {
            center:  {lat: 0, lng: 0},
            zoom: 13,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            scrollwheel: false,
        });

        this._params.style = JSON.parse(this._params.style);

        this._mapContentElement = this.block.domElement.querySelector('.map_content');

        //console.log("Map", this._params);

        this._mapsScriptCallbackRef = this.onMapScriptLoaded.bind(this);

        if(MapComponent._mapsScript == null){
            MapComponent._mapsScriptCallbacks.push(this._mapsScriptCallbackRef);

            MapComponent._mapsScript = document.createElement('script');
            MapComponent._mapsScript.async = true;
            MapComponent._mapsScript.defer = true;
            MapComponent._mapsScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAokmv2CmTkgh6EtzyXvyulp9jKipI2iIU";
            MapComponent._mapsScript.onload =  ()=> {
                MapComponent._mapsScriptLoaded = true;
                console.log("maps loaded");
                _.each(MapComponent._mapsScriptCallbacks,(callback)=>{callback()});
            };
            document.body.appendChild(MapComponent._mapsScript);
        }
        else if(!MapComponent._mapsScriptLoaded){
            MapComponent._mapsScriptCallbacks.push(this._mapsScriptCallbackRef);
        }
        else{
            this.onMapScriptLoaded();
        }
    }

    private onMapScriptLoaded():void{

        this._map = new google.maps.Map(this._mapContentElement, {
            center: this._params.center,
            zoom: this._params.zoom,
            zoomControl: this._params.zoomControl,
            mapTypeControl: this._params.mapTypeControl,
            scaleControl: this._params.scaleControl,
            streetViewControl: this._params.streetViewControl,
            rotateControl: this._params.rotateControl,
            scrollwheel: this._params.scrollwheel
        });

        this._mapStyle = new google.maps.StyledMapType(<any> this._params.style, {name: "RedPlantStyle"});
        this._map.mapTypes.set('map_style', this._mapStyle);
        this._map.setMapTypeId('map_style');

        //TODO: This is no longer possible. appeared should never been called befor the component is ready.
        if(this._block.appeared){
            console.error("This is no longer possible. appeared should never been called befor the component is ready.");
            this.AddMarker();
        }

        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        google.maps.event.clearInstanceListeners(window);
        google.maps.event.clearInstanceListeners(document);
        google.maps.event.clearInstanceListeners(this._mapContentElement);

        _.each(this._clickListeners,(clickListener)=>{
            clickListener.remove();
        });

        $(this._mapContentElement).detach();
        MapComponent._mapsScriptCallbacks = _.without(MapComponent._mapsScriptCallbacks,this._mapsScriptCallbackRef);
        this.state = ComponentState.DESTROYED;
    }

    public appear():void{
        this.AddMarker();
    }

    private AddMarker():void{
        if(this._markersAdded) return;
        this._markersAdded = true;

        let depthSortedMarker:Array<any> = _.sortBy(this._params.markers,(markerdata)=>{return (<any>markerdata).depth;});

        _.each(depthSortedMarker,(markerdata, index)=>{
            setTimeout(()=>{
                let marker = new google.maps.Marker({
                    position: markerdata.position,
                    animation: google.maps.Animation.DROP,
                    title: markerdata.title,
                    map: this._map,
                });

                let relations = _.filter(this._params.relations,{to:markerdata.id});
                _.each(relations,(relation)=>{
                    let fromMarker:any = _.find(this._params.markers,{id:(<any>relation).from});
                    //console.log("relations", markerdata.id,fromMarker.id);

                    let polyline = new google.maps.Polyline({
                        path: [markerdata.position,fromMarker.position],
                        geodesic: true,
                        strokeColor: '#FF0000',
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                        map: this._map
                    });

                });
                // console.log("relations", markerdata.id,relations);

                if(markerdata.showcontent){
                    let infowindow = new google.maps.InfoWindow({
                        content: markerdata.content
                    });

                    //TODO: FIX click memory leak
                    let listener = marker.addListener('click', () => {
                        infowindow.open(this._map, marker);
                    });

                    this._clickListeners.push(listener);
                }
            }, 500 + (300 * <any>index));
        });


        // _.each(this._params.relations,(relation, index)=>{
        //     setTimeout(()=>{
        //         let polyline = new google.maps.Polyline({
        //             path: relation,
        //             geodesic: true,
        //             strokeColor: '#FF0000',
        //             strokeOpacity: 1.0,
        //             strokeWeight: 2,
        //             map: this._map
        //         });
        //     }, 500 + (150 * <any>index));
        // });



    }


}