/**
 * LightProbe.ts: environment map tools
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { CubeReflectionMapping, ClampToEdgeWrapping, DoubleSide, LinearFilter, RGBAFormat, UnsignedByteType, EquirectangularReflectionMapping, CubeRefractionMapping, EquirectangularRefractionMapping, BackSide } from "../../lib/threejs/constants";
import { Texture } from "../../lib/threejs/textures/Texture";
import { Box3 } from "../../lib/threejs/math/Box3";
import { ShaderMaterial } from "../../lib/threejs/materials/ShaderMaterial";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Scene } from "../../lib/threejs/scenes/Scene";
import { CubeCamera } from "../../lib/threejs/cameras/CubeCamera";
import { MeshBasicMaterial } from "../../lib/threejs/materials/MeshBasicMaterial";
import { IcosahedronGeometry } from "../../lib/threejs/geometries/IcosahedronGeometry";
import { WebGLRenderTargetCube } from "../../lib/threejs/renderers/WebGLRenderTargetCube";
import { OrthographicCamera } from "../../lib/threejs/cameras/OrthographicCamera";
import { PlaneGeometry } from "../../lib/threejs/geometries/PlaneGeometry";
import { WebGLRenderTarget } from "../../lib/threejs/renderers/WebGLRenderTarget";
import { SpriteMaterial } from "../../lib/threejs/materials/SpriteMaterial";
import { Sprite } from "../../lib/threejs/objects/Sprite";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { DataTexture } from "../../lib/threejs/textures/DataTexture";
import {Render} from './Render';
import { RenderQuality } from './QualityLevels';
import { ShaderLibrary } from './ShaderLibrary';
import { build } from "../core/Build";
import { TextureDB } from "../io/AssetInfo";
import { AssetProcessor, EProcessType } from "../io/AssetProcessor";
import { maxMipLevels, blackTextureCube } from "./Texture";
import { RenderState } from "./State";
import { ShaderApplyInterface, setValueShader, setValueShaderGlobal } from "./Shader";
import { RedCamera } from "./Camera";
import { Uniform } from "./Uniforms";

export const DefaultProbeBoxMin = [-65536,-65536,-65536];
export const DefaultProbeBoxMax = [ 65536, 65536, 65536];

/** generic reflection probe access */
export interface IReflectionProbe {
    sphericalHarmonics: SHLighting;
    cubemap : Texture;
    intensity: number;
    shIntensity: number;
    worldBounds: Box3;
}

/** SH lighting data */
export interface SHLighting {
    sh: Float32Array[];
    compact: {
        cAr: number[];
        cAg: number[];
        cAb: number[];
        cBr: number[];
        cBg: number[];
        cBb: number[];
        cC: number[];
    };
}

/**
 * set environment map / probe
 * @param shaderInterface shader interface
 * @param camera camera instance
 * @param material material
 * @param probe ReflectionProbeComponent
 */
export function setValueShaderProbe(shaderInterface:ShaderApplyInterface, camera:RedCamera, material:ShaderMaterial, nearestProbe:any) {
    // NEW STYLE CODE
    const uniformProbe = material.uniforms['reflectionProbe'] as Uniform;

    if(!uniformProbe) {
        return;
    }

    if(!uniformProbe.value) {
        uniformProbe.value = {
            mipLevels: 0,
            iblLuminance: 0.0,
            boxMin: new Vector3(),
            boxMax: new Vector3()
        };
    }

    if(nearestProbe) {
        const probe = nearestProbe.object as IReflectionProbe;

        const maxLevel = maxMipLevels(probe.cubemap);

        uniformProbe.value.mipLevels = maxLevel;
        uniformProbe.value.iblLuminance = probe.intensity * camera.exposure;
        uniformProbe.value.shLuminance = probe.shIntensity * camera.exposure;
        // convert to world coordinates
        uniformProbe.value.boxMin.copy(probe.worldBounds.min);
        uniformProbe.value.boxMax.copy(probe.worldBounds.max);
        //TODO: check for probe state
        uniformProbe.needsUpdate = true;

        setValueShader(shaderInterface, "reflectionProbeMap", material, probe.cubemap);

        if(probe.sphericalHarmonics) {
            setValueShader(shaderInterface, "cAr", material, probe.sphericalHarmonics.compact.cAr);
            setValueShader(shaderInterface, "cAg", material, probe.sphericalHarmonics.compact.cAg);
            setValueShader(shaderInterface, "cAb", material, probe.sphericalHarmonics.compact.cAb);
            setValueShader(shaderInterface, "cBr", material, probe.sphericalHarmonics.compact.cBr);
            setValueShader(shaderInterface, "cBg", material, probe.sphericalHarmonics.compact.cBg);
            setValueShader(shaderInterface, "cBb", material, probe.sphericalHarmonics.compact.cBb);
            setValueShader(shaderInterface, "cC", material, probe.sphericalHarmonics.compact.cC);
        } else {
            // use global SH values
            setValueShaderSHLights(shaderInterface, material);
        }
    } else {
        const maxLevel = maxMipLevels(blackTextureCube());

        uniformProbe.value.mipLevels = maxLevel;
        uniformProbe.value.iblLuminance = 30000.0 * camera.exposure;
        uniformProbe.value.shLuminance = 30000.0 * camera.exposure;
        uniformProbe.value.boxMin.fromArray(DefaultProbeBoxMin);
        uniformProbe.value.boxMax.fromArray(DefaultProbeBoxMax);
        //TODO: check for probe state
        uniformProbe.needsUpdate = true;

        setValueShader(shaderInterface, "reflectionProbeMap", material, blackTextureCube());

        // use global SH values
        setValueShaderSHLights(shaderInterface, material);
    }
}

/**
 * set global light data
 * @param shaderInterface
 * @param material
 */
export function setValueShaderSHLights(shaderInterface:ShaderApplyInterface, material:ShaderMaterial) {
    setValueShaderGlobal(shaderInterface, "cAr", material);
    setValueShaderGlobal(shaderInterface, "cAg", material);
    setValueShaderGlobal(shaderInterface, "cAb", material);
    setValueShaderGlobal(shaderInterface, "cBr", material);
    setValueShaderGlobal(shaderInterface, "cBg", material);
    setValueShaderGlobal(shaderInterface, "cBb", material);
    setValueShaderGlobal(shaderInterface, "cC", material);
}

/**
 * convert equirectangular map to cubemap
 */
export class EquirectangularToCubemap {

    public get isValid() : boolean {
        return this._renderer && this._scene && this._material;
    }

    private _renderer;
    private _scene;
    private _camera;
    private _material;
    private maxSize;

    constructor(renderer:Render) {
        this._renderer = renderer.webGLRender;
        this._scene = new Scene();

        const gl = this._renderer.getContext();
        this.maxSize = gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE);

        this._camera = new CubeCamera( 1, 100000, 1 );

        this._material = new MeshBasicMaterial( {
            map: null,
            color: 0xffffff,
            side: BackSide
        } );

        const mesh = new THREEMesh(
            new IcosahedronGeometry( 100, 4 ),
            this._material
        );
        this._scene.add(mesh);
    }

    /** convert it now */
    public apply(source, size) {
        if(!this.isValid) {
            return null;
        }

        const mapSize = Math.min(size, this.maxSize);

        this._camera = new CubeCamera( 1, 100000, mapSize );
        this._material.map = source;

        this._camera.update(this._renderer, this._scene);

        // set this to get correct mapping
        this._camera.renderTarget.texture.mapping = CubeReflectionMapping;
        this._camera.renderTarget.texture.name = source.name;

        return this._camera.renderTarget.texture;
    }

}

export const EquirectanglarToCubemapProcessor:AssetProcessor = {
    type: EProcessType.Texture,
    priority: 99999999,
    processTexture: (texture:any, settings?:TextureDB) => {

        // convert to cubemap
        if(settings && settings.convertToCubemap === true) {
            const cubemapTool = new EquirectangularToCubemap(Render.Main);
            // set input texture to update to GPU (just created)
            texture.needsUpdate = true;
            // convert to cubemap and return new instance
            texture = cubemapTool.apply(texture, Render.DefaultCubemapSize);
            //
            texture.needsUpdate = false;
        }

        return texture;
    }
};

/**
 * generate prefiltered cubemap mip levels from cubemap
 */
export class PBRCubemap {

    /** service accessor */
    public static get(renderer:Render) {
        if(!this._asService) {
            this._asService = new PBRCubemap(renderer);
        }
        return this._asService;
    }
    private static _asService:PBRCubemap;

    /** filter mip level 0 */
    public filterLod0:boolean = true;

    /** internal renderer */
    private _renderer:Render;
    /** filter shader */
    private _shader:any[];

    /** filter lods pipeline */
    private _pipeState:RenderState;
    private _sceneSetup:any;

    /** initialization */
    constructor(renderer:Render) {
        this._shader = null;
        this._pipeState =  new RenderState();
        this._renderer = renderer;
    }

    /** destruction */
    public destroy() {
        this._pipeState.destroy();
    }

    /** copy mip levels into source target */
    public copy(sourceTarget:WebGLRenderTargetCube) : WebGLRenderTargetCube {

        const source = sourceTarget.texture;

        // remember old wrapping mode and use clamp to edge
        const tempWrapS = source.wrapS;
        const tempWrapT = source.wrapT;

        source.wrapS = ClampToEdgeWrapping;
        source.wrapT = ClampToEdgeWrapping;

        this._setupShader();

        // generate new texture from lod levels
        this._renderCopy(sourceTarget);

        // restore old wrapping
        source.wrapS = tempWrapS;
        source.wrapT = tempWrapT;

        return sourceTarget;
    }

    private _setupScene() {
        if(this._sceneSetup) {
            return;
        }

        this._sceneSetup = {
            scene: new Scene(),
            camera: new OrthographicCamera(-1, 1, 1, -1, 0, 1),
            planeMesh: new THREEMesh(new PlaneGeometry(2, 2), this._shader)
        };

        this._sceneSetup.planeMesh.frustumCulled = false;
        if(!Array.isArray(this._sceneSetup.planeMesh.material)) {
            this._sceneSetup.planeMesh.material.side = DoubleSide;
        } else {
            for(const mat of this._sceneSetup.planeMesh.material) {
                mat.side = DoubleSide;
            }
        }
        this._sceneSetup.scene.add(this._sceneSetup.planeMesh);
    }

    /** lazy init */
    private _setupShader() {
        // initialize shader
        if(!this._shader) {
            this._shader = [];

            const defines = {};

            if(this._renderer.qualitySetting === RenderQuality.HighQuality) {
                defines['NUM_SAMPLES'] = 1024;
            } else if(this._renderer.qualitySetting === RenderQuality.MediumQuality) {
                defines['NUM_SAMPLES'] = 512;
            } else {
                defines['NUM_SAMPLES'] = 64;
            }

            const template = {
                shader: "redEnvMapFilterCube"
            };

            for(let i = 0; i < 6; ++i) {
                defines['RED_FILTER_FACE_ID'] = i;
                this._shader[i] = ShaderLibrary.createMaterialShader("create_envmap_roughness_" + i, template, defines);
                this._shader[i].depthWrite = false;
                this._shader[i].depthTest = false;
                this._shader[i].side = BackSide;
            }
        }
    }

    /** copy target mip levels */
    private _renderCopy(sourceTarget:WebGLRenderTargetCube) {

        // filter scene setup
        this._setupScene();

        // setup lod levels (take other stuff into account?)
        const size = sourceTarget.width;
        const numLods = Math.log(size) / Math.log(2);
        //const numLods = maxMipLevelsTarget(sourceTarget);

        const source = sourceTarget.texture;

        const params = {
            format: source.format,
            magFilter: LinearFilter,
            minFilter: LinearFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            type: source.type,
            generateMipmaps: false,
            anisotropy: source.anisotropy,
            encoding: source.encoding,
            stencilBuffer: false,
            depthBuffer: false
        };

        const firstMip = this.filterLod0 ? 0 : 1;

        // level 0 -> numLods
        let mipWidth = this.filterLod0 ? source.image.width : Math.floor(source.image.width / 2);
        let mipHeight = this.filterLod0 ? source.image.height : Math.floor(source.image.height / 2);

        // render lod roughness levels
        for(let i = firstMip; i < numLods; ++i) {
            const roughness = Math.max(0.04, i / ( numLods - 1 ));

            // generate render target for roughness levels
            const renderTarget = this._pipeState.requestTemporaryTarget({
                width: mipWidth,
                height: mipHeight,
            }, params);

            for(let f = 0; f < 6; ++f) {
                // update shader
                this._shader[f].uniforms['envMap'].value = sourceTarget.texture;
                this._shader[f].uniforms['roughness'].value = roughness;
                this._shader[f].needsUpdate = true;

                // update pipeline setup
                this._pipeState.renderTarget = renderTarget;
                this._pipeState.clearTarget = true;
                this._pipeState.clearColor = [1.0, 1.0, 1.0];
                this._pipeState.overrideMaterial = this._shader[f];

                this._renderer.render(this._sceneSetup.scene, this._sceneSetup.camera, this._pipeState);

                // copy to source mip level
                const _gl = this._renderer.webGLRender.getContext();
                const width = renderTarget.width;
                const height = renderTarget.height;
                const level = i;
                const glFormat = source.format === RGBAFormat ? _gl.RGBA : _gl.RGB;

                if(source.type === UnsignedByteType) {
                    this._renderer.webGLRender.setTextureCube(source, 0);
                    _gl.copyTexImage2D(_gl.TEXTURE_CUBE_MAP_POSITIVE_X + f, level, glFormat, 0, 0, width, height, 0 );
                } else {
                    // copy back (TODO: no support for LOD)
                    // const lastActiveMip = sourceTarget.activeCubeFace;
                    // sourceTarget.activeCubeFace = f;
                    // this._renderer.renderCopy(renderTarget, sourceTarget);
                    // sourceTarget.activeCubeFace = lastActiveMip;
                    console.assert(false, "not working on webgl 1.0");
                }
            }

            // next lod size
            mipWidth = Math.floor(Math.max( 1, mipWidth / 2 ));
            mipHeight = Math.floor(Math.max( 1, mipHeight / 2 ));

            // do some cleanup
            this._pipeState.returnTemporaryTarget(renderTarget);
        }

        this._renderer.webGLRender.setRenderTarget(null);

    }
}

/**
 * tool for filtering equirectangular maps
 * currently only works for power of two textures
 * TODO: flips currently y axis
 */
export class PBREquirectangular {

    private _renderer:Render;
    private _shader;

    private _lods:any[];
    private _debug:boolean;

    constructor(renderer:Render, visualizeDebug?:boolean) {
        this._debug = visualizeDebug || false;
        this._lods = [];
        this._shader = null;
        this._renderer = renderer;
    }

    /** @return data texture */
    public apply(source:any) : any {

        // remember old wrapping mode and use clamp to edge
        const tempWrapS = source.wrapS;
        const tempWrapT = source.wrapT;

        source.wrapS = ClampToEdgeWrapping;
        source.wrapT = ClampToEdgeWrapping;

        // initialize shader
        const template = {
            shader: "redEnvMapFilter"
        };

        const defines = {};

        if(this._renderer.qualitySetting === RenderQuality.HighQuality) {
            defines['NUM_SAMPLES'] = 1024;
        } else if(this._renderer.qualitySetting === RenderQuality.MediumQuality) {
            defines['NUM_SAMPLES'] = 512;
        } else {
            defines['NUM_SAMPLES'] = 64;
        }

        this._shader = ShaderLibrary.createMaterialShader("create_envmap_roughness", template, defines);
        this._shader.depthWrite = false;
        this._shader.depthTest = false;
        this._shader.side = BackSide;

        // setup lod levels (take other stuff into account?)
        const size = source.image.width;
        const numLods = Math.log(size) / Math.log(2) + 1;

        const params = {
            format: source.format,
            magFilter: source.magFilter,
            minFilter: source.minFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            type: source.type,
            generateMipmaps: false,
            anisotropy: source.anisotropy,
            encoding: source.encoding,
            stencilBuffer: false,
            depthBuffer: false
        };

        // level 0 -> numLods
        let mipWidth = source.image.width;
        let mipHeight = source.image.height;
        for(let i = 0; i < numLods; i++) {

            // generate render target for roughness levels
            const renderTarget = new WebGLRenderTarget(mipWidth, mipHeight, params);
            renderTarget.texture.name = "generatedenvmap" + i;
            this._lods.push(renderTarget);

            // next lod size
            mipWidth = Math.floor(Math.max( 1, mipWidth / 2 ));
            mipHeight = Math.floor(Math.max( 1, mipHeight / 2 ));
        }

        // generate new texture from lod levels
        const newTex = this._render(source, numLods);

        // restore old wrapping
        source.wrapS = tempWrapS;
        source.wrapT = tempWrapT;

        // do some cleanup
        for(const lod of this._lods) {
            if(!this._debug) {
                lod.dispose();
            }
        }

        return newTex;
    }

    private _render(sourceTexture:any, numLods:number) {

        // filter scene setup
        const scene = new Scene();
        const camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
        const planeMesh = new THREEMesh(new PlaneGeometry(2, 2), this._shader);
        planeMesh.frustumCulled = false;
        if(!Array.isArray(planeMesh.material)) {
            planeMesh.material.side = DoubleSide;
        } else {
            for(const mat of planeMesh.material) {
                mat.side = DoubleSide;
            }
        }
        scene.add(planeMesh);

        // update shader
        this._shader.uniforms['envMap'].value = sourceTexture;

        // generate new texture from source settings
        //FIXME: use original wrapS and wrapT??
        const dataTexture = new DataTexture(undefined, sourceTexture.image.width, sourceTexture.image.height, sourceTexture.format, sourceTexture.type,
                                                sourceTexture.mapping, sourceTexture.wrapS, sourceTexture.wrapT, sourceTexture.magFilter, sourceTexture.minFilter);
        // force no
        dataTexture.generateMipmaps = false;

        // force source texture to be uploaded
        // checking if source texture was a render target texture
        if(sourceTexture.version === 0 && sourceTexture.image) {
            sourceTexture.needsUpdate = true;
        }

        // render lod roughness levels
        for(let i = 0; i < numLods; ++i) {
            const roughness = Math.max(0.04, i / ( numLods - 1 ));

            // update shader
            this._shader.uniforms['roughness'].value = roughness;
            this._shader.needsUpdate = true;

            // do the filtering on target
            this._renderer.webGLRender.setRenderTarget(this._lods[i]);
            this._renderer.webGLRender.clear(false, true, true);
            this._renderer.webGLRender.render(scene, camera);

            //FIXME: apply this lod as next source

            // copy to source texture in mip map channels
            //TODO: would like to use ClampedArray but many webgl implementations do not support them
            //const buffer = new Uint8ClampedArray(this._lods[i].width * this._lods[i].height * 4);
            const buffer = new Uint8Array(this._lods[i].width * this._lods[i].height * 4);

            this._renderer.webGLRender.readRenderTargetPixels(this._lods[i], 0, 0, this._lods[i].width, this._lods[i].height, buffer);

            dataTexture.mipmaps[i] = {
                width: this._lods[i].width,
                height: this._lods[i].height,
                data: buffer as any
            };

            // add debug stuff
            if(this._debug) {
                const material = new SpriteMaterial( { map: this._lods[i].texture, color: 0xffffff} );
                const sprite = new Sprite( material );
                sprite.scale.set(this._lods[i].width, this._lods[i].height,1);

                const test = new Object3D();
                test.scale.set(64.0 / this._lods[i].width, 64.0 / this._lods[i].height, 1.0);
                test.position.set( i * 100, 0, 0 );
                test.add(sprite);
                //World.scene.add(test);
            }
        }

        this._renderer.webGLRender.setRenderTarget(null);

        // finished with new texture
        dataTexture.needsUpdate = true;
        return dataTexture;
    }
}

export const PrefilterProcessor:AssetProcessor = {
    type: EProcessType.Texture,
    processTexture: (texture:any, settings?:TextureDB) => {

        //REMOVE and add to global stuff
        if(settings && settings.isEquirectangular) {
            texture.mapping = EquirectangularReflectionMapping;
        }

        // only environment maps
        if(texture.mapping !== CubeReflectionMapping &&
           texture.mapping !== CubeRefractionMapping &&
           texture.mapping !== EquirectangularReflectionMapping &&
           texture.mapping !== EquirectangularRefractionMapping) {
            return texture;
        }

        // pre filter
        if(ShaderLibrary.usePrefilteredProbes() && Render.Main) {

            //TODO: force usePrefilteredProbes to false when no mip map access
            if(!Render.Main.capabilities.textureLOD) {
                return texture;
            }

            let filter = null;
            switch(texture.mapping) {
                case CubeReflectionMapping:
                case CubeRefractionMapping:
                    filter = new PBRCubemap(Render.Main);
                    break;
                case EquirectangularReflectionMapping:
                case EquirectangularRefractionMapping:
                    filter = new PBREquirectangular(Render.Main, false);
                    break;
                default:
                    filter = new PBRCubemap(Render.Main);
                    break;
            }

            if(filter) {
                texture = filter.apply(texture);
            }

            //FIXME: add debugShaderOutput
            if(build.Options.debugRenderOutput) {
                console.log("Shader: overriding envmap with filtered envmap");
            }
        }

        return texture;
    }
};
