/**
 * AsyncLoad.ts: promise like loading state
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */

export type AsyncCallback = (resolve:any, reject?:any) => void;

function noop() {}

interface AsyncHandle<T> {
    primitive:AsyncLoad<T>;
    resolve: <T1 = T>(value:T) => (T|AsyncLoad<T1>) | void;
    reject: <T2 = never>(reason?:any) => (T2|AsyncLoad<T2>);
}

/**
 * custom promise class implementation
 */
export class AsyncLoad<T> implements PromiseLike<T> {

    private static LAST_ERROR = null;
    private static IS_ERROR = {};

    private _handles:AsyncHandle<any>[];
    private _state:number;
    private _value:any;

    /** promise constructor */
    constructor(func: (resolve: (result? : T | PromiseLike<T>) => void, reject: (error: any) => void) => void) {
        this._state = 0;
        this._handles = [];

        if (func === noop) {
            return;
        }
        // try to resolve
        AsyncLoad._doResolve(func, this);
    }

    /** promise thenable */
    public then<T1 = T|never, T2 = never>(resolve?: ((value:T) => T1|PromiseLike<T1>) | undefined | null, reject?: ((reason?:any) => T2|PromiseLike<T2>) | undefined | null) : AsyncLoad<T1|T2> {
        const res = new AsyncLoad<T1>(noop);

        this._handle({
            primitive: res,
            resolve: (resolve || null),
            reject: (reject || null)
        });

        return res;
    }

    /** promise catch */
    public catch<T1 = never>(reject: ((reason?:any) => T1|AsyncLoad<T1>) | undefined | null) : AsyncLoad<T | T1> {
        return this.then(null, reject);
    }

    /** to native promise */
    public toPromise() : Promise<T> {
        return new Promise<T>( (resolve, reject) => {
            this.then(resolve, reject);
        });
    }

    /** promise reject */
    public static reject<T1>(reason?:any) {
        return new AsyncLoad<T1>(function(resolve, reject) {
            reject(reason);
        });
    }

    /** promise resolve */
    public static resolve<T1>(value?:(T1|AsyncLoad<T1>)|void) : AsyncLoad<T1> {
        if (value instanceof AsyncLoad) {
            return value;
        }

        if (value && typeof value === 'object' || typeof value === 'function') {
            try {
                const then = (value as any).then;
                if (typeof then === 'function') {
                    return new AsyncLoad(then.bind(value));
                }
            } catch (ex) {
                return new AsyncLoad(function(resolve, reject) {
                    reject(ex);
                });
            }
        }

        const ret = new AsyncLoad<T1>(noop);
        ret._state = 1;
        ret._value = value;
        return ret;
    }

    /** promise all */
    public static all<T1>(iterable: Iterable<T1|AsyncLoad<T1>>) : AsyncLoad<T1[]> {
        const args = Array.prototype.slice.call(iterable);

        return new AsyncLoad<T1[]>(function(resolve, reject) {
            if (args.length === 0) {
                return resolve([]);
            }

            let remaining = args.length;
            function res(i:number, val:any) {
                if (val && (typeof val === 'object' || typeof val === 'function')) {
                    if (val instanceof AsyncLoad && val.then === AsyncLoad.prototype.then) {
                        while (val._state === 3) {
                            val = val._value;
                        }
                        if (val._state === 1) {
                            return res(i, val._value);
                        }
                        if (val._state === 2) {
                            reject(val._value);
                        }

                        // wait for it
                        val.then(function(value) {
                            res(i, value);
                        }, reject);

                        return;
                    } else {
                        const then = val.then;
                        if (typeof then === 'function') {

                            const p = new AsyncLoad(then.bind(val));
                            p.then(function(value) {
                                res(i, value);
                            }, reject);
                            return;
                        }
                        //FIXME: error here?!
                    }
                }
                args[i] = val;
                if (--remaining === 0) {
                    resolve(args);
                }
            }
            for(let i = 0; i < args.length; i++) {
                res(i, args[i]);
            }
        });
    }

    private _handle<T1 = T>(handle:any) {

        let self = this;

        while(self._state === 3) {
            self = self._value as any;
        }

        if(self._state === 0) {
            self._handles.push(handle);
            return;
        }

        self._handleResolved<T>(handle);
    }

    private _handleResolved<T1 = T>(handle:AsyncHandle<T1>) {
        const cb = this._state === 1 ? handle.resolve : handle.reject;
        if(cb === null) {
            if(this._state === 1) {
                AsyncLoad._resolve(handle.primitive, this._value);
            } else {
                AsyncLoad._reject(handle.primitive, this._value);
            }
            return;
        }

        let ret = null;
        try {
            const func = cb as any;
            ret = func(this._value);
        } catch (ex) {
            AsyncLoad.LAST_ERROR = ex;
            ret = AsyncLoad.IS_ERROR;
        }

        if(ret === AsyncLoad.IS_ERROR) {
            AsyncLoad._reject(handle.primitive, AsyncLoad.LAST_ERROR);
        } else {
            AsyncLoad._resolve(handle.primitive, ret);
        }
    }

    private static _resolve<T>(primitive:AsyncLoad<T>, value?:any) {

        if(value && (typeof value === 'object' || typeof value === 'function')) {

            let then = null;
            try {
                then = value.then;
            } catch(ex) {
                AsyncLoad.LAST_ERROR = ex;
                then = AsyncLoad.IS_ERROR;
            }

            if (then === AsyncLoad.IS_ERROR) {
                return AsyncLoad._reject(primitive, AsyncLoad.LAST_ERROR);
            }
            if (then === primitive.then && value instanceof AsyncLoad) {
                primitive._state = 3;
                primitive._value = value;
                primitive._finale();
                return;
            } else if (typeof then === 'function') {
                AsyncLoad._doResolve(then.bind(value), primitive);
                return;
            }
        }

        primitive._state = 1;
        primitive._value = value;
        primitive._finale();
    }

    private static _reject<T>(primitive:AsyncLoad<T>, value?:any) {
        if(value) {
            console.error(value);
        }

        primitive._state = 2;
        primitive._value = value;
        primitive._finale();
    }

    private _finale() {
        for(const handler of this._handles) {
            this._handle(handler);
        }
        this._handles = [];
    }

    private static _doResolve<T>(func:AsyncCallback, loader:AsyncLoad<T>) {
        let done = false;
        let res = null;
        try {
            func(function(value) {
                if (done) {
                    return;
                }
                done = true;
                AsyncLoad._resolve(loader, value);
            }, function(reason) {
                if (done) {
                    return;
                }
                done = true;
                AsyncLoad._reject(loader, reason);
            });

        } catch (ex) {
            AsyncLoad.LAST_ERROR = ex;
            res = AsyncLoad.IS_ERROR;
        }

        if (!done && res === AsyncLoad.IS_ERROR) {
            done = true;
            AsyncLoad._reject(loader, AsyncLoad.LAST_ERROR);
        }
    }
}
