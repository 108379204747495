/**
 * Geometry.ts: Generic Geometry code
 * http://www.terathon.com/code/tangent.html
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { Quaternion } from "../../lib/threejs/math/Quaternion";
import { BufferGeometry } from "../../lib/threejs/core/BufferGeometry";
import { BufferAttribute } from "../../lib/threejs/core/BufferAttribute";

export interface InstanceBufferRef {
    name:string;
    buffer:any;
}

export interface MaterialRef {
    /** name of material */
    name:string;
    /** reference to material */
    ref:string;
}

export function generateQTangent(geometry:BufferGeometry) {

    const vertices  = geometry.getAttribute("position");
    const normals   = geometry.getAttribute("normal");
    const uvs       = geometry.getAttribute("uv");
    const indices = geometry.index;

    const vertexCount:number   = vertices.count;
    const indexCount:number = indices.count;

    // tangents and bitangent variables
    const tan1:Vector3[] = [];
    const tan2:Vector3[] = [];
    const sdir = new Vector3();
    const tdir = new Vector3();

    // qTangents variables
    const qTangents = new Float32Array(4 * vertexCount);
    const normal = new Vector3();
    const tangentFrameM = new Matrix4();
    const tangentFrameQ = new Quaternion();
    const naturalBinormal = new Vector3();

    //initialize arrays
    for(let i = 0; i < indexCount; i++) {
        tan1.push(new Vector3());
        tan2.push(new Vector3());
    }

    //calculate tangents and bitangent
    for(let i = 0; i < indexCount; i += 3) {

        const i1 = indices.getX(i);
        const i2 = indices.getY(i);
        const i3 = indices.getZ(i);

        const x1 = vertices.getX(i2) - vertices.getX(i1); // v2.x - v1.x
        const x2 = vertices.getX(i3) - vertices.getX(i1); // v3.x - v1.x
        const y1 = vertices.getY(i2) - vertices.getY(i1); // v2.y - v1.y
        const y2 = vertices.getY(i3) - vertices.getY(i1); // v3.y - v1.y
        const z1 = vertices.getZ(i2) - vertices.getZ(i1); // v2.z - v1.z
        const z2 = vertices.getZ(i3) - vertices.getZ(i1); // v3.z - v1.z

        const s1 = uvs.getX(i2) - uvs.getX(i1); // w2.x - w1.x
        const s2 = uvs.getX(i3) - uvs.getX(i1); // w3.x - w1.x
        const t1 = uvs.getY(i2) - uvs.getY(i1); // w2.y - w1.y
        const t2 = uvs.getY(i3) - uvs.getY(i1); // w3.y - w1.y

        const r = 1.0 / (s1 * t2 - s2 * t1);

        sdir.set(
            (t2 * x1 - t1 * x2) * r,
            (t2 * y1 - t1 * y2) * r,
            (t2 * z1 - t1 * z2) * r
        );

        tdir.set(
            (s1 * x2 - s2 * x1) * r,
            (s1 * y2 - s2 * y1) * r,
            (s1 * z2 - s2 * z1) * r
        );

        tan1[i1].add(sdir);
        tan1[i2].add(sdir);
        tan1[i3].add(sdir);

        tan2[i1].add(tdir);
        tan2[i2].add(tdir);
        tan2[i3].add(tdir);
    }

    // normalize
    for(let i = 0; i < vertexCount; i++) {
        tan1[i].normalize();
        tan2[i].normalize();
    }

    // calculate qtangents
    for(let i = 0; i < vertexCount; i++) {

        const iTangent = tan1[i];
        const iBitangent = tan2[i];

        normal.set(
            normals.getX(i),
            normals.getY(i),
            normals.getZ(i) );

        tangentFrameM.makeBasis(normal, iTangent, iBitangent);
        tangentFrameQ.setFromRotationMatrix(tangentFrameM);

        if(tangentFrameQ.w < 0.0) {
            tangentFrameQ.set(-tangentFrameQ.x, -tangentFrameQ.y, -tangentFrameQ.z, -tangentFrameQ.w);
        }

        const bias = 0.000001;
        if(tangentFrameQ.w < bias) {
            const normFactor = Math.sqrt(1.0 - bias * bias);
            tangentFrameQ.w = bias;
            tangentFrameQ.x *= normFactor;
            tangentFrameQ.y *= normFactor;
            tangentFrameQ.z *= normFactor;
        }

        naturalBinormal.copy(iTangent).cross(normal);

        if(naturalBinormal.dot(iBitangent) <= 0.0) {
            tangentFrameQ.set(-tangentFrameQ.x, -tangentFrameQ.y, -tangentFrameQ.z, -tangentFrameQ.w);
        }

        qTangents[4*i + 0] = tangentFrameQ.x;
        qTangents[4*i + 1] = tangentFrameQ.y;
        qTangents[4*i + 2] = tangentFrameQ.z;
        qTangents[4*i + 3] = tangentFrameQ.w;
    }

    geometry.addAttribute("qTangent", new BufferAttribute(qTangents, 4));
}
