/**
 * ShaderLibrary.ts: Shader Library
 * [[include:sourceDoc/Shader.md]]
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { CullFaceBack, NormalBlending, AdditiveBlending, MultiplyBlending, NoBlending, CullFaceFront, BackSide, FrontSide, CullFaceNone, DoubleSide } from "../../lib/threejs/constants";
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { Color } from "../../lib/threejs/math/Color";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Vector4 } from "../../lib/threejs/math/Vector4";
import { ShaderMaterial } from "../../lib/threejs/materials/ShaderMaterial";
import { Vector2 } from "../../lib/threejs/math/Vector2";
import { InstancedBufferGeometry } from "../../lib/threejs/core/InstancedBufferGeometry";
import { BoxBufferGeometry } from "../../lib/threejs/geometries/BoxGeometry";
import { PerspectiveCamera } from "../../lib/threejs/cameras/PerspectiveCamera";
import { Scene } from "../../lib/threejs/scenes/Scene";
import { Material } from "../../lib/threejs/materials/Material";
import { mergeObject } from '../core/Globals';
import { build } from '../core/Build';
import { makeAPI, registerAPI } from "../plugin/Plugin";
import { devMarkTimelineStart, devMarkTimelineEnd } from "../core/Debug";
import { EventNoArg } from '../core/Events';
import { AsyncLoad } from '../io/AsyncLoad';
import { AssetManager } from '../framework/AssetManager';
import { AssetProcessing, AssetProcessor } from "../io/AssetProcessor";
import { RenderQuality } from './QualityLevels';
import { Uniform, Uniforms, mergeUniforms, cloneUniforms, cloneUniformValue, EUniformType } from './Uniforms';
import { ShaderSettings, ShaderVariantRef, ShaderDesc, Shader, ShaderVariant, ShaderSelectorCallback} from './Shader';
import { UniformLib, ShaderChunk, preloadShaderFragments, loadShader } from './ShaderBuilder';
import { RedMaterial, MaterialTemplate } from './Material';
import { EquirectanglarToCubemapProcessor, PrefilterProcessor, DefaultProbeBoxMin, DefaultProbeBoxMax } from './LightProbe';
import { LightData, SphereLightUniformData, TubeLightUniformData, LightDataGlobal, EShadowQuality, RedDirectionalUniformData, PoissonSamples, IESLightUniformData } from './Lights';
import { Mesh } from "./Mesh";
import { Render } from './Render';
import { Line } from "../render-line/Line";
import { blackTextureCube } from "./Texture";
import { ELightType } from "../framework/LightAPI";

/**
 * @interface IShaderLibrary
 * provides functionality handling three.js material shader
 */
export interface IShaderLibrary {
    ShaderSelect:{[key:string]:ShaderSelectorCallback};
    CustomShaderLib:{[key:string]:Shader};
    DefaultShader:string;
    OnHotReload:EventNoArg;

    /** compile fog information into shader */
    useFog() : boolean;
    setUseFog(value:boolean);

    /** use prefiltered environment map */
    usePrefilteredProbes(): boolean;
    setUsePrefilteredProbes(value:boolean);

    /** shader precision settings */
    useShaderPrecision(): boolean;
    setUseShaderPrecision(value:boolean);

    /** use area lights */
    useAreaLights() : boolean;
    setUseAreaLights(value:boolean);

    /** use gamma correction */
    useGammaCorrected() : boolean;
    setUseGammaCorrected(value:boolean);

    /** shadow quality setup */
    shadowQuality() : EShadowQuality;

    setShadowQuality(value:EShadowQuality);
    /** shadow side */
    shadowSide() : number;
    setShadowSide(value:number);

    /** use parallax corrected cubemaps */
    useParallaxCubemap() : boolean;
    setUseParallaxCubemap(value:boolean);

    /** use spherical harmonics for lighting */
    useSHLighting() : boolean;
    setUseSHLighting(value:boolean);

    init();

    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    flushGPUMemory();

    /**
     * flush anything data related here
     */
    flush();

    hotReload();

    /**
     * create a shader material for THREE.JS
     * this does not do any caching/reusing and is just a basic implementation
     * @param shaderName shader name (optional when in template)
     * @param template material template definition
     * @param name optional shader name (defaults to template name)
     * @return three.js ShaderMaterial
     */
    createShader(shaderName:string, mesh?:Mesh|Line, variant?:ShaderVariant, compile?:boolean) : RedMaterial;

    /**
     * create a shader material for THREE.JS
     * this does not do any caching/reusing and is just a basic implementation
     * @param shaderName shader name (optional when in template)
     * @param template material template definition
     * @param name optional shader name (defaults to template name)
     * @return three.js ShaderMaterial
     */
    createMaterialShader(name:string, template:MaterialTemplate, customDefines?:{}) : any;

    /**
     * remapping shaders (replacing completly)
     * @param shaderName original shader name
     * @param replaceShader replace shader name
     */
    remapShader(shaderName:string, replaceShader:string);

    isGlobalDefineSet(name:string) : boolean;
    getGlobalDefine(name:string) : string | number | boolean | undefined;
    /**
     * set global define
     * @param name define name
     * @param value define value
     */
    setGlobalDefine(name:string, value:string | number | boolean, predicate?:(material:any) => boolean, compile?:boolean);
    /**
     * set global parameter
     * @param name parameter name
     * @param value value
     * @param type uniform type
     */
    setGlobalParameter(name:string, value:any, type:EUniformType);

    /**
     * get uniform value
     * @param name parameter name
     */
    getGlobalParameter(name:string) : Uniform | undefined;

    /**
     * get value
     * @param name parameter name
     * @param type optional type check
     */
    getGlobalParameterValue(name:string, type?:EUniformType) : any | undefined;

    /**
     * remove a global define on shader
     * @param name
     */
    removeGlobalDefine(name, compile?:boolean);

    updateBuiltinLights(lightData:LightDataGlobal);
    updateLights(lights:LightData[]);

    loadShader(name:string) : AsyncLoad<string>;

    /**
     * find or create shader from library
     * may hurt performance when needs to compile shader
     * @param name
     * @param mesh
     * @param defaultVariant
     */
    findOrCreateShader(name:string, mesh?:Mesh|Line, defaultVariant?:ShaderVariant);

    findRuntimeShader(name:string, mesh?:Mesh|Line, variant?:ShaderVariant) : any;

    save();

    printRuntimeShaders();
}
export const SHADERLIBRARY_API = makeAPI("IShaderLibrary");

/** shader module load callback */
// export interface LoadCallback {
//     callback:ShaderModuleCallback;
//     files?:Array<string>;
// }

/**
 * global defines handling
 */
interface GlobalDefine {
    value: any;
    predicate: (material:any) => boolean;
}
function GlobalDefine_defaultPredicate(material:any) : boolean {
    return true;
}

/**
 * @namespace ShaderLibrary
 * provides functionality handling three.js material shader
 */
//export namespace ShaderLibrary {
/**
 * Shader Selections
 */
const ShaderSelect:{[key:string]:ShaderSelectorCallback} = {
};

/**
 * Shader Combinations
 * include Unlit.js, Basic.js etc for loading shaders.
 * add your own custom shader to this library
 */
const CustomShaderLib:{[key:string]:Shader} = {
};

const DefaultShader:string = "redUnlit";

/** hot reload callback */
const OnHotReload:EventNoArg = new EventNoArg();

/** compile fog information into shader */
function useFog() : boolean {
    return _useFog;
}
function setUseFog(value:boolean) {
    if(_useFog !== value) {
        _useFog = value;
        hotReload();
    }
}

/** use prefiltered environment map */
function usePrefilteredProbes() {
    return _usePrefilteredProbes;
}

function setUsePrefilteredProbes(value:boolean) {
    if(_usePrefilteredProbes !== value) {
        _usePrefilteredProbes = value;
        if(value) {
            setGlobalDefine("RED_FILTERED_REFLECTIONPROBE", 1);
        } else {
            removeGlobalDefine("RED_FILTERED_REFLECTIONPROBE");
        }
        //FIXME: update shader to non or prefiltered?!!
        if(value) {
            AssetProcessing.get().addProcessor(_prefilterProbesFilter);
        } else {
            AssetProcessing.get().removeProcessor(_prefilterProbesFilter);
        }
    }
}

/** shader precision settings */
function useShaderPrecision() : boolean {
    return _useShaderPrecision;
}

function setUseShaderPrecision(value:boolean) {
    if(_useShaderPrecision !== value) {
        _useShaderPrecision = value;
        hotReload();
    }
}

/** use area lights */
function useAreaLights() : boolean {
    return _useAreaLights;
}
function setUseAreaLights(value:boolean) {
    if(_useAreaLights !== value) {
        //FIXME: directly update shader code?!
        _useAreaLights = value;
    }
}

/** use gamma correction */
function useGammaCorrected() : boolean {
    return true;
}
function setUseGammaCorrected(value:boolean) {
    if(_useGammaCorrection !== value) {
        if(value) {
            setGlobalDefine("RED_USE_GAMMA_CORRECTED", 1, undefined, false);
        } else {
            setGlobalDefine("RED_USE_GAMMA_CORRECTED", 0, undefined, false);
        }
        _useGammaCorrection = value;
    }
}

/** shadow quality setup */
function shadowQuality() : EShadowQuality {
    return _shadowQuality;
}

function setShadowQuality(value:EShadowQuality) {
    if(_shadowQuality !== value) {
        switch(value) {
            case EShadowQuality.LOW:
                setGlobalDefine("RED_SHADOW_QUALITY", 0, undefined, false);
                break;
            case EShadowQuality.MEDIUM:
                setGlobalDefine("RED_SHADOW_QUALITY", 1, undefined, false);
                break;
            case EShadowQuality.HIGH:
                setGlobalDefine("RED_SHADOW_QUALITY", 2, undefined, false);
                break;
            default:
                break;
        }

        _shadowQuality = value;
    }
}
/** shadow side */
function shadowSide() : number {
    return _shadowSide;
}
function setShadowSide(value:number) {
    if(_shadowSide !== value) {
        _shadowSide = value;
        _applyGlobalSettings();
    }
}

/** use parallax corrected cubemaps */
function useParallaxCubemap() : boolean {
    return _useParallaxCubemap;
}
function setUseParallaxCubemap(value:boolean) {
    if(_useParallaxCubemap !== value) {
        if(value) {
            setGlobalDefine("RED_ENVMAP_BOX_PROJECTED", 1, GlobalDefine_envMapPredicate, false);
        } else {
            setGlobalDefine("RED_ENVMAP_BOX_PROJECTED", 0, GlobalDefine_envMapPredicate, false);
        }
        _useParallaxCubemap = value;
    }
}

/** use spherical harmonics for lighting */
function useSHLighting() {
    return _useSHLighting;
}
function setUseSHLighting(value:boolean) {
    if(_useSHLighting !== value) {
        if(value) {
            setGlobalDefine("RED_SH_LIGHTING", 1, GlobalDefine_shPredicate, false);
        } else {
            setGlobalDefine("RED_SH_LIGHTING", 0, GlobalDefine_shPredicate, false);
        }
        _useSHLighting = value;
    }
}

/** scene fogging */
let _useFog:boolean;

/** prefiltered environment maps */
let _usePrefilteredProbes:boolean;
let _prefilterProbesFilter:AssetProcessor;
/** area lights */
let _useAreaLights:boolean;
/** precision */
let _useShaderPrecision:boolean;
/** gamma correction */
let _useGammaCorrection:boolean;
/** shadow quality */
let _shadowQuality:EShadowQuality;
let _shadowSide:number;
/** parallax corrected cubemaps */
let _useParallaxCubemap:boolean;
/** use spherical harmonics for lighting */
let _useSHLighting: boolean;

/** shader library */
let _isLoaded:boolean = false;
let _isInitialized:boolean = false;
let _compileShader:boolean;
//let _loadCallback:Array<LoadCallback> = [];
let _builtinUniforms:{[key:string]:Uniforms} = {};
/** runtime instances */
let _runtimeMaterials:Array<RedMaterial> = [];
let _runtimeShaders:Array<RedMaterial|any> = [];
let _shaderInstance:{[key:string]:ShaderVariantRef} = {};

const _compilerData:{} = {};

/** global variables (shader) */
const _globalParameters:Uniforms = {};
const _globalDefines:{[key:string]:GlobalDefine} = {};

/**
 * construction
 */
function init_constructor() {
    _compileShader = true;

    // setup filter
    _prefilterProbesFilter = PrefilterProcessor;

    // convert to cube map processor
    AssetProcessing.get().addProcessor(EquirectanglarToCubemapProcessor);

    // standard uniforms used by three.js
    // update with every three.js upgrade
    _builtinUniforms = {
        // global fog
        fog: {
            fogDensity: { value: 0.00025, type:EUniformType.FLOAT },
            fogNear: { value: 1, type:EUniformType.FLOAT },
            fogFar: { value: 2000, type:EUniformType.FLOAT },
            fogColor: { value: new Color(0xffffff), type:EUniformType.COLOR }
        },
        // direct lighting
        lights: {
            ambientLightColor: { value: [] },

            directionalLights: { value: [], properties: {
                direction: {},
                color: {},
                shadow: {},
                shadowBias: {},
                shadowRadius: {},
                shadowMapSize: {}
            } },

            directionalShadowMap: { value: [] },
            directionalShadowMatrix: { value: [] },

            spotLights: { value: [], properties: {
                color: {},
                position: {},
                direction: {},
                distance: {},
                coneCos: {},
                penumbraCos: {},
                decay: {},

                shadow: {},
                shadowBias: {},
                shadowRadius: {},
                shadowMapSize: {}
            } },

            spotShadowMap: { value: [] },
            spotShadowMatrix: { value: [] },

            pointLights: { value: [], properties: {
                color: {},
                position: {},
                decay: {},
                distance: {},
                shadow: {},
                shadowBias: {},
                shadowRadius: {},
                shadowMapSize: {},
                shadowCameraNear: {},
                shadowCameraFar: {}
            } },

            pointShadowMap: { value: [] },
            pointShadowMatrix: { value: [] },

            hemisphereLights: { value: [], properties: {
                direction: {},
                skyColor: {},
                groundColor: {}
            } },

            // TODO (abelnation): RectAreaLight BRDF data needs to be moved from example to main src
            rectAreaLights: { value: [], properties: {
                color: {},
                position: {},
                width: {},
                height: {},
            } }
        },
        // redplant custom lights
        redLights: {
            sphereLights: {
                needsUpdate: false,
                type: EUniformType.STRUCT,
                value: [],
                properties: {
                    color: {},
                    position: {},
                    decay: {},
                    distance: {},
                    radius: {},
                }
            },
            tubeLights: {
                type: EUniformType.STRUCT,
                needsUpdate: false,
                value: [],
                properties: {
                    color: {},
                    position: {},
                    lightAxis: {},
                    decay: {},
                    distance: {},
                    radius: {},
                    width: {},
                    height: {}
                }
            },
            iesLights: {
                type: EUniformType.STRUCT,
                needsUpdate: false,
                value: [],
                properties: {
                    color: {},
                    position: {},
                    decay: {},
                    distance: {}
                }
            },
            iesLightsProfile: { value: null, type: EUniformType.TEXTURE },
            directionalRedLights: { // directional vsm or esm
                type: EUniformType.STRUCT,
                needsUpdate: false,
                value: [],
                properties: {
                    direction: {},
                    color: {},
                    shadow: {},
                    shadowBias: {},
                    shadowRadius: {},
                    shadowMapSize: {},
                }
            },
            directionalRedShadowMap: { value: [], type: EUniformType.TEXTURE_ARRAY },
            directionalRedShadowMatrix: { value: [], type: EUniformType.MATRIX4_ARRAY },
        },
        // sh lighting
        sh: {
            cAr: { value: new Vector4(0,0,0,0), type: EUniformType.VECTOR4, default: new Vector4(0,0,0,0)},
            cAg: { value: new Vector4(0,0,0,0), type: EUniformType.VECTOR4, default: new Vector4(0,0,0,0)},
            cAb: { value: new Vector4(0,0,0,0), type: EUniformType.VECTOR4, default: new Vector4(0,0,0,0)},
            cBr: { value: new Vector4(0,0,0,0), type: EUniformType.VECTOR4, default: new Vector4(0,0,0,0)},
            cBg: { value: new Vector4(0,0,0,0), type: EUniformType.VECTOR4, default: new Vector4(0,0,0,0)},
            cBb: { value: new Vector4(0,0,0,0), type: EUniformType.VECTOR4, default: new Vector4(0,0,0,0)},
            cC: { value: new Vector4(0,0,0,0), type: EUniformType.VECTOR4, default: new Vector4(0,0,0,0)}
        },
        // hdr tonemapping
        hdr: {
            toneMappingExposure: { value: 1.0, type: EUniformType.FLOAT, default: 1.0 },
            toneMappingWhitePoint: { value: 1.0, type: EUniformType.FLOAT, default: 1.0 }
        },
        // poisson disc samples
        pds:{
            poissonSamples: { type: EUniformType.VECTOR2_ARRAY, value: PoissonSamples },
        },
        // physical camera
        camera: {
            exposure: { value: 1.0, type: EUniformType.FLOAT, default: 1.0 },
        },
        // environment mapping
        probe: {
            reflectionProbe: {
                needsUpdate: false,
                type: EUniformType.STRUCT,
                value: {
                    mipLevels: 0,
                    iblLuminance: 0.0,
                    boxMin: new Vector3(DefaultProbeBoxMin[0], DefaultProbeBoxMin[1], DefaultProbeBoxMin[2]),
                    boxMax: new Vector3(DefaultProbeBoxMax[0], DefaultProbeBoxMax[1], DefaultProbeBoxMax[2])
                },
                properties: {
                    mipLevels: { type: EUniformType.INTEGER, value: 0, default: 0 },
                    iblLuminance : { type: EUniformType.FLOAT, value: 1.0, default: 1.0 }, // temporary
                    shLuminance : { type: EUniformType.FLOAT, value: 1.0, default: 1.0 }, // temporary
                    /** in world space */
                    boxMin : { type: EUniformType.VECTOR3, value: null, default: new Vector3(DefaultProbeBoxMin[0], DefaultProbeBoxMin[1], DefaultProbeBoxMin[2])},
                    boxMax : { type: EUniformType.VECTOR3, value: null, default: new Vector3(DefaultProbeBoxMax[0], DefaultProbeBoxMax[1], DefaultProbeBoxMax[2])},
                }
            },
            reflectionProbeMap : { type: EUniformType.TEXTURE, value: null, default: blackTextureCube() },
        }
    };

    //
    for(const block in _builtinUniforms) {
        const copy = cloneUniforms(_builtinUniforms[block]);
        UniformLib[block] = copy;
    }

    // off by default for current version
    _useFog = false;
    // try to use prefiltered probes
    _usePrefilteredProbes = false;
    setUsePrefilteredProbes(true);
    // use with care:
    // most graphics driver are not working in custom precision
    _useShaderPrecision = false;
    // default on as these run on most systems
    _useAreaLights = true;
    // default to gamma correction
    _useGammaCorrection = true;
    // default to high quality
    _shadowQuality = EShadowQuality.MEDIUM;
    // default shadow setup
    _shadowSide = DoubleSide;
    // default on as these are mostly used in projects
    _useParallaxCubemap = true;
    // default to use sh lights
    _useSHLighting = true;

    // setup global defines
    setGlobalDefine("RED_USE_GAMMA_CORRECTED", 1, undefined, false);
    setGlobalDefine("RED_SHADOW_QUALITY", EShadowQuality.MEDIUM, undefined, false);
    setGlobalDefine("RED_USE_QTANGENT", 1, undefined, false);
    setGlobalDefine("RED_PROBE_LIGHTING", 1, undefined, false);
    setGlobalDefine("ENVMAP_TYPE_CUBE", 1, GlobalDefine_envMapPredicate, false);
    setGlobalDefine("RED_ENVMAP_BOX_PROJECTED", 1, GlobalDefine_envMapPredicate, false);
    setGlobalDefine("RED_SH_LIGHTING", 1, GlobalDefine_shPredicate, false);

    setGlobalParameter("poissonSamples", PoissonSamples, EUniformType.VECTOR2_ARRAY);

    // default
    setGlobalSHLighting_DEPRECATED(null);

    RenderQuality.OnQualityChanged.on(() => hotReload());
}
// auto init
if(!_isInitialized) {
    _isInitialized = true;
    init_constructor();
}

/**
 * flush memory on the gpu,
 * does not destroy memory on client side
 */
function flushGPUMemory() {
    // old style THREE.js materials
    for(const material of _runtimeMaterials) {
        material.dispose();
    }
    // shader instances
    for(const shader of _runtimeShaders) {
        shader.dispose();
    }
}

/**
 * flush anything data related here
 */
function flush() {
    flushGPUMemory();

    _runtimeMaterials = [];
    _runtimeShaders = [];
    _shaderInstance = {};

    // precreate shaders
    _precreateShaders();
}

/**
 * init shader library
 * call this on startup (at preloading)
 */
function init() : AsyncLoad<void> {
    return new AsyncLoad<void>( (resolve, reject) => {
        if(build.Options.shaderLibrary.useShaderBundles) {

            // try to preload shader bundle
            _preloadShaderBundle(build.Options.shaderLibrary.bundleFilename).then( () => {

                //TODO: add handling of errors, for now, always initalized
                preloadShaderFragments().then(() => {
                    _isLoaded = true;
                    _precreateShaders();
                    resolve();
                }, () => {
                    _isLoaded = true;
                    _precreateShaders();
                    resolve();
                });

            }, reject);

        } else {

            //TODO: add handling of errors, for now, always initalized
            preloadShaderFragments().then(() => {
                _isLoaded = true;
                _precreateShaders();
                resolve();
            }, () => {
                _isLoaded = true;
                _precreateShaders();
                resolve();
            });
        }
    });
}

/**
 * internally load shader module (JSON file)
 * @param filename json filename
 */
// function loadShaderModule(filename:string) {
//     if(_isLoaded === false) {
//         return AsyncLoad.reject<void>(new Error("ShaderLibrary not initialized yet"));
//     }

//     return AssetManager.loadText(filename).then( (text:string) => {
//         try {
//             const shaderDesc = JSON.parse(text) as ShaderDesc;

//             let shaderCodes:AsyncLoad<string>[] = [];
//             // load files into chunks
//             if(shaderDesc.files && Array.isArray(shaderDesc.files)) {
//                 shaderCodes = shaderDesc.files.map(loadShader);
//             }

//             return AsyncLoad.all(shaderCodes).then( () => {
//                 if(CustomShaderLib[shaderDesc.name]) {
//                     console.warn("ShaderLibrary: overwriting shader " + shaderDesc.name);
//                 }

//                 CustomShaderLib[shaderDesc.name] = {
//                     fragmentShader: ShaderChunk[shaderDesc.pixelShader],
//                     vertexShader: ShaderChunk[shaderDesc.vertexShader],
//                     uniforms: {},
//                     redSettings: shaderDesc.redSettings,
//                     evaluateDefines: function() { return shaderDesc.defines; }
//                 };

//                 // fill in uniforms
//                 const uniforms:Uniforms[] = [];
//                 for(const builtin of shaderDesc.builtins) {
//                     if(UniformLib[builtin]) {
//                         uniforms.push(UniformLib[builtin]);
//                     }
//                 }
//                 uniforms.push(shaderDesc.uniforms);

//                 CustomShaderLib[shaderDesc.name].uniforms = mergeUniforms(uniforms);

//                 // convert json data to three.js classes

//                 for(const key in CustomShaderLib[shaderDesc.name].uniforms) {
//                     const uniform = CustomShaderLib[shaderDesc.name].uniforms[key];

//                     switch(uniform.type) {
//                         case EUniformType.FLOAT:
//                             if(uniform.value === null || uniform.value === undefined) {
//                                 uniform.value = 0.0;
//                             }
//                             break;
//                         case EUniformType.COLOR:
//                             if(Array.isArray(uniform.value)) {
//                                 uniform.value = new Color().fromArray(uniform.value);
//                             } else if(typeof uniform.value === 'number') {
//                                 uniform.value = new Color(uniform.value);
//                             }
//                             break;
//                         case EUniformType.VECTOR2:
//                             if(Array.isArray(uniform.value)) {
//                                 uniform.value = new Vector2().fromArray(uniform.value);
//                             }
//                             break;
//                         case EUniformType.VECTOR3:
//                             if(Array.isArray(uniform.value)) {
//                                 uniform.value = new Vector3().fromArray(uniform.value);
//                             }
//                             break;
//                         case EUniformType.VECTOR4:
//                             if(Array.isArray(uniform.value)) {
//                                 uniform.value = new Vector4().fromArray(uniform.value);
//                             }
//                             break;
//                         case EUniformType.TEXTURE:
//                             // builtin textures
//                             if(uniform.value === "white") {
//                                 uniform.value = whiteTexture();
//                             } else if(uniform.value === "black") {
//                                 uniform.value = blackTexture();
//                             } else if(typeof uniform.value === "string" ) {
//                                 // save and reset
//                                 const texName = uniform.value;
//                                 uniform.value = null;
//                                 // apply
//                                 TextureLibrary.createTexture(texName, null, null).then( (tex) => uniform.value = tex);
//                             }
//                             break;
//                         case EUniformType.MATRIX3:
//                             if(Array.isArray(uniform.value)) {
//                                 uniform.value = new Matrix3().fromArray(uniform.value);
//                             } else {
//                                 uniform.value = new Matrix3();
//                             }
//                             break;
//                         case EUniformType.MATRIX4:
//                             if(Array.isArray(uniform.value)) {
//                                 uniform.value = new Matrix4().fromArray(uniform.value);
//                             } else {
//                                 uniform.value = new Matrix4();
//                             }
//                             break;
//                         default:
//                             break;
//                     }
//                 }

//                 return AsyncLoad.resolve<void>();
//             },
//             (err) => {
//                 return AsyncLoad.reject<void>(err);
//             });
//         } catch(err) {
//             return AsyncLoad.reject<void>(err);
//         }
//     });
// }

/**
 * remapping shaders (replacing completly)
 * @param shaderName original shader name
 * @param replaceShader replace shader name
 */
function remapShader(shaderName:string, replaceShader:string) {

    if(!CustomShaderLib[shaderName]) {
        console.warn("ShaderLibrary: unknown shader " + shaderName);
        return;
    }
    if(!CustomShaderLib[replaceShader]) {
        console.warn("ShaderLibrary: unknown shader " + replaceShader);
        return;
    }

    const remapName = shaderName + "_remapped";
    if(CustomShaderLib[remapName]) {
        console.warn("ShaderLibrary: " + shaderName + " already remapped");
        return;
    }

    // save old instance
    CustomShaderLib[remapName] = CustomShaderLib[shaderName];

    // set
    CustomShaderLib[shaderName] = CustomShaderLib[replaceShader];

}

/**
 * replace old mapping with original
 * @param shaderName original shader name
 */
function unmapShader(shaderName:string) {
    const remapName = shaderName + "_remapped";
    if(!CustomShaderLib[remapName]) {
        console.warn("ShaderLibrary: " + shaderName + " not remapped");
        return;
    }

    // restore old instance
    CustomShaderLib[shaderName] = CustomShaderLib[remapName];

    // remove old remapped
    delete CustomShaderLib[remapName];
}

/**
 * create a shader material for THREE.JS
 * this does not do any caching/reusing and is just a basic implementation
 * @param shaderName shader name (optional when in template)
 * @param template material template definition
 * @param name optional shader name (defaults to template name)
 * @return three.js ShaderMaterial
 */
function createShader(shaderName:string, mesh?:Mesh|Line, variant:ShaderVariant = ShaderVariant.DEFAULT, compile?:boolean) {
    // resolve to default when no input
    shaderName = shaderName || DefaultShader;

    // make sure valid name is given
    const shader:Shader = CustomShaderLib[shaderName];

    if(!shader) {
        console.warn("ShaderLibrary: invalid shader name " + shaderName);
        return null;
    }

    // evaluate variant
    if(mesh) {
        variant = mesh.meshVariant | variant;
    }

    // use variant
    let material = findRuntimeShader(shaderName, mesh, variant);

    // already created
    if(material) {
        return material;
    }

    // check if variant is supported by shader
    if(variant !== ShaderVariant.DEFAULT && shader.variants) {
        const variantSupport = shader.variants.indexOf(variant);
        if(variantSupport === -1) {
            return null;
        }
    }

    // defaults
    const defines = {};

    const uniformVariables:any = cloneUniforms(shader.uniforms);

    const lighting:boolean = shader.redSettings ? shader.redSettings.lights === true : false;
    const fog:boolean = shader.redSettings ? shader.redSettings.fog === true : _useFog;
    const skinning:boolean = shader.redSettings ? shader.redSettings.skinning === true : false;
    const wireframe:boolean = shader.redSettings ? shader.redSettings.wireframe === true : false;

    const depthTest = shader.redSettings && shader.redSettings.depthTest !== undefined ? shader.redSettings.depthTest === true : true;
    const depthWrite = shader.redSettings && shader.redSettings.depthWrite !== undefined ? shader.redSettings.depthWrite === true : true;
    const cullFace = shader.redSettings && shader.redSettings.cullFace !== undefined ? shader.redSettings.cullFace : CullFaceBack;

    const blending = shader.redSettings.blending ? shader.redSettings.blending : "none";
    const transparent = blending === "none" ? false : true;
    const premultipliedAlpha = shader.redSettings && shader.redSettings.premultipliedAlpha !== undefined ? shader.redSettings.premultipliedAlpha === true : true;

    const polygonOffset = shader.redSettings.polygonOffset === true;

    const isRawMaterial:boolean = shader.redSettings ? shader.redSettings.isRawMaterial === true : false;
    console.assert(isRawMaterial, "shaders should only use three.js raw material");

    // find shader code
    let vertexCode = null;
    if(typeof shader.vertexShader === 'string') {
        vertexCode = shader.vertexShader;
    } else {
        vertexCode = shader.vertexShader[variant];
    }
    if(!vertexCode) {
        console.error("missing shader for variant: " + variant);
    }

    let pixelCode = null;
    if(typeof shader.fragmentShader === 'string') {
        pixelCode = shader.fragmentShader;
    } else {
        pixelCode = shader.fragmentShader[variant];
    }
    if(!pixelCode) {
        console.error("missing shader for variant: " + variant);
    }

    material = new RedMaterial({
        fragmentShader: pixelCode,
        vertexShader: vertexCode,
        uniforms: uniformVariables,
        defines: defines,
        lights: lighting,
        fog: fog,
        skinning: skinning,
        wireframe: wireframe,
        depthTest: depthTest,
        depthWrite: depthWrite,
        transparent: transparent,
        premultipliedAlpha: premultipliedAlpha
    });

    // assign red shader desc
    material.__redShader = shader;
    material.__redOrder = shader.redSettings.order || 0;
    // assign red load identificator
    material._redLoadCounter = 0;
    material._redVersion = 0;
    // write custom data
    material.shaderType = shaderName;
    // naming
    material.name = shaderName + "_instance";
    material.__redName = shaderName;

    // assign compile callback
    if(shader.onCompile) {
        material.onBeforeCompile = shader.onCompile;
    }

    // setup derivatives and extensions
    const derivatives:boolean = shader.redSettings ? shader.redSettings.derivatives === true : false;
    material.extensions.derivatives = derivatives;

    const shaderLOD:boolean = shader.redSettings ? shader.redSettings.shaderTextureLOD === true : false;
    material.extensions.shaderTextureLOD = shaderLOD;

    if(material.transparent === true) {
        if(blending === "normal") {
            material.blending = NormalBlending;
        } else if (blending === "additive") {
            material.blending = AdditiveBlending;
        } else if (blending === "multiply") {
            material.blending = MultiplyBlending;
        } else {
            console.error("Invalid state: transparent material cannot have blending set to none");
        }
    } else {
        //no blending
        material.blending = NoBlending;
    }

    // cull face
    switch(cullFace) {
        case CullFaceFront:
            material.side = BackSide;
            break;
        case CullFaceBack:
            material.side = FrontSide;
            break;
        case CullFaceNone:
            material.side = DoubleSide;
            break;
    }

    // polygon offset
    if(polygonOffset) {
        const factor = shader.redSettings.polygonOffsetFactor || 0.0;
        const units = shader.redSettings.polygonOffsetUnits || 0.0;
        material.polygonOffset = true;
        material.polygonOffsetFactor = factor;
        material.polygonOffsetUnits = units;
    }

    // setup global settings
    _applyGlobalSettings([material]);

    // add to runtime lib
    //TODO: add support for removing them...
    //FIXME: put this into MaterialLibrary?!
    _shaderInstance[shaderName] = _shaderInstance[shaderName] || {
        shader,
        variants: []
    };

    // set variant
    material.__redVariant = variant;

    // generate material defines for variant support
    generateShaderDefines(shader, material, variant, mesh);

    // add new variant
    _shaderInstance[shaderName].variants.push({
        variant: variant,
        runtimeShader: material
    });

    // add to runtime lib
    _runtimeShaders.push(material);

    // material needs recompile
    material.needsUpdate = true;

    // try to compile
    if(compile) {
        _compileShaders();
    }

    return material;
}

/**
 * create a shader material for THREE.JS
 * this does not do any caching/reusing and is just a basic implementation
 * @param shaderName shader name (optional when in template)
 * @param template material template definition
 * @param name optional shader name (defaults to template name)
 * @return three.js ShaderMaterial
 */
function createMaterialShader(name:string, template:MaterialTemplate, customDefines?:{}) : any {
    console.assert(template, "no template");
    console.assert(template.shader, "no template shader");

    // make sure valid name is given
    const shader:Shader = CustomShaderLib[template.shader];

    if(!shader) {
        console.warn("ShaderLibrary: invalid shader name " + template.shader);
        return null;
    }

    // defaults
    const defines = customDefines || {};

    const uniformVariables:any = cloneUniforms(shader.uniforms);

    const lighting:boolean = shader.redSettings ? shader.redSettings.lights === true : false;
    const fog:boolean = shader.redSettings ? shader.redSettings.fog === true : _useFog;
    const skinning:boolean = shader.redSettings ? shader.redSettings.skinning === true : false;
    const wireframe:boolean = shader.redSettings ? shader.redSettings.wireframe === true : false;

    const depthTest = shader.redSettings && shader.redSettings.depthTest !== undefined ? shader.redSettings.depthTest === true : true;
    const depthWrite = shader.redSettings && shader.redSettings.depthWrite !== undefined ? shader.redSettings.depthWrite === true : true;

    const blending = shader.redSettings.blending ? shader.redSettings.blending : "none";
    const transparent = blending === "none" ? false : true;
    const premultipliedAlpha = shader.redSettings && shader.redSettings.premultipliedAlpha !== undefined ? shader.redSettings.premultipliedAlpha === true : true;

    //TODO: add depth and stencil functions

    const isRawMaterial:boolean = shader.redSettings ? shader.redSettings.isRawMaterial === true : false;
    const variant = "default";

    // find shader code
    let vertexCode = null;
    if(typeof shader.vertexShader === 'string') {
        vertexCode = shader.vertexShader;
    } else {
        vertexCode = shader.vertexShader[variant];
    }
    if(!vertexCode) {
        console.error("missing shader for variant: " + variant);
    }

    let pixelCode = null;
    if(typeof shader.fragmentShader === 'string') {
        pixelCode = shader.fragmentShader;
    } else {
        pixelCode = shader.fragmentShader[variant];
    }
    if(!pixelCode) {
        console.error("missing shader for variant: " + variant);
    }

    let material;
    if(!isRawMaterial) {
        console.warn("ShaderLibrary: using old shaders.... " + template.shader);

        material = new ShaderMaterial({
            fragmentShader: pixelCode,
            vertexShader: vertexCode,
            uniforms: uniformVariables,
            defines: defines,
            lights: lighting,
            fog: fog,
            skinning: skinning,
            wireframe: wireframe,
            depthTest: depthTest,
            depthWrite: depthWrite,
            transparent: transparent,
            premultipliedAlpha: premultipliedAlpha
        });
    } else {
        material = new RedMaterial({
            fragmentShader:pixelCode,
            vertexShader:vertexCode,
            uniforms: uniformVariables,
            defines: defines,
            lights: lighting,
            fog: fog,
            skinning: skinning,
            wireframe: wireframe,
            depthTest: depthTest,
            depthWrite: depthWrite,
            transparent: transparent,
            premultipliedAlpha: premultipliedAlpha
        });
    }

    // assign red shader desc
    material.__redShader = shader;
    // assign red load identificator
    material._redLoadCounter = 0;
    material._redVersion = 0;
    // write custom data
    material.shaderType = template.shader;

    // assign compile callback
    if(shader.onCompile) {
        material.onBeforeCompile = shader.onCompile;
    }

    // setup precision
    if(_useShaderPrecision) {
        if(RenderQuality.qualityLevel === RenderQuality.HighQuality) {
            material.precision = "highp";
        } else if(RenderQuality.qualityLevel === RenderQuality.MediumQuality) {
            material.precision = "mediump";
        } else {
            material.precision = "lowp";
        }
    }

    //setup derivatives
    const derivatives:boolean = shader.redSettings ? shader.redSettings.derivatives === true : false;
    material.extensions.derivatives = derivatives;

    const shaderLOD:boolean = shader.redSettings ? shader.redSettings.shaderTextureLOD === true : false;
    material.extensions.shaderTextureLOD = shaderLOD;

    // blending
    if(material.transparent === true) {
        if(blending === "normal") {
            material.blending = NormalBlending;
        } else if (blending === "additive") {
            material.blending = AdditiveBlending;
        } else if (blending === "multiply") {
            material.blending = MultiplyBlending;
        } else {
            console.error("Invalid state: transparent material cannot have blending set to none");
        }
    } else {
        //no blending
        material.blending = NoBlending;
    }

    material.name = name + "_instance";
    material.__redName = name;

    if(template) {

        transferMaterialVariables(material, template, defines);
    } else {
        //FIXME: init default values???

        // apply material defines
        material.defines = generateMaterialDefines(template, null);
    }

    _runtimeMaterials.push(material);

    return material;
}

/**
 * find a runtime material by name
 * @return three.js shader instance
 */
function findRuntimeShader(name:string, mesh?:Mesh|Line, variant:ShaderVariant = ShaderVariant.DEFAULT) : any {

    // try to find shader through selection
    if(ShaderSelect[name]) {
        name = ShaderSelect[name](variant) || name;
    }
    let shaderRuntime = null;

    if(_shaderInstance[name]) {

        if(mesh) {
            variant = variant | mesh.meshVariant;
        }

        // default variant
        shaderRuntime = _shaderInstance[name].variants.find( (v) => {
            return v.variant === variant;
        });
    }

    if(shaderRuntime) {
        return shaderRuntime.runtimeShader;
    }

    return null;
}

/**
 * find or create shader from library
 * may hurt performance when needs to compile shader
 * @param name
 * @param mesh
 * @param defaultVariant
 */
function findOrCreateShader(name:string, mesh?:Mesh|Line, defaultVariant:ShaderVariant = ShaderVariant.DEFAULT) {
    // try to find shader through selection
    if(ShaderSelect[name]) {
        name = ShaderSelect[name](defaultVariant) || name;
    }

    const shaderInstance = _shaderInstance[name];

    if(shaderInstance) {
        if(mesh) {
            defaultVariant = defaultVariant | mesh.meshVariant;
        }

        // find variant
        for(let i = 0; i < shaderInstance.variants.length; ++i) {
            if(shaderInstance.variants[i].variant === defaultVariant) {
                return shaderInstance.variants[i].runtimeShader;
            }
        }
    }

    // check if variant is supported by shader
    if(defaultVariant !== ShaderVariant.DEFAULT) {
        // make sure valid name is given
        const shader:Shader = CustomShaderLib[name];
        if(shader.variants) {
            const variantSupport = shader.variants.indexOf(defaultVariant);
            if(variantSupport === -1) {
                return null;
            }
        } else {
            //FIXME: allow variant support?!
        }
    }

    if(build.Options.development) {
        console.info(`Runtime compiling of shader '${name} may hurt performance`);
    }
    return createShader(name, mesh, defaultVariant, true);
}

/**
 * does a hot reload on every material
 * (recompile and parameter update)
 */
function hotReload() {

    flushGPUMemory();

    // set shadow quality
    switch(RenderQuality.qualityLevel) {
        case RenderQuality.HighQuality:
        _shadowQuality = EShadowQuality.HIGH;
        setGlobalDefine("RED_SHADOW_QUALITY", 2, undefined, false);
        break;
        case RenderQuality.MediumQuality:
        _shadowQuality = EShadowQuality.MEDIUM;
        setGlobalDefine("RED_SHADOW_QUALITY", 1, undefined, false);
        break;
        case RenderQuality.LowQuality:
        _shadowQuality = EShadowQuality.LOW;
        setGlobalDefine("RED_SHADOW_QUALITY", 0, undefined, false);
        break;
        default:
        _shadowQuality = EShadowQuality.MEDIUM;
        setGlobalDefine("RED_SHADOW_QUALITY", 1, undefined, false);
        break;
    }

    // update shader code
    for(let i = 0; i < _runtimeShaders.length; ++i) {

        //FIXME: remove defines for all? check envMap for filtered?!
        if(!_usePrefilteredProbes) {
            if(_runtimeShaders[i].defines['RED_FILTERED_REFLECTIONPROBE'] !== undefined) {
                delete _runtimeShaders[i].defines['RED_FILTERED_REFLECTIONPROBE'];
            }
        }

        // shadow quality level change
        if(_runtimeShaders[i].defines['RED_SHADOW_QUALITY'] !== undefined) {
            _runtimeShaders[i].defines['RED_SHADOW_QUALITY'] = _shadowQuality;
        }

        // reset area lights defines
        if(_runtimeShaders[i].defines['RED_LIGHTS_SPHERE_COUNT'] !== undefined) {
            _runtimeShaders[i].defines['RED_LIGHTS_SPHERE_COUNT'] = 0;
        }

        if(_runtimeShaders[i].defines['RED_LIGHTS_TUBE_COUNT'] !== undefined) {
            _runtimeShaders[i].defines['RED_LIGHTS_TUBE_COUNT'] = 0;
        }

        if(_runtimeShaders[i].defines['RED_LIGHTS_DIRECTIONAL_COUNT'] !== undefined) {
            _runtimeShaders[i].defines['RED_LIGHTS_DIRECTIONAL_COUNT'] = 0;
        }

        _runtimeShaders[i].needsUpdate = true;
    }

    // force recompile of all shaders
    for(const shaderKey in _shaderInstance) {
        const shader = _shaderInstance[shaderKey];

        for(const variant of shader.variants) {
            // recreate defines
            const updated = generateShaderDefines(shader.shader, variant.runtimeShader, variant.variant, undefined);

            // apply compile
            variant.runtimeShader.needsUpdate = updated || variant.runtimeShader.needsUpdate;
        }
    }

    // recompile all materials
    _compileShaders();

    // notify for an hot reload
    OnHotReload.trigger();
}

/**
 * DEPRECATED
 * set global environment spherical harmonics lighting
 * @param sh sh data
 */
function setGlobalSHLighting_DEPRECATED(sh:any) {
    if(sh) {
        // compact form
        setGlobalParameter("cAr", new Vector4(sh.cAr[0],sh.cAr[1],sh.cAr[2],sh.cAr[3]), EUniformType.VECTOR4);
        setGlobalParameter("cAg", new Vector4(sh.cAg[0],sh.cAg[1],sh.cAg[2],sh.cAg[3]), EUniformType.VECTOR4);
        setGlobalParameter("cAb", new Vector4(sh.cAb[0],sh.cAb[1],sh.cAb[2],sh.cAb[3]), EUniformType.VECTOR4);

        setGlobalParameter("cBr", new Vector4(sh.cBr[0],sh.cBr[1],sh.cBr[2],sh.cBr[3]), EUniformType.VECTOR4);
        setGlobalParameter("cBg", new Vector4(sh.cBg[0],sh.cBg[1],sh.cBg[2],sh.cBg[3]), EUniformType.VECTOR4);
        setGlobalParameter("cBb", new Vector4(sh.cBb[0],sh.cBb[1],sh.cBb[2],sh.cBb[3]), EUniformType.VECTOR4);

        setGlobalParameter("cC", new Vector4(sh.cC[0],sh.cC[1],sh.cC[2],sh.cC[3]), EUniformType.VECTOR4);
    } else {
        // compact form
        setGlobalParameter("cAr", new Vector4(0,0,0,0), EUniformType.VECTOR4);
        setGlobalParameter("cAg", new Vector4(0,0,0,0), EUniformType.VECTOR4);
        setGlobalParameter("cAb", new Vector4(0,0,0,0), EUniformType.VECTOR4);

        setGlobalParameter("cBr", new Vector4(0,0,0,0), EUniformType.VECTOR4);
        setGlobalParameter("cBg", new Vector4(0,0,0,0), EUniformType.VECTOR4);
        setGlobalParameter("cBb", new Vector4(0,0,0,0), EUniformType.VECTOR4);

        setGlobalParameter("cC", new Vector4(0,0,0,0), EUniformType.VECTOR4);
    }

    // recompile all materials
    _compileShaders();
}

/**
 * set global parameter
 * @param name parameter name
 * @param value value
 * @param type uniform type
 */
function setGlobalParameter(name:string, value:any, type:EUniformType) {

    if(_globalParameters[name]) {
        if(_globalParameters[name].type !== type) {
            console.warn(`ShaderLibrary: overwriting global parameter with different type ${_globalParameters[name].type} != ${type}`);
        }
    }

    // init new object if not set
    _globalParameters[name] = _globalParameters[name] || {
        value: value,
        type: type,
        default: value
    };

    // set value
    _globalParameters[name].value = value;
}

/**
 * set global parameter using a uniform
 */
function setGlobalParameterUniform(name:string, uniform:Uniform) {
    if(_globalParameters[name]) {
        if(_globalParameters[name].type !== uniform.type) {
            console.warn(`ShaderLibrary: overwriting global parameter with different type ${_globalParameters[name].type} != ${uniform.type}`);
        }
    }

    // init new object if not set
    _globalParameters[name] = _globalParameters[name] || {
        value: uniform.value,
        type: uniform.type,
        default: uniform.default,
        properties: uniform.properties
    };

    // set value
    _globalParameters[name].value = uniform.value;
    _globalParameters[name].default = uniform.default;
    _globalParameters[name].properties = uniform.properties;
}
/**
 * get uniform value
 * @param name parameter name
 */
function getGlobalParameter(name:string) : Uniform | undefined {
    if(_globalParameters[name]) {
        return _globalParameters[name];
    }
    return undefined;
}

/**
 * get value
 * @param name parameter name
 * @param type optional type check
 */
function getGlobalParameterValue(name:string, type?:EUniformType) : any | undefined {
    if(_globalParameters[name]) {
        if(type && type === _globalParameters[name].type) {
            return _globalParameters[name].value;
        } else if(!type) {
            return _globalParameters[name].value;
        }
    }
    return undefined;
}

/**
 * set global define
 * @param name define name
 * @param value define value
 */
function setGlobalDefine(name:string, value:string | number | boolean, predicate?:(material:any) => boolean, compile?:boolean) {
    // convert to number
    if(value === true || value === false) {
        value = value ? 1 : 0;
    }

    _globalDefines[name] = _globalDefines[name] || { value: null, predicate: null };
    _globalDefines[name].value = value;
    _globalDefines[name].predicate = predicate || GlobalDefine_defaultPredicate;

    // apply to shaders
    _applyGlobalDefines();

    // recompile all materials
    if(compile !== false) {
        _compileShaders();
    }
}

/**
 * remove a global define on shader
 * @param name
 */
function removeGlobalDefine(name, compile?:boolean) {
    if(_globalDefines[name] !== undefined) {
        delete _globalDefines[name];
    }

    for(const shader of _runtimeShaders) {
        if(shader.defines[name]) {
            delete shader.defines[name];
            shader.needsUpdate = true;
        }
    }

    // recompile all materials
    if(compile !== false) {
        _compileShaders();
    }
}

/**
 * check if define is set
 * @param name define name
 */
function isGlobalDefineSet(name:string) : boolean {
    return _globalDefines[name] !== undefined;
}

/**
 * return the value of global define
 * @param name define name
 */
function getGlobalDefine(name:string) : string | number | boolean | undefined {
    return _globalDefines[name].value;
}

/**
 * notify shader about light update
 * @param lightData current global light data
 */
function updateBuiltinLights(lightData:LightDataGlobal) {
    // setup shader defines for new light setup
    if(lightData.shadowCount) {
        setGlobalDefine("RED_USE_SHADOWMAP", 1, GlobalDefine_lightPredicate);
    } else {
        removeGlobalDefine("RED_USE_SHADOWMAP");
        //setGlobalDefine("RED_USE_SHADOWMAP", 0, GlobalDefine_lightPredicate);
    }

    // update materials
    _compileShaders();
}

/**
 * update lights at shaders
 * @param lights red lights
 */
function updateLights(lights:LightData[]) {
    let needsRecompile = false;

    // get uniform buffers (global) and reset
    const uniforms = _builtinUniforms["redLights"];

    const sphereLights = uniforms['sphereLights'];
    let sphereLightsCount = 0;

    const tubeLights = uniforms['tubeLights'];
    let tubeLightsCount = 0;

    const iesLights = uniforms['iesLights'];
    let iesLightsCount = 0;

    const redDirectionalLights = uniforms['directionalRedLights'];
    const redDirectionalShadowMaps = uniforms['directionalRedShadowMap'];
    const redDirectionalShadowMatrices = uniforms['directionalRedShadowMatrix'];

    let redDirectionalLightsCount = 0;

    // copy light data to uniform buffers
    for(const light of lights) {

        if(light.type === ELightType.Sphere && _useAreaLights) {

            const sphereLight = light.data as SphereLightUniformData;

            // make sure this is initialized
            if(!sphereLights.value[sphereLightsCount]) {
                sphereLights.value[sphereLightsCount] = {
                    position: new Vector3(),
                    color: new Vector3()
                };
            }

            const uniformValue = sphereLights.value[sphereLightsCount] as SphereLightUniformData;

            //FIXME: copy?
            uniformValue.position.copy(sphereLight.position);
            uniformValue.color = sphereLight.color;
            uniformValue.decay = sphereLight.decay;
            uniformValue.distance = sphereLight.distance;
            uniformValue.radius = sphereLight.radius;

            sphereLightsCount++;

        } else if(light.type === ELightType.Tube && _useAreaLights) {

            const tubeLight = light.data as TubeLightUniformData;

            // make sure this is initialized
            if(!tubeLights.value[tubeLightsCount]) {
                tubeLights.value[tubeLightsCount] = {
                    position: new Vector3(),
                    color: new Vector3(),
                    lightAxis: new Vector3()
                };
            }

            const uniformValue = tubeLights.value[tubeLightsCount] as TubeLightUniformData;

            uniformValue.position.copy(tubeLight.position);
            uniformValue.color = tubeLight.color;
            uniformValue.lightAxis.copy(tubeLight.lightAxis);
            uniformValue.decay = tubeLight.decay;
            uniformValue.distance = tubeLight.distance;
            uniformValue.radius = tubeLight.radius;
            uniformValue.size = tubeLight.size;

            tubeLightsCount++;
        } else if(light.type === ELightType.IESLight) {
            const iesLight = light.data as IESLightUniformData;

            // make sure this is initialized
            if(!iesLights.value[iesLightsCount]) {
                iesLights.value[iesLightsCount] = {
                    position: new Vector3(),
                    color: new Vector3()
                };
            }

            const uniformValue = iesLights.value[iesLightsCount] as IESLightUniformData;

            uniformValue.position.copy(iesLight.position);
            uniformValue.color = iesLight.color;
            uniformValue.decay = iesLight.decay;
            uniformValue.distance = iesLight.distance;

            iesLightsCount++;
        } else if(light.type === ELightType.RedDirectional) {

            const redDirectionalLight = light.data as RedDirectionalUniformData;

            // make sure this is initialized
            if(!redDirectionalLights.value[redDirectionalLightsCount]) {
                redDirectionalLights.value[redDirectionalLightsCount] = {
                    direction: new Vector3(),
                    color: new Color(),
                    shadow: false,
                    shadowMapSize: new Vector2(),
                    shadowBias: 0.0,
                    shadowRadius: 0.0
                };
            }

            const uniformValue = redDirectionalLights.value[redDirectionalLightsCount] as RedDirectionalUniformData;

            uniformValue.direction.copy(redDirectionalLight.direction);
            uniformValue.color = redDirectionalLight.color;
            uniformValue.shadow = redDirectionalLight.shadow;
            uniformValue.shadowMapSize.copy(redDirectionalLight.shadowMapSize);
            uniformValue.shadowBias = redDirectionalLight.shadowBias;
            uniformValue.shadowRadius = redDirectionalLight.shadowRadius;

            // always use shadow map
            redDirectionalShadowMaps.value[redDirectionalLightsCount] = redDirectionalLight.shadowMap;
            redDirectionalShadowMatrices.value[redDirectionalLightsCount] = redDirectionalLight.shadowMatrix;

            redDirectionalLightsCount++;
        }
    }

    // write new length
    sphereLights.value.length = sphereLightsCount;
    tubeLights.value.length = tubeLightsCount;
    iesLights.value.length = iesLightsCount;
    redDirectionalLights.value.length = redDirectionalLightsCount;

    // make sure internal type is set
    sphereLights.type = EUniformType.STRUCT;
    tubeLights.type = EUniformType.STRUCT;
    iesLights.type = EUniformType.STRUCT;
    redDirectionalLights.type = EUniformType.STRUCT;

    // update global parameters
    setGlobalParameterUniform("sphereLights", sphereLights);
    setGlobalParameterUniform("tubeLights", tubeLights);
    setGlobalParameterUniform("iesLights", iesLights);
    setGlobalParameterUniform("directionalRedLights", redDirectionalLights);
    setGlobalParameterUniform("directionalRedShadowMap", redDirectionalShadowMaps);
    setGlobalParameterUniform("directionalRedShadowMatrix", redDirectionalShadowMatrices);

    // apply to all materials that use redLights
    for(const material of _runtimeShaders) {
        let usesLights:boolean = false;
        // support for custom lights?
        if(material.uniforms && material.uniforms['sphereLights']) {
            usesLights = true;
        } else if(material.uniforms && material.uniforms['tubeLights']) {
            usesLights = true;
        } else if(material.uniforms && material.uniforms['iesLights']) {
            usesLights = true;
        } else if(material.uniforms && material.uniforms['directionalRedLights']) {
            usesLights = true;
        }

        // material supports redLights
        if(usesLights) {

            // reset flag and check for light count update
            usesLights = false;

            if(material.defines['RED_LIGHTS_SPHERE_COUNT'] === undefined || material.defines['RED_LIGHTS_SPHERE_COUNT'] !== sphereLightsCount) {
                usesLights = true;
            }
            if(!material.defines['RED_LIGHTS_TUBE_COUNT'] === undefined || material.defines['RED_LIGHTS_TUBE_COUNT'] !== tubeLightsCount) {
                usesLights = true;
            }
            if(!material.defines['RED_LIGHTS_IES_COUNT'] === undefined || material.defines['RED_LIGHTS_IES_COUNT'] !== iesLightsCount) {
                usesLights = true;
            }
            if(!material.defines['RED_LIGHTS_DIRECTIONAL_COUNT'] === undefined || material.defines['RED_LIGHTS_DIRECTIONAL_COUNT'] !== redDirectionalLightsCount) {
                usesLights = true;
            }

            // only update whole material when
            // count has changed
            if(usesLights) {

                // update defines
                material.defines = mergeObject(material.defines, {
                    RED_LIGHTS_SPHERE_COUNT: sphereLightsCount,
                    RED_LIGHTS_TUBE_COUNT: tubeLightsCount,
                    RED_LIGHTS_IES_COUNT: iesLightsCount,
                    RED_LIGHTS_DIRECTIONAL_COUNT: redDirectionalLightsCount
                });

                material.needsUpdate = true;
                needsRecompile = true;
            }
        }
    }

    // recompile all materials
    if(needsRecompile) {
        _compileShaders();
    }
}

/**
 * transfer uniform variables from one material to another
 * taking into account which variables are support
 * also supports THREE.JS uniform variables ({type: EUniformType.FLOAT, value: 1.0})
 * and custom format
 * @param material THREE.js Material Shader
 * @param template shader decription
 * @param defines optional defines
 * @param geometry optional geometry object
 * @return asyncload that resolves when material is fully loaded (textures etc.)
 */
function transferMaterialVariables(material:any, template:MaterialTemplate, defines?:{}, geometry?:any) : AsyncLoad<void> {
    return new AsyncLoad<void>( (resolve, reject) => {
        defines = defines || {};

        const shader:Shader = material.__redShader;

        //FIXME: validate template??
        if(!shader || material.uniforms === undefined) {
            console.error("ShaderLibrary: transfering to wrong material", material);
            reject("ShaderLibrary: transfering to wrong material " + template.name);
            return;
        }

        // start loading
        if(material._redLoadCounter === undefined || material._redLoadCounter < 0 || material._redVersion === undefined) {
            console.warn(`ShaderLibrary::transferMaterialVariables: transfering data to material '${material.__redName}' that has been wrong initialized`);
            material._redLoadCounter = 0;
            material._redVersion = 0;
        }

        // set new version
        material.setLoading();
        material.updateVersion();

        // save current version
        const currentMaterialVersion = material._redVersion;

        // try to set uniforms from parameters
        const uniforms = material.uniforms as Uniforms;

        for(const uniformName in uniforms) {
            const uniform:Uniform = uniforms[uniformName];

            // no template data, check if not builtin
            // when builtin we use the defaultValue assigned after this
            // when no builtin we have no data and assign the default value from shader
            if(template[uniformName] === undefined) {

                if(!_isBuiltinUniform(uniformName)) {

                    if(!shader.uniforms[uniformName]) {
                        console.warn("ShaderLibrary: no uniform value for '" + uniformName + "'.", material);
                        continue;
                    }
                    // copy uniform init value
                    uniform.value = cloneUniformValue(shader.uniforms[uniformName].value);

                    // check for default value
                    uniform.value = uniform.value || uniform.default;

                    // global parameters overwrite local settings...
                    if(_globalParameters[uniformName] && _globalParameters[uniformName].type === uniform.type) {
                        uniform.value = _globalParameters[uniformName].value;
                    }

                    // if(material[uniformName]) {
                    //     material[uniformName] = uniform.value;
                    // } else if(uniform.value !== undefined && uniform.value !== null) {
                    //     material[uniformName] = uniform.value;
                    // }
                    continue;
                }
            }

            // get default value (global scope or uniform value)
            let defaultValue = uniform.value || uniform.default;

            // okay here is the thing: template data can result to null for specific objects
            // but the uniform.value could be set before (like when switchMaterialGroup happens)
            // so a value of "null" should replace this. but uniform.default will win always here
            if(template[uniformName] !== undefined) {
                defaultValue = uniform.default || template[uniformName];
            }

            // global parameters overwrite local settings...
            if(_globalParameters[uniformName] && _globalParameters[uniformName].type === uniform.type) {
                defaultValue = _globalParameters[uniformName].value;
            }

            if(uniform.type === EUniformType.TEXTURE) {

                // continue for environment maps, these are handled explicit
                if(uniformName === "envMap") {
                    continue;
                }

                material.transferVariable(uniformName, template[uniformName]).then( () => {
                    material.needsUpdate = true;
                });
            } else {
                material.transferVariable(uniformName, template[uniformName]).then( () => {
                    material.needsUpdate = true;
                });
            }
        }

        // allow transparency when required and no shadow rendering pass
        //FIXME: check settings on shader too???
        const transparent:boolean = (template['transparent']) && !material._shadowPass;

        if(!transparent && template['transparent']) {
            console.warn("Shader: cannot apply transparency to shader ", material);
        }
        // set transparency boolean
        material.transparent = transparent !== undefined ? transparent : false;

        //TODO: add support for depth and stencil states

        //TODO: replace with material fog settings
        if(_useFog && !material._shadowPass) {
            material.fog = true;
        }

        //TODO: geometry based defines are not handled here every time
        //      so 1) save geometry defines in shader
        //      so 2) force transferMaterialVariables to get a geometry
        //      so 3)
        const shaderDefines = generateMaterialDefines(template, geometry);

        //FIXME: update defines here??
        material.defines = mergeObject(shaderDefines, defines);

        // apply fixed function stuff
        if(template.fixedFunction) {
            //TODO: add support for front, back, double sided rendering
            const doubleSided = template.fixedFunction.doubleSided || false;

            if(doubleSided === true) {
                material.side = DoubleSide;
            }
        }

        // handles the part when nothing changed
        material.finishLoading((mat) => {
            mat.needsUpdate = true;
            resolve();
        });
    });
}

/**
 * generates defines from shader
 * use these defines for compiling
 * @param shaderName shader name
 * @return define object
 */
function generateShaderDefines(shader:Shader, instance:RedMaterial, variant?:ShaderVariant, mesh?:Mesh|Line) : boolean {
    // uniform parameters
    const settings:ShaderSettings = shader.redSettings;

    // custom created defines
    let define:any = { };

    // quality level
    switch(RenderQuality.qualityLevel) {
        case RenderQuality.HighQuality:
            define.LOW_QUALITY = 0;
            define.MEDIUM_QUALITY = 0;
            define.HIGH_QUALITY = 1;
            break;
        case RenderQuality.MediumQuality:
            define.LOW_QUALITY = 0;
            define.MEDIUM_QUALITY = 1;
            define.HIGH_QUALITY = 0;
            break;
        case RenderQuality.LowQuality:
            define.LOW_QUALITY = 1;
            define.MEDIUM_QUALITY = 0;
            define.HIGH_QUALITY = 0;
            break;
        default:
            define.LOW_QUALITY = 0;
            define.MEDIUM_QUALITY = 0;
            define.HIGH_QUALITY = 1;
            break;
    }

    // generate defines from shader code
    if(shader) {
        // evaluate defines for variant
        if(shader.evaluateDefines && typeof shader.evaluateDefines === "function") {
            //TODO: redefine function for this...
            define = mergeObject(define, shader.evaluateDefines(variant, mesh));
        } else if(shader.evaluateDefines && typeof shader.evaluateDefines === "object") {
            if(variant) {
                define = mergeObject(define, shader.evaluateDefines[variant]);
            } else {
                define = mergeObject(define, shader.evaluateDefines);
            }
        }
    }

    // add lights value
    if(settings && settings.lights) {
        define.RED_USE_LIGHTS = 1;

        // get uniform buffers (global) and reset
        const uniforms = _builtinUniforms["redLights"];
        const redDirectionalLights = uniforms['directionalRedLights'].value;
        const sphereLights = uniforms['sphereLights'].value;
        const tubeLights = uniforms['tubeLights'].value;
        const iesLights = uniforms['iesLights'].value;

        // update defines
        define.RED_LIGHTS_DIRECTIONAL_COUNT = redDirectionalLights.length || 0;
        define.RED_LIGHTS_SPHERE_COUNT = sphereLights.length || 0;
        define.RED_LIGHTS_TUBE_COUNT = tubeLights.length || 0;
        define.RED_LIGHTS_IES_COUNT = iesLights.length || 0;
    }

    if(settings.isRawMaterial) {
        // setup render capabilities
        if(Render.Main) {
            if(Render.Main.capabilities.textureLOD) {
                define.TEXTURE_LOD_EXT = 1;
            }
        }
    }

    // compare defines
    let sameDefines = true;

    // compare new defines against old defines
    for(const key in define) {
        if(instance.defines[key] !== define[key]) {
            sameDefines = false;
        }
    }
    // check if old defines have any missing ones in new defines
    for(const key in instance.defines) {
        if(define[key] === undefined) {
            sameDefines = false;
        }
    }

    // apply custom defines
    instance.defines = define;

    // apply global defines
    sameDefines = _applyGlobalDefines(instance) || sameDefines;

    return sameDefines;
}

/**
 * generates material defines from material template
 * use these defines for shader compiling
 * @return define object
 */
function generateMaterialDefines(template:MaterialTemplate, geometry:any) : {} {
    console.assert(template.shader, "missing template shader");

    // uniform parameters
    const shader:Shader = CustomShaderLib[template.shader];
    const settings:ShaderSettings = shader.redSettings;

    //
    const define:any = { };

    // quality level
    switch(RenderQuality.qualityLevel) {
        case RenderQuality.HighQuality:
            define.LOW_QUALITY = 0;
            define.MEDIUM_QUALITY = 0;
            define.HIGH_QUALITY = 1;
            break;
        case RenderQuality.MediumQuality:
            define.LOW_QUALITY = 0;
            define.MEDIUM_QUALITY = 1;
            define.HIGH_QUALITY = 0;
            break;
        case RenderQuality.LowQuality:
            define.LOW_QUALITY = 1;
            define.MEDIUM_QUALITY = 0;
            define.HIGH_QUALITY = 0;
            break;
        default:
            define.LOW_QUALITY = 0;
            define.MEDIUM_QUALITY = 0;
            define.HIGH_QUALITY = 1;
            break;
    }

    // apply geometry stuff
    if(geometry) {
        // activate instancing on instanced buffer geometry
        if(geometry instanceof InstancedBufferGeometry) {
            define.USE_INSTANCING = true;
        }
    }

    // add lights value
    if(settings && settings.lights) {
        define.RED_USE_LIGHTS = 1;

        // get uniform buffers (global) and reset
        const uniforms = _builtinUniforms["redLights"];
        const redDirectionalLights = uniforms['directionalRedLights'].value;
        const sphereLights = uniforms['sphereLights'].value;
        const tubeLights = uniforms['tubeLights'].value;
        const iesLights = uniforms['iesLights'].value;

        // update defines
        define.RED_LIGHTS_DIRECTIONAL_COUNT = redDirectionalLights.length || 0;
        define.RED_LIGHTS_SPHERE_COUNT = sphereLights.length || 0;
        define.RED_LIGHTS_TUBE_COUNT = tubeLights.length || 0;
        define.RED_LIGHTS_IES_COUNT = iesLights.length || 0;
    }

    if(template.transparent === true) {
        define.RED_TRANSPARENT = true;
    }

    // apply global sh lighting
    if(_useSHLighting) {
        define.RED_SH_LIGHTING = 1;
    }

    if(settings.isRawMaterial) {

        // no local setup here
        define['ENVMAP_TYPE_CUBE'] = 1;

        if(Render.Main) {
            if(Render.Main.capabilities.textureLOD) {
                define.TEXTURE_LOD_EXT = true;
            }
        }
    }

    // apply env map settings
    if(_usePrefilteredProbes) {
        define.RED_FILTERED_REFLECTIONPROBE = 1;
    }

    // no local setup here
    define.RED_ENVMAP_BOX_PROJECTED = _useParallaxCubemap ? 1:0;

    // apply global defines
    for(const globalDefine in _globalDefines) {
        if(!define[globalDefine]) {
            define[globalDefine] = _globalDefines[globalDefine];
        }
    }

    //define.SHADOWMAP_DEBUG = true;
    return define;
}

/**
 * load a shader (resolves includes etc)
 * automatically adds it into the chunk list
 */
function _loadShader(name:string) : AsyncLoad<string> {
    return loadShader(name);
}

/**
 * load a shader chunk
 * automatically adds it into the chunk list
 */
// function loadShaderChunk(name:string) : AsyncLoad<string> {
//     return new AsyncLoad<string>( (resolve, reject) => {

//         // FIXME: chunks is already loaded?
//         if(ShaderChunk[name]) {
//             resolve(ShaderChunk[name]);
//             return;
//         }

//         // construct path
//         let path = build.Options.shaderLibrary.basePath;

//         if(path) {
//             path = path + name + ".glsl";
//         } else {
//             path = name + ".glsl";
//         }

//         AssetManager.loadText(path).then((text:string) => {

//             //TODO: check text for correctness...
//             if(text) {
//                 ShaderChunk[name] = text;
//             }
//             resolve(text);
//         },
//         reject);
//     });
// }

/** replication */
function save() {

    return {
        ShaderChunk: ShaderChunk,
        CustomShaderLib: CustomShaderLib,
        //runtimeMaterials: this._runtimeMaterials
    };
}

/**
 * preload shader bundle file
 * @param bundleFile bundle file
 */
function _preloadShaderBundle(bundleFile?:string) : AsyncLoad<void> {
    // default bundle file
    bundleFile = bundleFile || "shader.json";

    return new AsyncLoad<void>( (resolve, reject) => {
        AssetManager.loadText(bundleFile).then((text:string) => {

            try {
                const shaders = JSON.parse(text);

                for(const chunk in shaders) {
                    //TODO: check text for correctness...
                    if(shaders[chunk]) {
                        ShaderChunk[chunk] = shaders[chunk];
                    }
                }

                resolve();

            } catch(err) {
                reject(err);
            }
        },
        reject);
    });
}

/**
 * precompile shaders
 */
function _precreateShaders() {
    for(const shd in CustomShaderLib) {
        const shader = CustomShaderLib[shd];

        if(shader.redSettings && shader.redSettings.instantiate === true) {
            if(shader.variants) {
                for(const variant of shader.variants) {
                    createShader(shd, undefined, variant, false);
                }
            } else {
                createShader(shd, undefined, undefined, false);
            }

        }
    }
}

/**
 * is builtin uniform data
 * @param name uniform data name
 */
function _isBuiltinUniform(name:string) : boolean {
    for(const builin in _builtinUniforms) {
        if(_builtinUniforms[builin][name]) {
            return true;
        }
    }
    return false;
}

/**
 * print debug information about shader instances
 */
export function printRuntimeShaders() {
    console.info("ShaderLibrary: runtime shaders");
    for(const shader in _shaderInstance) {
        console.info(` Shader(${shader}) @ ${_shaderInstance[shader].shader.redSettings.order}: with ${_shaderInstance[shader].variants.length} variants: `);
        for(const variant of _shaderInstance[shader].variants) {
            console.info(`  variant(${variant.variant}) has defines `, variant.runtimeShader.defines);
        }
    }
    console.info("ShaderLibrary: total runtime materials: " + _runtimeShaders.length);
    for(const material of _runtimeShaders) {
        console.info(` Material(${material.name}) variant ${material['__redVariant']} with sortID: ${material['_sortID']}`);
    }
    console.info("------------");
}

/**
 * apply global defines to instance or all instances
 * @param shaderInstance optional single instance
 */
function _applyGlobalDefines(shaderInstance?:any) : boolean {
    let changed = false;

    // if no shader is given, process all instances
    if(!shaderInstance) {
        for(const instance of _runtimeShaders) {
            changed = _applyGlobalDefines(instance) || changed;
        }
        return changed;
    }

    // apply global defines
    for(const define in _globalDefines) {
        // check if shader matches global define
        if(!_globalDefines[define].predicate(shaderInstance)) {
            //FIXME: always remove?!
            if(shaderInstance.defines[define]) {
                delete shaderInstance.defines[define];
            }
            shaderInstance.needsUpdate = true;
            changed = true;
            continue;
        }

        if(shaderInstance.defines[define] !== _globalDefines[define].value) {
            shaderInstance.defines[define] = _globalDefines[define].value;
            shaderInstance.needsUpdate = true;
            changed = true;
        }
    }
    return changed;
}

/**
 * apply global setup to materials
 */
function _applyGlobalSettings(source?:Material[]) {
    // no renderer available, skipping
    if(!_isLoaded || !Render.Main || !Render.Main.webGLRender) {
        return;
    }

    source = source || _runtimeShaders;

    for(const material of source) {
        // setup precision
        if(_useShaderPrecision) {
            if(RenderQuality.qualityLevel === RenderQuality.HighQuality) {
                material.precision = "highp";
            } else if(RenderQuality.qualityLevel === RenderQuality.MediumQuality) {
                material.precision = "mediump";
            } else {
                material.precision = "lowp";
            }
        }

        // shadow setup
        (material as any).shadowSide = _shadowSide;
    }
}

/**
 * compile all runtime shaders that needs an update
 */
function _compileShaders() {
    const self = _compilerData as any;
    // no renderer available, skipping
    if(!_isLoaded || !Render.Main || !Render.Main.webGLRender) {
        return;
    }
    // no pre-compiling
    if(!_compileShader) {
        return;
    }

    // compile scene description
    if(!self['_compileScene']) {
        const geometry = new BoxBufferGeometry(1,1,1);

        self['_compileScene'] = {
            scene: new Scene(),
            camera: new PerspectiveCamera(90, 1.0, 1.0, 1000.0),
            object: new THREEMesh(geometry, null)
        };

        self['_compileScene'].scene.add(self['_compileScene'].object);
    }
    //TODO: add fog support

    // update materials using proxy objects
    const scene = self['_compileScene'].scene;
    const camera = self['_compileScene'].camera;
    const object = self['_compileScene'].object;

    let recompiled: number = 0;
    for(const material of _runtimeShaders) {
        //FIXME: check for define update
        if(!material.needsUpdate) {
            continue;
        }

        // update defines
        const shader = material.__redShader;
        const variant = material.__redVariant;

        // generate material defines for variant support
        generateShaderDefines(shader, material, variant, undefined);

        // count
        recompiled++;
    }

    if(recompiled > 0) {
        recompiled = 0;
        devMarkTimelineStart("compile_shader");

        _runtimeShaders.sort( (a, b) => {
            const aOrder = a.__redOrder || 0;
            const bOrder = b.__redOrder || 0;
            if(aOrder > bOrder) {
                return -1;
            } else if(bOrder > aOrder) {
                return 1;
            }
            return 0;
        });

        // assign sort id
        let sortOrder = 0;
        let lastShader = null;
        let lastShaderOrder = 0;
        for(const material of _runtimeShaders) {
            const shader = material.__redShader || null;
            const shaderSort = material.__redOrder || 0;

            if(lastShaderOrder !== shaderSort || lastShader !== shader) {
                sortOrder++;
                lastShaderOrder = shaderSort;
                lastShader = shader;
            }

            material._sortID = sortOrder;
        }

        for(const material of _runtimeShaders) {
            if(!material.needsUpdate) {
                continue;
            }
            // set material
            object.material = material;
            // compile shader for now (no definition yet)
            (Render.Main.webGLRender as any).compile(scene, camera);

            // reset state
            material.needsUpdate = false;

            // count
            recompiled++;
        }

        devMarkTimelineEnd("compile_shader");
        console.info(`ShaderLibrary: compiling done ${recompiled} of ${_runtimeShaders.length}`);
    }
}

function _resolveShader(name:string, variant:ShaderVariant) : Shader {
    if(!ShaderLibrary.CustomShaderLib[name]) {
        console.error("no shader found with name " + name);
        return null;
    }

    //
    if(ShaderLibrary.CustomShaderLib[name].selector) {
        const resolved = ShaderLibrary.CustomShaderLib[name].selector(variant);

        if(resolved !== name) {
            return _resolveShader(name, variant);
        }
    }

    return ShaderLibrary.CustomShaderLib[name];
}
//}

export const ShaderLibrary:IShaderLibrary = {
    CustomShaderLib,
    DefaultShader,
    OnHotReload,
    ShaderSelect,
    init,
    findOrCreateShader,
    flush,
    flushGPUMemory,
    getGlobalDefine,
    isGlobalDefineSet,
    getGlobalParameter,
    getGlobalParameterValue,
    loadShader: _loadShader,
    printRuntimeShaders,
    save,
    setShadowQuality,
    setShadowSide,
    setUseAreaLights,
    setUseFog,
    setUseGammaCorrected,
    setUseParallaxCubemap,
    setUsePrefilteredProbes,
    setUseSHLighting,
    setUseShaderPrecision,
    shadowQuality,
    shadowSide,
    updateBuiltinLights,
    updateLights,
    useAreaLights,
    useFog,
    useGammaCorrected,
    useParallaxCubemap,
    usePrefilteredProbes,
    useSHLighting,
    useShaderPrecision,
    createShader,
    createMaterialShader,
    findRuntimeShader,
    hotReload,
    remapShader,
    removeGlobalDefine,
    setGlobalDefine,
    setGlobalParameter
};
registerAPI(SHADERLIBRARY_API, ShaderLibrary);

/**
 * fixes JSON export/import
 * @param shader runtime shader
 */
export function Shader_FixJSONImport(shader:Shader) {

    function fixUniforms(uniforms:Uniforms) {
        for(const u in uniforms) {
            const uniform = uniforms[u];

            if(uniform.type !== undefined) {

                if(uniform.type === EUniformType.COLOR) {
                    uniform.value = new Color(uniform.value);
                } else if(uniform.type === EUniformType.VECTOR2) {
                    uniform.value = new Vector2(uniform.value.x, uniform.value.y);
                } else if(uniform.type === EUniformType.VECTOR3) {
                    uniform.value = new Vector3(uniform.value.x, uniform.value.y, uniform.value.z);
                } else if(uniform.type === EUniformType.VECTOR4) {
                    uniform.value = new Vector4(uniform.value.x, uniform.value.y, uniform.value.z, uniform.value.w);
                } else if(uniform.type === EUniformType.VECTOR2_ARRAY) {
                    const newValues = [];
                    for(const value of uniform.value) {
                        newValues.push(new Vector2(value.x, value.y));
                    }
                    uniform.value = newValues;
                } else if(uniform.type === EUniformType.VECTOR3_ARRAY) {
                    const newValues = [];
                    for(const value of uniform.value) {
                        newValues.push(new Vector3(value.x, value.y, value.z));
                    }
                    uniform.value = newValues;
                } else if(uniform.type === EUniformType.VECTOR4_ARRAY) {
                    const newValues = [];
                    for(const value of uniform.value) {
                        newValues.push(new Vector4(value.x, value.y, value.z, value.w));
                    }
                    uniform.value = newValues;
                } else if(uniform.properties) {
                    //
                    uniform.properties = fixUniforms(uniform.properties);
                }

            }
        }
        return uniforms;
    }

    shader.uniforms = fixUniforms(shader.uniforms);

    return shader;
}

/**
 * default define predications
 */
function GlobalDefine_shPredicate(material:any) : boolean {
    const values = ["cAr","cAb","cAg","cBr","cBb","cBg","cC"];

    // find materials that use SH lighting
    let usesSH = false;
    for(const value of values) {
        if(material.uniforms && material.uniforms[value]) {
            usesSH = true;
            break;
        }
    }
    return usesSH;
}

function GlobalDefine_lightPredicate(material:any) : boolean {
    if(material.lights) {
        return true;
    }
    return false;
}

function GlobalDefine_envMapPredicate(material:any) : boolean {
    if(material.uniforms && material.uniforms["reflectionProbe"]) {
        return true;
    }
    return false;
}
