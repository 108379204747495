/**
 * Utils.ts: utility shader
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    shaderBuilder.importCode(["redPrecision", "redBlur_Vertex", "redBlur_h_Pixel", "redBlur_v_Pixel", "redPackedBlur_h_Pixel", "redPackedBlur_v_Pixel"]).then( () => {

        shaderBuilder.createShader("redHorizontalPackedBlurShader", {
            redSettings: {
                isRawMaterial: true,
                lights: false,
                fog: false
            },
            uniforms: {
                map: { type: EUniformType.TEXTURE, value: null },
                widthPixel: { type: EUniformType.FLOAT, value: 1.0 / 512.0 }
            },
            vertexShader: "redBlur_Vertex",
            fragmentShader: "redPackedBlur_h_Pixel"
        });

        shaderBuilder.createShader("redVerticalPackedBlurShader", {
            redSettings: {
                isRawMaterial: true,
                lights: false,
                fog: false
            },
            uniforms: {
                map: { type: EUniformType.TEXTURE, value: null },
                heightPixel: { type: EUniformType.FLOAT, value: 1.0 / 512.0 }
            },
            vertexShader: "redBlur_Vertex",
            fragmentShader: "redPackedBlur_v_Pixel"
        });

        shaderBuilder.createShader("redHorizontalBlurShader", {
            redSettings: {
                isRawMaterial: true,
                lights: false,
                fog: false
            },
            uniforms: {
                map: { type: EUniformType.TEXTURE, value: null },
                widthPixel: { type: EUniformType.FLOAT, value: 1.0 / 512.0 }
            },
            vertexShader: "redBlur_Vertex",
            fragmentShader: "redBlur_h_Pixel"
        });

        shaderBuilder.createShader("redVerticalBlurShader", {
            redSettings: {
                isRawMaterial: true,
                lights: false,
                fog: false
            },
            uniforms: {
                map: { type: EUniformType.TEXTURE, value: null },
                heightPixel: { type: EUniformType.FLOAT, value: 1.0 / 512.0 }
            },
            vertexShader: "redBlur_Vertex",
            fragmentShader: "redBlur_v_Pixel"
        });

    });
});
