///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class IndexComponent extends AbstractBlockComponent {

    private _list:any;
    private _toggleBtn:any;

    protected onInit():void{
        this._list = this._block.domElement.querySelector("ul");
        this._toggleBtn = this._block.domElement.querySelector(".index_toggle");

        this._toggleBtn.addEventListener("click",(event)=>{
            this._block.domElement.classList.toggle('opened');
        });

        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }
}