/**
 * PrefabAPO.ts: prefab API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { AsyncLoad } from "../io/AsyncLoad";
import { WorldFileNode } from "../framework-types/WorldFileFormat";
import { IONotifier } from "../io/Interfaces";
import { makeAPI, queryAPI } from "../plugin/Plugin";

/**
 * @interface IPrefabSystem
 * Container class for loaded Prefab definitions.
 * Access prefab templates through hasPrefab and getPrefab
 * Prefabs, like the world, are created from JSON description file(s).
 *
 * ~~~~
 * {
 *   "prefabs": [
 *     {
 *        // ID = reference name for @prefab:red_pointlight
 *        "id": "red_pointlight",
 *        "type": "node",
 *        "name": "polyplane_light",
 *        "translation": [0, 0, 0],
 *        "components": [
 *            {
 *            "module": "RED",
 *            "type": "PointLightComponent",
 *            "parameters": {
 *                "castShadow": false,
 *                "color": [
 *                    1.0,
 *                    0.1,
 *                    0.1],
 *                "intensity": 0.5,
 *                "distance": 30.01,
 *                "debugHelper": false
 *                }
 *            }
 *        ],
 *        "children": []
 *      }
 *   ]
 * }
 * ~~~~
 */
export interface IPrefabSystem {
    /**
     * load prefab into manager
     * @param file filename
     * @param preload preload data used by prefab
     */
    load(file:string, preload?:boolean) : AsyncLoad<string[]>;
    /**
     * create new prefab
     * @param name prefab name
     * @param prefab prefab description
     * @param preload preload data used by prefab
     */
    createPrefab(name:string, prefab:WorldFileNode, preload?:boolean);
    /**
     * query prefab name
     * @param name prefab name
     */
    hasPrefab(name:string) : boolean;
    /**
     * get raw prefab data
     * @param name prefab name
     */
    getPrefab(name:string) : WorldFileNode;
    /**
     * preload data used by prefab
     * @param name prefab name
     * @param preloadFiles additional files
     * @param ioNotifier async notifier
     */
    preload(name:string, preloadFiles?:any[], ioNotifier?:IONotifier);
    /**
     * preload all prefabs with their data
     * @param ioNotifier async notifier
     */
    preloadAll(ioNotifier?:IONotifier);
    /**
     * prefab name to filename
     * @param name prefab name
     */
    getFileReference(name:string) : string;
}
export const PREFABMANAGER_API = makeAPI("IPrefabManager");

let _globalPrefabManager:IPrefabSystem;
export function queryPrefabSystem() {
    if(_globalPrefabManager === undefined) {
        _globalPrefabManager = queryAPI<IPrefabSystem>(PREFABMANAGER_API);
    }
    return _globalPrefabManager;
}
