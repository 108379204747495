/**
 * RenderSystem.ts: Entity Component code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Render } from "../render/Render";
import { IRenderSystem, RENDERSYSTEM_API, PrepareRenderCallback, GenericRenderCallback } from "../framework/RenderAPI";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { RenderState } from "../render/State";
import { registerAPI } from "../plugin/Plugin";
import { IWorld } from "../framework/WorldAPI";
import { RedCamera } from "../render/Camera";

//TODO: NEW NAME HERE :)
interface RenderObjectCallback {
    id: ComponentId;
    needsRender: boolean;
    prepareRender: PrepareRenderCallback;
    preRender: GenericRenderCallback;
    render: GenericRenderCallback;
    debugRef?:string;
}

/** construction */
let _registeredCallbacks:RenderObjectCallback[] = [];
let _version:number = 1;

function destroy() {
    // clear all callbacks
    _registeredCallbacks = [];
    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function init(world:IWorld) {

    // clear all callbacks
    _registeredCallbacks = [];
    // increase version
    _version = (_version + 1) & 0x000000FF;
}

/** needs think state */
function needsRender(id:ComponentId) : boolean {
    if(_validId(id)) {
        const index = componentIdGetIndex(id);
        return _registeredCallbacks[index].needsRender;
    }
    return false;
}

function activate(id:ComponentId) {
    if(_validId(id)) {
        const index = componentIdGetIndex(id);
        _registeredCallbacks[index].needsRender = true;
    }
}

function deactivate(id:ComponentId) {
    if(_validId(id)) {
        const index = componentIdGetIndex(id);
        _registeredCallbacks[index].needsRender = false;
    }
}

function registerCallback(prepareRender?:PrepareRenderCallback, preRender?:GenericRenderCallback, render?:GenericRenderCallback, debugRef?:string) {
    let index = -1;

    for(let i = 0; i < _registeredCallbacks.length; ++i) {
        if(!_registeredCallbacks[i].id) {
            index = i;
            break;
        }
    }

    // new entry
    if(index === -1) {
        index = _registeredCallbacks.length;
        _registeredCallbacks[index] = {
            id: 0,
            needsRender: false,
            prepareRender: null,
            preRender: null,
            render: null
        };
    }

    _registeredCallbacks[index].id = createComponentId(index, _version);
    _registeredCallbacks[index].prepareRender = prepareRender;
    _registeredCallbacks[index].preRender = preRender;
    _registeredCallbacks[index].render = render;
    _registeredCallbacks[index].needsRender = !!(prepareRender || preRender || render);
    _registeredCallbacks[index].debugRef = debugRef;

    return _registeredCallbacks[index].id;
}

function removeCallback(id:ComponentId) {
    if(!_validId(id)) {
        console.error("RenderSystem: failed to remove object " + id);
        return;
    }

    const index = componentIdGetIndex(id);

    // cleanup
    _registeredCallbacks[index].id = 0;
    _registeredCallbacks[index].needsRender = false;
    _registeredCallbacks[index].prepareRender = null;
    _registeredCallbacks[index].preRender = null;
    _registeredCallbacks[index].render = null;

    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function prepareRendering(render:Render, scene:any, camera:any, pipeState:RenderState) {
    for(const cb of _registeredCallbacks) {
        if(cb.needsRender && cb.prepareRender) {
            cb.prepareRender(render, scene, camera, pipeState);
        }
    }
}

function _preRender(render:Render, camera:RedCamera) {
    for(const cb of _registeredCallbacks) {
        if(cb.needsRender && cb.preRender) {
            cb.preRender(render, camera);
        }
    }
}

function _render(render:Render, camera:RedCamera) {
    for(const cb of _registeredCallbacks) {
        if(cb.needsRender && cb.render) {
            cb.render(render, camera);
        }
    }
}

function _validId(id:ComponentId) {
    const index = componentIdGetIndex(id);
    if(index >= 0 && index < _registeredCallbacks.length) {
        return _registeredCallbacks[index].id === id;
    }
    return false;
}

function printStatistic(verbose?:boolean) {
    let active = 0;
    let inactive = 0;
    let empty = 0;

    for(const cb of _registeredCallbacks) {
        if(!cb.id) {
            empty++;
        } else if(cb.needsRender) {
            active++;
        } else {
            inactive++;
        }
    }

    console.info(`RenderSystem: Statistic for ${_registeredCallbacks.length} objects`);
    console.info(`RenderSystem: Empty: ${empty}  Inactive: ${inactive}  Active: ${active}`);
    if(verbose) {

        for(const cb of _registeredCallbacks) {
            if(!cb.id) {
                continue;
            }
            let callbacks = "";
            if(cb.prepareRender) {
                callbacks += "prepareRendering ";
            }
            if(cb.preRender) {
                callbacks += "preRender ";
            }
            if(cb.render) {
                callbacks += "render ";
            }
            const name = cb.debugRef || "none";

            console.info(`RenderSystem: object ${name} - ${cb.id} with state ${cb.needsRender} and callbacks: ${callbacks}`);
        }
    }
}

const renderSystem:IRenderSystem = {
    init: init,
    destroy: destroy,
    registerCallback: registerCallback,
    removeCallback: removeCallback,
    activate: activate,
    deactivate: deactivate,
    needsRender: needsRender,
    preRender: _preRender,
    prepareRendering: prepareRendering,
    render: _render,
};

registerAPI<IRenderSystem>(RENDERSYSTEM_API, renderSystem);
