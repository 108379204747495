
///<reference path="../../js_bindings.d.ts"/>

import { AppDelegate } from "redTyped/framework/AppDelegate";
import * as THREE from "redTyped/three"

import './redStandard_PixelUV';
import './redUnlit_AOAlpha';

import {Block} from '../../block/Block';
import {PropertyUI} from '../PropertyUI';
import {PropertyProvider} from '../PropertyProvider';
import {PropertyOption} from '../PropertyOption';
import { RenderQuality } from "redTyped/render/QualityLevels";
import { createPlatformRenderInitConfig } from "redTyped/render/Config";
import { CameraComponent } from "redTyped/framework-components/CameraComponent";
import { Platform } from "redTyped/core/Platform";
import { FileStat } from "redTyped/io/AssetInfo";
import { MaterialLibrary } from "redTyped/framework/MaterialLibrary";
import { MeshComponent, MeshComponentParams } from "redTyped/framework-components/MeshComponent";
import {ReflectionProbeComponent} from "redTyped/framework-lights/ReflectionProbeComponent";
import { Color } from "redTyped/three";
import { AssetManager } from "redTyped/framework/AssetManager";
import { ERayCastQuery, ECollisionBehaviour, CollisionResult, queryCollisionSystem } from "redTyped/framework/CollisionAPI";

// collision
import "redTyped/collision-raycast/CollisionSystem";

// model loader
import "redTyped/framework-loader/ModelLoader";
import { math } from "redTyped/core/Math";

declare var TWEEN;

enum InputState {
    None, Mouse, Touch
}

/*
export class Backpack3D extends RED.AppDelegate implements PropertyProvider {
    // light
    directionalLight:any;

    // camera
    public cameraControl:RED.CameraControl = null;

    private resolved:boolean = false;

    private _domElement:Element = null;
    private _canvasElement:HTMLCanvasElement = null;
    private _properties:PropertyUI;
    private _presets:Array<any>;

    // Zoom
    private zoomSpeed:number = 0.1;
    public onZoomInID:number = -1;
    public onZoomOutID:number = -1;
    private _isZooming:boolean = false;
    private _zoomDirection:number = 0;;

    // Block
    private _controlsZoomElement:Element = null;
    private _controlsInteractionElement:Element = null;
    private _controlsInteractionProgressElement:Element = null;
    private _zoomInElement:Element = null;
    private _zoomOutElement:Element = null;
    private _sidebar:Element = null;

    // MaterialClick
    private firstMaterialClick:string = null;
    private lastMaterialClick:string = null;
    private firstMaterialMSClick:number = null;
    private lastMaterialMSClick:number = null;

    // User Input
    private _inputState:InputState = InputState.None;

    // PropertyProvider
    private _onPropertyIdChange = new EventDispatcher<PropertyProvider,string>();

    // construction
    constructor(domElement:Element, private _block:Block) {
        super();
        this._domElement        = domElement;
        this._controlsZoomElement         = this._block.domElement.querySelector(".scene_controls_zoom");
        this._controlsInteractionElement  = this._block.domElement.querySelector(".scene_controls_interaction");
        this._controlsInteractionProgressElement  = this._block.domElement.querySelector(".scene_controls_interaction_progress");
        this._zoomInElement     = this._controlsZoomElement.querySelector(".plus");
        this._zoomOutElement    = this._controlsZoomElement.querySelector(".minus");

        this._zoomInElement.addEventListener("mousedown", this.onZoomIn);
        this._zoomInElement.addEventListener("mouseup", this.onZoomInEnd);
        this._zoomInElement.addEventListener("mouseleave", this.onZoomInEnd);
        this._zoomInElement.addEventListener("touchstart", this.onZoomIn);
        this._zoomInElement.addEventListener("touchend", this.onZoomInEnd);

        this._zoomOutElement.addEventListener("mousedown", this.onZoomOut);
        this._zoomOutElement.addEventListener("mouseup", this.onZoomOutEnd);
        this._zoomOutElement.addEventListener("mouseleave", this.onZoomOutEnd);
        this._zoomOutElement.addEventListener("touchstart", this.onZoomOut);
        this._zoomOutElement.addEventListener("touchend", this.onZoomOutEnd);


        // setup quality level
        let qualityLevel = RED.MaterialQuality.HighQualityLevel;

        //only on mobile
        if(RED.Platform.get().browser == "Microsoft Internet Explorer" && RED.Platform.get().browserMajorVersion <= 11){
            qualityLevel = RED.MaterialQuality.MediumQualityLevel;
        } else if(RED.Platform.get().operationSystem == "Android" && RED.Platform.get().osMajorVersion <= 4) {
            qualityLevel = RED.MaterialQuality.LowQualityLevel;
        } else if(RED.Platform.get().isMobile) {
            qualityLevel = RED.MaterialQuality.MediumQualityLevel;
        }

        RED.MaterialLibrary.get().qualityLevel = qualityLevel;

        //RED.AssetManager.get().baseMeshPath = "themes/red/3d/models/"
        //RED.AssetManager.get().baseTexturePath = "themes/red/3d/textures/"
        //RED.AssetManager.get().baseTextPath = "themes/red/3d/"
        //RED.AssetManager.get().baseShaderPath = "themes/red/3d/shader/"

        this._presets = [
             {
                preview: ["#778b9e","#a3a3a3","#ff6f08"],
                apply:()=>{
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_grayLight_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_steel_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_gray_second", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_orange", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_steel", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
                }
            },
            {
                preview: ["#2c2c2c","#595959","#ff6f08"],
                apply:()=>{
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_darker_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_darker_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_darker_second", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_orange", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
                }
            },
            {
                preview: ["#1fa2e1","#647585","#3b3b3b"],
                apply:()=>{
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_cyan_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_steel_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_steel_second", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_01", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_weiss", 0.25);
                }
            },
            {
                preview: ["#2682c9","#897c51","#67af1e"],
                apply:()=>{
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_darkCyan_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_darkCyan_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_taupe_second", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_darkGreen", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
                }
            },
            {
                preview: ["#ffffff","#595959","#3c3c3c"],
                apply:()=>{
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_white_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_white_main", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_dark_second", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_01", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
					RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                    RED.MaterialLibrary.get().switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
                }
            }
        ]
    }

    public getOnPropertyIdChange():IEvent<PropertyProvider,string> {
        return this._onPropertyIdChange.asEvent();
    }

    public selectProperty(id:string,label:string):void{
        //Nothing to do here!
    }

    public getOptionsByPropertyId = (id:string):Array<any> =>{

        let options:Array<PropertyOption> = [];

        if (id == "presets") {
            _.each(this._presets, (preset,index) => {
                options.push(
                    {
                        type: "preset",
                        preview: {
                            type:"color_array",
                            value: preset.preview
                        },
                        payload:{
                            presetIndex:index
                        },
                        trackingTag:`preset_${index}`,
                        selected:false
                    });
            });

            return options;
        }

        let group = RED.MaterialLibrary.get().findMaterialGroupByName(id);
        let currentMaterial = RED.MaterialLibrary.get().getCurrentMaterialGroupTemplate(id);
        let currentMaterialName  = currentMaterial.name;

        if(group != null){
            _.each(group['materials'],(materialName)=>{
                let material = RED.MaterialLibrary.get().findMaterialTemplateByName(materialName);
                if(!material) {
                    return;
                }

                let option:PropertyOption = {
                    type:"material",
                    preview: _.cloneDeep(material['preview']),
                    payload:{
                        group:id,
                        name:materialName
                    },
                    trackingTag:materialName,
                    selected:currentMaterialName == materialName
                }

                if(!option.preview){
                    let colorArray = material['color'] || material['diffuse'];
                    let color = "#ff0000";

                    if(colorArray.length == 3){
                        color= "#" + ((1 << 24) + (Math.round(colorArray[0] * 255) << 16) + (Math.round(colorArray[1] * 255) << 8) + Math.round(colorArray[2] * 255)).toString(16).slice(1);
                    }
                    else if(colorArray.length == 1){
                        color = colorArray[0];
                    }

                    option.preview = {
                        type: "color",
                        value: color
                    }
                }

                if(option.preview.type == "texture"){
                    option.preview.value = RED.AssetManager.get().baseTexturePath + option.preview.value;
                }

                options.push(option);
            });
        }

        return options;
    }

    public getProviderTag():string {
        return "Backpack";
    }

    public applyOption(option:PropertyOption):void{
        switch(option.type){
            case "material":
                 RED.MaterialLibrary.get().switchMaterialGroup(option.payload.group, option.payload.name, 0.25);
            break;
            case "preset":
                this._presets[option.payload.presetIndex].apply.call(null,this);
            break;
        }
    }

    set autoRotate(value:boolean) {

         if(this.cameraControl.orbit.autoRotate == value){
             return;
         }

        //start up
        if(value == true){
            this.cameraControl.orbit.autoRotate = true;
            this.cameraControl.orbit.autoRotateSpeed = 0;
        }

        let fromSpeed = {speed: this.cameraControl.orbit.autoRotateSpeed};
        let toSpeed = value ? {speed: 0.15} : {speed:0};

        var tween = new TWEEN.Tween(fromSpeed)
                .to(toSpeed, 1000)
                .onUpdate(() => {
                    // console.log(this,fromSpeed,toSpeed);
                    this.cameraControl.orbit.autoRotateSpeed = fromSpeed.speed;
                })
                .onComplete(() => {
                    //stop after slow down
                    if(value == false){
                        this.cameraControl.orbit.autoRotate = false;
                    }
                })
                .start();
    }


    onMouseDown(event){
        let px = this.app.mouse.normalizedScreenX;
        let py = this.app.mouse.normalizedScreenY;
        let hits = this.world.rayCast(px, py, RED.ERayCastQuery.FirstHit);
        this.firstMaterialMSClick = Date.now();
        this.firstMaterialClick = hits.length > 0 ? hits[0].intersect[0].materialGroup : null;
    }

    onTouchStart(event:TouchEvent) {
        if(this._inputState == InputState.None){
            this._inputState = InputState.Touch;
            // this.autoRotate = false;
        }
    }

    onMouseMove(event) {
        if(this._inputState == InputState.None){
            this._inputState = InputState.Mouse;

            this.cameraControl.orbit.enablePan = true;
            this.cameraControl.orbit.enableRotate = true;
            this.cameraControl.orbit.enableZoom = false;
            this.autoRotate = false;

            this._controlsZoomElement.classList.add("open");
            this._controlsInteractionElement.classList.remove("open");

            this._properties.hidden = false;
        }


        let px = this.app.mouse.normalizedScreenX;
        let py = this.app.mouse.normalizedScreenY;
        let hits = this.world.rayCast(px, py, RED.ERayCastQuery.FirstHit);

        if(hits.length > 0) {
            const matLib = RED.MaterialLibrary.get();
            // get nearest hit
            const obj = hits[0].intersect[0];
            const groupName = obj ? obj.materialGroup : null;


            if(groupName && groupName.length > 0) {
                switch(groupName){
                   case "matgroup_backpack_fabric_main_A":
                   case "matgroup_backpack_fabric_main_B":
                   case "matgroup_backpack_fabric_secondary_A":
                   case "matgroup_backpack_straps_A":
                   case "matgroup_backpack_straps_B":
                   case "matgroup_backpack_buckles":
                        this._canvasElement.style.cursor = "pointer";
                        return;
                }
            }
        }

        this._canvasElement.style.cursor = "default";
    }


    onMouseUp(event) {
        let px = this.app.mouse.normalizedScreenX;
        let py = this.app.mouse.normalizedScreenY;
        let hits = this.world.rayCast(px, py, RED.ERayCastQuery.FirstHit);
        this.lastMaterialMSClick = Date.now();
        let msBetweenClicks = this.lastMaterialMSClick - this.firstMaterialMSClick;


        this.lastMaterialClick = hits.length > 0 ? hits[0].intersect[0].materialGroup : null;

        if(this.firstMaterialClick !== this.lastMaterialClick){
            return;
        }

        //console.log(event);
        if(hits.length > 0) {
            const matLib = RED.MaterialLibrary.get();
            // get nearest hit
            const obj = hits[0].intersect[0];
            const groupName = obj ? obj.materialGroup : null;

            if(msBetweenClicks < 500){

                if(groupName && groupName.length > 0) {
                    const group = matLib.findMaterialGroupByName(groupName);

                    if(group) {
                        console.log(group.name);
                        this._onPropertyIdChange.dispatch(this,group.name);
                    }
                }
            }
        }
    }

    preloadItems() {
        RED.AssetManager.get().loadText("json/backpack/scene_backpack.json");
        RED.MaterialLibrary.get().loadMaterial("json/backpack/materials_backpack.json");
        RED.MaterialLibrary.get().loadMaterial("json/backpack/materials_metro_backpack.json");
        RED.MaterialLibrary.get().loadMaterialGroupFile("json/backpack/materialgroup_backpack.json");
    }

    init() {
        this._canvasElement = this._domElement.querySelector("canvas");

        // orbit camera
        this.cameraControl = new RED.CameraControl(RED.ECameraMode.Orbit, this.world.scene, this.world.mainCamera, this.containerElement());

        this.cameraControl.orbit.enableDamping = true;
        this.cameraControl.orbit.dampingFactor = 0.2;
        this.cameraControl.orbit.enablePan = false;
        this.cameraControl.orbit.enableZoom = false;
        this.cameraControl.orbit.enableRotate = false;
        this.cameraControl.orbit.autoRotate = true;
        this.cameraControl.orbit.autoRotateSpeed = 0.15;

        var dpr = window.devicePixelRatio !== undefined ? window.devicePixelRatio : 1.0;
        this.cameraControl.orbit.zoomSpeed = RED.is_touch_device() ? 1.0 / dpr : 1.0;
        this.cameraControl.orbit.rotateSpeed = RED.is_touch_device() ? 0.15 : 0.25;
        this.cameraControl.orbit.panSpeed = RED.is_touch_device() ? 0.15 : 0.25;

        this.cameraControl.orbit.minPolarAngle = 0.1;
        this.cameraControl.orbit.maxPolarAngle = Math.PI/2.05;
        //this.cameraControl.orbit.minAzimuthAngle = -Math.PI/2.15;
        //this.cameraControl.orbit.maxAzimuthAngle = Math.PI/2.15;

        this.cameraControl.orbit.minDistance = 100.0;
        this.cameraControl.orbit.maxDistance = 250.0;
        this.cameraControl.orbit.minBound = new THREE.Vector3(0.0, 20.0, 0.0);
        this.cameraControl.orbit.maxBound = new THREE.Vector3(0.0, 60.0, 0.0);

        this.cameraControl.target.position.set(0.0, 35, 0.0);
        this.world.mainCamera.position.set(150, 110, 200);
        this.world.mainCamera.fov = 35.0;
        this.world.mainCamera.near = 1;
        this.world.mainCamera.far = 1000.0;
        this.world.mainCamera.updateProjectionMatrix();

        // this.renderer.setClearColor(0xff0000, 1.0);

        this.initSceneStatics();
    }

    destroy() {
        super.destroy();

        // Angelegte Eventlistener entfernen
        this._zoomInElement.removeEventListener("mousedown", this.onZoomIn);
        this._zoomInElement.removeEventListener("mouseup", this.onZoomInEnd);
        this._zoomInElement.removeEventListener("mouseleave", this.onZoomInEnd);
        this._zoomInElement.removeEventListener("touchstart", this.onZoomIn);
        this._zoomInElement.removeEventListener("touchend", this.onZoomInEnd);

        this._zoomOutElement.removeEventListener("mousedown", this.onZoomOut);
        this._zoomOutElement.removeEventListener("mouseup", this.onZoomOutEnd);
        this._zoomOutElement.removeEventListener("mouseleave", this.onZoomOutEnd);
        this._zoomOutElement.removeEventListener("touchstart", this.onZoomOut);
        this._zoomOutElement.removeEventListener("touchend", this.onZoomOutEnd);

        this._block = null;

        if(this._properties){
            this._properties.destroy();
        }
    }

    renderingConfiguration() {

        var qualityLevel = RED.Render.HighQuality;

        // only when devicePixelRatio is not over 2
        // then their is to much power consumption
        const antialias = window.devicePixelRatio < 2.0;

        return {
            DOMElement: this.containerElement(),
            qualityLevel: qualityLevel,
            renderShadowMaps: false,
            renderAntialias: antialias,
            renderFXAA: false,
            renderSSAO: false,
            renderOffscreen: false,
            renderSize: null,
            clearColor: 0x333631
        };
    }


    containerElement():Element {
        return this._domElement;
    }

    initSceneStatics() {

        var realtimeLights = false;
        var realtimeShadows = false;

        if(realtimeLights) {
            // Directional Light
            var dirTarget = new THREE.Object3D();
            dirTarget.name = "DirectionalLight Target";
            dirTarget.position.set( 0, 0, 0 );

            this.directionalLight = new THREE.DirectionalLight( 0xffffff, 0.0 );
            this.directionalLight.name = "Directional Light";
            this.directionalLight.position.set( 10, 10, 10 );
            this.directionalLight.castShadow = realtimeShadows;
            this.directionalLight.target = dirTarget;
            this.directionalLight.shadow.bias = -0.01;

            this.directionalLight.shadow.mapSize.width = 2048;
            this.directionalLight.shadow.mapSize.height = 1024;

            var dLR = 80;
            var dTB = 49;
            this.directionalLight.shadow.camera.left = -dLR;
            this.directionalLight.shadow.camera.right = dLR;
            this.directionalLight.shadow.camera.top = dTB;
            this.directionalLight.shadow.camera.bottom = -dTB;

            this.directionalLight.shadow.camera.far = 125;

            this.world.scene.add(dirTarget);
            this.world.scene.add(this.directionalLight);
        }

        // load rest of scene
        this.loadScene();
    }

    loadScene() {
        this.world.load("json/backpack/scene_backpack.json");
        this.world.OnWorldLoaded.on(()=>{
            this._properties = new PropertyUI(this._block.domElement, this);

            if(RED.is_touch_device()){
                this._controlsInteractionElement.classList.add("open");
                this._controlsInteractionElement.addEventListener("click",(event)=>{
                    let toggle = !this.cameraControl.orbit.enableRotate;
                    this.cameraControl.orbit.enableRotate = toggle;
                    this.cameraControl.orbit.enablePan = toggle;
                    this.cameraControl.orbit.enableZoom = toggle;
                    this.autoRotate = !toggle;
                    this._properties.hidden = !toggle;

                    if(toggle){
                        this._controlsInteractionProgressElement.classList.add('progress_anim');
                        this._controlsInteractionElement.classList.add("active");
                    }
                    else{
                        this._controlsInteractionElement.classList.remove("active");
                        this._controlsInteractionProgressElement.classList.remove('progress_anim');
                    }
                });
            }
            else{
                this._properties.hidden = false;
            }
        });
    }

    onZoomIn = () => {
        this._isZooming = true;
        this._zoomDirection = 1;
    }

    onZoomInEnd = () => {
        this._isZooming = false;
    }

    onZoomOut = () => {
        this._isZooming = true;
        this._zoomDirection = -1;
    }

    onZoomOutEnd = () => {
        this._isZooming = false;
    }

    update(deltaTime) {

        if(this._isZooming === true){
            console.log("zoom",this._zoomDirection * deltaTime * 1000);
            const dir = new THREE.Vector3();
            dir.subVectors(this.cameraControl.target.position, this.cameraControl.camera.position).normalize();
            this.cameraControl.camera.position.add(dir.multiplyScalar(this.zoomSpeed * deltaTime * this._zoomDirection * 1000));
        }

        if(this.cameraControl) {
            this.cameraControl.update();
        }
    }

    onLoadProgress(percentage) {
    }

    onLoad(loadingScreen:boolean) {
    }

    onLoadFinished() {

        let preloader = this.containerElement().querySelector(".scene_preloader");
        jQuery(preloader).fadeOut();
    }

}
*/

/** Application */
export class Backpack3D extends AppDelegate implements PropertyProvider {

    /** DOM */
    private _sceneElement:Element;
    private _block:Block;
    private _properties:PropertyUI;

    // Configuration
    private _presets:Array<any>;

    // Zoom
    private zoomSpeed:number = 0.1;
    public onZoomInID:number = -1;
    public onZoomOutID:number = -1;
    private _isZooming:boolean = false;
    private _zoomDirection:number = 0;;

    // Block
    private _controlsZoomElement:Element = null;
    private _controlsInteractionElement:Element = null;
    private _controlsInteractionProgressElement:Element = null;
    private _zoomInElement:Element = null;
    private _zoomOutElement:Element = null;
    private _sidebar:Element = null;

    // MaterialClick
    private firstMaterialClick:string = null;
    private lastMaterialClick:string = null;
    private firstMaterialMSClick:number = null;
    private lastMaterialMSClick:number = null;

    // User Input
    private _inputState:InputState = InputState.None;

    // PropertyProvider
    private _onPropertyIdChange = new EventDispatcher<PropertyProvider,string>();

    // light
    private _directionalLight:THREE.DirectionalLight;

    /** construction */
    constructor(domElement:Element, block:Block, params:any) {
        super();

        this._block = block;
        this._sceneElement = domElement || document.querySelector(".scene");

        let qualityLevel = RenderQuality.HighQuality;

        this._renderConfig = createPlatformRenderInitConfig({
            DOMElement: this.containerElement(),
            qualityLevel: qualityLevel,
            renderShadowMaps: true,
            renderAntialias: true,
            renderOffscreen: false
        });


        this._controlsZoomElement         = this._block.domElement.querySelector(".scene_controls_zoom");
        this._controlsInteractionElement  = this._block.domElement.querySelector(".scene_controls_interaction");
        this._controlsInteractionProgressElement  = this._block.domElement.querySelector(".scene_controls_interaction_progress");
        this._zoomInElement     = this._controlsZoomElement.querySelector(".plus");
        this._zoomOutElement    = this._controlsZoomElement.querySelector(".minus");

        this._zoomInElement.addEventListener("mousedown", this.onZoomIn);
        this._zoomInElement.addEventListener("mouseup", this.onZoomInEnd);
        this._zoomInElement.addEventListener("mouseleave", this.onZoomInEnd);
        this._zoomInElement.addEventListener("touchstart", this.onZoomIn);
        this._zoomInElement.addEventListener("touchend", this.onZoomInEnd);

        this._zoomOutElement.addEventListener("mousedown", this.onZoomOut);
        this._zoomOutElement.addEventListener("mouseup", this.onZoomOutEnd);
        this._zoomOutElement.addEventListener("mouseleave", this.onZoomOutEnd);
        this._zoomOutElement.addEventListener("touchstart", this.onZoomOut);
        this._zoomOutElement.addEventListener("touchend", this.onZoomOutEnd);


        this._presets = [
            {
               preview: ["#778b9e","#a3a3a3","#ff6f08"],
               apply:()=>{
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_grayLight_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_steel_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_gray_second", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_orange", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_steel", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
               }
           },
           {
               preview: ["#2c2c2c","#595959","#ff6f08"],
               apply:()=>{
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_darker_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_darker_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_darker_second", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_orange", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
               }
           },
           {
               preview: ["#1fa2e1","#647585","#3b3b3b"],
               apply:()=>{
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_cyan_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_steel_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_steel_second", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_01", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_weiss", 0.25);
               }
           },
           {
               preview: ["#2682c9","#897c51","#67af1e"],
               apply:()=>{
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_darkCyan_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_darkCyan_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_taupe_second", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_darkGreen", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
               }
           },
           {
               preview: ["#ffffff","#595959","#3c3c3c"],
               apply:()=>{
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_A","mat_white_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_main_B","mat_white_main", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_fabric_secondary_A","mat_dark_second", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_A","mat_backpack_straps_01", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_straps_B","mat_backpack_straps_01", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_zipper","mat_backpack_zipper_schwarz", 0.25);
                   MaterialLibrary.switchMaterialGroup("matgroup_backpack_buckles","mat_backpack_buckles_01", 0.25);
               }
           }
       ]
    }

    public destroy() {

        // Angelegte Eventlistener entfernen
        this._zoomInElement.removeEventListener("mousedown", this.onZoomIn);
        this._zoomInElement.removeEventListener("mouseup", this.onZoomInEnd);
        this._zoomInElement.removeEventListener("mouseleave", this.onZoomInEnd);
        this._zoomInElement.removeEventListener("touchstart", this.onZoomIn);
        this._zoomInElement.removeEventListener("touchend", this.onZoomInEnd);

        this._zoomOutElement.removeEventListener("mousedown", this.onZoomOut);
        this._zoomOutElement.removeEventListener("mouseup", this.onZoomOutEnd);
        this._zoomOutElement.removeEventListener("mouseleave", this.onZoomOutEnd);
        this._zoomOutElement.removeEventListener("touchstart", this.onZoomOut);
        this._zoomOutElement.removeEventListener("touchend", this.onZoomOutEnd);

        this._block = null;

        if(this._properties){
            this._properties.destroy();
        }

        super.destroy();
    }

    /**
     * preload callback
     */
    onPreloadItems() {
        MaterialLibrary.loadMaterialFile("json/backpack/materials_backpack.json");
        MaterialLibrary.loadMaterialFile("json/backpack/materials_metro_backpack.json");
        MaterialLibrary.loadMaterialGroupFile("json/backpack/materialgroup_backpack.json");
    }

    onInitialization() {
        super.onInitialization();

        const cameraComponent = this.world.findByName("mainCamera").getComponent<CameraComponent>(CameraComponent);

        // orbit camera
        cameraComponent.setupOrbitController(this._sceneElement);

        cameraComponent.orbit.enableDamping = true;
        cameraComponent.orbit.dampingFactor = 0.2;
        cameraComponent.orbit.enablePan = true;

        cameraComponent.orbit.enablePan = false;
        cameraComponent.orbit.enableZoom = false;
        cameraComponent.orbit.enableRotate = false;
        cameraComponent.orbit.autoRotate = true;
        cameraComponent.orbit.autoRotateSpeed = 0.15;

        var dpr = window.devicePixelRatio !== undefined ? window.devicePixelRatio : 1.0;
        cameraComponent.orbit.zoomSpeed = Platform.get().isTouchDevice ? 2.0 / dpr : 2.0;
        cameraComponent.orbit.rotateSpeed = Platform.get().isTouchDevice ? 0.15 : 0.25;

        cameraComponent.orbit.minPolarAngle = 0.1;
        cameraComponent.orbit.maxPolarAngle = Math.PI/2.05;

        cameraComponent.orbit.minBound = new THREE.Vector3(0.0, 20.0, 0.0);
        cameraComponent.orbit.maxBound = new THREE.Vector3(0.0, 60.0, 0.0);

        cameraComponent.orbit.minDistance = 100.0;
        cameraComponent.orbit.maxDistance = 250.0;

        cameraComponent.target.set(0.0, 35.0, 0.0);

        cameraComponent.sceneCamera.position.set(150, 110, 200);
        cameraComponent.entity.updateTransform();
        cameraComponent.near = 0.1;
        cameraComponent.far = 1000.0;
        cameraComponent.fov = 45.0;

        this.world.setEnvironment({
            color: [34.0/255.0, 40.0/255.0, 48.0/255.0],
            //envMap: "backpack/env_studio.jpg"
        });

        // model
        const backpackEnt = this.world.instantiateEntity("backpack");
        //backpackEnt.rotateY(math.toRadian(-160));
        //backpackEnt.updateTransform();
        const backpackMesh = backpackEnt.createComponent<MeshComponent>(MeshComponent);
        backpackMesh.setMesh({
            filename: "models/backpack/backpack.red",
            collision: ECollisionBehaviour.Triangles
        } as MeshComponentParams);

        // reflection probe
        const probeEnt = this.world.instantiateEntity("reflProbe");
        const probe = probeEnt.createComponent<ReflectionProbeComponent>(ReflectionProbeComponent);
        probe.renderObjects = false;
        probe.size = 128;
        probe.shIntensity = 1000;
        probe.intensity = 4000;
        probe.setStaticProbe("backpack/env_studio.jpg");

        const ambientLight = new THREE.HemisphereLight(new Color(1,1,1), new Color(0.5, 0.5, 0.5), 1.0);
        this.world.scene.add(ambientLight);

        this._initSceneStatics();
        this._initUIControls();

        //queryCollisionSystem().setDebugHelper(true);
    }

    private _initSceneStatics() {

        var realtimeLights = false;
        var realtimeShadows = false;

        if(realtimeLights) {
            // Directional Light
            var dirTarget = new THREE.Object3D();
            dirTarget.name = "DirectionalLight Target";
            dirTarget.position.set( 0, 0, 0 );

            this._directionalLight = new THREE.DirectionalLight( 0xffffff, 0.0 );
            this._directionalLight.name = "Directional Light";
            this._directionalLight.position.set( 10, 10, 10 );
            this._directionalLight.castShadow = realtimeShadows;
            this._directionalLight.target = dirTarget;
            this._directionalLight.shadow.bias = -0.01;

            this._directionalLight.shadow.mapSize.width = 2048;
            this._directionalLight.shadow.mapSize.height = 1024;

            var dLR = 80;
            var dTB = 49;
            this._directionalLight.shadow.camera.left = -dLR;
            this._directionalLight.shadow.camera.right = dLR;
            this._directionalLight.shadow.camera.top = dTB;
            this._directionalLight.shadow.camera.bottom = -dTB;

            this._directionalLight.shadow.camera.far = 125;

            this.world.scene.add(dirTarget);
            this.world.scene.add(this._directionalLight);
        }

        // load rest of scene
        //this.loadScene();
    }

    private _initUIControls() {
        this._properties = new PropertyUI(this._block.domElement, this);

        if(Platform.get().isTouchDevice) {
            this._controlsInteractionElement.classList.add("open");
            this._controlsInteractionElement.addEventListener("click",(event)=> {
                const cameraControl = CameraComponent.Main;

                let toggle = !cameraControl.orbit.enableRotate;
                cameraControl.orbit.enableRotate = toggle;
                cameraControl.orbit.enablePan = toggle;
                cameraControl.orbit.enableZoom = toggle;
                this.autoRotate = !toggle;
                this._properties.hidden = !toggle;

                if(toggle){
                    this._controlsInteractionProgressElement.classList.add('progress_anim');
                    this._controlsInteractionElement.classList.add("active");
                }
                else{
                    this._controlsInteractionElement.classList.remove("active");
                    this._controlsInteractionProgressElement.classList.remove('progress_anim');
                }
            });
        } else {
            this._properties.hidden = false;
        }
    }

    // PropertyProvider

    public getOnPropertyIdChange():IEvent<PropertyProvider,string> {
        return this._onPropertyIdChange.asEvent();
    }

    public selectProperty(id:string,label:string):void{
        //Nothing to do here!
    }

    public getOptionsByPropertyId = (id:string):Array<any> =>{

        let options:Array<PropertyOption> = [];

        if (id == "presets") {
            _.each(this._presets, (preset,index) => {
                options.push(
                    {
                        type: "preset",
                        preview: {
                            type:"color_array",
                            value: preset.preview
                        },
                        payload:{
                            presetIndex:index
                        },
                        trackingTag:`preset_${index}`,
                        selected:false
                    });
            });

            return options;
        }

        let group = MaterialLibrary.findGroupByName(id);
        let currentMaterial = MaterialLibrary.getCurrentGroupMaterial(id);
        let currentMaterialName  = currentMaterial.name;

        if(group != null){
            _.each(group['materials'],(materialName)=>{
                let material = MaterialLibrary.findMaterialByName(materialName);
                if(!material) {
                    return;
                }

                let option:PropertyOption = {
                    type:"material",
                    preview: _.cloneDeep(material['preview']),
                    payload:{
                        group:id,
                        name:materialName
                    },
                    trackingTag:materialName,
                    selected:currentMaterialName == materialName
                }

                if(!option.preview){
                    let colorArray = material['color'] || material['diffuse'] || material['baseColor'];
                    let color = "#ff0000";

                    if(colorArray.length == 3){
                        color= "#" + ((1 << 24) + (Math.round(colorArray[0] * 255) << 16) + (Math.round(colorArray[1] * 255) << 8) + Math.round(colorArray[2] * 255)).toString(16).slice(1);
                    }
                    else if(colorArray.length == 1){
                        color = colorArray[0];
                    }

                    option.preview = {
                        type: "color",
                        value: color
                    }
                }

                if(option.preview.type == "texture"){
                    option.preview.value = AssetManager.setup.baseTexturePath + option.preview.value;
                }

                options.push(option);
            });
        }

        return options;
    }

    public getProviderTag():string {
        return "Backpack";
    }

    public applyOption(option:PropertyOption):void{
        switch(option.type){
            case "material":
                 MaterialLibrary.switchMaterialGroup(option.payload.group, option.payload.name, 0.25);
            break;
            case "preset":
                this._presets[option.payload.presetIndex].apply.call(null,this);
            break;
        }
    }

    // MOUSE

    set autoRotate(value:boolean) {

        const cameraControl = CameraComponent.Main;

        if(cameraControl.orbit.autoRotate == value){
            return;
        }

        //start up
        if(value == true){
            cameraControl.orbit.autoRotate = true;
            cameraControl.orbit.autoRotateSpeed = 0;
        }

        let fromSpeed = {speed: cameraControl.orbit.autoRotateSpeed};
        let toSpeed = value ? {speed: 0.15} : {speed:0};

       cameraControl.orbit.autoRotate = false;
       const tween = new TWEEN.Tween(fromSpeed)
               .to(toSpeed, 1000)
               .onUpdate(() => {
                   // console.log(this,fromSpeed,toSpeed);
                   cameraControl.orbit.autoRotateSpeed = fromSpeed.speed;
               })
               .onComplete(() => {
                   //stop after slow down
                   if(value == false){
                       cameraControl.orbit.autoRotate = false;
                   }
               })
               .start();
   }


   onMouseDown(event){
       let px = this.app.mouse.normalizedScreenX;
       let py = this.app.mouse.normalizedScreenY;
       let hits:CollisionResult[] = [];
       this.world.rayCast(CameraComponent.Main.sceneCamera, px, py, ERayCastQuery.FirstHit, hits);
       this.firstMaterialMSClick = Date.now();
       this.firstMaterialClick = hits.length > 0 ? hits[0].intersect.materialName : null;
   }

   onTouchStart(event:TouchEvent) {
       if(this._inputState == InputState.None){
           this._inputState = InputState.Touch;
           // this.autoRotate = false;
       }
   }

   onMouseMove(event) {
       if(this._inputState == InputState.None){
           this._inputState = InputState.Mouse;

           const cameraControl = CameraComponent.Main;

           cameraControl.orbit.enablePan = true;
           cameraControl.orbit.enableRotate = true;
           cameraControl.orbit.enableZoom = false;
           this.autoRotate = false;

           this._controlsZoomElement.classList.add("open");
           this._controlsInteractionElement.classList.remove("open");

           this._properties.hidden = false;
       }

       const canvasElement = this._sceneElement.querySelector("canvas");

       let px = this.app.mouse.normalizedScreenX;
       let py = this.app.mouse.normalizedScreenY;
       let hits:CollisionResult[] = [];
       this.world.rayCast(CameraComponent.Main.sceneCamera, px, py, ERayCastQuery.FirstHit, hits);

       if(hits.length > 0) {
           // get nearest hit
           const obj = hits[0].intersect;
           const groupName = obj ? obj.materialName : null;

           if(groupName && groupName.length > 0) {
               switch(groupName){
                  case "matgroup_backpack_fabric_main_A":
                  case "matgroup_backpack_fabric_main_B":
                  case "matgroup_backpack_fabric_secondary_A":
                  case "matgroup_backpack_straps_A":
                  case "matgroup_backpack_straps_B":
                  case "matgroup_backpack_buckles":
                       canvasElement.style.cursor = "pointer";
                       return;
               }
           }
       }

       canvasElement.style.cursor = "default";
   }


   onMouseUp(event) {
       let px = this.app.mouse.normalizedScreenX;
       let py = this.app.mouse.normalizedScreenY;
       let hits:CollisionResult[] = [];
       this.world.rayCast(CameraComponent.Main.sceneCamera, px, py, ERayCastQuery.FirstHit, hits);
       this.lastMaterialMSClick = Date.now();
       let msBetweenClicks = this.lastMaterialMSClick - this.firstMaterialMSClick;


       this.lastMaterialClick = hits.length > 0 ? hits[0].intersect.materialName : null;

       if(this.firstMaterialClick !== this.lastMaterialClick){
           return;
       }

       //console.log(event);
       if(hits.length > 0) {
           // get nearest hit
           const obj = hits[0].intersect;
           const groupName = obj ? obj.materialName : null;

           if(msBetweenClicks < 500){

               if(groupName && groupName.length > 0) {
                   const group = MaterialLibrary.findGroupByName(groupName);

                   if(group) {
                       console.log(group.name);
                       this._onPropertyIdChange.dispatch(this,group.name);
                   }
               }
           }
       }
   }

    // DOM
    containerElement():HTMLElement {
        return this._sceneElement as HTMLElement;
    }

    /** frame tick */
    update(delta:number) {
        super.update(delta);

        if(this._isZooming === true){
            console.log("zoom",this._zoomDirection * delta * 1000);
            const dir = math.tmpVec3();
            const cameraControl = CameraComponent.Main;
            dir.subVectors(cameraControl.target, cameraControl.sceneCamera.position).normalize();
            cameraControl.sceneCamera.position.add(dir.multiplyScalar(this.zoomSpeed * delta * this._zoomDirection * 1000));
        }
    }


    onZoomIn = () => {
        this._isZooming = true;
        this._zoomDirection = 1;
    }

    onZoomInEnd = () => {
        this._isZooming = false;
    }

    onZoomOut = () => {
        this._isZooming = true;
        this._zoomDirection = -1;
    }

    onZoomOutEnd = () => {
        this._isZooming = false;
    }

    onLoad(loadingScreen:boolean) {
    }

	onLoadProgress(stats:FileStat) {

    }

    onLoadFinished() {

        let preloader = this.containerElement().querySelector(".scene_preloader");
        jQuery(preloader).fadeOut();
    }

}
