/**
 * CollisionAPI.ts: collision API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Face3 } from "../../lib/threejs/core/Face3";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Box3 } from "../../lib/threejs/math/Box3";
import { StaticModel } from "../render/Model";
import { WorldSystem } from "./WorldAPI";
import { ComponentId } from "./Component";
import { Mesh } from "../render/Mesh";
import { MaterialTemplate } from "../render/Material";
import { Entity } from "./Entity";
import { queryAPI, makeAPI } from "../plugin/Plugin";

/**
 * ray cast query options
 */
export enum ERayCastQuery {
    /** returns any hit (e.g. shadow testing) */
    AnyHit = 1,
    /** return hits sorted after distance */
    FirstHit = 2,
    /** only test against boundings */
    OnlyBounds = 4,
    /** hit only visible objects */
    OnlyVisible = 8,
    /** one hit per object */
    OneHitPerObject = 10
}

/**
 * object ray cast behaviour
 */
export enum ECollisionBehaviour {
    None = 0,
    Bounds = 1,
    Triangles = 2,
}

/**
 * BitMask for comparing CollisionObjects
 */
export type CollisionLayer = number;
export const CollisionLayerDefaults = {
    None: 0,    // really needed, maybe ALL is better?
    Default: 1,
};

/** hit result */
export interface RayCastResult {
    /** object reference */
    id: ComponentId;
    /** entity reference */
    entity: Entity;

    /** world position of hit */
    point: Vector3;
    distance: number;

    /** geometry hit */
    intersect?: IntersectData;
}

/** detailed collision data */
export interface IntersectData {
    face: Face3;
    faceIndex: number;
    indices: any;

    material: MaterialTemplate;
    mesh: Mesh | any;
    object: StaticModel | Mesh | any;
    // TODO: geometry data
    [key:string]:any;
}

/** hit result */
export interface CollisionResult {
    /** object reference */
    id: ComponentId;
    /** entity reference */
    entity: Entity;

    point: Vector3;

    //TODO: unused, remove?
    distance?:number;

    normal?: Vector3;
    penetrationVector?:Vector3;

    /** geometry hit */
    // TODO: delete?
    intersect?: IntersectData;
}

/**
 * global collision system handling
 */
export interface ICollisionSystem extends WorldSystem {

    /**
     * set debug info
     * @param value show debug info (true)
     */
    setDebugHelper(value:boolean) : void;

    /**
     * DEPRECATED
     * register Model object
     * @param model model
     * @param node entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionModel(model:StaticModel, node:any, collisionBehaviour: ECollisionBehaviour, collisionLayer?: CollisionLayer) : ComponentId;

    /**
     * register Mesh object
     * @param mesh mesh
     * @param node entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionMesh(mesh:Mesh, node:any, collisionBehaviour: ECollisionBehaviour, collisionLayer?: CollisionLayer) : ComponentId;

    /**
     * register Line object
     * @param mesh mesh
     * @param node entity
     * @param collisionBehaviour behaviour
     * @param collisionLayer layer
     */
    registerCollisionLine(line:any, node:any, collisionBehaviour: ECollisionBehaviour, collisionLayer?: CollisionLayer) : ComponentId;

    /**
     * remove object from system
     * @param id component id
     */
    removeCollisionObject(id:ComponentId);

    /**
     * update layer setup for object
     * @param id
     * @param layer
     * @param collidesWith
     */
    updateCollisionObjectLayer(id: ComponentId, layer?: CollisionLayer, collidesWith?: CollisionLayer);

    /**
     * inform system that object has transformed
     * @param id component id
     */
    updateTransform(id:ComponentId);

    /**
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @param normalizedScreenX screen position in ndc
     * @param normalizedScreenY screen position in ndx
     * @param camera camera reference
     * @param result hit results
     * @param query query options
     * @return true for any hit
     */
    rayCast(normalizedScreenX:number, normalizedScreenY:number, camera:any, query:ERayCastQuery, result:CollisionResult[], layerToRaycast?: CollisionLayer) : boolean;

    /**
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     *
     * @param origin origin vec3
     * @param direction direction vec3
     * @param result hit results
     * @param query query options
     * @return true for any hit
     */
    rayCastWorld(origin:Vector3, direction:Vector3, query:ERayCastQuery, result:CollisionResult[], layerToRaycast?: CollisionLayer) : boolean;

    /**
     * check world AABB against scene
     * @param bounds world bounds to check collision for
     * @param query query flags
     * @param result hit results
     * @param layer layer to check (optional)
     */
    checkBounds(bounds:Box3, query:ERayCastQuery, result:CollisionResult[], layer?: CollisionLayer) : boolean;

    /**
     * checks entity against all current registered collisionObjects
     * @param results array of results. array will be mutated
     * @returns true if any collision exists
     */
    checkCollisionForEntity?(entity:Entity, results: CollisionResult[]) : boolean;

    /**
     * checks entities against all current registered collisionObjects
     * avoiding duplicate collisions (so a:b will not be tested again if b:a already yielded a result)
     * @param results array of results. array will be mutated
     * @returns true if any collision exists
     */
    checkCollisionForEntities?(entities:Entity[], results: CollisionResult[]): boolean;

    /**
     * checks all current registered collisionObjects against each other
     * avoiding duplicate collisions (so a:b will not be tested again if b:a already yielded a result)
     * @param results array of results. array will be mutated
     * @returns true if any collision exists
     */
    checkCollisionAll?(results: CollisionResult[]): boolean;

    /**
     * register callback when collision happened
     * @param entity entity object
     * @param callback callback function
     */
    registerCallback?(entity: Entity, callback: (result?: CollisionResult) => void): string;

}
export const COLLISIONSYSTEM_API = makeAPI("ICollisionSystem");

let _globalCollisionSystem:ICollisionSystem;
export function queryCollisionSystem() {
    if(_globalCollisionSystem === undefined) {
        _globalCollisionSystem = queryAPI<ICollisionSystem>(COLLISIONSYSTEM_API);
    }
    return _globalCollisionSystem;
}
