/**
 * Math.ts: math functions
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { Vector4 } from "../../lib/threejs/math/Vector4";
import { Vector2 } from "../../lib/threejs/math/Vector2";

export namespace math {

    export function toRadian(degress:number) : number {
        return degress * (Math.PI / 180.0);
    }

    export function toDegress(radian:number) : number {
        return radian * (180.0 / Math.PI);
    }

    export function clamp(value:number, min:number, max:number) : number {
        return Math.min(max, Math.max(value, min));
    }

    /** default vectors (!!!do not edit!!!) */
    export const Vec3Zero:Vector3 = new Vector3(0,0,0);
    export const Vec3One:Vector3 = new Vector3(1,1,1);
    export const Vec3I:Vector3 = new Vector3(1,0,0);
    export const Vec3J:Vector3 = new Vector3(0,1,0);
    export const Vec3K:Vector3 = new Vector3(0,0,1);

    /** size interface */
    export interface Size {
        width:number;
        height:number;
    }

    /** convert between measurements */
    export function centimeterToMillimeter(value:number) {
        return value * 10.0;
    }
    export function centimeterToMeter(value:number) {
        return value * 0.01;
    }
    export function millimeterToCentimeter(value:number) {
        return value * 0.1;
    }
    export function millimeterToMeter(value:number) {
        return value * 0.001;
    }
    export function meterToMillimeter(value:number) {
        return value * 1000.0;
    }
    export function meterToCentimeter(value:number) {
        return value * 100.0;
    }

    export function precisionRound(value:number, precision:number) {
        const factor = Math.pow(10, precision);
        return Math.round(value * factor) / factor;
    }

    /**
     * find nearest point on line
     * @param lineA line start
     * @param lineB line end
     * @param point point
     * @param segmentClamp clamp in 0-1
     * @return nearest point on line
     */
    export function closestPointOnLine(lineA:any, lineB:any, point:any, segmentClamp?:boolean) : any {

        const ap = new Vector3().subVectors(point, lineA);
        const ab = new Vector3().subVectors(lineB, lineA);

        const ab2 = ab.dot(ab);
        const apdotab = ap.dot(ab);
        let t = apdotab / ab2;
        if(segmentClamp) {
            if (t < 0.0) {
                t = 0.0;
            } else if (t > 1.0) {
                t = 1.0;
            }
        }

        //console.log(t);
        return new Vector3().addVectors(lineA, ab.multiplyScalar(t));
    }

    /**
     * snap position
     * @param position position to snap
     * @param snapping snapping value
     */
    export function snap(position:[number, number, number], snapping:number) {
        return [Math.floor(position[0] / snapping) * snapping,
                Math.floor(position[1] / snapping) * snapping,
                Math.floor(position[2] / snapping) * snapping];
    }

    /**
     * snap vec3 position
     * @param position position to snap
     * @param snapping snapping value
     */
    export function snapVec3(position:Vector3, snapping:number) {
        return [Math.floor(position.x / snapping) * snapping,
                Math.floor(position.y / snapping) * snapping,
                Math.floor(position.z / snapping) * snapping];
    }

    const tmpProjectMatrix4 = new Matrix4();

    /**
     * Projects a world position to screen space position
     * THREE.JS does not provide a function for Vector4 to project
     * @param vector vector 4 to project
     * @param camera three.js camera project
     */
    export function projectVectorOnScreen(vector:Vector4, camera:any) : Vector4 {
        tmpProjectMatrix4.multiplyMatrices( camera.projectionMatrix, tmpProjectMatrix4.getInverse( camera.matrixWorld ) );
        return vector.applyMatrix4( tmpProjectMatrix4 );
    }

    /**
     * smooth damping
     * @param currentVelocity (in/out) adjust the current velocity
     * @return { value: smooth value, currentVelocity: velocity }
     */
    export function SmoothDamp(current:number, target:number, currentVelocity:number, smoothTime:number,
                               maxSpeed:number, deltaTime:number):any {
        smoothTime = Math.max(0.0001, smoothTime);
        const num = 2.0 / smoothTime;
        const num2 = num * deltaTime;
        const num3 = 1.0 / (1.0 + num2 + 0.48 * num2 * num2 + 0.235 * num2 * num2 * num2);
        let num4 = current - target;
        const num5 = target;
        const num6 = maxSpeed * smoothTime;
        num4 = Math.min(Math.max(num4, -num6), num6);
        target = current - num4;
        const num7 = (currentVelocity + num * num4) * deltaTime;
        currentVelocity = (currentVelocity - num * num7) * num3;
        let num8 = target + (num4 + num7) * num3;
        if((num5 - current > 0.0) === (num8 > num5)) {
            num8 = num5;
            currentVelocity = (num8 - num5) / deltaTime;
        }
        return {
            value: num8,
            currentVelocity: currentVelocity
        };
    }

    // this is used by SmoothDampVector3 to save memory allocations
    const tmpCopy0 = new Vector3();
    const tmpCopy1 = new Vector3();
    const tmpVector0 = new Vector3();
    const tmpVector1 = new Vector3();
    const tmpVector2 = new Vector3();

    /**
     * smooth damping
     * @param currentVelocity (in/out) adjust the current velocity
     * @return { value: smooth value, currentVelocity: velocity }
     */
    export function SmoothDampVector3(current:any, target:any, currentVelocity:any, smoothTime:number,
                                      maxSpeed:number, deltaTime:number):any {
        smoothTime = Math.max(0.0001, smoothTime);
        const num = 2.0 / smoothTime;
        const num2 = num * deltaTime;
        const d = 1.0 / (1.0 + num2 + 0.48 * num2 * num2 + 0.235 * num2 * num2 * num2);
        let vector = tmpVector0.subVectors(current, target);
        const vector2 = tmpCopy0.copy(target);
        const maxLength = maxSpeed * smoothTime;
        vector = vector.clampLength(0.0, maxLength);
        const vector3 = tmpVector2.addVectors(currentVelocity, tmpCopy1.copy(vector).multiplyScalar(num)).multiplyScalar(deltaTime);
        currentVelocity = currentVelocity.subVectors(currentVelocity, tmpCopy1.copy(vector3).multiplyScalar(num)).multiplyScalar(d);
        const vector4 = new Vector3().addVectors(target, tmpVector1.addVectors(vector, vector3).multiplyScalar(d));

        if(tmpVector1.subVectors(vector2, current).dot(tmpVector1.subVectors(vector4, vector2)) > 0.0) {
            vector4.copy(vector2);
            currentVelocity = currentVelocity.subVectors(vector4, vector2).divideScalar(deltaTime);
        }
        return vector4;
    }

    // three.js (x) = 3ds max (x)
    // three.js (y) = 3ds max (z)
    // three.js (z) = 3ds max (y)
    export function dominantAxes(normal:Vector3) {
        if(Math.abs(normal.x) >= Math.abs(normal.y) && Math.abs(normal.x) >= Math.abs(normal.z)) {
            // XAXIS
            //return vec2(pos.z, pos.y);
            return 0;
        } else if(Math.abs(normal.y) >= Math.abs(normal.z)) {
            // YAXIS (inverted for three.js)
            //return vec2(pos.x, pos.z);
            return 1;
        } else {
            // ZAXIS
            //return vec2(pos.y, pos.x);
            return 2;
        }
    }

    /**
     * 32 bit float to half float
     * @return 16 bit float (save into Uint16Array)
     */
    export let toHalf = (function() {

        const floatView = new Float32Array(1);
        const int32View = new Int32Array(floatView.buffer);

        /* This method is faster than the OpenEXR implementation (very often
         * used, eg. in Ogre), with the additional benefit of rounding, inspired
         * by James Tursa?s half-precision code. */
        return function toHalfImpl(val:number) {

            floatView[0] = val;
            const x = int32View[0];

            let bits = (x >> 16) & 0x8000; /* Get the sign */
            let m = (x >> 12) & 0x07ff; /* Keep one extra bit for rounding */
            const e = (x >> 23) & 0xff; /* Using int is faster here */

            /* If zero, or denormal, or exponent underflows too much for a denormal
             * half, return signed zero. */
            if (e < 103) {
                return bits;
            }

            /* If NaN, return NaN. If Inf or exponent overflow, return Inf. */
            if (e > 142) {
                bits |= 0x7c00;
                /* If exponent was 0xff and one mantissa bit was set, it means NaN,
                    * not Inf, so make sure we set one mantissa bit too. */
                bits |= ((e === 255) ? 0 : 1) && (x & 0x007fffff);
                return bits;
            }

            /* If exponent underflows but not too much, return a denormal */
            if (e < 113) {
                m |= 0x0800;
                /* Extra rounding may overflow and set mantissa to 0 and exponent
                * to 1, which is OK. */
                bits |= (m >> (114 - e)) + ((m >> (113 - e)) & 1);
                return bits;
            }

            bits |= ((e - 112) << 10) | (m >> 1);
            /* Extra rounding. An overflow will set mantissa to 0 and increment
             * the exponent, which is OK. */
            bits += m & 1;
            return bits;
        };

    }());

    /**
     * half float (16 bit uint) to float
     * @return 32 bit float
     */
    function fromHalf(binary) : number {
        const exponent = (binary & 0x7C00) >> 10;
        const fraction = binary & 0x03FF;

        return (binary >> 15 ? -1 : 1) * (
            exponent ?
            (
                exponent === 0x1F ?
                fraction ? NaN : Infinity :
                Math.pow(2, exponent - 15) * (1 + fraction / 0x400)
            ) :
            6.103515625e-5 * (fraction / 0x400)
        );
    }

    /** temporary vector 2 on the stack */
    const _tmpVec2:Vector2[] = [];
    let _tmpVec2Allocated:number = 0;
    export function tmpVec2() : Vector2 {
        const index = _tmpVec2Allocated++;
        if(!_tmpVec2[index]) {
            _tmpVec2[index] = new Vector2();
        }
        return _tmpVec2[index].set(0,0);
    }

    /** temporary vector 3 on the stack */
    const _tmpVec3:Vector3[] = [];
    let _tmpVec3Allocated:number = 0;
    export function tmpVec3() : Vector3 {
        const index = _tmpVec3Allocated++;
        if(!_tmpVec3[index]) {
            _tmpVec3[index] = new Vector3();
        }
        return _tmpVec3[index].set(0,0,0);
    }

    /** temporary vector 4 on the stack */
    const _tmpVec4:Vector4[] = [];
    let _tmpVec4llocated:number = 0;
    export function tmpVec4() : Vector4 {
        const index = _tmpVec4llocated++;
        if(!_tmpVec4[index]) {
            _tmpVec4[index] = new Vector4();
        }
        return _tmpVec4[index].set(0,0,0,0);
    }

    /** temporary mat 4 on the stack */
    const _tmpMat4:Matrix4[] = [];
    let _tmpMat4llocated:number = 0;
    export function tmpMat4() : Matrix4 {
        const index = _tmpMat4llocated++;
        if(!_tmpMat4[index]) {
            _tmpMat4[index] = new Matrix4();
        }
        return _tmpMat4[index].identity();
    }

    /** reset temporary values */
    export function mathTemporaryReset() {
        _tmpVec2Allocated = 0;
        _tmpVec3Allocated = 0;
        _tmpVec4llocated = 0;
        _tmpMat4llocated = 0;
    }

}
