/**
 * InstancingAPI.ts: instancing API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { StaticModel } from "../render/Model";
import { AsyncLoad } from "../io/AsyncLoad";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { ComponentId } from "./Component";
import { MaterialRef } from "../render/Geometry";
import { Mesh } from "../render/Mesh";
import { queryAPI, makeAPI } from "../plugin/Plugin";
import { WorldSystem } from "./WorldAPI";

/**
 * global Instancing system handling
 * @class InstanceWrapperSystem
 * [[include:sourceDoc/Instancing.md]]
 */
export interface IInstancingSystem extends WorldSystem {

    /**
     * Creation of the InstanceObject for the pool and call for creation of the instance
     * @param filename name of the model to load
     * @param materialRefs if needed
     * @param instanceName name of instance in pool
     */
    registerModel(filename: string, materialRefs?:MaterialRef[], instanceName?: string) : AsyncLoad<StaticModel>;

    /**
     * Creation of the InstanceObject for the pool and call for creation of the instance
     * @param filename name of primitive
     * @param mesh primitive mesh created by component directly if no mesh is attached
     * @param transform transform of entity attached to it
     * @param instanceName name of instance in pool
     */
    registerMesh(name:string, mesh:Mesh, materialRefs?:MaterialRef[]);

    registerInstance(name:string, transform:Matrix4);

    removeInstance(id:ComponentId);

    /**
     * Check if the name corresponds to anything in the pool
     * @param name
     */
    isAttached(name:string) : boolean;

    /**
     * Get Mesh from pool based on primitive name
     * @param name name of primitive (string)
     */
    getMesh(name:string) : Mesh | StaticModel;

    /**
     * Called by component on update of Entity transform. Applies new transform to geometry
     * @param indexMap index of instance in Buffer
     * @param filename name of instance in poolMesh
     * @param transform new transform
     */
    updateInstance(id: ComponentId, transform: Matrix4): void;

}
export const INSTANCINGSYSTEM_API = makeAPI("IInstancingSystem");

let _globalInstancingSystem:IInstancingSystem;
export function queryInstancingSystem() {
    if(_globalInstancingSystem === undefined) {
        _globalInstancingSystem = queryAPI<IInstancingSystem>(INSTANCINGSYSTEM_API);
    }
    return _globalInstancingSystem;
}
