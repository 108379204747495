/**
 * AssetManager.ts: asset management
 *
 * [[include:sourceDoc/Asset.md]]
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Mesh as THREEMesh} from "../../lib/threejs/objects/Mesh";
import { build } from '../core/Build';
import { UpdateQueryString, parseFileExtension } from '../core/Globals';
import { EventNoArg, EventOneArg } from '../core/Events';
import { FileStat, AssetInfo, AssetInfoFile, getPreloadAssets, EAssetLoadStat } from '../io/AssetInfo';
import { StorageProvider, AssetProvider, HttpProvider } from '../io/StorageProvider';
import { findLoader, findLoaderWithExtension, EAssetType, ResolveCallback, resolveLoad, resolveFileSize } from '../io/Interfaces';
import { AsyncLoad } from '../io/AsyncLoad';
import { IDataCache, DATACACHE_API } from '../io/DataCache';
import { LoadingManager, GenericCompleteCallback } from '../io/LoadingManager';
import { FileLoader } from '../io/FileLoader';
import { ModelData, IModelLoader } from "../framework-types/ModelFileFormat";
import { queryAPI, registerAPI } from "../plugin/Plugin";
import { IImageLoader } from "../framework-types/ImageFileFormat";
import { AssetContent, IAssetManager, ASSETMANAGER_API, AssetSettings } from "./AssetAPI";
import { TextureImportDB } from "./TextureAPI";
import { MeshImportDB, applyImportSettingsMesh } from "./MeshAPI";

// default loader (auto import)
import '../framework-loader/ImageLoader';

/**
 * cache entry for images
 */
interface ImageCache {
    image:any;
    isLoaded:boolean;
    isError:boolean;
    resolver:Array<ResolveCallback>;
}

/**
 * cache entry for meshes
 */
interface MeshCache {
    mesh:any;
    isLoaded:boolean;
    isError:boolean;
    resolver:Array<ResolveCallback>;
}

interface TextCache {
    text: string;
    isLoaded:boolean;
    isError:boolean;
    resolver:Array<ResolveCallback>;
}

interface BinaryCache {
    binary: any;
    isLoaded:boolean;
    isError:boolean;
    resolver:Array<ResolveCallback>;
}

/** loading started */
const LoadStarted:EventNoArg = new EventNoArg();
/** loading finished */
const LoadFinished:EventNoArg = new EventNoArg();
/** loading failed */
const LoadFailed:EventNoArg = new EventNoArg();
/** loading progress callback (percent) */
const LoadProgress:EventOneArg<FileStat> = new EventOneArg<FileStat>();

/** asset settings */
const setup:AssetSettings = new AssetSettings();

/** internal storage provider */
let _storage:StorageProvider = build.Options.useAssetServer ? new AssetProvider() : new HttpProvider();

/** caches */
let _imageCache:{[ref:string]: ImageCache;} = {};
let _textCache:{[ref:string]: TextCache;} = {};
let _binaryCache:{[ref:string]: BinaryCache;} = {};

/** cross domain loading */
let _allowCrossDomain:boolean = _storage.forceCrossDomain();

/** is loading state */
const _loadManager:LoadingManager = new LoadingManager(_loadStart, _loadFinished, _loadProgress, _loadError);
let _isEmittingState:number = 0;

/** forced preloading items */
//let preloadAssetTypes: string[] = ["materialProvider", "materialGroup"];
//let preloadAssetPrediction: (settings:AssetInfoFile) => AssetInfoFile = null;

/** file stats */
const _assetStats:{[key:string]:AssetInfo} = {};
const _globalStats:FileStat = {loaded: 0, total: 0};

/** static initialization for startup */
function init() {

    if(build.Options.useAssetServer) {
        if(!(_storage instanceof AssetProvider)) {
            _storage = new AssetProvider();
        }
        console.info("AssetManager: Using Asset Server");
    } else {
        if(!(_storage instanceof HttpProvider)) {
            _storage = new HttpProvider();
        }
    }

    // set provider cross domain settings
    _allowCrossDomain = _allowCrossDomain || _storage.forceCrossDomain();

    // update settings
    setup.update(_storage);

    // check for datacache support
    const dataCache:IDataCache = queryAPI(DATACACHE_API);

    if(dataCache) {
        return dataCache.init();
    }

    return AsyncLoad.resolve<void>();
}

/** switch to asset server */
function useAssetServer() {
    if(_storage instanceof AssetProvider) {
        return;
    }

    _storage = new AssetProvider();
    console.info("AssetManager: Using Asset Server");
}

/**
 * create URL from path or name
 * @param path input path, can be absolute or relative
 * @param basePath base path to use (optional)
 */
function createURL(path:string, basePath?:string) : string {
    basePath = basePath || "";

    if(!path || path.length === 0) {
        console.warn("AssetManager: invalid path");
        return "";
    }

    // create url
    let url = _storage.processURL(path, basePath);

    // update url to revision tag
    if(setup.useRevisionTag && _storage.canUseRevisionTag(path)) {
        url = UpdateQueryString(url, "revTag", build.Options.revision);
    }
    if(setup.denyUpdateAccess && _storage.canUseUpdateAccess(path)) {
        url = UpdateQueryString(url, "updateAccess", false);
    }

    return url;
}

/**
 * load asset info file for preloading and settings import
 * @param filename url of asset info
 * @param mutate optional asset info mutation
 * TODO: change mutate to a more discret (asset:AssetInfo) => boolean
 */
function loadAssetInfo(filename:string, mutate?:(settings:AssetInfoFile)=>AssetInfoFile) : AsyncLoad<AssetInfoFile> {
    return loadText(filename).then( (data:string) => {
        try {

            let settings = JSON.parse(data) as AssetInfoFile;

            if(!settings || !settings["__metadata__"] || settings["__metadata__"].format !== "asset_info") {
                return AsyncLoad.reject<AssetInfoFile>(new Error("Failure reading import settings"));
            }

            // let the user prepare asset info
            mutate = mutate || setup.preloadAssetPrediction;
            if(mutate) {
                settings = mutate(settings);
            }
            if(!settings) {
                throw new Error('AssetManager: asset info prediction error');
            }

            // first add import settings and asset infos
            for(const asset of settings.assets) {
                addAssetInfo(asset);

                if(asset.runtimeImports) {
                    const DB = getAssetDB(asset);

                    if(DB) {
                        DB[asset.reference] = asset.runtimeImports;
                    }
                }
            }

            // then try to preload some data
            const preloadAssets = getPreloadAssets(settings.assets, setup.preloadAssetTypes);

            let preloadChain:AsyncLoad<any> = null;
            for(const preload of preloadAssets) {
                if(!preloadChain) {
                    preloadChain = AsyncLoad.all(_loadAssets(preload.assets));
                } else {
                    preloadChain = preloadChain.then( () => AsyncLoad.all(_loadAssets(preload.assets)));
                }
            }

            if(preloadChain) {
                return preloadChain.then( () => settings);
            } else {
                return AsyncLoad.resolve<AssetInfoFile>(settings);
            }

        } catch(err) {
            return AsyncLoad.reject<AssetInfoFile>(err);
        }
    });
}

function _loadAssets(assets:AssetInfo[]) {
    const preloadGroups = [];

    for(const asset of assets) {

        const resolver = resolveLoad(asset.type);

        if(resolver) {
            preloadGroups.push(resolver(asset.reference));
            continue;
        }

        if(asset.type === "image") {
            preloadGroups.push(loadImage(asset.reference));
        } else if(asset.type === "text") {
            preloadGroups.push(loadText(asset.reference));
        } else {
            const extension = asset.reference.split('.').pop();
            if(extension === "json") {
                preloadGroups.push(loadText(asset.reference));
            } else {
                //FIXME: DEFAULT TO BINARY?!
            }
        }
    }

    // console.log("_loadAssets: ", assets);

    // return [AsyncLoad.all(preloadGroups).then( () => {
    //     console.log("_loadAssets finished: ", assets, preloadGroups);
    // })];

    return preloadGroups;
}

/**
 * add asset informations
 * @param filename
 */
function addAssetInfo(name:string|AssetInfo, size?:number) {
    console.assert(name, "asset data missing");
    if(typeof name === 'string') {
        if(_assetStats[name]) {
            _assetStats[name].size = size || 0;
        }
    } else {
        if(_assetStats[name.reference]) {
            _assetStats[name.reference].reference = name.reference;
            _assetStats[name.reference].runtimeImports = name.runtimeImports;
            _assetStats[name.reference].preload = name.preload;
            _assetStats[name.reference].size = size || name.size;
            _assetStats[name.reference].type = name.type;
        } else {
            // add runtime data if missing
            if(!name.loaded) {
                name.loaded = { loaded: 0, total: 0 };
            }
            if(name.loadState === undefined) {
                name.loadState = EAssetLoadStat.UNKNOWN;
            }

            _assetStats[name.reference] = name;
        }
    }
}

function getAssetInfo(name:string, basePath?:string) : AssetInfo {
    if(_assetStats[name]) {
        return _assetStats[name];
    }

    return null;
}

function getLoadingProgress(preload:boolean) {
    // update global stats
    _globalStats.loaded = 0;
    _globalStats.total = 0;

    for(const fileUrl in _assetStats) {
        const preloadAsset = preload && (_assetStats[fileUrl].preload || setup.preloadAssetTypes.indexOf(_assetStats[fileUrl].type)) !== -1;

        if(_assetStats[fileUrl].loadState === EAssetLoadStat.LOADING || _assetStats[fileUrl].loadState === EAssetLoadStat.LOADED || preloadAsset) {
            _globalStats.loaded += _assetStats[fileUrl].loaded.loaded;
            _globalStats.total += _assetStats[fileUrl].loaded.total || _assetStats[fileUrl].size;
        }
    }
    return _globalStats;
}

function getLoadingManager() {
    return _loadManager;
}

/**
 * generic loading function
 * TODO: add AsyncLoad based solution
 * @param func(completeCallback)
 */
function loadGeneric(func:GenericCompleteCallback) {
    if(!func) {
        console.warn("AssetManager: no loading function");
        return;
    }

    _loadManager.itemStart("Generic");

    const complete = () => {
        _loadManager.itemEnd("Generic");
    };

    func(complete);
}

/**
 * load image (HTML)
 * @param filename
 */
function loadImage(filename:string) : AsyncLoad<any> {
    return new AsyncLoad<any>((resolve, reject) => {
        if(!filename) {
            reject(new Error("ASSET: failed to load image " + filename));
            return;
        }

        // runtime loading
        const obj = _imageCache[filename];
        if(obj) {
            // already loaded
            if(obj.isLoaded && !obj.isError) {
                resolve(obj.image);
            } else if(!obj.isLoaded && !obj.isError) {
                // currently loading -> add to resolver list
                obj.resolver.push({ resolve: resolve, reject: reject });
            } else {
                reject("Failed to load");
            }
        } else {
            // load image
            _loadImageProxy(filename, { resolve: resolve, reject: reject }, _loadManager);
        }
    });
}

/**
 * load text content
 * (json, glsl, text files)
 * @param filename
 */
function loadText(filename:string) : AsyncLoad<string> {
    return new AsyncLoad<string>((resolve, reject) => {
        if(!filename) {
            reject(new Error("ASSET: failed to load text " + filename));
            return;
        }

        // runtime loading
        const obj = _textCache[filename];
        if(obj) {
            // already loaded
            if(obj.isLoaded && !obj.isError) {
                resolve(obj.text);
            } else if(!obj.isLoaded && !obj.isError) {
                // currently loading -> add to waiting list
                obj.resolver.push({ resolve: resolve, reject: reject });
            } else {
                // failed to load
                reject("failed");
            }
        } else {
            // load text
            _loadTextProxy(filename, { resolve: resolve, reject: reject }, _loadManager);
        }
    });
}

function loadBinary(filename:string) : AsyncLoad<any> {
    return new AsyncLoad<any>((resolve, reject) => {
        if(!filename) {
            reject(new Error("ASSET: failed to binary, no filename present"));
            return;
        }

        // runtime loading
        const obj = _binaryCache[filename];
        if(obj) {
            // already loaded
            if(obj.isLoaded && !obj.isError) {
                resolve(obj.binary);
            } else if(!obj.isLoaded && !obj.isError) {
                // currently loading -> add to waiting list
                obj.resolver.push({ resolve: resolve, reject: reject });
            } else {
                // failed to load
                reject("failed");
            }
        } else {
            // load text
            _loadBinaryProxy(filename, { resolve: resolve, reject: reject }, _loadManager);
        }
    });
}

/**
 * load asset bundle from url
 * @param filename url
 * @return AssetContent files that are loading or got loaded
 */
function loadAssetBundle(filename:string) : AsyncLoad<AssetContent[]> {
    if(!build.Options.libraries.JSZip) {
        console.error("AssetManager: cannot load bundle file, JSZip not available!");
        return AsyncLoad.reject(new Error("AssetManager: cannot load bundle file, JSZip not available!"));
    }

    return new AsyncLoad<AssetContent[]>((resolve, reject) => {
        // notify load start
        _loadStart(filename, 0, 1);

        const xhr = new XMLHttpRequest();
        xhr.open('GET', filename, true);
        xhr.responseType = "arraybuffer";

        //older browsers
        if(xhr.overrideMimeType) {
            xhr.overrideMimeType("text/plain; charset=x-user-defined");
        }

        xhr.onreadystatechange = (evt) => {
            // use `xhr` and not `this`... thanks IE
            if(xhr.readyState === 4) {
                if(xhr.status === 200 || xhr.status === 0) {
                    let err = null;
                    try {
                        const data = xhr.response || xhr.responseText;

                        JSZip.loadAsync(data).then((zip) => {
                            addAssetBundle(zip).then(resolve, reject);
                        },
                        reject);

                    } catch(e) {
                        err = new Error(e);
                    }
                } else {
                    reject(new Error("AssetManager: ajax error for " + filename + " : " + xhr.status + " " + xhr.statusText));
                }
                // finished with loading
                _loadFinished(filename);
            }
        };

        xhr.send();
    });
}

/**
 * add objects from bundle file to asset manager
 * @param zip JSZip instance
 */
function addAssetBundle(zip:any) : AsyncLoad<AssetContent[]> {
    if(!build.Options.libraries.JSZip) {
        console.error("AssetManager: cannot load bundle file, JSZip not available!");
        return AsyncLoad.reject(new Error("AssetManager: cannot load bundle file, JSZip not available!"));
    }

    const _internalLoad = (filesLoad:AsyncLoad<AssetContent>[]) => {
        // all files loaded
        return AsyncLoad.all(filesLoad).then( (contents:AssetContent[]) => {
            console.log(contents);

            for(const content of contents) {

                if(content.type === "text") {
                    addText(content.file, content.data);
                } else if(content.type === "image") {
                    // assuming image
                    addImage(content.file, content.data);
                } else if(content.type === "binary") {
                    // raw binary data
                    addBinary(content.file, content.data);
                }
            }

            // finished loading all files
            return contents;
        },
        (err) => {
            return AsyncLoad.reject(err);
        });
    };

    return new AsyncLoad<AssetContent[]>( (resolve, reject) => {

        if(build.Options.debugAssetOutput) {
            console.log("AssetManager: loading bundle file with ", zip);
        }

        const bundleFile = zip.file("_bundle.json");

        // bundle file description
        if(bundleFile) {
            bundleFile.async('string').then( (bundleString:string) => {

                const bundle = JSON.parse(bundleString);

                const assets = bundle.assets as AssetContent[];
                const filesLoad:AsyncLoad<AssetContent>[] = [];

                for(const asset of assets) {

                    if(!asset.file) {
                        console.warn("AssetManager: asset in bundle has invalid reference ", asset);
                        continue;
                    }

                    const fileExtension = asset.file.split('.').pop();
                    let dataType = "unknown";

                    switch(asset.type) {
                        case "text":
                        case "string":
                            dataType = "string";
                            break;
                        case "model":

                            //FIXME: this is shit, ask for datatype at loaders directly?
                            if(asset.loaderIdentifier === "geomLoader") {
                                dataType = "string";
                            } else {
                                if(fileExtension === "red") {
                                    dataType = "arraybuffer";
                                } else if(fileExtension === "json") {
                                    dataType = "string";
                                }
                            }
                            break;
                        case "image":
                            dataType = "base64";
                            break;
                    }

                    if(dataType === "unknown") {
                        console.warn("AssetManager: asset in bundle has invalid type ", asset);
                        continue;
                    }

                    filesLoad.push(new AsyncLoad<AssetContent>( (resolveAsset, rejectAsset) => {

                        const file = zip.file(asset.file);

                        if(file) {
                            file.async(dataType).then( (data:any) => {
                                resolveAsset({
                                    file: asset.file,
                                    type: asset.type,
                                    loaderIdentifier: asset.loaderIdentifier,
                                    dataType: dataType,
                                    data: data
                                });
                            },
                            rejectAsset);
                        } else {
                            rejectAsset(new Error("AssetManager: cannot find file '"+asset.file+"' in bundle"));
                        }
                    }));

                }

                _internalLoad(filesLoad).then(resolve, reject);
            }, reject);

        } else {

            const filesLoad:AsyncLoad<AssetContent>[] = [];

            // go through all files
            zip.forEach( (relativePath:string, zipObject:any) => {

                // ignoring directories
                if(zipObject.dir) {
                    return;
                }

                const fileExtension = relativePath.split('.').pop();

                // resolve asset content
                let type = "unknown";
                let dataType = "arraybuffer";
                if(fileExtension === "json" || fileExtension === "txt") {
                    dataType = "string";
                    type = "text";
                } else if(fileExtension === "red") {
                    dataType = "arraybuffer";
                    type = "model";
                } else if(fileExtension === "png" || fileExtension === "jpg") {

                    dataType = "base64";
                    type = "image";
                }

                filesLoad.push(new AsyncLoad<AssetContent>( (resolveAsset, rejectAsset) => {

                    zipObject.async(dataType).then( (data:any) => {
                        resolveAsset({
                            file: relativePath,
                            type: type,
                            dataType: dataType,
                            data: data
                        });
                    },
                    rejectAsset);
                }));

            });

            _internalLoad(filesLoad).then(resolve, reject);
        }
    });
}

/**
 * add text to asset management
 * @param name reference name
 * @param content content string
 */
function addText(name:string, content:string|Blob) {

    if(!name) {
        console.warn("AssetManager: invalid name for content ", content);
        return;
    }

    if(!content) {
        console.warn("AssetManager: empty data for '"+name+"'");
        return;
    }

    if(_textCache[name] && build.Options.debugAssetOutput) {
        // overwriting cache entry
        console.info("AssetManager: overwriting cache entry '"+name+"'");
    }

    if(!_textCache[name]) {
        // generate simple loading cache entry
        _textCache[name] = {
            text: null,
            isLoaded: false,
            isError: false,
            resolver: []
        };
    }

    if(content instanceof Blob) {
        const reader = new FileReader();

        reader.onload = (event) => {
            _textCache[name].text = reader.result as string;
            _textCache[name].isLoaded = true;
        };

        reader.onerror = (event) => {
            _textCache[name].isLoaded = false;
            _textCache[name].isError = true;
        };

        reader.readAsText(content);

    } else {

        _textCache[name].text = content;
        _textCache[name].isLoaded = true;

    }

    _emitLoadingState();
}

/**
 * add binary content to asset manager
 * @param name name of binary content
 * @param content binary data
 */
function addBinary(name:string, content:any) {
    if(!name) {
        console.warn("AssetManager: invalid name for content ", content);
        return;
    }

    if(!content) {
        console.warn("AssetManager: empty data for '"+name+"'");
        return;
    }

    if(_binaryCache[name] && build.Options.debugAssetOutput) {
        // overwriting cache entry
        console.info("AssetManager: overwriting cache entry '"+name+"'");
    }

    if(!_binaryCache[name]) {
        // generate simple loading cache entry
        _binaryCache[name] = {
            binary: null,
            isLoaded: false,
            isError: false,
            resolver: []
        };
    }

    if(content instanceof Blob) {
        const reader = new FileReader();

        reader.onload = (event) => {
            _binaryCache[name].binary = reader.result;
            _binaryCache[name].isLoaded = true;
        };

        reader.onerror = (event) => {
            _binaryCache[name].isLoaded = false;
            _binaryCache[name].isError = true;
        };

        reader.readAsText(content);

    } else {
        _binaryCache[name].binary = content;
        _binaryCache[name].isLoaded = true;
    }

    _emitLoadingState();
}

/**
 * add an image to asset management
 * @param name reference name
 * @param content image content
 * @param mimeType image mime type
 */
function addImage(name:string, content:string|ArrayBuffer|Blob|File|HTMLImageElement, mimeType?:string) {

    if(!name) {
        console.warn("AssetManager: invalid name for content ", content);
        return;
    }

    if(!content) {
        console.warn("AssetManager: empty data for '"+name+"'");
        return;
    }

    if(_imageCache[name] && build.Options.debugAssetOutput) {
        // overwriting cache entry
        console.info("AssetManager: overwriting cache entry '"+name+"'");
    }

    let img = null;

    if(!mimeType) {
        const fileExtension = name.split('.').pop();

        if(fileExtension === "jpg") {
            mimeType = "image/jpeg";
        } else if(fileExtension === "png") {
            mimeType = "image/png";
        }
    }

    // assign default
    if(!mimeType) {
        mimeType = "image/jpeg";
    }

    if(content instanceof Image) {
        img = content;

    } else if(content instanceof ArrayBuffer) {

        //const buffer = new Uint8Array(content);
        const blob = new Blob([content], { type: mimeType });
        const imageUrl = window.URL.createObjectURL(blob);

        img = new Image();

        img.onload = function() {
            window.URL.revokeObjectURL(imageUrl);
        };

        img.src = imageUrl;

    } else if(content instanceof Blob) {
        const imageUrl = window.URL.createObjectURL(content);
        window.open(imageUrl);

        img.onload = function() {
            window.URL.revokeObjectURL(imageUrl);
        };

        img.src = imageUrl;
    } else {

        // assuming base64
        img = new Image();

        if(content.charAt(0) === "d" && content.charAt(1) === "a" && content.charAt(2) === "t" && content.charAt(3) === "a") {
            img.src = content;
        } else {
            img.src = "data:" + mimeType + ";base64," + content;
        }
    }

    if(img) {
        // generate new entry
        if(!_imageCache[name]) {
            _imageCache[name] = {
                image: null,
                isError: false,
                isLoaded: false,
                resolver: []
            };
        }

        _imageCache[name].image = img;
        _imageCache[name].isLoaded = true;
    }

    _emitLoadingState();
}

/**
 * flush all caches
 * should result in reloading all models
 */
function flushCaches() {
    //FIXME: clean up references??
    _textCache = {};
    _binaryCache = {};
    _imageCache = {};
}

/**
 * emit all loading states to other
 */
function _emitLoadingState() {

    _isEmittingState += 1;

    // already emitting
    if(_isEmittingState > 1) {
        return;
    }

    function finishCache(cache:any) {
        for(const element in cache) {
            const obj = cache[element];

            if(obj.isLoaded && !obj.isError) {
                // call waiting list
                if(obj.resolver) {
                    // call waiting list
                    for(let i = 0; i < obj.resolver.length; ++i) {
                        if(obj.text) {
                            obj.resolver[i].resolve(obj.text);
                        } else if(obj.binary) {
                            obj.resolver[i].resolve(obj.binary);
                        } else if(obj.mesh) {
                            obj.resolver[i].resolve(obj.mesh);
                        } else if(obj.image) {
                            obj.resolver[i].resolve(obj.image);
                        } else if(obj.texture) {
                            obj.resolver[i].resolve(obj.texture);
                        } else {
                            //FIXME: reject?
                            obj.resolver[i].resolve(null);
                        }
                    }

                    obj.resolver.length = 0;
                }

            } else if(obj.isError) {
                //TODO: add reject code

                // call waiting list
                for(let i = 0; i < obj.resolver.length; ++i) {
                    if(obj.resolver[i].reject) {
                        obj.resolver[i].reject(new Error("file not found"));
                    }
                }

                obj.resolver.length = 0;
            } else {
                // isLoaded == false && isError == false

                //THIS can happen for objects that are invalid

                //FIXME: wait for it?
                //console.warn("Not fully loaded: ", obj);
            }
        }
    }

    // resolve all elements in right order
    while(_isEmittingState > 0) {

        finishCache(_textCache);
        finishCache(_binaryCache);
        finishCache(_imageCache);

        _isEmittingState -= 1;
    }
}

/** loading start */
function _loadStart(url:string, loadedItems:number, totalItems:number) {
    // update url stats
    if(_assetStats[url]) {
        _assetStats[url].loadState = EAssetLoadStat.LOADING;
        // don't know correct size yet
        _assetStats[url].loaded.total = _assetStats[url].loaded.total || _assetStats[url].size;
    } else {
        addAssetInfo({ reference: url, runtimeImports: null, preload: false, type: "unknown", size: 0, loaded: {loaded: 0, total: 0}, loadState: EAssetLoadStat.LOADING });
    }

    _emitLoadingProgress();

    LoadStarted.trigger();
}

/** loading progresss */
function _loadProgress(url:string, loaded:number, total:number) {
    // update url stats
    if(_assetStats[url]) {
        console.assert(_assetStats[url].loadState === EAssetLoadStat.LOADING || _assetStats[url].loadState === EAssetLoadStat.UNKNOWN, "wrong load state " + EAssetLoadStat[_assetStats[url].loadState]);
        _assetStats[url].loadState = EAssetLoadStat.LOADING;
    } else {
        addAssetInfo({ reference: url, runtimeImports: null, preload: false, type: "unknown", size: 0, loaded: {loaded: 0, total: 0}, loadState: EAssetLoadStat.LOADING });
        console.warn("AssetManager: loading '" + url + "' never started loading");
    }

    // update url stats
    _assetStats[url].loaded.loaded = loaded;
    _assetStats[url].loaded.total = total || _assetStats[url].size;

    // update global stats
    _emitLoadingProgress();
}

/** loading finished */
function _loadFinished(url: string) {
    // update url stats
    if(_assetStats[url]) {
        console.assert(_assetStats[url].loadState === EAssetLoadStat.LOADED || _assetStats[url].loadState === EAssetLoadStat.LOADING || _assetStats[url].loadState === EAssetLoadStat.UNKNOWN, "wrong load state " + EAssetLoadStat[_assetStats[url].loadState]);
        _assetStats[url].loadState = EAssetLoadStat.LOADED;
    } else {
        addAssetInfo({ reference: url, runtimeImports: null, preload: false, type: "unknown", size: 0, loaded: {loaded: 0, total: 0}, loadState: EAssetLoadStat.ERROR });
        console.warn("AssetManager: loading '" + url + "' never started loading");
    }

    _emitLoadingState();
    _emitLoadingProgress();

    //FIXME: only when loadCounter === 0???
    // trigger that loading has finished
    LoadFinished.trigger();
}

/** loading failed for one item */
function _loadError(url:string, err?:any) {
    console.error("AssetManager: loading failed ", url);

    // update url stats
    if(_assetStats[url]) {
        console.assert(_assetStats[url].loadState === EAssetLoadStat.LOADING || _assetStats[url].loadState === EAssetLoadStat.UNKNOWN, "wrong load state " + EAssetLoadStat[_assetStats[url].loadState]);
        _assetStats[url].loadState = EAssetLoadStat.ERROR;
    } else {
        addAssetInfo({ reference: url, runtimeImports: null, preload: false, type: "unknown", size: 0, loaded: {loaded: 0, total: 0}, loadState: EAssetLoadStat.ERROR });
        console.warn("AssetManager: loading '" + url + "' never started loading");
    }

    _emitLoadingState();
    _emitLoadingProgress();

    LoadFailed.trigger();
}

function _resolveFileSize(asset:AssetInfo, assets:{[key:string]:AssetInfo}) : number {
    const resolve = resolveFileSize(asset.type);
    return resolve ? resolve(asset, assets) : asset.size;
}

function _emitLoadingProgress() {
    _globalStats.loaded = 0;
    _globalStats.total = 0;

    for(const fileUrl in _assetStats) {
        const preloadAsset = (_assetStats[fileUrl].preload || setup.preloadAssetTypes.indexOf(_assetStats[fileUrl].type)) !== -1;

        if(_assetStats[fileUrl].loadState === EAssetLoadStat.LOADING || _assetStats[fileUrl].loadState === EAssetLoadStat.LOADED || preloadAsset) {
            _globalStats.loaded += _assetStats[fileUrl].loaded.loaded;
            _globalStats.total += _assetStats[fileUrl].loaded.total || _resolveFileSize(_assetStats[fileUrl], _assetStats);
        }
    }

    LoadProgress.trigger(_globalStats);
}

/**
 * internal image loading
 */
function _loadImageProxy(name:string, resolve:ResolveCallback, loadingManager:LoadingManager, loaderIdentifier?:string) {
    if(!name) {
        console.warn("ASSET: (_loadImageProxy) failed to load image ", name);
        return;
    }

    if(_imageCache[name]) {
        // check if already loaded
        if(_imageCache[name].isLoaded) {
            //SHOULD BE HANDLED BEFORE!!!
            console.warn("ASSET: image already loaded " + name);
            if(resolve) {
                resolve.resolve(_imageCache[name].image);
            }
        } else {
            if(resolve) {
                _imageCache[name].resolver.push(resolve);
            }
        }
        return;
    }

    // generate simple image cache
    _imageCache[name] = {
        image: null,
        isLoaded: false,
        isError: false,
        resolver: []
    };

    if(resolve) {
        _imageCache[name].resolver.push(resolve);
    }

    //texture loading
    //TODO: verify filename extension and load through TGA/DDS loader
    //const imageLoader = new ImageLoader(loadingManager);

    let loaderClass:any = null;

    if(loaderIdentifier) {
        // find loader class
        loaderClass = findLoader(loaderIdentifier, EAssetType.Model);
    } else {
        // try to find with file extension
        const fileExtension = parseFileExtension(name);
        // find loader class
        loaderClass = findLoaderWithExtension(fileExtension, EAssetType.Image);
    }

    if(!loaderClass) {
        console.error("AssetManager: missing image loader: " + loaderIdentifier + " " + name);
        _imageCache[name].isError = true;
        _imageCache[name].isLoaded = false;
        return;
    }

    //TODO: get list of objects that need pre loading
    const imageLoader:IImageLoader = new loaderClass(loadingManager);

    // create url
    let url = _storage.processURL(name, setup.baseTexturePath);

    if(_allowCrossDomain) {
        imageLoader.crossOrigin = "Anonymous";
    }

    // update url to revision tag
    if(setup.useRevisionTag && _storage.canUseRevisionTag(name)) {
        url = UpdateQueryString(url, "revTag", build.Options.revision);
    }
    if(setup.denyUpdateAccess && _storage.canUseUpdateAccess(name)) {
        url = UpdateQueryString(url, "updateAccess", false);
    }

    if(build.Options.debugAssetOutput) {
        console.info("ASSET: trying to load Image " + name);
    }
    try {
        imageLoader.load(url, name, (image:any) => {
            //check valid image object
            if(image && image.width > 0 && image.height > 0) {

                if(build.Options.debugAssetOutput) {
                    console.info("ASSET: loaded Image " + name);
                }
                _imageCache[name].image = image;
                _imageCache[name].isLoaded = true;

            } else {
                _imageCache[name].isError = true;
                console.warn("ASSET: failed image " + name);
            }
        },
        // progress
        (event:any) => {
            //TODO: remove
            //console.info(event);
        },
        // error
        (status:any) => {
            _imageCache[name].isError = true;
            //console.warn("ASSET: (_loadImageProxy) "+name+" ERROR: ", status);
        });

    } catch(err) {
        //console.warn(err);
        _imageCache[name].isError = true;
    }
}

/**
 * internal text loading
 */
function _loadTextProxy(name:string, resolve:any, loadingManager:LoadingManager) {
    if(!name) {
        console.warn("ASSET: (_loadTextProxy) failed to load text ", name);
        return;
    }

    if(_textCache[name]) {
        // check if already loaded
        if(_textCache[name].isLoaded) {
            console.warn("ASSET: text already loaded " + name);

            if(resolve) {
                resolve.resolve(_textCache[name].text);
            }
        } else {
            if(resolve) {
                _textCache[name].resolver.push(resolve);
            }
        }
        return;
    }

    // generate simple image cache
    _textCache[name] = {
        text: null,
        isLoaded: false,
        isError: false,
        resolver: []
    };

    if(resolve) {
        _textCache[name].resolver.push(resolve);
    }

    // text loading
    const textLoader = new FileLoader(loadingManager);

    const isShaderFile = name.split('.').pop() === "glsl";
    const basePath = isShaderFile ? setup.baseShaderPath : setup.baseTextPath;

    // create url
    let url = _storage.processURL(name, basePath);

    if(_allowCrossDomain) {
        textLoader['crossOrigin'] = "Anonymous";
    }

    // update url to revision tag
    if(setup.useRevisionTag && _storage.canUseRevisionTag(name)) {
        url = UpdateQueryString(url, "revTag", build.Options.revision);
    }
    if(setup.denyUpdateAccess && _storage.canUseUpdateAccess(name)) {
        url = UpdateQueryString(url, "updateAccess", false);
    }

    textLoader.load(url, name, (data:any) => {
        if(data) {
            if(build.Options.debugAssetOutput) {
                console.info("ASSET: loaded text " + name);
            }

            _textCache[name].text = data;
            _textCache[name].isLoaded = true;
        } else {
            _textCache[name].isError = true;
            console.warn("ASSET: failed text" + name);
        }
    },
    (progress:any) => {},
    (status:any) => {
        //console.warn("ASSET: (_loadTextProxy) "+name+" ERROR: ", status);
        _textCache[name].isError = true;
    });
}

/**
 * internal text loading
 */
function _loadBinaryProxy(name:string, resolve:any, loadingManager:LoadingManager) {
    if(!name) {
        console.warn("ASSET: (_loadTextProxy) failed to load text ", name);
        return;
    }

    if(_binaryCache[name]) {
        // check if already loaded
        if(_binaryCache[name].isLoaded) {
            console.warn("ASSET: text already loaded " + name);

            if(resolve) {
                resolve.resolve(_binaryCache[name].binary);
            }
        } else {
            if(resolve) {
                _binaryCache[name].resolver.push(resolve);
            }
        }
        return;
    }

    // generate simple image cache
    _binaryCache[name] = {
        binary: null,
        isLoaded: false,
        isError: false,
        resolver: []
    };

    if(resolve) {
        _binaryCache[name].resolver.push(resolve);
    }

    // text loading
    const textLoader = new FileLoader(loadingManager);
    textLoader.responseType = "arraybuffer" as XMLHttpRequestResponseType;

    const isShaderFile = name.split('.').pop() === "glsl";
    const basePath = isShaderFile ? setup.baseShaderPath : setup.baseTextPath;

    // create url
    let url = _storage.processURL(name, basePath);

    if(_allowCrossDomain) {
        textLoader['crossOrigin'] = "Anonymous";
    }

    // update url to revision tag
    if(setup.useRevisionTag && _storage.canUseRevisionTag(name)) {
        url = UpdateQueryString(url, "revTag", build.Options.revision);
    }
    if(setup.denyUpdateAccess && _storage.canUseUpdateAccess(name)) {
        url = UpdateQueryString(url, "updateAccess", false);
    }

    textLoader.load(url, name, (data:any) => {
        if(data) {
            if(build.Options.debugAssetOutput) {
                console.info("ASSET: loaded text " + name);
            }

            _binaryCache[name].binary = data;
            _binaryCache[name].isLoaded = true;
        } else {
            _binaryCache[name].isError = true;
            console.warn("ASSET: failed text" + name);
        }
    },
    (progress:any) => {},
    (status:any) => {
        //console.warn("ASSET: (_loadTextProxy) "+name+" ERROR: ", status);
        _binaryCache[name].isError = true;
    });
}

function getAssetDB(asset: AssetInfo) {
    switch(asset.type) {
        case "model": return MeshImportDB;
        case "image": return TextureImportDB;
    }
    return null;
}

export const AssetManager:IAssetManager = {
    LoadFailed,
    LoadFinished,
    LoadProgress,
    LoadStarted,
    setup,
    init,
    createURL,
    loadAssetInfo,
    useAssetServer,
    addAssetInfo,
    getAssetInfo,
    loadGeneric,
    loadBinary,
    loadImage,
    loadText,
    loadAssetBundle,
    addImage,
    addText,
    addBinary,
    flushCaches,
    getLoadingProgress,
    getLoadingManager
};
registerAPI(ASSETMANAGER_API, AssetManager);
