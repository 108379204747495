/**
 * MeshComponent.ts: mesh
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Color } from "../../lib/threejs/math/Color";
import { BoxHelper } from "../../lib/threejs/helpers/BoxHelper";
import {GraphicsDisposeSetup, cloneObject} from '../core/Globals';
import {IONotifier} from '../io/Interfaces';
import {Component, ComponentData, registerComponent, ComponentId} from '../framework/Component';
import {Entity} from '../framework/Entity';
import {MaterialRef} from '../render/Geometry';
import {StaticModel, loadModel } from '../render/Model';
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { ECollisionBehaviour, CollisionLayer, CollisionLayerDefaults, ICollisionSystem, COLLISIONSYSTEM_API } from "../framework/CollisionAPI";
import { build } from "../core/Build";
import { ERenderLayer } from "../render/Layers";
import { Mesh } from "../render/Mesh";
import { queryMeshSystem } from "../framework/MeshAPI";

/** MeshComponent parameters */
export interface MeshComponentParams {
    filename:string;
    loaderIdentifier?: string;
    preload?: boolean;
    visible?: boolean;
    visibleState?:{[key:string]:boolean};
    castShadow?: boolean;
    receiveShadow?: boolean;
    collision?: ECollisionBehaviour;
    collisionLayer?: CollisionLayer;
    materialRefs?: MaterialRef[];
    renderLayer?: number;
    renderOrder?: number;
    instantiatePrefabs?: boolean | PrefabCallback;
    // debugging
    debugHelper?: boolean;
}

export type PrefabCallback = (params:any) => void;

/**
 * Mesh Component class
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "MeshComponent",
 *     "parameters": {
 *         "filename": "polyplane.json",
 *         "loaderIdentifier": "redModel",
 *         "visible": boolean,
 *         "debugHelper": false,
 *         "castShadow": boolean,
 *         "receiveShadow": boolean,
 *         "collision": number,
 *         "materialRefs": [
 *              { name: "original", ref: "newName" }
 *         ]
 *         "renderLayer": number
 *         "renderOrder": number
 *     }
 * }
 * ~~~~
 */
export class MeshComponent extends Component {

    /** model reference */
    //TODO: add readonly prefix
    public model:StaticModel = null;

    /** custom reference setup */
    public get materialRefs() : MaterialRef[] {
        return this._materialRefs;
    }

    /** visible state */
    public get visible() : boolean {
        if(this._meshRootNode) {
            return this._meshRootNode.visible;
        }
        return this._visibleFlag;
    }

    /** visible state */
    public set visible(value:boolean) {
        // always store this value
        this._visibleFlag = value;

        if(this._meshRootNode) {
            //TODO: check if this needs to be set recursive
            this._meshRootNode.visible = value;
        }
        for(const mesh of this._meshes) {
            mesh.visible = value;
        }
    }

    /** bounds visible state */
    public set boundsVisible(value:boolean) {
        if(this._meshRootNode) {
            if(this._boundingBoxHelper == null) {
                this._boundingBoxHelper = new BoxHelper(this._meshRootNode, new Color(0xffff00));
                this.threeJSScene.add(this._boundingBoxHelper);
            }
            this._boundingBoxHelper.visible = value;
        } else {
            if(this._boundingBoxHelper == null) {
                this._boundingBoxHelper = new BoxHelper(this._entityRef, new Color(0xffff00));
                this.threeJSScene.add(this._boundingBoxHelper);
            }
            this._boundingBoxHelper.visible = value;
        }
    }

    /** bounds visible state */
    public get boundsVisible() : boolean {
        if(this._boundingBoxHelper) {
            return this._boundingBoxHelper.visible;
        }
        return false;
    }

    /** collision detection */
    public get collision() : ECollisionBehaviour {
        if(build.Options.isEditor) {
            return ECollisionBehaviour.Bounds;
        }
        return this._collisionBehaviour;
    }

    public set collision(value:ECollisionBehaviour) {
        if(this._collisionBehaviour !== value) {
            // apply new
            this._collisionBehaviour = value;
            // apply to collision system
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if(collisionSystem) {
                for(const id of this._collisionId) {
                    collisionSystem.removeCollisionObject(id);
                }
                this._collisionId = [];
                if(this.collision !== ECollisionBehaviour.None) {
                    if(this.model) {
                        this._collisionId = [collisionSystem.registerCollisionModel(this.model, this.entity, this.collision, this._collisionLayer)];
                    } else if(this._meshes.length) {
                        for(const mesh of this._meshes) {
                            this._collisionId.push(collisionSystem.registerCollisionMesh(mesh, this.entity, this.collision, this._collisionLayer));
                        }
                    }
                }
            }
        }
    }

    public get collisionLayer() : CollisionLayer {
        return this._collisionLayer;
    }

    public set collisionLayer(value:CollisionLayer) {
        if(this.collisionLayer !== value) {
            this._collisionLayer = value;
            // update layer
            if(this._collisionId.length) {
                const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
                for (const id of this._collisionId) {
                    collisionSystem.updateCollisionObjectLayer(id, this.collisionLayer);
                }
            }
        }
    }

    /** shadow setup */
    public get castShadow() : boolean {
        return this._castShadow;
    }
    public set castShadow(value:boolean) {
        // always store this value
        this._castShadow = value;

        if(this.model) {
            //TODO: check if this needs to be set recursive
            this.model.castShadow = this._castShadow;
        }
        for(const mesh of this._meshes) {
            mesh.castShadow = this._castShadow;
        }
    }

    public get receiveShadow() : boolean {
        return this._receiveShadow;
    }
    public set receiveShadow(value:boolean) {
        // always store this value
        this._receiveShadow = value;

        if(this.model) {
            //TODO: check if this needs to be set recursive
            this.model.receiveShadow = this._receiveShadow;
        }
        for(const mesh of this._meshes) {
            mesh.receiveShadow = this._receiveShadow;
        }
    }

    /** render layer */
    public get renderLayer() : number {
        return this._renderLayer;
    }
    public set renderLayer(value:number) {
        // always store this value
        this._renderLayer = value;

        if(this.model) {
            //TODO: check if this needs to be set recursive
            this.model.setRenderLayer(this._renderLayer);
        }
        for(const mesh of this._meshes) {
            mesh.layers.set(this._renderLayer);
        }
    }
    /** render order */
    public get renderOrder() : number|undefined {
        return this._renderOrder;
    }
    public set renderOrder(value:number|undefined) {
        // always store this value
        this._renderOrder = value;

        if(this.model) {
            //TODO: check if this needs to be set recursive
            this.model.setRenderOrder(this._renderOrder);
        }
        for(const mesh of this._meshes) {
            mesh.renderOrder = this._renderOrder;
        }
    }

    /** visibility */
    private _visibleFlag:boolean;
    private _visibleState?:{[key:string]:boolean};

    /** three js model scene */
    private _meshRootNode:any;
    private _materialRefs:MaterialRef[];
    private _meshes:Mesh[];
    private _filename:string;

    /** helper */
    private _boundingBoxHelper:any = null;

    /** collision test state */
    private _collisionId:ComponentId[];
    private _collisionBehaviour: ECollisionBehaviour;
    private _collisionLayer: CollisionLayer;

    /** shadows */
    private _castShadow: boolean;
    private _receiveShadow: boolean;

    /** rendering */
    private _renderLayer: number;
    private _renderOrder: number|undefined;

    /** initialization */
    constructor(entity:Entity) {
        super(entity);
        this._materialRefs = [];
        this._meshes = [];
        this._filename = "";
        this._meshRootNode = null;
        this._visibleFlag = false;
        this._collisionBehaviour = ECollisionBehaviour.None;
        this._collisionId = [];
        this._collisionLayer = CollisionLayerDefaults.Default;

        this._castShadow = true;
        this._receiveShadow = true;

        this._renderLayer = ERenderLayer.World;
        this._renderOrder = undefined;
    }

    /** cleanup */
    public destroy(dispose?:GraphicsDisposeSetup) {

        // clean up helper
        if(this._boundingBoxHelper && this.threeJSScene) {
            this.threeJSScene.remove(this._boundingBoxHelper);
            this._boundingBoxHelper = null;
        }

        this._cleanupMesh();
        super.destroy(dispose);
    }

    /** game loop */
    public think() {
        if(this.model && this._meshRootNode) {
            const isLoading = this._isLoading();

            if(isLoading) {
                this._meshRootNode.visible = false;
            } else {
                this._meshRootNode.visible = this._visibleFlag;
                this.needsThink = false;
            }
        } else if(this._meshes.length > 0) {
            const isLoading = this._isLoading();
            if(isLoading) {
                for(const mesh of this._meshes) {
                    mesh.visible = false;
                }
            } else {
                for(const mesh of this._meshes) {
                    mesh.visible = this._visibleFlag;
                }
                this.needsThink = false;
            }
        }
    }

    public onTransformUpdate() {
        if(this._collisionId.length) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            for(const id of this._collisionId) {
                collisionSystem.updateTransform(id);
            }
        }
    }

    /**
     * set new mesh on component
     * @param modelOrParams model instance or component parameters
     * @param ioNotifier notifier when loaded
     */
    public setMesh(modelOrParams:StaticModel|MeshComponentParams|Mesh[], ioNotifier?:IONotifier) {

        if(!this._isValid) {
            console.warn("MeshComponent: setting mesh on destroyed/invalid Component");
            return;
        }

        // cleanup
        this._cleanupMesh();

        if(modelOrParams instanceof StaticModel) {

            if(ioNotifier) {
                ioNotifier.startLoading();
            }

            // directly set
            this._setMesh(modelOrParams, true, ioNotifier);

            if(ioNotifier) {
                ioNotifier.finishLoading();
            }
        } else if(Array.isArray(modelOrParams)) {
            if(ioNotifier) {
                ioNotifier.startLoading();
            }

            // directly set
            this._setMesh(modelOrParams, true, ioNotifier);

            if(ioNotifier) {
                ioNotifier.finishLoading();
            }

        } else {
            // setup through param loading
            this._loadMesh(modelOrParams, ioNotifier);
        }
    }

    /**
     * override materials to new material name
     * @param materials material references
     */
    public setMaterialRefs(materials:MaterialRef[]) {
        // can be incomplete
        this._materialRefs = materials;

        if(this.model) {
            this.model.setMaterialRefs(materials);
        }
        for(const mesh of this._meshes) {
            mesh.setMaterialRef(materials);
        }
    }

    /**
     * asynchronous loading function
     * @param params
     * @param ioNotifier
     */
    private _loadMesh(params:MeshComponentParams, ioNotifier?:IONotifier) {

        if(ioNotifier) {
            ioNotifier.startLoading();
        }

        // not able to load new model
        if(!params.filename) {
            if(ioNotifier) {
                ioNotifier.finishLoading();
            }
            return;
        }

        // set filename reference
        this._filename = params.filename;

        //read shadow settings
        if(params.castShadow !== undefined) {
            this._castShadow = params.castShadow === true;
        }
        if(params.receiveShadow !== undefined) {
            this._receiveShadow = params.receiveShadow === true;
        }
        // set layer mask
        if(params.renderLayer !== undefined) {
            this._renderLayer = params.renderLayer;
        } else {
            this._renderLayer = ERenderLayer.World;
        }
        // set render order
        if(params.renderOrder !== undefined) {
            this._renderOrder = params.renderOrder;
        }

        // set material references
        if(params.materialRefs) {
            this.setMaterialRefs(params.materialRefs);
        }

        if(params.collision) {
            this._collisionBehaviour = params.collision;
        }

        if (params.collisionLayer) {
            this._collisionLayer = params.collisionLayer;
        }

        // set visibility
        if(params.visible!==undefined) {
            this._visibleFlag = params.visible;
        } else {
            //default value
            this._visibleFlag = true;
        }

        // set visibility for Meshes
        if(params.visibleState !== undefined) {
            this._visibleState = params.visibleState;
        }

        const instantiatePrefabs = params.instantiatePrefabs === undefined ? true : params.instantiatePrefabs;

        const modelFilename = this._extractModelName();

        // load mesh
        loadModel(modelFilename, params.loaderIdentifier, false, params.preload).then((model:StaticModel) => {
            // not valid anymore -> skip
            if(!this._isValid) {
                console.warn("MeshComponent: finished loading of model on destroyed/invalid Component");
                return;
            }

            const submeshes = this._extractNodeOrIndex();

            if(submeshes === false) {
                // not passing ioNotifier as this is getting called here
                this._setMesh(model, instantiatePrefabs, ioNotifier);

                // set to false, wait to one tick to activate
                if(this._isLoading()) {
                    this._meshRootNode.visible = false;
                    this.needsThink = true;
                } else {
                    this._meshRootNode.visible = this._visibleFlag;
                }

                // show debug helper?
                if(params.debugHelper === true) {
                    this.boundsVisible = true;
                }

                if(ioNotifier) {
                    ioNotifier.finishLoading();
                }
            } else {
                //TODO: get submeshes and add
                const meshes:Mesh[] = [];
                for(const submesh of submeshes) {
                    if(typeof submesh === 'string') {
                        for(const mesh of model.meshes) {
                            if(mesh instanceof Mesh) {
                                if(mesh.name === submesh) {
                                    meshes.push(mesh);
                                }
                            }
                        }
                    } else {
                        const mesh = model.meshes[submesh];
                        if(mesh instanceof Mesh) {
                            meshes.push(mesh);
                        }
                    }
                }
                // set mesh
                this._setMesh(meshes, false, ioNotifier);

                // show debug helper?
                if(params.debugHelper === true) {
                    this.boundsVisible = true;
                }

                if(ioNotifier) {
                    ioNotifier.finishLoading();
                }
            }

        },
        (error) => {
            if(ioNotifier) {
                ioNotifier.finishLoading(error);
            }
        });
    }

    /**
     * assumes that component is cleared before
     * @param model new static model
     */
    private _setMesh(model:StaticModel|Mesh[], instantiatePrefabs?:boolean | PrefabCallback, notifier?:IONotifier) {
        console.assert(model, "MeshComponent: invalid model reference");

        if(model instanceof StaticModel) {
            this.model = model;

            // merge material refs
            this._materialRefs = this._materialRefs || [];
            for(const modelMatRef of model.materialRefs) {
                const index = this._materialRefs.findIndex( (ref) => ref.name === modelMatRef.name);

                if(index === -1) {
                    this._materialRefs.push({ name: modelMatRef.name, ref: modelMatRef.ref });
                }
            }

            // get model ref
            this._meshRootNode = model.getHierarchy();

            // TODO: get parent node object
            this.entity.add(this._meshRootNode);
            this.entity.updateTransform(true);

            // check prefabs for initialization
            if(instantiatePrefabs !== false) {
                if(typeof instantiatePrefabs === "function") {
                    this._instantiatePrefabs(notifier, instantiatePrefabs);
                } else {
                    this._instantiatePrefabs(notifier);
                }
            }

            // set visibility (default)
            this._visibleFlag = true;

            // set to false, wait to one tick to activate
            if(this._isLoading()) {
                this._meshRootNode.visible = false;
                this.needsThink = true;
            } else {
                this._meshRootNode.visible = this._visibleFlag;

                if(this._visibleState !== undefined) {
                    for(const mesh of this.model.meshes) {
                        if(this._visibleState[mesh.name] !== undefined) {
                            mesh.visible = this._visibleState[mesh.name];
                        }
                    }
                }
            }

            // setup shadow settings
            if(this._castShadow !== undefined) {
                this.model.castShadow = this._castShadow === true;
            }
            if(this._receiveShadow !== undefined) {
                this.model.receiveShadow = this._receiveShadow === true;
            }

            // set material references
            if(this._materialRefs) {
                model.setMaterialRefs(this._materialRefs);
            }

            // set layer mask
            if(this._renderLayer !== undefined) {
                model.setRenderLayer(this._renderLayer);
            }

            // set render order
            if(this._renderOrder !== undefined) {
                model.setRenderOrder(this._renderOrder);
            }

            // register at collision system (force when editor)
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            if(collisionSystem && this.collision !== ECollisionBehaviour.None) {
                console.assert(this._collisionId.length === 0, "already registered a collision model");
                this._collisionId = [collisionSystem.registerCollisionModel(this.model, this.entity, this.collision, this._collisionLayer)];
            }
        } else if(Array.isArray(model)) {

            // set meshes
            this._meshes = model;

            // merge material refs
            this._materialRefs = this._materialRefs || [];
            for(const mesh of model) {
                const modelMatRef = mesh.materialRef;
                const index = this._materialRefs.findIndex( (ref) => ref.name === modelMatRef.name);

                if(index === -1) {
                    this._materialRefs.push({ name: modelMatRef.name, ref: modelMatRef.ref });
                }
            }

            // set model ref
            this._meshRootNode = null;

            for(const mesh of model) {
                this._entityRef.add(mesh);
            }

            // set visibility (default)
            this._visibleFlag = true;

            // set to false, wait to one tick to activate
            if(this._isLoading()) {
                for(const mesh of model) {
                    mesh.visible = false;
                }
                this.needsThink = true;
            } else {
                for(const mesh of model) {
                    mesh.visible = this._visibleFlag;
                }
            }

            // setup shadow settings
            if(this._castShadow !== undefined) {
                for(const mesh of this._meshes) {
                    mesh.castShadow = this._castShadow === true;
                }
            }
            if(this._receiveShadow !== undefined) {
                for(const mesh of this._meshes) {
                    mesh.receiveShadow = this._receiveShadow === true;
                }
            }

            // set material references
            if(this._materialRefs) {
                for(const ref of this._materialRefs) {
                    // apply to all meshes
                    for(const mesh of this._meshes) {
                        mesh.setMaterialRef(ref);
                    }
                }
            }

            // set layer mask
            if(this._renderLayer !== undefined) {
                // apply to all meshes
                for(const mesh of this._meshes) {
                    mesh.layers.set(this._renderLayer);
                }
            }

            // set render order
            if(this._renderOrder !== undefined) {
                // apply to all meshes
                for(const mesh of this._meshes) {
                    mesh.renderOrder = this._renderOrder;
                }
            }

            // register at collision system (force when editor)
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
            if(collisionSystem && this.collision !== ECollisionBehaviour.None) {
                console.assert(this._collisionId.length === 0, "already registered a collision model");
                for(const mesh of this._meshes) {
                    this._collisionId.push(collisionSystem.registerCollisionMesh(mesh, this.entity, this.collision, this._collisionLayer));
                }
            }
        }
    }

    /**
     * this only instantiates entities for prefab nodes.
     * In the future use: _instantiateModel
     */
    private _instantiatePrefabs(notifier?:IONotifier, instantiatePrefabs?:PrefabCallback) {

        const scope = this;
        const world = this._entityRef.world;

        function recursive(node:any, parent:Entity) {
            parent = parent || scope._entityRef;

            // parse node and instantiate prefab
            if(node.name.indexOf("$prefab:") === 0 || node.name.indexOf("@prefab:") === 0) {
                const prefabName = node.name.substring(8);
                // copy node
                let prefabEntity:any = world.instantiateEntity(prefabName, parent);
                // instantiate new prefab
                prefabEntity.position.copy(node.position);
                prefabEntity.scale.copy(node.scale);
                prefabEntity.quaternion.copy(node.quaternion);
                // remove old node
                if(node.parent) {
                    node.parent.remove(node);
                }

                if(instantiatePrefabs) {
                    prefabEntity = instantiatePrefabs(prefabEntity);
                }

                if(prefabEntity) {
                    prefabEntity.updateTransform();
                    world.instantiatePrefab(prefabName, prefabEntity, null, notifier);
                }
            }

            if(node.children) {
                // create copy (sub nodes could be dynamically deleted)
                const childs = node.children.slice(0);

                for(let i = 0; i < childs.length; ++i) {
                    recursive(childs[i], node);
                }
            }
        }

        recursive(this._meshRootNode, null);
    }

    private _isLoading() {
        let isLoading = false;
        if(!this.model && this._meshes.length === 0) {
            isLoading = true;
        }
        return isLoading;
    }

    private _cleanupMesh() {
        if(this._collisionId.length) {
            const collisionSystem = this.world.getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);

            for(const id of this._collisionId) {
                collisionSystem.removeCollisionObject(id);
            }
            this._collisionId = [];
        }

        if(this.model) {

            if(this._meshRootNode) {
                this.entity.remove(this._meshRootNode);
            }
            this._meshRootNode = null;

            this.model.destroy({noGeometry:true, noMaterial: true});
            this.model = null;
        }

        for(const mesh of this._meshes) {
            this._entityRef.remove(mesh);

            mesh.destroy({noGeometry:true, noMaterial: true});
        }
        this._meshes = [];
        this._materialRefs = [];
        //FIXME: reset filename reference?
        this._filename = "";
    }

    /** load component */
    public load(data:ComponentData, ioNotifier?:IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        // update mesh
        this.setMesh(data.parameters as MeshComponentParams, ioNotifier);
    }

    public save() : ComponentData {
        const node = {
            module: "RED",
            type: "MeshComponent",
            parameters: {
                filename: null,
                loaderIdentifier: null,
                visible: true,
                debugHelper: false,
                castShadow: false,
                receiveShadow: false,
                collision: ECollisionBehaviour.None,
                materialRefs: [],
                renderLayer: this._renderLayer,
                renderOrder: this._renderOrder
            } as MeshComponentParams
        };

        if(this.model) {
            node.parameters.filename = this.model.name;
            node.parameters.visible = this._meshRootNode.visible || false;
            node.parameters.castShadow = this.model.castShadow;
            node.parameters.receiveShadow = this.model.receiveShadow;
            node.parameters.materialRefs = this._materialRefs || cloneObject(this.model.materialRefs);
        } else {
            //TODO: filename
            node.parameters.filename = this._filename || "";
            node.parameters.visible = this._visibleFlag;
            node.parameters.castShadow = this._castShadow;
            node.parameters.receiveShadow = this._receiveShadow;
            node.parameters.materialRefs = this._materialRefs || [];
        }

        node.parameters.collision = this._collisionBehaviour;

        return node;
    }

    public static Preload(component:WorldFileComponent, preloadFiles:any[]) {
        if(component.parameters && component.parameters.filename) {
            preloadFiles.push(queryMeshSystem().preloadModel(component.parameters.filename, component.parameters.loaderIdentifier));
        }
    }

    /**
     * extract filename for model loading
     */
    private _extractModelName() {
        if(this._filename) {
            const index = this._filename.search("@");
            if(index === -1) {
                return this._filename;
            }
            return this._filename.substring(0, index);
        }
        return "";
    }

    /**
     * parse filename and extract nodes or sub mesh indices
     */
    private _extractNodeOrIndex() : false|(number|string)[] {
        if(this._filename) {
            let index = this._filename.indexOf("@");
            if(index === -1) {
                return false;
            }
            const submeshes = [];
            while(index !== -1) {
                let endIndex = this._filename.indexOf(",", index + 1);
                if(endIndex === -1) {
                    endIndex = undefined;
                }

                // startup
                const nodeOrSubmesh = this._filename.substring(index + 1, endIndex);
                if(!nodeOrSubmesh) {
                    break;
                }

                if(isNaN(nodeOrSubmesh as any)) {
                    submeshes.push(nodeOrSubmesh as string);
                } else {
                    const submesh = parseInt(nodeOrSubmesh, 10);
                    if(!isNaN(submesh)) {
                        submeshes.push(submesh);
                    }
                }

                if(endIndex !== undefined) {
                    index = endIndex + 1;
                } else {
                    index = -1;
                }
            }
            return submeshes;
        }
        return false;
    }
}

/** register component */
registerComponent("RED", "MeshComponent", MeshComponent);
