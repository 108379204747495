/**
 * System.ts: System definition
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { IWorld, WorldSystem } from "./WorldAPI";
import { PluginId } from "../plugin/Plugin";

/**
 * System Register definition
 */
export interface WorldSystemRegistrar {
    /** api reference */
    api:number;
    /** prototype (DEPRECATED) */
    type?:any;
    /** world system module */
    module?:WorldSystem;
}

export let WorldSystemRegister:{[key:string]:WorldSystemRegistrar} = {
};

/** register type of system */
export function registerWorldSystem(mod:string, name:string, api:number|PluginId, type:any) {
    let apiId:number;
    if(typeof api === 'number') {
        apiId = api;
    } else {
        apiId = api.api;
    }
    WorldSystemRegister[mod+"."+name] = { api:apiId, type };
}

/** register type of system */
export function registerWorldSystemModule(mod:string, name:string, api:number|PluginId, module:WorldSystem) {
    let apiId:number;
    if(typeof api === 'number') {
        apiId = api;
    } else {
        apiId = api.api;
    }
    WorldSystemRegister[mod+"."+name] = { api:apiId, module };
}

/**
 * construct a world system
 * does not initialize the system
 * @param name
 * @param moduleName
 * @param world
 */
export function constructWorldSystem(name: string, moduleName:string, world:IWorld) : WorldSystem {
    let prototype:any = null;
    if(moduleName) {
        if(window[moduleName] && window[moduleName][name]) {
            prototype = window[moduleName][name].prototype;
        }
    } else {
        prototype = window[name] ? window[name].prototype : null;
    }

    if(!prototype) {
        if(WorldSystemRegister[moduleName+"."+name]) {
            if(WorldSystemRegister[moduleName+"."+name]) {
                return WorldSystemRegister[moduleName+"."+name].module;
            }

            prototype = WorldSystemRegister[moduleName+"."+name].type.prototype;
        } else if(WorldSystemRegister[name]) {
            if(WorldSystemRegister[name]) {
                return WorldSystemRegister[name].module;
            }

            prototype = WorldSystemRegister[name].type.prototype;
        }
    }

    if(!prototype) {
        console.warn("IWorld: failed to construct component: " + moduleName + " " + name);
        return null;
    }

    const instance:WorldSystem = Object.create(prototype);
    instance.constructor.apply(instance);
    return instance;
}
