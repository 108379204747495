/**
 * EnvironmentBuilder.ts: Generic Environment Scene code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { EnvironmentSetup, BackgroundMode } from "../framework-types/WorldFileFormat";
import { WorldEnvironment } from "./WorldAPI";
import { destroyObject3D } from "../core/Globals";
import { Color } from "../../lib/threejs/math/Color";
import { queryTextureSystem, getImportSettingsTexture } from "./TextureAPI";
import { OrthographicCamera } from "../../lib/threejs/cameras/OrthographicCamera";
import { Mesh } from "../render/Mesh";
import { PlaneBufferGeometry } from "../../lib/threejs/geometries/PlaneGeometry";
import { UVMapping, EquirectangularReflectionMapping, MirroredRepeatWrapping, CubeReflectionMapping, CubeRefractionMapping, EquirectangularRefractionMapping } from "../../lib/threejs/constants";
import { ShaderVariant } from "../render/Shader";
import { PhysicalCamera } from "../render/Camera";
import { ERenderLayer } from "../render/Layers";
import { SphereBufferGeometry } from "../../lib/threejs/geometries/SphereGeometry";
import { Fog } from "../../lib/threejs/scenes/Fog";
import { Scene } from "../../lib/threejs/scenes/Scene";
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { ShaderLibrary } from "../render/ShaderLibrary";
import { IONotifier } from "../io/Interfaces";
import { AsyncLoad } from "../io/AsyncLoad";

/**
 * cleanup environment data
 * @param environment created environment 3d data
 */
export function cleanupEnvironment(environment:WorldEnvironment|null) {
    // cleanup old environment setup
    if(environment) {
        if(environment.backgroundScene) {
            // free all objects from scene
            destroyObject3D(environment.backgroundScene);
            // clear references directly
            environment.backgroundScene = null;
            environment.backgroundMesh = null;
        }
    }
}

/**
 *
 * @param environmentSetup environment setup
 * @param _envScene predefined environment scene
 * @param ioNotifier
 * @param reference
 */
export function createEnvironment(environmentSetup:EnvironmentSetup, _envScene?:Scene, ioNotifier?:IONotifier, reference?:WorldEnvironment) : AsyncLoad<WorldEnvironment> {
    return new AsyncLoad<WorldEnvironment>( (resolve, reject) => {
        let _environment:WorldEnvironment = reference;

        if(_environment && !_envScene) {
            _envScene = _environment.backgroundScene;
        }
        _envScene = _envScene || new Scene();

        // cleanup old environment setup
        if(_environment) {
            if(_environment.backgroundScene) {
                // free all objects from scene
                destroyObject3D(_environment.backgroundScene);
                // clear references directly
                _environment.backgroundScene = null;
                _environment.backgroundMesh = null;
            }
            _environment = null;
        }

        if(environmentSetup.texture) {
            if(ioNotifier) {
                ioNotifier.startLoading();
            }

            queryTextureSystem().createTexture(environmentSetup.texture, undefined, undefined).then((texture) => {

                _environment = {
                    backgroundColor: null,
                    backgroundAlpha: null,
                    backgroundScene: null,
                    backgroundCamera: null,
                    backgroundMesh: null
                };

                const template = {
                    shader: "redBackground",
                    map: environmentSetup.texture,
                    offsetRepeat: [0.0,0.0,1.0,1.0]
                };

                if(environmentSetup.textureMode === BackgroundMode.Tile) {
                    template.offsetRepeat = [0.0, 0.0, 2.0, 2.0];
                }

                // setup camera
                _environment.backgroundCamera = new OrthographicCamera( - 1, 1, 1, - 1, 0, 1 );
                _environment.backgroundTexture = texture;
                _environment.backgroundTextureMode = environmentSetup.textureMode;
                // setup scene
                _environment.backgroundScene = _envScene;
                _environment.backgroundMesh = new Mesh(
                    new PlaneBufferGeometry( 2, 2 ),
                    template
                );
                _environment.backgroundMesh.frustumCulled = false;
                _environment.backgroundScene.add(_environment.backgroundMesh);

                if(ioNotifier) {
                    ioNotifier.finishLoading();
                }

                resolve(_environment);
            },
            (err) => {

                // handle error
                console.warn("World: invalid environment texture ", environmentSetup.texture);
                _environment = null;

                if(ioNotifier) {
                    ioNotifier.finishLoading(err);
                }

                reject(err);
            });
        } else if(environmentSetup.customMaterialShader) {
            _environment = {
                backgroundColor: null,
                backgroundAlpha: null,
                backgroundScene: null,
                backgroundCamera: null,
                backgroundMesh: null
            };

            // setup camera
            _environment.backgroundCamera = new OrthographicCamera( - 1, 1, 1, - 1, 0, 1 );

            // setup scene
            _environment.backgroundScene = _envScene;

            _environment.backgroundMesh = new THREEMesh(
                new PlaneBufferGeometry( 2, 2 ),
                environmentSetup.customMaterialShader
            );
            _environment.backgroundMesh.frustumCulled = false;
            _environment.backgroundScene.add(_environment.backgroundMesh);

            resolve(_environment);
        } else if(environmentSetup.envMap) {
            //startLoadingWorld();
            if(ioNotifier) {
                ioNotifier.startLoading();
            }

            const textureName = environmentSetup.envMap;

            //TODO: create texture needs cube map support?
            //TODO: support for reflection etc.
            queryTextureSystem().createTexture(textureName, environmentSetup.envMap, undefined).then( (texture) => {

                //TODO: remove this as textures should be setup before...
                if(texture.mapping === UVMapping) {
                    texture.mapping = EquirectangularReflectionMapping;
                    texture.wrapS = MirroredRepeatWrapping;
                    texture.wrapT = MirroredRepeatWrapping;
                    texture.flipY = true;
                }

                _environment = {
                    backgroundColor: null,
                    backgroundAlpha: null,
                    backgroundScene: null,
                    backgroundCamera: null,
                    backgroundMesh: null
                };

                const template = {
                    shader: "redBackground",
                    map: textureName,
                    offsetRepeat: [0.0,0.0,1.0,1.0]
                };

                const rgbmEncoded = getImportSettingsTexture(environmentSetup.envMap).isRGBMEncoded;

                let variant = ShaderVariant.DEFAULT;
                switch(texture.mapping) {
                    case CubeReflectionMapping:
                    case CubeRefractionMapping:
                        variant |= rgbmEncoded ? (ShaderVariant.CUBE | ShaderVariant.HDR) : ShaderVariant.CUBE;
                        break;
                    case EquirectangularReflectionMapping:
                    case EquirectangularRefractionMapping:
                        variant = rgbmEncoded ? (ShaderVariant.EQUIRECT | ShaderVariant.HDR) : ShaderVariant.EQUIRECT;
                        break;
                    default:
                        // default cube
                        variant |= rgbmEncoded ? (ShaderVariant.CUBE | ShaderVariant.HDR) : ShaderVariant.CUBE;
                        break;
                }

                // setup scene
                _environment.backgroundScene = _envScene;
                //FIXME: fov
                _environment.backgroundCamera = new PhysicalCamera( 90.0, window.innerWidth / window.innerHeight, 1, 1100 );
                _environment.backgroundCamera.name = "BackgroundCamera";
                _environment.backgroundCamera.layers.set(ERenderLayer.Background);

                const geometry = new SphereBufferGeometry( 500, 60, 40, Math.PI );

                // force variant on mesh
                _environment.backgroundMesh = new Mesh(geometry, template, variant );
                _environment.backgroundMesh.layers.set(ERenderLayer.Background);
                _environment.backgroundMesh.frustumCulled = false;
                _environment.backgroundScene.add(_environment.backgroundMesh);
                _environment.isEnvironmentMap = true;

                //finishLoadingWorld();
                if(ioNotifier) {
                    ioNotifier.finishLoading();
                }

                resolve(_environment);
            },
            (err) => {

                _environment = null;
                console.warn("World: invalid environment texture ", environmentSetup.envMap);

                if(ioNotifier) {
                    ioNotifier.finishLoading(err);
                }

                // handle error
                reject(err);
            });

        } else if(environmentSetup.color) {
            _environment = {
                backgroundColor: new Color().fromArray(environmentSetup.color),
                backgroundAlpha: environmentSetup.alpha,
                backgroundScene: null,
                backgroundCamera: null,
                backgroundMesh: null
            };
            resolve(_environment);
        } else {
            // error setup
            _environment = null;
            resolve(_environment);
        }
    });
}

export function applyFogSetup(environmentSetup:EnvironmentSetup, scene:Scene) {

    //TODO: add support for fog and dynamic reloading
    if(environmentSetup.fog) {

        // linear fog
        const near = environmentSetup.fog.near || 100.0;
        const far = environmentSetup.fog.far || 1000.0;
        const color = environmentSetup.fog.color ? environmentSetup.fog.color : [1.0, 0.0, 1.0];

        scene.fog = new Fog(new Color().fromArray(color).getHex(), near, far);

        ShaderLibrary.setUseFog(true);
    } else {
        if(ShaderLibrary.useFog()) {
            ShaderLibrary.setUseFog(false);
        }
    }
}
