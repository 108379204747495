///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {ComponentState} from './ComponentState';

export abstract class AbstractBlockComponent {
    private _initialized:boolean = false;
    private _destroyed:boolean = false;
    private _state:ComponentState = ComponentState.NONE;
    private _onStateChange = new EventDispatcher<AbstractBlockComponent,ComponentState>();

    constructor(protected _block:Block, protected _params:any) {
    }

    get block():Block{
        return this._block;
    }

    public get onStateChange(): IEvent<AbstractBlockComponent,ComponentState> {
        return this._onStateChange.asEvent();
    }

    protected set state(value:ComponentState) {
        if(this._state != value){
            this._state = value;
            this._onStateChange.dispatchAsync(this, this._state);
        }
    }

    public get currentState():ComponentState{
        return this._state;
    }

    public init():void {
        if(this._initialized === true) return;
        this._initialized = true;
        this.state = ComponentState.PREINIT;
        this.onInit();
    }

    public destroy():void {
        if(this._destroyed === true) return;
        this._destroyed = true;
        this.onDestroy();
    }

    protected abstract onInit():void;
    protected abstract onDestroy():void;

    public appear():void{};
    public disappear():void{};
}
