///<reference path="../js_bindings.d.ts"/>

import { Tuple } from '../helper/Tuple';
import { Config } from '../Config';
import { Templates } from './Templates';


export enum CommunicationCenterState {
    Newsletter,
    Chat,
}

export class CommunicationCenter {

    public get opened(): boolean {
        return this._element.classList.contains('opened');
    }

    public set opened(value: boolean) {
        if (value) this._element.classList.add('opened');
        else this._element.classList.remove('opened');
    }

    public get state(): CommunicationCenterState {
        if (this._element.classList.contains('news')) return CommunicationCenterState.Newsletter;
        if (this._element.classList.contains('chat')) return CommunicationCenterState.Chat;
    }

    public set state(value: CommunicationCenterState) {
        if (this.state != value) {

            if (value == CommunicationCenterState.Chat && !this._chat.available) {
                console.warn("CommunicationCenter - can't open chat if the chat isn't available");
                return;
            }

            switch (value) {
                case CommunicationCenterState.Chat:
                    this._element.classList.remove('news');
                    this._element.classList.add('chat');
                    break;
                case CommunicationCenterState.Newsletter:
                    this._element.classList.remove('chat');
                    this._element.classList.add('news');
                    break;
            }

            this.opened = true;

        }
        else {
            this.opened = !this.opened;
        }
    }


    private _element: any;
    private _chatBtn: ChatBtn;
    private _btnNews: any;
    private _isOpen: boolean = false;
    private _newsletter: Newsletter;
    private _chat: Chat;

    public get chat(): Chat {
        return this._chat;
    }

    constructor() {
        this._element = document.querySelector('.communication');
        this._btnNews = this._element.querySelector('.news_btn');

        this._newsletter = new Newsletter(this._element.querySelector('.news_block'));
        this._chat = new Chat(this._element.querySelector('.chat_block'));
        this._chatBtn = new ChatBtn(this._element.querySelector('.chat_btn'), this);

        this._chat.onAvailableChange.subscribe((available: boolean) => {
            if (available) {
                this._element.classList.add('chat_available');
            }
            else {
                this._element.classList.remove('chat_available');
            }
        });

        this._btnNews.addEventListener("click", (event) => {
            this.state = CommunicationCenterState.Newsletter;
        });
    }
}

/**
 * ChatBtn
 */
class ChatBtn {
    private _element: any;
    private _unread: any;
    private _communicationCenter: CommunicationCenter;
    private _blinkIntervalId: number = -1;
    private _blink: boolean = false;
    private _operator: string;

    get blink(): boolean {
        return this._blink;
    }

    set blink(value: boolean) {
        console.log('blink', value);
        if (this._blink != value) {
            this._blink = value;
            if (this._blink && this._blinkIntervalId == -1) {
                this._blinkIntervalId = window.setInterval(() => {
                    if (this._operator) this._element.classList.toggle(`chat_operator_${this._operator.trim().toLowerCase()}`);
                }, 3000);
            }
            else {
                clearInterval(this._blinkIntervalId);
                if (this._operator) this._element.classList.remove(`chat_operator_${this._operator.trim().toLowerCase()}`);
                this._blinkIntervalId = -1;
            }
        }
    }

    constructor(element: any, communicationCenter: CommunicationCenter) {
        this._element = element;
        this._unread = this._element.querySelector('.unread');
        this._communicationCenter = communicationCenter;

        //console.log("ChatBtn");

        this._element.addEventListener("click", (event) => {
            this._communicationCenter.state = CommunicationCenterState.Chat;
        });

        this._communicationCenter.chat.onOperatorChange.subscribe((operator) => {
            //console.log("ChatBtn - operator", operator);

            //set the new
            this._operator = operator.item1;
            //remove the old
            if (operator.item2) this._element.classList.remove(`chat_operator_${operator.item2.trim().toLowerCase()}`);
        });

        this._communicationCenter.chat.onUnreadCountChange.subscribe((count) => {
            //console.log("ChatBtn - unreadCount", count);
            let unread = count > 0;
            this._unread.style.visibility = unread ? 'visible' : 'hidden';
            this._unread.innerHTML = count;
            this.blink = unread;
        });
    }
}

export class Chat {
    private _parent: any;
    private _element: any;
    private _btnChat: any;

    private _available: boolean;
    private _onAvailableChange: SimpleEventDispatcher<boolean> = new SimpleEventDispatcher();

    get onAvailableChange(): ISimpleEvent<boolean> {
        return this._onAvailableChange.asEvent();
    }

    get available(): boolean {
        return this._available;
    }

    set available(value: boolean) {
        if (this._available != value) {
            this._available = value;
            this._onAvailableChange.dispatch(this._available);
        }
    }

    private _operator: string;
    private _onOperatorChange: SimpleEventDispatcher<Tuple<string, string>> = new SimpleEventDispatcher();

    get onOperatorChange(): ISimpleEvent<Tuple<string, string>> {
        return this._onOperatorChange.asEvent();
    }

    get operator(): string {
        return this._operator;
    }

    set operator(value: string) {
        if (this._operator != value) {
            this._onOperatorChange.dispatch(new Tuple(value, this._operator));
            this._operator = value;
        }
    }

    private _unreadCount: number = 0;
    private _onUnreadCountChange: SimpleEventDispatcher<number> = new SimpleEventDispatcher();

    get onUnreadCountChange(): ISimpleEvent<number> {
        return this._onUnreadCountChange.asEvent();
    }

    get unreadCount(): number {
        return this._unreadCount;
    }

    set unreadCount(value: number) {
        if (this._unreadCount != value) {
            this._unreadCount = value;
            this._onUnreadCountChange.dispatch(this._unreadCount);
        }
    }

    private _nano: any;
    private _id: any;
    private _page: any;
    private _msg: any;
    private _btnSend: any;
    private _chat: any;
    private _content: any;


    constructor(element) {
        this._element = element;
        this._nano = this._element.querySelector('.nano');
        this._content = this._element.querySelector('.history_content');
        this._msg = this._element.querySelector('.chat_msg');
        this._btnSend = this._element.querySelector('.chat_send');
        // this._id = "guest_"+window['chatMeta'].id;
        // this._page = window['chatMeta'].page;
        this._id = Config.getInstance().userId;
        this._page = Config.getInstance().page;


        /*
        this._chat = io.connect('chat.io.redplant-office.de/visitor', { query: Templates.Chat.ConnectQuery(this._id, this._page) });

        this._chat.on('connect', () => {
            //console.log("Chat - connected");
        });

        this._chat.on('available', (isAvailable) => {
            //console.log("isAvailable", isAvailable);
            this.available = isAvailable;
        });

        this._chat.on('restoreHistory', (historyItems) => {
            //console.log("Chat - restoreHistory", historyItems);
            this.addItems(historyItems);
        });

        this._chat.on('message', (item) => {
            //console.log("Chat - message", item);

            this.unreadCount++;

            //set the current operator
            this.operator = item.id;

            this.addItems([item]);
        });
        */

        this._msg.addEventListener("keyup", this.onKeyUp.bind(this));
        this._btnSend.addEventListener("click", this.send.bind(this));



    }

    private onKeyUp(event): void {
        if (event.keyCode == 13) {
            this.send();
        }
    }

    private send(): void {
        var message = this._msg.value.trim();
        if (message.length <= 0) return;
        this._msg.value = "";
        this._chat.emit('message', message);

        this.unreadCount = 0;

        this.addItems([{
            id: this._id,
            msg: message
        }]);
    }

    private addItems(items: Array<any>) {
        //console.log("Chat - addItems", items);
        _.each(items, (item) => {

            //operator
            let type = "operator";
            let name = item.id;

            //override with guest
            if (item.id.indexOf("guest_") >= 0) {
                type = "guest";
                name = "Du";
            }

            let entry = Templates.Chat.Entry(type, name, item.msg);
            $(entry).appendTo(this._content);
        });

        //refresh the scroll area !important
        $(this._nano).nanoScroller();
        //now it's possible to scroll - crazy
        $(this._nano).nanoScroller({ scroll: 'bottom' });
    }
}

class Newsletter {

    private _element: any;
    private _inputEMAIL: any;
    private _inputFNAME: any;
    private _inputLNAME: any;
    private _inputMMERGE3: any;
    private _btnSUBMIT: any;
    private _iconFailure: any;

    constructor(element) {
        this._element = element;
        this._inputEMAIL = this._element.querySelector('#mce-EMAIL');
        this._inputFNAME = this._element.querySelector('#mce-FNAME');
        this._inputLNAME = this._element.querySelector('#mce-LNAME');
        this._inputMMERGE3 = this._element.querySelector('#mce-MMERGE3');
        this._btnSUBMIT = this._element.querySelector('#mce-SUBMIT');
        this._iconFailure = this._element.querySelector('.failure_icon');

        this._inputEMAIL.addEventListener("keyup", this.onKeyUp.bind(this));
        this._inputFNAME.addEventListener("keyup", this.onKeyUp.bind(this));
        this._inputLNAME.addEventListener("keyup", this.onKeyUp.bind(this));
        this._inputMMERGE3.addEventListener("keyup", this.onKeyUp.bind(this));

        this._iconFailure.addEventListener("click", (event) => {
            this._element.classList.remove('failure');
            this._element.classList.remove('success');
            this._element.classList.remove('loading');
        });

        this._btnSUBMIT.addEventListener("click", this.send.bind(this));

        //console.log(Newsletter,this);
    }

    private onKeyUp(event): void {
        if (event.keyCode == 13) {
            this.send();
        }
    }

    private send(): void {
        let localValid = true;

        let FNAME = this._inputFNAME.value.trim();
        let LNAME = this._inputLNAME.value.trim();
        let EMAIL = this._inputEMAIL.value.trim();
        let MMERGE3 = this._inputMMERGE3.value.trim();

        var emailRegEx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (EMAIL.length == 0 || emailRegEx.test(EMAIL) === false) {
            this._inputEMAIL.classList.add('error');
            localValid = false;
        }
        else {
            this._inputEMAIL.classList.remove('error');
        }

        if (localValid) {
            this._element.classList.add('loading');

            jQuery.ajax({
                url: "newsletter/subscripe",
                type: 'get',
                data: {
                    FNAME: encodeURI(FNAME),
                    LNAME: encodeURI(LNAME),
                    EMAIL: encodeURI(EMAIL),
                    MMERGE3: encodeURI(MMERGE3)
                },
                dataType: 'json',
                async: true,
                success: (data, status, xhr) => {
                    //console.log("subscripe", data);
                    this._element.classList.remove('loading');

                    if (data.status == "pending") {
                        this._element.classList.add('success');
                    }
                    else {
                        this._element.classList.add('failure');
                    }
                },
                error: (xhr, status, error) => {
                    //console.log("subscripe error", status, error);
                    this._element.classList.remove('loading');
                    this._element.classList.add('failure');
                }
            });
        }
    }
}
