export default {
    name: "youtube",
    title: "Youtube",
    purposes: ['functional'],
    // contextualConsentOnly: true,
    translations: {
        en: {
            description: `
                YouTube allows content published on youtube.com to be embedded directly into websites. The cookies are used to collect visited websites and detailed statistics about user behavior. This data can be linked to the data of users registered on youtube.com and google.com.
                <br><br>
                
                <table>
                    <thead>
                        <th>Cookie-Type</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Validity period</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SIDCC</td>
                            <td>.youtube.com</td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PAPISID</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-APISID</td>
                            <td>.youtube.com</td>
                            <td>1 Month</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SAPISID</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SSID</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>1P_JAR</td>
                            <td>.youtube.com</td>
                            <td>1 Month</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SEARCH_SAMESITE</td>
                            <td>.youtube.com</td>
                            <td>6 Months</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>YSC</td>
                            <td>.youtube.com</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>LOGIN_INFO</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>HSID</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>VISITOR_INFO1_LIVE</td>
                            <td>.youtube.com</td>
                            <td>6 Months</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>CONSENT</td>
                            <td>.youtube.com</td>
                            <td>18 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-SSID</td>
                            <td>.youtube.com</td>
                            <td>1 Month</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-HSID</td>
                            <td>.youtube.com</td>
                            <td>1 Month</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>APISID</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PSID</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>PREF</td>
                            <td>.youtube.com</td>
                            <td>8 Months</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SID</td>
                            <td>.youtube.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>s_gl</td>
                            <td>.youtube.com</td>
                            <td>Session</td>
                        </tr>

                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SIDCC</td>
                            <td>.google.com</td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PAPISID</td>
                            <td>.google.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SAPISID</td>
                            <td>.google.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>APISID</td>
                            <td>.google.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SSID</td>
                            <td>.google.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>HSID</td>
                            <td>.google.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PSID</td>
                            <td>.google.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SID</td>
                            <td>.google.com</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>CONSENT</td>
                            <td>.google.com</td>
                            <td>18 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>NID</td>
                            <td>.google.com</td>
                            <td>6 Months</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>1P_JAR</td>
                            <td>.google.com</td>
                            <td>1 Month</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>DV</td>
                            <td>.google.com</td>
                            <td>1 Minute</td>
                        </tr>
                    </tbody>
                </table>

                <br>
                Use on the legal basis of: Consent
                <br>
                Provider: Vimeo Inc.
                <br>
                US data processing: Yes
                <br>
                Provider privacy policy: https://vimeo.com/privacy
            `,
        },
        de: {
            description: `
                YouTube ermöglicht die direkte Einbettung von auf youtube.com veröffentlichten Inhalten in Websites. Die Cookies werden verwendet, um besuchte Websites und detaillierte Statistiken über das Nutzerverhalten zu sammeln. Diese Daten können mit den Daten der auf youtube.com und google.com angemeldeten Nutzer verknüpft werden.
                <br><br>
                
                <table>
                    <thead>
                        <th>Cookie-Typ</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Gültigkeitsdauer</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SIDCC</td>
                            <td>.youtube.com</td>
                            <td>1 Jahr</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PAPISID</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-APISID</td>
                            <td>.youtube.com</td>
                            <td>1 Monat</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SAPISID</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SSID</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>1P_JAR</td>
                            <td>.youtube.com</td>
                            <td>1 Monat</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SEARCH_SAMESITE</td>
                            <td>.youtube.com</td>
                            <td>6 Monate</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>YSC</td>
                            <td>.youtube.com</td>
                            <td>Sitzung / Session</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>LOGIN_INFO</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>HSID</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>VISITOR_INFO1_LIVE</td>
                            <td>.youtube.com</td>
                            <td>6 Monate</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>CONSENT</td>
                            <td>.youtube.com</td>
                            <td>18 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-SSID</td>
                            <td>.youtube.com</td>
                            <td>1 Monat</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-HSID</td>
                            <td>.youtube.com</td>
                            <td>1 Monat</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>APISID</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PSID</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>PREF</td>
                            <td>.youtube.com</td>
                            <td>8 Monate</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SID</td>
                            <td>.youtube.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>s_gl</td>
                            <td>.youtube.com</td>
                            <td>Sitzung /Session</td>
                        </tr>

                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SIDCC</td>
                            <td>.google.com</td>
                            <td>1 Jahr</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PAPISID</td>
                            <td>.google.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SAPISID</td>
                            <td>.google.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>APISID</td>
                            <td>.google.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SSID</td>
                            <td>.google.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>HSID</td>
                            <td>.google.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>__Secure-3PSID</td>
                            <td>.google.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>SID</td>
                            <td>.google.com</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>CONSENT</td>
                            <td>.google.com</td>
                            <td>18 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>NID</td>
                            <td>.google.com</td>
                            <td>6 Monate</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>1P_JAR</td>
                            <td>.google.com</td>
                            <td>1 Monat</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>DV</td>
                            <td>.google.com</td>
                            <td>1 Minute</td>
                        </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Verwendung auf gesetzlicher Grundlage von: Einwilligung
                </p>
                <p class="purposes">
                    Anbieter: Google Ireland Limited
                </p>
                <p class="purposes">
                    US-Datenverarbeitung: Ja
                </p>
                <p class="purposes">
                    Datenschutzerklärung des Anbieters: https://policies.google.com/privacy
                </p>
            `,
        },
    },
    cookies: [
        ["SIDCC", ".youtube.com"],
        ["__Secure-3PAPISID", ".youtube.com"],
        ["__Secure-APISID", ".youtube.com"],
        ["SAPISID", ".youtube.com"],
        ["SSID", ".youtube.com"],
        ["1P_JAR", ".youtube.com"],
        ["SEARCH_SAMESITE", ".youtube.com"],
        ["YSC", ".youtube.com"],
        ["LOGIN_INFO", ".youtube.com"],
        ["HSID", ".youtube.com"],
        ["VISITOR_INFO1_LIVE", ".youtube.com"],
        ["CONSENT", ".youtube.com"],
        ["__Secure-SSID", ".youtube.com"],
        ["__Secure-HSID", ".youtube.com"],
        ["APISID", ".youtube.com"],
        ["__Secure-3PSID", ".youtube.com"],
        ["PREF", ".youtube.com"],
        ["SID", ".youtube.com"],
        ["s_gl", ".youtube.com"],

        ["SIDCC", ".google.com"],
        ["__Secure-3PAPISID", ".google.com"],
        ["SAPISID", ".google.com"],
        ["APISID", ".google.com"],
        ["SSID", ".google.com"],
        ["HSID", ".google.com"],
        ["__Secure-3PSID", ".google.com"],
        ["SID", ".google.com"],
        ["CONSENT", ".google.com"],
        ["NID", ".google.com"],
        ["1P_JAR", ".google.com"],
        ["DV", ".google.com"],
    ],
};