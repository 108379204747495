/**
 * ImageLoader.ts: image loading
 *
 * Copyright redPlant GmbH 2016-2018
 */
import {FileLoadCallback, FileProgressCallback, FileErrorCallback, registerLoader, EAssetType} from "../io/Interfaces";
import {IDataCache, DATACACHE_API} from '../io/DataCache';
import {LoadingManager} from "../io/LoadingManager";
import { build } from "../core/Build";
import { OnUpdate } from "../framework/Tick";
import { AssetManager } from "../framework/AssetManager";
import { queryAPI } from "../plugin/Plugin";
import { IImageLoader } from "../framework-types/ImageFileFormat";

/**
 * @author mrdoob / http://mrdoob.com/
 * @author Lutz Hören
 */
export class ImageLoader implements IImageLoader {

    public manager:LoadingManager;
    public crossOrigin:string;
    public path:string;

    constructor(manager:LoadingManager) {
        this.crossOrigin = "";
        this.path = undefined;
        this.manager = manager;
    }

    public setCrossOrigin(value:string) {
        this.crossOrigin = value;
    }

    public setPath(value:string) {
        this.path = value;
    }

    public load(url:string, assetName:string, onLoad:FileLoadCallback, onProgress:FileProgressCallback, onError:FileErrorCallback) {

        if(this.path !== undefined) {
            url = this.path + url;
        }

        this.manager.itemStart(assetName);

        // check for datacache support
        const dataCache:IDataCache = queryAPI(DATACACHE_API);

        if(dataCache) {
            dataCache.get(url).then( (cached:any) => {
                if(onLoad) {
                    onLoad(cached);
                }
                this.manager.itemEnd(assetName);
            },
            (err) => {
                this._actuallyLoad(url, assetName, onLoad, onProgress, onError);
            });
        } else {
            this._actuallyLoad(url, assetName, onLoad, onProgress, onError);
        }
    }

    private _actuallyLoad(url:string, assetName:string, onLoad:any, onProgress:any, onError:any) {
        const image = document.createElement('img');
        _imageLoad(url, () => {
            if(image.complete) {
                // valid image?
                if(image.width > 0 && image.height > 0) {
                    // check for datacache support
                    const dataCache:IDataCache = queryAPI(DATACACHE_API);
                    if(dataCache) {
                        dataCache.set(url, image);
                    }

                    // FAKE PROGRESS HERE
                    const assetInfo = AssetManager.getAssetInfo(assetName);

                    if(assetInfo) {
                        this.manager.itemProgress(assetName, assetInfo.size, assetInfo.size);
                    } else {
                        // assuming uncompressed raw RGBA
                        const fileSize = image.width * image.height * 4;
                        this.manager.itemProgress(assetName, fileSize, fileSize);
                    }

                    if(onLoad) {
                        onLoad(image);
                    }

                    this.manager.itemEnd(assetName);
                }
                _imageFinish(url);
            }
        });

        //TODO: add support for progress loading of images
        // image.onprogress = (event) => {
        //     console.log("IMAGE PROGRESS", event);
        // };

        // image.addEventListener('progress', (event) => {
        //     if (onProgress !== undefined) {
        //         onProgress(event);
        //     }
        //     this.manager.itemProgress(url, event.loaded, event.total);
        // }, false);

        image.addEventListener('error', (event) => {

            if(onError) {
                onError(event);
            }

            _imageFinish(url);

            this.manager.itemEnd( assetName );
            this.manager.itemError( assetName );

        }, false);

        if(this.crossOrigin !== undefined) {
            image.crossOrigin = this.crossOrigin;
        }

        // start loading
        image.src = url;
    }
}

/**
 * internal image loading check
 * don't rely on image.onload
 */
const loadingImages:{[key:string]:any} = {};
let _active = false;
let _internalId:number = 0;

function _imageLoad(url, callback) {

    loadingImages[url] = callback;

    // check polling loop
    if(!_active) {

        if(build.Options.gameLoop) {
            OnUpdate.on(_loadCallback);
        } else {
            _internalId = window.setInterval(_loadCallback, 32);
        }

        _active = true;
    }
}

function _loadCallback() {
    let actives = 0;
    for(const url in loadingImages) {
        loadingImages[url]();
        actives++;
    }

    if(!actives) {
        _active = false;
        if(_internalId) {
            clearInterval(_internalId);
            _internalId = 0;
        }
        OnUpdate.off(_loadCallback);
    }
}

function _imageFinish(url) {
    delete loadingImages[url];
}

registerLoader("ImageLoader", "", EAssetType.Image, ImageLoader);
