/**
 * Platform.ts: Platform code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { build } from './Build';

/**
 * platform framework is running on
 */
export class Platform {

    // singleton
    private static _instance: Platform = null;
    public static get(): Platform {
        if (!Platform._instance) {
            Platform._instance = new Platform();
        }
        return Platform._instance;
    }

    /** screen resultion */
    get screenWidth(): number {
        return this._width;
    }
    get screenHeight(): number {
        return this._height;
    }

    /** mobile platform */
    get isMobile(): boolean {
        return this._mobile;
    }
    /** is iOS device */
    get isiOS(): boolean {
        return navigator.userAgent.match(/iPhone/i) !== null || navigator.userAgent.match(/iPad/i) !== null;
    }
    /** is android device */
    get isAndroid(): boolean {
        return navigator.userAgent.match(/Android/i) !== null;
    }

    /**
     * touch devices (not reliable)
     * @see http://www.stucox.com/blog/you-cant-detect-a-touchscreen/
     */
    public get isTouchDevice() : boolean {
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints > 0;       // works on IE10/11 and Surface
    }

    /** cookies are enabled */
    get isCookieEnabled(): boolean {
        return this._cookies;
    }

    /** operation system string */
    get operationSystem(): string {
        return this._operationSystem;
    }

    get osMajorVersion(): number {
        return this._osMajorVersion;
    }

    /** browser name */
    get browser(): string {
        return this._browser;
    }

    /** browser major version */
    get browserMajorVersion(): number {
        return this._browserMajorVersion;
    }

    /** chrome browser */
    get isChromeBrowser(): boolean {
        return this._browser === 'Chrome';
    }

    /** internet explorer */
    get isIEBrowser() : boolean {
        return navigator.userAgent.match(/Trident/i) !== null;
    }

    /** ms edge browser */
    get isEdgeBrowser(): boolean {
        return navigator.userAgent.match(/Edge/i) !== null;
    }

    /** browser supports webgl */
    get supportWebGL(): boolean {

        if(this.isIEBrowser) {
            return false;
        }

        try {
            const canvas = document.createElement('canvas');
            return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
        } catch (e) {
            return false;
        }
    }

    /** currently online */
    get isOnline() : boolean {
        return navigator.onLine === true;
    }

    /** private variables */
    private _width = 0;
    private _height = 0;

    private _mobile: boolean = false;
    private _cookies: boolean = false;

    private _browser: string = "Unknown";
    private _browserVersion: string;
    private _browserMajorVersion: number;

    private _operationSystem: string;
    private _osVersion: string;
    private _osMajorVersion: number;

    constructor() {

        // screen
        this._width = (screen.width) ? screen.width : window.innerWidth;
        this._height = (screen.height) ? screen.height : window.innerHeight;

        // browser
        const nVer = navigator.appVersion;
        const nAgt = navigator.userAgent;
        this._browser = navigator.appName;
        this._browserVersion = '' + parseFloat(navigator.appVersion);
        this._browserMajorVersion = parseInt(navigator.appVersion, 10);
        //let nameOffset: number;
        //let verOffset: number;
        //let ix: number;

        // Opera
        const operaAgent = nAgt.indexOf('Opera');
        const operaNextAgent = nAgt.indexOf('OPR');
        const edgeAgent = nAgt.indexOf('Edge');
        const msieAgent = nAgt.indexOf('MSIE');
        const tridentAgent = nAgt.indexOf('Trident/');
        const chromeAgent = nAgt.indexOf('Chrome');
        const safariAgent = nAgt.indexOf('Safari');
        const firefoxAgent = nAgt.indexOf('Firefox');

        if (operaAgent !== -1) {
            this._browser = 'Opera';
            this._browserVersion = nAgt.substring(operaAgent + 6);
            const verOffset = nAgt.indexOf('Version');
            if (verOffset !== -1) {
                this._browserVersion = nAgt.substring(verOffset + 8);
            }
        }
        // Opera Next
        if (operaNextAgent !== -1) {
            this._browser = 'Opera';
            this._browserVersion = nAgt.substring(operaNextAgent + 4);
        } else if(edgeAgent !== -1) {
            this._browser = "Edge";
            this._browserVersion = nAgt.substring(edgeAgent + 5);
        } else if (msieAgent !== -1) {
            this._browser = 'Microsoft Internet Explorer';
            this._browserVersion = nAgt.substring(msieAgent + 5);
        } else if (chromeAgent !== -1) {
            this._browser = 'Chrome';
            this._browserVersion = nAgt.substring(chromeAgent + 7);
        } else if (safariAgent !== -1) {
            this._browser = 'Safari';
            this._browserVersion = nAgt.substring(safariAgent + 7);
            const verOffset = nAgt.indexOf('Version');
            if (verOffset !== -1) {
                this._browserVersion = nAgt.substring(verOffset + 8);
            }
        } else if (firefoxAgent !== -1) {
            this._browser = 'Firefox';
            this._browserVersion = nAgt.substring(firefoxAgent + 8);
        } else if (tridentAgent !== -1) {
            //MSIE 11+
            this._browser = 'Microsoft Internet Explorer';
            this._browserVersion = nAgt.substring(nAgt.indexOf('rv:') + 3);
        } else {

            // OTHERS
            const nameOffset = nAgt.lastIndexOf(' ') + 1;
            const verOffset = nAgt.lastIndexOf('/');

            if(nameOffset < verOffset) {
                this._browser = nAgt.substring(nameOffset, verOffset);
                this._browserVersion = nAgt.substring(verOffset + 1);
                if (this._browser.toLowerCase() === this._browser.toUpperCase()) {
                    this._browser = navigator.appName;
                }
            } else {
                this._browser = "Unknown";
                this._browserVersion = "1.0";
            }
        }
        // trim the version string
        let ix = this._browserVersion.indexOf(';');
        if (ix !== -1) {
            this._browserVersion = this._browserVersion.substring(0, ix);
        }
        ix = this._browserVersion.indexOf(' ');
        if (ix !== -1) {
            this._browserVersion = this._browserVersion.substring(0, ix);
        }
        ix = this._browserVersion.indexOf(')');
        if (ix !== -1) {
            this._browserVersion = this._browserVersion.substring(0, ix);
        }

        this._browserMajorVersion = parseInt('' + this._browserVersion, 10);
        if (isNaN(this._browserMajorVersion)) {
            this._browserVersion = '' + parseFloat(navigator.appVersion);
            this._browserMajorVersion = parseInt(navigator.appVersion, 10);
        }

        // mobile version
        this._mobile = this._mobileCheck() || /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

        // cookie
        this._cookies = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled === 'undefined' && !this._cookies) {
            document.cookie = 'testcookie';
            this._cookies = (document.cookie.indexOf('testcookie') !== -1) ? true : false;
        }

        // system
        const clientStrings = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Linux', r: /(Linux|X11)/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
        ];
        for (const id in clientStrings) {
            const cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                this._operationSystem = cs.s;
                break;
            }
        }

        if (/Windows/.test(this._operationSystem)) {
            this._osVersion = /Windows (.*)/.exec(this._operationSystem)[1];
            this._operationSystem = 'Windows';
        }

        switch (this._operationSystem) {
            case 'Mac OS X':
                this._osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'Android':
                this._osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                const osVersionReg: any = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                this._osVersion = osVersionReg[1] + '.' + osVersionReg[2] + '.' + (osVersionReg[3] | 0);
                break;
        }

        this._osMajorVersion = parseInt('' + this._osVersion, 10);
        if (isNaN(this._osMajorVersion)) {
            this._osMajorVersion = parseInt(navigator.appVersion, 10);
        }

        if (build.Options.development) {
            this._printDebug();
        }

    }

    private _mobileCheck(): boolean {
        let check = false;
        (function(a: string) {
            if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    /** print debug platform informations */
    private _printDebug(): void {

        console.info("Platform: ", this);

    }

}
