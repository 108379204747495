import { Clock } from "../../lib/threejs/core/Clock";
import { PerformanceMeasurement } from "../render/QualityLevels";
import { EventOneArg } from "../core/Events";

export const OnUpdate:EventOneArg<number> = new EventOneArg<number>();
export const OnPostUpdate:EventOneArg<void> = new EventOneArg<void>();

// delayed calls
export const nextFrameCalls = [];
export function callOnNextFrame(lambda:() => void) {
    nextFrameCalls.push(lambda);
}

// global tick
export const tick = {
    // constants
    IdealTimePerFrame : 1000.0 / 60.0,
    IdealDeltaTime: 1.0 / 60.0,
    MaxCatchUp: 2,
    // frame variables
    globalClock: new Clock(),
    timeAtLastFrame: performance.now(),
    leftover: 0.0,
    frameCount: 0,
    gameFrameCount: 0,
    inFrame: false,
    needTick: true,
    performanceMeasurement: {
        averageTime: 0.0, minTime: 999999.0, maxTime: 0.0, count: 0, startTime: undefined, totalTime: 0.0
    }
};
export const performanceTest = {
    /** time range in seconds */
    TimeRange: 4.0,
    /** running speed test */
    performanceTest: false,
    /** performance result callback */
    callback: null
};
export function redInternalSpeedTest() {
    if(tick.performanceMeasurement.startTime === undefined) {
        tick.performanceMeasurement.startTime = performance.now();
    }

    // testing for slow systems
    const seconds = (performance.now() - tick.performanceMeasurement.startTime) * 0.001;

    if(seconds > performanceTest.TimeRange) {
        console.info("APP: Running at Speed Level: ", tick.performanceMeasurement);

        performanceTest.performanceTest = false;

        if(performanceTest.callback) {
            performanceTest.callback(tick.performanceMeasurement);
        }

        // activated again
        if(performanceTest.performanceTest) {
            // restart testing
            tick.performanceMeasurement.startTime = undefined;
            tick.performanceMeasurement.totalTime = 0.0;
            tick.performanceMeasurement.minTime = 999999.0;
            tick.performanceMeasurement.maxTime = 0.0;
            tick.performanceMeasurement.count = 0;
        }
    }
}

/**
 * start application performance measurement
 *
 * @export
 * @param {(PerformanceMeasurement) => void} callback
 */
export function measureAppPerformance(callback:(value:PerformanceMeasurement) => void, timeRange:number = 4.0) {
    // start speed test
    performanceTest.performanceTest = true;
    performanceTest.TimeRange = timeRange || 4.0;
    performanceTest.callback = callback;

    // restart testing
    tick.performanceMeasurement.startTime = undefined;
    tick.performanceMeasurement.totalTime = 0.0;
    tick.performanceMeasurement.minTime = 999999.0;
    tick.performanceMeasurement.maxTime = 0.0;
    tick.performanceMeasurement.count = 0;
}
