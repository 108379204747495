/**
 * Unlit.ts: unlit shader
 *
 * Parameters:
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Transparency (TODO)
 * * alphaMap -- Alpha Texture
 * * alphaMult --
 * * opacity -- Opacity value (diffuse.A * opacity)
 * #### Modifications
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector4 } from "../../../lib/threejs/math/Vector4";
import { Color } from "../../../lib/threejs/math/Color";
import { NotEqualDepth } from "../../../lib/threejs/constants";
import {mergeUniforms, EUniformType} from '../Uniforms';
import {setValueShader, applyShaderToRenderer, ShaderVariant, variantIsSet, ShaderStencilOp} from '../Shader';
import { ShaderBuilder, ShaderModule, UniformLib } from "../ShaderBuilder";
import { whiteTexture } from "../Texture";
import { Render } from "../Render";
import { Mesh } from "../Mesh";
import { Line } from "../../render-line/Line";

import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    // first import code
    shaderBuilder.importCode(["redCommon", "redPrecision", "redUnlit_Vertex", "redUnlit_Pixel"]).then( () => {

        shaderBuilder.createShader("redUnlit", {
            redSettings: {
                lights: false,
                isRawMaterial: true,
                order: 7
            },
            selector(variant:ShaderVariant) : string | void {
                // shadow rendering
                if(variantIsSet(ShaderVariant.VSM, variant)) {
                    return "redVSMDepth";
                }
                if(variantIsSet(ShaderVariant.ESM, variant)) {
                    return "redESMDepth";
                }
                if(variantIsSet(ShaderVariant.PCF, variant)) {
                    return "redPCFDepth";
                }
            },
            // supported variants
            variants: [ShaderVariant.DEFAULT, ShaderVariant.IBL, ShaderVariant.LIGHTING_DIFFUSE],
            uniforms: mergeUniforms( [
                UniformLib["fog"],
            {
                baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
            }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
            },
            vertexShader: "redUnlit_Vertex",
            fragmentShader: "redUnlit_Pixel"
        });

        /**
         * unlit transparent (premultiply)
         */
        shaderBuilder.createShader("redUnlitTransparent", {
            redSettings: {
                lights: false,
                isRawMaterial: true,
                blending: "normal"
            },
            selector(variant:ShaderVariant) : string | void {
                // shadow rendering
                if(variantIsSet(ShaderVariant.VSM, variant)) {
                    return "redVSMDepth";
                }
                if(variantIsSet(ShaderVariant.ESM, variant)) {
                    return "redESMDepth";
                }
                if(variantIsSet(ShaderVariant.PCF, variant)) {
                    return "redPCFDepth";
                }
            },
            // supported variants
            variants: [ShaderVariant.DEFAULT, ShaderVariant.IBL, ShaderVariant.LIGHTING_DIFFUSE],
            uniforms: mergeUniforms( [
                UniformLib["fog"],
            {
                baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
                baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                /** transparency */
                opacity : { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "opacity", material, data.opacity);
            },
            vertexShader: "redUnlit_Vertex",
            fragmentShader: "redUnlitTransparent_Pixel"
        });

        /**
         * Transparent masking stencil buffer
         */

        shaderBuilder.createShaderFrom("redUnlitTransparent_Stencil", "redUnlitTransparent", {
            redSettings: {
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                blending: "normal",
                depthTest: true,
                /** stencil */
                stencilTest: true,
                stencilFunc: {
                    func: NotEqualDepth,
                    ref: 1,
                    mask: 0xFFFFFFFF
                },
                stencilOp: {
                    fail: ShaderStencilOp.KEEP,
                    zfail: ShaderStencilOp.KEEP,
                    zpass: ShaderStencilOp.REPLACE
                }
            }
        });
    });

}, ["redCommon", "redPrecision", "redUnlit_Vertex", "redUnlit_Pixel"]);
