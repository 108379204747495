/**
 * Utils.ts: utility shader
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType } from "../Uniforms";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    shaderBuilder.importCode(["redPrecision"]).then( () => {

        shaderBuilder.createShader("redCopy", {
            redSettings: {
                lights: false,
                fog: false,
                depthTest: false,
                depthWrite: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms:
            {
                tDiffuse: { type: EUniformType.TEXTURE, value: null }
            },
            vertexShaderSource: `
                //@include "redPrecision"
                //attributes
                attribute vec3 position;
                attribute vec2 uv;
                // uniforms
                uniform mat4 modelMatrix;
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;
                // varyings
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    vUv.y = 1.0 - vUv.y;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,
            fragmentShaderSource: `
                //@include "redPrecision"
                uniform sampler2D tDiffuse;
                varying vec2 vUv;

                void main() {
                    gl_FragColor = texture2D(tDiffuse, vUv);
                }
            `
        });
    });

});
