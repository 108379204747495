/**
 * Entity.ts: Entity definition
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Object3D } from '../../lib/threejs/core/Object3D';
import { Quaternion } from '../../lib/threejs/math/Quaternion';
import { Vector3 } from '../../lib/threejs/math/Vector3';
import { Box3 } from '../../lib/threejs/math/Box3';
import { Euler } from '../../lib/threejs/math/Euler';
import {build} from '../core/Build';
import {GraphicsDisposeSetup} from '../core/Globals';
import {Component, constructComponent, ComponentId, preloadComponent} from './Component';
import {Render} from '../render/Render';
import {IWorld} from './WorldAPI';
import {World} from './World';
import {EventNoArg } from "../core/Events";
import {tick } from "./Tick";
import {WorldFileNode} from "../framework-types/WorldFileFormat";
import { queryTaggingSystem } from "./TaggingAPI";

export enum EEntityFlags {
    None = 0x0000,
    Persistent = 0x0001,
    Transient = 0x0002,
    HideInHierarchy = 0x0004,
    Component = 0x0008,
    Visible = 0x0010,
    Prefab = 0x0020
}
/** default flags entity copies from parent */
const DEFAULT_PARENT_FLAGS_MASK = EEntityFlags.Transient || EEntityFlags.Prefab;

/**
 * @class Entity
 * GameObject in the hierachy
 * can have n components
 * [[include:sourceDoc/Entity.md]]
 */
export class Entity extends Object3D {

    public static TransformDirty:number = -1;
    public static HierarchyDirty:number = -1;

    public static IsEntity(obj:Object3D) {
        return obj['isEntity'] === true;
    }

    /** transformtion feedback */
    private _onTransformUpdated:EventNoArg = new EventNoArg();
    public get OnTransformUpdated() {
        return this._onTransformUpdated;
    }

    /** type definition */
    public get isEntity() : boolean {
        return true;
    }

    /** entity flags */
    public get flags() : number {
        return this._flags;
    }

    /** parent entity */
    public get parentEntity() : Entity {
        return this.parent as Entity;
    }

    /** child entities */
    public get childrens() : Array<Entity> {
        return this.children as Array<Entity>;
    }

    /** world reference */
    public get world() : IWorld {
        return World;
    }

    /** position (THREE.Vector3) */
    public get localPosition() : Vector3 {
        return this.position;
    }

    /** position (THREE.Vector3) */
    public set localPosition(position:Vector3) {
        this.position.copy(position);
    }

    /** position in world space */
    public get positionWorld() : Vector3 {
        if(!this._worldPosTemp) {
            this._worldPosTemp = new Vector3();
        }
        // make sure world matrix is correct
        this.updateTransformWorld();
        this._worldPosTemp.setFromMatrixPosition(this.matrixWorld);
        return this._worldPosTemp;
    }

    /** rotation (THREE.Quaternion) */
    public get localRotation() : Quaternion {
        return this.quaternion;
    }

    /** rotation (THREE.Quaternion) */
    public set localRotation(rotation: Quaternion) {
        this.quaternion.copy(rotation);
    }

    /** world rotation */
    public get rotationWorld() : Quaternion {
        if(!this._worldRotTemp) {
            this._worldRotTemp = new Quaternion();
        }
        // make sure world matrix is correct
        this.updateTransformWorld();
        this._worldRotTemp.setFromRotationMatrix(this.matrixWorld);
        return this._worldRotTemp;
    }

    /** scaling (THREE.Vector3) */
    public get scaling() : Vector3 {
        return this.scale;
    }

    /** scaling (THREE.Vector3) */
    public set scaling(scaling:Vector3) {
        this.scale.copy(scaling);
    }

    /** world scaling */
    public get scalingWorld() : Vector3 {
        if(!this._worldScaleTemp) {
            this._worldScaleTemp = new Vector3();
        }
        // make sure world matrix is correct
        this.updateTransformWorld();
        this._worldScaleTemp.setFromMatrixScale(this.matrixWorld);
        return this._worldScaleTemp;
    }

    /** tagging */
    public get tag() : number {
        if(this._tagId) {
            return queryTaggingSystem().tag(this._tagId);
        }
        return 0;
    }
    public set tag(value:number) {
        if(!this._tagId && value) {
            // add this tag
            this._tagId = queryTaggingSystem().registerObject(this, value, undefined);
        }

        // only apply when active
        if(this._tagId) {
            queryTaggingSystem().setTag(this._tagId, value);
        }
    }

    /** userData code */
    public get customData() : any {
        if(this._tagId) {
            return queryTaggingSystem().userData(this._tagId);
        }
        return null;
    }
    public set customData(value:any) {
        if(!this._tagId && value) {
            // add this tag
            this._tagId = queryTaggingSystem().registerObject(this, undefined, value);
        }

        // only apply when active
        if(this._tagId) {
            queryTaggingSystem().setUserData(this._tagId, value);
        }
    }

    /** THREE.js overrides */
    public set userData(value:any) {
        if(this.world && this._tagId !== undefined) {
            this.customData = value;
        }
    }
    public get userData() : any {
        return this.customData;
    }

    /** plain component access */
    public get components() : Component[] {
        return this._components;
    }

    /** prefab object */
    public get prefab() : boolean {
        return (this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab;
    }
    /** return file reference when created from file */
    public get fileReference() : string {
        //FIXME: really?!
        if(this._fileReference === undefined && this.parentEntity) {
            return this.parentEntity.fileReference;
        }
        return this._fileReference;
    }

    /** scene visible */
    public get visible() : boolean {
        return (this._flags & EEntityFlags.Visible) === EEntityFlags.Visible;
    }
    public set visible(value:boolean) {
        if(value !== this.visible) {
            if(value) {
                this._flags |= EEntityFlags.Visible;
            } else {
                this._flags &= ~EEntityFlags.Visible;
            }
            Entity.HierarchyDirty = tick.frameCount;
        }
    }

    /** hierarchy visible */
    public get hideInHierarchy() : boolean {
        return (this._flags & EEntityFlags.HideInHierarchy) === EEntityFlags.HideInHierarchy;
    }

    public set hideInHierarchy(value:boolean) {
        if(value) {
            this._flags |= EEntityFlags.HideInHierarchy;
        } else {
            this._flags &= ~EEntityFlags.HideInHierarchy;
        }
    }

    /** transient state */
    public get transient() : boolean {
        return (this._flags & EEntityFlags.Transient) === EEntityFlags.Transient;
    }
    public set transient(value:boolean) {
        if(value) {
            this._flags |= EEntityFlags.Transient;
        } else {
            this._flags &= ~EEntityFlags.Transient;
        }
    }

    /** persistent state */
    public get persistent() : boolean {
        return (this._flags & EEntityFlags.Persistent) === EEntityFlags.Persistent;
    }
    public set persistent(value:boolean) {
        if(value) {
            this._flags |= EEntityFlags.Persistent;
        } else {
            this._flags &= ~EEntityFlags.Persistent;
        }
    }

    /** entities created on hierarchy through components */
    public get componentEntity() : boolean {
        return (this._flags & EEntityFlags.Component) === EEntityFlags.Component;
    }
    public set componentEntity(value:boolean) {
        if(value) {
            this._flags |= EEntityFlags.Component;
        } else {
            this._flags &= ~EEntityFlags.Component;
        }
    }

    /** entity flags */
    private _flags:number;
    /** components */
    private _components:Array<Component>;
    /** tagging / userData of object */
    private _tagId:ComponentId;
    /** transformations cached */
    private _worldPosTemp:Vector3;
    private _worldRotTemp:Quaternion;
    private _worldScaleTemp:Vector3;
    /** file reference */
    private _fileReference:string;

    /** construct raw entity */
    constructor(name?:string) {
        super();
        this._fileReference = undefined;
        // setup base object3d
        this.matrixAutoUpdate = false;
        this._flags = EEntityFlags.Visible;
        this._components = [];
        this.name = name || "Unknown";
        this._tagId = 0;
    }

    /**
     * cleanup entity
     * destroys child entities and components
     */
    public destroy(dispose?:GraphicsDisposeSetup) : void {

        // reset reference for garbage collection
        if(this._tagId) {
            queryTaggingSystem().removeObject(this._tagId);
        }

        if(build.Options.debugWorldOutput) {
            console.log("Entity::Destroying " + this.name);
        }

        // destroy all components
        if(this._components) {
            const components = this._components.slice(0);
            for(let i = 0; i < components.length; ++i) {
                components[i].destroy(dispose);
            }
            this._components = [];
        }

        // cleanup child entities
        const children = this.children.slice(0) as Entity[];
        for(let i = children.length-1; i >= 0; --i) {
            // remove from three js node
            if(children[i].isEntity && children[i].destroy) {
                children[i].destroy(dispose);
            }
        }
        //this.children = [];

        // remove from parent
        if(this.parent && this.parent !== this.world.scene) {
            this.parentEntity.remove(this);
        } else if(this.world) {
            this.world._removeEntity(this, true);
        }
    }

    /** get component by type */
    public getComponent<T extends Component>(type:any) : T {
        for(let i = 0; i < this._components.length; ++i) {
            if(this._components[i] instanceof type) {
                return this._components[i] as T;
            }
        }
        return null;
    }

    /** get components by type */
    public getComponents<T extends Component>(type:any) : Array<T> {
        const res:Array<T> = [];
        for(let i = 0; i < this._components.length; ++i) {
            if(this._components[i] instanceof type) {
                res.push(this._components[i] as T);
            }
        }
        return res;
    }

    /** get component by type */
    public getComponentInChildren<T extends Component>(type:any) : T {
        for(let i = 0; i < this._components.length; ++i) {
            if(this._components[i] instanceof type) {
                return this._components[i] as T;
            }
        }

        for(let i = 0; i < this.childrens.length; ++i) {
            const component = this.childrens[i].getComponentInChildren<T>(type);

            if(component) {
                return component;
            }
        }
        return null;
    }

    /** get components by type */
    public getComponentsInChildren<T extends Component>(type:any) : Array<T> {
        let res:Array<T> = [];
        for(let i = 0; i < this._components.length; ++i) {
            if(this._components[i] instanceof type) {
                res.push(this._components[i] as T);
            }
        }

        for(let i = 0; i < this.childrens.length; ++i) {
            const components = this.childrens[i].getComponentsInChildren<T>(type);

            if(components && components.length > 0) {
                res = res.concat(components);
            }
        }
        return res;
    }

    /**
     * create component from type
     * and add it directly to entity
     * @param type
     */
    public createComponent<T extends Component>(type:any|string) : T {
        if(typeof type === 'string') {
            const component = constructComponent(type, undefined, this) as T;
            if(component) {
                this.addComponent(component);
            }
            return component;
        } else {
            const component = new type(this);
            if(component) {
                this.addComponent(component);
            }
            return component;
        }
    }

    /**
     * add component to this
     * this assumes that the components is new
     */
    public addComponent(component:Component) {
        // check if already in list?
        for(const c of this._components) {
            if(c === component) {
                return component;
            }
        }
        // add it to our list
        this._components.push(component);

        // set us as reference
        component._setEntityRef(this);

        return component;
    }

    /**
     * remove component from this entity
     */
    public removeComponent(component:Component) {
        if(component.entity !== this) {
            return;
        }
        const index = this._components.indexOf(component);
        if(index !== -1) {
            this._components.splice(index, 1);
            // remove reference
            component._setEntityRef(null);
        }
    }

    /**
     * destroy component from this entity
     */
    public destroyComponent(component:Component, dispose?:GraphicsDisposeSetup) {
        if(component.entity !== this) {
            return;
        }
        const index = this._components.indexOf(component);
        if(index !== -1) {
            // remove from list
            this._components.splice(index, 1);
            // then destroy internal data of component
            component.destroy(dispose);
            // remove reference
            component._setEntityRef(null);
        }
    }

    /** three.js override */
    public add(object:Object3D) : this {
        // remove us self from parent
        if(object.parent) {
            object.parent.remove(object);
        }
        super.add(object);

        // copy flags from parent
        if(Entity.IsEntity(object)) {
            const entity = object as Entity;
            entity._flags |= this._flags & DEFAULT_PARENT_FLAGS_MASK;
        }

        Entity.HierarchyDirty = tick.frameCount;
        return this;
    }

    /** three.js override */
    public remove(object:Object3D) : this {
        super.remove(object as any);
        Entity.HierarchyDirty = tick.frameCount;
        return this;
    }

    /**
     * remove child and destroy
     * including geometry and material references
     */
    public destroyChild(entity:Entity, dispose?:GraphicsDisposeSetup) : boolean {

        if(entity.parent !== this) {
            console.warn("Entity::removeChild: entity is not child object ", entity);
            return false;
        }

        if(entity.componentEntity) {
            console.warn("Entity::removeChild: entity is a component child object ", entity);
            return false;
        }

        const index = this.children.indexOf(entity);
        if(index !== -1) {
            console.assert(!entity.persistent, "try to remove child object that is persistent");
            // remove from three js node
            this.remove(entity);
            entity.matrixWorldNeedsUpdate = true;
            entity.parent = null;

            entity.destroy(dispose);
            return true;
        }
        console.warn("Entity::removeChild: entity is not child object ", entity);
        return false;
    }

    /**
     * destroys all childs
     * including geometry and material references
     */
    public destroyChilds(dispose?:GraphicsDisposeSetup) : void {
        const childs = this.children.slice(0);
        for(const child of childs) {
            const tmp = child as Entity;
            // do not destroy if this is a component child and
            // not in a chain
            if(tmp.componentEntity && !this.componentEntity) {
                continue;
            }
            const success = this.remove(tmp);

            // error
            if(!success) {
                console.error("Entity::removeChilds: failed to remove all childs");
                break;
            }
            // destroy
            tmp.destroy(dispose);
        }
    }

    /** remove self from tree */
    public removeSelf() {

        // remove from parent
        if(this.parentEntity && this.parent !== this.world.scene) {
            this.parentEntity.remove(this);
        } else {
            this.world._removeEntity(this, false);
        }

        //FIXME: should already happen?!!!
        if(this.parent) {
            this.parent.remove(this);
        } else {
            //FIXME: never happens??!!!!!
            this.world.scene.remove(this);
        }
    }

    /**
     * find entity by name
     * will return the first found entity with the same name
     * @param recursive also find entity in childrens
     */
    public findByName(name:string, recursive:boolean) : Entity {
        // no name, no object
        if(!name) {
            console.warn("Entity::findByName: no name given");
            return null;
        }

        //FIXME: check for self??
        // if recursive, this call is unnecessary
        if(this.name === name) {
            return this;
        }

        // find children directly
        if(!recursive) {
            for(let i = 0; i < this.childrens.length; ++i) {
                if(this.childrens[i].name === name) {
                    return this.childrens[i];
                }
            }
        } else {
            for(const child of this.childrens) {
                const entity = child.findByName(name, recursive);

                if(entity) {
                    return entity;
                }
            }
        }

        return null;
    }

    public findByTag(tag:number, recursive:boolean) : Entity[] {
        let result:Entity[] = [];
        // no tag, no object
        if(!tag) {
            console.warn("Entity::findByTag: no tag given");
            return result;
        }

        //FIXME: check for self??
        // if recursive, this call is unnecessary
        if(this.tag === tag) {
            result.push(this);
        }

        // find children directly
        if(!recursive) {
            for(let i = 0; i < this.childrens.length; ++i) {
                if(this.childrens[i].tag === tag) {
                    result.push(this.childrens[i]);
                }
            }
        } else {
            for(const child of this.childrens) {
                const entities = child.findByTag(tag, recursive);

                if(entities.length > 0) {
                    result = result.concat(entities);
                }
            }
        }

        return result;
    }

    public findByPredicate(callback:(entity:Entity) => boolean, recursive:boolean) : Entity[] {
        let result:Entity[] = [];
        // no tag, no object
        if(!callback) {
            console.warn("Entity::findByTag: no tag given");
            return result;
        }

        //FIXME: check for self??
        // if recursive, this call is unnecessary
        if(callback(this)) {
            result.push(this);
        }

        // find children directly
        if(!recursive) {
            for(let i = 0; i < this.childrens.length; ++i) {
                if(callback(this.childrens[i])) {
                    result.push(this.childrens[i]);
                }
            }
        } else {
            for(const child of this.childrens) {
                const entities = child.findByPredicate(callback, recursive);

                if(entities.length > 0) {
                    result = result.concat(entities);
                }
            }
        }

        return result;
    }

    /**
     * check if entity is a child
     * @param entity entity to search
     * @param recursive find hierarchical
     */
    public isChild(entity:Entity, recursive:boolean = true) : boolean {
        for(const child of this.childrens) {
            if(child === entity) {
                return true;
            }
        }
        if(recursive) {
            for(const child of this.childrens) {
                if(child.isChild(entity, recursive)) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * update transform
     * @param forceWorld force to update to whole chain including localToWorld matrix
     * CALL this after you have changed position, scale, rotation
     * TODO: check if transform needs to update matrix world too (some stuff use matrixWorld)
     */
    public updateTransform(forceWorld:boolean = false) {
        // set transform dirty on this frame
        Entity.TransformDirty = tick.frameCount;

        // update local matrix
        this.updateMatrix();

        // mark every child that their world matrix needs an update
        // but do not update them directly
        function mark(node:Object3D) {
            node.matrixWorldNeedsUpdate = true;
            for(const child of node.children) {
                mark(child);
            }
        }
        for(const child of this.children) {
            mark(child);
        }

        // update world transformation
        if(forceWorld) {
            this.updateTransformWorld();
        }

        /** update attached components */
        for(const c of this._components) {
            c.onTransformUpdate();
        }

        // notify every child that their transformation has changed
        //FIXME: just check every child?
        function notifyUpdate(node:Entity) {
            if(node.matrixWorldNeedsUpdate === true || forceWorld) {
                for(const c of node._components) {
                    c.onTransformUpdate();
                }
            }
            for(const child of node.children) {
                if(child['isEntity']) {
                    notifyUpdate(child as Entity);
                }
            }
        }
        for(const child of this.children) {
            if(child['isEntity']) {
                notifyUpdate(child as Entity);
            }
        }

        // inform others
        this._onTransformUpdated.trigger();
    }

    /**
     * goes up the hierachy and updates all
     * that have a dirty world transformation
     */
    public updateTransformWorld() {
        // make sure chain is correct
        let first:Object3D = this;
        let needsUpdate = false;
        if(first.matrixWorldNeedsUpdate && first.parent) {
            first = first.parent;
            needsUpdate = true;
        }

        if(needsUpdate) {
            first.updateMatrixWorld(false);
        }
    }

    /**
     * get complete world boundings from this entity
     * @param traverseChilds include childs
     */
    public getWorldBounds(traverseChilds:boolean) : Box3 {
        const bounds = new Box3();

        if(this['isMesh'] === true) {
            const mesh = this as any;

            // on demand bounding box creation
            if(!mesh.geometry.boundingBox) {
                mesh.geometry.computeBoundingBox();
            }
            const boundingBox = mesh.geometry.boundingBox;
            const tempVector = new Vector3();

            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z)));
            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z)));
            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z)));
            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z)));
            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z)));
            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z)));
            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z)));
            bounds.expandByPoint(mesh.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z)));
        }

        if(traverseChilds) {
            for(const child of this.childrens) {
                if(child.getWorldBounds) {
                    const bound = child.getWorldBounds(traverseChilds);
                    bounds.union(bound);
                }
            }
        }

        // get component bounds
        if(this._components) {
            for(let i = 0; i < this._components.length; ++i) {
                //TODO
            }
        }

        return bounds;
    }

    /**
     * like load but instantiate from no data
     * this will be called from World
     * NEVER CALL THIS ON YOUR OWN
     */
    public instantiate() {
        // copy parent flags
        if(this.parentEntity) {
            this._flags |= this.parentEntity.flags & DEFAULT_PARENT_FLAGS_MASK;
        }

        //FIXME: instantiate all childs?!
        for(const child of this.childrens) {
            child.instantiate();
        }
    }

    /**
     * load from node data
     * NEVER CALL THIS ON YOUR OWN
     */
    public load(node:WorldFileNode, fileReference?:string) {

        if(!node) {
            console.warn("Entity::load: cannot load data for ", this);
            return;
        }

        this.name = node.name || "Unknown";
        this._flags = EEntityFlags.Visible | (node.flags || 0);
        this._fileReference = fileReference;

        if(node.type === "prefab") {
            this._flags |= EEntityFlags.Prefab;
        }

        if(node.translation) {
            this.position.fromArray(node.translation);
        }

        if(node.scaling) {
            this.scale.fromArray(node.scaling);
        }

        if(node.rotation) {
            this.quaternion.setFromEuler( new Euler(node.rotation[0] * Math.PI/180.0,
                                                        node.rotation[1] * Math.PI/180.0,
                                                        node.rotation[2] * Math.PI/180.0, 'XYZ'));

        }
        // apply transformation
        this.updateTransform(false);
    }

    /** export hierarchy from this to prefab */
    public exportAsPrefab() : WorldFileNode {
        console.assert((this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab);

        // find top most prefab object
        if(this.parentEntity && this.parentEntity.prefab) {

            // no valid file reference for us
            if(!this.fileReference) {
                return this.parentEntity.exportAsPrefab();
            } else if(this.parentEntity.fileReference === this.fileReference) {
                return this.parentEntity.exportAsPrefab();
            }
        }
        const node = this._exportPrefab();
        return node;
    }

    /** save node */
    public save() {
        const node:WorldFileNode = {
            type: "node",
            name: "",
            flags: 0,
            translation: [0,0,0],
            scaling: [1,1,1],
            rotation: [0,0,0],
            components: [],
            children: []
        };

        if((this._flags & EEntityFlags.Prefab) === EEntityFlags.Prefab) {
            node.type = "prefab";
            node.name = this.name;

            node.translation[0] = this.position.x;
            node.translation[1] = this.position.y;
            node.translation[2] = this.position.z;

            node.scaling[0] = this.scale.x;
            node.scaling[1] = this.scale.y;
            node.scaling[2] = this.scale.z;

            node.rotation[0] = this.rotation.x * 180.0/Math.PI;
            node.rotation[1] = this.rotation.y * 180.0/Math.PI;
            node.rotation[2] = this.rotation.z * 180.0/Math.PI;
        } else {
            node.name = this.name;

            node.translation[0] = this.position.x;
            node.translation[1] = this.position.y;
            node.translation[2] = this.position.z;

            node.scaling[0] = this.scale.x;
            node.scaling[1] = this.scale.y;
            node.scaling[2] = this.scale.z;

            node.rotation[0] = this.rotation.x * 180.0/Math.PI;
            node.rotation[1] = this.rotation.y * 180.0/Math.PI;
            node.rotation[2] = this.rotation.z * 180.0/Math.PI;

            if(this._components) {
                for(const component of this._components) {
                    if(component) {
                        node.components.push(component.save());
                    }
                }
            }

            for(const child of this.childrens) {
                if(child && child.isEntity && !child.transient) {
                    node.children.push(child.save());
                }
            }
        }

        return node;
    }

    public static Preload(node:WorldFileNode, preloadFiles:any[]) {
        if(node.components && Array.isArray(node.components)) {
            for(const component of node.components) {
                preloadComponent(component, preloadFiles);
            }
        }

        if(node.children && Array.isArray(node.children)) {
            for(const child of node.children) {
                Entity.Preload(child, preloadFiles);
            }
        }
    }

    private _exportPrefab() {
        const node:WorldFileNode = {
            type: "prefab",
            name: "",
            flags: 0,
            translation: [0,0,0],
            scaling: [1,1,1],
            rotation: [0,0,0],
            components: [],
            children: []
        };

        //TODO: get origin?!
        node.name = this.name;

        node.translation[0] = this.position.x;
        node.translation[1] = this.position.y;
        node.translation[2] = this.position.z;

        node.scaling[0] = this.scale.x;
        node.scaling[1] = this.scale.y;
        node.scaling[2] = this.scale.z;

        node.rotation[0] = this.rotation.x * 180.0/Math.PI;
        node.rotation[1] = this.rotation.y * 180.0/Math.PI;
        node.rotation[2] = this.rotation.z * 180.0/Math.PI;

        if(this._components) {
            for(const component of this._components) {
                if(component) {
                    node.components.push(component.save());
                }
            }
        }

        for(const child of this.childrens) {
            if(child && child.isEntity && !child.transient) {

                // check if child is also a prefab but not the same file reference
                // then save this as node
                if(child.prefab && child.fileReference !== this.fileReference) {

                    node.children.push(child.save());
                    continue;
                }

                node.children.push(child._exportPrefab());
            }
        }

        return node;
    }
}

/**
 * clears function calls which object3d cannot deliver
 */
class Object3DPolyfill {

    public think(render:Render) : void {
    }

    public render(): void {
    }

    public addComponent(component:Component) {
    }

    public removeComponent(component:Component) {
    }

    public destroyComponent(component:Component) {
    }

    public getComponent<T extends Component>(type:any) : T {
        return null;
    }

    public getComponents<T extends Component>(type:any) : Array<T> {
        return [];
    }

    public getComponentInChildren<T extends Component>(type:any) : T {
        return null;
    }

    public getComponentsInChildren<T extends Component>(type:any) : Array<T> {
        return [];
    }
}

function applyEntityToObject3D() {
    Object.getOwnPropertyNames(Entity.prototype).forEach((name) => {

        // ignore entity getter
        if(name === "isEntity") {
            return;
        }

        // ignore base functions (do not overwrite anything)
        if(Object3D.prototype[name]) {
            return;
        }

        const descriptor = Object.getOwnPropertyDescriptor(Entity.prototype, name);

        if(descriptor) {
            Object.defineProperty(Object3D.prototype, name, descriptor);
        }
    });

    Object.getOwnPropertyNames(Object3DPolyfill.prototype).forEach((name) => {

        const descriptor = Object.getOwnPropertyDescriptor(Object3DPolyfill.prototype, name);

        if(descriptor) {
            Object.defineProperty(Object3D.prototype, name, descriptor);
        }

    });
}
applyEntityToObject3D();
