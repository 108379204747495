
import * as THREE from "redTyped/three"

import {Block} from '../../block/Block';

import {seed, simplex2} from './PerlinNoise';

import './hexagonShader';
import { AppDelegate } from "redTyped/framework/AppDelegate";
import { RenderQuality } from "redTyped/render/QualityLevels";
import { createPlatformRenderInitConfig } from "redTyped/render/Config";
import { CameraComponent } from "redTyped/framework-components/CameraComponent";
import { Platform } from "redTyped/core/Platform";
import { FileStat } from "redTyped/io/AssetInfo";
import { NumberAnimation, Vector3Animation, EValueInterpolation } from "redTyped/animation/ValueAnimation";
import { ShaderLibrary } from "redTyped/render/ShaderLibrary";
import { DirectionalLightComponent } from "redTyped/framework-lights/DirectionalLightComponent";
import { Color, Vector3 } from "redTyped/three";

/** Application */
export class Hexagon3D extends AppDelegate {

    public static maxHeight = 5.0;
    public static hexaCount = 22;
    public static instances = Hexagon3D.hexaCount * Hexagon3D.hexaCount;
    public static radius = 5.0;
    public static gap = 0.0;
    public static gridAnimSpeed = 8.0;
    public static HexagonInitColor = [0.941176, 0.254901, 0.1372549];
    public static HexagonBackgroundColor = [0.2, 0.211764, 0.1921568];

    /** hexagon */
    public hexagons = [];

    public hexagonGeometry;
    public cylinderMesh;
    public hexagonMesh;

    /** animation */
    private _noiseXOffset:number = 0;
    private _noiseYOffset:number = 0;

    private _noiseXSign:NumberAnimation = new NumberAnimation(EValueInterpolation.Linear, 0.25);
    private _noiseYSign:NumberAnimation = new NumberAnimation(EValueInterpolation.Linear, 0.25);

    private rangeAnim:NumberAnimation = new NumberAnimation(EValueInterpolation.Linear, 1.0);

    private _lastNoiseChangeTime:number = 0;

    /** DOM */
    private _sceneElement:Element;

    /** construction */
    constructor(domElement:Element,  private _block:Block, params:any) {
        super();

        this._sceneElement = domElement || document.querySelector(".scene");

        let qualityLevel = RenderQuality.HighQuality;

        this._renderConfig = createPlatformRenderInitConfig({
            DOMElement: this.containerElement(),
            qualityLevel: qualityLevel,
            renderShadowMaps: true,
            renderAntialias: true,
            renderOffscreen: false
        });

    }

    /**
     * preload callback
     */
    onPreloadItems() {

    }

    onInitialization() {
        super.onInitialization();

        const cameraComponent = this.world.findByName("mainCamera").getComponent<CameraComponent>(CameraComponent);

        // orbit camera
        cameraComponent.setupOrbitController(this._sceneElement);

        cameraComponent.orbit.enableDamping = true;
        cameraComponent.orbit.dampingFactor = 0.2;
        cameraComponent.orbit.enablePan = false;
        cameraComponent.orbit.enableZoom = false;
        cameraComponent.orbit.enableRotate = false;

        var dpr = window.devicePixelRatio !== undefined ? window.devicePixelRatio : 1.0;
        cameraComponent.orbit.zoomSpeed = Platform.get().isTouchDevice ? 2.0 / dpr : 2.0;
        cameraComponent.orbit.rotateSpeed = Platform.get().isTouchDevice ? 0.15 : 0.25;
        cameraComponent.orbit.minDistance = 0.1;
        cameraComponent.orbit.maxDistance = 200.0;

        cameraComponent.target.set(0.0, -5.0, 0.0);

        cameraComponent.sceneCamera.position.set(-60.0, 55.0, -120.0);
        cameraComponent.entity.updateTransform();
        cameraComponent.near = 1.0;
        cameraComponent.far = 400.0;
        cameraComponent.fov = 60.0;

        this.world.setEnvironment({
            color: [51.0/255.0, 54.0/255.0, 49.0/255.0]
        });

        const ent = this.world.instantiateEntity("directional_light");
        ent.position.set(100, 100, 100);
        const dir = new Vector3(-600, -600, -600).normalize();
        ent.quaternion.setFromUnitVectors(new Vector3(0, 0, 1), dir);
        ent.updateTransform();

        const directionalLight = ent.createComponent<DirectionalLightComponent>(DirectionalLightComponent);
        directionalLight.load({
            module: "RED",
            type: "DirectionalLightComponent",
            parameters: {
                color: [0.7, 0.7, 0.7],
                intensity: 1.0,
                castShadow: false,
            }
        });

        const ambientLight = new THREE.HemisphereLight(new Color(1,1,1), new Color(0.8, 0.8, 0.8), 1.1);
        this.world.scene.add(ambientLight);

        this._initGroundPlane();
        this._initHexagonGrid();
    }

    containerElement():HTMLElement {
        return this._sceneElement as HTMLElement;
    }

    /** frame tick */
    update(delta:number) {
        super.update(delta);


        this._randomAnimGrid();

        this._updateHexagonGrid();

    }

    private _initGroundPlane() {

        let geometryGrey = new THREE.PlaneGeometry(1000, 1000, 1);
        //const planeHeightGrey = 4.90;
        const planeHeightGrey = 5.01;
        let materialGrey = new THREE.MeshBasicMaterial( {color: 0x333631} );
        let planeGrey = new THREE.Mesh( geometryGrey, materialGrey );
        planeGrey.position.set(0,planeHeightGrey,0);
        planeGrey.rotation.set(-Math.PI*0.5,0,0);
        this.world.scene.add(planeGrey);
    }

    private _randomAnimGrid() {
        // every n seconds we change the translation
        const SignChangeTime = 10.0;
        const lastNoiseChange = (Date.now()-this._lastNoiseChangeTime)/1000;

        if(lastNoiseChange > SignChangeTime) {
            //this._noiseXSign.value = Math.random() > 0.5 ? 1.0 : 0.75;
            //this._noiseYSign.value = Math.random() > 0.5 ? 1.0 : 0.75;

            this.rangeAnim.value = Math.random();
            this.rangeAnim.speed = 0.1;

            this._lastNoiseChangeTime = Date.now();
        }

        const speed = 0.05;

        //const range = 7.5;
        const range = this.rangeAnim.smoothValue * 0.5 + 7.5;

        const blockHeight = 10.0;

        const REDcolor = new THREE.Vector3(Hexagon3D.HexagonInitColor[0], Hexagon3D.HexagonInitColor[1], Hexagon3D.HexagonInitColor[2]);
        const GREYcolor = new THREE.Vector3(Hexagon3D.HexagonBackgroundColor[0], Hexagon3D.HexagonBackgroundColor[1], Hexagon3D.HexagonBackgroundColor[2]);

        const lerpVector = new THREE.Vector3();
        const halfElements = Math.floor(this.hexagons.length / 2.0);

        for(let y = 0; y < this.hexagons.length; ++y) {
            for(let x = 0; x < this.hexagons[y].length; ++x) {


                let value = simplex2((this._noiseXOffset + x)/range, (this._noiseYOffset + y)/range) + 1.0 * 0.5;
                value = Math.min(1.0, Math.max(0.0, value));


                let distanceToCenter = (x - halfElements) * (x - halfElements) + (y - halfElements) * (y - halfElements);
                distanceToCenter /= (halfElements * halfElements);
                distanceToCenter = Math.min(1.0, Math.max(0.0, distanceToCenter));
                value *= 1.0 - distanceToCenter;

                //console.log(value);
                const height = value * blockHeight;

                this.hexagons[y][x].height.value = 5.0 + height;


                lerpVector.lerpVectors(GREYcolor, REDcolor, value);
                this.hexagons[y][x].color.value = lerpVector.clone();
            }
        }

        this._noiseXOffset += this._noiseXSign.smoothValue * speed;
        this._noiseYOffset += this._noiseYSign.smoothValue * speed;
    }

    private _updateHexagonGrid() {

        if(this.hexagonGeometry.boundingSphere != null){
            this.hexagonGeometry.boundingSphere.radius = 200;
        }

        for(let y = 0; y < this.hexagons.length; ++y) {

            for(let x = 0; x < this.hexagons[y].length; ++x) {

                let index = y * Hexagon3D.hexaCount * 3 + (x * 3);

                //if(y%2==0){
                if((y & 1) == 0) {
                    this.hexagons[y][x].neighborHeights = [
                        this._getHeightNeighbor(this.hexagons, y-1, x-1),
                        this._getHeightNeighbor(this.hexagons, y-1, x),
                        this._getHeightNeighbor(this.hexagons, y, x+1),
                        this._getHeightNeighbor(this.hexagons, y+1, x),
                        this._getHeightNeighbor(this.hexagons, y+1, x-1),
                        this._getHeightNeighbor(this.hexagons, y, x-1)
                    ];
                } else {
                        this.hexagons[y][x].neighborHeights = [
                        this._getHeightNeighbor(this.hexagons, y-1, x),
                        this._getHeightNeighbor(this.hexagons, y-1, x+1),
                        this._getHeightNeighbor(this.hexagons, y, x+1),
                        this._getHeightNeighbor(this.hexagons, y+1, x+1),
                        this._getHeightNeighbor(this.hexagons, y+1, x),
                        this._getHeightNeighbor(this.hexagons, y, x-1)
                    ];
                }


                this.hexagonGeometry.attributes.color.array[index] = this.hexagons[y][x].color.smoothValue.x;
                this.hexagonGeometry.attributes.color.array[index+1] = this.hexagons[y][x].color.smoothValue.y;
                this.hexagonGeometry.attributes.color.array[index+2] = this.hexagons[y][x].color.smoothValue.z;

                this.hexagonGeometry.attributes.offset.array[index] = this.hexagons[y][x].offset.x;
                this.hexagonGeometry.attributes.offset.array[index+1] = this.hexagons[y][x].height.smoothValue - 5.0;
                this.hexagonGeometry.attributes.offset.array[index+2] = this.hexagons[y][x].offset.y;

                this.hexagonGeometry.attributes.neighborHeights0.array[index] = this.hexagons[y][x].neighborHeights[0];
                this.hexagonGeometry.attributes.neighborHeights0.array[index+1] = this.hexagons[y][x].neighborHeights[1];
                this.hexagonGeometry.attributes.neighborHeights0.array[index+2] = this.hexagons[y][x].neighborHeights[2];

                this.hexagonGeometry.attributes.neighborHeights1.array[index] = this.hexagons[y][x].neighborHeights[3];
                this.hexagonGeometry.attributes.neighborHeights1.array[index+1] = this.hexagons[y][x].neighborHeights[4];
                this.hexagonGeometry.attributes.neighborHeights1.array[index+2] = this.hexagons[y][x].neighborHeights[5];

                if(this.hexagons[y][x].sprite) {
                    this.hexagons[y][x].sprite.position.set(this.hexagons[y][x].offset.x, this.hexagons[y][x].height.smoothValue + 1.0, this.hexagons[y][x].offset.y);
                }

            }
        }

        this.hexagonGeometry.attributes.offset.needsUpdate = true;
        this.hexagonGeometry.attributes.color.needsUpdate = true;
        this.hexagonGeometry.attributes.neighborHeights0.needsUpdate = true;
        this.hexagonGeometry.attributes.neighborHeights1.needsUpdate = true;
    }

    private _getHeightNeighbor(hexagons, y, x) {
        if(y >= 0 && y < hexagons.length) {
            if(x >= 0 && x < hexagons[y].length) {
                //return hexagons[y][x].height;
                return hexagons[y][x].height.smoothValue;
            }
        }
        return -1.0;
    }

    private _initHexagonGrid() {

        const stepWidth = (2*Hexagon3D.radius*Math.cos(Math.PI/6))*(1+Hexagon3D.gap);
        const stepHeight = (Hexagon3D.radius + Hexagon3D.radius*Math.sin(Math.PI/6))*(1+Hexagon3D.gap);

        this.hexagonGeometry = new THREE.InstancedBufferGeometry();
        this.hexagonGeometry.maxInstancedCount = Hexagon3D.instances;
        this.hexagonGeometry.dynamic = true;

        let cylinderGeometry = new THREE.CylinderBufferGeometry(Hexagon3D.radius, Hexagon3D.radius, 10, 6 );
        this.cylinderMesh = new THREE.Mesh( cylinderGeometry);

        let hexagonVertices = new THREE.BufferAttribute(new Float32Array(Array.prototype.slice.call(cylinderGeometry.attributes.position.array)), 3);
        let hexagonNormals =  new THREE.BufferAttribute(new Float32Array(Array.prototype.slice.call(cylinderGeometry.attributes.normal.array)), 3);

        let vertexCount = hexagonVertices.count;

        let hexagonIndex = new THREE.BufferAttribute(new Uint16Array(Array.prototype.slice.call(cylinderGeometry.index.array)), 1);
        let hexagonNeighbours0Array = new Float32Array(Hexagon3D.instances * 3);
        let hexagonNeighbours0 = new THREE.InstancedBufferAttribute(new Float32Array(Hexagon3D.instances * 3), 3, 1 );
        let hexagonNeighbours1Array = new Float32Array(Hexagon3D.instances * 3);
        let hexagonNeighbours1 = new THREE.InstancedBufferAttribute(new Float32Array(Hexagon3D.instances * 3), 3, 1 );
        let hexagonOffsetsArray = new Float32Array(Hexagon3D.instances * 3);
        let hexagonOffsets = new THREE.InstancedBufferAttribute(new Float32Array(Hexagon3D.instances * 3), 3, 1 );
        let hexagonColorsArray = new Float32Array(Hexagon3D.instances * 3);
        let hexagonColors = new THREE.InstancedBufferAttribute(new Float32Array(Hexagon3D.instances * 3), 3, 1 );

        let sizeX = (Hexagon3D.hexaCount-1) * stepWidth + 3 * Hexagon3D.radius * Math.cos(Math.PI/6) + 2 * Hexagon3D.gap;
        let sizeY = (Hexagon3D.hexaCount-1) * stepHeight + Hexagon3D.radius * 2;

        for(let y=0; y < Hexagon3D.hexaCount; y++) {

            this.hexagons[y] = [];

            let yPosition = y*stepHeight;
            let xOffset = (y & 1) ? stepWidth * 0.5 : 0.0;

            for(let x=0; x < Hexagon3D.hexaCount; x++) {

                let xPosition = (x * stepWidth) + xOffset;
                let index = y * Hexagon3D.hexaCount * 3 + (x * 3);
                let position = new THREE.Vector3(xPosition+Hexagon3D.radius*Math.cos(Math.PI/6) - (sizeX*0.5), 5.0, yPosition+Hexagon3D.radius - (sizeY*0.5));

                hexagonOffsetsArray[index] = position.x;
                hexagonOffsetsArray[index+1] = position.y;
                hexagonOffsetsArray[index+2] =  position.z;

                hexagonColorsArray[index] = Hexagon3D.HexagonInitColor[0];
                hexagonColorsArray[index+1] = Hexagon3D.HexagonInitColor[1];
                hexagonColorsArray[index+2] =  Hexagon3D.HexagonInitColor[2];

                this.hexagons[y][x] = {
                    offset: new THREE.Vector2(position.x, position.z),
                    height: new NumberAnimation(EValueInterpolation.SmoothDamp, position.y),
                    color: new Vector3Animation(EValueInterpolation.Linear, new THREE.Vector3(1,1,1)),
                    neighborHeights: [],
                    sprite: null
                };

                this.hexagons[y][x].height.speed = Hexagon3D.gridAnimSpeed;
                this.hexagons[y][x].color.speed = Hexagon3D.gridAnimSpeed;
                this.hexagons[y][x].color.value = new THREE.Vector3(Hexagon3D.HexagonInitColor[0], Hexagon3D.HexagonInitColor[1], Hexagon3D.HexagonInitColor[2]);

                hexagonNeighbours0Array[index] = 0.0;
                hexagonNeighbours0Array[index+1] = 0.0;
                hexagonNeighbours0Array[index+2] = 0.0;

                hexagonNeighbours1Array[index+0] = 0.0;
                hexagonNeighbours1Array[index+1] = 0.0;
                hexagonNeighbours1Array[index+2] = 0.0;
            }
        }

        this.hexagonGeometry.addAttribute('position', hexagonVertices);
        this.hexagonGeometry.addAttribute('normal', hexagonNormals);
        this.hexagonGeometry.addAttribute('offset', hexagonOffsets);
        this.hexagonGeometry.addAttribute('color', hexagonColors);
        this.hexagonGeometry.addAttribute('neighborHeights0', hexagonNeighbours0);
        this.hexagonGeometry.addAttribute('neighborHeights1', hexagonNeighbours1);

        this.hexagonGeometry.setIndex(hexagonIndex);

        let matShader = this._initHexagonMaterial();

        // add mesh
        this.hexagonMesh = new THREE.Mesh(this.hexagonGeometry, matShader);
        this.hexagonMesh.castShadow = true;
        this.hexagonMesh.receiveShadow = true;
        this.world.scene.add(this.hexagonMesh);
    }

    private _initHexagonMaterial() {

        const stepWidth = (2*Hexagon3D.radius*Math.cos(Math.PI/6))*(1+Hexagon3D.gap);
        const stepHeight = (Hexagon3D.radius + Hexagon3D.radius*Math.sin(Math.PI/6))*(1+Hexagon3D.gap);

        let sizeX = (Hexagon3D.hexaCount-1) * stepWidth + 3 * Hexagon3D.radius * Math.cos(Math.PI/6) + 2 * Hexagon3D.gap;
        let sizeY = (Hexagon3D.hexaCount-1) * stepHeight + Hexagon3D.radius * 2;

        // generate texture
        let template = {
            shader: "hexagon",
            offsetRepeat: [
                0.0,
                0.0,
                1.0,
                1.0
            ],
            diffuse: [
                1.0,
                1.0,
                1.0
            ],
            transparent: false,
            metalness: 0.0,
            roughness: 0.6
        };

        template['gridSize'] = [2.0/sizeX, 2.0/sizeY];
        template['background'] = new THREE.Color(Hexagon3D.HexagonBackgroundColor[0], Hexagon3D.HexagonBackgroundColor[1], Hexagon3D.HexagonBackgroundColor[2]);

        let matShader = ShaderLibrary.createMaterialShader("hexagon", template);
        matShader.flatShading = true;

        return matShader;
    }

    onLoad(loadingScreen:boolean) {
    }

	onLoadProgress(stats:FileStat) {

    }

    onLoadFinished() {
        let preloader = this.containerElement().querySelector(".scene_preloader");
        jQuery(preloader).fadeOut();
    }

}
