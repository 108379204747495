///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class ScrollTransformComponent extends AbstractBlockComponent {

    private _onCenterDistanceUpdateHandler:()=>void;;
    private _blockContents:NodeListOf<Element>;

    protected onInit():void{
        this._blockContents = this._block.domElement.querySelectorAll('.block_content');

        this._onCenterDistanceUpdateHandler = (distance:number = 0) => {
            distance = Math.max(0,distance);
            let offsetY = distance * (window.innerHeight * 0.2);
            _.each(this._blockContents,(blockContent)=>{
                blockContent.style.transform = `translateY(${offsetY}px)`;
            });
        }

        this._block.onCenterDistanceUpdate.subscribe(this._onCenterDistanceUpdateHandler);

        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        this._block.onCenterDistanceUpdate.unsubscribe(this._onCenterDistanceUpdateHandler);
        this.state = ComponentState.DESTROYED;

    }
}