/**
 * ValueAnimation.ts: animation of primitive values.
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector3 } from "../../lib/threejs/math/Vector3";
import {EventNoArg} from "../core/Events";
import {math} from "../core/Math";
import {OnUpdate} from '../framework/Tick';

/** interpolation types */
export enum EValueInterpolation {
    Linear = 0,
    SmoothDamp = 1
}

/**
 * assuming number
 */
export class NumberAnimation {

    /** callback when value has reached target */
    public OnCompleted:EventNoArg = new EventNoArg();
    /** callback when animating */
    public OnUpdated:EventNoArg = new EventNoArg();

    /** get the smooth value */
    get smoothValue() : number {
        return this._currentValue;
    }

    /** get real value */
    get value() : number {
        return this._targetValue;
    }

    /** set a new value */
    set value(value:number) {
        this._targetValue = value;
        this._globalTime = 0.0;
    }

    /** DEPRECATED: api compatibility speed */
    public get speed() : number {
        return 1.0 / this._time;
    }
    public set speed(value:number) {
        if(value !== 0.0) {
            this._time = 1.0 / value;
        }
    }

    /** transition speed time */
    public get time() : number {
        return this._time;
    }
    public set time(value:number) {
        this._time = Math.max(0.00001, value);
    }

    //public speed:number = 1.0;
    public maxSpeed:number = Infinity;

    /** interpolation type */
    private _interpolation:EValueInterpolation;
    private _time:number;

    /** values */
    private _currentValue:number;
    private _targetValue:number;
    private _globalTime:number;

    /** smooth damping velocity */
    private _currentVelocity:number;

    constructor(interpolation:EValueInterpolation = EValueInterpolation.Linear, value:number = 0.0) {
        this._globalTime = 0.0;
        this._time = 1.0;
        this._interpolation = interpolation;

        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = 0.0;

        OnUpdate.on(this._update);
    }

    /** destroy */
    public destroy() {
        this.reset(this._targetValue);
        OnUpdate.off(this._update);

        this.OnCompleted.clearAll();
        this.OnUpdated.clearAll();
    }

    /** reset value */
    public reset(value:number) {
        this._globalTime = 0.0;
        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = 0.0;
    }

    // get ticked by 1.0/60.0
    private _update = () => {
        this._globalTime += 1.0/60.0;

        if(this._currentValue !== this._targetValue) {

            if(this._interpolation === EValueInterpolation.Linear) {

                //let speed = Math.min(1.0 / (this._time * 60.0), 1.0);
                //this._currentValue = speed * this._targetValue + (1.0 - speed) * this._currentValue;

                const lerp = Math.min(1.0, this._globalTime / this._time);
                this._currentValue = lerp * this._targetValue + (1.0 - lerp) * this._currentValue;

                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();
            } else {

                // smooth damp to value
                const value = math.SmoothDamp(this._currentValue, this._targetValue, this._currentVelocity, this._time, this.maxSpeed, 1.0/60.0);

                // apply values
                this._currentValue = value.value;
                this._currentVelocity = value.currentVelocity;
                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();
            }

            if(Math.abs(this._currentValue - this._targetValue) < 0.00001) {
                this._currentValue = this._targetValue;
                this.OnUpdated.trigger();
                this.OnCompleted.trigger();
            }

            //console.log(this._currentValue);
        }
    }
}

/**
 * assuming THREE.Vector3 values
 */
export class Vector3Animation {

    /** callback when value has reached target */
    public OnCompleted:EventNoArg = new EventNoArg();
    /** callback when animating */
    public OnUpdated:EventNoArg = new EventNoArg();

    /** get the smooth value */
    get smoothValue() : any {
        return this._currentValue;
    }

    /** get real value */
    get value() : any {
        return this._targetValue;
    }

    /** set a new value */
    set value(value:any) {
        this._targetValue = value;
        this._globalTime = 0.0;
    }

    /** DEPRECATED: api compatibility speed */
    public get speed() : number {
        return 1.0 / this._time;
    }
    public set speed(value:number) {
        if(value !== 0.0) {
            this._time = 1.0 / value;
        }
    }

    /** transition speed time */
    public get time() : number {
        return this._time;
    }
    public set time(value:number) {
        this._time = Math.max(0.00001, value);
    }

    public maxSpeed:number = Infinity;

    /** interpolation type */
    private _interpolation:EValueInterpolation;
    private _time:number;

    /** values */
    private _currentValue:any;
    private _targetValue:any;

    private _globalTime:number;

    /** smooth damping velocity */
    private _currentVelocity:any;

    constructor(interpolation:EValueInterpolation = EValueInterpolation.Linear, value:any = new Vector3()) {
        this._globalTime = 0.0;
        this._time = 1.0;
        this._interpolation = interpolation;

        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = new Vector3();

        OnUpdate.on(this._update);
    }

    /** destroy */
    public destroy() {
        this.reset(this._targetValue);
        OnUpdate.off(this._update);

        this.OnCompleted.clearAll();
        this.OnUpdated.clearAll();
    }

    /** reset value */
    public reset(value:any) {
        this._globalTime = 0.0;
        this._currentValue = value;
        this._targetValue = value;
        this._currentVelocity = 0.0;
    }

    /**
     * call this when you updated the value with
     * function calls
     */
    public valueUpdated() {
        this._globalTime = 0.0;
    }

    // get ticked by 1.0/60.0
    private _update = () => {
        this._globalTime += 1.0/60.0;

        if(!this._currentValue.equals(this._targetValue)) {

            if(this._interpolation === EValueInterpolation.Linear) {

                //let speed = Math.min(1.0 / (this._time * 60.0), 1.0);

                const lerp = Math.min(1.0, this._globalTime / this._time);

                this._currentValue = this._currentValue.lerp(this._targetValue, lerp);

                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();

            } else {

                // smooth damp to value
                const value = math.SmoothDampVector3(this._currentValue, this._targetValue, this._currentVelocity, this._time, this.maxSpeed, 1.0/60.0);

                // apply values
                this._currentValue = value;
                //this._currentVelocity = value.currentVelocity;

                //FIXME: trigger even when before finished??
                this.OnUpdated.trigger();
            }

            if(Math.abs(this._currentValue.length() - this._targetValue.length()) < 0.00001) {
                this._currentValue = this._targetValue;
                this.OnUpdated.trigger();
                this.OnCompleted.trigger();
            }
            //console.log(this._currentValue);
        }
    }

}
