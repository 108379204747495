/**
 * Uniforms.ts: Uniforms code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */

/**
 * uniform data type
 */
export enum EUniformType {
    TEXTURE = 1,
    TEXTURE_ARRAY = 2,
    FLOAT = 3,
    FLOAT_ARRAY = 4,
    INTEGER = 5,
    INTEGER_ARRAY = 6,
    COLOR = 7,
    COLOR_ARRAY = 8,
    VECTOR2 = 9,
    VECTOR2_ARRAY = 10,
    VECTOR3 = 11,
    VECTOR3_ARRAY = 12,
    VECTOR4 = 13,
    VECTOR4_ARRAY = 14,
    MATRIX2 = 15,
    MATRIX2_ARRAY = 16,
    MATRIX3 = 17,
    MATRIX3_ARRAY = 18,
    MATRIX4 = 19,
    MATRIX4_ARRAY = 20,
    STRUCT = 21
}

/** TODO: bullshit to three.js compatibility */
export interface Uniform {
    value:any;
    type?:EUniformType;
    properties?:any;
    needsUpdate?:boolean;
    default?:any;
}

/** uniform list */
export interface Uniforms {
    [key:string]:Uniform;
}

/** merge one or more uniforms */
export function mergeUniforms(uniforms:Uniforms[]) : Uniforms {
    const merged = {};
    for(let u = 0; u < uniforms.length; u++) {
        const tmp = cloneUniforms(uniforms[u]);

        for(const p in tmp) {
            merged[p] = tmp[p];
        }
    }
    return merged;
}

/** clone a variable (three.js objects too) */
export function cloneUniformValue(srcParameter:any) : any {
    let destParameter;

    if(srcParameter && (srcParameter.isColor ||
        srcParameter.isMatrix3 || srcParameter.isMatrix4 ||
        srcParameter.isVector2 || srcParameter.isVector3 || srcParameter.isVector4 ||
        srcParameter.isTexture) ) {

        // three.js object
        if(srcParameter.isTexture) {
            destParameter = srcParameter;
        } else {
            destParameter = srcParameter.clone();
        }

    } else if(Array.isArray(srcParameter)) {

        destParameter = [];

        //TODO: add support for array of array copy data
        for(const v of srcParameter) {

            if(v && (v.isColor ||
                v.isMatrix3 || v.isMatrix4 ||
                v.isVector2 || v.isVector3 || v.isVector4 ||
                v.isTexture)) {

                // three.js object
                if(v.isTexture) {
                    destParameter.push(v);
                } else {
                    destParameter.push(v.clone());
                }

            } else {

                // primitive data type
                destParameter.push(v);
            }
        }

        // does not copy references
        //uniforms_dst[u][p] = parameter_src.slice();

    } else {
        // primitive data type
        destParameter = srcParameter;
    }
    return destParameter;
}

/** clone uniform object */
export function cloneUniforms(srcUniforms:Uniforms) : Uniforms {
    const destUniforms = {};

    for(const u in srcUniforms) {
        // new object
        destUniforms[u] = {};

        // copy values
        for(const p in srcUniforms[u]) {
            const srcParameter = srcUniforms[u][p];

            destUniforms[u][p] = cloneUniformValue(srcParameter);
        }
    }

    return destUniforms;
}
