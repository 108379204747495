/**
 * AssetProcessor.ts: asset processing
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { TextureDB, MeshDB } from "./AssetInfo";

export type ProcessGenericCallback = (data:any, settings?:any) => any;
export type ProcessTextureCallback = (texture:any, settings?:TextureDB) => any;
export type ProcessModelCallback = (model:any, settings?:MeshDB) => any;

export enum EProcessType {
    Generic = 1,
    Model = 2,
    Texture = 3
}

/**
 * processing of assets
 */
export interface AssetProcessor {
    /** like mimetype */
    type:EProcessType;
    priority?:number;
    /** processors */
    processGeneric?:ProcessGenericCallback;
    processTexture?:ProcessTextureCallback;
    processModel?:ProcessModelCallback;
}

/**
 * processing assets
 */
export class AssetProcessing {

    // singleton
    private static _instance:AssetProcessing = null;
    public static get():AssetProcessing {
        if(!AssetProcessing._instance) {
            AssetProcessing._instance = new AssetProcessing();
        }
        return AssetProcessing._instance;
    }

    /** processors */
    private _processors:AssetProcessor[] = [];

    constructor() {

    }

    /** add processor to list */
    public addProcessor(processor:AssetProcessor) {
        this._processors.push(processor);
        this._processors.sort( (a, b) => {
            if(a.priority !== undefined) {
                if(b.priority !== undefined) {
                    return a.priority > b.priority ? -1 : 1;
                }
            } else if(b.priority !== undefined) {
                return +1;
            }
            // a
            return -1;
        });
    }

    /** remove processor from list */
    public removeProcessor(processor:AssetProcessor) {
        const idx = this._processors.indexOf(processor);
        if(idx !== -1) {
            this._processors.splice(idx, 1);
        }
    }

    public processTexture(texture:any, settings?:TextureDB) {
        // run through processors
        for(const processor of this._processors) {
            if(processor.processTexture) {
                texture = processor.processTexture(texture, settings);
            }
        }
        return texture;
    }

    public processAsset(data:any, type:EProcessType) {
        // run through processors
        for(const processor of this._processors) {
            if(processor.type === type && processor.processGeneric) {
                data = processor.processGeneric(data);
            }
        }
        return data;
    }
}
