/**
 * # Standard Shader
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * roughnessMap -- Roughness Texture (RGB)
 * * metalness -- Metal (0-1)
 * * metalnessMap -- Metal Texture
 * #### Emissive
 * * emissive -- Emissive Color (RGB)
 * * emissiveMap -- Emissive Texture (RGB)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Bump Mapping
 * * bumpMap -- Bump Texture (RGB)
 * * bumpScale -- Bump Scale (float)
 * #### Transparency
 * * alphaMap -- Alpha Texture
 * * alphaMult --
 * * opacity -- Opacity value (diffuse.A * opacity)
 * * alphaTest -- alpha testing value
 * #### Ambient Occlusion
 * * aoMap -- AO Texture (RGB)
 * * aoMapIntensity -- AO Intensity
 * #### Lightmapping
 * * lightMap -- Lightmap Texture (RGB)
 * * lightMapIntensity -- Lightmap Intensity
 * #### Environment Mapping
 * * envMap -- Environment Map (RGB)
 * * envMapIntensity -- Environment Intensity
 * * flipEnvMap -- flip environment map (y axis)
 * * reflectivity -- reflection value (0-1)
 * * refractionRatio -- refraction value (0-1)
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 */
import "../shader/shader_generated";
import * as THREE from "redTyped/three"
import { ShaderModule, ShaderBuilder, UniformLib, ShaderChunk } from "redTyped/render/ShaderBuilder";
import { mergeUniforms, EUniformType } from "redTyped/render/Uniforms";
import { ShaderLibrary } from "redTyped/render/ShaderLibrary";
import { ShaderVariant, applyShaderToRenderer, setValueShader, variantIsSet } from "redTyped/render/Shader";
import { Color, Vector4, Vector2 } from "redTyped/three";
import { whiteTexture, normalTexture, blackTexture } from "redTyped/render/Texture";
import { Render } from "redTyped/render/Render";
import { Mesh } from "redTyped/render/Mesh";
import { setValueShaderProbe } from "redTyped/render/LightProbe";
import { setValueShaderLights } from "redTyped/render/Lights";
import { ESpatialType, querySpatialSystem } from "redTyped/framework/SpatialAPI";


/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {


	shaderBuilder.createShaderFrom("redStandardUV", "redStandardAO", {
		variants: [ShaderVariant.DEFAULT, ShaderVariant.INSTANCED, ShaderVariant.IBL],
		uniforms: mergeUniforms( [
			UniformLib["redLights"],
			UniformLib["lights"],
			UniformLib["fog"],
			UniformLib["sh"],
			UniformLib["hdr"],
			UniformLib["pds"],
			UniformLib["probe"],
			{
				/** diffuse */
				baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc)},
				baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
				/** standard shader */
				roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
				metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
				occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
				reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
				/** ao map */
				aoMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
				aoMapIntensity : { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
				/** bump map */
				bumpMap : { type: EUniformType.TEXTURE, value: null, default: blackTexture() },
				bumpScale : { type: EUniformType.FLOAT, value: 1.0 },
				/** normal mapping */
				normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
				normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
				/** uv channel transform */
				offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
			}
		]),
		onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh, data:any):void {

			const shaderInterface = applyShaderToRenderer(renderer, material);

			// not applicable
			if(!shaderInterface) {
				return;
			}

			setValueShader(shaderInterface, "baseColor", material, data.baseColor);
			setValueShader(shaderInterface, "metalness", material, data.metalness);
			setValueShader(shaderInterface, "roughness", material, data.roughness);
			setValueShader(shaderInterface, "reflectance", material, data.reflectance);

			setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
			setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
			setValueShader(shaderInterface, "normalMap", material, data.normalMap);
			setValueShader(shaderInterface, "normalScale", material, data.normalScale);

			setValueShader(shaderInterface, "aoMap", material, data.aoMap);
			setValueShader(shaderInterface, "aoMapIntensity", material, data.aoMapIntensity || 1.0);

			setValueShader(shaderInterface, "bumpMap", material, data.bumpMap);
			setValueShader(shaderInterface, "bumpScale", material, data.bumpScale || 1.0);

			// request reflection probe
			const nearestProbe = querySpatialSystem().getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
			// apply probe
			setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

			// reset program (for globals)
			if(shaderInterface.initial) {
				// access global parameters
				setValueShaderLights(shaderInterface, material);
			}

			setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

			setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
			setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
		},
		evaluateDefines:(variant:ShaderVariant, mesh:any) => {
			const defines = {
				RED_STANDARD: 1
			};

			if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
				defines['USE_INSTANCING'] = 1;
			}

			// TESTING
			defines['USE_NORMALMAP'] = 1;
			defines['USE_BUMPMAP'] = 1;
			defines['RED_USE_UV2'] = 1;

			return defines;
		},
		fragmentShader: "redBackpack_Pixel"
	});

	shaderBuilder.createShaderFrom("redBackpackTransparent", "redTransparent", {
		redSettings: {
			lights: true,
			derivatives: true,
			shaderTextureLOD: true,
			isRawMaterial: true,
			premultipliedAlpha: false,
			blending: "additive"
		},
		fragmentShader: "redBackpackTransparent_Pixel"
	});

}, ["redCommon", "redFunctions", "redBSDFPhysical_Pixel", "redLightsPhysical_Pixel", "redShadowsPhysical_Pixel", "redStandard_VertexUV", "redStandard_PixelUV"] );
