export default {
    name: "silverstripe",
    title: "Silverstripe",
    purposes: ["essential"],
    required: true,
    translations: {
        en: {
            description: `
                Silverstript is the content management system for this website. The cookies store the current session as well as the desired display of the language version.
                <br><br>

                <table>
                    <thead>
                        <th>Cookie-Type</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Validity period</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>HTTP Cookie</td>
                        <td>PHPSESSID</td>
                        <td>.</td>
                        <td>Session</td>
                    </tr>
                    <tr>
                        <td>HTTP Cookie</td>
                        <td>FluentLocale</td>
                        <td>.</td>
                        <td>3 Months</td>
                    </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Use on legal basis of: Legitimate interest
                </p>
                <p class="purposes">
                    Provider: redPlant GmbH
                </p>
                <p class="purposes">
                    Privacy policy of the provider: {privacyPolicy}
                </p>
            `,
        },
        de: {
            description: `
                Silverstript ist das Content-Management-System für diese Website. Die Cookies speichern die aktuelle Sitzung sowie die gewünschte Anzeige der Sprachversion.
                <br><br>
                
                <table>
                    <thead>
                        <th>Cookie-Typ</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Gültigkeitsdauer</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>PHPSESSID</td>
                            <td>.</td>
                            <td>Sitzung / Session</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>FluentLocale</td>
                            <td>.</td>
                            <td>3 Monate</td>
                        </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Verwendung auf gesetzlicher Grundlage von: Berechtigtes Interesse
                </p>
                <p class="purposes">
                    Anbieter: redPlant GmbH
                </p>
                <p class="purposes">
                    Datenschutzerklärung des Anbieters: {privacyPolicy}
                </p>
            `,
        },
    },
    cookies: [
        "PHPSESSID",
        "FluentLocale",
    ],
};