/**
 * Prefilter.ts: probe prefiltering shader
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { EUniformType } from "../Uniforms";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    shaderBuilder.importCode(["redCommon", "redPrecision"]).then( () => {

        shaderBuilder.createShader("redEnvMapFilter", {
            redSettings: {
                lights: false,
                fog: false,
                depthTest: false,
                depthWrite: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms:
            {
                envMap: { type: EUniformType.TEXTURE, value: null },
                roughness: { type: EUniformType.FLOAT, value: 0.04 }
            },
            vertexShaderSource: `
                //@include "redPrecision"
                //attributes
                attribute vec3 position;
                attribute vec2 uv;
                // uniforms
                uniform mat4 modelMatrix;
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;
                // varyings
                varying vec2 vUv;
                varying vec3 direction;
                void main() {
                    vUv = uv;
                    vUv.y = 1.0 - vUv.y;

                    vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
                    direction = normalize(worldPosition.xyz);

                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,
            fragmentShaderSource: prefilterEquirectanuglar
        });

        shaderBuilder.createShader("redEnvMapFilterCube", {
            redSettings: {
                lights: false,
                fog: false,
                depthTest: false,
                depthWrite: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms:
            {
                envMap: { type: EUniformType.TEXTURE, value: null },
                roughness: { type: EUniformType.FLOAT, value: 0.04 }
            },
            vertexShaderSource: `
                //@include "redPrecision"
                //attributes
                attribute vec3 position;
                attribute vec2 uv;
                // uniforms
                uniform mat4 modelMatrix;
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;
                // varyings
                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    vUv.y = 1.0 - vUv.y;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,
            fragmentShaderSource: prefilterCubemap
        });

        shaderBuilder.createShader("redEnvMapCubeDebug", {
            redSettings: {
                lights: false,
                fog: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms: {
                envMap: { type: EUniformType.TEXTURE, value: null },
                mipLevel: { type: EUniformType.FLOAT, value: 0.0 }
            },
            vertexShaderSource: `
                //@include "redPrecision"
                //attributes
                attribute vec3 position;
                attribute vec2 uv;
                // uniforms
                uniform mat4 modelMatrix;
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;
                // varyings
                varying vec2 vUv;
                varying vec3 direction;

                void main() {
                    vUv = uv;
                    direction = normalize(position.xyz);
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }`,
            fragmentShaderSource: `
                //@include "redPrecision"

                #define PI 3.14159
                #define PI2 6.28318
                #define RECIPROCAL_PI 0.31830988618
                #define RECIPROCAL_PI2 0.15915494

                varying vec2 vUv;
                varying vec3 direction;

                uniform samplerCube envMap;
                uniform float mipLevel;

                vec3 SampleEnvironmentMap(vec3 direction) {
                #ifdef TEXTURE_LOD_EXT
                    // removes seams
                    vec4 texelColor = textureCubeLodEXT( envMap, direction, mipLevel );
                #else
                    vec4 texelColor = textureCube(envMap, direction);
                #endif
                    return texelColor.rgb;
                }

                void main() {
                    vec2 uv = (vUv * 2.0) - 1.0;
                    vec3 texel = SampleEnvironmentMap(normalize(direction));
                    gl_FragColor = vec4(texel.xyz, 1.0);
                }

            `
        });
    });
});

const prefilterEquirectanuglar = `
//@include "redPrecision"

#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494

varying vec2 vUv;
varying vec3 direction;

uniform sampler2D envMap;
uniform float roughness;

float rnd(vec2 uv) {
    return fract(sin(dot(uv, vec2(12.9898, 78.233) * 2.0)) * 43758.5453);
}

vec3 ImportanceSampleGGX(vec2 Xi, float Roughness, vec3 N)
{
	float a = Roughness * Roughness; // DISNEY'S ROUGHNESS [see Burley'12 siggraph]

	// Compute distribution direction
	float Phi = 2.0 * PI * Xi.x;
	float CosTheta = sqrt((1.0 - Xi.y) / (1.0 + (a*a - 1.0) * Xi.y));
	float SinTheta = sqrt(1.0 - CosTheta * CosTheta);

	// Convert to spherical direction
	vec3 H;
	H.x = SinTheta * cos(Phi);
	H.y = SinTheta * sin(Phi);
	H.z = CosTheta;

	vec3 UpVector = abs(N.z) < 0.999 ? vec3(0.0, 0.0, 1.0) : vec3(1.0, 0.0, 0.0);
	vec3 TangentX = normalize(cross(UpVector, N));
	vec3 TangentY = cross(N, TangentX);

	// Tangent to world space
	return TangentX * H.x + TangentY * H.y + N * H.z;
}

vec3 SampleEnvironmentMap(vec3 direction) {
    float flipNormal = 1.0;

    vec2 sampleUV;
    //sampleUV.y = saturate( flipNormal * direction.y * 0.5 + 0.5);
    //LH: removes more artifacts but slower
    sampleUV.y = acos(-direction.y) * RECIPROCAL_PI;
    sampleUV.x = atan( flipNormal * direction.z, flipNormal * direction.x ) * RECIPROCAL_PI2 + 0.5;

#ifdef TEXTURE_LOD_EXT
    // removes seams
    vec4 texelColor = texture2DLodEXT( envMap, sampleUV, 0.0 );
#else
    vec4 texelColor = texture2D(envMap, sampleUV);
#endif

    return texelColor.rgb;
}


float VanDerCorpus(int n, int base)
{
    float invBase = 1.0 / float(base);
    float denom   = 1.0;
    float result  = 0.0;

    for(int i = 0; i < 32; ++i)
    {
        if(n > 0)
        {
            denom   = mod(float(n), 2.0);
            result += denom * invBase;
            invBase = invBase / 2.0;
            n       = int(float(n) / 2.0);
        }
    }

    return result;
}
// ----------------------------------------------------------------------------
vec2 HammersleyNoBitOps(int i, int N)
{
    return vec2(float(i)/float(N), VanDerCorpus(i, 2));
}

vec3 PrefilterEnvMap( float Roughness, vec3 R )
{
    vec3 N = R;
    vec3 V = R;
    vec3 PrefilteredColor = vec3(0.0);
    float TotalWeight = 0.0;
    //const int NumSamples = 1024;
    const int NumSamples = NUM_SAMPLES;

    for( int i = 0; i < NumSamples; i++ )
    {
        //vec2 Xi = Hammersley( i, NumSamples );

        //
        //float sini = sin(float(i));
        //float cosi = cos(float(i));
        //float rand = rnd(vec2(sini, cosi));
        //vec2 Xi = vec2(float(i) / float(NumSamples), rand);

        vec2 Xi = HammersleyNoBitOps(i, NumSamples);


        vec3 H = ImportanceSampleGGX( Xi, Roughness, N );
        vec3 L = normalize(2.0 * dot( V, H ) * H - V);
        float NoL = clamp(dot(N, L), 0.0, 1.0);
        if( NoL > 0.0 )
        {
            PrefilteredColor += SampleEnvironmentMap(L).rgb * NoL;
            TotalWeight += NoL;
        }
    }
    return PrefilteredColor / TotalWeight;
}

void main() {

    // spherical coordinates
    vec2 uv = vUv * 2.0 - 1.0;
    float theta = uv.x * 3.14;
    float phi = uv.y * 3.14 * 0.5;

    // to direction
    vec3 dir;
    dir.x = cos(phi) * cos(theta);
    dir.y = sin(phi);
    dir.z = cos(phi) * sin(theta);

    //
    vec3 filtered = PrefilterEnvMap(roughness, dir);
    gl_FragColor = vec4(filtered, 1.0);
}
`;

const prefilterCubemap = `
//@include "redPrecision"

#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494

varying vec2 vUv;

uniform samplerCube envMap;
uniform float roughness;

#ifndef RED_FILTER_FACE_ID
#define RED_FILTER_FACE_ID 0
#endif

vec3 ImportanceSampleGGX(vec2 Xi, float Roughness, vec3 N) {
	float a = Roughness * Roughness; // DISNEY'S ROUGHNESS [see Burley'12 siggraph]

	// Compute distribution direction
	float Phi = 2.0 * PI * Xi.x;
	float CosTheta = sqrt((1.0 - Xi.y) / (1.0 + (a*a - 1.0) * Xi.y));
	float SinTheta = sqrt(1.0 - CosTheta * CosTheta);

	// Convert to spherical direction
	vec3 H;
	H.x = SinTheta * cos(Phi);
	H.y = SinTheta * sin(Phi);
	H.z = CosTheta;

	vec3 UpVector = abs(N.z) < 0.999 ? vec3(0.0, 0.0, 1.0) : vec3(1.0, 0.0, 0.0);
	vec3 TangentX = normalize(cross(UpVector, N));
	vec3 TangentY = cross(N, TangentX);

	// Tangent to world space
	return TangentX * H.x + TangentY * H.y + N * H.z;
}

vec3 SampleEnvironmentMap(vec3 direction) {
#ifdef TEXTURE_LOD_EXT
    // removes seams
    vec4 texelColor = textureCubeLodEXT( envMap, direction, 0.0 );
#else
    vec4 texelColor = textureCube(envMap, direction);
#endif

    return texelColor.rgb;
}

float VanDerCorpus(int n, int base)
{
    float invBase = 1.0 / float(base);
    float denom   = 1.0;
    float result  = 0.0;

    for(int i = 0; i < 32; ++i)
    {
        if(n > 0)
        {
            denom   = mod(float(n), 2.0);
            result += denom * invBase;
            invBase = invBase / 2.0;
            n       = int(float(n) / 2.0);
        }
    }

    return result;
}
// ----------------------------------------------------------------------------
vec2 HammersleyNoBitOps(int i, int N)
{
    return vec2(float(i)/float(N), VanDerCorpus(i, 2));
}

vec3 texelCoordToVec(const in vec2 uv) {
    mat3 faceUvVectors[6];

    // +x
    faceUvVectors[0][0] = vec3(0.0, 0.0, -1.0); // u -> -z
    faceUvVectors[0][1] = vec3(0.0, 1.0, 0.0); // v -> -y
    faceUvVectors[0][2] = vec3(1.0, 0.0, 0.0);  // +x face

    // -x
    faceUvVectors[1][0] = vec3(0.0, 0.0, 1.0);  // u -> +z
    faceUvVectors[1][1] = vec3(0.0, 1.0, 0.0); // v -> -y
    faceUvVectors[1][2] = vec3(-1.0, 0.0, 0.0); // -x face

    // +y
    faceUvVectors[2][0] = vec3(1.0, 0.0, 0.0);  // u -> +x
    faceUvVectors[2][1] = vec3(0.0, 0.0, -1.0); // v -> -z
    faceUvVectors[2][2] = vec3(0.0, 1.0, 0.0); // -y face

    // -y
    faceUvVectors[3][0] = vec3(1.0, 0.0, 0.0);  // u -> +x
    faceUvVectors[3][1] = vec3(0.0, 0.0, 1.0);  // v -> +z
    faceUvVectors[3][2] = vec3(0.0, -1.0, 0.0);  // +y face

    // +z
    faceUvVectors[4][0] = vec3(1.0, 0.0, 0.0);  // u -> +x
    faceUvVectors[4][1] = vec3(0.0, 1.0, 0.0); // v -> +y
    faceUvVectors[4][2] = vec3(0.0, 0.0, 1.0);  // +z face

    // -z
    faceUvVectors[5][0] = vec3(-1.0, 0.0, 0.0); // u -> -x
    faceUvVectors[5][1] = vec3(0.0, 1.0, 0.0); // v -> +y
    faceUvVectors[5][2] = vec3(0.0, 0.0, -1.0); // -z face

    // out = u * s_faceUv[0] + v * s_faceUv[1] + s_faceUv[2].
    vec3 result = (faceUvVectors[RED_FILTER_FACE_ID][0] * uv.x) + (faceUvVectors[RED_FILTER_FACE_ID][1] * uv.y) + faceUvVectors[RED_FILTER_FACE_ID][2];
    return normalize(result);
}

vec3 PrefilterEnvMap( float Roughness, vec3 R )
{
    vec3 N = R;
    vec3 V = R;
    vec3 PrefilteredColor = vec3(0.0);
    float TotalWeight = 0.0;
    //const int NumSamples = 1024;
    const int NumSamples = NUM_SAMPLES;

    for( int i = 0; i < NumSamples; i++ ) {
        vec2 Xi = HammersleyNoBitOps(i, NumSamples);
        vec3 H = ImportanceSampleGGX( Xi, Roughness, N );
        vec3 L = normalize(2.0 * dot( V, H ) * H - V);
        float NoL = clamp(dot(N, L), 0.0, 1.0);
        if( NoL > 0.0 ) {
            PrefilteredColor += SampleEnvironmentMap(L).rgb * NoL;
            TotalWeight += NoL;
        }
    }
    return PrefilteredColor / TotalWeight;
}

void main() {
    vec2 uv = (vUv * 2.0) - 1.0;
	vec3 dir = texelCoordToVec(uv);
    vec3 filtered = PrefilterEnvMap(roughness, dir);
    gl_FragColor = vec4(filtered, 1.0);
}
`;
