/**
 * LightAPI.ts: light API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Entity } from "./Entity";
import { Color } from "../../lib/threejs/math/Color";
import { Vector2 } from "../../lib/threejs/math/Vector2";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Texture as THREETexture } from "../../lib/threejs/textures/Texture";
import { WorldSystem } from "./WorldAPI";
import { ComponentId } from "./Component";
import { RedCamera } from "../render/Camera";
import { queryAPI, makeAPI } from "../plugin/Plugin";

/**
 * shadow and lighting
 */

/** red light types */
export enum ELightType {
    /** area */
    Sphere = 0,
    Tube,
    /** custom */
    IESLight,
    RedDirectional,
    /** builtin */
    Builtin_Directional,
    Builtin_Point,
    Builtin_Spot
}

export enum ShadowType {
    ESM = 1,
    VSM = 2,
    PCF = 3,
    PPCF = 4
}
export interface ILightComponent {
    castShadow: boolean;
    entity: Entity;
}

export interface IDirectionalLightComponent extends ILightComponent {
    colorIntensity: Color;
    shadowMapSize: Vector2;
    shadowBias:number;
    shadowType:ShadowType;
    shadowRadius: number;
    shadowMatrix: Matrix4;
    shadowMap: THREETexture;
}

export interface ITubeLightComponent extends ILightComponent {
    color:Vector3; // vector3
    distance:number;
    radius:number;
    height:number;
    axis:Vector3;
}
export interface ISphereLightComponent extends ILightComponent {
    /** color as THREE.Vector3 */
    color:Vector3;
    distance:number;
    radius:number;
}

export interface IIESLightComponent extends ILightComponent {
    /** color as THREE.Vector3 */
    color:Vector3;
    distance:number;
}

export type AnyLight = ILightComponent|IDirectionalLightComponent|ITubeLightComponent|ISphereLightComponent|IIESLightComponent;
export interface ILightSystem extends WorldSystem {

    registerLight(type:ELightType, light:AnyLight, node:Entity) : ComponentId;

    removeLight(id:ComponentId);

    /**
     * update light cache and upload to gpu
     * @param camera camera to update light cache for
     */
    updateLightCache(camera:RedCamera);
}
export const LIGHTSYSTEM_API = makeAPI("ILightSystem");

let _globalLightSystem:ILightSystem;
export function queryLightSystem() {
    if(_globalLightSystem === undefined) {
        _globalLightSystem = queryAPI<ILightSystem>(LIGHTSYSTEM_API);
    }
    return _globalLightSystem;
}
