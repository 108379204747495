///<reference path="../typings/PageConfig.d.ts"/>
///<reference path="js_bindings.d.ts"/>

export class Config  {
    public userId:string = "test";
    public page:string = "unknown";
    public media_breakpoints = {
        size_xs:0,
        size_s:640,
        size_m:960,
        size_l:1280,
        size_xl:2560,
    }

    private static _instance:Config = new Config();

    constructor() {
        if(Config._instance){
            throw new Error("Error: Instantiation failed: Use REDWeb.Config.getInstance() instead of new.");
        }

        _.extend(this, REDWeb.PageConfig || {});
        Config._instance = this;
        //console.log("REDWeb.Config",this);
    }

    public static getInstance():Config
    {
        return Config._instance;
    }
}



