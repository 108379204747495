/**
 * Background.ts: background shader
 *
 * Parameters:
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Modifications
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { CullFaceFront } from "../../../lib/threejs/constants";
import { Vector4 } from "../../../lib/threejs/math/Vector4";
import { Color } from "../../../lib/threejs/math/Color";
import { whiteTexture } from "../Texture";
import { ShaderBuilder, ShaderModule, UniformLib } from "../ShaderBuilder";
import { applyShaderToRenderer, setValueShader, ShaderVariant, variantIsSet } from "../Shader";
import { mergeUniforms, EUniformType } from "../Uniforms";
import { Mesh } from "../Mesh";
import { Render } from "../Render";
import { Line } from "../../render-line/Line";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    // first import code
    shaderBuilder.importCode(["redCommon", "redBackground_Vertex", "redBackground_Pixel"]).then( () => {

        //TODO: add transparency support
        shaderBuilder.createShader('redBackground', {
            redSettings: {
                shaderTextureLOD: true,
                lights: false,
                fog: false,
                depthTest: false,
                depthWrite: false,
                cullFace: CullFaceFront,
                isRawMaterial: true
            },
            uniforms: mergeUniforms( [
                UniformLib["hdr"],
                {
                    diffuse: { type: EUniformType.COLOR, value: new Color(0xffffff), default: new Color(0xffffff) },
                    map: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                    offsetRepeat: { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) }
                }
            ]),
            variants: [ShaderVariant.DEFAULT, ShaderVariant.EQUIRECT, ShaderVariant.CUBE, ShaderVariant.HDR],
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {
                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "diffuse", material, data.diffuse);
                setValueShader(shaderInterface, "map", material, data.map);

                // tonemapping support for backgrounds
                setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
                setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {};

                if(variantIsSet(ShaderVariant.EQUIRECT, variant)) {
                    defines['SAMPLER_2D'] = 1;
                    defines['ENVMAP'] = 1;
                } else if(variantIsSet(ShaderVariant.CUBE, variant)) {
                    defines['SAMPLER_CUBE'] = 1;
                    defines['ENVMAP'] = 1;
                }

                if(variantIsSet(ShaderVariant.HDR, variant)) {
                    defines['RGBM'] = 1;
                }

                // if(variant === "envmap_equirect") {
                //     defines['ENVMAP'] = 1;
                //     defines['SAMPLER_2D'] = 1;
                // } else if(variant === "envmap_equirect_rgbm") {
                //     defines['ENVMAP'] = 1;
                //     defines['RGBM'] = 1;
                //     defines['SAMPLER_2D'] = 1;
                // } else if(variant === "envmap_cube") {
                //     defines['ENVMAP'] = 1;
                //     defines['SAMPLER_CUBE'] = 1;
                // } else if(variant === "envmap_cube_rgbm") {
                //     defines['ENVMAP'] = 1;
                //     defines['RGBM'] = 1;
                //     defines['SAMPLER_CUBE'] = 1;
                // }

                return defines;
            },
            vertexShader: "redBackground_Vertex",
            fragmentShader: "redBackground_Pixel"

        });

    });

});
