/**
 * Shader.ts: Shader code
 * [[include:sourceDoc/Shader.md]]
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { CullFace, DepthModes, NeverDepth, AlwaysDepth, LessDepth, LessEqualDepth, EqualDepth, GreaterEqualDepth, GreaterDepth, NotEqualDepth } from "../../lib/threejs/constants";
import { ShaderMaterial } from "../../lib/threejs/materials/ShaderMaterial";
import { Uniforms, Uniform, EUniformType } from "./Uniforms";
import { RedMaterial } from "./Material";
import { ShaderLibrary } from "./ShaderLibrary";
import { Mesh } from "./Mesh";
import { Line } from "../render-line/Line";
import { Render } from "./Render";
import { build } from "../core/Build";
import { queryTextureSystem } from "../framework/TextureAPI";

/**
 * stencil operation
 */
export enum ShaderStencilOp {
    KEEP = 1,
    ZERO = 2,
    REPLACE = 3,
    INCR = 4,
    INCR_WRAP = 5,
    DECR = 6,
    DECR_WRAP = 7,
    INVERT = 8
}

/** shader settings */
export interface ShaderSettings {
    /** preinstantiate */
    instantiate?:boolean;

    /** render/compile order (higher more important) */
    order?:number;

    lights?:boolean;
    fog?:boolean;
    skinning?:boolean;
    derivatives?:boolean;

    /** wireframe rendering */
    wireframe?:boolean;
    /** use pixel shader */
    colorWrite?:boolean;

    //TODO: add stencil, depth etc.
    depthTest?: boolean;
    depthWrite?: boolean;

    /** culling */
    cullFace?: CullFace;

    /** blending */
    blending?: "none" | "additive" | "multiply" | "normal";
    /** premultiplied alpha is on by default */
    premultipliedAlpha?: boolean;

    /** alpha to coverage */
    alphaToCoverage?: boolean;

    /** polygon offset */
    polygonOffset?: boolean;
    polygonOffsetFactor?: number;
    polygonOffsetUnits?: number;

    /** stencil buffer operation */
    stencilTest?: boolean;
    stencilFunc?: {
        func: DepthModes;
        ref: number;
        mask: number;
    };
    stencilOp?: {
        fail: ShaderStencilOp;
        zfail: ShaderStencilOp;
        zpass: ShaderStencilOp;
    };

    /** custom variables */
    [key:string]:any;
}

/** shader description file */
export interface ShaderDesc {
    /** internal name */
    name:string;
    /** global settings (state mostly) */
    redSettings: ShaderSettings;
    /** builtin uniforms */
    builtins: string[];
    /** uniform table */
    uniforms: Uniforms;
    /** list of defines */
    defines: {};
    /** chunk reference */
    vertexShader: string;
    pixelShader: string;
    /** string files to load */
    files:string[];
}

export type ShaderSelectorCallback = (variant:ShaderVariant) => string | void;
export type ShaderSourceCallback = (variant:ShaderVariant) => string;
export interface ShaderSourceMap {[key:string]:string;}

/** shader layout definition */
export interface Shader {

    /**
     * defines callback
     * @return defines object e.g.( { USE_NORMALS: 1 })
     */
    evaluateDefines?:(variant:ShaderVariant, mesh:any) => {};

    /**
     * optional before compiler callback
     */
    onCompile?(shader:any):void;

    /**
     * optional pre rendering callback
     * use this to set uniforms or states
     * @param camera three.js camera
     * @param material three.js material
     * @param geometry red mesh or line
     */
    onPreRender?(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void;

    /**
     * optional post rendering callback
     * use this to reset states
     * @param camera three.js camera
     * @param material three.js material
     * @param geometry red mesh or line
     */
    onPostRender?(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void;

    /** optional settings */
    redSettings?:ShaderSettings;

    /** shader selection */
    selector?(variant:ShaderVariant) : string | void;

    /** uniform list */
    uniforms?:Uniforms;

    /** source code */
    vertexShader?:string|ShaderSourceMap;
    fragmentShader?:string|ShaderSourceMap;

    /** preloaded variants */
    variants?: ShaderVariant[];

    /** source code */
    vertexShaderSource?:string | ShaderSourceCallback;
    fragmentShaderSource?:string | ShaderSourceCallback;
}

/**
 * builtin variants
 */
export enum ShaderVariant {
    DEFAULT     = 0x00000000,
    INSTANCED   = 0x00000001,
    HDR         = 0x00000002,
    IBL         = 0x00000004,
    // sampler variants
    CUBE        = 0x00000008,
    EQUIRECT    = 0x00000010,
    // shadow variants
    ESM         = 0x00000020,
    VSM         = 0x00000040,
    PCF         = 0x00000080,
    PDS         = 0x00000100,
    // deferred variants
    SPECULAR_ROUGHNESS = 0x00000200,
    INDIRECT_SPECULAR  = 0x00000400,
    LIGHTING_DIFFUSE   = 0x00000800,

    // USER
    MAX         = 0xFFFFFFFF
}

export function variantIsSet(variant:ShaderVariant, variants:ShaderVariant) {
    return (variants & variant) === variant;
}

export function variantIsShadow(variant:ShaderVariant) {
    return variantIsSet(variant, ShaderVariant.ESM) ||
           variantIsSet(variant, ShaderVariant.VSM) ||
           variantIsSet(variant, ShaderVariant.PCF | ShaderVariant.PDS) ||
           variantIsSet(variant, ShaderVariant.PCF);
}

export interface ShaderVariantDef {
    /** global shader reference */
    runtimeShader: RedMaterial;
    /** variants */
    variant: ShaderVariant;
}

export interface ShaderVariantRef {
    /** global shader reference */
    shader: Shader;
    /** variants */
    variants: ShaderVariantDef[];
}

function shaderValueEquals(uniform:Uniform, value:any) {
    switch(uniform.type) {
        case EUniformType.COLOR:
            if(value.isColor) {
                return uniform.value.equals(value);
            } else {
                return uniform.value.r === value[0] && uniform.value.g === value[1] &&
                       uniform.value.b === value[2] && uniform.value.a === value[3];
            }
            break;
        case EUniformType.VECTOR2:
            if(value.isVector2) {
                return uniform.value.equals(value);
            } else {
                return uniform.value.x === value[0] && uniform.value.y === value[1];
            }
            break;
        case EUniformType.VECTOR3:
            if(value.isVector3) {
                return uniform.value.equals(value);
            } else {
                return uniform.value.x === value[0] && uniform.value.y === value[1] && uniform.value.z === value[2];
            }
            break;
        case EUniformType.VECTOR4:
            if(value.isVector4) {
                return uniform.value.equals(value);
            } else {
                return uniform.value.x === value[0] && uniform.value.y === value[1] && uniform.value.z === value[2] && uniform.value.w === value[3];
            }
            break;
        default: break;
    }
    return uniform.value === value;
}

/**
 * low level uniform update
 * @param shaderInterface
 * @param name
 * @param material
 * @param value
 */
export function setValueShader(shaderInterface:ShaderApplyInterface, name:string, material:ShaderMaterial, value:any) {

    // retrieve uniforms
    const uniform:Uniform = material.uniforms[name];

    // check for uniform
    if(!uniform) {
        return;
    }

    // resolve value (FIXME: test null only for textures?!)
    if(value === undefined || value === null) {
        value = uniform.default;
    }

    if(shaderValueEquals(uniform, value)) {
        const needsUpdate = shaderInterface.initial || (uniform.type === EUniformType.TEXTURE || uniform.type === EUniformType.TEXTURE_ARRAY);

        // updated internally
        uniform.needsUpdate = needsUpdate;

        //TODO: check three.js definition update
        material['uniformsNeedUpdate'] = material['uniformsNeedUpdate'] || needsUpdate;
        return;
    }

    // first apply to uniform table
    const dataType = uniform.type;

    if(dataType === EUniformType.TEXTURE || dataType === EUniformType.TEXTURE_ARRAY) {

        if(value && Array.isArray(value)) {
            uniform.value.length = value.length;
            for(let i = 0; i < value.length; ++i) {
                uniform.value[i] = value[i];
            }
        } else if(value && value.length > 0) {
            // override value for hwUniforms
            uniform.value = queryTextureSystem().resolveForShader(value, uniform.default);
        } else {
            uniform.value = value;
        }
    } else if(dataType === EUniformType.COLOR) {
        if(value && Array.isArray(value)) {
            //FIXME: alpha?!
            uniform.value.r = value[0];
            uniform.value.g = value[1];
            uniform.value.b = value[2];
        } else {
            // assuming three.color
            uniform.value.copy(value);
        }
    } else if(dataType === EUniformType.FLOAT) {
        uniform.value = value;
    } else if(dataType === EUniformType.INTEGER) {
        uniform.value = value;
    } else if(dataType === EUniformType.VECTOR2) {
        if(value.isVector2 === true) {
            uniform.value.copy(value);
        } else {
            uniform.value.x = value[0];
            uniform.value.y = value[1];
        }
    } else if(dataType === EUniformType.VECTOR2_ARRAY) {
        const ilen = uniform.value.length;
        const vlen = value.length;
        console.assert(ilen <= vlen, "input and values does not match");
        for(let k = 0; k < ilen; ++k) {
            if(value[k].isVector2 === true || (value[k].x !== undefined && value[k].y !== undefined)) {
                uniform.value[k].copy(value[k]);
            } else {
                uniform.value[k].x = value[k][0];
                uniform.value[k].y = value[k][1];
            }
        }
    } else if(dataType === EUniformType.VECTOR3) {
        if(value.isVector3 === true || (value.x !== undefined && value.y !== undefined && value.z !== undefined)) {
            uniform.value.copy(value);
        } else if(value.isColor || (value.r !== undefined && value.g !== undefined && value.b !== undefined)) {
            uniform.value.set(value.r, value.g, value.b);
        } else {
            uniform.value.x = value[0];
            uniform.value.y = value[1];
            uniform.value.z = value[2];
        }
    } else if(dataType === EUniformType.VECTOR3_ARRAY) {
        const ilen = uniform.value.length;
        const vlen = value.length;
        console.assert(ilen <= vlen, "input and values does not match");
        for(let k = 0; k < ilen; ++k) {
            if(value[k].isVector3 === true || (value[k].x !== undefined && value[k].y !== undefined && value[k].z !== undefined)) {
                uniform.value[k].copy(value[k]);
            } else {
                uniform.value[k].x = value[k][0];
                uniform.value[k].y = value[k][1];
                uniform.value[k].z = value[k][2];
            }
        }
    } else if(dataType === EUniformType.VECTOR4) {
        if(value.isVector4 === true || (value.x !== undefined && value.y !== undefined && value.z !== undefined)) {
            uniform.value.copy(value);
        } else if(value.isColor || (value.r !== undefined && value.g !== undefined && value.b !== undefined)) {
            uniform.value.set(value.r, value.g, value.b, value.a);
        } else {
            uniform.value.x = value[0];
            uniform.value.y = value[1];
            uniform.value.z = value[2];
            uniform.value.w = value[3];
        }
    } else if(dataType === EUniformType.VECTOR4_ARRAY) {
        const ilen = uniform.value.length;
        const vlen = value.length;
        console.assert(ilen <= vlen, "input and values does not match");
        for(let k = 0; k < ilen; ++k) {
            if(value[k].isVector4 === true || (value[k].x !== undefined && value[k].y !== undefined && value[k].z !== undefined)) {
                uniform.value[k].copy(value[k]);
            } else {
                uniform.value[k].x = value[k][0];
                uniform.value[k].y = value[k][1];
                uniform.value[k].z = value[k][2];
                uniform.value[k].w = value[k][3];
            }
        }
    } else if(dataType === EUniformType.MATRIX4 || dataType === EUniformType.MATRIX4_ARRAY) {
        if(value && Array.isArray(value)) {
            uniform.value.length = value.length;

            //FIXME: this is matrix reference
            for(let i = 0; i < value.length; ++i) {
                uniform.value[i] = value[i];
            }
        } else if(value.isMatrix4 === true) {
            uniform.value.copy(value);
        } else {
            uniform.value.set(value[0],value[1],value[2],value[3],
                                     value[4],value[5],value[6],value[7],
                                     value[8],value[9],value[10],value[11],
                                     value[12],value[13],value[14],value[15]);
        }
    } else if(dataType === EUniformType.MATRIX3) {
        if(value.isMatrix3 === true) {
            uniform.value.copy(value);
        } else {
            uniform.value.set(value[0],value[1],value[2],
                                     value[3],value[4],value[5],
                                     value[6],value[7],value[8]);
        }
    } else if(dataType === EUniformType.STRUCT) {
        //FIXME: just copy reference?!
        uniform.value = value;
    } else {
        console.log("setShaderValue: "+ name +" wrong data type " + dataType);
    }

    // updated internally
    uniform.needsUpdate = true;

    //TODO: check three.js definition update
    material['uniformsNeedUpdate'] = true;
}

/**
 * low level value shader set (using global parameters)
 * @param shaderInterface
 * @param name
 * @param material
 */
export function setValueShaderGlobal(shaderInterface:ShaderApplyInterface, name:string, material:ShaderMaterial) {
    const param = ShaderLibrary.getGlobalParameter(name);
    if(param === undefined) {
        console.warn("setValueShaderGlobal: cannot find global parameter: " + name);
        return;
    }
    return setValueShader(shaderInterface, name, material, param.value || param.default);
}

/** convert internal enum to GLenum */
export function stencilOpToGL(context:WebGLRenderingContext, op:ShaderStencilOp) : number {
    switch(op) {
        case ShaderStencilOp.KEEP:
            return context.KEEP;
        case ShaderStencilOp.ZERO:
            return context.ZERO;
        case ShaderStencilOp.REPLACE:
            return context.REPLACE;
        case ShaderStencilOp.INCR:
            return context.INCR;
        case ShaderStencilOp.INCR_WRAP:
            return context.INCR_WRAP;
        case ShaderStencilOp.DECR:
            return context.DECR;
        case ShaderStencilOp.DECR_WRAP:
            return context.DECR_WRAP;
        case ShaderStencilOp.INVERT:
            return context.INVERT;
    }
    return context.KEEP;
}

/** convert internal enum to GLenum */
export function stencilFuncToGL(gl:WebGLRenderingContext, func:DepthModes) {
    switch ( func ) {
        case NeverDepth:
            return gl.NEVER;
        case AlwaysDepth:
            return gl.ALWAYS;
        case LessDepth:
            return gl.LESS;
        case LessEqualDepth:
            return gl.LEQUAL;
        case EqualDepth:
            return gl.EQUAL;
        case GreaterEqualDepth:
            return gl.GEQUAL;
        case GreaterDepth:
            return gl.GREATER;
        case NotEqualDepth:
            return gl.NOTEQUAL;
        default:
            return gl.ALWAYS;
    }
}

/**
 * data interface when succesfully applied
 */
export interface ShaderApplyInterface {
    /** interface to shader running */
    shader :Shader;
    /** shader got applied for the first call */
    initial: boolean;
}

let lastShaderActive = null;
const activeShaderInterface:ShaderApplyInterface = { shader: undefined, initial: undefined };

/** current shader state */
export function clearShaderState() {
    lastShaderActive = null;
    activeShaderInterface.shader = undefined;
    activeShaderInterface.initial = undefined;
}

export function clearFixedFunctionState(render:Render) {
    render.setAlphaToCoverage(false);
}

function applyFixedFunctionToRenderer(render:Render, settings:ShaderSettings) {
    // alpha to coverage
    if(settings.alphaToCoverage === true) {
        render.setAlphaToCoverage(true);
    } else {
        render.setAlphaToCoverage(false);
    }
    // stencil buffer
    if(settings.stencilTest === true) {

        render.webGLRender.state.buffers.stencil.setTest(true);

        const stencilOpFail = stencilOpToGL(render.webGLRender.getContext(), settings.stencilOp.fail) || render.webGLRender.getContext().KEEP;
        const stencilOpZFail = stencilOpToGL(render.webGLRender.getContext(), settings.stencilOp.zfail) || render.webGLRender.getContext().KEEP;
        const stencilOpZPass = stencilOpToGL(render.webGLRender.getContext(), settings.stencilOp.zpass) || render.webGLRender.getContext().KEEP;

        const stencilFunc = stencilFuncToGL(render.webGLRender.getContext(), settings.stencilFunc.func) || render.webGLRender.getContext().ALWAYS;
        const stencilRef = settings.stencilFunc.ref;
        const stencilMask = settings.stencilFunc.mask;

        render.webGLRender.state.buffers.stencil.setMask(0xFF); //FIXME: make sure?!
        render.webGLRender.state.buffers.stencil.setOp(stencilOpFail, stencilOpZFail, stencilOpZPass);
        render.webGLRender.state.buffers.stencil.setFunc(stencilFunc, stencilRef, stencilMask);

    } else {
        render.webGLRender.state.buffers.stencil.setTest(false);
    }
}

/**
 * apply shader to three.js renderer (used for onBeforeRender and onAfterRender)
 * @param renderer THREE.js WebGLRenderer
 * @param material THREE.js shader reference
 */
export function applyShaderToRenderer(render:Render, material:any) : ShaderApplyInterface {
    const initial = lastShaderActive !== material;

    // always update
    lastShaderActive = material;

    //TODO: no activate apply interface
    if(material.needsUpdate) {
        if(build.Options.debugRenderOutput) {
            console.warn("applyShaderToRenderer: try to use shader " + material.name + " while not compiled. (slow down)");
        }
    }

    const shader:Shader = material['__redShader'];
    if(shader.redSettings) {
        applyFixedFunctionToRenderer(render, shader.redSettings);
    }

    // set flag
    activeShaderInterface.initial = initial;
    activeShaderInterface.shader = shader;

    return activeShaderInterface;
}
