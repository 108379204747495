/**
 * LightSystem.ts: IWorld Lighting code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Box3 } from "../../lib/threejs/math/Box3";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { RGBAFormat, UnsignedByteType, UVMapping, ClampToEdgeWrapping, LinearFilter, LinearEncoding } from "../../lib/threejs/constants";
import { DataTexture } from "../../lib/threejs/textures/DataTexture";
import { ELightType, AnyLight, ITubeLightComponent, ISphereLightComponent, IDirectionalLightComponent, ShadowType, ILightComponent, ILightSystem, LIGHTSYSTEM_API, IIESLightComponent } from "../framework/LightAPI";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { LightData, LightDataGlobal } from "../render/Lights";
import { RedCamera } from "../render/Camera";
import { ShaderLibrary } from "../render/ShaderLibrary";
import { Entity } from "../framework/Entity";
import { math } from "../core/Math";
import { registerAPI } from "../plugin/Plugin";
import { IWorld } from "../framework/WorldAPI";

/** base object */
interface LightObject {
    id: ComponentId;
    /** light type */
    type: ELightType;
    /** scene tree reference */
    node: Object3D;
    /** component reference */
    component: AnyLight;
}

/**
 * Handles Lights in a World
 */

/**  */
let _lightObjects:LightObject[] = [];
let _version:number = 1;

/** per frame light cache */
const _areaLightCache:LightData[] = [];
const _globalLightCache:LightDataGlobal = { count: 0, shadowCount: 0 };

/** ies light profile cache */
const MaxIESProfiles = 32;
const _ioesLightProfileCache:{[key:string]:number} = {};
const _iesLightProfileData:Uint8Array = new Uint8Array(180 * MaxIESProfiles * 3 * 4);
let _iesLightProfileSampler:DataTexture = null;

/** internal world reference */
let _world:IWorld;

function destroy() {
    // clear all callbacks
    _lightObjects = [];
    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function init(world:IWorld) {
    _world = world;
}

function registerLight(type:ELightType, light:AnyLight, node:Entity) : ComponentId {
    const id = _registerObjectGeneric();
    const index = componentIdGetIndex(id);

    _lightObjects[index].type = type;
    _lightObjects[index].node = node;
    _lightObjects[index].component = light;

    return id;
}

function removeLight(id:ComponentId) {
    if(!_validateId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    // cleanup
    _lightObjects[index].id = 0;
    _lightObjects[index].type = -1;
    _lightObjects[index].node = null;
    _lightObjects[index].component = null;

    // increase version
    _version = (_version + 1) & 0x000000FF;
}

/**
 * update light cache and upload to gpu
 * @param camera camera to update light cache for
 */
function updateLightCache(camera:RedCamera) {

    // using cache to save memory
    let areaLightsCount = 0;
    let globalLightsCount = 0;
    let globalShadowsCount = 0;

    //FIXME: force camera update?
    camera.updateMatrixWorld(false);

    //TODO: this only works for one camera: TODO...
    const viewMatrix = camera.matrixWorldInverse;
    const cameraExposure = camera.exposure || 1.0;

    // find lights and update at shader code
    for(const lightDef of _lightObjects) {
        const component = lightDef.component;
        const entity = lightDef.node as Entity;

        if(lightDef.type === ELightType.Tube) {
            const light = component as ITubeLightComponent;
            _areaLightCache[areaLightsCount] = {
                type: ELightType.Tube,
                data: {
                    position: entity.positionWorld.applyMatrix4(viewMatrix),
                    color: light.color,
                    lightAxis: light.axis.transformDirection(viewMatrix),
                    distance: light.distance,
                    decay: 1.0,
                    radius: light.radius,
                    size: light.height
                }
            };
            areaLightsCount++;
        } else if(lightDef.type === ELightType.Sphere) {
            const light = component as ISphereLightComponent;

            _areaLightCache[areaLightsCount] = {
                type: ELightType.Sphere,
                data: {
                    position: entity.positionWorld.applyMatrix4(viewMatrix),
                    color: light.color,
                    distance: light.distance || 100.0,
                    decay: 1.0,
                    radius: light.radius || 40.0
                }
            };
            areaLightsCount++;
        } else if(lightDef.type === ELightType.IESLight) {
            const light = component as IIESLightComponent;

            _areaLightCache[areaLightsCount] = {
                type: ELightType.IESLight,
                data: {
                    position: entity.positionWorld.applyMatrix4(viewMatrix),
                    color: light.color,
                    distance: light.distance,
                    decay: 1.0
                }
            };
            areaLightsCount++;

        } else if(lightDef.type === ELightType.RedDirectional) {
            const light = component as IDirectionalLightComponent;

            const direction = math.tmpVec3().set(0,0,1).applyMatrix4(component.entity.matrixWorld).transformDirection(viewMatrix);

            _areaLightCache[areaLightsCount] = {
                type: ELightType.RedDirectional,
                data: {
                    direction: direction.normalize(),
                    // pre-exposure to support older hardware with less precision
                    color: light.colorIntensity.clone().multiplyScalar(cameraExposure),
                    shadow: light.castShadow,
                    shadowBias: light.shadowBias,
                    //FIXME: put shadow radius setup into component?
                    shadowRadius: (light.shadowType === ShadowType.VSM || light.shadowType === ShadowType.ESM) ? (1.0 - light.shadowRadius / 8.0)  : light.shadowRadius,
                    shadowMapSize: light.shadowMapSize,
                    // shadow part
                    shadowMap: light.shadowMap,
                    shadowMatrix: light.shadowMatrix
                }
            };

            areaLightsCount++;

            if(light.castShadow) {
                globalShadowsCount++;
            }

        } else if(lightDef.type === ELightType.Builtin_Directional) {
            const light = component as ILightComponent;
            globalLightsCount++;
            if(light.castShadow) {
                globalShadowsCount++;
            }
        } else if(lightDef.type === ELightType.Builtin_Spot) {
            const light = component as ILightComponent;
            globalLightsCount++;
            if(light.castShadow) {
                globalShadowsCount++;
            }
        } else if(lightDef.type === ELightType.Builtin_Point) {
            const light = component as ILightComponent;
            globalLightsCount++;
            if(light.castShadow) {
                globalShadowsCount++;
            }
        }
    }

    // shrink cache
    _areaLightCache.length = areaLightsCount;

    // upload to gpu
    ShaderLibrary.updateLights(_areaLightCache);

    // update lights
    if(_globalLightCache.count !== globalLightsCount ||
        _globalLightCache.shadowCount !== globalShadowsCount) {

        _globalLightCache.count = globalLightsCount;
        _globalLightCache.shadowCount = globalShadowsCount;

        ShaderLibrary.updateBuiltinLights(_globalLightCache);
    }
}

/** create new collision object entry */
function _registerObjectGeneric(worldBounds?:Box3) : ComponentId {
    let index = -1;

    for(let i = 0; i < _lightObjects.length; ++i) {
        if(!_lightObjects[i].id) {
            index = i;
            break;
        }
    }

    // new entry
    if(index === -1) {
        index = _lightObjects.length;
        _lightObjects[index] = {
            id: 0,
            node: null,
            component: null,
            type: -1
        };
    }

    _lightObjects[index].id = createComponentId(index, _version);

    return _lightObjects[index].id;
}

/** valid component id */
function _validateId(id:ComponentId) {
    const index = componentIdGetIndex(id);
    if(index >= 0 && index < _lightObjects.length) {
        return _lightObjects[index].id === id;
    }
    return false;
}

/** create ies profile gpu data */
function _resizeIESProfile() {
    const defaultWidth = 180;
    const defaultHeight = 3 * MaxIESProfiles;

    if(!_iesLightProfileSampler) {
        _iesLightProfileSampler = new DataTexture(_iesLightProfileData, defaultWidth, defaultHeight, RGBAFormat, UnsignedByteType, UVMapping, ClampToEdgeWrapping, ClampToEdgeWrapping, LinearFilter, LinearFilter, 0, LinearEncoding);
    }
}

/** register new ies profile */
function _registerIESProfile(profile:string, data:Uint8Array) : number {
    if(_ioesLightProfileCache[profile] !== undefined) {
        return _ioesLightProfileCache[profile];
    }

    let lastIndex = -1;
    for(const p in _ioesLightProfileCache) {
        lastIndex = Math.max(lastIndex, _ioesLightProfileCache[p]);
    }

    if(lastIndex === -1) {
        lastIndex = 0;
    } else {
        lastIndex = lastIndex + 1;
    }

    // register
    _ioesLightProfileCache[profile] = lastIndex;

    // copy data into row
    for(let row = 0; row < 3; row++) {
        const destRow = 180 * ((lastIndex * 3) + row) * 4;

        for(let x = 0; x < 180; x++) {
            _iesLightProfileData[x * 4 + 0 + destRow] = data[x * 4 + 0];
            _iesLightProfileData[x * 4 + 1 + destRow] = data[x * 4 + 1];
            _iesLightProfileData[x * 4 + 2 + destRow] = data[x * 4 + 2];
            _iesLightProfileData[x * 4 + 3 + destRow] = data[x * 4 + 3];
        }
    }
    _iesLightProfileSampler.needsUpdate = true;

    return lastIndex;
}

const lightSystem:ILightSystem = {
    init: init,
    destroy: destroy,
    registerLight,
    removeLight,
    updateLightCache
};

registerAPI<ILightSystem>(LIGHTSYSTEM_API, lightSystem);
