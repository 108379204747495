/**
 * Component.ts: Entity Component code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import {GraphicsDisposeSetup, generateUUID} from "../core/Globals";
import {IONotifier} from "../io/Interfaces";
import {Entity} from "./Entity";
import {Render} from "../render/Render";
import { World } from "./World";
import { IWorld } from "./WorldAPI";
import { build } from "../core/Build";
import { WorldFileComponent } from "../framework-types/WorldFileFormat";
import { queryRenderSystem } from "./RenderAPI";
import { queryComponentUpdateSystem } from "./UpdateAPI";
import { RedCamera } from "../render/Camera";

export interface ComponentData {
    module:string;
    type:string;
    parameters:any;
}

/**
 * Base Component class
 */
export class Component {

    public get world() : IWorld {
        return World;
    }

    /** unique identifier */
    public get uuid() {
        return this._uuid;
    }

    /** three js node access */
    public get threeJSScene() : any {
        if(this.isValid) {
            return this._entityRef.world.scene;
        }
        return null;
    }

    public get isValid() : boolean {
        return this._entityRef != null;
    }

    public get entity() : Entity {
        return this._entityRef;
    }

    /** component needs think callback */
    public get needsThink() : boolean {
        if(this._updateId) {
            return queryComponentUpdateSystem().isActive(this._updateId);
        }
        return false;
    }
    public set needsThink(value:boolean) {
        if(value) {
            this._registerUpdate();
            queryComponentUpdateSystem().activate(this._updateId);
        } else {
            queryComponentUpdateSystem().deactivate(this._updateId);
        }
    }

    /** component needs render callback */
    public get needsRender() : boolean {
        if(this._renderId) {
            return queryRenderSystem().needsRender(this._renderId);
        }
        return false;
    }
    public set needsRender(value:boolean) {
        if(value) {
            this._registerRender();
            queryRenderSystem().activate(this._renderId);
        } else {
            queryRenderSystem().deactivate(this._renderId);
        }
    }

    /** internal valid state */
    protected get _isValid() : boolean {
        return !!this._uuid && !!this._entityRef;
    }

    /** internal uuid reference */
    private _uuid:string;
    /** entity reference */
    protected _entityRef:Entity;
    /** update system handle */
    private _updateId:ComponentId;
    /** render system handle */
    private _renderId:ComponentId;

    /** construct */
    constructor(entity?:Entity) {
        this._updateId = 0;
        this._renderId = 0;
        this._uuid = generateUUID();
        this._setEntityRef(entity);
    }

    /**
     * destroying
     * never call on your own use Entity.destroyComponent for this
     */
    public destroy(dispose?:GraphicsDisposeSetup) : void {
        if(this._updateId) {
            queryComponentUpdateSystem().removeCallback(this._updateId);
        }
        if(this._renderId) {
            queryRenderSystem().removeCallback(this._renderId);
        }
        this._updateId = 0;
        this._renderId = 0;
        // remove entity ref and uuid reference
        this._entityRef = null;
        this._uuid = null;
    }

    /** override for update feedback */
    public think() : void {
        console.assert(this.needsThink, "think() called but not wanted");
    }

    /** override for custom rendering */
    public preRender(render:Render, camera:RedCamera) : void {
        console.assert(this.needsRender, "preRender() called but not wanted");
    }

    /** override for custom rendering */
    public render(render:Render, camera:RedCamera) : void {
        console.assert(this.needsRender, "render() called but not wanted");
    }

    /** transformation change callback */
    public onTransformUpdate() {
    }

    /** callback when new owner */
    public reparent(entity:Entity, last:Entity) {
    }

    /** load component */
    public load(data:ComponentData, ioNotifier?:IONotifier, prefab?:any) {
    }

    /** save component */
    public save() : ComponentData {
        const node = {
            module: "RED",
            type: "Component",
            parameters: null
        };

        return node;
    }

    /**
     * used only be entity class to set entity reference
     * !!NEVER USE ON YOUR OWN!!
     */
    public _setEntityRef(entity:Entity) {
        const last = this._entityRef;
        // make sure component is not attached to any other entity
        if(last && last !== entity) {
            last.removeComponent(this);
        }
        this._entityRef = entity;

        if(this._entityRef) {
            this._entityRef.addComponent(this);
        }
        // reparent callback
        this.reparent(last, entity);
    }

    /**
     * handles update system registration
     */
    private _registerUpdate() {
        if(!this._updateId) {
            this._updateId = queryComponentUpdateSystem().registerCallback(
                this._componentThink);
        }
    }

    /**
     * handles render system registration
     *
     * @private
     * @memberof Component
     */
    private _registerRender() {
        if(!this._renderId) {
            this._renderId = queryRenderSystem().registerCallback(
                null,
                this._componentPreRender,
                this._componentRender);
        }
    }

    private _componentThink = () => {
        this.think();
    }
    private _componentPreRender = (render:Render, camera:RedCamera) => {
        this.preRender(render, camera);
    }
    private _componentRender = (render:Render, camera:RedCamera) => {
        this.render(render, camera);
    }
}

/**
 * Component Register definition
 */
export let ComponentRegister:{[key:string]:any} = {
};

//TODO: helper function for registering classes
export function registerComponent(mod:string, name:string, type:any) {
    ComponentRegister[mod+"."+name] = type;
}

export function getComponentTypeFromName(name: string, moduleName:string) {
    let type:any = null;
    if(moduleName) {
        if(window[moduleName] && window[moduleName][name]) {
            type = window[moduleName][name];
        }
    } else {
        type = window[name] ? window[name] : null;
    }

    if(!type) {
        if(ComponentRegister[moduleName+"."+name]) {
            type = ComponentRegister[moduleName+"."+name];
        } else if(ComponentRegister[name]) {
            type = ComponentRegister[name];
        }
    }
    return type;
}

// only works for Browser
export function constructComponent(name: string, moduleName:string, ...args: any[]) : Component {
    let prototype:any = null;
    if(moduleName) {
        if(window[moduleName] && window[moduleName][name]) {
            prototype = window[moduleName][name].prototype;
        }
    } else {
        prototype = window[name] ? window[name].prototype : null;
    }

    if(!prototype) {
        if(ComponentRegister[moduleName+"."+name]) {
            prototype = ComponentRegister[moduleName+"."+name].prototype;
        } else if(ComponentRegister[name]) {
            prototype = ComponentRegister[name].prototype;
        }
    }

    if(!prototype) {
        console.warn("World: failed to construct component: " + moduleName + " " + name);
        return null;
    }

    const instance:Component = Object.create(prototype);
    instance.constructor.apply(instance, args);
    return instance;
}

/**
 * preload component data
 * @param component file data
 */
export function preloadComponent(component:WorldFileComponent, preloadFiles:any[]) {
    if(!component.type) {
        console.warn("preloadComponent: failed to preload component");
        return;
    }
    const moduleName = component.module;
    const name = component.type;

    let type:any = null;
    if(moduleName) {
        if(window[moduleName] && window[moduleName][name]) {
            type = window[moduleName][name];
        }
    } else {
        type = window[name] ? window[name] : null;
    }

    if(!type) {
        type = ComponentRegister[moduleName+"."+name] || ComponentRegister[name];
    }

    if(!type) {
        console.warn("preloadComponent: failed to preload component: " + moduleName + " " + name);
        return;
    }

    if(type.Preload) {
        type.Preload(component, preloadFiles);
    } else if(build.Options.debugWorldOutput) {
        console.info("preloadComponent: component: " + moduleName + " " + name + " has no preload method");
    }
}

/**
 * Component Id definition
 */
export type ComponentId = number;

export function createComponentId(index:number, version:number) : ComponentId {
    return (version & 0x000000FF) << 24 | ((index + 1) & 0x00FFFFFF);
}

export function componentIdGetIndex(id:ComponentId) {
    return (id & 0x00FFFFFF) - 1;
}

export function componentIdGetVersion(id:ComponentId) {
    return (id >> 24) & 0x000000FF;
}
