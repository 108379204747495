///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class VideoComponent extends AbstractBlockComponent {

    private _loaded:boolean = false;
    private _autoPlaySupport:boolean = false;
    private _video:HTMLVideoElement;
    private _videoContainer:HTMLElement;
    private _videoControls:HTMLElement;
    private _videoPoster:any;
    private _btnPlay:any;

    get video():any{
        return this._video;
    }

    protected onInit():void{
        this._params = _.defaults(this._params, {
            appearBehavior : "resume"
        });

        this._video = this._block.domElement.querySelector("video");
        this._videoContainer = this._video.parentElement;
        this._videoControls = this._block.domElement.querySelector(".video_controls") as HTMLElement;
        this._videoPoster = this._block.domElement.querySelector(".video_poster") as HTMLElement;

        this.calcControlPosition();

        //if the device didn't support the autoplay attrib
        //a real user interaction is required to start the video playback
        this._btnPlay = this._block.domElement.querySelector(".video_play");
        if(this._btnPlay){
            this._btnPlay.addEventListener("click", this.deviceTrustedClickPlay);
        }
        this._video.addEventListener("click", this.toggleVideoOnClick);
        this._video.addEventListener("playing", this.autoPlaySupport);
        this._video.addEventListener("timeupdate", this.timeUpdate);

        let canPlayHandler = (event)=>{
            let playPromise = this._video.play();
            if(playPromise){
                if(playPromise){
                    playPromise.then(() => {
                        this._videoControls.style.display = 'none';
                    },
                    (exeption)=>{
                        this._videoControls.style.display = 'block';
                        this.calcControlPosition();
                        // console.log("play exeption",exeption);
                    });
                }
            }
            else{
                this._video.removeEventListener("canplay",canPlayHandler);
                // console.log("canplay",this._video);
                setTimeout(()=>{
                    if (!this._autoPlaySupport) {
                        //console.log("autoplay not supported");
                        this._videoControls.style.display = 'block';
                        this.calcControlPosition();
                    }
                },100);
            }
        };
        this._video.addEventListener("canplay", canPlayHandler);

        this.state = ComponentState.READY;
    }

    timeUpdate = () => {
        this._video.style.visibility = 'visible';
        if(this._videoPoster){
            this._videoPoster.style.display = 'none';
        }
    }



    autoPlaySupport = () => {
        this._autoPlaySupport = true;
    }

    toggleVideoOnClick = () => {
        let videoIsPlaying = this._videoControls.style.display === "none" ? true : false;

        if(videoIsPlaying){
            this._video.pause();
            this._videoControls.style.display = 'block';
            this.calcControlPosition();
        }else{
            this._video.play();
            this._videoControls.style.display = 'none';
        }

    }

    deviceTrustedClickPlay = () => {
        this._video.play();
        this._videoControls.style.display = 'none';
    }

    calcControlPosition = () => {
        // if the video is in portrait mode it is necessary to move the play controls over the video
        const containerWidth = this._videoContainer.getBoundingClientRect().width;
        const videoWidth = this._video.getBoundingClientRect().width;
        if(this._block.domElement.classList.contains('left')) {
            const controlsLeft = 100 - (((videoWidth / containerWidth) / 2)*100);
            this._videoControls.style.left = `${controlsLeft}%`;
        }
        else {
            const controlsLeft = ((videoWidth / containerWidth) / 2)*100;
            this._videoControls.style.left = `${controlsLeft}%`;
        }
    }

    protected onDestroy():void{

        this._btnPlay.removeEventListener("click", this.deviceTrustedClickPlay);
        this._video.removeEventListener("click", this.toggleVideoOnClick);
        this._video.removeEventListener("playing", this.autoPlaySupport);
        this._video.removeEventListener("timeupdate", this.timeUpdate);

        this.state = ComponentState.DESTROYED;
    }

    public appear():void{
        //console.log("VideoComponent OnAppear");

        if(!this._loaded){
            this._loaded = true;
            this._video.load();
        }

        if(this._params.appearBehavior === "restart"){
            this._video.currentTime = 0;
        }

        let playPromise = this._video.play();

        if(playPromise){
            playPromise.then(() => {
                this._videoControls.style.display = 'none';
            },
            (exeption)=>{
                this._videoControls.style.display = 'block';
                this.calcControlPosition();
                //console.log("play exeption",exeption);
            });
        }

    }

    public disappear():void{
        //console.log("VideoComponent OnDisappear");
        this._video.pause();
    }
}