export namespace Templates{
    export class Chat {
        public static ConnectQuery(id, page):string{
            return  `id=${encodeURI(id)}&page=${encodeURI(page)}`;
        }
        public static Entry(type,name,message):string{
            return  `<p class="${type}"><span class="name">${name}:</span>${message}</p>`;
        }
    }
}
