/**
 * # Standard Shader
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * roughnessMap -- Roughness Texture (RGB)
 * * metalness -- Metal (0-1)
 * * metalnessMap -- Metal Texture
 * #### Emissive
 * * emissive -- Emissive Color (RGB)
 * * emissiveMap -- Emissive Texture (RGB)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Bump Mapping
 * * bumpMap -- Bump Texture (RGB)
 * * bumpScale -- Bump Scale (float)
 * #### Transparency
 * * alphaMap -- Alpha Texture
 * * alphaMult --
 * * opacity -- Opacity value (diffuse.A * opacity)
 * * alphaTest -- alpha testing value
 * #### Ambient Occlusion
 * * aoMap -- AO Texture (RGB)
 * * aoMapIntensity -- AO Intensity
 * #### Lightmapping
 * * lightMap -- Lightmap Texture (RGB)
 * * lightMapIntensity -- Lightmap Intensity
 * #### Environment Mapping
 * * envMap -- Environment Map (RGB)
 * * envMapIntensity -- Environment Intensity
 * * flipEnvMap -- flip environment map (y axis)
 * * reflectivity -- reflection value (0-1)
 * * refractionRatio -- refraction value (0-1)
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 */
import "../shader/shader_generated";

import * as THREE from "redTyped/three";
import { UniformLib, ShaderBuilder, ShaderModule, ShaderChunk } from "redTyped/render/ShaderBuilder";
import { ShaderLibrary } from "redTyped/render/ShaderLibrary";
import { mergeUniforms, EUniformType } from "redTyped/render/Uniforms";
import { ShaderVariant, variantIsSet, applyShaderToRenderer, setValueShader } from "redTyped/render/Shader";
import { Color, Vector4 } from "redTyped/three";
import { Mesh } from "redTyped/render/Mesh";
import { Line } from "redTyped/render-line/Line";
import { Render } from "redTyped/render/Render";
import { whiteTexture } from "redTyped/render/Texture";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

	shaderBuilder.createShader("redUnlit_AOAlpha", {
		redSettings: {
			lights: false,
			isRawMaterial: true,
			order: 7
		},
		selector(variant:ShaderVariant) : string | void {
			// shadow rendering
			if(variantIsSet(ShaderVariant.VSM, variant)) {
				return "redVSMDepth";
			}
			if(variantIsSet(ShaderVariant.ESM, variant)) {
				return "redESMDepth";
			}
			if(variantIsSet(ShaderVariant.PCF, variant)) {
				return "redPCFDepth";
			}
		},
		// supported variants
		variants: [ShaderVariant.DEFAULT, ShaderVariant.IBL, ShaderVariant.LIGHTING_DIFFUSE],
		uniforms: mergeUniforms( [
			UniformLib["fog"],
		{
			baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc) },
			baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
			offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
			aoMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
		}
		]),
		onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

			const shaderInterface = applyShaderToRenderer(renderer, material);

			// not applicable
			if(!shaderInterface) {
				return;
			}

			setValueShader(shaderInterface, "baseColor", material, data.baseColor);
			setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

			setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
			setValueShader(shaderInterface, "aoMap", material, data.aoMap);
		},
		vertexShader: "redBackpackPlane_Vertex",
		fragmentShader: "redBackpackPlane_Pixel"
	});

	/**
	 * physical based shader with lighting and shadow support
	 * also supports transparency
     * TODO: work in progress
	 */
	ShaderLibrary.CustomShaderLib['redUnlit_AOAlpha_'] = {
		redSettings: {
			lights: true,
		},
		uniforms: mergeUniforms( [
            UniformLib["lights"],
            UniformLib["fog"],
			{
				/** diffuse */
				diffuse : { type: EUniformType.COLOR, value: new THREE.Color(0xcccccc)},
				map : { type: EUniformType.TEXTURE, value: null },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 1.0 },
                roughnessMap: { type: EUniformType.TEXTURE, value: null },
                /** metalness */
                metalness: { type: EUniformType.FLOAT, value: 0.0 },
                metalnessMap: { type: EUniformType.TEXTURE, value: null },
				/** emissive */
				emissive : { type: EUniformType.COLOR, value: new THREE.Color(0x000000)},
				emissiveMap : { type: EUniformType.TEXTURE, value: null },
				/** ao map */
				aoMap : { type: EUniformType.TEXTURE, value: null },
				aoMapIntensity : { type: EUniformType.FLOAT, value: 1.0 },
				/** light map */
				lightMap : { type: EUniformType.TEXTURE, value: null },
				lightMapIntensity : { type: EUniformType.FLOAT, value: 1.0 },
				/** normal mapping */
				normalMap : { type: EUniformType.TEXTURE, value: null },
				normalScale : { type: EUniformType.VECTOR2, value: new THREE.Vector2(1.0, 1.0) },
				/** bump mapping */
				bumpMap : { type: EUniformType.TEXTURE, value: null },
				bumpScale : { type: EUniformType.FLOAT, value: 1.0 },
				/** transparency */
				alphaMap : { type: EUniformType.TEXTURE, value: null },
				alphaMult : { type: EUniformType.FLOAT, value: 1.0 },
				opacity : { type: EUniformType.FLOAT, value: 1.0 },
				alphaTest : { type: EUniformType.FLOAT, value: 0.0 },
				/** environment mapping */
				envMap : { type: EUniformType.TEXTURE, value: null },
				envMapIntensity : { type: EUniformType.FLOAT, value: 1.0 }, // temporary
				flipEnvMap : { type: EUniformType.FLOAT, value: - 1 },
				reflectivity : { type: EUniformType.FLOAT, value: 1.0 },
				refractionRatio : { type: EUniformType.FLOAT, value: 0.98 },
                /** uv channel transform */
				offsetRepeat : { type: EUniformType.VECTOR4, value: new THREE.Vector4(0.0, 0.0, 1.0, 1.0) },
			}
		]),
        vertexShader: ShaderChunk["redUnlit_AOAlphaVertex"],
		fragmentShader: ShaderChunk["redUnlit_AOAlpha"]
	};

}, ["redCommon", "redFunctions", "redBSDFPhysical_Pixel", "redLightsPhysical_Pixel", "redShadowsPhysical_Pixel", "redUnlit_AOAlphaVertex", "redUnlit_AOAlpha"] );
