/**
 * FXAA.ts: fullscreen fxaa shader
 *
 *  * NVIDIA FXAA by Timothy Lottes
 * http://timothylottes.blogspot.com/2011/06/fxaa3-source-released.html
 * - WebGL port by @supereggbert
 * http://www.glge.org/demos/fxaa/
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector2 } from "../../../lib/threejs/math/Vector2";
import { ShaderLibrary } from '../ShaderLibrary';
import { EUniformType } from "../Uniforms";
// builtin shader code
import "./shader_generated";
import { ShaderModule, ShaderChunk } from "../ShaderBuilder";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function() {

    //TODO: add transparency support
    ShaderLibrary.CustomShaderLib['redFXAA'] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false
        },
        uniforms:
        {
            tDiffuse:   { type: EUniformType.TEXTURE, value: null },
            resolution: { type: EUniformType.VECTOR2, value: new Vector2( 1 / 512, 1 / 512 ) }
        },
        vertexShader: ShaderChunk["redFXAA_Vertex"],
        fragmentShader: ShaderChunk["redFXAA_Pixel"]

    };

}, ["redFXAA_Vertex", "redFXAA_Pixel"]);
