import translations from "./translation";

import silverstripeService from "./services/silverstripe";
import klaroService from "./services/klaro";
import youtubeService from "./services/youtube";
import vimeoService from "./services/vimeo";
import googleAnalyticsService from "./services/google-analytics";
import googleMapsService from "./services/google-maps";

export const klaroConfig = {
    version: 1,
    cookieExpiresAfterDays: 365,
    htmlTexts: true,
    acceptAll: true,
    translations,
    services: [
        //essential
        silverstripeService,
        klaroService,

        // functional
        youtubeService,
        vimeoService,
        googleMapsService,
        
        // analytics
        googleAnalyticsService,
    ],
};
