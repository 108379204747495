/**
 * LoadingManager.ts: loading manager
 *
 * Copyright redPlant GmbH 2016-2018
 */
import { AsyncLoad } from "./AsyncLoad";

export type GenericStartCallback = (url: string, itemsLoaded:number, itemsTotal:number) => void;
export type GenericProgressCallback = (url:string, loaded:number, total:number) => void;
export type GenericLoadCallback = (url: string) => void;
export type GenericErrorCallback = (url:string, err:any) => void;

export type GenericCompleteCallback = (complete:GenericLoadCallback) => void;

/**
 * THREE.JS influenced loading manager
 * Needed to be rewritten to support TypeScript compile
 */
export class LoadingManager {

    public onStart: GenericStartCallback = undefined;
    public onLoad: GenericLoadCallback;
    public onProgress: GenericProgressCallback;
    public onError: GenericErrorCallback;

    private isLoading: boolean;
    private itemsLoaded: number;
    private itemsTotal: number;

    constructor(onStart?: GenericStartCallback, onLoad?: GenericLoadCallback, onProgress?: GenericProgressCallback, onError?: GenericErrorCallback) {

        this.isLoading = false;
        this.itemsLoaded = 0;
        this.itemsTotal = 0;

        this.onStart = onStart;
        this.onLoad = onLoad;
        this.onProgress = onProgress;
        this.onError = onError;
    }

    /**
     * called when item starts loading
     * @param url address of item starting loading
     */
    public itemStart(url: string) {

        this.itemsTotal++;

        if(this.isLoading === false) {
            this.isLoading = true;
        }

        if(this.onStart !== undefined) {
            this.onStart(url, this.itemsLoaded, this.itemsTotal);
        }
    }

    /**
     * itemEnd is getting called for every item
     * @param url address of item finished loading (success or failure)
     */
    public itemEnd(url: string) {
        this.itemsLoaded++;

        if(!this.isLoading) {
            console.warn("LoadingManager: item ended without loading");
        }

        if(this.itemsLoaded === this.itemsTotal && this.isLoading) {
            this.isLoading = false;
        }

        // if(this.onProgress !== undefined) {
        //     this.onProgress(url, this.itemsLoaded, this.itemsTotal);
        // }

        // something got loaded (failed or success)
        if(this.onLoad !== undefined) {
            this.onLoad(url);
        }
    }

    /**
     * itemError gets called when item failed to load
     * this is getting called after itemEnd
     * @param url address where error got thrown
     */
    public itemError(url: string, err?:any) {

        // error callback
        if(this.onError !== undefined) {
            this.onError(url, err);
        }

        // check loading state
        if(!this.isLoading && this.itemsLoaded !== this.itemsTotal) {
            console.warn("LoadingManager: item error without loading");
            return;
        }
    }

    public itemProgress(url: string, loaded:number, total:number) {
        // error callback
        if(this.onProgress !== undefined) {
            this.onProgress(url, loaded, total);
        }
    }

    public resolveURL(url:string) {
        return url;
    }
}

/**
 * utility to get file size from web server
 * @param url url string
 */
export function getFileSize(url) {
    return new AsyncLoad<number>( (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("HEAD", url, true); // ONLY GET HEADER
        xhr.onreadystatechange = function() {
            if(this.readyState === this.DONE) {
                const contentLength = xhr.getResponseHeader("Content-Length");
                if(contentLength) {
                    const size = parseInt(contentLength, 10);
                    resolve(size);
                } else {
                    reject(new Error('File has no Content-Length'));
                }
            }
        };
        xhr.send();
    });
}
