/**
 * Bloom.ts: fullscreen bloom shader
 *
 *  * NVIDIA FXAA by Timothy Lottes
 * http://timothylottes.blogspot.com/2011/06/fxaa3-source-released.html
 * - WebGL port by @supereggbert
 * http://www.glge.org/demos/fxaa/
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector2 } from "../../../lib/threejs/math/Vector2";
import { ShaderLibrary } from '../ShaderLibrary';
import { EUniformType } from "../Uniforms";
import { ShaderModule } from "../ShaderBuilder";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function() {

    //
    ShaderLibrary.CustomShaderLib['redBlitThreshold'] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false
        },
        uniforms:
        {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            threshold: { type: EUniformType.FLOAT, value: 0.9 }
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,
        fragmentShader: `
            uniform float threshold;
            uniform sampler2D tDiffuse;
            varying vec2 vUv;

            void main() {

                vec4 texel = texture2D(tDiffuse, vUv);

                // threshold
                float brightness = dot(texel.rgb, vec3(0.2126, 0.7152, 0.0722));
                if(brightness < threshold) {
                    texel = vec4(0.0, 0.0, 0.0, 1.0);
                }

                gl_FragColor = texel;
            }
        `
    };

    // http://o3d.googlecode.com/svn/trunk/samples/convolution.html
    ShaderLibrary.CustomShaderLib['redConvolution'] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false
        },
        uniforms: {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            imageIncrement: { type: EUniformType.VECTOR2, value: new Vector2( 0.001953125, 0.0 ) },
            kernel: { type: EUniformType.FLOAT_ARRAY, value: [] }
        },
        vertexShader: `
            #define KERNEL_SIZE_FLOAT 25.0
            #define KERNEL_SIZE_INT 25

            uniform vec2 imageIncrement;
            varying vec2 vUv;

            void main() {
                vUv = uv - ( ( KERNEL_SIZE_FLOAT - 1.0 ) / 2.0 ) * imageIncrement;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,
        fragmentShader: `
            #define KERNEL_SIZE_FLOAT 25.0
            #define KERNEL_SIZE_INT 25

            uniform float kernel[KERNEL_SIZE_INT];

            uniform sampler2D tDiffuse;
            uniform vec2 imageIncrement;

            varying vec2 vUv;

            void main() {

                vec2 imageCoord = vUv;
                vec4 sum = vec4( 0.0, 0.0, 0.0, 1.0 );

                for(int i = 0; i < KERNEL_SIZE_INT; i++) {
                    sum += texture2D( tDiffuse, imageCoord ) * kernel[i];
                    imageCoord += imageIncrement;
                }

                gl_FragColor = sum;
            }
        `
    };

    // http://o3d.googlecode.com/svn/trunk/samples/convolution.html
    ShaderLibrary.CustomShaderLib['redConvolutionThreshold'] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false
        },
        uniforms: {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            imageIncrement: { type: EUniformType.VECTOR2, value: new Vector2( 0.001953125, 0.0 ) },
            kernel: { type: EUniformType.FLOAT_ARRAY, value: [] },
            threshold: { type: EUniformType.FLOAT, value: 0.98 }
        },
        vertexShader: `
            #define KERNEL_SIZE_FLOAT 25.0
            #define KERNEL_SIZE_INT 25

            uniform vec2 imageIncrement;
            varying vec2 vUv;

            void main() {
                vUv = uv - ( ( KERNEL_SIZE_FLOAT - 1.0 ) / 2.0 ) * imageIncrement;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,
        fragmentShader: `
            #define KERNEL_SIZE_FLOAT 25.0
            #define KERNEL_SIZE_INT 25

            uniform float kernel[KERNEL_SIZE_INT];

            uniform sampler2D tDiffuse;
            uniform vec2 imageIncrement;
            uniform float threshold;

            varying vec2 vUv;

            void main() {

                vec2 imageCoord = vUv;
                vec4 sum = vec4( 0.0, 0.0, 0.0, 1.0 );
                vec4 texel;

                for(int i = 0; i < KERNEL_SIZE_INT; i++) {

                    texel = texture2D( tDiffuse, imageCoord );

                    // threshold
                    float brightness = dot(texel.rgb, vec3(0.2126, 0.7152, 0.0722));
                    if(brightness < threshold) {
                        texel = vec4(0.0, 0.0, 0.0, 1.0);
                    }

                    sum += texel * kernel[i];
                    imageCoord += imageIncrement;
                }
                /*
                for(int i = 0; i < KERNEL_SIZE_INT; i++) {
                    sum += texture2D( tDiffuse, imageCoord ) * kernel[i];
                    imageCoord += imageIncrement;
                }

                // threshold
                float brightness = dot(sum.rgb, vec3(0.2126, 0.7152, 0.0722));
                if(brightness < threshold) {
                    sum = vec4(0.0, 0.0, 0.0, 1.0);
                }
                */
                gl_FragColor = sum;
            }
        `
    };

    //TODO: add transparency support
    ShaderLibrary.CustomShaderLib['redBlit'] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false
        },
        uniforms:
        {
            tDiffuse: { type: EUniformType.TEXTURE, value: null }
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,
        fragmentShader: `
            uniform sampler2D tDiffuse;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tDiffuse, vUv);
            }
        `
    };

    //TODO: add transparency support
    ShaderLibrary.CustomShaderLib['redBlend'] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false,
            blending: "additive"
        },
        uniforms: {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            opacity: { type: EUniformType.FLOAT, value: 0.5 }
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,
        fragmentShader: `
            uniform sampler2D tDiffuse;
            uniform float opacity;
            varying vec2 vUv;
            // FIXME: only rgb?!
            void main() {
                gl_FragColor = vec4(texture2D(tDiffuse, vUv).rgb * opacity, 1.0);
            }
        `

    };

});
