///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class CodeComponent extends AbstractBlockComponent {

    private _mobile:boolean;

    protected onInit():void{
        this._params = _.defaults(this._params, {});
        this.state = ComponentState.READY;
        hljs.highlightBlock(this._block.domElement.querySelector("pre code"))
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }
}