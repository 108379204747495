///<reference path="../js_bindings.d.ts"/>

import {Sidebar} from './Sidebar';

export class Handle{

    private _svgIcon:Snap.Paper;
    private _sidebar:Sidebar;
    private _handleIcon:any;
    private _isOpen:boolean = false;

    constructor(sidebar:Sidebar){
        this._sidebar = sidebar;
        this._handleIcon = document.querySelector('.sidebar_icon');

        this._svgIcon = Snap("#sidebar_x5F_icon");
        let bar_top = this._svgIcon.select('#bar_x5F_top');
        let bar_middle = this._svgIcon.select('#bar_x5F_middle');
        let bar_bottom = this._svgIcon.select('#bar_x5F_bottom');
        let cross_1 = this._svgIcon.select('#cross_x5F_1');
        let cross_2 = this._svgIcon.select('#cross_x5F_2');

        this._handleIcon.addEventListener('click',(event)=>{
            this._sidebar.toggle();
        });

        this._sidebar.onOpenStateChange.subscribe((open: boolean) => {
            if(open){
                this._handleIcon.classList.add('opened');
                bar_top.stop().animate({transform: 's0,1'}, 100, mina.easeinout);
                bar_middle.stop().animate({transform: 's0,1'}, 200, mina.easeinout);
                bar_bottom.stop().animate({transform: 's0,1'}, 300, mina.easeinout);
                cross_1.stop().animate({transform: 's0.75,0.75', fill:'#333631'}, 150, mina.easeinout);
                cross_2.stop().animate({transform: 's0.75,0.75', fill:'#333631'}, 300, mina.easeinout);
            }
            else{
                this._handleIcon.classList.remove('opened');
                bar_top.stop().animate({transform: 's1,1'}, 100, mina.easeinout);
                bar_middle.stop().animate({transform: 's1,1'}, 200, mina.easeinout);
                bar_bottom.stop().animate({transform: 's1,1'}, 300, mina.easeinout);
                cross_1.stop().animate({transform: 's0,0',fill:'#FFFFFF'}, 100, mina.easeinout);
                cross_2.stop().animate({transform: 's0,0',fill:'#FFFFFF'}, 300, mina.easeinout);
            }
        });
    }
}