/**
 * Prefabs.ts: world prefabs handling
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import {AsyncLoad} from '../io/AsyncLoad';
import {AssetManager} from '../framework/AssetManager';
import { Entity } from '../framework/Entity';
import { IONotifier } from '../io/Interfaces';
import { WorldFileNode } from '../framework-types/WorldFileFormat';
import { IPrefabSystem, PREFABMANAGER_API } from '../framework/PrefabAPI';
import { registerAPI } from '../plugin/Plugin';
import { PrefabFile } from '../framework-types/PrefabFileFormat';

/** single registered prefab */
interface PrefabObject {
    prefabDef: WorldFileNode;
    preloaded: boolean;
    fileReference: string;
}

/** loaded prefabs data */
const _prefabs:{[key:string]:PrefabObject} = {};

/**
 * load prefab file
 * @returns list of prefab id's loaded
 */
function load(file:string, doPreload?:boolean) : AsyncLoad<string[]> {
    return new AsyncLoad<string[]>( (resolve, reject) => {

        // dynamic load scene
        AssetManager.loadText(file).then((data) => {
            if(data) {
                //TODO: merge files
                const obj = JSON.parse(data) as PrefabFile;

                // check header
                if(!obj.__metadata__ ||
                    obj.__metadata__.version !== 1000 ||
                    obj.__metadata__.format !== "prefab") {
                    console.warn("PrefabManager: invalid prefab data, unknown version", obj);
                    reject(new Error("PrefabManager: invalid prefab data, unknown version"));
                    return;
                }

                // start preloading
                const ids = _processPrefabs(obj, file);

                resolve(ids);
            } else {
                reject(new Error("empty prefabs file"));
            }
        },reject);
    });
}

/**
 * create prefab
 * @param name
 * @param prefab
 * @param preload
 */
function createPrefab(name:string, prefab:WorldFileNode, doPreload?:boolean) {
    name = name || prefab.id;

    if(_prefabs[name] !== undefined) {
        _prefabs[name].prefabDef = prefab;
        _prefabs[name].preloaded = false;
    } else {
        _prefabs[name] = { prefabDef: prefab, preloaded: false, fileReference: "" };
    }

    if(doPreload) {
        preload(name);
    }
}

/**
 * has prefab with name
 */
function hasPrefab(name:string) : boolean {

    if(_prefabs[name] !== undefined) {
        return true;
    }
    return false;
}

/**
 * get prefab with name
 */
function getPrefab(name:string) : WorldFileNode {

    if(_prefabs[name]) {
        return _prefabs[name].prefabDef;
    }
    return null;
}

/**
 * preload every prefab that is in the list
 *
 * @param {IONotifier} [ioNotifier]
 * @returns
 * @memberof PrefabManager
 */
function preloadAll(ioNotifier?:IONotifier) {
    const loaded = [];

    if(ioNotifier) {
        ioNotifier.startLoading();
    }

    for(const key in _prefabs) {
        if(!_prefabs[key].preloaded) {
            loaded.push(preload(key, undefined, ioNotifier));
        }
    }

    if(loaded.length === 0) {
        if(ioNotifier) {
            ioNotifier.finishLoading();
        }
        return AsyncLoad.resolve<void>();
    } else {
        return AsyncLoad.all<void>(loaded).then( () => {
            if(ioNotifier) {
                ioNotifier.finishLoading();
            }
            return AsyncLoad.resolve<void>();
        },
        (err) => {
            console.error(err);
            if(ioNotifier) {
                ioNotifier.finishLoading();
            }
        });
    }
}

/**
 *
 *
 * @param {string} name
 * @param {any[]} [preloadFiles]
 * @param {IONotifier} [ioNotifier]
 * @returns
 * @memberof PrefabManager
 */
function preload(name:string, preloadFiles?:any[], ioNotifier?:IONotifier) {
    preloadFiles = preloadFiles || [];

    if(_prefabs[name]) {
        if(ioNotifier) {
            ioNotifier.startLoading();
        }

        try {
            // first process all preload data
            _preload(_prefabs[name].prefabDef, preloadFiles);

            // wait for all files loaded
            return new AsyncLoad<void>( (resolve, reject) => {
                AsyncLoad.all<void>(preloadFiles).then(() => {
                    // mark preloaded
                    _prefabs[name].preloaded = true;

                    if(ioNotifier) {
                        ioNotifier.finishLoading();
                    }

                    resolve();
                }, (err) => {

                    if(ioNotifier) {
                        ioNotifier.finishLoading();
                    }

                    reject(err);
                });
            });
        } catch(err) {
            console.error(err);

            if(ioNotifier) {
                ioNotifier.finishLoading();
            }
        }

        return AsyncLoad.resolve<void>();
    }
    return AsyncLoad.reject<void>(new Error("Prefab: prefab with name '" + name + "' not found"));
}

/**
 *
 *
 * @private
 * @param {*} obj
 * @memberof PrefabManager
 */
function _processPrefabs(obj:PrefabFile, fileReference:string) {
    const prefabs:string[] = [];

    if(obj.prefab) {
        const id = obj.prefab.id || fileReference;
        _prefabs[id] = { prefabDef: obj.prefab, preloaded: false, fileReference };
        prefabs.push(id);
    }

    if(obj.prefabs) {
        for(let i = 0; i < obj.prefabs.length; ++i) {
            _prefabs[obj.prefabs[i].id] = { prefabDef: obj.prefabs[i], preloaded: false, fileReference };
            prefabs.push(obj.prefabs[i].id);
        }
    }

    return prefabs;
}

/**
 *
 *
 * @private
 * @param {WorldFileNode} prefab
 * @param {any[]} preloadFiles
 * @returns
 * @memberof PrefabManager
 */
function _preload(prefab:WorldFileNode, preloadFiles:any[]) {
    try {
        Entity.Preload(prefab, preloadFiles);
        return true;
    } catch(err) {
        return false;
    }
}

/**
 * has prefab with name
 */
function getFileReference(name:string) : string {
    if(_prefabs[name] !== undefined) {
        return _prefabs[name].fileReference || name;
    }
    return "";
}

export const PrefabLibrary:IPrefabSystem = {
    createPrefab,
    getPrefab,
    hasPrefab,
    load,
    preload,
    preloadAll,
    getFileReference
};
registerAPI(PREFABMANAGER_API, PrefabLibrary);
