
export interface PixelData {
    data: ArrayBuffer;
    cube: ArrayBuffer[];
    type: 0|1; // 0 = uint8, 1 = float
    width: number;
    height: number;
    components: number;
    multiplier: number;
}

//! SH calculuations
export function SHWorker(input: PixelData, callback: (_: any) => void) {

    type vec3 = [number, number, number] | number[];

    // assumes 8 bit pixels
    function getPixel(pixels:PixelData, x:number, y:number, index: number) : number {
        return pixels.data[(x * pixels.components) + (y * pixels.components * pixels.width) + index];
    }

    // assumes 8 bit pixels
    function getPixel8(pixels:PixelData, data:Uint8Array, x:number, y:number, index: number) : number {
        return data[(x * pixels.components) + (y * pixels.components * pixels.width) + index];
    }

    // assumes 8 bit pixels
    function getPixel32(pixels:PixelData, data:Float32Array, x:number, y:number, index: number) : number {
        return data[(x * pixels.components) + (y * pixels.components * pixels.width) + index];
    }

    function getPixel_Cube(pixels:PixelData, cube:number, x:number, y:number, index: number) : number {
        return pixels.cube[cube][(x * pixels.components) + (y * pixels.components * pixels.width) + index];
    }

    /**
     * Scales a vec3 by a scalar number
     *
     * @param {vec3} out the receiving vector
     * @param {vec3} a the vector to scale
     * @param {Number} b amount to scale the vector by
     * @returns {vec3} out
     */
    function vec3_scale(out:vec3, a:vec3, b:number) : vec3 {
        out[0] = a[0] * b;
        out[1] = a[1] * b;
        out[2] = a[2] * b;
        return out;
    }

    /**
     * Adds two vec3's
     *
     * @param {vec3} out the receiving vector
     * @param {vec3} a the first operand
     * @param {vec3} b the second operand
     * @returns {vec3} out
     */
    function vec3_add(out:vec3, a:vec3, b:vec3) : vec3 {
        out[0] = a[0] + b[0];
        out[1] = a[1] + b[1];
        out[2] = a[2] + b[2];
        return out;
    }

    /**
     * Normalize a vec3
     *
     * @param {vec3} out the receiving vector
     * @param {vec3} a vector to normalize
     * @returns {vec3} out
     */
    function vec3_normalize(out:vec3, a:vec3) : vec3 {
        const x = a[0]; const y = a[1]; const z = a[2];
        let len = x*x + y*y + z*z;
        if (len > 0) {
            //TODO: evaluate use of glm_invsqrt here?
            len = 1 / Math.sqrt(len);
            out[0] = a[0] * len;
            out[1] = a[1] * len;
            out[2] = a[2] * len;
        }
        return out;
    }

    function texelSolidAngle(aU:number, aV:number, width:number, height:number) : number {
        // transform from [0..res - 1] to [- (1 - 1 / res) .. (1 - 1 / res)]
        // ( 0.5 is for texel center addressing)
        const U = (2.0 * (aU + 0.5) / width) - 1.0;
        const V = (2.0 * (aV + 0.5) / height) - 1.0;

        // shift from a demi texel, mean 1.0 / size  with U and V in [-1..1]
        const invResolutionW = 1.0 / width;
        const invResolutionH = 1.0 / height;

        // U and V are the -1..1 texture coordinate on the current face.
        // get projected area for this texel
        const x0 = U - invResolutionW;
        const y0 = V - invResolutionH;
        const x1 = U + invResolutionW;
        const y1 = V + invResolutionH;
        const angle = areaElement(x0, y0) - areaElement(x0, y1) - areaElement(x1, y0) + areaElement(x1, y1);

        return angle;
    }

    function areaElement(x:number, y:number) {
        return Math.atan2(x * y, Math.sqrt(x * x + y * y + 1.0));
    }

    // three.js (x) = 3ds max (x)
    // three.js (y) = 3ds max (z)
    // three.js (z) = 3ds max (y)
    function dominantAxes(normal:vec3) {
        if(Math.abs(normal[0]) >= Math.abs(normal[1]) && Math.abs(normal[0]) >= Math.abs(normal[2])) {
            // XAXIS
            return normal[0] > 0.0 ? 0 : 1;
        } else if(Math.abs(normal[1]) >= Math.abs(normal[2])) {
            // YAXIS (inverted for three.js)
            return normal[2] > 0.0 ? 2 : 3;
        } else {
            // ZAXIS
            //return vec2(pos.y, pos.x);
            return normal[2] > 0.0 ? 4 : 5;
        }
    }

    // Sample Environment Map
    function SampleEnvironmentMap_Equirect(pixels:PixelData, pixelView:Uint8Array, dir:vec3) {
        // THREE.js way
        const flipNormal = 1.0;
        const RECIPROCAL_PI2 = 0.15915494;

        let u = Math.atan2( flipNormal * dir[2], flipNormal * dir[0] ) * RECIPROCAL_PI2 + 0.5;
        let v = Math.min( 1.0, Math.max( 0.0, flipNormal * dir[1] * 0.5 + 0.5 ));

        u = Math.min(1.0, Math.max(0.0, u));
        //FIXME: flip y coordinates?
        v = 1.0 - Math.min(1.0, Math.max(0.0, v));

        const x = Math.floor(u * pixels.width);
        const y = Math.floor(v * pixels.height);

        // need all RGB values for now...
        console.assert(pixels.components>=3);

        const result = new Float32Array(3);

        result[0] = getPixel8(pixels, pixelView, x, y, 0) / 255.0;
        result[1] = getPixel8(pixels, pixelView, x, y, 1) / 255.0;
        result[2] = getPixel8(pixels, pixelView, x, y, 2) / 255.0;

        return result;
    }

    function _generateSH(pixels:PixelData) {
        const cubemapFaceNormals = [
            [ [0, 0, -1], [0, -1, 0], [1, 0, 0] ],  // posx
            [ [0, 0, 1], [0, -1, 0], [-1, 0, 0] ],  // negx

            [ [1, 0, 0], [0, 0, 1], [0, 1, 0] ],    // posy
            [ [1, 0, 0], [0, 0, -1], [0, -1, 0] ],  // negy

            [ [1, 0, 0], [0, -1, 0], [0, 0, 1] ],   // posz
            [ [-1, 0, 0], [0, -1, 0], [0, 0, -1] ]  // negz
        ];

        // generate shperical harmonics
        const sh = [
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3)
        ];
        let weightAccum = 0;

        // sh maps to 128 cubemap sizes?
        const size = 128;

        const coeffs = new Float32Array(9);

        const pixelView = new Uint8Array(pixels.data);

        for(let i = 0; i < 6; ++i) {
            const index = i;

            for (let y = 0; y < size; y++) {
                for (let x = 0; x < size; x++) {

                    const fU = (2.0 * x / (size - 1.0)) - 1.0;
                    const fV = (2.0 * y / (size - 1.0)) - 1.0;

                    const vecX = [0,0,0];
                    vec3_scale(vecX, cubemapFaceNormals[index][0], fU);
                    const vecY = [0,0,0];
                    vec3_scale(vecY, cubemapFaceNormals[index][1], fV);
                    const vecZ = cubemapFaceNormals[index][2];

                    const direction = [];
                    vec3_add(direction, vecX, vecY);
                    vec3_add(direction, direction, vecZ);
                    vec3_normalize(direction, direction);

                    // sample color
                    const color = SampleEnvironmentMap_Equirect(pixels, pixelView, direction);

                    const weight = texelSolidAngle(x, y, size, size);

                    // create basic coefficients for direction
                    SHNewEval3_(direction, coeffs);

                    // apply cosine lobe
                    CosineConvolve(coeffs);

                    // RGB
                    for(let c = 0; c < 3; c++) {
                        const value = color[c];

                        //if (gammaCorrect) value = Math.pow(value, 2.2)

                        for(let j = 0; j < 9; ++j) {
                            sh[j][c] += value * coeffs[j] * weight;
                        }
                    }

                    weightAccum += weight;
                }
            }

        }

        for (let i = 0; i < 9; i++) {
            sh[i][0] *= 4.0 * Math.PI / weightAccum;
            sh[i][1] *= 4.0 * Math.PI / weightAccum;
            sh[i][2] *= 4.0 * Math.PI / weightAccum;
        }

        const shaderResult = _convert(sh);

        return {
            sh: sh,
            compact: shaderResult
        };
    }

    // generate sh from cube map data
    function _generateSH_Cube(pixels:PixelData) {
        const cubemapFaceNormals = [
            [ [0, 0, -1], [0, -1, 0], [1, 0, 0] ],  // posx
            [ [0, 0, 1], [0, -1, 0], [-1, 0, 0] ],  // negx

            [ [1, 0, 0], [0, 0, 1], [0, 1, 0] ],    // posy
            [ [1, 0, 0], [0, 0, -1], [0, -1, 0] ],  // negy

            [ [1, 0, 0], [0, -1, 0], [0, 0, 1] ],   // posz
            [ [-1, 0, 0], [0, -1, 0], [0, 0, -1] ]  // negz
        ];

        // generate shperical harmonics
        const sh = [
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3),
            new Float32Array(3)
        ];
        let weightAccum = 0;

        // sh maps to 128 cubemap sizes?
        const width = pixels.width;
        const height = pixels.height;

        const coeffs = new Float32Array(9);

        let readPixel:(pixels:PixelData, face:number, x:number, y:number) => number[] = null;

        //debugger;

        if(pixels.type === 0) {
            const pixelViews:Uint8Array[] = pixels.cube.map( (value) => new Uint8Array(value) );
            // UINT8
            readPixel = (pixelData:PixelData, face:number, x:number, y:number): number[] => {
                const r = getPixel8(pixels, pixelViews[face], x, y, 0) / 255.0;
                const g = getPixel8(pixels, pixelViews[face], x, y, 1) / 255.0;
                const b = getPixel8(pixels, pixelViews[face], x, y, 2) / 255.0;
                return [r, g, b];
            };

        } else if(pixels.type === 1) {
            const pixelViews:Float32Array[] = pixels.cube.map( (value) => new Float32Array(value) );
            // FLOAT
            readPixel = (pixelData:PixelData, face:number, x:number, y:number): number[] => {
                const r = getPixel32(pixels, pixelViews[face], x, y, 0);
                const g = getPixel32(pixels, pixelViews[face], x, y, 1);
                const b = getPixel32(pixels, pixelViews[face], x, y, 2);
                return [r, g, b];
            };

        } else {
            //ERROR
        }

        // for every face
        for(let i = 0; i < 6; ++i) {
            const index = i;
            for (let y = 0; y < height; y++) {
                for (let x = 0; x < width; x++) {

                    const fU = (2.0 * x / (width - 1.0)) - 1.0;
                    const fV = (2.0 * y / (height - 1.0)) - 1.0;

                    const vecX = [0,0,0];
                    vec3_scale(vecX, cubemapFaceNormals[index][0], fU);
                    const vecY = [0,0,0];
                    vec3_scale(vecY, cubemapFaceNormals[index][1], fV);
                    const vecZ = cubemapFaceNormals[index][2];

                    const direction = [];
                    vec3_add(direction, vecX, vecY);
                    vec3_add(direction, direction, vecZ);
                    vec3_normalize(direction, direction);

                    //TODO: check for flipY on texture data...
                    // sample color
                    const color = readPixel(pixels, index, x, height - y - 1);
                    // add weighting
                    //const weight = texelSolidAngle(x, y, width, height);
                    // add weighting: http://ppsloan.org/publications/StupidSH36.pdf
                    const fTmp = 1 + fV*fV + fU*fU;
                    const weight = 4.0 / (Math.sqrt(fTmp)*fTmp);

                    // create basic coefficients for direction
                    SHNewEval3_(direction, coeffs);

                    // apply cosine lobe
                    CosineConvolve(coeffs);

                    // RGB
                    for(let c = 0; c < 3; c++) {
                        const value = color[c] * pixels.multiplier;

                        //if (gammaCorrect) value = Math.pow(value, 2.2)

                        for(let j = 0; j < 9; ++j) {
                            sh[j][c] += value * coeffs[j] * weight;
                        }
                    }

                    weightAccum += weight;
                }
            }
        }

        for (let i = 0; i < 9; i++) {
            sh[i][0] *= 4.0 * Math.PI / weightAccum;
            sh[i][1] *= 4.0 * Math.PI / weightAccum;
            sh[i][2] *= 4.0 * Math.PI / weightAccum;
        }

        const shaderResult = _convert(sh);

        return {
            sh: sh,
            compact: shaderResult
        };
    }

    /**
     * with prefixed rotation
     * using three bands
     */
    function SHNewEval3_(normal:number[], sh:Float32Array) {
        // Band 0
        sh[0] = 0.282095;

        // Band 1
        //sh[1] = -0.488603f * n.Y;
        sh[1] = 0.488603 * normal[1];
        sh[2] = 0.488603 * normal[2];
        //sh[3] = -0.488603f * n.X;
        sh[3] = 0.488603 * normal[0];

        // Band 2
        sh[4] = 1.092548 * normal[0] * normal[1];
        //sh[5] = -1.092548f * n.Y * n.Z;
        sh[5] = 1.092548 * normal[1] * normal[2];
        sh[6] = 0.315392 * (3.0 * normal[2] * normal[2] - 1.0);
        //sh[7] = -1.092548f * n.X * n.Z;
        sh[7] = 1.092548 * normal[0] * normal[2];
        sh[8] = 0.546274 * (normal[0] * normal[0] - normal[1] * normal[1]);
    }

    //do a cosine kernel over sh coefficients
    //using 3 bands
    function CosineConvolve(sh:Float32Array) {
        // Cosine kernel (with pi)
        const A0 = 3.141593;
        const A1 = 2.095395;
        const A2 = 0.785398;

        // Cosine kernel (without pi)
        // const A0 = 1.0;
        // const A1 = 2.0 / 3.0;
        // const A2 = 1.0 / 4.0;

        // band 0
        sh[0] *= A0;

        // band 1
        sh[1] *= A1;
        sh[2] *= A1;
        sh[3] *= A1;

        // band 2
        sh[4] *= A2;
        sh[5] *= A2;
        sh[6] *= A2;
        sh[7] *= A2;
        sh[8] *= A2;
    }

    function _convert(shCoefficients) {
        // Lighting environment coefficients
        const vCoeff = [
            new Float32Array(4),
            new Float32Array(4),
            new Float32Array(4)
        ];

        const C0 = 0.2820947918;
        const C1 = 0.325735008;
        const C2 = 0.2731371076;
        const C3 = 0.07884789129;
        const C4 = C2 * 0.5;

        const shBlock = {
            cAr:[],
            cAg:[],
            cAb:[],
            cBr:[],
            cBg:[],
            cBb:[],
            cC:[]
        };

        // for every color
        for(let c = 0; c < 3; ++c) {
            // -C1 * L11
            vCoeff[c][0] = C1 * shCoefficients[3][c];
            // -C1 * L1_1
            vCoeff[c][1] = C1 * shCoefficients[1][c];
            // C1 * L10
            vCoeff[c][2] = C1 * shCoefficients[2][c];
            // C0 * L00 - C3 * L20
            vCoeff[c][3] = C0 * shCoefficients[0][c] - C3 * shCoefficients[6][c];
        }

        shBlock.cAr = Array.prototype.slice.call(vCoeff[0]);
        shBlock.cAg = Array.prototype.slice.call(vCoeff[1]);
        shBlock.cAb =  Array.prototype.slice.call(vCoeff[2]);

        for(let c=0; c < 3; ++c) {
            // C2 * L2_2
            vCoeff[c][0] = C2 * shCoefficients[4][c];
            // -C2 * L2_1
            vCoeff[c][1] = C2 * shCoefficients[5][c];
            // 3.0 * C3 * L20
            vCoeff[c][2] = 3.0 * C3 * shCoefficients[6][c];
            // -C2 * L21
            vCoeff[c][3] = C2 * shCoefficients[7][c];
        }

        shBlock.cBr = Array.prototype.slice.call(vCoeff[0]);
        shBlock.cBg = Array.prototype.slice.call(vCoeff[1]);
        shBlock.cBb = Array.prototype.slice.call(vCoeff[2]);

        // C4 * L22
        vCoeff[0][0] = C4 * shCoefficients[8][0];
        vCoeff[0][1] = C4 * shCoefficients[8][1];
        vCoeff[0][2] = C4 * shCoefficients[8][2];
        vCoeff[0][3] = 1.0;

        shBlock.cC = Array.prototype.slice.call(vCoeff[0]);

        return shBlock;
    }

    // check input data
    if(input.data) {
        const shResult = _generateSH(input);
        // return values
        callback(shResult);
    } else if(input.cube) {
        const shResult = _generateSH_Cube(input);
        // return values
        callback(shResult);
    } else {
        callback({
            compact: {
                cAr:[],
                cAg:[],
                cAb:[],
                cBr:[],
                cBg:[],
                cBb:[],
                cC:[]
            },
            sh: []
        });
    }

}
