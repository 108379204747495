export default {
    name: "vimeo",
    title: "Vimeo",
    purposes: ['functional'],
    // contextualConsentOnly: true,
    translations: {
        en: {
            description: `
                Vimeo allows content published on vimeo.com to be embedded directly into websites. The cookies are used to collect visited web pages and detailed statistics about user behavior. This data can be linked to the data of users registered on vimeo.com.
                <br><br>

                <table>
                    <thead>
                        <th>Cookie-Type</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Validity period</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>vuid</td>
                            <td>.vimeo.com</td>
                            <td>10 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>player</td>
                            <td>.vimeo.com</td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>Local Storage</td>
                            <td>sync_volume</td>
                            <td>player.vimeo.com</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Local Storage</td>
                            <td>sync_active</td>
                            <td>player.vimeo.com</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>vimeo</td>
                            <td>.vimeo.com</td>
                            <td>1 Month</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>is_logged_in</td>
                            <td>.vimeo.com</td>
                            <td>10 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_uetvid</td>
                            <td>.vimeo.com</td>
                            <td>21 Tage</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>has_logged_in</td>
                            <td>.vimeo.com</td>
                            <td>10 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_fbp</td>
                            <td>.vimeo.com</td>
                            <td>3 Months</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_uetsid</td>
                            <td>.vimeo.com</td>
                            <td>1 Tag</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gat_UA-*</td>
                            <td>.vimeo.com</td>
                            <td>1 Stunde</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gid</td>
                            <td>.vimeo.com</td>
                            <td>1 Tag</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>continuous_play_v3</td>
                            <td>.vimeo.com</td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gcl_au</td>
                            <td>.vimeo.com</td>
                            <td>3 Months</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_ga</td>
                            <td>.vimeo.com</td>
                            <td>2 Years</td>
                        </tr>
                    </tbody>
                </table>

                <br>
                Use on the legal basis of: Consent
                <br>
                Provider: Vimeo Inc.
                <br>
                US data processing: Yes
                <br>
                Provider privacy policy: https://vimeo.com/privacy
            `,
        },
        de: {
            description: `
                Vimeo erlaubt es auf vimeo.com veröffentlichte Inhalte direkt in Websites einzubetten. Die Cookies werden verwendet, um besuchte Webseiten und detaillierte Statistiken über das Nutzerverhalten zu sammeln. Diese Daten können mit den Daten der auf vimeo.com angemeldeten Nutzer verknüpft werden.
                <br><br>

                <table>
                    <thead>
                        <th>Cookie-Typ</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Gültigkeitsdauer</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>vuid</td>
                            <td>.vimeo.com</td>
                            <td>10 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>player</td>
                            <td>.vimeo.com</td>
                            <td>1 Jahr</td>
                        </tr>
                        <tr>
                            <td>Local Storage</td>
                            <td>sync_volume</td>
                            <td>player.vimeo.com</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Local Storage</td>
                            <td>sync_active</td>
                            <td>player.vimeo.com</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>vimeo</td>
                            <td>.vimeo.com</td>
                            <td>1 Monat</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>is_logged_in</td>
                            <td>.vimeo.com</td>
                            <td>10 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_uetvid</td>
                            <td>.vimeo.com</td>
                            <td>21 Tage</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>has_logged_in</td>
                            <td>.vimeo.com</td>
                            <td>10 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_fbp</td>
                            <td>.vimeo.com</td>
                            <td>3 Monate</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_uetsid</td>
                            <td>.vimeo.com</td>
                            <td>1 Tag</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gat_UA-*</td>
                            <td>.vimeo.com</td>
                            <td>1 Stunde</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gid</td>
                            <td>.vimeo.com</td>
                            <td>1 Tag</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>continuous_play_v3</td>
                            <td>.vimeo.com</td>
                            <td>1 Jahr</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gcl_au</td>
                            <td>.vimeo.com</td>
                            <td>3 Monate</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_ga</td>
                            <td>.vimeo.com</td>
                            <td>2 Jahre</td>
                        </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Verwendung auf gesetzlicher Grundlage von: Einwilligung
                </p>
                <p class="purposes">
                    Anbieter: Vimeo Inc.
                </p>
                <p class="purposes">
                    US-Datenverarbeitung: Ja
                </p>
                <p class="purposes">
                    Datenschutzerklärung des Anbieters: https://vimeo.com/privacy
                </p>
            `,
        },
    },
    cookies: [
        ["vuid", ".vimeo.com"],
        ["player", ".vimeo.com"],
        ["vimeo", ".vimeo.com"],
        ["is_logged_in", ".vimeo.com"],
        ["_uetvid", ".vimeo.com"],
        ["has_logged_in", ".vimeo.com"],
        ["_fbp", ".vimeo.com"],
        ["_uetsid", ".vimeo.com"],
        ["_gat_UA-*", ".vimeo.com"],
        ["_gid", ".vimeo.com"],
        ["continuous_play_v3", ".vimeo.com"],
        ["_gcl_au", ".vimeo.com"],
        ["_ga", ".vimeo.com"],
     
        ["sync_volume", "player.vimeo.com"],
        ["sync_active", "player.vimeo.com"],
    ],
};