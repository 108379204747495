// Generics
// Classes
export class Tuple<T1, T2> {

    private _item1:T1;
    get item1():T1 {return this._item1};
    private _item2:T2;
    get item2():T2 {return this._item2};

    constructor(public i1: T1, public i2: T2) {
        this._item1 = i1;
        this._item2 = i2;
    }
}

// Interfaces
interface Pair<T> {
    item1: T;
    item2: T;
}

// And functions
export var pairToTuple = function<T>(p: Pair<T>) {
    return new Tuple(p.item1, p.item2);
};