///<reference path="../../js_bindings.d.ts"/>

import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';
import { Platform } from 'redTyped/core/Platform';

export class SocialComponent extends AbstractBlockComponent {

    private _mobile:boolean;

    protected onInit():void{
        this._params = _.defaults(this._params, {});
        this._mobile = Platform.get().isMobile;
        //console.log("SocialComponent", this._block.domElement);
        this.state = ComponentState.READY;

        let whatsapp = this._block.domElement.querySelector('.social_whatsapp');
        if(whatsapp && !this._mobile){
            whatsapp.parentNode.removeChild(whatsapp);
        }
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }
}