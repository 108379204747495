/**
 * Events.ts: Class Events
 *
 * use these as public variables or through getter in your class
 * TODO: trigger should only be callable from owning class.
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */

export type NoArgCallback = () => void;
export type OneArgCallback<T> = (first: T) => void;
export type TwoArgCallback<T, U> = (first: T, second: U) => void;
export type ThreeArgCallback<T, U, V> = (first: T, second: U, third: V) => void;

/**
 * Event without arguments
 */
export class EventNoArg {
    private _handlers: NoArgCallback[] = [];

    public get hasHandlers() {
        return this._handlers.length > 0;
    }

    public on(handler: NoArgCallback) {
        this._handlers.push(handler);
    }

    public off(handler: NoArgCallback) {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger() {
        this._handlers.slice(0).forEach((h) => h());
    }

    public clearAll() {
        this._handlers = [];
    }
}

/**
 * Event with one argument
 */
export class EventOneArg<T> {
    private _handlers: OneArgCallback<T>[] = [];

    public get hasHandlers() {
        return this._handlers.length > 0;
    }

    public on(handler: OneArgCallback<T>) {
        this._handlers.push(handler);
    }

    public off(handler: OneArgCallback<T>) {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(first?: T) {
        this._handlers.slice(0).forEach((h) => h(first));
    }

    public clearAll() {
        this._handlers = [];
    }
}

/**
 * Event with two arguments
 */
export class EventTwoArg<T, U> {
    private _handlers: TwoArgCallback<T, U>[] = [];

    public get hasHandlers() {
        return this._handlers.length > 0;
    }

    public on(handler: TwoArgCallback<T, U>) {
        this._handlers.push(handler);
    }

    public off(handler: TwoArgCallback<T, U>) {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(first?: T, second?: U) {
        this._handlers.slice(0).forEach((h) => h(first, second));
    }

    public clearAll() {
        this._handlers = [];
    }
}

/**
 * Event with three arguments
 */
export class EventThreeArg<T, U, V> {
    private _handlers: ThreeArgCallback<T, U, V>[] = [];

    public get hasHandlers() {
        return this._handlers.length > 0;
    }

    public on(handler: ThreeArgCallback<T, U, V>) {
        this._handlers.push(handler);
    }

    public off(handler: ThreeArgCallback<T, U, V>) {
        this._handlers = this._handlers.filter((h) => h !== handler);
    }

    public trigger(first?:T, second?:U, third?:V) {
        this._handlers.slice(0).forEach((h) => h(first, second, third));
    }

    public clearAll() {
        this._handlers = [];
    }
}
