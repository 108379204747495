/**
 * MaterialAPI.ts: asset API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { MaterialGroupTemplate, MaterialTemplateNamed, MaterialTemplate } from "../render/Material";
import { EventTwoArg, EventNoArg, EventOneArg } from "../core/Events";
import { AsyncLoad } from "../io/AsyncLoad";
import { MaterialAnimation } from "../animation/MaterialAnimation";
import { makeAPI, queryAPI } from "../plugin/Plugin";

/**
 * @interface IMaterialSystem
 *
 * #### Material handling
 *
 * There are two libraries used:
 * a global list where materials are generated from a template.
 *
 * a group list where material templates are attached to one group.
 * groups have one active material which are switchable.
 * every groups saves not the material but the templates.
 * templates are editable and can be applied across groups.
 *
 * Events:
 * OnMaterialChanged: get called when material template in a group has changed.
 * OnMaterialGroupChanged: get called when new material template for group has been choosen.
 *
 */
export interface IMaterialSystem {
    /** events */
    OnMaterialChanged:EventTwoArg<MaterialTemplateNamed, number|undefined>;
    OnMaterialGroupChanged:EventTwoArg<MaterialGroupTemplate, string|undefined>;
    OnMaterialsLoaded:EventNoArg;
    OnMaterialGroupsLoaded:EventNoArg;
    /** notify client database has changed */
    MaterialGroupDBChanged:EventOneArg<string>;
    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    flushGPUMemory();
    /**
     * flush memory on the cpu and gpu
     * uses snapshot data to restore to any point
     */
    flush();

    init();

    save();

    createMaterial(name:string, copyToMaterialDB?:boolean, template?:MaterialTemplate) : MaterialTemplate;
    createMaterialGroup(name:string, copyToMaterialGroupDB?:boolean, materials?:string[], defaultMaterial?:string) : MaterialGroupTemplate;

    findGroupByName(group:string) : MaterialGroupTemplate;
    findMaterialByName(name:string, namespace?:string) : MaterialTemplate;
    getCurrentGroupMaterial(group:string, namespace?:string) : MaterialTemplate;

    isGroup(name:string) : boolean;
    isMaterialInGroup(group:string, materialName:string) : boolean;
    getCurrentGroupMaterialName(group:string, namespace?:string);

    loadMaterial(name:string|MaterialTemplate) : AsyncLoad<void>;

    loadMaterialFile(filename:string, preloadTextures?:boolean) : AsyncLoad<void>;

    loadMaterialGroupFile(name:string) : AsyncLoad<void>;

    updateMaterial(name:string, material:MaterialTemplate, transferToLocal?:boolean, mesh?:string|string[]) : void;

    switchMaterialGroup(group:string, materialName:string, time?:number) : MaterialAnimation|null;

    blendTo(name:string, destination:MaterialTemplate, time?:number, mesh?:string|string[]) : MaterialAnimation;
    blink(name:string, destination:MaterialTemplate, time?:number, mesh?:string) : MaterialAnimation;

    hasAnimation(name:string) : boolean;
    stopAnimation(name:string) : void;
}
export const MATERIALSYSTEM_API = makeAPI("IMaterialSystem");

let _globalMaterialSystem:IMaterialSystem;
export function queryMaterialSystem() {
    if(_globalMaterialSystem === undefined) {
        _globalMaterialSystem = queryAPI<IMaterialSystem>(MATERIALSYSTEM_API);
    }
    return _globalMaterialSystem;
}
