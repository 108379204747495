///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class UtilsComponent extends AbstractBlockComponent {


    protected onInit():void{
        this._params = _.defaults(this._params, {});
        this.state = ComponentState.READY;

        // fix pdf to download instead of open in a new tab
        const anchorsToPDF = Array.prototype.slice.call(this.block.domElement.querySelectorAll("a[href*='.pdf']")) as HTMLAnchorElement[];
        anchorsToPDF.forEach((anchorToPDF)=>{
            const filename = anchorToPDF.href.split('/').pop();
            anchorToPDF.download = filename;
        });
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }
}