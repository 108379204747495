
import {PropertyProvider} from './PropertyProvider';
import {PropertyOption} from './PropertyOption';

interface ClientCoords {
    x:number;
    y:number;
}

export class PropertyUI {

    private _rootElement:HTMLElement;
    private _selectorElement:HTMLElement;
    private _selectedElement:HTMLElement;
    private _propertiesListElement:HTMLElement;
    private _propertyElements:NodeListOf<HTMLElement>;
    private _properties:Object = {};
    private _optionsElement:HTMLElement;
    private _optionsCloseElement:HTMLElement;
    private _optionsScrollElement:HTMLElement;
    private _optionsClipElement:HTMLElement;
    private _optionsListElement:HTMLElement;
    private _templateOptionsList;
    private _lastClickedOption:PropertyOption = null;
    private _currentOption:PropertyOption = null;

    private _isHidden:boolean = true;
    private _currentPropertyIndex:number = -1;
    private _selectorOpen:boolean = false;
    private _propertyCount:number = 0;

    private _couldBeDragging:boolean;
    private _isDragging:boolean;
    private _isDown:boolean;

    private _dragXStart:number;
    private _dragXEnd:number;
    private _dragXDistance:number;

    private _dragLeftMax:number;
    private _dragLeftStart:number;

    private _dragTimeStart:number;
    private _dragTimeEnd:number;
    private _dragDuration:number;

    private _dragTimeout:number;

     constructor(private _domElement:Element, private _provider:PropertyProvider) {

        this._dragTimeout = -1;

        this._rootElement           = this._domElement.querySelector('.scene_properties') as HTMLElement;
        this._selectorElement       = this._domElement.querySelector('.scene_properties_selector') as HTMLElement;
        this._selectedElement       = this._domElement.querySelector('.scene_properties_selector_selected') as HTMLElement;
        this._propertiesListElement = this._selectorElement.querySelector('ul');
        this._propertyElements      = this._propertiesListElement.querySelectorAll('li');
        this._optionsElement        = this._domElement.querySelector('.scene_properties_options') as HTMLElement;
        this._optionsCloseElement   = this._domElement.querySelector('.scene_properties_options_close') as HTMLElement;
        this._optionsClipElement    = this._domElement.querySelector('.scene_properties_options_clip') as HTMLElement;
        // this._optionsScrollElement    = this._domElement.querySelector('.scene_properties_options_scroll') as HTMLElement;
        this._optionsListElement    = this._optionsElement.querySelector('ul');

        this._selectedElement.addEventListener("click",this.toggleSelector);
        this._optionsCloseElement.addEventListener("click",this.closeOptions);
        this._optionsElement.addEventListener("mouseleave",this.applyLastOption);

        //Touch handler
        document.addEventListener('touchmove', this.dragMove, false);
        document.addEventListener('touchend', this.dragEnd);
        this._optionsClipElement.addEventListener('touchstart', this.dragStart);

        //Mouse handler
        document.addEventListener('mousemove', this.dragMove, false);
        document.addEventListener('mouseup', this.dragEnd);
        this._optionsClipElement.addEventListener('mousedown', this.dragStart, true);

        //Window Events for the options bar
        window.addEventListener("resize",this.recalcLayout);
        window.addEventListener("orientationchange",this.recalcLayout,false);

        this._templateOptionsList = _.template(`
        <% _.forEach(options, function(option) { %>
            <% if (option.preview.type == "color") { %>
                <li class="color<% if (option.selected) { %> selected<% } %>" style="background-color:<%- option.preview.value %>;"></li>
            <% } %>
            <% if (option.preview.type == "color_array") { %>
                <li class="color_array<% if (option.selected) { %> selected<% } %>">
                    <div style="background-color:<%- option.preview.value[0] %>;"></div>
                    <div style="background-color:<%- option.preview.value[1] %>;"></div>
                    <div style="background-color:<%- option.preview.value[2] %>;"></div>
                </li>
            <% } %>
            <% if (option.preview.type == "texture") { %>
                <li class="texture<% if (option.selected) { %> selected<% } %>" style="background-image:url('<%- option.preview.value %>');"></li>
            <% } %>
            <% if (option.preview.type == "text") { %>
                <li class="text<% if (option.selected) { %> selected<% } %>"><%- option.preview.value %></li>
            <% } %>
        <% }); %>`
        );

        _.each( this._propertyElements, (propertyElement:HTMLElement, index)=>{
            let id = propertyElement.getAttribute("data-propertyid");
            let label = propertyElement.innerHTML;
            this._properties[id] = label;

            propertyElement.addEventListener("click",()=>{
               this.currentPropertyIndex = index;
            });
        });


        if(this._provider.getOnPropertyIdChange() != null){
            this._provider.getOnPropertyIdChange().subscribe(this.handleProviderIdChange);
        }

        this._propertyCount = _.size(this._properties);
        this.resetLabel();
        this.recalcLayout();
        this._rootElement.classList.add("initialized");
     }

     public set hidden(isHidden:boolean){
         if(this._isHidden == isHidden){
             return;
         }

         this._isHidden = isHidden;

         if(this._isHidden){
            this._rootElement.classList.add("hidden");
            this.closeOptions();
            this.closeSelector();
         }
         else {
            this._rootElement.classList.remove("hidden");
         }
     }
     public get hidden():boolean{
        return this._isHidden;
     }

     private getCurrentPropertyId():string{
        if (this._currentPropertyIndex  >= 0 && this._currentPropertyIndex  < this._propertyCount) {
            let id = Object.keys(this._properties)[this._currentPropertyIndex ];
            return id;
        }
        return "undefined";
     }

    private set currentPropertyIndex(value: number) {
        if (value == this._currentPropertyIndex) {
            this.closeSelector();
            return;
        }

        this._currentPropertyIndex = value;
        //  console.log("currentPropertyIndex", value);

        if (value >= 0 && value < this._propertyCount) {
            let id = Object.keys(this._properties)[value];
            let label = this._properties[id];

            if (typeof ga === 'function'){
                ga('send', 'event', this._provider.getProviderTag() , 'PropertySelect', id);
            }

            // console.log("currentPropertyId", id, label);
            this._selectedElement.innerHTML = label;

            this._provider.selectProperty(id, label);

            let options = this._provider.getOptionsByPropertyId(id);
            if (options.length > 0) {
                this.updateOptions(options);
            }
        }
        else {
            this.resetLabel();
            this.closeOptions();
        }

        this.closeSelector();
    }

    private handleProviderIdChange = (provider,id) => {
        if(this.hidden){
            return;
        }

        let index = _.indexOf(Object.keys(this._properties),id);
        this.currentPropertyIndex = index;
    };

    private recalcLayout = (event:Event = null):void => {
        //debounce resize or orientationchange event
        setTimeout(()=>{
            let bodyElement = document.getElementsByTagName('body')[0];
            let pageWidth = document.documentElement.clientWidth || bodyElement.clientWidth || window.innerWidth;
            let pageHeight = document.documentElement.clientHeight || bodyElement.clientHeight || window.innerHeight;

            let bodyRect = document.body.getBoundingClientRect();
            let elemRect = this._optionsClipElement.getBoundingClientRect();
            let offsetLeft   = elemRect.left - bodyRect.left;

            let maxWidth = pageWidth - (offsetLeft * 2);

            //safty area for chat icon
            if(maxWidth >= pageWidth - 100){
                maxWidth -= 50;
            }

            this._optionsClipElement.style.maxWidth = maxWidth + "px";

            //make the option panel visible ->
            //it has to be invisible but without display:none at first to
            //calcualte the bounding values correctly
            //-> avoid flickering
            setTimeout(()=>{
                this._optionsElement.style.visibility = "visible";
            },10);

            //set the top value for the root element
            let rootLeft = this._rootElement.getBoundingClientRect().left * 0.5;
            this._rootElement.style.top = (pageHeight - rootLeft - 50) + "px";

            //Scrollable property list
            let maxPropertyListHeigth = this._selectedElement.getBoundingClientRect().top - 70;
            this._propertiesListElement.style.maxHeight = maxPropertyListHeigth + "px";
        },10);
    }

    private getClientCoordsFromEvent = (event:Event):ClientCoords => {
         let coords:ClientCoords = {
             x: 0,
             y: 0
         }

         if (event instanceof TouchEvent){
             if(event.touches.length > 0){
                coords.x = event.touches[0].clientX;
                coords.y = event.touches[0].clientY;
             }
         }

         if (event instanceof MouseEvent){
             coords.x = event.clientX;
             coords.y = event.clientY;
         }

         return coords;
     }

     private dragStart = (event:Event):void => {
        //  console.log("dragStart",event);
         this._dragTimeStart = Date.now();
         this._couldBeDragging = true;
         this._dragLeftMax = this._optionsListElement.scrollWidth - this._optionsListElement.clientWidth;
         this._dragLeftStart = this._optionsListElement.scrollLeft;
         this._dragXStart = this.getClientCoordsFromEvent(event).x;
         this._dragXDistance = 0;
     }

     private dragMove = (event: Event): void => {

         if (this._couldBeDragging) {
             this._dragDuration = Date.now() - this._dragTimeStart;
             let clientX = this.getClientCoordsFromEvent(event).x
             this._dragXDistance = clientX - this._dragXStart;

             if(Math.abs( this._dragXDistance) > 5){
                 this._isDragging = true;
             }

             if (this._isDragging) {
                 event.preventDefault();
                 event.stopPropagation();

                 let scrollLeft = Math.max(0, Math.min(this._dragLeftStart - this._dragXDistance, this._dragLeftMax));
                 this._optionsListElement.scrollLeft = scrollLeft;


                //  console.log("dragMove dragXDistance", this._dragLeftMax, this._dragXDistance , this._optionsClipElement.scrollLeft);
             }
         }
     }

     private dragEnd = (event: Event): void => {

         this._dragTimeEnd = Date.now();
         this._dragDuration = this._dragTimeEnd - this._dragTimeStart;

         let clientX = this.getClientCoordsFromEvent(event).x
         this._dragXDistance = clientX - this._dragXStart;

         this._couldBeDragging = false;
         this._isDragging = false;
         clearTimeout(this._dragTimeout);

         this._dragTimeout = setTimeout(() => {
             this.applyLastOption();
         }, 500);
        //  console.log("dragEnd", event);
     }

     private resetLabel = () => {
        this._selectedElement.innerHTML = this._selectedElement.getAttribute("data-unselected-label");
     }

     private closeSelector = () => {
        this._selectorElement.classList.remove('open');
        this._selectorOpen = false;
     }

     private toggleSelector = (event) => {
        this._propertiesListElement.scrollTop = 0;
        this._selectorElement.classList.toggle('open');
     }

      private openOptions = () => {
        this._optionsElement.classList.add('open');
        this.recalcLayout();
     }

      private closeOptions = () => {
        //reset the last options
        this._lastClickedOption = null;
        this._currentOption = null;

        this._optionsElement.classList.remove('open');
        this.currentPropertyIndex = -1;
     }

     private updateOptions = (options:Array<PropertyOption>) => {
        //reset the last options
        let selectedOption = _.find(options,(option:PropertyOption)=>{return option.selected});
        this._lastClickedOption = selectedOption;
        this._currentOption = selectedOption;

        //generate the list html
        let tmpList = this._templateOptionsList({options:options});
        //remove all the whitespace between tags -> display:inline-block issue
        tmpList = _.trim(tmpList.replace(/\>\s+\</g,"><"));
        this._optionsListElement.innerHTML = tmpList;
        this._optionsListElement.scrollLeft = 0;

        //get all list elements
        let optionsElements = this._optionsListElement.querySelectorAll('li');
        _.each( optionsElements, (optionElement:HTMLElement, index) => {
            optionElement.addEventListener("click",(event) => {
                //The click is only valid if the click comes fast or if the dragdistance is small
                if(this._dragDuration < 1000 || Math.abs(this._dragXDistance) < 30){
                    clearTimeout(this._dragTimeout);

                    let optionValue = options[index];
                    // console.log("optionValue",this.getCurrentPropertyId(),optionValue.trackingTag);
                    if (typeof ga === 'function'){
                        ga('send', 'event', this._provider.getProviderTag() , 'PropertyOptionApply', this.getCurrentPropertyId(), optionValue.trackingTag);
                    }

                    this._provider.applyOption(optionValue);
                    this._currentOption = optionValue;
                    this._lastClickedOption = optionValue;
                    optionElement.classList.add('selected');
                    _.each(this._optionsListElement.childNodes,(sibling, indexSiblings) => {
                        if(sibling != optionElement){
                            sibling.classList.remove('selected');
                        }
                    });
                }
            });
            optionElement.addEventListener("mouseover",() => {
                if(this._isDragging === false){
                    this._provider.applyOption(options[index]);
                    this._currentOption = options[index];
                }
            });

            //FadeIn
            setTimeout(()=>{
                optionElement.style.opacity = "1.0";
            }, 100 + (index * 20));
        });
        this.openOptions();
        // console.log("propertyValues", options,tmpList);
     }

    private applyLastOption = () =>{
        if(this._currentOption != this._lastClickedOption){
            if(this._lastClickedOption != null){
                this._provider.applyOption(this._lastClickedOption);
            }
        }
     };

     public destroy():void {
        this._selectedElement.removeEventListener("click",this.toggleSelector);
        this._optionsCloseElement.removeEventListener("click",this.closeOptions);
        this._optionsElement.removeEventListener("mouseleave",this.applyLastOption);

        //remove provider events
        if(this._provider.getOnPropertyIdChange() != null){
            this._provider.getOnPropertyIdChange().unsubscribe(this.handleProviderIdChange);
        }

        //Touch handler
        document.removeEventListener('touchmove', this.dragMove, false);
        document.removeEventListener('touchend', this.dragEnd);
        this._optionsClipElement.removeEventListener('touchstart', this.dragStart);

        //Mouse handler
        document.removeEventListener('mousemove', this.dragMove, false);
        document.removeEventListener('mouseup', this.dragEnd);
        this._optionsClipElement.removeEventListener('mousedown', this.dragStart, true);

        window.removeEventListener("resize",this.recalcLayout);
        window.removeEventListener("orientationchange",this.recalcLayout,false);
     }
}