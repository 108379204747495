/**
 * ComponentUpdateSystem.ts: Entity Component code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { TagObject, ITaggingSystem, TAGGINGSYSTEM_API } from "../framework/TaggingAPI";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { IWorld } from "../framework/WorldAPI";
import { registerAPI } from "../plugin/Plugin";

/** single entry in tagging */
interface TagObjectInternal {
    /** id */
    id: ComponentId;
    /** tagging of object */
    tag:number;
    /** user data reference */
    userData: any;
    /** object reference */
    object: any;
}

/**
 * tagging of arbitary objects
 */

let _objects:TagObjectInternal[] = [];
let _version:number = 1;

function destroy() {
    // clear all callbacks
    _objects = [];
    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function init(world:IWorld) {

}

/**
 * return tag of component id
 * @param id component id of object
 */
function getTag(id:ComponentId) : number {
    if(!_validId(id)) {
        return 0;
    }

    const index = componentIdGetIndex(id);
    return _objects[index].tag;
}

/**
 * set tag of component id
 * @param id component id of object
 * @param tag tag value
 */
function setTag(id:ComponentId, tag:number) {
    if(!_validId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    // apply new tag
    _objects[index].tag = tag;
}

/**
 * get user data attach to component id
 * @param id component id
 */
function getUserData(id:ComponentId) : any {
    if(!_validId(id)) {
        return null;
    }

    const index = componentIdGetIndex(id);
    return _objects[index].userData;
}

/**
 * set custom data for component id
 * @param id component id
 * @param userData any user data
 */
function setUserData(id:ComponentId, userData:any) {
    if(!_validId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    // cleanup
    _objects[index].userData = userData;
}

/** get all objects that are registered */
function getObjects() {
    _objects.map( (value) => {
        return {
            object: value.object,
            tag: value.tag,
            userData: value.userData
        } as TagObject;
    });
}

/** get all objects with a tag associated */
function getObjectsTagged() {
    const objects:TagObject[] = [];

    for(const obj of _objects) {
        if(obj.tag === undefined) {
            continue;
        }

        objects.push({
            object: obj.object,
            tag: obj.tag,
            userData: obj.userData
        });
    }
    return objects;
}

/** get all objects with data associated */
function getObjectsWithData() {
    const objects:TagObject[] = [];

    for(const obj of _objects) {
        if(obj.userData === undefined || obj.userData === null) {
            continue;
        }

        objects.push({
            object: obj.object,
            tag: obj.tag,
            userData: obj.userData
        });
    }
    return objects;
}

/**
 * query objects by tag
 * @param tag tag to search for
 */
function getObjectsByTag(tag:number) {
    const objects:TagObject[] = [];

    for(const obj of _objects) {
        if(obj.tag !== tag) {
            continue;
        }

        objects.push({
            object: obj.object,
            tag: obj.tag,
            userData: obj.userData
        });
    }
    return objects;
}

/**
 * query objects by tag
 * @param predicate callback function
 */
function getObjectsByTagPredicate(predicate:(object:TagObject) => boolean) {
    const objects:TagObject[] = [];

    for(const obj of _objects) {
        if(obj.tag === undefined || !predicate(obj)) {
            continue;
        }

        objects.push({
            object: obj.object,
            tag: obj.tag,
            userData: obj.userData
        });
    }
    return objects;
}

/**
 * query all objects with custom data set
 * @param customData custom data to search for
 */
function getObjectsByData(customData:any) {
    const objects:TagObject[] = [];

    for(const obj of _objects) {
        if(obj.userData !== customData) {
            continue;
        }

        objects.push({
            object: obj.object,
            tag: obj.tag,
            userData: obj.userData
        });
    }
    return objects;
}

/**
 * query all objects with custom data set
 * @param predicate callback function
 */
function getObjectsByDataPredicate(predicate:(object:TagObject) => boolean) {
    const objects:TagObject[] = [];

    for(const obj of _objects) {
        if(obj.userData === undefined || obj.userData === null || !predicate(obj)) {
            continue;
        }

        objects.push({
            object: obj.object,
            tag: obj.tag,
            userData: obj.userData
        });
    }
    return objects;
}

/**
 * add a new tag object to this list
 * @param object generic object
 * @param tag optional tag
 * @param userData optional custom data
 */
function registerObject(object:any, tag?:number, userData?:any) : ComponentId {
    let index = -1;

    for(let i = 0; i < _objects.length; ++i) {
        if(!_objects[i].id) {
            index = i;
            break;
        }
    }

    // new entry
    if(index === -1) {
        index = _objects.length;
        _objects[index] = {
            id: 0,
            tag: 0,
            userData: null,
            object: null
        };
    }

    // defaults
    if(userData === undefined) {
        userData = null;
    }

    // setup
    _objects[index].id = createComponentId(index, _version);
    _objects[index].object = object;
    _objects[index].userData = userData;
    _objects[index].tag = tag;

    return _objects[index].id;
}

/**
 * remove object from global list
 * @param id component id
 */
function removeObject(id:ComponentId) {
    if(!_validId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    // cleanup
    _objects[index].id = 0;
    _objects[index].object = null;
    _objects[index].tag = 0;
    _objects[index].userData = null;

    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function _validId(id:ComponentId) {
    const index = componentIdGetIndex(id);
    if(index >= 0 && index < _objects.length) {
        return _objects[index].id === id;
    }
    return false;
}

const taggingSystem:ITaggingSystem = {
    init: init,
    destroy: destroy,
    getObjects,
    getObjectsByData,
    getObjectsByDataPredicate,
    getObjectsByTag,
    getObjectsByTagPredicate,
    getObjectsTagged,
    getObjectsWithData,
    registerObject,
    removeObject,
    setTag,
    setUserData,
    tag: getTag,
    userData: getUserData
};

registerAPI<ITaggingSystem>(TAGGINGSYSTEM_API, taggingSystem);
