///<reference path="../js_bindings.d.ts"/>

export class Breadcrumb{

    private _element:any;
    private _lastScrollTop = 0;
    private _opened:boolean = true;

    constructor(){
        this._element = document.querySelector('.breadcrumbs');

        window.addEventListener("scroll", ()=>{
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            let delta = scrollTop - this._lastScrollTop;
            if(Math.abs(delta) > 5){
                this._lastScrollTop = scrollTop;

                if(delta > 0){
                    this._element.classList.remove('opened');
                }
                else{
                    this._element.classList.add('opened');
                }
            }

            if(scrollTop <= 0){
                this._element.classList.add('opened');
            }
        });
    }

    public apply(breadcrumb:string){
        this._element.innerHTML = breadcrumb;
        this._element.classList.add('opened');
    }
}
