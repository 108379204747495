/**
 * WorldAPI.ts: world API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Entity } from "./Entity";
import { RedCamera } from "../render/Camera";
import { ERayCastQuery, CollisionResult } from "./CollisionAPI";
import { IONotifier } from "../io/Interfaces";
import { GraphicsDisposeSetup } from "../core/Globals";
import { AsyncLoad } from "../io/AsyncLoad";
import { RenderState } from "../render/State";
import { Render } from "../render/Render";
import { Application } from "./App";
import { Scene } from "../../lib/threejs/scenes/Scene";
import { EnvironmentSetup, BackgroundMode } from "../framework-types/WorldFileFormat";
import { EventNoArg } from "../core/Events";
import { makeAPI, PluginId } from "../plugin/Plugin";

/**
 * Generalized System interface.
 */
export interface WorldSystem {
    init(world:IWorld);
    destroy?(dispose?:GraphicsDisposeSetup);
}

/** runtime environment data */
export interface WorldEnvironment {
    /** three.js color */
    backgroundColor:any;
    backgroundAlpha:any;
    /** three.js texture */
    backgroundTexture?:any;
    backgroundTextureMode?:BackgroundMode;
    isEnvironmentMap?:boolean;
    /** three.js background setup (plane or cube map) */
    backgroundScene:any;
    backgroundCamera:any;
    backgroundMesh:any;
}

/**
 * @interface IWorld
 * [[include:sourceDoc/World.md]]
 */
export interface IWorld {
    /** world loaded event */
    OnWorldLoaded:EventNoArg;
    /** world destroyed event */
    OnWorldDestroyed:EventNoArg;

    /** THREE.JS scene reference */
    scene : Scene;

    /** world has environment (renderer clears to this) */
    hasEnvironment() : boolean;

    /** get environment of this world */
    getEnvironment() : EnvironmentSetup;

    /** setup environment of this world */
    setEnvironment(env:EnvironmentSetup);

    /** root entities */
    getEntities() : Array<Entity>;

    /** world validation */
    isValid() : boolean;

    /** loading indicator */
    isLoading() : boolean;

    /** access global app */
    getApp() : Application;

    /** frame has changed world */
    isFrameDirty() : boolean;

    /** init world */
    init(scene?:Scene);

    /**
     * destroy world objects
     * @param forceAll force all scene objects to delete (not only entities)
     */
    destroy(forceAll?:boolean, dispose?:GraphicsDisposeSetup) : void;

    /** update loop */
    think(deltaSeconds:number);

    /** access to systems */
    getSystem<T extends WorldSystem>(api:number|PluginId) : T;

    /** add a new system */
    registerSystem(api:number, instance:WorldSystem);

    /** prepare for rendering */
    preRender(renderer:Render, mainCamera:RedCamera);

    /**
     * render world and entities
     * @param renderer render device to render to
     * @param mainCamera main camera to render to
     */
    render(renderer:Render, mainCamera:RedCamera);

    /**
     * render environment setup to pipeline state
     * @param renderer render device
     * @param camera camera to render environment to
     * @param pipeState pipeline state
     * @param environment environment description
     */
    renderEnvironment(renderer:Render, camera:RedCamera, pipeState:RenderState, environment:WorldEnvironment);

    /**
     * render world only
     * @param renderer render device to render to
     * @param pipeState render pipeline state to use
     * @param camera optional camera to render to
     * @param environment optional environment setup (null for no environment, undefined for default environment)
     */
    renderWorld(renderer:Render, camera:RedCamera, pipeState:RenderState, environment?:WorldEnvironment);

    /**
     * load world
     * @param file filename or preloaded world name
     * @param forceAllCleanup cleanup all scene objects (not only three.js)
     */
    load(file:string, forceAllCleanup?:boolean) : AsyncLoad<IWorld>;

    /**
     * statically preload scene file
     * @param file file reference
     */
    Preload(file:string, preloadFiles?:any[]) : AsyncLoad<void>;

    instantiateEntity(name:string, parent?:Entity) : Entity;

    //removeEntity(entity:Entity);

    /** remove from scene */
    destroyEntity(entity:Entity, dispose?:GraphicsDisposeSetup);

    /**
     * create entity from prefab file
     */
    instantiatePrefab(name:string, parent:Entity, data?:any, ioNotifier?:IONotifier) : Entity;

    findByName(name:string, root?:Entity) : Entity;
    findByPredicate(callback:(entity:Entity) => boolean, root?:Entity) : Entity[];
    /**
     * find entities by tag
     * will return the list of entities with this tag
     */
    findByTag(tag:number, root?:Entity) : Entity[];

    /**
     * @see CollisionSystem
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @returns any hit
     */
    rayCastWorld(origin:any, direction:any, query:ERayCastQuery, results:CollisionResult[]) : boolean;

    /**
     * @see CollisionSystem
     * ray cast against world objects
     * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
     * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
     * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
     * @returns any hit
     */
    rayCast(camera:RedCamera, normalizedScreenX:number, normalizedScreenY:number, query:ERayCastQuery, results:CollisionResult[]) : boolean;

    /**
     * add entity
     */
    addEntity(entity:Entity, parent?:Entity);

    /** never call on your own */
    _removeEntity(entity:Entity, save:boolean);

    /** save world to json */
    save();
}
export const WORLD_API = makeAPI("IWorld");
