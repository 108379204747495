/**
 * InputPropogate.ts: IWorld Input code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { EventOneArg } from "../core/Events";
import { appGet } from "../framework/App";
import { IWorld } from "../framework/WorldAPI";
import { IInputSystem, INPUTSYSTEM_API } from "../framework/InputAPI";
import { registerAPI } from "../plugin/Plugin";

/**
 * input propogation class
 * helps to provide an input
 */
const OnTouchStart = new EventOneArg<Event>();
const OnTouchMove = new EventOneArg<Event>();
const OnTouchEnd = new EventOneArg<Event>();
const OnMouseEnter = new EventOneArg<Event>();
const OnMouseLeave = new EventOneArg<Event>();
const OnMouseDown = new EventOneArg<Event>();
const OnMouseMove = new EventOneArg<Event>();
const OnMouseWheel = new EventOneArg<Event>();
const OnMouseUp = new EventOneArg<Event>();

function init(world:IWorld) {
    const _app = appGet();

    if(_app) {
        _app.OnTouchStart.on(_propogateOnTouchStart);
        _app.OnTouchMove.on(_propogateOnTouchMove);
        _app.OnTouchEnd.on(_propogateOnTouchEnd);
        _app.OnMouseEnter.on(_propogateOnMouseEnter);
        _app.OnMouseLeave.on(_propogateOnMouseLeave);
        _app.OnMouseDown.on(_propogateOnMouseDown);
        _app.OnMouseMove.on(_propogateOnMouseMove);
        _app.OnMouseWheel.on(_propogateOnMouseWheel);
        _app.OnMouseUp.on(_propogateOnMouseUp);
    }
}

/** clean all references to input system */
function destroy() {
    const _app = appGet();

    if(_app) {
        _app.OnTouchStart.off(_propogateOnTouchStart);
        _app.OnTouchMove.off(_propogateOnTouchMove);
        _app.OnTouchEnd.off(_propogateOnTouchEnd);
        _app.OnMouseEnter.off(_propogateOnMouseEnter);
        _app.OnMouseLeave.off(_propogateOnMouseLeave);
        _app.OnMouseDown.off(_propogateOnMouseDown);
        _app.OnMouseMove.off(_propogateOnMouseMove);
        _app.OnMouseWheel.off(_propogateOnMouseWheel);
        _app.OnMouseUp.off(_propogateOnMouseUp);
    }
}

function _propogateOnTouchStart(event:Event) {
    OnTouchStart.trigger(event);
}
function _propogateOnTouchMove(event:Event) {
    OnTouchMove.trigger(event);
}
function _propogateOnTouchEnd(event:Event) {
    OnTouchEnd.trigger(event);
}
function _propogateOnMouseEnter(event:Event) {
    OnMouseEnter.trigger(event);
}
function _propogateOnMouseLeave(event:Event) {
    OnMouseLeave.trigger(event);
}
function _propogateOnMouseDown(event:Event) {
    OnMouseDown.trigger(event);
}
function _propogateOnMouseMove(event:Event) {
    OnMouseMove.trigger(event);
}
function _propogateOnMouseWheel(event:Event) {
    OnMouseWheel.trigger(event);
}
function _propogateOnMouseUp(event:Event) {
    OnMouseUp.trigger(event);
}

const inputSystem:IInputSystem = {
    init: init,
    destroy: destroy,
    OnMouseDown,
    OnMouseEnter,
    OnMouseLeave,
    OnMouseMove,
    OnMouseUp,
    OnMouseWheel,
    OnTouchEnd,
    OnTouchMove,
    OnTouchStart
};

registerAPI<IInputSystem>(INPUTSYSTEM_API, inputSystem);
