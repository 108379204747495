/**
 * StorageProvider.ts: unified storage
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import {build} from "../core/Build";
import {isAbsoluteURL} from "../core/Globals";

/**
 * Storage Provider base class
 */
export class StorageProvider {

    /**
     * does this provder needs cross domain access
     */
    public forceCrossDomain() : boolean {
        return false;
    }

    /**
     * process url
     * storage provider can modify the url
     */
    public processURL(url:string, basePath:string) : string {
        return encodeURI(url);
    }

    public canUseRevisionTag(url:string) {
        return true;
    }

    public canUseUpdateAccess(url:string) {
        return true;
    }
}

/**
 * simple http provider
 */
export class HttpProvider extends StorageProvider {

    constructor() {
        super();
    }

    public processURL(url:string, basePath:string) : string {

        const isRelativePath = (url.indexOf('#') === 0);
        const isBlob = (url.indexOf('blob:') === 0);

        // create url
        let result = basePath + url;

        if(isAbsoluteURL(url) || isBlob || isRelativePath) {
            if(isRelativePath) {
                //FIXME: process url through storage?
                result = url.substr(1,url.length);
            } else {
                // replace url
                result = encodeURI(url);
            }
        }
        return result;
    }

    public canUseRevisionTag(url:string) {
        const isRelativePath = (url.indexOf('#') === 0);
        const isBlob = (url.indexOf('blob:') === 0);
        return !isRelativePath && !isBlob;
    }

    public canUseUpdateAccess(url:string) {
        const isRelativePath = (url.indexOf('#') === 0);
        const isBlob = (url.indexOf('blob:') === 0);
        return !isRelativePath && !isBlob;
    }
}

/**
 * asset server provider
 */
export class AssetProvider extends StorageProvider {

    private _hostname:string;

    constructor() {
        super();

        if(build.Options.assetServer && build.Options.assetServer.hostname) {
            //FIXME: remove potential double //
            this._hostname = build.Options.assetServer.hostname + "/asset/get/";
        } else {
            this._hostname = "http://localhost:1337/asset/get/";
        }
    }

    public forceCrossDomain() : boolean {
        return true;
    }

    public processURL(url:string, basePath:string) : string {

        const isRelativePath = (url.indexOf('#') === 0);
        const isBlob = (url.indexOf('blob:') === 0);

        // create url
        if(isAbsoluteURL(url) || isBlob || isRelativePath) {
            if(isRelativePath) {
                //FIXME: process url through storage?
                //FIXME: encode uri or as component?
                return url.substr(1,url.length);
            } else {
                // replace url
                return encodeURI(url);
            }
        } else {

            //TODO: remove this but shader files are not supported yet.
            //if(AssetManager.baseShaderPath == basePath) {
            //    return basePath + url;
            //}

            return encodeURI(joinPathes([this._hostname, basePath, url]));
        }
    }

    public canUseRevisionTag(url:string) {
        const isRelativePath = (url.indexOf('#') === 0);
        const isBlob = (url.indexOf('blob:') === 0);
        return !isRelativePath && !isBlob;
    }

    public canUseUpdateAccess(url:string) {
        const isRelativePath = (url.indexOf('#') === 0);
        const isBlob = (url.indexOf('blob:') === 0);
        return !isRelativePath && !isBlob;
    }
}

function joinPath(pathA:string, pathB:string) {
    if(pathA.endsWith("/") && pathB.startsWith("/")) {
        return pathA + pathB.substring(1);
    } else {
        return pathA + pathB;
    }
}

function joinPathes(pathes:string[]) {
    let path = "";
    for(let i = 0; i < pathes.length; ++i) {
        path = joinPath(path, pathes[i]);
    }
    return path;
}
