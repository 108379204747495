///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class PrivacySettingsComponent extends AbstractBlockComponent {

    private _updatePrivacySettings:HTMLElement | undefined = undefined;
    private _clearPrivacySettings:HTMLElement | undefined = undefined;
    
    private showKlaro(){
        klaro.show(window["klaroConfig"], true);
    }
    private clearKlaro(){
        klaro.getManager().resetConsents();
        location.reload();
    }

    protected onInit():void {
        this._params = _.defaults(this._params, {});
        this.state = ComponentState.READY;
        this._updatePrivacySettings = this._block.domElement.querySelector(".updatePrivacySettings");
        this._clearPrivacySettings = this._block.domElement.querySelector(".clearPrivacySettings");

        this._updatePrivacySettings.addEventListener("click", this.showKlaro);
        this._clearPrivacySettings.addEventListener("click", this.clearKlaro);
    }

    protected onDestroy():void {
        this._updatePrivacySettings.removeEventListener("click", this.showKlaro);
        this._clearPrivacySettings.removeEventListener("click", this.clearKlaro);
        
        this.state = ComponentState.DESTROYED;

    }
}