/**
 * DirectionalLightComponent.ts: directional light
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { Texture as THREETexture} from "../../lib/threejs/textures/Texture";
import { UnsignedByteType, RGBAFormat, LinearFilter, LinearMipMapLinearFilter, ClampToEdgeWrapping, HalfFloatType, FloatType, RGBFormat,
        CubeReflectionMapping} from "../../lib/threejs/constants";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Box3 } from "../../lib/threejs/math/Box3";
import { WebGLRenderTargetCube } from "../../lib/threejs/renderers/WebGLRenderTargetCube";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { RawShaderMaterial } from "../../lib/threejs/materials/RawShaderMaterial";
import { SphereBufferGeometry } from "../../lib/threejs/geometries/SphereGeometry";
import { MeshBasicMaterial } from "../../lib/threejs/materials/MeshBasicMaterial";
import { BoxBufferGeometry } from "../../lib/threejs/geometries/BoxGeometry";
import { Scene } from "../../lib/threejs/scenes/Scene";
import {GraphicsDisposeSetup, destroyObject3D, cloneObject} from '../core/Globals';
import {EventOneArg} from '../core/Events';
import {Entity} from '../framework/Entity';
import {IONotifier} from '../io/Interfaces';
import {Component, registerComponent, ComponentData, ComponentId} from '../framework/Component';
import {Render} from '../render/Render';
import {ShaderLibrary} from '../render/ShaderLibrary';
import { PBRCubemap, SHLighting, DefaultProbeBoxMax, IReflectionProbe } from "../render/LightProbe";
import { blackTextureCube } from "../render/Texture";
import { defaultRenderLayerMask, ERenderLayer, layerToMask } from "../render/Layers";
import { PhysicalCamera } from "../render/Camera";
import { ShaderVariant } from "../render/Shader";
import { build } from "../core/Build";
import { RenderState } from "../render/State";
import { createWorker, ITypedWorker } from "../core/Async";
import { SHWorker, PixelData } from "./Worker/SHWorker";
import { ESpatialType, querySpatialSystem } from "../framework/SpatialAPI";
import { WorldEnvironment } from "../framework/WorldAPI";
import { createEnvironment } from "../framework/EnvironmentBuilder";

// BUILTIN SHADER (auto import)
import "../render/shader/Prefilter";

/** internal read back data job */
interface ReadBackJob {
    cubeData: ArrayBuffer[];
}

/** default probe map camera exposure setup */
const DefaultReflectionProbeExposure = 10000.0;
const DefaultReflectionProbeWhitepoint = 0.95;
/** boost/reduce SH lighting coefficients */
const DefaultReflectionProbeSHMultiplier = 1.0 * Math.PI;

/**
 * ReflectionProbeComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "ReflectionProbeComponent",
 *     "parameters": {
 *          size: number (cubemap size)
 *          realtime: boolean (realtime update)
 *          debug: boolean (debug output)
 *     }
 * }
 * ~~~~
 */
export class ReflectionProbeComponent extends Component implements IReflectionProbe {

    /** access maximum cubemap texture size */
    public get maxSize() : number {
        if(Render.Main) {
            const gl = Render.Main.webGLRender.getContext();
            return gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE);
        } else {
            return 512;
        }
    }

    /** access current cubemap texture */
    public get cubemap() : THREETexture {
        if(this._ready) {
            return this._renderTarget[this._currentTarget].texture;
        } else {
            return blackTextureCube();
        }
    }

    /** set cubemap size */
    public set size(size:number) {
        if(this._internalSize !== size) {
            this._internalSize = size || this._internalSize;
            this._gpuDirty = true;
        }
    }

    /** enable prefiltering */
    public set prefiltered(value:boolean) {
        if(value !== this._filtered) {
            this._filtered = value;
            this.needsUpdate = true;
        }
    }

    /** enable box projection */
    public set projected(value:boolean) {
        if(value !== this._boxProjected) {
            this._boxProjected = value;
            if(this._debugNode) {
                this._debugOutput(false);
                this._debugOutput(value);
            }
        }
    }

    /** intensity */
    public set intensity(value:number) {
        this._intensity = value;
    }
    public get intensity() {
        return this._intensity;
    }

    /** intensity */
    public set shIntensity(value:number) {
        this._shIntensity = value;
    }
    public get shIntensity() {
        return this._shIntensity;
    }

    /** spherical harmonics */
    public get sphericalHarmonics() : SHLighting {
        return this._shLighting;
    }

    /** do SH lighting calculations */
    public get calculateSHLighting() : boolean {
        return this._doSHLighting;
    }
    public set calculateSHLighting(value:boolean) {
        if(this._doSHLighting !== value) {
            this._doSHLighting = value;
            if(value) {
                this.needsUpdate = true;
            }
        }
    }

    /** render objects */
    public set renderObjects(value:boolean) {
        if(this._renderObjects !== value) {
            this._renderObjects = value;
            this._needsUpdate = 2;
            if(this._renderObjects && this._renderLayers) {
                this._setLayerMask(this._renderLayers | defaultRenderLayerMask());
            }
        }
    }
    public get renderObjects() {
        return this._renderObjects;
    }

    /** intensity */
    public set exposure(value:number) {
        this._cameraExposure = value;
    }
    public get exposure() {
        return this._cameraExposure;
    }

    /** box projection bounding box */
    public set bounds(value:Vector3) {
        this._envBoxDimension.copy(value);
        this.updateWorldBounds();
    }
    public get bounds() : Vector3 {
        return this._envBoxDimension;
    }
    /** box projection world bounding box */
    public get worldBounds() : Box3 {
        return this._envBoxWorld;
    }

    /** show helper */
    public set showHelper(value:boolean) {
        this._debugOutput(value);
    }

    /** show helper size */
    public set showHelperSize(value:number) {
        this._debugOutput(true, value);
    }

    /** show helper mip level */
    public set showHelperLod(value:number) {
        this._debugLod = value;
        if(this._debugNode) {
            this._debugOutput(true);
        }
    }

    /** set to realtime update */
    public set realtime(value:boolean) {
        if(this._realtimeUpdate !== value) {
            this._realtimeUpdate = value;
            this._needsUpdate = Infinity;
        }
    }

    public get realtime() {
        return this._realtimeUpdate;
    }

    /** set update cubemap */
    public set needsUpdate(value:boolean) {
        if(!this._realtimeUpdate) {
            this._needsUpdate = value ? 2 : this._needsUpdate;
        }
    }

    /** callback when updated  */
    public get OnUpdated() {
        return this._onUpdated;
    }

    /** render layer */
    public get renderLayer() : number|undefined {
        return this._renderLayers;
    }
    public set renderLayer(value:number) {
        this._setLayerMask(value);
    }

    /** access current update target */
    private get _updateTarget() {
        return this._renderTarget[this._currentTarget ^ 1];
    }

    /** needs update */
    private _needsUpdate:number;
    /** realtime updating */
    private _realtimeUpdate:boolean;
    /** filter probe */
    private _filtered:boolean;
    /** is box projected */
    private _boxProjected:boolean;
    /** has data to provide */
    private _ready:boolean;
    /** render target dirty */
    private _gpuDirty:boolean;
    /** cubemap cameras */
    private _cubemapCams:PhysicalCamera[];
    /** render targets */
    private _renderTarget:WebGLRenderTargetCube[];
    /** current active render target */
    private _currentTarget:number;
    /** spherical harmonics */
    private _shLighting: SHLighting;
    /** calculate spherical harmonics */
    private _doSHLighting:boolean;
    /** environment box (local) */
    private _envBoxDimension:Vector3;
    private _envBoxWorld:Box3;
    /** static environment */
    private _staticEnvironment:WorldEnvironment|undefined;
    /** internal target size */
    private _internalSize:number;
    private _internalDebug:boolean;
    /** render objects on probe */
    private _renderObjects:boolean;
    /** runtime render state */
    private _renderState:RenderState;
    /** set layers to draw */
    private _renderLayers:number;
    /** lighting intensity (IBL) */
    private _intensity:number;
    /** lighting intensity (SH) */
    private _shIntensity:number;
    /** camera exposure */
    private _cameraExposure:number;
    /** update notification */
    private _onUpdated:EventOneArg<any> = new EventOneArg<any>();
    /** debug data */
    private _debugNode:Object3D;
    private _debugLod:number;
    /** spatial id for probe */
    private _spatialId:ComponentId;

    /** active read back job */
    private _cpuCubemapData:ReadBackJob;
    private _shWorker:ITypedWorker<PixelData, any> = null;

    /** construct */
    constructor(entity:Entity) {
        super(entity);
        this.needsRender = true;
        this._renderState = new RenderState();
        this._filtered = false;
        this._boxProjected = false;
        this._internalSize = 64;
        this._cubemapCams = [];
        this._renderTarget = [];
        this._currentTarget = 0;
        this._envBoxDimension = new Vector3(DefaultProbeBoxMax[0],DefaultProbeBoxMax[1],DefaultProbeBoxMax[2]);
        this._envBoxWorld = new Box3();
        this._envBoxWorld.min.copy(this._envBoxDimension).multiplyScalar(-0.5);
        this._envBoxWorld.max.copy(this._envBoxDimension).multiplyScalar(0.5);
        this._needsUpdate = 2; // 2 for rendering both probes
        this._realtimeUpdate = false;
        this._internalDebug = false;
        this._renderObjects = true;
        this._doSHLighting = true;
        this._renderLayers = 0;
        this._spatialId = 0;
        this._ready = false;
        this._gpuDirty = true;
        this._cameraExposure = 1.0;
        this._intensity = 10000.0;
        this._shIntensity = 10000.0;

        this._shLighting = null;
        this._cpuCubemapData = undefined;

        this._debugLod = 0.0;
        this._debugNode = null;
    }

    /** cleanup */
    public destroy(dispose?:GraphicsDisposeSetup) {

        if(this._shWorker) {
            this._shWorker.terminate();
            this._shWorker = null;
        }

        // free graphics data
        for(const target of this._renderTarget) {
            target.dispose();
        }

        this._renderTarget = [];

        querySpatialSystem().removeObject(this._spatialId);

        super.destroy(dispose);
    }

    /** force SH lighting update */
    public updateSHLighting() {
        this._shLighting = undefined;
        this.needsUpdate = true;
    }

    /** set static environment probe */
    public setStaticProbe(envMap:string) {

        // cleanup old environment setup
        if(this._staticEnvironment) {
            if(this._staticEnvironment.backgroundScene) {
                // free all objects from scene
                destroyObject3D(this._staticEnvironment.backgroundScene);
                // clear references directly
                this._staticEnvironment.backgroundScene = null;
                this._staticEnvironment.backgroundMesh = null;
            }
            this._staticEnvironment = undefined;
        }

        // precreate environment scene
        const _envScene = new Scene();
        _envScene.name = "environment_root";
        _envScene['_world'] = this.world;

        createEnvironment({envMap}, _envScene, null, this._staticEnvironment).then( (env) => {

            // apply and re-render
            this._staticEnvironment = env;
            this.needsUpdate = true;

        },
        (err) => {
            // handle error
            this._staticEnvironment = undefined;
            console.warn("ReflectionProbeComponent: invalid environment texture ", envMap);
        });
    }

    /**
     * update cubemaps when transformed
     */
    public onTransformUpdate() {
        this.updateWorldBounds();

        if(this._spatialId) {
            querySpatialSystem().updateTransform(this._spatialId, this.entity.position);
        }

        this.needsUpdate = true;
    }

    /**
     * update world boundings of parallax cubemap
     * CALL this when you have changed the local boundings
     */
    public updateWorldBounds() {
        // recalculate world bounding box
        const worldPosition = this.entity.positionWorld;
        // convert to world coordinates
        this._envBoxWorld.min.copy(this._envBoxDimension).multiplyScalar(-0.5).add(worldPosition);
        this._envBoxWorld.max.copy(this._envBoxDimension).multiplyScalar(0.5).add(worldPosition);
    }

    /** render cubemap */
    public preRender(render:Render) {

        // check dirty flags
        if(this._renderTarget.length === 0 || this._gpuDirty) {
            this._initTarget(render);
            this._gpuDirty = false;
        }

        // check for update flag
        if(!this._needsUpdate && !this.world.isFrameDirty()) {
            return;
        }
        this._initCamera();

        // update global probe reference
        //this._ready = false;

        // get update target and render to it
        const renderTarget = this._updateTarget;
        this._renderState.renderTarget = renderTarget;

        // read data when no sh lighting is available to create data
        // or force to update sh lighting
        if(this._needsSHUpdate(this.world.isFrameDirty())) {
            this._cpuCubemapData = {
                cubeData: []
            };
        }

        // render cube faces
        for(let i = 0; i < 6; ++i) {
            // mip map at last render call
            if(i === 0) {
                renderTarget.texture.generateMipmaps = false;
            } else if(i === 5) {
                renderTarget.texture.generateMipmaps = true;
            }

            // set current render target binding
            this._renderState.renderTargetBind.activeCubeFace = i;

            //renderTarget.activeCubeFace = i;
            // FIXME: render world with environment
            this._entityRef.world.renderWorld(render, this._cubemapCams[i], this._renderState, this._staticEnvironment);

            // read back data for processing
            if(this._cpuCubemapData) {
                this._readCubemapData(render, renderTarget, i);
            }
        }

        //FIXME: reset?!
        render.webGLRender.setRenderTarget(null);

        // process cpu data
        if(this._cpuCubemapData) {
            // send to web worker for sh calculations...
            this._shLighting = this._shLighting || {
                compact: {
                    cAr: [0,0,0,0],
                    cAg: [0,0,0,0],
                    cAb: [0,0,0,0],
                    cBr: [0,0,0,0],
                    cBg: [0,0,0,0],
                    cBb: [0,0,0,0],
                    cC:  [0,0,0,0],
                },
                sh: []
            };
            // check format and send to web worker
            let type:0|1 = 0;
            if(renderTarget.texture.type === UnsignedByteType) {
                type = 0;
            } else {
                type = 1;
            }
            this._webWorker_SHLighting(renderTarget.width, renderTarget.height, type);

            // finished
            this._cpuCubemapData = undefined;
        }

        // add filtering (karis)
        if(this._filtered) {
            const filter = PBRCubemap.get(render);
            const result = filter.copy(renderTarget);
            console.assert(result === renderTarget, "mismatch");
        }

        // toggle read / write cubemap
        this._currentTarget = this._currentTarget ^ 1;

        // update global probe reference
        this._ready = true;

        //
        this._onUpdated.trigger(renderTarget.texture);

        // setup update flag
        if(!this._realtimeUpdate) {
            this._needsUpdate = Math.max(0, this._needsUpdate - 1);
        }

        if(this._internalDebug) {
            this._debugOutput(true);
        }
    }

    private _setLayerMask(layers:number) {
        this._renderLayers = layers;
        this._initCamera();
    }

    private _initTarget(render?:Render) {
        render = render || Render.Main;

        if(this._renderTarget.length === 0) {
            this._renderTarget.length = 2;
        }

        const options = {
            type: UnsignedByteType,
            format: RGBAFormat,
            magFilter: LinearFilter,
            minFilter: LinearMipMapLinearFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            generateMipmaps: true
        };

        // support SH read back
        const readBack = true;

        // support float textures (use them)
        if(render.capabilities.halfFloatTextures && render.capabilities.halfFloatRenderable && !readBack) {
            options.type = HalfFloatType;
            //options.format = RGBFormat;
            // using RGBA because chrome does not like RGB format
            options.format = RGBAFormat;
        } else if(render.capabilities.floatTextures && render.capabilities.floatRenderable) {
            options.type = FloatType;
            //options.format = RGBFormat;
            // using RGBA because chrome does not like RGB format
            options.format = RGBAFormat;
        }

        // no ibl filtering support for float
        if(this._filtered) {
            options.type = UnsignedByteType;
            options.format = RGBFormat;
        }

        if(!this._renderTarget[0]) {
            this._internalSize = Math.min(this._internalSize, this.maxSize);
            this._renderTarget[0] = new WebGLRenderTargetCube(this._internalSize, this._internalSize, options);
            this._renderTarget[0].texture.name = "CubeCamera";
            this._renderTarget[0].texture.mapping = CubeReflectionMapping;
            this._renderTarget[0].texture.image = {
                width: this._internalSize,
                height: this._internalSize
            };
        } else {
            this._internalSize = Math.min(this._internalSize, this.maxSize);
            this._renderTarget[0].setSize(this._internalSize, this._internalSize);
            this._renderTarget[0].texture.image = {
                width: this._internalSize,
                height: this._internalSize
            };
        }
        if(!this._renderTarget[1]) {
            this._internalSize = Math.min(this._internalSize, this.maxSize);
            this._renderTarget[1] = new WebGLRenderTargetCube(this._internalSize, this._internalSize, options);
            this._renderTarget[1].texture.name = "CubeCamera2";
            this._renderTarget[1].texture.mapping = CubeReflectionMapping;
            this._renderTarget[1].texture.image = {
                width: this._internalSize,
                height: this._internalSize
            };
        } else {
            this._internalSize = Math.min(this._internalSize, this.maxSize);
            this._renderTarget[1].setSize(this._internalSize, this._internalSize);
            this._renderTarget[1].texture.image = {
                width: this._internalSize,
                height: this._internalSize
            };
        }

        if(!this._spatialId) {
            this._spatialId = querySpatialSystem().registerObject(this, this.entity.position, ESpatialType.PROBE);
        }
    }

    private _initCamera() {
        const near = 0.1; const far = 100000;
        const fov = 90.0; const aspect = 1;

        if(this._cubemapCams.length === 0) {
            //positive x
            const cameraPX = new PhysicalCamera( fov, aspect, near, far );
            cameraPX.name = "CubeCamera";
            this._cubemapCams.push( cameraPX );

            //negative x
            const cameraNX = new PhysicalCamera( fov, aspect, near, far );
            cameraNX.name = "CubeCamera";
            this._cubemapCams.push( cameraNX );

            //positive y
            const cameraPY = new PhysicalCamera( fov, aspect, near, far );
            cameraPY.name = "CubeCamera";
            this._cubemapCams.push( cameraPY );

            //negative y
            const cameraNY = new PhysicalCamera( fov, aspect, near, far );
            cameraNY.name = "CubeCamera";
            this._cubemapCams.push( cameraNY );

            //positive z
            const cameraPZ = new PhysicalCamera( fov, aspect, near, far );
            cameraPZ.name = "CubeCamera";
            this._cubemapCams.push( cameraPZ );

            //negative z
            const cameraNZ = new PhysicalCamera( fov, aspect, near, far );
            cameraNZ.name = "CubeCamera";
            this._cubemapCams.push( cameraNZ );
        }

        let i = 0;
        for(const cam of this._cubemapCams) {
            const worldPos = this.entity.positionWorld;

            // update projection setup
            cam.aspect = aspect;
            cam.near = near;
            cam.far = far;
            cam.fov = fov;
            cam.updateProjectionMatrix();

            // update exposure (default exposure setup for camera)
            cam.exposure = this._cameraExposure * 1.0 / DefaultReflectionProbeExposure;
            cam.whitepoint = DefaultReflectionProbeWhitepoint;

            // render IBL type
            this._renderState.overrideShaderVariant = ShaderVariant.IBL;

            // set layer mask
            if(this._renderLayers) {
                cam.layers.mask = this._renderLayers;
            } else if(this._renderObjects) {
                // render everything
                cam.layers.mask = defaultRenderLayerMask();
            } else {
                // only background rendering
                cam.layers.mask = layerToMask(ERenderLayer.Background);
            }

            cam.up.copy(reflection_probe_rendering.views[i].up);
            cam.position.copy(worldPos);
            cam.lookAt(worldPos.add(reflection_probe_rendering.views[i].lookAt));
            cam.updateMatrixWorld(false);
            // next camera
            i++;
        }

    }

    /** debugging output */
    private _debugOutput(show:boolean, size?:number, force?:boolean) {
        if(show) {
            if(this._debugNode) {

                const mesh = this._debugNode.children[0] as THREEMesh;

                if(size) {
                    mesh.scale.set(size || 0.1, size || 0.1, size || 0.1);
                }
                const materialInstance = mesh.material as RawShaderMaterial;
                materialInstance.uniforms.envMap.value = this.cubemap;
                materialInstance.uniforms.mipLevel.value = this._debugLod;

            } else {
                const geometry = new SphereBufferGeometry(10, 32, 32, Math.PI);
                const template = {
                    shader: "redEnvMapCubeDebug"
                };

                const debugMaterial = ShaderLibrary.createMaterialShader("create_envmap_roughness_debug", template, {});
                debugMaterial.uniforms.envMap.value = this.cubemap;
                debugMaterial.uniforms.mipLevel.value = this._debugLod;

                this._debugNode = new Object3D();

                const mesh = new THREEMesh( geometry, debugMaterial );
                mesh.scale.set(size || 0.1, size || 0.1, size || 0.1);
                mesh.name = "reflection_probe_debug";
                mesh.layers.set(ERenderLayer.Debug);
                this._debugNode.add(mesh);

                if(this._boxProjected) {
                    const boxGeometry = new BoxBufferGeometry(this._envBoxDimension.x, this._envBoxDimension.y, this._envBoxDimension.z);
                    const boxMaterial = new MeshBasicMaterial( {color: 0xFFFFFFFF, wireframe: true});
                    const boxMesh = new THREEMesh( boxGeometry, boxMaterial );
                    boxMesh.name = "reflection_probe_world_bounds";
                    boxMesh.layers.set(31);
                    this._debugNode.add(boxMesh);
                }

                this.entity.add(this._debugNode);
            }

            // force update
            if(force) {
                this.needsUpdate = force;
            }

        } else {
            if(this._debugNode) {
                this._debugNode.parent.remove(this._debugNode);
                destroyObject3D(this._debugNode);
            }
            this._debugNode = null;
        }
        this._internalDebug = show;
    }

    private _needsSHUpdate(frameDirty:boolean) : boolean {
        if(!this._doSHLighting) {
            return false;
        }
        // directly when realtime update
        if(this._realtimeUpdate) {
            return !this._shLighting;
        } else {
            // done when tick tock ready
            //return this._needsUpdate === 1;
            // done when shLighting is filled
            return !this._shLighting || this._needsUpdate === 1; // || frameDirty;
        }
    }

    public load(data:ComponentData, ioNotifier?:IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        this.needsRender = true;
        this._ready = false;
        this._renderState = new RenderState();
        this._internalSize = data.parameters.size || 64;
        this._cubemapCams = [];
        this._renderTarget = [];
        this._currentTarget = 0;
        this._shLighting = undefined;

        this._realtimeUpdate = data.parameters.realtime || false;
        this._internalDebug = build.Options.isEditor || false;
        this._intensity = data.parameters.intensity || 30000.0;
        this._shIntensity = data.parameters.shIntensity || this._intensity;
        this._renderLayers = data.parameters.layers || 0;
        this._cameraExposure = data.parameters.cameraExposure || 1.0;

        if(data.parameters.bounds) {
            if(data.parameters.bounds.min) {
                // OLD FILE FORMAT
                this._envBoxDimension.set(data.parameters.bounds.max[0] - data.parameters.bounds.min[0],
                                          data.parameters.bounds.max[1] - data.parameters.bounds.min[1],
                                          data.parameters.bounds.max[2] - data.parameters.bounds.min[2]);
            } else {
                this._envBoxDimension.fromArray(data.parameters.bounds);
            }
        } else {
            this._envBoxDimension.fromArray(DefaultProbeBoxMax);
        }

        // render objects
        if(data.parameters.renderObjects) {
            this._renderObjects = true;
        } else {
            this._renderObjects = false;
        }

        // prefiltered
        if(data.parameters.prefiltered) {
            this.prefiltered = true;
        } else {
            this.prefiltered = false;
        }

        if(data.parameters.boxProjected) {
            this.projected = true;
        } else {
            this.projected = false;
        }

        // spherical harmonics
        if(data.parameters.calculateSHLighting !== undefined) {
            this._doSHLighting = data.parameters.calculateSHLighting;
        } else {
            this._doSHLighting = true;
        }

        if(data.parameters.sh) {
            this._shLighting = cloneObject(data.parameters.sh) as SHLighting;
        }

        this._debugOutput(this._internalDebug);

        this.needsUpdate = true;
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "ReflectionProbeComponent",
            parameters: {
                size: this._internalSize,
                intensity: this._intensity,
                shIntensity: this._shIntensity,
                prefiltered: this._filtered,
                boxProjected: this._boxProjected,
                bounds: [this._envBoxDimension.x, this._envBoxDimension.y, this._envBoxDimension.z],
                sh: this._shLighting || {
                    compact: {
                        cAr: [0,0,0,0],
                        cAg: [0,0,0,0],
                        cAb: [0,0,0,0],
                        cBr: [0,0,0,0],
                        cBg: [0,0,0,0],
                        cBb: [0,0,0,0],
                        cC:  [0,0,0,1],
                    },
                    sh: []
                },
                calculateSHLighting: this._doSHLighting,
                renderObjects: this._renderObjects,
                realtime: this._realtimeUpdate,
                cameraExposure: this._cameraExposure
            }
        };

        return node;
    }

    /**
     * read pixel data from cubemap
     * @param render render device
     * @param renderTarget cubemap rendertarget
     * @param cubeFace current face
     */
    private _readCubemapData(render:Render, renderTarget:WebGLRenderTargetCube, cubeFace:number) {
        if(!this._cpuCubemapData) {
            return;
        }

        // READ CUBE FACE AND SAVE to buffer
        let pixelBuffer:Uint8Array|Float32Array = null;
        //render.webGLRender.readRenderTargetPixels(renderTarget, 0, 0, renderTarget.width, renderTarget.height, pixelBuffer);

        const gl = render.webGLRender.context;
        let glType = 0;
        if(renderTarget.texture.type === UnsignedByteType) {
            glType = gl.UNSIGNED_BYTE;
            pixelBuffer = new Uint8Array(new ArrayBuffer(4 * renderTarget.width * renderTarget.height));
        } else {
            glType = gl.FLOAT;
            pixelBuffer = new Float32Array(new ArrayBuffer(4 * 4 * renderTarget.width * renderTarget.height));
        }

        //TODO: readRenderTargetPixels do not support cube map
        gl.readPixels(0, 0, renderTarget.width, renderTarget.height, gl.RGBA, glType, pixelBuffer );

        this._cpuCubemapData.cubeData[cubeFace] = pixelBuffer.buffer;
    }

    /**
     * run web worker for spherical harmonics calculation
     * @param width width of cube face
     * @param height height of cube face
     * @param type data type of cubemap
     */
    private _webWorker_SHLighting(width:number, height:number, type:0|1) {

        if(!this._cpuCubemapData) {
            return;
        }

        // SH WORKER
        const pixelData:PixelData = {
            components: 4,
            width: width,
            height: height,
            type: type,
            data: null,
            cube: this._cpuCubemapData.cubeData,
            multiplier: DefaultReflectionProbeSHMultiplier
        };

        if(!this._shWorker) {
            this._shWorker = createWorker(SHWorker, (result:SHLighting) => {
                if(result.compact) {
                    this._shLighting = result;

                    //this._createSHDebug();
                    //AUTO CLOSE
                    // this._shWorker.terminate();
                    // this._shWorker = null;
                }
            });
        }

        this._shWorker.postMessage(pixelData, this._cpuCubemapData.cubeData);

        // no more access to it
        this._cpuCubemapData.cubeData = null;
    }

    /**
     * show sh equirectangular texture
     */
    private _createSHDebug() {
        const x1 = new Vector3();
        const x2 = new Vector3();
        const x3 = new Vector3();

        function dot_v4(a:number[], b:number[]) {
            return a[0] * b[0] + a[1] * b[1] + a[2] * b[2] + a[3] * b[3];
        }

        function vec3_normalize(out:number[], a:number[]) : number[] {
            const x = a[0]; const y = a[1]; const z = a[2];
            let len = x*x + y*y + z*z;
            if (len > 0) {
                //TODO: evaluate use of glm_invsqrt here?
                len = 1 / Math.sqrt(len);
                out[0] = a[0] * len;
                out[1] = a[1] * len;
                out[2] = a[2] * len;
            }
            return out;
        }

        const shaderIrradiance = (normal) => {
            x1.x = dot_v4(this._shLighting.compact.cAr, normal);
            x1.y = dot_v4(this._shLighting.compact.cAg, normal);
            x1.z = dot_v4(this._shLighting.compact.cAb, normal);

            const vB = [normal[0] * normal[1], normal[1] * normal[2], normal[2] * normal[2], normal[2] * normal[0]];
            x2.x = dot_v4(this._shLighting.compact.cBr, vB);
            x2.y = dot_v4(this._shLighting.compact.cBg, vB);
            x2.z = dot_v4(this._shLighting.compact.cBb, vB);

            const vC = normal[0] * normal[0] - normal[1] * normal[1];
            x3.x = this._shLighting.compact.cC[0] * vC;
            x3.y = this._shLighting.compact.cC[1] * vC;
            x3.z = this._shLighting.compact.cC[2] * vC;

            return [x1.x+x2.x+x3.x, x1.y+x2.y+x3.y, x1.z+x2.z+x3.z];
        };

        // Create a 2D canvas to store the result
        const canvas = document.createElement('canvas');
        canvas.width = 1024;
        canvas.height = 512;
        const context = canvas.getContext('2d');
        const dir = [0, 0, 0, 1.0];
        for(let y = 0; y < 512; ++y) {
            for(let x = 0; x < 1024; ++x) {

                const fX = 2 * x / 1024 - 1;
                const fY = 2 * y / 512 - 1;

                const theta = fX * Math.PI;
                const phi = fY * Math.PI * 0.5;

                dir[0] = Math.cos(phi) * Math.cos(theta);
                dir[1] = Math.sin(phi);
                dir[2] = Math.cos(phi) * Math.sin(theta);

                vec3_normalize(dir, dir);

                // js way
                const flipNormal = 1.0;
                const RECIPROCAL_PI2 = 0.15915494;

                let u = Math.atan2( flipNormal * dir[2], flipNormal * dir[0] ) * RECIPROCAL_PI2 + 0.5;
                let v = Math.min( 1.0, Math.max( 0.0, flipNormal * dir[1] * 0.5 + 0.5 ));

                u = Math.min(1.0, Math.max(0.0, u));
                //FIXME: flip y coordinates?
                v = 1.0 - Math.min(1.0, Math.max(0.0, v));

                const px = Math.floor(u * 1024);
                const py = Math.floor(v * 512);

                const pixelColor = shaderIrradiance(dir);

                // const r = Math.floor(pixelColor[0] * 255 / Math.PI);
                // const g = Math.floor(pixelColor[1] * 255 / Math.PI);
                // const b = Math.floor(pixelColor[2] * 255 / Math.PI);
                const r = Math.floor(pixelColor[0] * 255);
                const g = Math.floor(pixelColor[1] * 255);
                const b = Math.floor(pixelColor[2] * 255);

                context.fillStyle = "rgba("+r+","+g+","+b+",1.0)";
                context.fillRect(x, 511 - y, 1, 1);

            }
        }
        // show
        const window2 = window.open("", "_blank");
        window2.document.write('<img src="'+canvas.toDataURL()+'"/>');
    }
}

/** register component */
registerComponent("RED", "ReflectionProbeComponent", ReflectionProbeComponent);

namespace reflection_probe_rendering {

    export const views = [
        { lookAt: new Vector3( 1, 0, 0), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3(-1, 0, 0), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3( 0, 1, 0), up: new Vector3(0, 0, 1) },
        { lookAt: new Vector3( 0,-1, 0), up: new Vector3(0, 0, -1) },
        { lookAt: new Vector3( 0, 0, 1), up: new Vector3(0, -1, 0) },
        { lookAt: new Vector3( 0, 0,-1), up: new Vector3(0, -1, 0) },
    ];
}
