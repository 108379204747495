/**
 * SSAO.ts: fullscreen fxaa shader
 *
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector2 } from "../../../lib/threejs/math/Vector2";
import { ShaderLibrary } from '../ShaderLibrary';
import { EUniformType } from "../Uniforms";
import { ShaderModule, ShaderChunk } from "../ShaderBuilder";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function() {

    //TODO: add transparency support
    ShaderLibrary.CustomShaderLib['redSSAO'] = {
        redSettings: {
            lights: false,
            fog: false,
            depthTest: false,
            depthWrite: false
        },
        uniforms:
        {
            tDiffuse: { type: EUniformType.TEXTURE, value: null },
            tDepth: { type: EUniformType.TEXTURE, value: null },
            size: { type: EUniformType.VECTOR2, value: new Vector2( 512, 512 ) },
            cameraNear: { type: EUniformType.FLOAT, value: 1 },
            cameraFar: { type: EUniformType.FLOAT, value: 100 },
            onlyAO: { type: EUniformType.INTEGER, value: 0 },
            aoClamp: { type: EUniformType.FLOAT, value: 0.5 },
            lumInfluence: { type: EUniformType.FLOAT, value: 0.5 }
        },
        vertexShader: ShaderChunk["redSSAO_Vertex"],
        fragmentShader: ShaderChunk["redSSAO_Pixel"]

    };

}, ["redPacking", "redSSAO_Vertex", "redSSAO_Pixel"]);
