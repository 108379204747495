/**
 * Build.ts: Build settings
 *
 * [[include:sourceDoc/Build.md]]
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import {mergeObject} from './Globals';

/**
 * [[include:sourceDoc/Build.md]]
 */
export namespace build {

    /** builtin libraries support */
    export interface LibraryOptions {
        available:boolean;
        options?:{[key:string]:any};
    }

    /** asset server options declaration */
    export interface AssetServerOptions {
        hostname:string;
    }

    /** data cache options declaration */
    export interface DataCacheOptions {
        /** runtime caching */
        useRuntimeCache:boolean;
        /** @see EDataCacheMode */
        mode: "inactive" | "readonly" | "writeonly" | "readwrite" | "offline";
        /** indexedDB settings */
        indexedDB: {
            database:string;
            version:number;
        };
    }

    /** editor settings */
    export interface EditorOptions {
        /** automatically import objects from mesh */
        autoImportMaterials: boolean;
        /** custom host name */
        hostname: string;
    }

    /** shader library options */
    export interface ShaderLibraryOptions {
        basePath:string;
        useShaderBundles:boolean;
        bundleFilename:string;
    }

    /** build options declaration */
    export interface BuildOptions {
        /** development build */
        development:boolean;
        /** editor or assetmanager build */
        isEditor:boolean;
        /** unittest build */
        isUnittest:boolean;
        /** use plugin system */
        usePluginSystem: boolean;
        /** use asset server */
        useAssetServer:boolean;
        /** asset server config */
        assetServer:AssetServerOptions;
        /** data cache settings */
        dataCache:DataCacheOptions;
        /** editor config */
        editor:EditorOptions;
        /** debvug output application */
        debugApplicationOutput:boolean;
        /** debug output rendering */
        debugRenderOutput:boolean;
        /** debug output assets */
        debugAssetOutput:boolean;
        /** material library output */
        debugMaterialOutput:boolean;
        /** shader library output */
        debugShaderOutput:boolean;
        /** model debug output */
        debugModelOutput:boolean;
        /** world debug output */
        debugWorldOutput:boolean;
        /** animation debug output */
        debugAnimationOutput:boolean;
        /** is playing */
        playing:boolean;
        /** has game loop */
        gameLoop:boolean;
        /** revision number/tag */
        revision:string;
        /** base pathes for build */
        baseTextPath:string;
        baseTexturePath:string;
        baseMeshPath:string;
        baseShaderPath:string; //DEPRECATED
        /** support for integrated libraries */
        libraries: {
            rStats:LibraryOptions;
            TWEEN:LibraryOptions;
            JSZip:LibraryOptions;
        };
        /** shaders */
        shaderLibrary:ShaderLibraryOptions;
        /** allow access any value */
        [key:string]:any;
    }

    /**
     * access build options
     */
    export let Options:BuildOptions = {
        development:false,
        isEditor:false,
        isUnittest:false,
        usePluginSystem:false,
        useAssetServer:false,
        assetServer: {
            hostname: "http://localhost:1337",
        },
        dataCache: {
            useRuntimeCache: false,
            mode: "inactive",
            indexedDB: {
                database: "redTyped_database",
                version: 1
            }
        },
        editor: {
            autoImportMaterials: false,
            hostname: null
        },
        debugApplicationOutput:false,
        debugRenderOutput:false,
        debugAssetOutput:false,
        debugMaterialOutput:false,
        debugShaderOutput:false,
        debugModelOutput:false,
        debugWorldOutput:false,
        debugAnimationOutput:false,
        playing:true,
        gameLoop: true,
        revision: "1001",
        baseTextPath: null,
        baseTexturePath: null,
        baseMeshPath: null,
        baseShaderPath: null,
        libraries: {
            rStats: { available: false },
            TWEEN: { available: false },
            JSZip: { available: false }
        },
        shaderLibrary: {
            basePath: null,
            bundleFilename: null,
            useShaderBundles: false
        }
    };

    /** first time init stuff */
    let initBuildFirstTime:boolean = false;

    /**
     * load configuration from builtin
     */
    export function _initBuildSettings(force?:boolean) {
        if(typeof window !== 'undefined' && typeof window.REDBUILD !== 'undefined') {
            Options = mergeObject(Options, window.REDBUILD.Options);
        }

        if(!initBuildFirstTime || force) {
            // libraries
            if(typeof rStats !== 'undefined') {
                Options.libraries.rStats.available = true;
            } else {
                Options.libraries.rStats.available = false;
            }

            if(typeof TWEEN !== 'undefined') {
                Options.libraries.TWEEN.available = true;
            } else {
                Options.libraries.TWEEN.available = false;
            }

            if(typeof JSZip !== 'undefined') {
                Options.libraries.JSZip.available = true;
            } else {
                Options.libraries.JSZip.available = false;
            }

            if(Options.development) {
                console.info("Build: loaded third party frameworks: ", Object.keys(Options.libraries).filter( (value) => {
                    return Options.libraries[value].available === true;
                }));
            }
        }

        initBuildFirstTime = true;
    }
}

// load build configuration at startup
build._initBuildSettings();
