

import { AppDelegate } from 'redTyped/framework/AppDelegate';
import { appDestroy, appInit } from 'redTyped/framework/App';
import { Platform } from 'redTyped/core/Platform';

// import {Fliptable3D} from '../../3d/Fliptable';
import {Hexagon3D} from '../../3d/hexagon/Hexagon';
import {Hexagon3D_Top} from '../../3d/hexagon/Hexagon_Top';
import {Backpack3D} from '../../3d/backpack/Backpack';
// import {Particle3D} from '../../3d/particle/Particle';
// import {LHApp} from '../../3d/lh/lufthansa';
// import {FlixApp} from '../../3d/flix/flix';

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

// load all builtin shader
import "redTyped/render/BuiltinShader";


export class SceneComponent extends AbstractBlockComponent {

    private _delegate:AppDelegate;

    protected onInit():void {

        //check webgl compatiblity
        let webGLContainer = this._block.domElement.querySelector(".scene_content");

        if(this._supportsWebGL()) {
            this._block.domElement.classList.add("supported");
            this._onWebGLContent();
        } else {
            this._block.domElement.classList.add("unsupported");
            this._onFallback();
        }
    }

    protected onDestroy():void{
        if(this._delegate) {
            appDestroy(this._delegate);
        }
        this._delegate = null;
        this.state = ComponentState.DESTROYED;
    }

    private _supportsWebGL() : boolean {
        //TODO: add mobile ???
        return Platform.get().supportWebGL && !Platform.get().isIEBrowser;
    }

    private _onWebGLContent() : void {

        // attach dom element to app3d
        let sceneContainer = this._block.domElement.querySelector(".scene_content");


        let appDelegate = null;

        switch(this._params['appName']) {
            case "Fliptable":
                //appDelegate = new Fliptable3D(sceneContainer, this._block, this._params, false);
                break;
            case "Fliptable_Header":
                //appDelegate = new Fliptable3D(sceneContainer, this._block, this._params, true);
                break;
            case "Hexagon":
                appDelegate = new Hexagon3D(sceneContainer, this._block, this._params);
                break;
            case "Hexagon_Top":
                appDelegate = new Hexagon3D_Top(sceneContainer, this._block, this._params);
                break;
            case "Particle":
                //appDelegate = new Particle3D(sceneContainer);
                break;
            case "Backpack":
                appDelegate = new Backpack3D(sceneContainer, this._block, this._params);
                break;
            case "Pen":
                appDelegate = new Backpack3D(sceneContainer, this._block, this._params);
                break;
            case "Vans":
                appDelegate = new Backpack3D(sceneContainer, this._block, this._params);
                break;
            case "Lufthansa":
                //appDelegate = new LHApp(sceneContainer, this._block);
                break;
            case "Flix":
                //appDelegate = new FlixApp(sceneContainer, this._block);
                break;
            default:
                break;
        }

        // init app
        if(appDelegate) {
            //console.log("3D Start");
            appInit(appDelegate);

            this._delegate = appDelegate;
        }

        this.state = ComponentState.READY;
    }

    private _onFallback() : void {

        let webGLHTML = this._block.domElement.querySelector(".scene_html");
        //base64 decode --> https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
        webGLHTML.innerHTML = decodeURIComponent(Array.prototype.map.call(atob(this._params['fallbackContent']), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        // remove webgl container
        let webGLContainer = this._block.domElement.querySelector(".scene_content");
        if(webGLContainer) {
            webGLContainer.remove();
        }

        this.state = ComponentState.READY;
    }

}