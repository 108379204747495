import { AsyncLoad } from "./AsyncLoad";
import { AssetInfo } from "./AssetInfo";

/**
 * Interfaces.ts: IO interfaces
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */

export type FileLoadCallback = (result:any) => void;
export type FileProgressCallback = (event:ProgressEvent) => void;
export type FileErrorCallback = (err:any) => void;

/**
 * interface for loading notifications
 */
export interface IONotifier {
    /** notify io that loading is started */
    startLoading() : void;
    /** notify io that loading is finished */
    finishLoading(err?:Error) : void;
}

/** attach promise async to notifier */
export function attachAsyncToNotifier(notifier:IONotifier, load:any) {
    notifier.startLoading();
    load.then(() => notifier.finishLoading(), notifier.finishLoading);
}

/**
 * asset type definition
 */
export enum EAssetType {
    Unknown = -1,
    Auto = 0,
    Text = 1,
    Image = 2,
    Model = 3
}

interface LoaderEntry {
    identifier:string;
    extension:string;
    type:EAssetType | string;
    class:any;
    load:(url:string) => AsyncLoad<any>;
    fileSize?:(asset:AssetInfo, assets:{[key:string]:AssetInfo}) => number;
}

/** load resolver list */
const loaderClasses:LoaderEntry[] = [];

/**
 * @param type file type
 */
export function resolveLoad(type:string) {
    for(const loader of loaderClasses) {
        if(loader.load && loader.type === type) {
            return loader.load;
        }
    }
    return null;
}

function defaultFileSizeResolve(asset:AssetInfo, assets:{[key:string]:AssetInfo}) : number {
    return asset.size;
}

/**
 * @param type file type
 */
export function resolveFileSize(type:string) : (asset:AssetInfo, assets:{[key:string]:AssetInfo}) => number {
    for(const loader of loaderClasses) {
        if(loader.load && loader.type === type) {
            return loader.fileSize;
        }
    }
    return defaultFileSizeResolve;
}

/**
 * add a loader
 * @param extension lower cased extension (without dot)
 * @param type type of data, loader can produce
 * @param classType reference to class
 */
export function registerLoadResolver<T>(type:string, cb:(url:string) => AsyncLoad<T>) {
    for(const loaderClass of loaderClasses) {
        if(loaderClass.type === type) {
            loaderClass.load = loaderClass.load || cb;
            return;
        }
    }
    loaderClasses.push({
        identifier: type,
        extension: type,
        type: type,
        class: null,
        load: cb
    });
}

/**
 * add a file size
 * @param extension lower cased extension (without dot)
 * @param type type of data, loader can produce
 * @param classType reference to class
 */
export function registerFileSizeResolver(type:string, cb:(asset:AssetInfo, assets:{[key:string]:AssetInfo}) => number) {
    for(const loaderClass of loaderClasses) {
        if(loaderClass.type === type) {
            loaderClass.fileSize = loaderClass.fileSize || cb;
            return;
        }
    }
    loaderClasses.push({
        identifier: type,
        extension: type,
        type: type,
        class: null,
        load: null,
        fileSize: cb
    });
}

/**
 * add a loader
 * @param extension lower cased extension (without dot)
 * @param type type of data, loader can produce
 * @param classType reference to class
 */
export function registerLoader<T>(identifier:string, extension:string, type:EAssetType, classType:any, cb?:(url:string) => AsyncLoad<T>) : void {
    const lowerCaseExtension = extension.toLowerCase();

    for(const loaderClass of loaderClasses) {
        if(loaderClass.identifier === identifier &&
            loaderClass.extension === lowerCaseExtension &&
            loaderClass.type === type) {

            loaderClass.class = loaderClass.class || classType;
            loaderClass.load = loaderClass.load || cb;
            return;
        }
    }
    loaderClasses.push({
        identifier: identifier,
        extension: lowerCaseExtension,
        type: type,
        class: classType,
        load: cb
    });
}

/**
 * find loader class with identifier
 * @param extension lower cased extension (without dot)
 * @param type type of data loader should provide
 */
export function findLoader(identifier:string, type:EAssetType) : any {
    for(const loader of loaderClasses) {
        if(loader.class && loader.type === type && loader.identifier === identifier) {
            return loader.class;
        }
    }
    return null;
}

/**
 * find a loader class for file extension and data type
 * @param extension lower cased extension (without dot)
 * @param type type of data loader should provide
 */
export function findLoaderWithExtension(extension:string, type:EAssetType) : any {
    const lowerCasedExt = extension.toLowerCase();
    // first find exact loader
    for(const loader of loaderClasses) {
        if(loader.type === type && loader.extension === lowerCasedExt) {
            return loader.class;
        }
    }
    // then process with same type
    for(const loader of loaderClasses) {
        if(loader.type === type) {
            return loader.class;
        }
    }
    return null;
}

/** base async calback data */
export interface ResolveCallback {
    resolve:any;
    reject:any;
}
