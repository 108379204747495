///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {Config} from '../../Config';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class CarouselComponent extends AbstractBlockComponent {

    private _jOwlContainer;
    private _autoplay:boolean = false;

    protected onInit():void{
        this._params = _.defaults(this._params, {
            //Owl Defaults
            items: 3,
            lazyLoad:true,
            loop: true,
            margin: 10,
            rtl: false,
            autoHeight: false,

            /*responsive: {
                0:{
                    items:1
                },
                640:{
                    items:2
                },
                960:{
                    items:3
                },
                1280:{
                    items:4
                },
                2560:{
                    items:5
                }
            },*/

            //AutoPlay Defaults
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,

            //Navigation Defaults
            nav: false,
            navText: [ 'prev', 'next' ],
            dots: true,

            //Video Defaults
            /*video: false,
            videoHeight: false,
            videoWidth: false,*/
        });


        //transform string breakpoints like size_xl into integer keys like 2560
        if(_.has(this._params, 'responsive')){
            let config = Config.getInstance();
            this._params['responsive'] = _.transform(this._params['responsive'].items, function(result, value, key) {
                result[config.media_breakpoints[key]]={items:value};
            });
        }


        this._autoplay = this._params.autoplay;
        //never apply the autoplay param diretly. the autoplay is used within the appear and disappear events
        this._params.autoplay = false;

        //console.log("CarouselComponent", this._params);
        this._jOwlContainer = $(this._block.domElement.querySelector('.owl-carousel'));


        this._jOwlContainer.on('initialized.owl.carousel', (event) => {
            this.state = ComponentState.READY;
        });

        this._jOwlContainer.owlCarousel(this._params);
    }

    public appear():void{
        if(this._autoplay){
            this._jOwlContainer.trigger('play.owl.autoplay',[this._params.autoplayTimeout]);
        }
    }

    public disappear():void{
        if(this._autoplay){
            this._jOwlContainer.trigger('stop.owl.autoplay');
        }
    }

    protected onDestroy():void{
        this._jOwlContainer.trigger('destroy.owl.carousel');
        this.state = ComponentState.DESTROYED;
    }
}