///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {Config} from '../../Config';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class SVGComponent extends AbstractBlockComponent {

    static renderCanvas:HTMLCanvasElement;
    static renderCtx:CanvasRenderingContext2D;

    protected onInit():void{
        this._params = _.defaults(this._params, {
           
        });

        this.state = ComponentState.READY;
    }

    public appear():void{

        if(!window.URL.createObjectURL){
            return;
        }

        if(!SVGComponent.renderCanvas){
            SVGComponent.renderCanvas = document.createElement('canvas');
            SVGComponent.renderCtx = SVGComponent.renderCanvas.getContext('2d');
        }
        const image = this._block.domElement.querySelector('img[src*=".svg"]') as HTMLImageElement;

        if(image){
            const svgToBlob = ()=>{
                image.removeEventListener('load',svgToBlob);
                
                const {width,height} = image.getBoundingClientRect();
                SVGComponent.renderCanvas.width = width;
                SVGComponent.renderCanvas.height = height;
                SVGComponent.renderCtx.clearRect(0,0,width,height);
                SVGComponent.renderCtx.drawImage(image, 0,0, width, height);

                SVGComponent.renderCanvas.toBlob((blob)=>{
                    image.src = window.URL.createObjectURL(blob)
                },'image/png');
            }

            if(!image.complete){
                image.addEventListener('load',svgToBlob);
            } else {
                svgToBlob();
            }
        }
    }

    public disappear():void{

    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }
}