///<reference path="js_bindings.d.ts"/>

import {Manager} from './sidebar/Manager';
import * as Block from './block/Import';
import * as Site from "./site/Import";
import * as Sidebar from "./sidebar/Import";

import { klaroConfig } from "./gdpr/config";

export class Main  {
    /** construction */
    constructor() {
        const blockManager = new Block.Manager({});
        const sidebarManager = new Sidebar.Manager();
        const breadcrumb = new Site.Breadcrumb();
        const colorTheme = new Site.ColorTheme();
        const contentLoader = new Site.ContentLoader(blockManager,colorTheme,breadcrumb,sidebarManager);

        // assign the Klaro module to the window, so that we can access it in JS
        window["klaro"] = klaro;
        window["klaroConfig"] = klaroConfig;

        // setup Klaro with the config
        klaro.setup(klaroConfig);
    }
}

// let tmpScrollTo = window.scrollTo;
// window.scrollTo = function(){
//     console.log("ScrollTo",arguments);
// }

//console.log("REDWeb Start");
let app = new Main();

// window.addEventListener("orientationchange", function() {
// if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
//     document.documentElement.innerHTML = document.documentElement.innerHTML;
// }
// }, false);
