/**
 * Depth.ts: depth buffer shader
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { CullFaceFront, CullFaceNone } from "../../../lib/threejs/constants";
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { ShaderVariant } from "../Shader";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    shaderBuilder.importCode(["redPrecision", "redPacking"]).then( () => {

        shaderBuilder.createShader("redDepth", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms: {},
            vertexShaderSource: `
                //@include "redPrecision"

                //attributes
                attribute vec3 position;

                //uniforms
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;

                void main() {
                    vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"

                void main()
                {
                    gl_FragColor = vec4(gl_FragCoord.z);
                }
            `
        });

        shaderBuilder.createShader("redLinearDepth", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms: {},
            vertexShaderSource: `
                //@include "redPrecision"

                //attributes
                attribute vec3 position;

                //uniforms
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;

                // varyings
                varying vec3 vViewPosition;

                void main() {
                    vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                    vViewPosition = mvPosition.xyz;
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"

                // varyings
                varying vec3 vViewPosition;

                void main()
                {
                    gl_FragColor = vec4(vViewPosition.z);
                    //gl_FragColor = vec4(gl_FragCoord.z);
                }
            `
        });

        shaderBuilder.createShader("redLinearDepthBackface", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                cullFace: CullFaceFront
            },
            uniforms: {},
            vertexShaderSource: `
                //@include "redPrecision"

                //attributes
                attribute vec3 position;

                //uniforms
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;

                // varyings
                varying vec3 vViewPosition;

                void main() {
                    vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                    vViewPosition = mvPosition.xyz;
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"

                // varyings
                varying vec3 vViewPosition;

                void main()
                {
                    gl_FragColor = vec4(vViewPosition.z);
                    //gl_FragColor = vec4(gl_FragCoord.z);
                }
            `
        });

        shaderBuilder.createShader("redPCFDepth", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                cullFace: CullFaceNone,
                polygonOffset: true,
                polygonOffsetFactor: 1,
                polygonOffsetUnits: 1
            },
            uniforms: {},
            variants: [ShaderVariant.PCF, ShaderVariant.PCF | ShaderVariant.PDS],
            vertexShaderSource: `
                //@include "redPrecision"

                //attributes
                attribute vec3 position;
                attribute vec2 uv;

                //uniforms
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;

                varying vec2 vUv;
                varying float vDepth;
                void main() {
                    vUv = uv;
                    vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                    float zBuffer = gl_Position.z / gl_Position.w;
                    vDepth = 0.5 + (zBuffer * 0.5);
                    vDepth = zBuffer;
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"
                //@include "redPacking"

                varying float vDepth;
                void main()
                {
                    gl_FragColor = packDepthToRGBA(gl_FragCoord.z);
                    //gl_FragColor = vec4(vec3(gl_FragCoord.z), 1.0);
                }
            `
        });

        shaderBuilder.createShader("redVSMDepth", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                cullFace: CullFaceNone,
            },
            variants: [ShaderVariant.VSM],
            uniforms: {},
            vertexShaderSource: `
                //@include "redPrecision"

                //attributes
                attribute vec3 position;
                attribute vec2 uv;

                //uniforms
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;

                varying vec2 vUv;
                varying float vDepth;
                void main() {
                    vUv = uv;
                    vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                    float zBuffer = gl_Position.z / gl_Position.w;
                    vDepth = 0.5 + (zBuffer * 0.5);
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"

                varying float vDepth;
                void main()
                {
                float depth2 = pow(vDepth, 2.0);
                float dx = dFdx(vDepth);
                float dy = dFdy(vDepth);
                float depth2Avg = depth2 + 0.25 * (dx*dx + dy*dy);
                gl_FragColor = vec4(vDepth, depth2Avg, 0.0, 1.0);
                }
            `
        });

        shaderBuilder.createShader("redESMDepth", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                cullFace: CullFaceNone,
            },
            uniforms: {},
            variants: [ShaderVariant.ESM],
            vertexShaderSource: `
                //@include "redPrecision"

                //attributes
                attribute vec3 position;
                attribute vec2 uv;

                //uniforms
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;

                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    vec4 mvPosition = modelViewMatrix * vec4( position.xyz, 1.0);
                    gl_Position = projectionMatrix * mvPosition;
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"
                //@include "redPacking"

                varying float vDepth;
                void main()
                {
                    gl_FragColor = packDepthToRGBA(gl_FragCoord.z);
                }
            `
        });
    });
});
