import { Shader } from "./Shader";
import { AsyncLoad } from "../io/AsyncLoad";
import { build } from "../core/Build";
import { cloneUniforms, Uniforms } from "./Uniforms";
import { AssetManager } from "../framework/AssetManager";
import { ShaderLibrary } from "./ShaderLibrary";
import { rejects } from "assert";

export type ShaderModuleCallback = (shaderBuilder:ShaderBuilder) => PromiseLike<void>;

//DEPRECATED
//REPLACE with something better
export let ShaderModule = function(callback:ShaderModuleCallback, files?:Array<string>) {
    return initShaderModule(callback, files);
};

/**
 * code chunks
 */
export const ShaderChunk:{[key:string]:string} = {
};

/** Uniform Chunks */
export const UniformLib:{[key:string]:Uniforms} = {};

/** shader module load callback */
export interface LoadCallback {
    callback:ShaderModuleCallback;
    files?:Array<string>;
}

/** shader library */
let _isLoaded:boolean = false;
let _loadCallback:Array<LoadCallback> = [];

/**
 * resolve shader code (resolves includes etc)
 */
function resolveShaderCode(source:string) : AsyncLoad<string> {
    const resolver = new ShaderResolver();
    return resolver.resolve(source);
}

/**
 * initialize shader module code
 */
function initShaderModule(callback: ShaderModuleCallback, files?:Array<string>) {

    if(_isLoaded === false) {
        // deferred loading
        _loadCallback.push({
            callback: callback,
            files: files
        });
    } else {
        if(files) {
            // first load all files
            const loaded = [];
            for(let i = 0; i < files.length; ++i) {
                loaded.push(loadShader(files[i]));
            }

            AsyncLoad.all(loaded).then( () => {
                // all files loaded, proceed
                if(callback) {
                    callback(ShaderBuilder.get());
                }
            });

        } else {
            // all files loaded, proceed
            if(callback) {
                callback(ShaderBuilder.get());
            }
        }
    }
}

/**
 * load a shader (resolves includes etc)
 * automatically adds it into the chunk list
 */
export function loadShader(name:string) : AsyncLoad<string> {
    return new AsyncLoad<string>( (resolve, reject) => {
        const resolver = new ShaderResolver();

        resolver.load(name).then((text) => {
            resolve(text);
        }, (error:any) => {
            reject(error);
        });

    });
}

/**
 * preload shader fragments
 */
export function preloadShaderFragments() : AsyncLoad<void> {
    return new AsyncLoad<void>( (resolve, reject) => {
        const chunks:AsyncLoad<string>[] = [];

        // preload default chunks
        // chunks.push(loadShaderChunk("redCommon"));
        // chunks.push(loadShaderChunk("redFunctions"));
        // chunks.push(loadShaderChunk("redBSDFFunctions"));
        // chunks.push(loadShaderChunk("redLightFunctions"));
        // chunks.push(loadShaderChunk("redShadowFunctions"));

        // load custom chunks
        for(let i = 0; i < _loadCallback.length; ++i) {
            const files = _loadCallback[i].files;

            if(files) {
                const totalCount = files.length;

                for(let j = 0; j < files.length; ++j) {
                    chunks.push(loadShader(files[j]));
                }
            }
        }

        // finish this loading
        AsyncLoad.all(chunks.map((p) => p.catch((e) => e)))
            .then(async (shaders:string[]) => {
                const loaded = [];
                // this will also be called when some shaders cannot be loaded too.
                // shaders array can be a error or string

                // notify shader that system is ready
                for(let i = 0; i < _loadCallback.length; ++i) {
                    if(_loadCallback[i].callback) {
                        loaded.push(_loadCallback[i].callback(ShaderBuilder.get()));
                    }
                }

                AsyncLoad.all(loaded).then( () => {
                    _loadCallback = [];
                    _isLoaded = true;
                    resolve();
                }, reject);
            },
            (err) => {
                //TODO: mark shader as invalid
                _loadCallback = [];
                _isLoaded = true;

                reject(new Error('Invalid Shader File'));
            });
    });
}

/**
 * load a shader chunk
 * automatically adds it into the chunk list
 */
function loadShaderChunk(name:string) : AsyncLoad<string> {
    return new AsyncLoad<string>( (resolve, reject) => {

        // FIXME: chunks is already loaded?
        if(ShaderChunk[name]) {
            resolve(ShaderChunk[name]);
            return;
        }

        // construct path
        let path = build.Options.shaderLibrary.basePath;

        if(path) {
            path = path + name + ".glsl";
        } else {
            path = name + ".glsl";
        }

        AssetManager.loadText(path).then((text:string) => {

            //TODO: check text for correctness...
            if(text) {
                ShaderChunk[name] = text;
            }
            resolve(text);
        },
        reject);
    });
}

/**
 * simple tool for building
 */
export class ShaderBuilder {

    public static get() {
        if(!ShaderBuilder.Instance) {
            ShaderBuilder.Instance = new ShaderBuilder();
        }
        return ShaderBuilder.Instance;
    }
    private static Instance:ShaderBuilder = null;

    public files:string[];

    public shaderMap:{[key:string]:Shader};

    constructor() {

    }

    public importCode(file:string|string[]) : AsyncLoad<void> {
        return new AsyncLoad<void>( (resolve, reject) => {
            if(Array.isArray(file)) {
                const files = [];
                for(const f of file) {
                    files.push(loadShader(f));
                }
                return AsyncLoad.all(files).then(() => resolve(), reject);
            } else {
                return loadShader(file).then(() => resolve(), reject);
            }
        });
    }

    public resolveCode(source:string) {
        return resolveShaderCode(source);
    }

    public createShader(name:string, shader:Shader) {
        shader.vertexShader = shader.vertexShader || {};
        shader.fragmentShader = shader.fragmentShader || {};
        shader.redSettings = shader.redSettings || {};

        // fill in order
        shader.redSettings.order = shader.redSettings.order || 0;

        // assume chunk reference
        if(typeof shader.vertexShader === 'string') {
            const shaderCode = shader.vertexShader;
            shader.vertexShader = ShaderChunk[shaderCode];
        }

        if(typeof shader.fragmentShader === 'string') {
            const shaderCode = shader.fragmentShader;
            shader.fragmentShader = ShaderChunk[shaderCode];
        }

        // add to entry (but not ready yet)
        ShaderLibrary.CustomShaderLib[name] = shader;

        // apply sources directly
        if(shader.vertexShaderSource) {
            this.resolveCode(shader.vertexShaderSource as string).then( (source) => {
                shader.vertexShader = source;
            });
        }

        if(shader.fragmentShaderSource) {
            this.resolveCode(shader.fragmentShaderSource as string).then( (source) => {
                shader.fragmentShader = source;
            });
        }

        if(shader.selector) {
            ShaderLibrary.ShaderSelect[name] = shader.selector;
        } else if(build.Options.debugShaderOutput) {
            console.info(`${name} has missing super selector`);
        }
    }

    public createShaderFrom(name:string, original:string, shader:Shader) {
        if(!ShaderLibrary.CustomShaderLib[original]) {
            return;
        }

        // assume chunk reference
        if(typeof shader.vertexShader === 'string') {
            const shaderCode = shader.vertexShader;
            shader.vertexShader = ShaderChunk[shaderCode];
        }

        if(typeof shader.fragmentShader === 'string') {
            const shaderCode = shader.fragmentShader;
            shader.fragmentShader = ShaderChunk[shaderCode];
        }

        // fill empty from original
        shader.vertexShader = shader.vertexShader || ShaderLibrary.CustomShaderLib[original].vertexShader;
        shader.fragmentShader = shader.fragmentShader || ShaderLibrary.CustomShaderLib[original].fragmentShader;
        shader.redSettings = shader.redSettings || ShaderLibrary.CustomShaderLib[original].redSettings;
        shader.selector = shader.selector || ShaderLibrary.CustomShaderLib[original].selector;
        // FIXME: merge?!
        shader.uniforms = shader.uniforms;
        if(!shader.uniforms) {
            shader.uniforms = cloneUniforms(ShaderLibrary.CustomShaderLib[original].uniforms);
        }

        // copy functions
        shader.evaluateDefines = shader.evaluateDefines || ShaderLibrary.CustomShaderLib[original].evaluateDefines;
        shader.onPreRender = shader.onPreRender || ShaderLibrary.CustomShaderLib[original].onPreRender;
        shader.onPostRender = shader.onPostRender || ShaderLibrary.CustomShaderLib[original].onPostRender;
        shader.onCompile = shader.onCompile || ShaderLibrary.CustomShaderLib[original].onCompile;

        //FIXME: merge something like redSettings?!

        ShaderLibrary.CustomShaderLib[name] = shader;

        // apply sources directly
        if(shader.vertexShaderSource) {
            this.resolveCode(shader.vertexShaderSource as string).then( (source) => {
                shader.vertexShader = source;
            });
        }

        if(shader.fragmentShaderSource) {
            this.resolveCode(shader.fragmentShaderSource as string).then( (source) => {
                shader.fragmentShader = source;
            });
        }

        if(shader.selector) {
            ShaderLibrary.ShaderSelect[name] = shader.selector;
        } else if(build.Options.debugShaderOutput) {
            console.info(`${name} has missing super selector`);
        }
    }
}

/**
 * Resolves Shader files with includes
 */
export class ShaderResolver {

    /** construction */
    constructor() {

    }

    /**
     * try to resolve shader code
     * @param source
     */
    public resolve(source:string) : AsyncLoad<string> {
        // resolve includes
        return this._resolveIndirects(source);
    }

    /**
     * try to load shader fragments
     * @param name filename
     * @param finished function: callback(code:string)
     */
    public load(name:string) : AsyncLoad<string> {
        return this._loadPart(name);
    }

    //TODO: use Shader lib for this...
    private _loadPart(name:string) : AsyncLoad<string> {
        return new AsyncLoad((resolve, reject) => {
            return loadShaderChunk(name).then( (text) => {

                if(text) {
                    // resolve includes
                    this._resolveIndirects(text).then( (chunk) => {
                        // add shader chunk (overwrite)
                        ShaderChunk[name] = chunk;

                        resolve(chunk);
                    },
                    reject);
                } else {
                    reject(new Error("load text error"));
                }

            }, reject);
        });
    }

    // resolves includes in GLSL files
    private _resolveIndirects(content:string) : AsyncLoad<string> {
        return new AsyncLoad((resolve, reject) => {
            let ready:boolean = false;
            let index:number = 0;
            const includes = [];

            do {
                // find next include entry
                index = content.indexOf("//@include", index);

                // found a new one
                if(index !== -1) {
                    const startIndex = index;
                    // parse filename from include
                    let filename = "";
                    let copy = false;
                    let endIndex = index;
                    for(endIndex = index + 10; endIndex < (index + 64); ++endIndex) {
                        if(copy) {
                            filename = filename + content.charAt(endIndex);
                        }

                        if(content.charAt(endIndex) === '"') {
                            copy = !copy;
                            if(!copy) {
                                // clean last "
                                filename = filename.substring(0, filename.length - 1);
                                break;
                            }
                        }
                    }
                    // new entry?!
                    if(filename.length > 0) {

                        //console.warn(filename);
                        includes.push({
                            startIndex: index,
                            endIndex: endIndex + 1,
                            filename: filename
                        });
                    }

                    // proceed to next
                    index = endIndex;

                } else {
                    ready = true;
                }

            } while(!ready);

            if(includes.length > 0) {
                const loads:AsyncLoad<string>[] = [];
                for(let i = 0; i < includes.length; ++i) {
                    loads.push(this._loadPart(includes[i].filename));
                }

                AsyncLoad.all(loads).then( (text:string[]) => {

                    // process from last to first to not destroy start and stop index
                    // this will not work when there is more work to replace
                    // start and end index will be false then
                    for(let i = includes.length - 1; i >= 0; --i) {
                        content = this._spliceSlice(content, includes[i].startIndex, includes[i].endIndex, text[i]);
                    }

                    resolve(content);
                },
                reject);
            } else {
                // directly ready when no include files
                resolve(content);
            }

        });
    }

    private _spliceSlice(str:string, index:number, endIndex:number, add:string) {
        if(add) {
            return str.slice(0, index) + add + str.slice(endIndex);
        } else {
            return str.slice(0, index) + str.slice(endIndex);
        }
    }

}
