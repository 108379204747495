/**
 * SpatialSystem.ts: Entity Component code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Patrick Kellerberg
 */
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Box3 } from "../../lib/threejs/math/Box3";
import { SpatialObject, ESpatialType, ESpatialIntersectionType, SpatialResponse, ISpatialSystem, SPATIALSYSTEM_API } from "../framework/SpatialAPI";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { registerAPI } from "../plugin/Plugin";
import { IWorld } from "../framework/WorldAPI";

/**
 * spatial location of objects
 * [[include:sourceDoc/Spatial.md]]
 */
let _objects: SpatialObject[] = [];
let _version: number = 1;

function destroy() {
    // clear all callbacks
    _objects = [];
    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function init(world: IWorld) {

}

/**
 * return position of component id
 * @param id component id of object
 */
function _position(id: ComponentId): Vector3 {
    if (!_validId(id)) {
        return null;
    }

    const index = componentIdGetIndex(id);
    return _objects[index].position;
}

/** get all objects that are registered sorted by Distance */
function getObjectsIn(boundingBox: Box3, type?: ESpatialType | number) {
    let spatialObjects: SpatialObject[] = [];

    if (type) {
        spatialObjects = _objects.filter((value) => {
            return value.type === type;
        });
    } else {
        spatialObjects = _objects.slice(0);
    }

    const nearestObjects = [];

    for (const spatialObject of spatialObjects) {

        let intersectionType: ESpatialIntersectionType = ESpatialIntersectionType.NOT_INTERSECTED;

        if (spatialObject.boundingBox) {
            intersectionType = boundingBox.containsBox(spatialObject.boundingBox) ? ESpatialIntersectionType.CONTAINED : ESpatialIntersectionType.NOT_INTERSECTED;

            if (intersectionType === ESpatialIntersectionType.NOT_INTERSECTED) {
                if (boundingBox.intersectsBox(spatialObject.boundingBox)) {
                    intersectionType = ESpatialIntersectionType.INTERSECTED;
                }
            } else {
                intersectionType = ESpatialIntersectionType.CONTAINED;
            }
        } else {
            intersectionType = boundingBox.distanceToPoint(spatialObject.position) === 0 ? ESpatialIntersectionType.CONTAINED : ESpatialIntersectionType.NOT_INTERSECTED;
        }

        if (intersectionType === ESpatialIntersectionType.CONTAINED || intersectionType === ESpatialIntersectionType.INTERSECTED) {
            nearestObjects.push(
                {
                    object: spatialObject.target,
                    intersectionType: intersectionType
                } as SpatialResponse
            );
        }
    }

    return nearestObjects;
}

/** get all objects that are registered sorted by Distance */
function getNearestObjects(origin: Vector3, type?: ESpatialType | number) {
    let spatialObjects: SpatialObject[] = [];

    if (type) {
        spatialObjects = _objects.filter((value) => {
            return value.type === type;
        });
    } else {
        spatialObjects = _objects.slice(0);
    }

    const nearestObjects = [];

    for (const spatialObject of spatialObjects) {

        let distance: number;

        if (spatialObject.boundingBox) {
            distance = spatialObject.boundingBox.distanceToPoint(origin);
        } else {
            distance = origin.distanceTo(spatialObject.position);
        }

        nearestObjects.push(
            {
                object: spatialObject.target,
                distance: distance,
            } as SpatialResponse
        );
    }

    return nearestObjects.sort((a, b) => a.distance - b.distance);
}

/** get nearest object */
function getNearestObject(origin: Vector3, type?: ESpatialType | number) {

    let bestSpatialObject = null;
    let bestDistance = Infinity;

    for (const spatialObject of _objects) {
        if (type && spatialObject.type !== type) {
            continue;
        }

        let distance: number;

        if (spatialObject.boundingBox) {
            distance = spatialObject.boundingBox.distanceToPoint(origin);
        } else {
            distance = origin.distanceTo(spatialObject.position);
        }

        if (distance < bestDistance) {
            bestDistance = distance;
            bestSpatialObject = spatialObject;
        }
    }

    if (bestSpatialObject) {
        return {
            object: bestSpatialObject.target,
            distance: bestDistance,
        } as SpatialResponse;
    }

    return null;
}

function updateTransform(id: ComponentId, position: Vector3, boundingBox?: Box3) {
    if (!_validId(id)) {
        return null;
    }

    const index = componentIdGetIndex(id);
    _objects[index].position = position.clone();

    if (boundingBox) {
        _objects[index].boundingBox = boundingBox.clone();
    }
}

/**
 * add a new spatial object to this list
 * @param object generic object
 * @param position optional position
 */
function registerObject(target: any, position: Vector3, type: ESpatialType | number, boundingBox?: Box3): ComponentId {
    let index = -1;

    for (let i = 0; i < _objects.length; ++i) {
        if (!_objects[i].id) {
            index = i;
            break;
        }
    }

    // new entry
    if (index === -1) {
        index = _objects.length;
        _objects[index] = {
            id: 0,
            position: null,
            target: null,
            type: null,
            boundingBox: null,
        };
    }

    // setup
    _objects[index].id = createComponentId(index, _version);
    _objects[index].target = target;
    _objects[index].position = position.clone();
    _objects[index].type = type;

    if (boundingBox) {
        _objects[index].boundingBox = boundingBox.clone();
    }

    return _objects[index].id;
}

/**
 * remove object from global list
 * @param id component id
 */
function removeObject(id: ComponentId) {
    if (!_validId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    // cleanup
    _objects[index].id = 0;
    _objects[index].target = null;
    _objects[index].position = null;
    _objects[index].boundingBox = null;

    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function _validId(id: ComponentId) {
    const index = componentIdGetIndex(id);
    if (index >= 0 && index < _objects.length) {
        return _objects[index].id === id;
    }
    return false;
}

const spatialSystem:ISpatialSystem = {
    init,
    destroy,
    getNearestObject,
    getNearestObjects,
    getObjectsIn,
    position: _position,
    registerObject,
    removeObject,
    updateTransform
};

registerAPI(SPATIALSYSTEM_API, spatialSystem);
