/**
 * QualityLevels.ts: unified render quality levels
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import {EventOneArg} from '../core/Events';

export interface PerformanceMeasurement {
    averageTime: number;
    minTime: number;
    maxTime: number;
    /** count */
    count: number;
    /** total time */
    totalTime: number;
    /** starting number */
    startTime: number;
}

/**
 * @class RenderQuality
 * Render Quality levels
 */
export namespace RenderQuality {

    /** quality levels */
    export const LowQuality:number = 0;
    export const MediumQuality:number = 1;
    export const HighQuality:number = 2;

    /** events */
    export const OnQualityChanged = new EventOneArg<number>();

    /** current quality state */
    export let qualityLevel: number = RenderQuality.HighQuality;

    /** set new render quality */
    export function setQualityLevel(_qualityLevel:number) {
        if(RenderQuality.qualityLevel !== _qualityLevel) {
            RenderQuality.qualityLevel = _qualityLevel;
            RenderQuality.OnQualityChanged.trigger(RenderQuality.qualityLevel);
        }
    }

    /** material quality levels */
    export const MaterialHighQuality:string = "highQuality";
    export const MaterialMediumQuality:string = "mediumQuality";
    export const MaterialLowQuality:string = "lowQuality";

    /** quality level for quality setting */
    export function QualityLevelMaterial(quality:string) : number {
        if(quality === RenderQuality.MaterialHighQuality) {
            return RenderQuality.HighQuality;
        } else if(quality === RenderQuality.MaterialMediumQuality) {
            return RenderQuality.MediumQuality;
        } else if(quality === RenderQuality.MaterialLowQuality) {
            return RenderQuality.LowQuality;
        }
        return RenderQuality.LowQuality;
    }

    export function QualityLevelMaterialString(quality:number) : string {
        switch(quality) {
            case RenderQuality.LowQuality:
                return RenderQuality.MaterialLowQuality;
            case RenderQuality.MediumQuality:
                return RenderQuality.MaterialLowQuality;
            case RenderQuality.HighQuality:
                return RenderQuality.MaterialHighQuality;
        }
        return RenderQuality.MaterialHighQuality;
    }

    /**
     * return the quality level for the measurement
     * assumes current quality level as base
     * @export
     * @param {PerformanceMeasurement} measurement performance measurement result
     * @param {number} quality base quality level
     * @returns
     */
    export function qualityLevelFromPerformance(measurement:PerformanceMeasurement, quality?:number) {
        quality = quality !== undefined ? quality : qualityLevel;
        // Ideal frame takes roughly 16.6
        // devices that don't reach complete 60 FPS are in between 16 - 32
        if(measurement.averageTime > 22.0) {
            quality = Math.max(LowQuality, quality - 1);
        } else if(measurement.averageTime > 38.0) {
            quality = Math.max(LowQuality, quality - 2);
        }
        return quality;
    }
}
