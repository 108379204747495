/**
 * # Standard Shader
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * roughnessMap -- Roughness Texture (RGB)
 * * metalness -- Metal (0-1)
 * * metalnessMap -- Metal Texture
 * #### Emissive
 * * emissive -- Emissive Color (RGB)
 * * emissiveMap -- Emissive Texture (RGB)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Transparency
 * * alphaMap -- Alpha Texture
 * * alphaMult --
 * * opacity -- Opacity value (diffuse.A * opacity)
 * #### Ambient Occlusion
 * * aoMap -- AO Texture (RGB)
 * * aoMapIntensity -- AO Intensity
 * #### Environment Mapping
 * * envMap -- Environment Map (RGB)
 * * envMapIntensity -- Environment Intensity
 * * flipEnvMap -- flip environment map (y axis)
 * * reflectivity -- reflection value (0-1)
 * * refractionRatio -- refraction value (0-1)
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 */
import "../shader/shader_generated";
import * as THREE from "redTyped/three"
import { ShaderLibrary } from "redTyped/render/ShaderLibrary";
import { ShaderModule, ShaderBuilder, UniformLib, ShaderChunk } from "redTyped/render/ShaderBuilder";
import { mergeUniforms, EUniformType } from "redTyped/render/Uniforms";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

	/**
	 * physical based shader with lighting and shadow support
	 * also supports transparency
     * TODO: work in progress
	 */
	ShaderLibrary.CustomShaderLib['hexagon'] = {
		redSettings: {
			lights: true,
		},
		uniforms: mergeUniforms( [
            UniformLib["lights"],
           	UniformLib["fog"],
			{
				/** diffuse */
				diffuse : { type: EUniformType.COLOR, value: new THREE.Color(0xcccccc)},
				map : { type: EUniformType.TEXTURE, value: null },
                /** standard shader */
                roughness: { type: EUniformType.FLOAT, value: 0.04 },
                roughnessMap: { type: EUniformType.TEXTURE, value: null },
                /** metalness */
                metalness: { type: EUniformType.FLOAT, value: 0 },
                metalnessMap: { type: EUniformType.TEXTURE, value: null },
				/** emissive */
				emissive : { type: EUniformType.COLOR, value: new THREE.Color(0x000000)},
				emissiveMap : { type: EUniformType.TEXTURE, value: null },
				/** ao map */
				aoMap : { type: EUniformType.TEXTURE, value: null },
				aoMapIntensity : { type: EUniformType.FLOAT, value: 1.0 },
				/** normal mapping */
				normalMap : { type: EUniformType.TEXTURE, value: null },
				normalScale : { type: EUniformType.VECTOR2, value: new THREE.Vector2(0.5, 0.5) },
				/** transparency */
				alphaMap : { type: EUniformType.TEXTURE, value: null },
				alphaMult : { type: EUniformType.FLOAT, value: 1.0 },
				opacity : { type: EUniformType.FLOAT, value: 1.0 },
				/** environment mapping */
				envMap : { type: EUniformType.TEXTURE, value: null },
				envMapIntensity : { type: EUniformType.FLOAT, value: 1.0 }, // temporary
				flipEnvMap : { type: EUniformType.FLOAT, value: - 1 },
				reflectivity : { type: EUniformType.FLOAT, value: 1.0 },
				refractionRatio : { type: EUniformType.FLOAT, value: 0.98 },
                /** uv channel transform */
				offsetRepeat : { type: EUniformType.VECTOR4, value: new THREE.Vector4(0.0, 0.0, 1.0, 1.0) },
                /** grid size */
                gridSize: { type: EUniformType.VECTOR2, value: new THREE.Vector2(0.1, 0.1) },
				background : { type: EUniformType.COLOR, value: new THREE.Color(0x000000)}
			}
		]),
		vertexShader: ShaderChunk["hexagonShader_vs"],
		fragmentShader: ShaderChunk["hexagonShader_ps"]
	};

}, ["redCommon", "redBSDFPhysical_Pixel", "redLightsPhysical_Pixel", "redShadowsPhysical_Pixel", "hexagonShader_vs", "hexagonShader_ps"] );
