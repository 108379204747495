///<reference path="../js_bindings.d.ts"/>


import {Block} from './Block';
import {BlockState} from './BlockState';

export class Manager {
    private _blocks:Array<Block> = [];
    private _onReady:SignalDispatcher = new SignalDispatcher();

    /** construction */
    constructor(settings:any) {
    }

    public get onUpdateReady(): ISignal {
        return this._onReady.asEvent();
    }

    public update():void{
        //mark every known block as unused
        _.each(this._blocks,block => {
            block.markAsUnused();
        });

        //query all blocks
        let nodes = document.querySelectorAll("main > .block");
        //cast it to an array
        let domElements:any[] = [].slice.call(nodes);


            _.each(domElements,(domElement)=>{
            //find a existing block
            let block = _.find(this._blocks, (block) => { return block.domElement == domElement;});
            //or create a new block
            if(block == null){
                block = new Block(domElement);
                this._blocks.push(block);

                let subnodes = domElement.querySelectorAll(".block");
                let subDomElements:any[] = [].slice.call(subnodes);
                _.each(subDomElements,(subDomElement)=>{
                    let subblock = new Block(subDomElement);
                    block.addSubBlock(subblock);
                });
            }
            //mark every reused block as used
            block.markAsUsed();
        });

        //create a used and unused partition
        let blockPartition = _.partition(this._blocks, { 'used': true });

        //first remove the unused
        _.each(blockPartition[1],block => {
            _.each(block.subBlocks,subblock => {
                subblock.destroy();
            });

            block.destroy();
        });

        //then keep the used blocks
        let blocksSnapshot:Array<Block> = this._blocks = blockPartition[0];

        //if the initialization went wrong
        let ripcordTimeoutId = setTimeout(() => {
            console.error("BlockManager ripcord")
            _.each(blocksSnapshot, (block: Block) => {
                block.onStateChange.unsubscribe(blockStateHandler);
                if(block.currentState == BlockState.READY){
                    block.start();
                }
                else{
                    block.destroy();
                    this._blocks = _.without(this._blocks,block);
                }
            });
            //Everything is ready in this round
            this._onReady.dispatch();
        },3000);

        let ready = false;
        //define the block state change handler
        let blockStateHandler = (block:Block, state:BlockState)=>{
            //we have to check the ready value because of the dispatchAsync race conditions
            if(ready === true){
                return;
            }

            //console.log("blockStateHandler state:", BlockState[state]);

            //Is every component ready?
            let tmpReady = _.every(blocksSnapshot, ['currentState', BlockState.READY]);
            //console.log("blockStateHandler allReady",allReady);

            if(tmpReady === true){
                ready = true;

                //clear ripcordTimeoutId
                clearTimeout(ripcordTimeoutId);

                _.each(blocksSnapshot,(block:Block) => {
                    block.onStateChange.unsubscribe(blockStateHandler);
                    block.start();
                });

                //Everything is ready in this round
                this._onReady.dispatchAsync();
            }
        };

        //init every block - ! subblocks are skipped
        _.each(blocksSnapshot,(block:Block) => {
            block.onStateChange.subscribe(blockStateHandler);
            block.init();
        });

        //console.log("Blocks", this._blocks);
    }
}