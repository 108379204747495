/**
 * Model.ts: Generic Model/Mesh code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { Box3 } from "../../lib/threejs/math/Box3";
import { InstancedBufferGeometry } from "../../lib/threejs/core/InstancedBufferGeometry";
import { InstancedBufferAttribute } from "../../lib/threejs/core/InstancedBufferAttribute";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { AnimationMixer } from "../../lib/threejs/animation/AnimationMixer";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Quaternion } from "../../lib/threejs/math/Quaternion";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { BufferGeometry } from "../../lib/threejs/core/BufferGeometry";
import { GraphicsDisposeSetup, destroyEntity } from '../core/Globals';
import { ModelData, ModelNode, ModelMesh, MODELMESH_PRIMITIVE_LINE, MODELMESH_PRIMITIVE_TRIANGLE } from '../framework-types/ModelFileFormat';
import { AsyncLoad } from '../io/AsyncLoad';
import { MaterialLibrary, MaterialLibSettings } from '../framework/MaterialLibrary';
import { AnimationController } from '../animation/Animation';
import { Entity } from "../framework/Entity";
import { MaterialRef, InstanceBufferRef } from "./Geometry";
import { Mesh } from "./Mesh";
import { Line } from "../render-line/Line";
import { MaterialTemplate, MaterialDesc } from "./Material";
import { createHierarchyFromModelData } from "../framework/ModelBuilder";
import { registerLoadResolver } from "../io/Interfaces";
import { queryMeshSystem } from "../framework/MeshAPI";

/** model statistics */
export interface ModelStatistic {
    meshesCount:number;
    materialCount:number;
    animationCount:number;
    vertices:number;
    indices:number;
    materials:string[];
}

/**
 * @class StaticModel
 * Abstraction of a "Mesh".
 *
 * - generates materials from mesh material names.
 * - connects to MaterialLibrary for material events.
 * - changes to the hierarchy is not recognized (position changes etc)
 *    only root changes get recognized (call update yourself)
 *
 */
export class StaticModel {
    private static _modelCounter:number = 0;
    private static generateId(): number {
        StaticModel._modelCounter++;
        return StaticModel._modelCounter;
    }

    public readonly isRedStaticModel = true;
    public static isStaticModel(obj: any) : obj is StaticModel {
        return obj.isRedStaticModel;
    }

    /** material update callback */
    public OnMaterialUpdate:{()};
    /** pre and post render callbacks */
    public OnPreRender:{(camera:any, mesh:any, material:any)};
    public OnPostRender:{(camera:any, mesh:any, material:any)};

    /**
     * print Model usage
     */
    public static printModelStats() {
        console.info("Models in Memory " + StaticModel._modelCounter);
    }

    public get receiveShadow():boolean {
        return this._receiveShadow;
    }

    public set receiveShadow(value:boolean) {
        this._receiveShadow = value || false;
        //TODO: materials on meshes with forceReceiveShadow should be ignored
        for(let i = 0; i < this._meshes.length; ++i) {
            this._meshes[i].receiveShadow = this._receiveShadow;
        }
    }

    public get castShadow(): boolean {
        return this._castShadow;
    }

    public set castShadow(value:boolean) {
        this._castShadow = value || false;

        //TODO: materials on meshes with forceReceiveShadow should be ignored
        for(let i = 0; i < this._meshes.length; ++i) {
            this._meshes[i].castShadow = this._castShadow;
        }
    }

    /** meshes in model */
    public get meshes() : Mesh[]|Line[] {
        return this._meshes;
    }

    /** animation controller access */
    public get animationController() : AnimationController {
        return this._animationController;
    }

    /** debug name (filename most of the time) */
    public get name() : string {
        return this._name;
    }

    private _name:string;
    // meshes
    private _meshes:any[] = [];
    // hierarchy
    private _root:any = null;
    // animation controller
    private _animationController:AnimationController = null;

    // unique id
    private _uniqueId:number = StaticModel.generateId();
    // local bounding box
    private _localBounding:Box3 = new Box3();
    // world bounding box
    private _worldBounding:Box3 = new Box3();
    // model states
    private _castShadow:boolean = true;
    private _receiveShadow:boolean = true;
    // line support
    private _supportLines:boolean;

    /**
     * construction
     * @param obj three.js root object
     * @param debugName debug name
     */
    constructor(obj:ModelData, supportLines?:boolean, name?:string) {

        this._name = name || "unknown";
        // meshes
        this._meshes = [];
        // hierarchy
        this._root = null;
        // unique id
        this._uniqueId = StaticModel.generateId();
        // world bounding box
        this._worldBounding = new Box3();
        this._supportLines = supportLines || false;
        this._castShadow = true;
        this._receiveShadow = true;

        // parse data
        this._processModelNode(obj);
        this._processAnimations(obj.animations);
    }

    /**
     * destroy model
     * BE CAREFUL: after calling this, model is invalid
     */
    public destroy(dispose?:GraphicsDisposeSetup) {

        // cleanup animation references
        if(this._animationController) {
            this._animationController.destroy();
        }
        this._animationController = null;

        for(const mesh of this.meshes) {
            mesh.destroy(dispose);
        }

        destroyEntity(this._root, dispose);
        this._root = null;
        this._meshes = [];
    }

    /**
     * flush gpu memory
     * TODO: add support for this in AssetManager
     * need a list of all available models
     */
    public flushGPUMemory() {
        function flushObject3D(obj:any) {
            if(obj instanceof THREEMesh) {
                obj.geometry.dispose();

                if(Array.isArray(obj.material)) {
                    for(const mat of obj.material) {
                        if(mat.dispose) {
                            mat.dispose();
                        }
                    }
                } else if(obj.material.dispose) {
                    obj.material.dispose();
                }

            } else {
                for(let i = 0; i < obj.children.length; ++i) {
                    flushObject3D(obj.children[i]);
                }
            }
        }
        flushObject3D(this._root);
    }

    /** get model hierarchy */
    public getHierarchy():any {
        return this._root;
    }

    /**
     * object bounding box (in world space)
     */
    public get worldBounds():any {
        this._adjustWorldBoundings();
        return this._worldBounding.clone();
    }

    /**
     * object bounding box (in local space)
     * TODO: this should be cached...
     */
    public get localBounds() : any {
        this._localBounding.makeEmpty();

        for(let i = 0; i < this._meshes.length; ++i) {
            const bound = this._adjustLocalBoundingsForMesh(this._meshes[i]);
            this._localBounding.union(bound);
        }
        return this._localBounding.clone();
    }

    /**
     * unique id
     * @return number
     */
    public get uniqueID() : number {
        return this._uniqueId;
    }

    /**
     * list of material references
     */
    public get materialRefs() : MaterialRef[] {
        //FIXME: filter unique ones?
        return this._meshes.map( (mesh:Mesh) => {
            return mesh.materialRef;
        });
    }

    /**
     * get a material slot by name
     */
    public getMaterialSlot(name:string, allowOverrides:boolean = true) : number {
        const index = this._meshes.findIndex( (mesh:Mesh) => {

            if(mesh.materialRef.name === name) {
                return true;
            }

            if(allowOverrides && mesh.materialRef.ref === name) {
                return true;
            }

            return false;
        });
        return index;
    }

    public getMaterialSlots(name:string, allowOverrides:boolean = true) : number[] {
        const slots:number[] = [];

        for(let i = 0; i < this._meshes.length; ++i) {
            const mesh:Mesh = this._meshes[i];

            if(mesh.materialRef.name === name) {
                slots.push(i);
            } else if(allowOverrides && mesh.materialRef.ref === name) {
                slots.push(i);
            }

        }
        return slots;
    }

    /**
     * apply material to slot's
     * @param slot
     * @param materialInstance
     */
    public setMaterial(slot:number|number[], material:string|MaterialTemplate) {
        console.assert(material, "invalid material instance reference");

        if(typeof material === 'string') {
            material = MaterialLibrary.findMaterialByName(material);
        }

        if(!material) {
            return;
        }

        if(Array.isArray(slot)) {
            for(const s of slot) {
                if(s < 0 || s >= this._meshes.length) {
                    continue;
                }

                const mesh:Mesh = this._meshes[s];
                mesh.setMaterialTemplate(material);
            }
        } else {
            if(slot === -1) {
                // apply to all slots
                for(const mesh of this._meshes) {
                    mesh.setMaterialTemplate(material);
                }
            } else {
                if(slot < 0 || slot >= this._meshes.length) {
                    return;
                }

                const mesh:Mesh = this._meshes[slot];
                mesh.setMaterialTemplate(material);
            }
        }
    }

    /**
     * override materials to new material name
     * @param materials material references
     */
    public setMaterialRefs(materials:MaterialRef[]) {
        for(const mesh of this.meshes) {
            // original name attached to mesh
            const materialName = mesh.materialRef.name;

            const matRef = materials.find( (value) => {
                if(value.name === materialName) {
                    return true;
                }
                return false;
            });

            // apply to mesh
            if(matRef) {
                if(mesh.setMaterialRef) {
                    mesh.setMaterialRef(matRef);
                } else {
                    const template = MaterialLibrary.findMaterialByName(matRef.ref || matRef.name);

                    if(!template) {
                        console.warn("StaticModel: invalid material reference " + (matRef.ref || matRef.name));
                        continue;
                    }

                    mesh.setMaterialTemplate(template);
                }
            }
        }
    }

    /** reset all material reference to original */
    public resetMaterials() {

        for(let i = 0; i < this._meshes.length; ++i) {
            const mesh:Mesh = this._meshes[i];

            // no way to resolve to material
            if(!mesh.materialRef) {
                continue;
            }

            const original = mesh.materialRef.name;

            // ignore material groups (handled globally)
            if(MaterialLibrary.isGroup(original)) {
                continue;
            }

            const template = MaterialLibrary.findMaterialByName(original);

            if(!template) {
                console.warn("Model::resetMaterials: cannot find original template reference: " + original);
                continue;
            }

            mesh.setMaterialTemplate(template);
        }
    }

    /**
     * set layer to all meshes
     * @param layer layer mask
     */
    public setRenderLayer(layer:number) {
        for(const mesh of this.meshes) {
            mesh.layers.set(layer);
        }
    }

    /**
     * set layer to all meshes
     * @param layer layer mask
     */
    public setRenderOrder(order:number) {
        for(const mesh of this.meshes) {
            mesh.renderOrder = order;
        }
    }
    /**
     * apply instancing to this model
     * @param instances instance number
     * @param buffers instance buffers to use
     */
    public setInstancing(instances:number, buffers:InstanceBufferRef[]) {

        // replace all geometry buffers with new instance buffers
        for(const mesh of this.meshes) {
            const tmpGeometry = mesh.geometry;

            // generate new buffers
            const geometry = new InstancedBufferGeometry().copy(mesh.geometry as InstancedBufferGeometry);

            geometry.maxInstancedCount = instances;

            // add instance buffers to this
            for(const buffer of buffers) {
                geometry.addAttribute(buffer.name, buffer.buffer);
            }

            // apply to mesh
            mesh.geometry = geometry;

            //TODO: calculate bounding from all instances (merge them and apply to all)

            // for now, frustum culling with instances is not supported
            mesh.frustumCulled = false;

            // free old geometry
            tmpGeometry.dispose();
        }

        //FIXME: force?
        this._recreateMaterials();

    }

    /**
     * this functions assumes that model has been converted to use
     * instancing
     * @param count new count (must be smaller than buffer size)
     */
    public setInstanceCount(count:number) {
        let applyable = false;
        for(const mesh of this.meshes) {
            const tmpGeometry = mesh.geometry;
            if(tmpGeometry instanceof InstancedBufferGeometry || tmpGeometry['isInstancedBufferGeometry']) {
                applyable = true;
            }
        }
        console.assert(applyable, "Model: not instancing geometry applied");
        let currentBufferSize = 0;

        for(const mesh of this.meshes) {
            if(mesh.geometry instanceof InstancedBufferGeometry || mesh.geometry['isInstancedBufferGeometry']) {
                const tmpGeometry = mesh.geometry as InstancedBufferGeometry;
                // find all attributes that are using instanced ones
                for(const attrKey in tmpGeometry.attributes) {
                    if(tmpGeometry.attributes[attrKey] instanceof InstancedBufferAttribute || tmpGeometry.attributes[attrKey]['isInstancedBufferAttribute']) {
                        currentBufferSize = Math.min(currentBufferSize, tmpGeometry.attributes[attrKey].count);
                    }
                }
            }
        }

        if(currentBufferSize > count) {
            console.error("Model: current instancing size smaller than count to render");
            return;
        }

        for(const mesh of this.meshes) {
            if(mesh.geometry instanceof InstancedBufferGeometry || mesh.geometry['isInstancedBufferGeometry']) {
                const tmpGeometry = mesh.geometry as InstancedBufferGeometry;
                tmpGeometry.maxInstancedCount = count;
            }
        }
    }

    /** return model statistics */
    public getStats() : ModelStatistic {
        const stats:ModelStatistic = {
            meshesCount: 0,
            materialCount: 0,
            animationCount: 0,
            vertices: 0,
            indices: 0,
            materials: []
        };

        stats.meshesCount = this.meshes.length;

        for(let i = 0; i < this.meshes.length; ++i) {
            //console.info(this.meshes[i]);

            if(!this.meshes[i].geometry) {
                console.warn("Model: submesh with invalid geometry at index " + i);
                continue;
            }

            const pos = this._meshes[i].geometry.getAttribute("position");
            stats.vertices += pos.count;

            const idx = this._meshes[i].geometry.getIndex();
            if(idx) {
                stats.indices += idx.count;
            }
        }

        // save material list
        stats.materialCount = 0;
        for(let i = 0; i < this._meshes.length; ++i) {
            const materialName = this._meshes[i].materialRef.name;

            // check for shared material (non shared will be listed twice)
            let uniqueIdx = 0;
            for(uniqueIdx = 0; uniqueIdx < stats.materials.length; ++uniqueIdx) {
                if(stats.materials[uniqueIdx] === materialName) {
                    break;
                }
            }

            if(uniqueIdx === stats.materials.length || stats.materials.length === 0) {
                stats.materials.push(materialName);
            }
        }
        stats.materialCount = stats.materials.length;

        // animation count
        if(this.animationController) {
            stats.animationCount = this.animationController.animationNames.length;
        } else {
            stats.animationCount = 0;
        }

        return stats;
    }

    /**
     * call this when local vertices have been updated
     */
    public updateBounds() {
        this._worldBounding.makeEmpty();

        for(let i = 0; i < this._meshes.length; ++i) {
            const bound = this._adjustWorldBoundingsForMesh(this._meshes[i], true);

            this._worldBounding.union(bound);
        }
    }

    /**
     * generates hierarchy of meshes
     */
    private _processModelNode(data:ModelData) {
        const scope = this;
        function hasMeshOrLineChildren(node:ModelNode) {
            // has meshes
            if(node.meshes && node.meshes.length > 0) {
                for(let i = 0; i < node.meshes.length; ++i) {
                    const mesIdx = node.meshes[i];

                    if(scope._supportLines && data.meshes[mesIdx].primitiveType === MODELMESH_PRIMITIVE_LINE) {
                        return true;
                    } else if(data.meshes[mesIdx].primitiveType === MODELMESH_PRIMITIVE_TRIANGLE) {
                        return true;
                    }
                }
            }
            // has children
            if(node.children && node.children.length > 0) {
                for(let i = 0; i < node.children.length; ++i) {
                    const child = node.children[i];
                    if(child.name.startsWith("CP") || child.name.startsWith("$prefab:") || child.name.startsWith("@prefab:")) {
                        return true;
                    } else if(child.children && child.children.length > 0) {
                        if(hasMeshOrLineChildren(child)) {
                            return true;
                        }
                    } else if(child.meshes && child.meshes.length > 0) {
                        if(hasMeshOrLineChildren(child)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }

        function createNode(name:string, modelNode:ModelNode, transform:Matrix4) /*: Object3D*/ {
            const hasChildren = hasMeshOrLineChildren(modelNode);
            const singleNode = modelNode.name.startsWith("CP") || modelNode.name.startsWith("$prefab:") || modelNode.name.startsWith("@prefab:");

            // node object
            if(hasChildren || singleNode) {
                const ent = new Entity(modelNode.name);

                ent.transient = true;
                ent.matrix.copy(transform);
                ent.matrix.decompose(ent.position, ent.quaternion, ent.scale);
                ent.name = name;
                ent.updateMatrix();

                return ent;
            }
            return null;
        }

        function createMesh(name:string, meshNode:ModelMesh, material:MaterialDesc, parent:Object3D) /*:Object3D*/ {
            // clone without geometry???
            let meshInstance:Line|Mesh = null;
            if(meshNode.primitiveType === MODELMESH_PRIMITIVE_LINE) {
                //TODO: support screenspace line
                if(scope._supportLines) {
                    meshInstance = new Line(meshNode.geometry, material);
                }
            } else {
                // red mesh (material should be loaded -> so use name)
                meshInstance = new Mesh(meshNode.geometry, material.name);
            }
            // mesh created?!
            if(meshInstance) {
                // shadow stuff
                meshInstance.castShadow = scope.castShadow;
                meshInstance.receiveShadow = scope.receiveShadow;
                //FIXME!!
                meshInstance.name = name;

                meshInstance.updateMatrix();

                //TODO: rewrite
                scope._replaceMaterialsForMesh(meshInstance);

                // add to list of meshes
                scope._meshes.push(meshInstance);
            }
            return meshInstance;
        }

        const rootNode = createHierarchyFromModelData(data, createNode, createMesh, true);

        rootNode.name = "Root_" + this._name;
        rootNode['_RED_lastPosition'] = new Vector3(0.0,0.0,0.0);
        rootNode['_RED_lastQuaternion'] = new Quaternion();

        this._root = rootNode;
    }

    /**
     * process animations if any are available
     * creates animation classes
     * @param animations AnimationClip instance
     */
    private _processAnimations(animations:any[]) {
        if(animations) {
            this._animationController = new AnimationController(new AnimationMixer(this.getHierarchy()), animations);
        }
    }

    /**
     * update boundings for this model
     */
    private _adjustWorldBoundings() {
        this._worldBounding.makeEmpty();

        for(let i = 0; i < this._meshes.length; ++i) {
            const bound = this._adjustWorldBoundingsForMesh(this._meshes[i]);
            this._worldBounding.union(bound);
        }
    }

    /**
     * calculcates world bounding of mesh part and returns it
     * also adjusts mesh local bounding to the new bounding calculated.
     * @return mesh bounding box
     */
    private _adjustLocalBoundingsForMesh(mesh:Mesh, forceUpdate:boolean = false) {
        if(!mesh || !mesh.geometry) {
            console.error("MODEL: invalid parameter, not a valid Mesh");
            return new Box3();
        }

        const meshGeometry = mesh.geometry as BufferGeometry;

        // make sure bounding box is already calculated
        // based on local vertices

        if(!meshGeometry.boundingBox || forceUpdate) {
            mesh.buildLocalBounds(forceUpdate);
        }

        return meshGeometry.boundingBox;
    }

    /**
     * calculcates world bounding of mesh part and returns it
     * also adjusts mesh local bounding to the new bounding calculated.
     * @return mesh world bounding box
     */
    private _adjustWorldBoundingsForMesh(mesh:Mesh, forceUpdate:boolean = false) {

        if(!mesh || !mesh.geometry) {
            console.error("MODEL: invalid parameter, not a valid Mesh");
            return new Box3();
        }

        const meshGeometry = mesh.geometry as BufferGeometry;

        // make sure bounding box is already calculated
        // based on local vertices

        if(!meshGeometry.boundingBox || forceUpdate) {
            mesh.buildLocalBounds(forceUpdate);
        }

        // WORLD BOUNDING
        const newBounding = mesh.worldBounds();

        // helper code
        // const boundsHelper = mesh.getObjectByName("Bounds_DEBUG");
        // if(boundsHelper) {
        //     parent.remove(boundsHelper);
        // }
        // const center = boundingBox.getCenter().clone();
        // const size = boundingBox.getSize();
        // const geometry = new THREE.BoxGeometry(size.x, size.y, size.z);
        // const material = new THREE.MeshBasicMaterial({ color: 0x0000ff, wireframe:true });
        // const debugBox = new THREE.Mesh(geometry, material);
        // debugBox.name = "Bounds_DEBUG";
        // debugBox.position.copy(center);
        // parent.add(debugBox);

        return newBounding;
    }

    // replace mesh materials with custom shader material
    private _replaceMaterialsForMesh(mesh:Mesh|Line, force?:boolean) {
        if(!mesh || !mesh.material) {
            console.warn("StaticModel: replaceMaterialsForMesh: not a valid mesh object");
            return;
        }

        // mesh.redMaterial can be the original one (template data)
        let templateMaterial = mesh.redMaterial;

        if(!templateMaterial) {
            let materialName = mesh.materialRef.name || mesh.materialName;

            // preprocess material name
            //FIXME: last index....
            if(materialName.indexOf("_instance") > 0) {
                materialName = materialName.replace("_instance", "");
            }

            // is material group reference?
            templateMaterial = MaterialLibrary.findMaterialByName(materialName);
        }

        if(!templateMaterial) {
            // or three.js shader material (instanced)
            templateMaterial = MaterialLibrary.findMaterialByName(MaterialLibSettings.defaultDebugMaterial);
        }

        if(templateMaterial) {
            mesh.setMaterialTemplate(templateMaterial, force);
        }
    }

    //TODO: make private
    public _recreateMaterials() {
        const scope = this;
        // generate meshes and new hierarchy
        function recursiveProcess(node:any) {

            //check if node is mesh (TODO)
            if(node.material) {
                const meshInstance = node;

                // shadow stuff
                meshInstance.castShadow = scope.castShadow;
                meshInstance.receiveShadow = scope.receiveShadow;

                // check if runtime material or template is
                // working the same way (could change variant)
                scope._replaceMaterialsForMesh(meshInstance, true);
            }

            // continue with childs
            if(node.children) {
                for(let i = 0; i < node.children.length; ++i) {
                    recursiveProcess(node.children[i]);
                }
            }
        }

        recursiveProcess(this._root);
    }

}

/** factory function */
export function loadModel(name:string, loaderIdentifier?:string, supportLines?:boolean, preloadMaterials?:boolean) : AsyncLoad<StaticModel> {
    return new AsyncLoad<StaticModel>((resolve, reject) => {
        queryMeshSystem().loadMesh(name, loaderIdentifier).then((mesh) => {
            if(preloadMaterials) {
                const loading = [];

                // preload materials
                if(mesh && mesh.materials && Array.isArray(mesh.materials)) {
                    for(const material of mesh.materials) {

                        // check if material name is loaded in material library
                        const template = MaterialLibrary.findMaterialByName(material.name);

                        if(template) {
                            loading.push(MaterialLibrary.loadMaterial(template));
                        } else {
                            loading.push(MaterialLibrary.loadMaterial(material));
                        }
                    }
                }

                AsyncLoad.all(loading).then( () => {
                    if(mesh) {
                        const model = new StaticModel(mesh, supportLines, name);
                        resolve(model);
                    } else {
                        reject(new Error("invalid loading model data"));
                    }
                }, reject);
            } else {
                if(mesh) {
                    const model = new StaticModel(mesh, supportLines, name);
                    resolve(model);
                } else {
                    reject(new Error("invalid loading model data"));
                }
            }
        },
        reject);
    });
}
