/**
 * RenderAPI.ts: render API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Render } from "../render/Render";
import { RenderState } from "../render/State";
import { ComponentId } from "./Component";
import { IWorld, WorldSystem } from "./WorldAPI";
import { queryAPI, makeAPI } from "../plugin/Plugin";
import { RedCamera } from "../render/Camera";

/**
 * Component Render System
 */
export type GenericRenderCallback = (render:Render, camera:RedCamera) => void;
export type PrepareRenderCallback = (render:Render, scene:any, camera:any, pipeState:RenderState) => void;

export interface IRenderSystem extends WorldSystem {
    init(world:IWorld);
    destroy();

    /** needs think state */
    needsRender(id:ComponentId) : boolean;

    activate(id:ComponentId): void;
    deactivate(id:ComponentId): void;

    registerCallback(prepareRender?:PrepareRenderCallback, preRender?:GenericRenderCallback, render?:GenericRenderCallback, debugRef?:string) : ComponentId;
    removeCallback(id:ComponentId): void;

    prepareRendering(render:Render, scene:any, camera:any, pipeState:RenderState) : void;

    preRender(render:Render, camera:RedCamera);
    render(render:Render, camera:RedCamera);
}
export const RENDERSYSTEM_API = makeAPI("IRenderSystem");

let _globalRenderSystem:IRenderSystem;
export function queryRenderSystem() {
    if(_globalRenderSystem === undefined) {
        _globalRenderSystem = queryAPI<IRenderSystem>(RENDERSYSTEM_API);
    }
    return _globalRenderSystem;
}
