///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';
import { Platform } from 'redTyped/core/Platform';

export class WalkwayComponent extends AbstractBlockComponent {

    private _svgElement:SVGSVGElement;
    private _walkway;
    private _drawn:boolean = false;

    protected onInit():void{
        this._params = _.defaults(this._params, {
            duration: 3000,
            weight:100
        });
        console.log("WalkwayComponent", this._block.domElement);

        this._svgElement = this._block.domElement.querySelector('svg');
        if(!this._svgElement) {
            //TODO: Handle errors instead of fake ready states -> this.state = ComponentState.ERROR;
            this.state = ComponentState.READY;
            return;
        }

        this._svgElement.style.width = this._params.weight + "%";

        //not supported
        if(Platform.get().isIEBrowser)
        {
            //get the max content width
            let blockContentElement = this._block.domElement.querySelector('.block_content') as HTMLElement;
            //get the svg dimensions
            let viewBox:String = this._svgElement.getAttribute("viewBox");
            if(viewBox){
                let dimensions = viewBox.split(" ");
                if(dimensions.length >= 4){
                    let svgAspect = parseInt(dimensions[3]) / parseInt(dimensions[2]);
                    //apply the width and height values for IE
                    //TODO: Window resize is not handled! But only on IE. So it dosent matter much.
                    let scale = this._params.weight / 100;
                    this._svgElement.setAttribute("width", (blockContentElement.offsetWidth * scale).toString()+"px");
                    this._svgElement.setAttribute("height", Math.round(svgAspect * blockContentElement.offsetWidth * scale)+"px");
                }
            }
            this._svgElement.classList.add('play');
        }
        else
        {
            if(this._svgElement.hasAttribute("width")){
                this._svgElement.removeAttribute("width");
            }

            if(this._svgElement.hasAttribute("height")){
                this._svgElement.removeAttribute("height");
            }

            this._svgElement.classList.add(this._params.walkway_id);
            let walkwaySelector = '.'+this._params.walkway_id;



            this._walkway = new Walkway({
                selector: walkwaySelector,
                duration: this._params.duration,
                // can pass in a function or a string like 'easeOutQuint'
                // easing: function (t) {
                //     return t * t;
                // }
            });

            this._block.onCenterDistanceChange.subscribe(this.draw);
        }



        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }

    private draw = (distance:number):void => {
        //console.log("WalkwayComponent Draw", distance);
        if(Math.abs(distance) > 0.5 || this._drawn === true) return;

        this._drawn = true;
        this._walkway.redraw();
        this._svgElement.classList.add('play');
    }

    public disappear():void{
        if(this._drawn === true){
            this._drawn = false;
            this._svgElement.classList.remove('play');
        }
    };
}