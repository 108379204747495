/**
 * TaggingAPI.ts: tagging API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { ComponentId } from "./Component";
import { queryAPI, makeAPI } from "../plugin/Plugin";
import { WorldSystem } from "./WorldAPI";

/**
 * tagging of arbitary objects
 */
export interface TagObject {
    tag? : number;
    userData?: any;
    /** object reference (DEPRECATED?!) */
    object: any;
}

export interface ITaggingSystem extends WorldSystem {

    /**
     * return tag of component id
     * @param id component id of object
     */
    tag(id:ComponentId) : number;

    /**
     * set tag of component id
     * @param id component id of object
     * @param tag tag value
     */
    setTag(id:ComponentId, tag:number);

    /**
     * get user data attach to component id
     * @param id component id
     */
    userData(id:ComponentId) : any;

    /**
     * set custom data for component id
     * @param id component id
     * @param userData any user data
     */
    setUserData(id:ComponentId, userData:any);

    /** get all objects that are registered */
    getObjects();

    /** get all objects with a tag associated */
    getObjectsTagged();

    /** get all objects with data associated */
    getObjectsWithData();

    /**
     * query objects by tag
     * @param tag tag to search for
     */
    getObjectsByTag(tag:number);

    /**
     * query objects by tag
     * @param predicate callback function
     */
    getObjectsByTagPredicate(predicate:(object:TagObject) => boolean);

    /**
     * query all objects with custom data set
     * @param customData custom data to search for
     */
    getObjectsByData(customData:any);

    /**
     * query all objects with custom data set
     * @param predicate callback function
     */
    getObjectsByDataPredicate(predicate:(object:TagObject) => boolean);

    /**
     * add a new tag object to this list
     * @param object generic object
     * @param tag optional tag
     * @param userData optional custom data
     */
    registerObject(object:any, tag?:number, userData?:any) : ComponentId;

    /**
     * remove object from global list
     * @param id component id
     */
    removeObject(id:ComponentId);

}
export const TAGGINGSYSTEM_API = makeAPI("ITaggingSystem");

let _globalLightSystem:ITaggingSystem;
export function queryTaggingSystem() {
    if(_globalLightSystem === undefined) {
        _globalLightSystem = queryAPI<ITaggingSystem>(TAGGINGSYSTEM_API);
    }
    return _globalLightSystem;
}
