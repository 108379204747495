///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';
import {BlockState} from '../BlockState';

export class DemandComponent extends AbstractBlockComponent {

    protected onInit():void{
        let trigger = this._block.domElement.querySelector('.demand_trigger');
        // let triggerBtn = trigger.querySelector('svg');
        let content = this._block.domElement.querySelector('.demand_content');
        let loaded = false;
        trigger.addEventListener("click",(event)=>{
            this._block.domElement.classList.toggle('opened');
            if(loaded){
                return;
            }
            loaded = true;

            _.each(this._block.subBlocks, (subblock:Block)=>{
                let stateHandler = (block:Block,state:BlockState)=>{
                    //console.log("blockStateHandler[subblock] state:", BlockState[state]);
                    if(state == BlockState.READY){
                        subblock.onStateChange.unsubscribe(stateHandler);
                        subblock.start();
                    }
                };
                subblock.onStateChange.subscribe(stateHandler);
                subblock.init();
            });
        });

        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }

}