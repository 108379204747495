/**
 * Standard.ts: standard (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * metalness -- Metal (0-1)
 * * occRoughMetalMap -- Occlusion (R) Roughness Texture (G)  Metal Texture (B)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Ambient Occlusion
 * * aoMap -- AO Texture (RGB)
 * * aoMapIntensity -- AO Intensity
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector2 } from "../../../lib/threejs/math/Vector2";
import { Vector4 } from "../../../lib/threejs/math/Vector4";
import {mergeUniforms, EUniformType} from '../Uniforms';
import {setValueShader, applyShaderToRenderer, ShaderVariant, variantIsSet} from '../Shader';
import { ShaderBuilder, ShaderModule } from "../ShaderBuilder";
import { normalTexture } from "../Texture";
import { Render } from "../Render";
import { Mesh } from "../Mesh";
import { Line } from "../../render-line/Line";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    // first import code
    shaderBuilder.importCode(["redCommon", "redPrecision", "redFunctions", "redBSDFFunctions", "redLightFunctions",
                                    "redPacking", "redShadowFunctions", "redStandard_Vertex", "redNormal_Pixel"]).then( () => {

        /**
         * world space normals
         */
        shaderBuilder.createShader("redNormal", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms: mergeUniforms( [
                {
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShader: "redNormal_Pixel"
        });

        /**
         * normals in view space
         */
        shaderBuilder.createShader("redViewNormal", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms: mergeUniforms( [
                {
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShaderSource: `
                //@include "redPrecision"

                #ifndef RED_NORMAL
                #define RED_NORMAL
                #endif

                // varyings
                varying vec3 vViewPosition;
                varying vec4 vWorldPosition;
                varying vec3 vNormal;
                varying vec2 vUv;

                #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
                varying vec3 vTangent;
                varying vec3 vBitangent;
                #endif

                uniform mat4 viewMatrix;

                // common
                //@include "redCommon"
                //@include "redFunctions"
                //@include "redBSDFFunctions"
                //@include "redLightFunctions"
                //@include "redPacking"
                //@include "redShadowFunctions"

                #if defined(USE_AOMAP)
                    varying vec2 vUv2;
                #endif

                //TODO:
                #if defined(USE_NORMALMAP)
                uniform vec2 normalScale;
                uniform sampler2D normalMap;
                #endif

                // pixel shader entry
                void main() {

                    vec3 normal = normalize(vNormal);

                #if defined(USE_NORMALMAP)
                #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
                    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
                    // vec(1,-1) for substance painter etc.
                    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
                    mat3 tsn = mat3( vTangent, vBitangent, vNormal );
                    normal = normalize( tsn * mapN );

                #else // NO QTANGENT
                    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
                #endif
                #endif

                    gl_FragColor = vec4( normal * 0.5 + 0.5, 1.0);

                    //debug
                }
            `
        });

        /**
         * print view positions
         */
        shaderBuilder.createShader("redPosition", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms: mergeUniforms( [
                {}
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {
                const shaderInterface = applyShaderToRenderer(renderer, material);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                //defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShaderSource: `
                //@include "redPrecision"

                //uniforms
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;

                //attributes
                attribute vec3 position;

                varying vec4 vColor;

                void main() {
                    vec4 pos = modelViewMatrix * vec4(position, 1.0);
                    gl_Position = projectionMatrix * pos;
                    // view position
                    vColor = pos;
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"

                varying vec4 vColor;

                void main() {
                    gl_FragColor = vColor;
                }
            `
        });

        /**
         * print world positions
         */
        shaderBuilder.createShader("redWorldPosition", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            uniforms: mergeUniforms( [
                {}
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {
                const shaderInterface = applyShaderToRenderer(renderer, material);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                //defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShaderSource: `
                //@include "redPrecision"

                //uniforms
                uniform mat4 modelMatrix;
                uniform mat4 viewMatrix;
                uniform mat4 projectionMatrix;

                //attributes
                attribute vec3 position;

                varying vec4 vColor;

                void main() {
                    vec4 pos = modelMatrix * vec4(position, 1.0);
                    gl_Position = projectionMatrix * viewMatrix * pos;
                    // world position
                    vColor = pos;
                }
            `,
            fragmentShaderSource: `
                //@include "redPrecision"

                varying vec4 vColor;

                void main() {
                    gl_FragColor = vColor;
                }
            `
        });

    });
});
