/**
 * ModelLoader.ts: binary model loading
 *
 * ORIGINAL:
 * @author Alexander Gessler / http://www.greentoken.de/
 * https://github.com/acgessler
 *
 * Loader for models imported with Open Asset Import Library (http://assimp.sf.net)
 * through assimp2json (https://github.com/acgessler/assimp2json).
 *
 * Supports any input format that assimp supports, including 3ds, obj, dae, blend,
 * fbx, x, ms3d, lwo (and many more).
 *
 * See webgl_loader_assimp2json example.
 *
 * - Modified by Lutz Hören
 *
 * Copyright redPlant GmbH 2016-2018
 */
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { Line as THREELine } from "../../lib/threejs/objects/Line";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Color } from "../../lib/threejs/math/Color";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { Quaternion } from "../../lib/threejs/math/Quaternion";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { InterpolateLinear, InterpolateSmooth } from "../../lib/threejs/constants";
import { AnimationClip } from "../../lib/threejs/animation/AnimationClip";
import { Float32BufferAttribute, BufferAttribute } from "../../lib/threejs/core/BufferAttribute";
import { BufferGeometry } from "../../lib/threejs/core/BufferGeometry";
import { Box3 } from "../../lib/threejs/math/Box3";
import { Sphere } from "../../lib/threejs/math/Sphere";
import {AsyncLoad} from '../io/AsyncLoad';
import { EAssetType, registerLoader } from '../io/Interfaces';
import {build} from "../core/Build";
import { FileLoader } from "../io/FileLoader";
import { generateQTangent } from "../render/Geometry";
import { MaterialDB, MaterialTemplate, writeToMaterialDB, MaterialDesc } from "../render/Material";
import { createHierarchyFromModelData } from "../framework/ModelBuilder";
import { MaterialLibrary } from "../framework/MaterialLibrary";
import { IModelLoader, ModelLoadCallback, ModelErrorCallback, ModelData, ModelMesh, MODELMESH_PRIMITIVE_LINE, ModelNode, ModelAnimation, MODELMESH_PRIMITIVE_TRIANGLE } from "../framework-types/ModelFileFormat";

interface ModelHeader {
    minorVersion:number;
    majorVersion:number;
    checksum:number;
    compressed:number;
    polygonize:number;
}

interface ModelChunk {
    id:number;
    size:number;
}

const MESH_HAS_POSITIONS = 0x1;
const MESH_HAS_NORMALS = 0x2;
const MESH_HAS_QTANGENTS = 0x4;
const MESH_HAS_TEXCOORD_BASE = 0x10;
const MESH_HAS_COLOR_BASE = 0x1000;
const MESH_HAS_SELECTIONSET_BASE = 0x10000;
const MESH_HAS_TEXCOORD = function(n:number) {
    return (MESH_HAS_TEXCOORD_BASE << n);
};
const MESH_HAS_COLOR = function(n:number) {
    return (MESH_HAS_COLOR_BASE << n);
};
const MESH_HAS_SELECTIONSET = function(n:number) {
    return (MESH_HAS_SELECTIONSET_BASE << n);
};

export const MATERIAL_TYPE_STANDARD = 0;
export const MATERIAL_TYPE_ANISOTROPY = 1;
export const MATERIAL_TYPE_CLEARCOAT = 2;
export const MATERIAL_TYPE_CLOTH = 3;

const REDMODEL_MIN_VERSION = 390;

const REDMODEL_CHUNK_NODE = 0x1230;
const REDMODEL_CHUNK_NODEANIM = 0x1231;
const REDMODEL_CHUNK_STREAMDATA = 0x1236;
const REDMODEL_CHUNK_MESH = 0x1237;
const REDMODEL_CHUNK_STREAMMESH = 0x1238;
const REDMODEL_CHUNK_SCENE = 0x1239;
const REDMODEL_CHUNK_BONE = 0x123a;
const REDMODEL_CHUNK_MATERIAL = 0x123b;
const REDMODEL_CHUNK_ANIMATION = 0x123c;

const REDMODEL_MAX_NUMBER_OF_TEXTURECOORDS = 4;
const REDMODEL_MAX_NUMBER_OF_COLOR_SETS = 2;
const REDMODEL_MAX_NUMBER_OF_SELECTION_SETS = 16;

export const REDMODEL_INTERPOLATION_LINEAR = 1;
export const REDMODEL_INTERPOLATION_CUBIC = 2;

/**
 * new style typescript model loader
 */
export class ModelLoader implements IModelLoader {
    public crossOrigin:any;

    public manager:any;
    public texturePath:string;
    public autoLoadTextures:boolean;
    public autoShrink:boolean;
    public useGeometryBuffer:boolean;
    public colorRGBToIndex:boolean;
    public fixNodeNames:boolean;
    public importMaterials:boolean;

    private _littleEndian:boolean;
    private _offset:number;

    constructor(manager:any) {
        this.manager = manager;
    }

    public load(url:string, reference:string, onLoad:ModelLoadCallback, onProgress:any, onError:ModelErrorCallback) {

        const scope = this;

        // optional external texture path
        this.texturePath = this.texturePath && ( typeof this.texturePath === "string" ) ? this.texturePath : this.extractUrlBase( url );

        // load referenced textures directly
        this.autoLoadTextures = this.autoLoadTextures || false;

        // try to shrink scene graph
        this.autoShrink = this.autoShrink || false;

        // use WebGL Geometry Buffer
        this.useGeometryBuffer = this.useGeometryBuffer || false;

        // Control Points color conversion
        this.colorRGBToIndex = this.colorRGBToIndex || false;

        // fix FBX node names
        this.fixNodeNames = this.fixNodeNames !== undefined ? this.fixNodeNames : true;

        // try to import materials
        this.importMaterials = this.importMaterials || true;

        // byte parse format (TODO: check format)
        this._littleEndian = true;

        this.manager.itemStart(reference);

        this._startLoad(url, reference).then( (scene:any) => {

            try {
                onLoad(scene);
            } catch(err) {
                console.error("ModelLoader: fatal error in onLoad callback!!!");
            }

            scope.manager.itemEnd(reference);
        },
        (err:any) => {

            try {
                onError(url);
            } catch(err) {
                console.error("ModelLoader: fatal error in error callback!!!");
            }

            // always call this
            scope.manager.itemEnd(reference);
            scope.manager.itemError(reference);
        });

    }

    /**
     * load from memory
     * only support version >= 300
     */
    public loadFromMemory(binary:ArrayBuffer, reference:string, onLoad:ModelLoadCallback, onProgress:any, onError:ModelErrorCallback) {

        const scope = this;

        // optional external texture path
        this.texturePath = this.texturePath && ( typeof this.texturePath === "string" ) ? this.texturePath : "";

        // load referenced textures directly
        this.autoLoadTextures = this.autoLoadTextures || false;

        // try to shrink scene graph
        this.autoShrink = this.autoShrink || false;

        // use WebGL Geometry Buffer
        this.useGeometryBuffer = this.useGeometryBuffer || false;

        // Control Points color conversion
        this.colorRGBToIndex = this.colorRGBToIndex || false;

        // fix FBX node names
        this.fixNodeNames = this.fixNodeNames !== undefined ? this.fixNodeNames : true;

        // try to import materials
        this.importMaterials = this.importMaterials || true;

        // byte parse format (TODO: check format)
        this._littleEndian = true;

        this.manager.itemStart("loadFromMemory");

        this._startLoadMemory(binary).then( (scene:any) => {

            try {
                onLoad(scene);
            } catch(err) {
                console.error("ModelLoader: fatal error in onLoad callback!!!");
            }

            scope.manager.itemEnd("loadFromMemory");
        },
        (err:any) => {

            try {
                onError("loadFromMemory");
            } catch(err) {
                console.error("ModelLoader: fatal error in error callback!!!");
            }

            // always call this
            scope.manager.itemEnd("loadFromMemory");
        });

    }

    /** new version >= 300 loading code */
    private _startLoad(url:string, reference:string) : AsyncLoad<any> {
        return new AsyncLoad<any>( (resolve, reject) => {

            this._loadInternal(url, reference, 'arraybuffer').then( (buffer) => {

                try {
                    const scene = this._loadRED(buffer);
                    resolve(scene);
                } catch(err) {
                    reject(err);
                }
            },
            (err:any) => {
                reject(err);
            });

        });
    }

    /**
     * can only load files >= 300
     */
    private _startLoadMemory(binary:ArrayBuffer) : AsyncLoad<any> {
        return new AsyncLoad<any>( (resolve, reject) => {
            try {
                const scene = this._loadRED(binary);
                resolve(scene);
            } catch(err) {
                reject(err);
            }
        });
    }

    /** extract meshes from binary */
    private _loadRED(buffer:ArrayBuffer) : ModelData {

        // reset offset
        this._offset = 0;
        // The stl binary is read into a DataView for processing
        const dv:DataView = new DataView(buffer, 0);

        const header = this._readHeader(dv);

        if(header.checksum !== 0xdeadbeef) {
            return null;
        }

        // apply new header version
        const version = parseInt("" + header.majorVersion + "" + header.minorVersion + "0", 10);
        console.assert(version >= REDMODEL_MIN_VERSION, "version mismatch version to low " + version + " < " + REDMODEL_MIN_VERSION);

        // compressed file
        if(header.compressed) {

            //TODO...
            if(!build.Options.libraries.JSZip) {
                return null;
            }

            const compressedBuffer = new Uint8Array(buffer, this._offset);

            JSZip.loadAsync(compressedBuffer).then((zip) => {

                console.log(zip);
            },
            (err) => {
                console.error(err);
            });

            return {
                hierarchy: null,
                nodes: null,
                meshes: null,
                animations: null,
                materials: null
            };
        } else {

            // chunkID from scene
            const sceneChunk = this._readChunk(dv, version, REDMODEL_CHUNK_SCENE);

            const materials = this._readMaterials(dv, version);

            let meshes;
            if(version >= 600) {
                const streams = [];
                if(version > 610) {
                    const numStreams = dv.getUint32(this._offset, this._littleEndian);
                    this._offset += 4;
                    for(let i = 0; i < numStreams; ++i) {
                        const streamData = this._readStreamData(dv, version);
                        streams.push(streamData);
                    }
                } else {
                    const streamData = this._readStreamData(dv, version);
                    streams.push(streamData);
                }

                meshes = this._readStreamMeshes(dv, version, streams);

            } else {
                meshes = this._readMeshesOld(dv, version, header.polygonize);
            }

            // TODO: parse before nodes to set autoShrink
            const animations  = this._readAnimationsBinary(dv, version);

            // force no auto shrink when animations are loaded
            if(animations && animations.length > 0) {
                this.autoShrink = false;
            }

            // const nodes = this._readNodes(dv, new Matrix4(), meshes, materials, version);
            // // nodes are scene hierarchy (whole mesh)
            // return {
            //     hierarchy: nodes,
            //     nodes: null,
            //     meshes,
            //     animations,
            //     materials
            // };
            const nodes = this._readModelNodes(dv, new Matrix4(), meshes, materials, version);

            const modelData:ModelData = {
                hierarchy: null,
                nodes,
                meshes,
                animations,
                materials
            };

            modelData.hierarchy = createHierarchyFromModelData(modelData, null, null, this.autoShrink);

            // nodes are scene hierarchy (whole mesh)
            return modelData;
        }

    }

    /** load internal a file, return AsyncLoader */
    private _loadInternal(url:string, reference:string, responseType?:string) : AsyncLoad<any> {
        return new AsyncLoad<any>( (resolve, reject) => {
            const loader = new FileLoader(this.manager);

            loader['crossOrigin'] = this.crossOrigin;

            if(responseType) {
                //loader.setResponseType(responseType);
                loader.responseType = responseType as XMLHttpRequestResponseType;
            }

            loader.load(url, reference, (data:any) => {
                resolve(data);
            },
            // progress
            (xhr:any) => {
                //TODO
            },
            //
            (xhr:any) => {
                reject(new Error("failed to load url " + url));
            });
        });
    }

    /** read string from binary */
    private _readString(dv:DataView) : string {
        const length = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const out = [];
        for(let i = 0; i < length; ++i) {
            out[i] = dv.getUint8(this._offset);
            this._offset += 1;
        }
        const outString = String.fromCharCode.apply(null, out);
        return outString;
    }

    /** read Vector3 from binary */
    private _readVector3(dv:DataView) {
        const out = new Vector3();

        out.x = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.y = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.z = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;

        return out;
    }

    /** read color (vec4) from binary */
    private _readColor(dv:DataView) {
        const out = new Color();

        out.r = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.g = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.b = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        //TODO: support for all color channels
        const tempA = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;

        return out;
    }

    /** read vertex weight values as binary */
    private _readVertexWeight(dv:DataView) {
        const out = {
            vertexId: -1,
            weight: 0.0
        };

        out.vertexId = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        out.weight = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;

        return out;
    }

    /** read matrix44 from binary */
    private _readMatrix(dv:DataView) {
        const out = new Matrix4();
        for(let i = 0; i < 4; ++i) {
            for(let i2 = 0; i2 < 4; ++i2) {
                out.elements[i2 + (i * 4)] = dv.getFloat32(this._offset, this._littleEndian);
                this._offset += 4;
            }
        }
        return out;
    }

    /** read vector key (animation) from binary */
    private _readVectorKey(dv:DataView) {
        const out = {
            time: 0,
            vector: []
        };

        out.time = dv.getFloat64(this._offset, this._littleEndian);
        this._offset += 8;

        out.vector[0] = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.vector[1] = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.vector[2] = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;

        return out;
    }

    /** read quat key (animation) from binary */
    private _readQuatKey(dv:DataView) {
        const out = {
            time: 0,
            quat: []
        };

        out.time = dv.getFloat64(this._offset, this._littleEndian);
        this._offset += 8;

        out.quat[3] = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.quat[0] = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.quat[1] = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.quat[2] = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;

        return out;
    }

    private _readQuat(dv:DataView) {
        const out = new Quaternion();

        out.w = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.x = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.y = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;
        out.z = dv.getFloat32(this._offset, this._littleEndian);
        this._offset += 4;

        return out;
    }

    /**
     * read chunk header
     */
    private _readChunk(dv:DataView, version:number, chunkID:number) : ModelChunk {
        if(version >= 400) {
            const out = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;

            if(out !== chunkID) {
                throw new Error("ModelLoader: chunk invalid");
            }
            return {id: out, size: 0};
        } else {
            const out = {
                id: dv.getUint32(this._offset, this._littleEndian),
                size: dv.getUint32(this._offset+4, this._littleEndian)
            };
            this._offset += 8;

            if(out.id !== chunkID) {
                throw new Error("ModelLoader: chunk invalid");
            }
            return out;
        }
    }

    /** read file format binary header */
    private _readHeader(dv:DataView) : ModelHeader {
         // read header (44bytes)
        let headerName:Array<any> = [];
        for(let i = 0; i < 44; ++i) {
            headerName[i] = dv.getUint8(this._offset);
            this._offset += 1;
        }
        headerName = String.fromCharCode.apply(null, headerName);
        //console.log(headerName);

        const header:ModelHeader = {
            majorVersion: -1,
            minorVersion: -1,
            checksum: 0,
            compressed: 0,
            polygonize: 0
        };

        const majorVersion = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const minorVersion = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const checksum = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const compressed = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const polygonize = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        if(checksum !== 0xdeadbeef) {
            console.error("wrong file format");
            //TODO: safe exit
            return header;
        }

        header.majorVersion = majorVersion;
        header.minorVersion = minorVersion;
        header.checksum = checksum;
        header.compressed = compressed;
        header.polygonize = polygonize;

        return header;
    }

    /** read node hiearchy */
    private _readNodes(dv:DataView, transform:any, meshes:ModelMesh[], materials:MaterialTemplate[], version:number) : Object3D {

        const chunk = this._readChunk(dv, version, REDMODEL_CHUNK_NODE);

        const nodeName:string = this._readString(dv);
        const transformMatrix:any = this._readMatrix(dv);

        //
        const numChildrens = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const numMeshes = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // read mesh indices
        const meshIndices:number[] = [];
        for(let i = 0; i < numMeshes; ++i) {
            meshIndices[i] = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        // auto shrink support
        if(this.autoShrink && nodeName.indexOf('$AssimpFbx$') > -1 && numChildrens === 1) {
            // shrink node
            //console.log("AssimpJSONLoader: shrinking " + node.name);
            const nodeMatrix = transformMatrix;
            const matrix = new Matrix4();

            matrix.multiplyMatrices(nodeMatrix, transform);

            // return child0
            return this._readNodes(dv, matrix, meshes, materials, version);

        } else {
            // new node
            const obj = new Object3D();
            //const obj = new Entity();
            const nodeMatrix = transformMatrix;

            if(this.autoShrink) {
                obj.name = nodeName || "AssimpJSONLoader Node";
            } else {
                obj.name = this.fixNodeName(nodeName) || "Unknown";
            }
            obj.matrix = new Matrix4();
            obj.matrix.multiplyMatrices(nodeMatrix, transform).transpose();
            obj.matrix.decompose(obj.position, obj.quaternion, obj.scale);

            // add meshes
            for(let i = 0; i < numMeshes; ++i) {
                const idx = meshIndices[i];
                const matIdx = meshes[idx].materialIndex;

                //FIXME: always export as line??
                if(meshes[idx].primitiveType === MODELMESH_PRIMITIVE_LINE) {
                    const mesh = new THREELine(meshes[idx].geometry, materials[matIdx] as any);

                    mesh.name = "Line_" + nodeName;
                    obj.add(mesh);
                } else {
                    //FIXME: also use RED.Mesh ?!
                    const mesh = new THREEMesh(meshes[idx].geometry, materials[matIdx] as any);
                    mesh.name = "Mesh_" + nodeName;

                    // add to parent
                    obj.add(mesh);
                }
            }

            for(let i = 0; i < numChildrens; ++i) {
                obj.add(this._readNodes(dv, new Matrix4(), meshes, materials, version));
            }

            return obj;
        }
    }

    /** read node hiearchy */
    private _readModelNodes(dv:DataView, transform:any, meshes:ModelMesh[], materials:MaterialTemplate[], version:number) : ModelNode {
        const chunk = this._readChunk(dv, version, REDMODEL_CHUNK_NODE);

        const nodeName:string = this._readString(dv);
        const transformMatrix:any = this._readMatrix(dv);

        //
        const numChildrens = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const numMeshes = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // read mesh indices
        const meshIndices:number[] = [];
        for(let i = 0; i < numMeshes; ++i) {
            meshIndices[i] = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        // auto shrink support
        if(this.autoShrink && nodeName.indexOf('$AssimpFbx$') > -1 && numChildrens === 1) {
            // shrink node
            //console.log("AssimpJSONLoader: shrinking " + node.name);
            const nodeMatrix = transformMatrix;
            const matrix = new Matrix4();

            matrix.multiplyMatrices(nodeMatrix, transform).transpose();

            // return child0
            return this._readModelNodes(dv, matrix, meshes, materials, version);

        } else {
            // new node
            const nodeObj:ModelNode = {
                children: [],
                meshes: null,
                name: null,
                position: new Vector3(),
                quaternion: new Quaternion(),
                scale: new Vector3()
            };
            const nodeMatrix = transformMatrix;

            if(this.autoShrink) {
                nodeObj.name = nodeName || "AssimpJSONLoader Node";
            } else {
                nodeObj.name = this.fixNodeName(nodeName) || "Unknown";
            }
            const objMatrix = new Matrix4();
            objMatrix.multiplyMatrices(nodeMatrix, transform).transpose();
            objMatrix.decompose(nodeObj.position, nodeObj.quaternion, nodeObj.scale);

            // add meshes
            nodeObj.meshes = meshIndices.slice(0, numMeshes) || [];

            for(let i = 0; i < numChildrens; ++i) {
                nodeObj.children.push(this._readModelNodes(dv, new Matrix4(), meshes, materials, version));
            }

            return nodeObj;
        }
    }

    /** read materials from binary */
    private _readMaterials(dv:DataView, version:number) : any {

        const numMaterials = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // material template
        const materials:MaterialDesc[] = [];

        for(let i = 0; i < numMaterials; ++i) {

            const chunkMaterial = this._readChunk(dv, version, REDMODEL_CHUNK_MATERIAL);

            const materialName:string = this._readString(dv);

            let type = MATERIAL_TYPE_STANDARD;
            let baseColor = new Color();
            let baseColorMap = "";
            let occRoughMetalMap = "";
            let normalMap = "";
            let roughness = 0.94;
            let metalness = 0.0;
            const reflectivity = 0.5;
            let anisotropy = 0.0;

            if(version < 610) {
                baseColor = this._readColor(dv);

                // new options for version > 390
                if(version > 390) {
                    roughness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    metalness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;
                }
            } else {
                type = dv.getInt32(this._offset, this._littleEndian);
                this._offset += 4;

                if(type === MATERIAL_TYPE_STANDARD) {

                    baseColor = this._readColor(dv);

                    roughness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    metalness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    baseColorMap = this._readString(dv);
                    occRoughMetalMap = this._readString(dv);
                    normalMap = this._readString(dv);
                } else if(type === MATERIAL_TYPE_ANISOTROPY) {

                    baseColor = this._readColor(dv);

                    roughness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    metalness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    anisotropy = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    baseColorMap = this._readString(dv);
                    occRoughMetalMap = this._readString(dv);
                    normalMap = this._readString(dv);
                } else if(type === MATERIAL_TYPE_CLEARCOAT) {

                    baseColor = this._readColor(dv);

                    roughness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    metalness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    baseColorMap = this._readString(dv);
                    occRoughMetalMap = this._readString(dv);
                    normalMap = this._readString(dv);
                } else if(type === MATERIAL_TYPE_CLOTH) {

                    baseColor = this._readColor(dv);

                    roughness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    metalness = dv.getFloat32(this._offset, this._littleEndian);
                    this._offset += 4;

                    baseColorMap = this._readString(dv);
                    occRoughMetalMap = this._readString(dv);
                    normalMap = this._readString(dv);
                } else {
                    console.error("ModelLoader: unknown material type: " + type);
                }

            }

            // type to shader
            let shader = "redStandard";

            switch(type) {
                case MATERIAL_TYPE_STANDARD: shader = "redStandard"; break;
                case MATERIAL_TYPE_CLEARCOAT: shader = "redClearCoat"; break;
                case MATERIAL_TYPE_ANISOTROPY: shader = "redAnisotropy"; break;
                case MATERIAL_TYPE_CLOTH: shader = "redCloth"; break;
                default: shader = "redStandard"; break;
            }

            if(this.importMaterials) {

                // not already in library?
                //TODO: add material library function for this?
                if(!MaterialDB[materialName] && !MaterialLibrary.isGroup(materialName)) {
                    console.info("ModelLoader: creating new material '"+materialName+"' from mesh import");

                    //FIXME: fetch material quality level?

                    const template:MaterialTemplate = {
                        shader
                    };

                    //FIXME: save more values from importer?
                    template.baseColor = [baseColor.r, baseColor.g, baseColor.b];
                    template.roughness = roughness;
                    template.metalness = metalness;
                    template.reflectivity = reflectivity;

                    if(baseColorMap) {
                        template.baseColorMap = baseColorMap;
                    }

                    if(occRoughMetalMap) {
                        template.occRoughMetalMap = occRoughMetalMap;
                    }

                    if(normalMap) {
                        template.normalMap = normalMap;
                    }

                    writeToMaterialDB(materialName, "model", template, false, true);
                }
            }

            //TODO: more template like
            materials.push({
                name: materialName,
                shader,
                type,
                baseColor: [baseColor.r, baseColor.g, baseColor.b],
                baseColorMap,
                roughness,
                metalness,
                occRoughMetalMap,
                normalMap,
                reflectivity
            });
        }

        return materials;
    }

    /** read animation channel from binary */
    private _readAnimationChannel(dv:DataView, version:number) : any {
        const animNodeChunk = this._readChunk(dv, version, REDMODEL_CHUNK_NODEANIM);

        const nodeName:string = this._readString(dv);

        const numPositionKeys = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const numRotationKeys = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const numScalingKeys = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        let interpolationPos = InterpolateLinear;
        let interpolationRot = InterpolateLinear;
        let interpolationScale = InterpolateLinear;

        if(version >= 400) {
            let interpolation = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;

            if(interpolation === REDMODEL_INTERPOLATION_LINEAR) {
                interpolationPos = InterpolateLinear;
            } else {
                interpolationPos = InterpolateSmooth;
            }

            interpolation = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;

            if(interpolation === REDMODEL_INTERPOLATION_LINEAR) {
                interpolationRot = InterpolateLinear;
            } else {
                interpolationRot = InterpolateSmooth;
            }

            interpolation = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;

            if(interpolation === REDMODEL_INTERPOLATION_LINEAR) {
                interpolationScale = InterpolateLinear;
            } else {
                interpolationScale = InterpolateSmooth;
            }
        } else {
            const preState = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            const postState = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        const tracks:any[] = [];

        if(numPositionKeys > 0) {

            const posTrack:any = {};
            posTrack.type = "vector3";
            posTrack.times = [];
            posTrack.values = [];
            posTrack.name = this.fixNodeName(nodeName) + ".position";
            posTrack.interpolation = interpolationPos;

            for(let i = 0; i < numPositionKeys; ++i) {
                const out = this._readVectorKey(dv);

                posTrack.times.push(out.time);
                posTrack.values.push.apply(posTrack.values, out.vector);
            }

            tracks.push(posTrack);
        }

        if(numRotationKeys) {

            const rotateTrack:any = {};
            rotateTrack.type = "quaternion";
            rotateTrack.times = [];
            rotateTrack.values = [];
            rotateTrack.name = this.fixNodeName(nodeName) + ".quaternion";
            rotateTrack.interpolation = interpolationRot;

            for(let i = 0; i < numRotationKeys; ++i) {
                const out = this._readQuatKey(dv);

                rotateTrack.times.push(out.time);
                rotateTrack.values.push.apply(rotateTrack.values, [out.quat[0], out.quat[1], out.quat[2], out.quat[3]]);
            }

            tracks.push(rotateTrack);
        }

        if(numScalingKeys) {

            const scaleTrack:any = {};
            scaleTrack.type = "vector3";
            scaleTrack.times = [];
            scaleTrack.values = [];
            scaleTrack.name = this.fixNodeName(nodeName) + ".scale";
            scaleTrack.interpolation = interpolationScale;

            for(let i = 0; i < numScalingKeys; ++i) {
                const out = this._readVectorKey(dv);

                scaleTrack.times.push(out.time);
                scaleTrack.values.push.apply(scaleTrack.values, out.vector);
            }

            tracks.push(scaleTrack);
        }

        return tracks;
    }

    /** read animation from binary */
    private _readAnimation(dv:DataView, version:number) : ModelAnimation {

        const animChunk = this._readChunk(dv, version, REDMODEL_CHUNK_ANIMATION);

        const animationName:string = this._readString(dv);

        const duration:number = dv.getFloat64(this._offset, this._littleEndian);
        this._offset += 8;

        const tickspersecond:number = dv.getFloat64(this._offset, this._littleEndian);
        this._offset += 8;

        const numChannels = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        const threeJSAnim:any = {};
        threeJSAnim.fps = tickspersecond;
        threeJSAnim.name = animationName;
        threeJSAnim.tracks = [];

        // process channels
        for(let i = 0; i < numChannels; ++i) {
            const tracks = this._readAnimationChannel(dv, version);

            threeJSAnim.tracks = threeJSAnim.tracks.concat(tracks);
        }

        // create clip from data
        const clip = AnimationClip.parse(threeJSAnim) as ModelAnimation;

        //
        clip.modelDuration = duration;

        return clip;
    }

    /** read all animations from binary */
    private _readAnimationsBinary(dv:DataView, version:number) : ModelAnimation[] {

        const numAnimations = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        const animsOut:ModelAnimation[] = [];

        for(let i = 0; i < numAnimations; ++i) {
            const animation = this._readAnimation(dv, version);

            animsOut.push(animation);
        }

        return animsOut;
    }

    /** version 600 meshes */
    private _readStreamData(dv:DataView, version:number) {
        const streamChunk = this._readChunk(dv, version, REDMODEL_CHUNK_STREAMDATA);
        const streamData = {
            vertices:null,
            normals:null,
            qTangents:null,
            color:null,
            uv:null,
            uv2:null,
            indices:null
        };

        let flags = 0;
        if(version >= 610) {
            // stream definition
            flags = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        const numVertices = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        const numNormals = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        const numColors = [];
        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_COLOR_SETS; ++i) {
            numColors[i] =  dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        const numUvs = [];
        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_TEXTURECOORDS; ++i) {
            numUvs[i] =  dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        const numIndices = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        if(numVertices) {
            // 4 byte boundary
            this._offset = (this._offset + 3) & ~(3);
            // directly assign
            const typedArray = new Float32Array(dv.buffer, this._offset, numVertices * 3);
            const positions = new Float32BufferAttribute(typedArray, 3);
            streamData.vertices = positions;
            // in bytes
            this._offset += numVertices * 12;
        }

        if(numNormals) {
            if(version >= 610) {
                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);
                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numNormals * 4);
                const qTangents = new Float32BufferAttribute(typedArray, 4);
                streamData.qTangents = qTangents;
                // in bytes
                this._offset += numNormals * 16;

                // directly assign
                const typedArrayNormals = new Float32Array(numNormals * 3);
                for(let i = 0; i < numNormals; ++i) {
                    //xAxis
                    const fTy = 2.0 * typedArray[i * 4 + 1];
                    const fTz = 2.0 * typedArray[i * 4 + 2];
                    const fTwy = fTy * typedArray[i * 4 + 3];
                    const fTwz = fTz * typedArray[i * 4 + 3];
                    const fTxy = fTy * typedArray[i * 4 + 0];
                    const fTxz = fTz * typedArray[i * 4 + 0];
                    const fTyy = fTy * typedArray[i * 4 + 1];
                    const fTzz = fTz * typedArray[i * 4 + 2];

                    typedArrayNormals[i * 3] = 1.0 - (fTyy + fTzz);
                    typedArrayNormals[i * 3 + 1] = fTxy + fTwz;
                    typedArrayNormals[i * 3 + 2] = fTxz - fTwy;
                }
                const normals = new Float32BufferAttribute(typedArrayNormals, 3);
                streamData.normals = normals;

            } else {
                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);
                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numNormals * 3);
                const normals = new Float32BufferAttribute(typedArray, 3);
                streamData.normals = normals;
                // in bytes
                this._offset += numNormals * 12;
            }
        }

        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_COLOR_SETS; ++i) {
            if(numColors[i]) {
                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);
                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numColors[i] * 3);
                const colors = new Float32BufferAttribute(typedArray, 3);
                streamData['color'] = colors;
                // in bytes
                this._offset += numColors[i] * 12;
            }
        }

        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_TEXTURECOORDS; ++i) {
            if(numUvs[i]) {
                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);

                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numUvs[i] * 2);

                //TODO: only support 2-components uv at the moment
                const uvs = new Float32BufferAttribute(typedArray, 2);

                const attrName = i === 0 ? 'uv' : 'uv'+(i+1);
                streamData[attrName] = uvs;

                // in bytes
                this._offset += (numUvs[i] * 8);
            }
        }

        if(numIndices) {
            // 4 byte boundary
            this._offset = (this._offset + 3) & ~(3);

            // just map for fast loading
            let indices;

            // 16 bit
            if(numVertices < 65536) {
                indices = new Uint16Array(dv.buffer, this._offset, numIndices);
                this._offset += numIndices * 2;
            } else {
                indices = new Uint32Array(dv.buffer, this._offset, numIndices);
                this._offset += numIndices * 4;
            }

            streamData.indices = new BufferAttribute(indices, 1);
        }

        return streamData;
    }

    /** version 600 meshes */
    private _readStreamMeshes(dv:DataView, version:number, streamData:any[]) {

        // read number of meshes
        const numMeshes = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        const meshes:ModelMesh[] = [];

        for(let m = 0; m < numMeshes; ++m) {
            const mesh = this._readStreamMesh(dv, version, streamData);

            meshes[m] = mesh;
        }

        return meshes;
    }

    /** version 600 meshes */
    private _readStreamMesh(dv:DataView, version:number, streams:any[]) : ModelMesh {
        const mesh:ModelMesh = {
            geometry: null,
            materialIndex: -1,
            primitiveType: -1,
            polygons: null,
            selectionSets: null
        };

        const meshChunk = this._readChunk(dv, version, REDMODEL_CHUNK_STREAMMESH);

        const primitiveType = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        const flags = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // vertices
        const startVertex = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const numVertices = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // normals
        const startNormal = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const numNormals = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // indices
        const startIndex = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;
        const numIndices = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // material index
        const materialIndex = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        // colors
        const startColor:number[] = [];
        const numColors:number[] = [];
        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_COLOR_SETS; ++i) {
            startColor[i] = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            numColors[i] = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        // uvs
        const startUV:number[] = [];
        const numUvs:number[] = [];
        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_TEXTURECOORDS; ++i) {
            startUV[i] = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            numUvs[i] = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
        }

        const selectionsets = [];
        for(let s = 0; s < REDMODEL_MAX_NUMBER_OF_SELECTION_SETS; s++) {

            const hasSelectionSet = flags & MESH_HAS_SELECTIONSET(s);

            if(!hasSelectionSet) {
                break;
            }

            const type = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;

            const name = this._readString(dv);

            const count = dv.getUint16(this._offset, this._littleEndian);
            this._offset += 2;

            // 4 byte boundary
            this._offset = (this._offset + 3) & ~(3);

            // directly assign
            //const typedArray = new Uint16Array(dv.buffer, this._offset, count);
            const typedArray = new Uint32Array(dv.buffer, this._offset, count);

            // in bytes
            this._offset += (count * 4);

            // add to list
            selectionsets.push({
                type: type,
                name: name,
                count: count,
                array: typedArray
            });
        }

        // find streams
        let streamDataFlags = MESH_HAS_POSITIONS;
        if(numNormals) {
            streamDataFlags |= MESH_HAS_NORMALS;
        }
        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_TEXTURECOORDS; ++i) {
            if(numUvs[i] > 0) {
                streamDataFlags |= MESH_HAS_TEXCOORD(i);
            }
        }
        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_COLOR_SETS; ++i) {
            if(numColors[i] > 0) {
                streamDataFlags |= MESH_HAS_COLOR(i);
            }
        }
        const streamData = this._findStream(streams, streamDataFlags);
        console.assert(streamData, "missing stream for mesh");

        // generate buffer geometry from stream
        const bufferGeometry = new BufferGeometry();

        if(numVertices) {
            bufferGeometry.addAttribute("position", streamData.vertices);
        }

        if(numNormals) {
            if(streamData.normals) {
                bufferGeometry.addAttribute("normal", streamData.normals);
            }
            if(streamData.qTangents) {
                bufferGeometry.addAttribute("qTangent", streamData.qTangents);
            }
        }

        //TODO: support multiple colors
        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_COLOR_SETS; ++i) {
            if(numColors[i]) {
                bufferGeometry.addAttribute("color", streamData.colors[i]);
            }
        }

        for(let i = 0; i < REDMODEL_MAX_NUMBER_OF_TEXTURECOORDS; ++i) {
            if(numUvs[i]) {
                const attrName = (i === 0) ? "uv" : ("uv"+(i+1));
                bufferGeometry.addAttribute(attrName, streamData[attrName]);
            }
        }

        if(numIndices) {
            // index buffer usage
            bufferGeometry.setIndex(streamData.indices);
            bufferGeometry.setDrawRange(startIndex, numIndices);
        } else {
            // vertices only
            bufferGeometry.setDrawRange(startVertex, numVertices);
        }

        //
        bufferGeometry.boundingBox = new Box3();
        bufferGeometry.boundingSphere = new Sphere();

        // calculate local bounding box
        const position = bufferGeometry.attributes['position'];

        if(position !== undefined) {
            let minX = +Infinity;
            let minY = +Infinity;
            let minZ = +Infinity;

            let maxX = -Infinity;
            let maxY = -Infinity;
            let maxZ = -Infinity;

            if(bufferGeometry.index) {
                const indices = bufferGeometry.index;

                const start = bufferGeometry.drawRange.start;
                const end = Math.min(bufferGeometry.drawRange.count, indices.count) + start;

                for(let j = start; j < end; ++j) {
                    const x = position.getX(indices.getX(j));
                    const y = position.getY(indices.getX(j));
                    const z = position.getZ(indices.getX(j));

                    if( x < minX ) { minX = x; }
                    if( y < minY ) { minY = y; }
                    if( z < minZ ) { minZ = z; }

                    if( x > maxX ) { maxX = x; }
                    if( y > maxY ) { maxY = y; }
                    if( z > maxZ ) { maxZ = z; }
                }
            } else {
                const start = bufferGeometry.drawRange.start;
                const end = Math.min(bufferGeometry.drawRange.count, position.count) + start;

                for(let j = start; j < end; ++j) {
                    const x = position.getX(j);
                    const y = position.getY(j);
                    const z = position.getZ(j);

                    if( x < minX ) { minX = x; }
                    if( y < minY ) { minY = y; }
                    if( z < minZ ) { minZ = z; }

                    if( x > maxX ) { maxX = x; }
                    if( y > maxY ) { maxY = y; }
                    if( z > maxZ ) { maxZ = z; }
                }
            }

            bufferGeometry.boundingBox.min.set( minX, minY, minZ );
            bufferGeometry.boundingBox.max.set( maxX, maxY, maxZ );

            bufferGeometry.boundingBox.getBoundingSphere(bufferGeometry.boundingSphere);
        } else {
            bufferGeometry.boundingBox.makeEmpty();
        }

        mesh.primitiveType = primitiveType;
        mesh.materialIndex = materialIndex;
        mesh.geometry = bufferGeometry;
        mesh.selectionSets = selectionsets;

        return mesh;
    }

    /** get stream for mesh stream */
    private _findStream(streams:any[], flags:number) : any {
        const meshHasPositions = (flags & MESH_HAS_POSITIONS) === MESH_HAS_POSITIONS;
        const meshHasNormals = (flags & MESH_HAS_NORMALS) === MESH_HAS_NORMALS;
        const meshHasTangentsBitangents = (flags & MESH_HAS_QTANGENTS) === MESH_HAS_QTANGENTS;
        const meshHasTexcoords0 = !!(flags & MESH_HAS_TEXCOORD(0));
        const meshHasTexcoords1 = !!(flags & MESH_HAS_TEXCOORD(1));
        const meshHasColors0 = flags & MESH_HAS_COLOR(0);
        const meshHasColors1 = flags & MESH_HAS_COLOR(1);

        for(const stream of streams) {
            const streamHasPositions = !!stream.vertices;
            const streamHasNormals = !!stream.normals || !!stream.qTangents;
            const streamHasTexcoords0 = !!stream.uv;
            const streamHasTexcoords1 = !!stream.uv2;

            if(meshHasPositions === streamHasPositions &&
               meshHasNormals === streamHasNormals &&
               meshHasTexcoords0 === streamHasTexcoords0 &&
               meshHasTexcoords1 === streamHasTexcoords1) {
                return stream;
            }
        }
        // just for backward compatible to old 610 version...
        if(streams.length === 1) {
            return streams[0];
        }
        return null;
    }

    /** read meshes from binary (only version under 600) */
    private _readMeshesOld(dv:DataView, version:number, polygonize:number) {

        // read number of meshes
        const numMeshes = dv.getUint32(this._offset, this._littleEndian);
        this._offset += 4;

        const meshes:Array<any> = [];

        for(let m = 0; m < numMeshes; ++m) {

            const meshChunk = this._readChunk(dv, version, REDMODEL_CHUNK_MESH);

            const primitiveType = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            const numVertices = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            const numIndices = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            const numBones = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            const materialIndex = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;
            const flags = dv.getUint32(this._offset, this._littleEndian);
            this._offset += 4;

            const hasPositions = (flags & MESH_HAS_POSITIONS) === MESH_HAS_POSITIONS;
            const hasNormals = (flags & MESH_HAS_NORMALS) === MESH_HAS_NORMALS;
            const hasTangentsBitangents = (flags & MESH_HAS_QTANGENTS) === MESH_HAS_QTANGENTS;
            const hasTexcoords0 = flags & MESH_HAS_TEXCOORD(0);
            const hasTexcoords1 = flags & MESH_HAS_TEXCOORD(1);
            const hasColors0 = flags & MESH_HAS_COLOR(0);
            const hasColors1 = flags & MESH_HAS_COLOR(1);

            const bufferGeometry = new BufferGeometry();
            const polygons = [];
            const selectionsets = [];
            //HACK here
            //bufferGeometry.materialindex = materialIndex;

            //TODO: optimize loading to just use typed arrays which references global blob array

            // read vertex positions
            if(hasPositions) {

                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);

                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numVertices * 3);

                const positions = new Float32BufferAttribute(typedArray, 3);

                bufferGeometry.addAttribute('position', positions);
                // in bytes
                this._offset += numVertices * 12;

            }

            if(hasNormals) {
                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);

                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numVertices * 3);

                const normals = new Float32BufferAttribute(typedArray, 3);

                bufferGeometry.addAttribute('normal', normals);
                // in bytes
                this._offset += numVertices * 12;
            }

            if(hasTangentsBitangents) {
                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);

                // // directly assign
                // const typedArray = new Float32Array(dv.buffer, this._offset, numVertices * 4);
                // const normals = new Float32BufferAttribute(typedArray, 4);
                // bufferGeometry.addAttribute('normal', normals);

                // in bytes
                this._offset += numVertices * 16;
            }

            //TODO: add support for more than one color buffer
            for(let c = 0; c < REDMODEL_MAX_NUMBER_OF_COLOR_SETS; ++c) {
                const hasColors = flags & MESH_HAS_COLOR(c);

                if(!hasColors) {
                    break;
                }

                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);

                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numVertices * 3);

                const colors = new Float32BufferAttribute(typedArray, 3);

                bufferGeometry.addAttribute('color', colors);

                // in bytes
                this._offset += numVertices * 12;
            }

            for(let c = 0; c < REDMODEL_MAX_NUMBER_OF_TEXTURECOORDS; ++c) {
                const hasTexcoords = flags & MESH_HAS_TEXCOORD(c);

                if(!hasTexcoords) {
                    break;
                }

                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);

                // directly assign
                const typedArray = new Float32Array(dv.buffer, this._offset, numVertices * 2);

                //TODO: only support 2-components uv at the moment
                const uvs = new Float32BufferAttribute(typedArray, 2);

                const attrName = c === 0 ? 'uv' : 'uv'+(c+1);
                bufferGeometry.addAttribute(attrName, uvs);

                // in bytes
                this._offset += (numVertices * 8);

            }

            for(let s = 0; s < REDMODEL_MAX_NUMBER_OF_SELECTION_SETS; s++) {

                const hasSelectionSet = flags & MESH_HAS_SELECTIONSET(s);

                if(!hasSelectionSet) {
                    break;
                }

                const type = dv.getUint32(this._offset, this._littleEndian);
                this._offset += 4;

                const name = this._readString(dv);

                const count = dv.getUint16(this._offset, this._littleEndian);
                this._offset += 2;

                // 4 byte boundary
                this._offset = (this._offset + 3) & ~(3);

                // directly assign
                //const typedArray = new Uint16Array(dv.buffer, this._offset, count);
                const typedArray = new Uint32Array(dv.buffer, this._offset, count);

                // in bytes
                this._offset += (count * 4);

                // add to list
                selectionsets.push({
                    type: type,
                    name: name,
                    count: count,
                    array: typedArray
                });
            }

            if(numIndices > 0) {

                if(polygonize) {
                    let numFaces = numIndices;
                    // when not triangle or line the numindices should be faces count of polygons
                    if(primitiveType === MODELMESH_PRIMITIVE_TRIANGLE) {
                        numFaces /= 3;
                    } else if(primitiveType === MODELMESH_PRIMITIVE_LINE) {
                        numFaces /= 2;
                    }

                    // first read counts
                    const indexSize = numVertices < 65536 ? 2 : 4;
                    let tmpOffset = this._offset;
                    let tmpIndexCount = 0;
                    for(let f = 0; f < numFaces; ++f) {
                        const numItems = dv.getUint16(tmpOffset, this._littleEndian);
                        tmpOffset += 2;
                        // 16/32 bit
                        tmpOffset += (indexSize * numItems);
                        tmpIndexCount += (numItems - 2) * 3;
                    }

                    // index buffer (triangles)
                    const TypeArray = numVertices > 65535 ? Uint32Array : Uint16Array;
                    const indices = new TypeArray(tmpIndexCount);
                    // max indices per face
                    const tmpIndices = new TypeArray(24);

                    // create polygon list
                    polygons.length = numFaces;

                    let e = 0;
                    for(let f = 0; f < numFaces; ++f) {

                        const numItems = dv.getUint16(this._offset, this._littleEndian);
                        this._offset += 2;

                        // 16 bit
                        if(numVertices < 65536) {
                            // read indices
                            for(let i = 0; i < numItems; ++i) {
                                tmpIndices[i] = dv.getUint16(this._offset, this._littleEndian);
                                this._offset += 2;
                            }

                            // add faces to polygons
                            polygons[f] = tmpIndices.slice(0, numItems);

                            // triangulate
                            const triNumTris = (numItems - 2); // * 3;
                            for(let i = 0; i < triNumTris; ++i) {

                                indices[e + 0] = tmpIndices[0];
                                indices[e + 1] = tmpIndices[i+1];
                                indices[e + 2] = tmpIndices[i+2];

                                e += 3;
                            }

                        } else {
                            //TODO
                            for(let i = 0; i < numItems; ++i) {
                                indices[e] = dv.getUint32(this._offset, this._littleEndian);
                                this._offset += 4;
                                e++;
                            }
                        }

                        bufferGeometry.setIndex(new BufferAttribute(indices, 1));
                    }

                } else {

                    // 4 byte boundary
                    this._offset = (this._offset + 3) & ~(3);

                    // just map for fast loading
                    let indices;

                    // 16 bit
                    if(numVertices < 65536) {
                        indices = new Uint16Array(dv.buffer, this._offset, numIndices);
                        this._offset += numIndices * 2;
                    } else {
                        indices = new Uint32Array(dv.buffer, this._offset, numIndices);
                        this._offset += numIndices * 4;
                    }

                    bufferGeometry.setIndex(new BufferAttribute(indices, 1));
                }
            }

            for(let b = 0; b < numBones; ++b) {
                const chunkID = dv.getUint32(this._offset, this._littleEndian);
                this._offset += 2;

                const boneName:string = this._readString(dv);
                let numWeights:number;
                //var boneWeights;
                let offsetMatrix:any;

                numWeights = dv.getUint32(this._offset, this._littleEndian);
                this._offset += 4;
                offsetMatrix = this._readMatrix(dv);

                // read weights
                for(let w = 0; w < numWeights; ++w) {
                    const vertexWeight = this._readVertexWeight(dv);

                    //TODO...
                }
            }

            // generate qtangents on the fly
            if(hasTexcoords0 && hasNormals) {
                generateQTangent(bufferGeometry);
            }

            bufferGeometry.computeBoundingSphere();

            // propogate material index
            meshes[m] = {
                geometry: bufferGeometry,
                materialIndex: materialIndex,
                primitiveType: primitiveType,
                polygons: polygons,
                selectionSets: selectionsets
            };
        }

        return meshes;
    }

    public setCrossOrigin(value:string) {
        this.crossOrigin = value;
    }

    public setTexturePath(value:string) {
        this.texturePath = value;
    }

    public extractUrlBase(url:string) {
        // from three/src/loaders/Loader.js
        const parts = url.split( '/' );
        parts.pop();
        return (parts.length < 1 ? '.' : parts.join('/')) + '/';
    }

    // fix node names for animation refering
    private fixNodeName(name:string) {
        if(this.fixNodeNames) {
            return name.replace("$AssimpFbx$", "AssimpFbx");
        } else {
            return name;
        }
    }

}

registerLoader("redModel", "red", EAssetType.Model, ModelLoader);
