/**
 * Layers.ts: predefined layers
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */

/**
 * predefined layers
 */
export enum ERenderLayer {
    /** Background layer */
    Background = 0,
    /** world layer */
    World = 1,
    /** debugging layers */
    Widgets = 30,
    Debug = 31,
    /** MAX */
    MAX = 32
}

/** default layer mask */
export function defaultRenderLayerMask() : number {
    let result = 0;
    // defaults
    result |= 1 << ERenderLayer.Background;
    result |= 1 << ERenderLayer.World;
    // user
    result |= 1 << 2;
    result |= 1 << 3;
    result |= 1 << 4;
    result |= 1 << 5;
    result |= 1 << 6;
    result |= 1 << 7;
    result |= 1 << 8;
    result |= 1 << 9;
    result |= 1 << 10;
    result |= 1 << 11;
    result |= 1 << 12;
    result |= 1 << 13;
    result |= 1 << 14;
    result |= 1 << 15;
    result |= 1 << 16;
    result |= 1 << 17;
    result |= 1 << 18;
    result |= 1 << 19;
    result |= 1 << 20;
    result |= 1 << 21;
    result |= 1 << 22;
    result |= 1 << 23;
    result |= 1 << 24;
    result |= 1 << 25;
    result |= 1 << 26;
    result |= 1 << 27;
    result |= 1 << 28;
    result |= 1 << 29;
    return result;
}

/** all layer mask */
export function allRenderLayerMask() : number {
    return 0xFFFFFFFF;
}

/** debug layer mask */
export function debugRenderLayerMask() : number {
    let result = 0;
    result |= 1 << ERenderLayer.Widgets;
    result |= 1 << ERenderLayer.Debug;
    return result;
}

/** convert to bit mask */
export function layerToMask(layer:number|ERenderLayer) {
    return 1 << layer;
}

/**
 * render order bitmask setup
 * BIT 32                                     16                   0
 *     0        0000       0000 0000 000    0 0000 0000 0000 00000
 *  RESERVED  shader id     material id             user id
 *              4 BIT           11 BIT              16 BIT
 */
// export const _RENDER_ORDER_MATERIAL_BITS = 16; // 16 - 26
// export const _RENDER_ORDER_MATERIAL_MASK = 0x07FF0000;
// export const _RENDER_ORDER_SHADER_BITS = 27; // 27 - 31
// export const _RENDER_ORDER_SHADER_MASK = 0x78000000;
// export const _RENDER_ORDER_USER_BITS = 0; // 0 - 15
// export const _RENDER_ORDER_USER_MASK = 0x0000FFFF;
/**
 * render order bitmask setup
 * BIT 32     31           24         16     12             0
 *     0        000 000    0 0000 0000 0000   0000 0000 0000
 *  RESVERED  shader id       material id         user id
 *               6 BIT           13 BIT           12 BIT
 */
export const RENDER_ORDER_MATERIAL_BITS = 12; // 12 - 24
export const RENDER_ORDER_MATERIAL_MASK = 0x01FFF000;
export const RENDER_ORDER_SHADER_BITS = 25; // 25 - 31
export const RENDER_ORDER_SHADER_MASK = 0x7E000000;
export const RENDER_ORDER_USER_BITS = 0; // 0 - 11
export const RENDER_ORDER_USER_MASK = 0x00000FFF;
