export default {
    // these values will overwrite the defaults. For a full list, have a look
    // at the `src/translations` directory of this repo:
    // https://github.com/KIProtect/klaro/tree/master/src/translations
    zz: {
        privacyPolicyUrl: "/datenschutzerklaerung",
    },
    de: {
        acceptAll: "Alle akzeptieren",
        acceptSelected: "Ausgewählte akzeptieren",
        close: "Schließen",
        consentModal: {
            description: " ",
            privacyPolicy: {
                name: "Datenschutzerklärung",
                text: "Um mehr zu erfahren, ließ Sie bitte unsere {privacyPolicy}.",
            },
            title: "Privatsphäre-Einstellungen",
        },
        consentNotice: {
            changeDescription: "Seit deinem letzten Besuch gab es Änderungen, bitte erneue deine Zustimmung.",
            title: "Privatsphäre-Einstellungen",
            description: `
                <span>Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten von dir (z.B. IP-Adresse), um z.B. Inhalte und Anzeigen zu personalisieren, Medien von Drittanbietern einzubinden oder Zugriffe auf unsere Website zu analysieren. Die Datenverarbeitung kann auch erst in Folge gesetzter Cookies stattfinden. Wir teilen diese Daten mit Dritten, die wir in den Privatsphäre-Einstellungen benennen.<br><br>Die Datenverarbeitung kann mit deiner Einwilligung oder auf Basis eines berechtigten Interesses erfolgen, dem du in den Privatsphäre-Einstellungen widersprechen kannst. Du hast das Recht, nicht einzuwilligen und deine Einwilligung zu einem späteren Zeitpunkt zu ändern oder zu widerrufen. Weitere Informationen zur Verwendung deiner Daten findest du in unserer {privacyPolicy}.<br><br>Im Folgenden findest du eine Übersicht über alle Services, die von dieser Website genutzt werden. Du kannst dir detaillierte Informationen zu jedem Service ansehen und ihm einzeln zustimmen oder von deinem Widerspruchsrecht Gebrauch machen.</span>
                <span style="margin-top: 7px; display: inline-block; font-size: 10px; color: rgb(124, 124, 124);">Einige Services verarbeiten personenbezogene Daten in den USA. Indem du der Nutzung dieser Services zustimmst, erklärst du dich auch mit der Verarbeitung deiner Daten in den USA gemäß Art. 49 (1) lit. a DSGVO einverstanden. Die USA werden vom EuGH als ein Land mit einem unzureichenden Datenschutz-Niveau nach EU-Standards angesehen. Insbesondere besteht das Risiko, dass deine Daten von US-Behörden zu Kontroll- und Überwachungszwecken verarbeitet werden – unter Umständen ohne die Möglichkeit eines Rechtsbehelfs.</span>
            `,
            imprint: {
                name: "Impressum",
            },
            learnMore: "Einstellungen individuell festlegen",
            privacyPolicy: {
                name: "Datenschutzerklärung",
            },
            testing: "Testmodus!",
        },
        contextualConsent: {
            acceptAlways: "Immer",
            acceptOnce: "Ja",
            description: "Möchtest Du diesen von {title} bereitgestellten externen Inhalt laden?",
        },
        ok: "Alle akzeptieren",
        decline: "Weiter ohne Einwilligung",
        poweredBy: "Realisiert mit Klaro!",
        privacyPolicy: {
            name: "Datenschutzerklärung",
            text: "Um mehr zu erfahren, ließ Sie bitte unsere {privacyPolicy}.",
        },
        purposeItem: {
            service: "Dienst",
            services: "Dienste",
        },
        purposes: {
            essential: {
                title: "Essenziell",
                description: "Essenzielle Services sind für die grundlegende Funktionalität der Website erforderlich. Sie enthalten nur technisch notwendige Services. Diesen Services kann nicht widersprochen werden.",
            },
            functional: {
                title: "Funktional",
                description: "Funktionale Services sind notwendig, um über die wesentliche Funktionalität der Website hinausgehende Features wie hübschere Schriftarten, Videowiedergabe oder interaktive Web 2.0-Features bereitzustellen. Inhalte von z.B. Video- und Social Media-Plattformen sind standardmäßig gesperrt und können zugestimmt werden. Wenn dem Service zugestimmt wird, werden diese Inhalte automatisch ohne weitere manuelle Einwilligung geladen.",
            },
            analytics: {
                title: "Statistik",
                description: "Statistik-Services werden benötigt, um pseudonymisierte Daten über die Besucher der Website zu sammeln. Die Daten ermöglichen es uns, die Besucher besser zu verstehen und die Website zu optimieren.",
            },
            marketing: {
                title: "Marketing",
                description: "Marketing Services werden von uns und Dritten genutzt, um das Verhalten einzelner Nutzer aufzuzeichnen, die gesammelten Daten zu analysieren und z.B. personalisierte Werbung anzuzeigen. Diese Services ermöglichen es uns, Nutzer über mehrere Websites hinweg zu verfolgen.",
            },
        },
        save: "Speichern",
        service: {
            disableAll: {
                description: "Mit diesem Schalter können Sie alle Dienste aktivieren oder deaktivieren.",
                title: "Alle Dienste aktivieren oder deaktivieren",
            },
            optOut: {
                description: "Diese Dienste werden standardmäßig geladen (Du kannst dich jedoch abmelden)",
                title: "(Opt-out)",
            },
            purpose: "Zweck",
            purposes: "Zwecke",
            required: {
                description: "Dieser Service ist immer erforderlich",
                title: "(immer erforderlich)",
            },
        },
    },
    en: {
        acceptAll: "Accept all",
        acceptSelected: "Accept selected",
        close: "Close",
        consentModal: {
            description: " ",
            privacyPolicy: {
                name: "Privacy policy",
                text: "To learn more, please read our {privacyPolicy}.",
            },
            title: "Privacy settings",
        },
        consentNotice: {
            changeDescription: "There have been changes since your last visit, please renew your approval.",
            title: "Privacy settings",
            description: `
                <span>We use cookies and similar technologies on our website and process personal data about you (e.g. IP address), e.g. to personalize content and ads, to integrate media from third-party providers or to analyze access to our website. Data processing may also take place only as a result of cookies set. We share this data with third parties that we designate in the privacy settings.<br><br>Data processing may be carried out with your consent or on the basis of a legitimate interest, which you can object to in the privacy settings. You have the right not to consent and to change or withdraw your consent at a later date. For more information on how we use your data, please see our {privacyPolicy}.<br><br>Below you will find an overview of all services used by this website. You can view detailed information about each service and agree to it individually or exercise your right to object.</span>
                <span style="margin-top: 7px; display: inline-block; font-size: 10px; color: rgb(124, 124, 124);">Some services process personal data in the USA. By agreeing to the use of these services, you also consent to the processing of your data in the USA in accordance with Art. 49 (1) lit. a DSGVO. The USA is considered by the EuGH to be a country with an insufficient level of data protection according to EU standards. In particular, there is a risk that your data will be processed by US authorities for control and monitoring purposes - possibly without the possibility of a legal remedy.</span>
            `,
            imprint: {
                name: "Imprint",
            },
            learnMore: "Set settings individually",
            privacyPolicy: {
                name: "Privacy policy",
            },
            testing: "Test mode!",
        },
        contextualConsent: {
            acceptAlways: "Always",
            acceptOnce: "Yes",
            description: "Want to load this external content provided by {title}?",
        },
        ok: "Accept all",
        decline: "Continue without consent",
        poweredBy: "Realized with Klaro!",
        privacyPolicy: {
            name: "Privacy policy",
            text: "To learn more, please read our {privacyPolicy}.",
        },
        purposeItem: {
            service: "Service",
            services: "Services",
        },
        purposes: {
            essential: {
                title: "Essential",
                description: "Essential services are required for the basic functionality of the website. They contain only technically necessary services. These services cannot be objected to.",
            },
            functional: {
                title: "Functional",
                description: "Functional services are necessary to provide features beyond the essential functionality of the website, such as prettier fonts, video playback, or interactive Web 2.0 features. Content from video and social media platforms, for example, is blocked by default and can be consented to. When the service is consented, this content is automatically loaded without further manual consent.",
            },
            analytics: {
                title: "Analytics",
                description: "Analytics services are needed to collect pseudonymous data about visitors to the website. The data allows us to better understand the visitors and optimize the website.",
            },
            marketing: {
                title: "Marketing",
                description: "Marketing Services are used by us and third parties to record the behavior of individual users, analyze the data collected and, for example, display personalized advertising. These services enable us to track users across multiple websites.",
            },
        },
        save: "Save",
        service: {
            disableAll: {
                description: "With this switch you can enable or disable all services.",
                title: "Enable or disable all services",
            },
            optOut: {
                description: "These services are loaded by default (but you can opt out)",
                title: "(Opt-out)",
            },
            purpose: "Purpose",
            purposes: "Purposes",
            required: {
                description: "This service is always required",
                title: "(always required)",
            },
        },
    },
}