/**
 * Plugin.ts: Plugin logic
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { hash } from "../core/Hash";
import { build } from "../core/Build";

/**
 * API Table
 */
const REDAPI = window.REDAPI || {};

/**
 * general plugin id
 */
export interface PluginId {
    name:string;
    api:number;
}

/**
 * create generic api id
 */
export function makeAPI(name:string) : PluginId {
    return {
        name,
        api: hash(name)
    };
}

/**
 * register new API object
 * @param api API hash id
 * @param object instance of API
 */
export function registerAPI<T extends any>(apiId:PluginId, object:T) {
    if(REDAPI[apiId.api]) {
        console.error("Plugin: already registered plugin api: " + apiId.name);
        return;
    }

    REDAPI[apiId.api] = object;

    // browser debug api
    if(build.Options.development && typeof window !== 'undefined') {
        console.info(`Plugin: api registered ${apiId.name} with API ID (${apiId.api})`);
        window.RED = window.RED || {};
        window.RED[apiId.name] = object;
    }
}

/**
 * query a API
 * @param api apu hash id
 */
export function queryAPI<T extends any>(api:number|PluginId) : T {
    let apiId:number;
    if(typeof api === 'number') {
        apiId = api;
    } else {
        apiId = api.api;
    }
    return REDAPI[apiId];
}

/** default api */
export interface IPluginAPI {
    registerAPI<T extends any>(api:PluginId, object:T):void;
    queryAPI<T extends any>(api:number|PluginId) : T;
}
export const PLUGIN_API = makeAPI("IPluginAPI");
registerAPI<IPluginAPI>(PLUGIN_API, {queryAPI, registerAPI});
