/**
 * ModelFileFormat.ts: model file format + loading
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Quaternion } from "../../lib/threejs/math/Quaternion";
import { BufferGeometry } from "../../lib/threejs/core/BufferGeometry";
import { AnimationClip } from "../../lib/threejs/animation/AnimationClip";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { MaterialDesc } from "../render/Material";

export interface ModelNode {
    name:string;
    children: ModelNode[];
    position: Vector3;
    quaternion: Quaternion;
    scale: Vector3;
    meshes: number[];
}

export interface ModelMesh {
    geometry: BufferGeometry;
    primitiveType: number;
    materialIndex: number;
    material?:string;
    //drawRange: { count: number, start: number };
    polygons: any[];
    selectionSets: any[];
}

export const MODELMESH_PRIMITIVE_LINE = 0x2;
export const MODELMESH_PRIMITIVE_TRIANGLE = 0x4;

export interface ModelAnimation extends AnimationClip {
    /** imported duration */
    modelDuration: number;
}

/**
 * model data that got loaded
 */
export interface ModelData {
    /** ModelNode hierarchy */
    nodes: ModelNode;
    /** THREE.js node object */
    hierarchy?: Object3D;
    /** meshes references */
    meshes: ModelMesh[];
    /** animations */
    animations: ModelAnimation[];
    /** materials */
    materials: MaterialDesc[];
}

export type ModelLoadCallback = (result:ModelData) => void;
export type ModelProgressCallback = (result:ModelData) => void;
export type ModelErrorCallback = (err:any) => void;

/**
 * Model loading interface
 */
export interface IModelLoader {

    crossOrigin: string;

    /** load from url */
    load(url:string, reference:string, onLoad:ModelLoadCallback, onProgress:ModelProgressCallback, onError:ModelErrorCallback) : void;

    /** load from in memory */
    loadFromMemory(buffer:ArrayBuffer, reference:string, onLoad:ModelLoadCallback, onProgress:ModelProgressCallback, onError:ModelErrorCallback);
}
