/**
 * MeshAPI.ts: mesh/model management
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { MeshDB } from "../io/AssetInfo";
import { AsyncLoad } from "../io/AsyncLoad";
import { makeAPI, queryAPI } from "../plugin/Plugin";
import { ModelData } from "../framework-types/ModelFileFormat";

/**
 * Mesh Library handling
 */
export interface IMeshSystem {
    /**
     * flush memory on the gpu,
     * does not destroy memory on client side
     */
    flushGPUMemory();

    /**
     * async mesh loading
     * @param filename filename
     * @param loaderIdentifier optional loader identifier
     */
    loadMesh(filename:string, loaderIdentifier?:string) : AsyncLoad<ModelData>;

    /**
     * add a mesh to asset management
     * @param name reference name
     * @param content content data
     * @param type not supported right now
     */
    addMesh(name:string, content:string|ArrayBuffer|Blob|ModelData, loaderIdentifier?:string);

    /**
     * preload model data with material data
     * @param name filename
     * @param loaderIdentifier
     */
    preloadModel(name:string, loaderIdentifier?:string) : AsyncLoad<void>;
}
export const MESHSYSTEM_API = makeAPI("IMeshSystem");

let _globalMeshSystem:IMeshSystem;
export function queryMeshSystem() {
    if(_globalMeshSystem === undefined) {
        _globalMeshSystem = queryAPI<IMeshSystem>(MESHSYSTEM_API);
    }
    return _globalMeshSystem;
}

/** Global Mesh Import DB */
export let MeshImportDB:{[key:string]:MeshDB} = window['MeshImportDB'] || {};

/**
 * apply import settings to mesh loader
 * @param loader loader
 * @param importName name of imported mesh
 */
export function applyImportSettingsMesh(loader:any, importName:string) {
    if(MeshImportDB[name]) {

        loader.autoShrink = applyValue(MeshImportDB[name].autoShrink, true);
        loader.texturePath = applyValue(MeshImportDB[name].texturePath, 'textures');
        loader.autoLoadTextures = applyValue(MeshImportDB[name].autoLoadTextures, false);
        loader.colorRGBToIndex = applyValue(MeshImportDB[name].colorRGBToIndex, false);
        loader.useGeometryBuffer = applyValue(MeshImportDB[name].useGeometryBuffer, true);

    } else {
        // default settings
        loader.texturePath = 'textures';
        loader.autoShrink = true;
        loader.autoLoadTextures = false;
        loader.colorRGBToIndex = false;
        loader.useGeometryBuffer = true;
    }
}

/** helper function */
function applyValue(value:any, defaultValue:any) {
    if(value !== undefined) {
        return value;
    } else {
        return defaultValue;
    }
}
