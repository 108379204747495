/**
 * UpdateAPI.ts: main loop API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { WorldSystem } from "./WorldAPI";
import { ComponentId } from "./Component";
import { queryAPI, makeAPI } from "../plugin/Plugin";

/**
 * Component Update System
 */
export interface IComponentUpdateSystem extends WorldSystem {

    /** needs think state */
    isActive(id:ComponentId): boolean;

    activate(id:ComponentId) : void;
    deactivate(id:ComponentId) : void;

    registerCallback(think:() => void) : ComponentId;
    removeCallback(id:ComponentId) : void;

    think(deltaSeconds:number) : void;
}
export const COMPONENTUPDATESYSTEM_API = makeAPI("IComponentUpdateSystem");

let _globalComponentUpdateSystem:IComponentUpdateSystem;
export function queryComponentUpdateSystem() {
    if(_globalComponentUpdateSystem === undefined) {
        _globalComponentUpdateSystem = queryAPI<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API);
    }
    return _globalComponentUpdateSystem;
}
