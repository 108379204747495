/**
 * Config.ts: Render configuration
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { RenderQuality } from "./QualityLevels";
import { Render } from "./Render";
import { Platform } from "../core/Platform";

/** unified rendering size */
export interface RenderSize {
    /** width (with device ratio) */
    width: number;
    /** height (with device ratio) */
    height: number;
    /** client width (without device ratio) */
    clientHeight?:number;
    /** client height (without device ratio) */
    clientWidth?:number;
    /** device pixel ratio */
    dpr?:number;
}

/** quality settings callback */
export type QualityLevelCallback = (render:Render) => number;
export type RenderCallback = (render:Render) => void;

/**
 * Render interface initialization data
 */
export interface RenderInitSetup {
    DOMElement?:Element;
    /** optional quality settings (use global settings per default) */
    qualityLevel?:number | QualityLevelCallback;
    renderShadowMaps?: boolean;
    renderAntialias?: boolean;
    renderRetina?: boolean;
    /**
     * canvas supports alpha channel
     * TODO: add support for depth/stencil
     */
    canvasHasAlpha?: boolean;
    /**
     * do not render to screen
     */
   renderOffscreen?: boolean;
   /** optional: custom rendering size */
   renderSize?: RenderSize;
}

/**
 * create render init config
 * @param base base configuration
 */
export function createPlatformRenderInitConfig(base?:RenderInitSetup) {
    base = base || {};

    //TODO: iOS fix -> remove this, ask for anti alias support...
    if(Platform.get().isiOS && base.renderAntialias) {
        base.renderAntialias = false;
    }

    // when on desktop, disable retina rendering when doing anti aliasing
    if(!Platform.get().isMobile && base.renderAntialias) {
        base.renderRetina = false;
    }

    base.DOMElement = base.DOMElement || null;
    base.renderShadowMaps = base.renderShadowMaps || false;
    base.canvasHasAlpha = base.canvasHasAlpha || false;
    base.qualityLevel = base.qualityLevel !== undefined ? base.qualityLevel : RenderQuality.HighQuality;
    base.renderOffscreen = base.renderOffscreen || false;
    base.renderSize = base.renderSize || null;
    return base;
}

/**
 * setup render configuration
 */
export interface RenderingSetup {
    /** DOM Element reference */
    DOMElement?:Element;
    /** optional quality settings (use global settings per default) */
    qualityLevel?:number | QualityLevelCallback;
    /**
     * allow FXAA
     * only allowed when no antialias rendering active
     *
     */
    renderFXAA?: boolean;
    /**
     * allow TAA
     * only allowed when no antialias rendering active
     *
     */
    renderTAA?: boolean;
    /**
     * allow SSAO rendering
     * only allowed when no antialias rendering active
     */
    renderSSAO?: boolean;
    /**
     * render simple depth of field
     */
    renderDOF?: boolean;
    /**
     * do not render to screen
     */
    renderOffscreen?: boolean;
    /** optional rendering size */
    renderSize?: RenderSize;
    /** tone mapping settings */
    gammaFactor?: number;
}

/**
 * render configuration helper class
 */
export class RenderConfiguration implements RenderingSetup {

    public static Default() : RenderConfiguration {
        if(!RenderConfiguration._Default) {
            RenderConfiguration._Default = new RenderConfiguration();
        }
        return RenderConfiguration._Default;
    }
    private static _Default: RenderConfiguration;

    public DOMElement:Element = null;
    /** optional quality settings (use global settings per default) */
    public qualityLevel:number | QualityLevelCallback = RenderQuality.HighQuality;
    /**
     * canvas supports alpha channel
     */
    public canvasHasAlpha: boolean = false;
    /**
     * allow FXAA
     * only allowed when no antialias rendering active
     *
     */
    public renderFXAA: boolean = false;
    /**
     * allow TAA
     * only allowed when no antialias rendering active
     *
     */
    public renderTAA: boolean = false;
    /**
     * allow SSAO rendering
     * only allowed when no antialias rendering active
     */
    public renderSSAO: boolean = false;
    /**
     * render simple depth of field
     */
    public renderDOF: boolean = false;
    /** using cinematic camera */
    public cinematicCamera: boolean = false;
    /**
     * do not render to screen
     */
    public renderOffscreen: boolean = false;
    /** screen/canvas size */
    public renderSize: RenderSize;
    /** tone mapping settings */
    public gammaFactor: number = 2.0;

    /** construction */
    constructor(settings?:RenderingSetup) {
        settings = settings || {};

        // //TODO: iOS fix -> remove this, ask for anti alias support...
        // if(Platform.get().isiOS && settings.renderAntialias) {
        //     settings.renderAntialias = false;
        //     settings.renderFXAA = true;
        // }

        this.DOMElement = settings.DOMElement || null;

        this.qualityLevel = settings.qualityLevel !== undefined ? settings.qualityLevel : RenderQuality.HighQuality;
        this.renderFXAA = settings.renderFXAA || false;
        this.renderSSAO = settings.renderSSAO || false;
        this.renderDOF = settings.renderDOF || false;
        this.renderTAA = settings.renderTAA || false;
        this.renderOffscreen = settings.renderOffscreen || false;

        this.renderSize = settings.renderSize || null;
        this.gammaFactor = settings.gammaFactor || 2.0;
    }

    /** rendering post processing */
    public get renderPostProcessing():boolean {
        return this.renderFXAA || this.renderSSAO || this.renderDOF || this.renderTAA;
    }
}
