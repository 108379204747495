///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class ContactComponent extends AbstractBlockComponent {

    private _inputs:any;
    private _errors:any;
    private _formElement:any;
    private _statusElement:any;
    private _statusMessage:any;
    private _btnSend:any;
    private _btnSignup:any;
    private _messageMaxCharacters:number;
    private _messageLeftCharactersElement:any;
    private _disabled:boolean;
    private _loading:boolean;

    private set disabled(value:boolean){
        if(value == this._disabled) return;
        this._disabled = value;

        _.each(this._inputs,(input)=>{
            value ? input.setAttribute('disabled',true) : input.removeAttribute('disabled');
        });
    }

    private get disabled():boolean{
        return this._disabled;
    }

    private set loading(value:boolean){
        if(value == this._loading) return;
        this._loading = value;
        this._loading ?  this._formElement.classList.add('loading') : this._formElement.classList.remove('loading');
    }

    private get loading():boolean{
        return this._loading;
    }

    protected onInit():void{
        //console.log("ContactComponent");

        this._formElement = this._block.domElement.querySelector(".contact_form");
        this._statusElement = this._block.domElement.querySelector(".contact_form_status");
        this._statusMessage = this._statusElement.querySelector("h2");

        this._inputs = {
            contact_type_mail :     this._block.domElement.querySelector("#contact_type_mail"),
            contact_type_phone :    this._block.domElement.querySelector("#contact_type_phone"),
            contact_data_mail:      this._block.domElement.querySelector("#contact_data_mail"),
            contact_data_phone:     this._block.domElement.querySelector("#contact_data_phone"),
            contact_data_subject:   this._block.domElement.querySelector("#contact_data_subject"),
            contact_data_message:   this._block.domElement.querySelector("#contact_data_message"),
            contact_data_name:      this._block.domElement.querySelector("#contact_data_name"),
            contact_data_forename:  this._block.domElement.querySelector("#contact_data_forename"),
            contact_data_company:   this._block.domElement.querySelector("#contact_data_company"),
            contact_data_gdpr:      this._block.domElement.querySelector("#contact_data_gdpr"),
            contact_form_captcha:      this._block.domElement.querySelector("#contact_form_captcha")
        };

        this._errors = {
            error_type_mail : this._block.domElement.querySelector("#contact_data_mail_error"),
            error_type_phone : this._block.domElement.querySelector("#contact_data_phone_error"),
            error_type_message : this._block.domElement.querySelector("#contact_data_message_error")
        }

        this._messageLeftCharactersElement = this._block.domElement.querySelector("#contact_data_message_charleft");
        this._messageMaxCharacters = this._inputs.contact_data_message.getAttribute('maxlength')

        //Init Wert eintragen
        this._messageLeftCharactersElement.innerHTML = this._messageMaxCharacters;

        //Berechne verbleibende Zeichen
        this._inputs.contact_data_message.addEventListener("keyup", (event) => {
            this._messageLeftCharactersElement.innerHTML = this._messageMaxCharacters - this._inputs.contact_data_message.value.length;
        });

        this._btnSend = this._block.domElement.querySelector("#contact_btn_send");
        this._btnSend.addEventListener("click",this.send.bind(this));

        this._btnSignup = this._block.domElement.querySelector("#contact_btn_signup");
        this._btnSignup.addEventListener("click",this.signup.bind(this));

        //console.log("ContactComponent", this._inputs);

        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }

    private send(event):void{
        event.preventDefault();
        // this.disabled = true;

        this._statusElement.classList.remove('mail');
        this._statusElement.classList.remove('success');
        this._statusElement.classList.remove('error');

        this._errors.error_type_mail.classList.remove('show');
        this._errors.error_type_phone.classList.remove('show');
        this._errors.error_type_message.classList.remove('show');

        this._inputs.contact_data_mail.classList.remove('error');
        this._inputs.contact_data_phone.classList.remove('error');
        this._inputs.contact_data_message.classList.remove('error');

        // nextElementSibling = next Element is the label
        this._inputs.contact_type_mail.nextElementSibling.classList.remove('error');
        this._inputs.contact_type_phone.nextElementSibling.classList.remove('error');
        this._inputs.contact_data_gdpr.nextElementSibling.classList.remove('error');


        let valid = true;

        let typeCheck = this._inputs.contact_type_mail.checked || this._inputs.contact_type_phone.checked;
        if(!typeCheck){

            this._inputs.contact_type_mail.nextElementSibling.classList.add('error');
            this._inputs.contact_type_phone.nextElementSibling.classList.add('error');

            valid = false;
        }

        if(!this._inputs.contact_data_gdpr.checked){
            this._inputs.contact_data_gdpr.nextElementSibling.classList.add('error');

            valid = false;
        }

        if(this._inputs.contact_type_mail.checked){
            var emailRegEx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if(this._inputs.contact_data_mail.value.length == 0 || emailRegEx.test(this._inputs.contact_data_mail.value) === false){
                this._inputs.contact_data_mail.classList.add('error');
                this._errors.error_type_mail.classList.add('show');
                valid = false;
            }
        }

        if(this._inputs.contact_type_phone.checked){
            if(this._inputs.contact_data_phone.value.length < 5){
                this._inputs.contact_data_phone.classList.add('error');
                this._errors.error_type_phone.classList.add('show');
                valid = false;
            }
        }

        if(this._inputs.contact_data_message.value.length < 5){
            this._inputs.contact_data_message.classList.add('error');
            this._errors.error_type_message.classList.add('show');
            valid = false;
        }

        if(valid){
            this.disabled = true;
            this.loading = true;

            jQuery.ajax({
                url: "mail/contact",
                type: 'get',
                data:{
                    forename:encodeURI(this._inputs.contact_data_forename.value),
                    name:encodeURI(this._inputs.contact_data_name.value),
                    company:encodeURI(this._inputs.contact_data_company.value),
                    mail:encodeURI(this._inputs.contact_data_mail.value),
                    phone:encodeURI(this._inputs.contact_data_phone.value),
                    subject:encodeURI(this._inputs.contact_data_subject.value),
                    message:encodeURI(this._inputs.contact_data_message.value),
                    captcha:encodeURI(this._inputs.contact_form_captcha.value),
                    via_phone:encodeURI(this._inputs.contact_type_phone.checked),
                    via_mail:encodeURI(this._inputs.contact_type_mail.checked),
                    gdpr:encodeURI(this._inputs.contact_data_gdpr.value),
                },
                dataType: 'json',
                async: true,
                success: (data, status, xhr) => {
                    // this._formElement.classList.add('newsletter');
                    this._statusElement.classList.add('mail');
                    this._statusElement.classList.add('success');

                    window.scrollTo(0,$(this._statusElement).offset().top);

                    this.disabled = false;
                    this.loading = false;

                },
                error: (xhr, status, error) =>{
                    this.disabled = false;
                    this.loading = false;
                    this._statusElement.classList.add('mail');
                    this._statusElement.classList.add('error');

                    window.scrollTo(0,$(this._statusElement).offset().top);
                }
            });
        }

    }

    private signup(event):void{
        event.preventDefault();

        this._inputs.contact_data_mail.classList.remove('error');
        let valid = true;


        var emailRegEx = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(this._inputs.contact_data_mail.value.length == 0 || emailRegEx.test(this._inputs.contact_data_mail.value) === false){
            this._inputs.contact_data_mail.classList.add('error');
            valid = false;
        }

        if(valid){
            this.disabled = true;
            this.loading = true;

            jQuery.ajax({
                url: "newsletter/subscripe",
                type: 'get',
                data:{
                    FNAME:encodeURI(this._inputs.contact_data_forename.value),
                    LNAME:encodeURI(this._inputs.contact_data_name.value),
                    EMAIL:encodeURI(this._inputs.contact_data_mail.value),
                    MMERGE3:encodeURI(this._inputs.contact_data_company.value)
                },
                dataType: 'json',
                async: true,
                success: (data, status, xhr) => {
                    //console.log("subscripe", data);
                    this._statusElement.classList.remove('mail');
                    window.scrollTo(0,$(this._statusElement).offset().top);

                    if(data.status == "pending"){
                        this._formElement.classList.remove('newsletter');
                        this._formElement.classList.add('done');

                        this._statusElement.classList.add('newsletter');
                        this._statusElement.classList.add('success');
                    }
                    else{
                        this._statusElement.classList.add('newsletter');
                        this._statusElement.classList.add('error');
                    }

                    this.disabled = false;
                    this.loading = false;
                },
                error: (xhr, status, error)=>{
                    //console.log("subscripe error", status, error);
                    this._statusElement.classList.add('newsletter');
                    this._statusElement.classList.add('error');
                    this.disabled = false;
                    this.loading = false;
                    window.scrollTo(0,$(this._statusElement).offset().top);
                }
            });
        }
    }
}