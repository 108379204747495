///<reference path="../js_bindings.d.ts"/>

import {Sidebar} from './Sidebar';
import {Handle} from './Handle';
import {CommunicationCenter} from './CommunicationCenter';


export class Manager{

    private _sidebar:Sidebar;
    private _handle:Handle;
    private _communicationCenter:CommunicationCenter;


    get sidebar():Sidebar { return this._sidebar};


    constructor(){
        this._sidebar = new Sidebar();
        this._handle = new Handle(this._sidebar);
        this._communicationCenter = new CommunicationCenter();
    }
}
