///<reference path="../js_bindings.d.ts"/>

export interface StaticImage{
    url:string;
    title:string;
}

export class StaticImageController  {
    public static getImage(id:number,maxheight:number = 0, baseUrl:string = ''):Promise<StaticImage>
    {
        return new Promise<StaticImage>((resolve, reject) => {
            jQuery.ajax({
                url: `${baseUrl}staticimage`,
                type: 'get',
                data: {
                    id: id,
                    maxheight : maxheight
                },
                dataType: 'json',
                async: true,
                success: (data, status, xhr) => {
                    // console.log("StaticImageController",data);
                    resolve(data);
                },
                error: (xhr, status, error) => {
                    reject(error);
                }
            });
        });
    }
}
