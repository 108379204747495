/**
 * Material.ts: Material code
 *
 * Every Mesh has a unique MaterialInstance to allow every material change
 * to applyable to every instance of a geometry object.
 *
 * Material Instances can refer to a Material or Group in the Library.
 * changing templates or groups get reflected to every MaterialInstance that
 * is connected.
 *
 * [[include:sourceDoc/Materials.md]]
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { RawShaderMaterial } from "../../lib/threejs/materials/RawShaderMaterial";
import { ShaderMaterialParameters, ShaderMaterial } from "../../lib/threejs/materials/ShaderMaterial";
import { Color } from "../../lib/threejs/math/Color";
import { Vector2 } from "../../lib/threejs/math/Vector2";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Vector4 } from "../../lib/threejs/math/Vector4";
import { Matrix3 } from "../../lib/threejs/math/Matrix3";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { AsyncLoad } from "../io/AsyncLoad";
import { build } from "../core/Build";
import { applyMixins, mergeObject, cloneObject } from "../core/Globals";
import { whiteTexture } from "./Texture";
import { EventOneArg } from "../core/Events";
import { EUniformType } from "./Uniforms";
import { queryTextureSystem } from "../framework/TextureAPI";

/**
 * custom material
 * TODO: merge with Material
 */
export class RedMaterial extends RawShaderMaterial {

    constructor(params:ShaderMaterialParameters) {
        super(params);
    }

}

/**
 * material runtime based overwrite ShaderSettings
 */
export interface MaterialShaderSetup {
    doubleSided:boolean;
}

/**
 * material template data (one material instance)
 */
export interface MaterialTemplate {
    /** needed values */
    shader:string;
    /** fixed function variables */
    forceCastShadow?: boolean;
    forceReceiveShadow?: boolean;
    fixedFunction?:MaterialShaderSetup;
    /** standard values */
    baseColor?: [number,number,number,number] | [number,number,number] | Color | undefined;
    baseColorMap?: string | undefined;
    /** custom variables */
    [key:string]:any;
}

export interface MaterialTemplateNamed {
    name:string;
    template: MaterialTemplate;
}

/**
 * material file description
 */
export interface MaterialDesc extends MaterialTemplate {
    /** material name */
    name:string;
}

/**
 * templates for new materials
 *
 * #### Parameters that all Materials share:
 * * shader -- base shader name (e.g. redUnlit)
 * * forceCastShadow -- boolean
 * * forceReceiveShadow -- boolean
 */
export let MaterialDB:{[key:string]:MaterialDesc} = window['MaterialDB'] || {};

/** global debug material */
MaterialDB["debug"] = {
    /** name */
    name: "debug",
    /** base shader type */
    shader: "redUnlit",
    /** force casting shadow */
    forceCastShadow: true,
    /** force receiving shadow (applied to geometry) */
    forceReceiveShadow: true,
    /** albedo (range 0-1) */
    diffuse: [
        1.0,
        0.0,
        1.0
    ],
    baseColor: [
        1.0,
        0.0,
        1.0
    ],
    /** specular (range 0-1) */
    specular: [
        1.0,
        0.0,
        1.0
    ],
    /** phong shininess */
    shininess: 25.0,
    /** emissive */
    emissive: [0.0, 0.0, 0.0],
    /** pbr standard */
    roughness: 0.045,
    metalness: 0,
    /** transparent */
    transparent: false,
    /** opacity value */
    opacity: 1.0,
    /** albedo */
    map: null,
    /** specular map */
    specularMap: null,
    /** transparency alpha map */
    alphaMap: null,
    /** normal map */
    normalMap: null,
    /** normal map scaling */
    normalScale: [0, 0],
    /** environment map */
    envMap: null,
    /** environment map reflectivity */
    reflectivity: 1.0,
    /** light map */
    lightMap: null,
    /** light map intensity */
    lightMapIntensity: 1.0,
    /** ao map */
    aoMap: null,
    /** ao map intensity */
    aoMapIntensity: 1.0,
    /** apply offset+repeat to uv channel */
    offsetRepeat: [0, 0, 1, 1],
    /** TODO: not used atm */
    ignoreAO: true

};

/** notify client database has changed */
export let MaterialDBChanged = new EventOneArg<string>();
let _MaterialDBChanged = false;

/** internal url to material name */
const MaterialURLMap:{[key:string]:string[]} = {};

/**
 * write single material to DB
 * @param name
 * @param template
 */
export function writeToMaterialDB(name:string, url:string|undefined, template:MaterialTemplate, allowOverwrite:boolean = false, notify:boolean = true) {
    if(!name) {
        console.error("writeToMaterialDB: calling without name for template: ", template);
        return;
    }
    if(MaterialDB[name] && !allowOverwrite) {
        console.warn("writeToMaterialDB: replacing material '"+name+"'");
    }

    // write to DB
    MaterialDB[name] = mergeObject(template, {name}) as MaterialDesc;
    // setup updated
    _MaterialDBChanged = true;

    applyMaterialId(template);

    // add new entry
    if(url) {
        MaterialURLMap[url] = MaterialURLMap[url] || [];
        if(MaterialURLMap[url].indexOf(name) === -1) {
            MaterialURLMap[url].push(name);
        }
    }

    // trigger update
    if(notify) {
        MaterialDBChanged.trigger(name);
    }
}

export function updateToMaterialDB(name:string, template:MaterialTemplate) {
    if(!name) {
        console.error("writeToMaterialDB: calling without name for template: ", template);
        return;
    }
    if(MaterialDB[name]) {
        const material = MaterialDB[name];

        // compare reference
        if(material === template) {
            return;
        }

        // delete variables
        for(const key in material) {
            delete material[key];
        }
        // copy variables
        for(const key in template) {
            material[key] = template[key];
        }
        // rewrite name
        material.name = name;

        applyMaterialId(material);
    } else {
        //console.error("Failure to update Material " + name + ", no entry yet");
        writeToMaterialDB(name, undefined, template, false, false);
    }
}

export function resolveURLToMaterialName(url:string) {
    // directly resolvable
    if(MaterialURLMap[url] && MaterialURLMap[url].length === 1) {
        return MaterialURLMap[url][0];
    }
    // parse url / filename
    const query = url.indexOf("?");
    if(query > 0) {
        url = url.substring(0, query);
    }
    // remove path
    const slash = url.lastIndexOf("/");
    if(slash >= 0) {
        url = url.substring(slash + 1);
    }
    // remove extension
    const dot = url.lastIndexOf(".");
    if(dot) {
        url = url.substring(0, dot);
    }
    return url;
}

export function resolveURLToMaterialNames(url:string) {
    return MaterialURLMap[url];
}

export function updateMaterialDB() {
    if(_MaterialDBChanged) {
        _MaterialDBChanged = false;
        MaterialDBChanged.trigger(name);
    }
}

/**
 *
 */
export function processMaterialDB() {
    // process template names
    for(const entry in MaterialDB) {
        const material = MaterialDB[entry];
        material.name = entry;
    }
}

export interface MaterialDBSnapshot {
    snapshot:{[key:string]:MaterialDesc};
    urlMap:{[key:string]:string[]};
}

export function restoreMaterialDB(data:MaterialDBSnapshot) {
    // cleans local databases used by material library
    for(const name in MaterialDB) {
        // clean entry
        delete MaterialDB[name];
        // restore from snapshot
        if(data.snapshot[name]) {
            MaterialDB[name] = data.snapshot[name];
        }
    }
    for(const url in MaterialURLMap) {
        // clean entry
        delete MaterialURLMap[url];
        // restore from snapshot
        if(data.urlMap[name]) {
            MaterialURLMap[name] = data.urlMap[name];
        }
    }
    processMaterialDB();
    applyMaterialsId(MaterialDB);
}

export function snapshotMaterialDB() : MaterialDBSnapshot {
    return {
        snapshot: cloneObject(MaterialDB) || {},
        urlMap: cloneObject(MaterialURLMap) || {}
    };
}

let _MaterialId = 1;

/**
 * sort material database
 * @param value
 * @param defaultValue
 */
export function applyMaterialsId(materials?:{[key:string]:MaterialTemplate}) {
    materials = materials || MaterialDB;
    const keys = Object.keys(materials);
    // recreate sort
    _MaterialId = 1;
    for(const key of keys) {
        //materials[key].name = key;
        materials[key]._sortID = _MaterialId;
        _MaterialId++;
    }
}

/**
 * sort material database
 * @param value
 * @param defaultValue
 */
export function applyMaterialId(material:MaterialTemplate|string) {

    if(typeof material === 'string') {
        material = MaterialDB[material];
        if(material) {
            applyMaterialId(material);
        }
    } else {
        material._sortID = _MaterialId;
        _MaterialId++;
    }
}

export interface MaterialGroupState {
    current:string;
}

/**
 * material group layout
 */
export interface MaterialGroupTemplate {
    // persistent
    name?:string;
    default?:string;
    materials?:string[];
    // runtime
    state?:{[key:string]:MaterialGroupState};
    globalState?:MaterialGroupState;
}

/**
 * apply values functions
 */

function valueIfValid(value:any, defaultValue:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }
    if(value) {
        return value;
    } else {
        return defaultValue;
    }
}

function colorValueIfValid(value:any, defaultValue:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }

    if(value && value !== undefined) {
        if(Object.prototype.toString.call(value) === '[object Array]') {
            return new Color(value[0],value[1],value[2]);
        } else {
            // assuming three.color
            return new Color(value.r,value.g,value.b);
        }

    } else {
        return defaultValue;
    }
}

function vector2ValueIfValid(value:any, defaultValue:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }

    if(value) {
        return new Vector2(value[0],value[1]);
    } else {
        return defaultValue;
    }
}

function vector3ValueIfValid(value:any, defaultValue:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }

    if(value) {
        return new Vector3(value[0],value[1],value[2]);
    } else {
        return defaultValue;
    }
}

function vector4ValueIfValid(value:any, defaultValue:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }

    if(value) {
        if(value.isVector4 === true) {
            return value;
        } else {
            return new Vector4(value[0],value[1],value[2],value[3]);
        }
    } else {
        return defaultValue;
    }
}

function matrix3ValueIfValid(value:any, defaultValue:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }

    if(value) {

        if(value.isMatrix3 === true) {
            return value;
        } else {
            return new Matrix3().set(value[0],value[1],value[2],
                                     value[3],value[4],value[5],
                                     value[6],value[7],value[8]);
        }
    } else {
        return defaultValue;
    }
}

function matrix4ValueIfValid(value:any, defaultValue:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }

    if(value) {

        if(value.isMatrix4 === true) {
            return value;
        } else {
            return new Matrix4().set(value[0],value[1],value[2],value[3],
                                     value[4],value[5],value[6],value[7],
                                     value[8],value[9],value[10],value[11],
                                     value[12],value[13],value[14],value[15]);
        }
    } else {
        return defaultValue;
    }
}

function textureValueIfValid(value:any, defaultValue:any, finishCallback:any) {
    // resolve THREE.JS value
    if(value && value.type !== undefined) {
        value = value.value;
    }

    if(value && value.length > 0) {
        // then try to create one for this texture
        queryTextureSystem().createTexture(value, undefined, undefined).then((texture) => {
            finishCallback(texture);
        },
        (err) => {
            finishCallback(whiteTexture());
        });
    } else if(defaultValue && defaultValue.length > 0) {
        finishCallback(whiteTexture());
        //TODO:
        queryTextureSystem().createTexture(defaultValue, undefined, undefined).then((texture) => finishCallback(texture), (err) => finishCallback(null));
    } else {
        finishCallback(null);
    }
}

/**
 * this is a little bit weird
 * At the moment is not practible to extend THREE.js classes
 * so this class will be added to every three.js ShaderMaterial
 */
export class MaterialMixins {

    public _redVersion = 0;
    public _redLoadCounter = 0;

    get _uniforms() {
        return this['uniforms'];
    }

    /**
     * apply value to material
     * @param name value name
     * @param value value
     */
    public setParameter(name:string, value:any) {
        if(this[name]) {
            this[name] = value;
        }

        if(!this._uniforms[name]) {
            console.warn("Material: no valid uniform value");
            return;
        }

        this._uniforms[name].value = value;
    }

    public transferVariable(uniformName:string, value:any) {
        return new AsyncLoad<void>( (resolve, reject) => {
            const uniform = this._uniforms[uniformName];

            if(!uniform) {
                return;
            }

            const currentMaterialVersion = this._redVersion;

            // get default value (global scope or uniform value)
            let defaultValue = uniform.default;

            // okay here is the thing: template data can result to null for specific objects
            // but the uniform.value could be set before (like when switchMaterialGroup happens)
            // so a value of "null" should replace this. but uniform.default will win always here
            if(value !== undefined) {
                defaultValue = uniform.default || value;
            }

            if(uniform.type === EUniformType.TEXTURE) {
                if(value && value.isTexture === true) {
                    // texture instance
                    uniform.value = value;
                    this['uniformsNeedUpdate'] = true;

                    // finished loading
                    resolve();

                } else if(value && value.length > 0) {

                    this.setLoading();

                    // texture name
                    textureValueIfValid(value, defaultValue, (tex:any) => {

                        if(currentMaterialVersion === this._redVersion) {
                            //textures needs to be applied to material object too
                            //this will force three.js to define USE_*MAP and apply these textures
                            uniform.value = tex;

                            this['uniformsNeedUpdate'] = true;
                        } else if(build.Options.debugRenderOutput) {
                            console.info(`ShaderLibrary::transferMaterialVariables: outdated ${currentMaterialVersion} material change ${this._redVersion}`);
                        }

                        // this needs to be called
                        // as material could be uploaded to three.js and texture
                        // got applied deferred
                        this.finishLoading(() => {
                            // finished loading
                            resolve();
                        });
                    });

                } else {
                    // reset value
                    uniform.value = defaultValue;

                    this['uniformsNeedUpdate'] = true;
                    // finished loading
                    resolve();
                }
            } else if(uniform.type === EUniformType.COLOR) {
                uniform.value = colorValueIfValid(value, defaultValue);
                this['uniformsNeedUpdate'] = true;
                // finished loading
                resolve();
            } else if(uniform.type === EUniformType.FLOAT) {
                uniform.value = valueIfValid(value, defaultValue);
                this['uniformsNeedUpdate'] = true;
                // finished loading
                resolve();
            } else if(uniform.type === EUniformType.VECTOR2) {
                uniform.value = vector2ValueIfValid(value, defaultValue);
                this['uniformsNeedUpdate'] = true;
                // finished loading
                resolve();
            } else if(uniform.type === EUniformType.VECTOR3) {
                uniform.value = vector3ValueIfValid(value, defaultValue);
                this['uniformsNeedUpdate'] = true;
                // finished loading
                resolve();
            } else if(uniform.type === EUniformType.VECTOR4) {
                uniform.value = vector4ValueIfValid(value, defaultValue);
                this['uniformsNeedUpdate'] = true;
                // finished loading
                resolve();
            } else if(uniform.type === EUniformType.MATRIX4) {
                uniform.value = matrix4ValueIfValid(value, defaultValue);
                this['uniformsNeedUpdate'] = true;
                // finished loading
                resolve();
            } else if(uniform.type === EUniformType.MATRIX3) {
                uniform.value = matrix3ValueIfValid(value, defaultValue);
                this['uniformsNeedUpdate'] = true;
                // finished loading
                resolve();
            } else {
                //WARNING
                resolve();
            }
        });
    }

    public updateVersion() {
        this._redVersion = this._redVersion + 1;
    }

    public setLoading() {
        this._redLoadCounter += 1;
    }

    public finishLoading(finishedCallback?:(mat:MaterialMixins) => void) {
        this._redLoadCounter -= 1;

        if(this._redLoadCounter === 0) {
            if(finishedCallback) {
                finishedCallback(this);
            }
        }
    }
}
/** merge with Shader Object */
applyMixins(ShaderMaterial, [MaterialMixins]);
