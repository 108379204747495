/**
 * Filter.ts: render tools
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { WebGLRenderTarget } from "../../lib/threejs/renderers/WebGLRenderTarget";
import { ClampToEdgeWrapping, DoubleSide, LinearFilter, RGBAFormat, UnsignedByteType } from "../../lib/threejs/constants";
import { PlaneGeometry } from "../../lib/threejs/geometries/PlaneGeometry";
import { OrthographicCamera } from "../../lib/threejs/cameras/OrthographicCamera";
import { Scene } from "../../lib/threejs/scenes/Scene";
import { Render } from "./Render";
import { RenderState } from "./State";
import { ShaderLibrary } from "./ShaderLibrary";

// BUILTIN SHADER (auto include)
import "../render/shader/Blur";

/**
 * generate prefiltered cubemap mip levels from cubemap
 */
export class BlurRenderJob {

    /** service accessor */
    public static get(renderer:Render) {
        if(!this._asService) {
            this._asService = new BlurRenderJob(renderer);
        }
        return this._asService;
    }
    private static _asService:BlurRenderJob;

    /** filter mip level 0 */
    public filterLod0:boolean = true;

    /** internal renderer */
    private _renderer:Render;
    /** filter shader */
    private _shader:any[];

    /** filter lods pipeline */
    private _pipeState:RenderState;
    private _sceneSetup:any;

    /** initialization */
    constructor(renderer:Render) {
        this._shader = null;
        this._pipeState =  new RenderState();
        this._renderer = renderer;
    }

    /** destruction */
    public destroy() {
        this._pipeState.destroy();
    }

    /** copy mip levels into source target */
    public copy(sourceTarget:WebGLRenderTarget) : WebGLRenderTarget {

        const source = sourceTarget.texture;

        // remember old wrapping mode and use clamp to edge
        const tempWrapS = source.wrapS;
        const tempWrapT = source.wrapT;

        source.wrapS = ClampToEdgeWrapping;
        source.wrapT = ClampToEdgeWrapping;

        this._setupShader();

        // generate new texture from lod levels
        this._renderCopy(sourceTarget);

        // restore old wrapping
        source.wrapS = tempWrapS;
        source.wrapT = tempWrapT;

        return sourceTarget;
    }

    private _setupScene() {
        if(this._sceneSetup) {
            return;
        }

        this._sceneSetup = {
            scene: new Scene(),
            camera: new OrthographicCamera(-1, 1, 1, -1, 0, 1),
            planeMesh: new THREEMesh(new PlaneGeometry(2, 2), this._shader)
        };

        this._sceneSetup.planeMesh.frustumCulled = false;
        if(!Array.isArray(this._sceneSetup.planeMesh.material)) {
            this._sceneSetup.planeMesh.material.side = DoubleSide;
        } else {
            for(const mat of this._sceneSetup.planeMesh.material) {
                mat.side = DoubleSide;
            }
        }
        this._sceneSetup.scene.add(this._sceneSetup.planeMesh);
    }

    /** lazy init */
    private _setupShader() {
        // initialize shader
        if(!this._shader) {
            this._shader = [];

            this._shader[0] = ShaderLibrary.createShader("redHorizontalBlurShader");
            this._shader[1] = ShaderLibrary.createShader("redVerticalBlurShader");

        }
    }

    /** copy target mip levels */
    private _renderCopy(sourceTarget:WebGLRenderTarget) {

        // filter scene setup
        this._setupScene();

        // setup lod levels (take other stuff into account?)
        const size = sourceTarget.width;
        const numLods = Math.log(size) / Math.log(2);
        //const numLods = maxMipLevelsTarget(sourceTarget);

        const source = sourceTarget.texture;

        const params = {
            format: source.format,
            magFilter: LinearFilter,
            minFilter: LinearFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            type: source.type,
            generateMipmaps: false,
            anisotropy: source.anisotropy,
            encoding: source.encoding,
            stencilBuffer: false,
            depthBuffer: false
        };

        const firstMip = this.filterLod0 ? 0 : 1;

        // level 0 -> numLods
        let mipWidth = this.filterLod0 ? sourceTarget.width : Math.floor(sourceTarget.width / 2);
        let mipHeight = this.filterLod0 ? sourceTarget.height : Math.floor(sourceTarget.height / 2);

        let lastSourceTarget = sourceTarget;

        // render lod roughness levels
        for(let i = firstMip; i < numLods; ++i) {

            // generate render target for ping pong
            const renderTarget0 = this._pipeState.requestTemporaryTarget({
                width: mipWidth,
                height: mipHeight,
            }, params);

            const renderTarget1 = this._pipeState.requestTemporaryTarget({
                width: mipWidth,
                height: mipHeight,
            }, params);

            // blur horizontal

            // update shader
            this._shader[0].uniforms['map'].value = lastSourceTarget.texture;
            this._shader[0].uniforms['widthPixel'].value = 1.0 / lastSourceTarget.width;

            // update pipeline setup
            this._pipeState.renderTarget = renderTarget0;
            this._pipeState.clearTarget = true;
            this._pipeState.clearColor = [1.0, 1.0, 1.0];
            this._pipeState.overrideMaterial = this._shader[0];

            this._renderer.render(this._sceneSetup.scene, this._sceneSetup.camera, this._pipeState);

            // and vertical

            // update shader
            this._shader[1].uniforms['map'].value = renderTarget0.texture;
            this._shader[1].uniforms['heightPixel'].value = 1.0 / lastSourceTarget.height;

            // update pipeline setup
            this._pipeState.renderTarget = renderTarget1;
            this._pipeState.clearTarget = true;
            this._pipeState.clearColor = [1.0, 1.0, 1.0];
            this._pipeState.overrideMaterial = this._shader[0];

            this._renderer.render(this._sceneSetup.scene, this._sceneSetup.camera, this._pipeState);

            // copy to source mip level
            const _gl = this._renderer.webGLRender.getContext();
            const width = mipWidth;
            const height = mipHeight;
            const level = i;
            const glFormat = source.format === RGBAFormat ? _gl.RGBA : _gl.RGB;

            if(source.type === UnsignedByteType) {
                this._renderer.webGLRender.setTexture2D(source, 0);
                _gl.copyTexImage2D(_gl.TEXTURE_2D, level, glFormat, 0, 0, width, height, 0 );
            } else {
                // copy back (TODO: no support for LOD)
                // const lastActiveMip = sourceTarget.activeCubeFace;
                // sourceTarget.activeCubeFace = f;
                // this._renderer.renderCopy(renderTarget, sourceTarget);
                // sourceTarget.activeCubeFace = lastActiveMip;
                console.assert(false, "not working on webgl 1.0");
            }

            // next lod size
            mipWidth = Math.floor(Math.max( 1, mipWidth / 2 ));
            mipHeight = Math.floor(Math.max( 1, mipHeight / 2 ));

            // temporary
            if(lastSourceTarget !== sourceTarget) {
                // do some cleanup
                this._pipeState.returnTemporaryTarget(lastSourceTarget);
            }

            lastSourceTarget = renderTarget1;

            // do some cleanup
            this._pipeState.returnTemporaryTarget(renderTarget0);
        }

        // temporary
        if(lastSourceTarget !== sourceTarget) {
            // do some cleanup
            this._pipeState.returnTemporaryTarget(lastSourceTarget);
        }

        this._renderer.webGLRender.setRenderTarget(null);

    }
}
