/**
 * MeshLibrary.ts: mesh/model management
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import {Mesh as THREEMesh} from "../../lib/threejs/objects/Mesh";
import { MeshImportDB, MESHSYSTEM_API, IMeshSystem, applyImportSettingsMesh } from "../framework/MeshAPI";
import { AsyncLoad } from "../io/AsyncLoad";
import { ModelData, IModelLoader } from "../framework-types/ModelFileFormat";
import { registerAPI } from "../plugin/Plugin";
import { ResolveCallback, EAssetType, findLoader, findLoaderWithExtension, registerLoadResolver } from "../io/Interfaces";
import { parseFileExtension } from "../core/Globals";
import { queryAssetManager } from "../framework/AssetAPI";
import { build } from "../core/Build";
import { LoadingManager } from "../io/LoadingManager";
import { queryMaterialSystem } from "../framework/MaterialAPI";

/** default for blank texture */
MeshImportDB['blank'] = {

    /** texture path */
    texturePath: "textures",

    /** texture loading */
    autoLoadTextures: false,

    /** shrink transformations */
    autoShrink: false,

    /** special stuff for old project */
    colorRGBToIndex: false,

    /** use THREE.BufferGeometry */
    useGeometryBuffer: true
};

/**
 * cache entry for meshes
 */
interface MeshCache {
    mesh:any;
    isLoaded:boolean;
    isError:boolean;
    resolver:Array<ResolveCallback>;
}

let _isEmittingState:number = 0;

/** caches */
let _meshCache:{[ref:string]: MeshCache;} = {};

function _flush() {
    _meshCache = {};
}

function _loadMesh(filename:string, loaderIdentifier?:string) : AsyncLoad<ModelData> {
    return new AsyncLoad<any>((resolve, reject) => {
        if(!filename) {
            // reject request
            reject(new Error("ASSET: failed to load mesh " + filename));
            return;
        }

        // runtime loading
        const obj = _meshCache[filename];
        if(obj) {
            // already loaded
            if(obj.isLoaded && !obj.isError) {
                // resolve
                resolve(obj.mesh);
            } else if(!obj.isLoaded && !obj.isError) {
                // currently loading -> add to resolver list
                obj.resolver.push({ resolve: resolve, reject: reject });
            } else {
                // failed to load
                reject("Failed to load");
            }
        } else {
            // start mesh proxy loading
            _loadMeshProxy(filename, { resolve: resolve, reject: reject }, queryAssetManager().getLoadingManager(), loaderIdentifier);
        }
    });
}

/**
 * internal mesh loading
 * @param loaderIdentifier optional loader identifier
 */
function _loadMeshProxy(name:string, resolver:any, loadingManager:LoadingManager, loaderIdentifier?:string) {
    if(!name) {
        console.warn("ASSET: (_loadMeshProxy) failed to load image ", name);
        return;
    }

    if(_meshCache[name]) {
        // check if already loaded
        if(_meshCache[name].isLoaded) {
            console.warn("ASSET: mesh already loaded " + name);
            if(resolver) {
                resolver.resolve(_meshCache[name].mesh);
            }
        } else {
            if(resolver) {
                _meshCache[name].resolver.push(resolver);
            }
        }
        return;
    }

    // generate simple image cache
    _meshCache[name] = {
        mesh: null,
        isLoaded: false,
        isError: false,
        resolver: []
    };

    if(resolver) {
        _meshCache[name].resolver.push(resolver);
    }

    let loaderClass:any = null;

    if(loaderIdentifier) {
        // find loader class
        loaderClass = findLoader(loaderIdentifier, EAssetType.Model);
    } else {
        // try to find with file extension
        const fileExtension = parseFileExtension(name);
        // find loader class
        loaderClass = findLoaderWithExtension(fileExtension, EAssetType.Model);
    }

    if(!loaderClass) {
        console.error("AssetManager: missing model loader: " + loaderIdentifier + " " + name);
        _meshCache[name].isError = true;
        _meshCache[name].isLoaded = false;
        _emitLoadingState();
        return;
    }

    queryAssetManager().loadBinary(name).then( (data) => {

        //TODO: get list of objects that need pre loading
        const loader:IModelLoader = new loaderClass(loadingManager);
        applyImportSettingsMesh(loader, name);

        loader.loadFromMemory(data, name,
        // load
        (mesh) => {
            if(mesh) {

                if(build.Options.debugAssetOutput) {
                    console.info("ASSET: loaded mesh " + name);
                }

                _meshCache[name].mesh = mesh;
                _meshCache[name].isLoaded = true;
            } else {
                _meshCache[name].isError = true;
                console.warn("ASSET: failed mesh" + name);
            }
            _emitLoadingState();
        },
        // progress
        () => {

        },
        // error
        () => {

        });
    },
    (err) => {
        //console.error("ASSET: (_loadMeshProxy) ERROR: ", message);
        _meshCache[name].isError = true;
        _emitLoadingState();
    });

    // // create url
    // let url = _storage.processURL(name, setup.baseMeshPath);

    // if(_allowCrossDomain) {
    //     loader.crossOrigin = "Anonymous";
    // }

    // // update url to revision tag
    // if(setup.useRevisionTag && _storage.canUseRevisionTag(name)) {
    //     url = UpdateQueryString(url, "revTag", build.Options.revision);
    // }
    // if(setup.denyUpdateAccess && _storage.canUseUpdateAccess(name)) {
    //     url = UpdateQueryString(url, "updateAccess", false);
    // }

    // loader.load(url, name, (mesh:any) => {
    //     if(mesh) {

    //         if(build.Options.debugAssetOutput) {
    //             console.info("ASSET: loaded mesh " + name);
    //         }

    //         _meshCache[name].mesh = mesh;
    //         _meshCache[name].isLoaded = true;
    //     } else {
    //         _meshCache[name].isError = true;
    //         console.warn("ASSET: failed mesh" + name);
    //     }
    // },
    // () => {},
    // (message:any) => {
    //     //console.error("ASSET: (_loadMeshProxy) ERROR: ", message);
    //     _meshCache[name].isError = true;
    // });
}

/**
 * add a mesh to asset management
 * @param name reference name
 * @param content content data
 * @param type not supported right now
 */
function addMesh(name:string, content:string|ArrayBuffer|Blob|ModelData, loaderIdentifier?:string) {

    if(!name) {
        console.warn("AssetManager: invalid name for content ", content);
        return;
    }

    if(!content) {
        console.warn("AssetManager: empty data for '"+name+"'");
        return;
    }

    if(_meshCache[name] && build.Options.debugAssetOutput) {
        // overwriting cache entry
        console.info("AssetManager: overwriting cache entry '"+name+"'");
    }

    // generate cache entry that other can resolve to this
    if(!_meshCache[name]) {
        _meshCache[name] = {
            mesh: null,
            isLoaded: false,
            isError: false,
            resolver: []
        };
    }

    let loaderClass:any = null;

    if(loaderIdentifier) {

        // find loader class
        loaderClass = findLoader(loaderIdentifier, EAssetType.Model);

    } else {
        // try to find with file extension
        const fileExtension = parseFileExtension(name);
        // find loader class
        loaderClass = findLoaderWithExtension(fileExtension, EAssetType.Model);

    }

    if(!loaderClass) {
        console.error("AssetManager: missing Model loader");
        _meshCache[name].isError = true;
        _meshCache[name].isLoaded = false;
        return;
    }

    const _modelLoader = (data) => {

        const loader = new loaderClass(queryAssetManager().getLoadingManager());

        if(MeshImportDB[name]) {

            loader.autoShrink = applyValue(MeshImportDB[name].autoShrink, true);
            loader.texturePath = applyValue(MeshImportDB[name].texturePath, 'textures');
            loader.autoLoadTextures = applyValue(MeshImportDB[name].autoLoadTextures, false);
            loader.colorRGBToIndex = applyValue(MeshImportDB[name].colorRGBToIndex, false);
            loader.useGeometryBuffer = applyValue(MeshImportDB[name].useGeometryBuffer, true);
        } else {
            // default settings
            loader.texturePath = 'textures';
            loader.autoShrink = true;
            loader.autoLoadTextures = false;
            loader.colorRGBToIndex = false;
            loader.useGeometryBuffer = true;
        }

        loader.loadFromMemory(data, (mesh:any) => {
            if(mesh) {
                _meshCache[name].mesh = mesh;
                _meshCache[name].isLoaded = true;
            } else {
                _meshCache[name].isError = true;
                _meshCache[name].isLoaded = false;
            }
        },
        () => {},
        (message:any) => {
            console.warn(message);
            _meshCache[name].isError = true;
            _meshCache[name].isLoaded = false;
        });
    };

    if(content instanceof ArrayBuffer) {

        _modelLoader(content);

    } else if(content instanceof Blob) {
        const reader = new FileReader();

        reader.onload = (event) => {

            _modelLoader(reader.result as ArrayBuffer);

        };

        reader.onerror = (event) => {
            _meshCache[name].isLoaded = false;
            _meshCache[name].isError = true;
        };

        reader.readAsArrayBuffer(content);

    } else if(content instanceof Object) {

        // ModelData
        _meshCache[name].mesh = content;
        _meshCache[name].isLoaded = true;

    } else {
        // assume data that can be directly loaded
        _modelLoader(content);
    }

}

/**
 * flush memory on the gpu,
 * does not destroy memory on client side
 */
function flushGPUMemory() {
    function flushObject3D(obj:any) {
        if(obj instanceof THREEMesh) {
            obj.geometry.dispose();

            //TODO: dispose is not given on custom material class
            if(Array.isArray(obj.material)) {
                for(const mat of obj.material) {
                    if(mat.dispose) {
                        mat.dispose();
                    }
                }
            } else if(obj.material && obj.material.dispose) {
                obj.material.dispose();
            }
        } else {
            for(let i = 0; i < obj.children.length; ++i) {
                flushObject3D(obj.children[i]);
            }
        }
    }

    for(const i in _meshCache) {
        if(_meshCache[i].mesh && _meshCache[i].mesh.mesh) {
            flushObject3D(_meshCache[i].mesh.mesh);
        }
    }
}

/**
 * emit all loading states to other
 */
function _emitLoadingState() {

    _isEmittingState += 1;

    // already emitting
    if(_isEmittingState > 1) {
        return;
    }

    function finishCache(cache:any) {
        for(const element in cache) {
            const obj = cache[element];

            if(obj.isLoaded && !obj.isError) {
                // call waiting list
                if(obj.resolver) {
                    // call waiting list
                    for(let i = 0; i < obj.resolver.length; ++i) {
                        if(obj.text) {
                            obj.resolver[i].resolve(obj.text);
                        } else if(obj.binary) {
                            obj.resolver[i].resolve(obj.binary);
                        } else if(obj.mesh) {
                            obj.resolver[i].resolve(obj.mesh);
                        } else if(obj.image) {
                            obj.resolver[i].resolve(obj.image);
                        } else if(obj.texture) {
                            obj.resolver[i].resolve(obj.texture);
                        } else {
                            //FIXME: reject?
                            obj.resolver[i].resolve(null);
                        }
                    }

                    obj.resolver.length = 0;
                }

            } else if(obj.isError) {
                //TODO: add reject code

                // call waiting list
                for(let i = 0; i < obj.resolver.length; ++i) {
                    if(obj.resolver[i].reject) {
                        obj.resolver[i].reject(new Error("file not found"));
                    }
                }

                obj.resolver.length = 0;
            } else {
                // isLoaded == false && isError == false

                //THIS can happen for objects that are invalid

                //FIXME: wait for it?
                //console.warn("Not fully loaded: ", obj);
            }
        }
    }

    // resolve all elements in right order
    while(_isEmittingState > 0) {
        finishCache(_meshCache);

        _isEmittingState -= 1;
    }
}

function applyValue(value:any, defaultValue:any) {
    if(value !== undefined) {
        return value;
    } else {
        return defaultValue;
    }
}

/**
 * preload model with dependency loading
 * @param name filename of model
 * @param loaderIdentifier
 */
export function preloadModel(name:string, loaderIdentifier?:string) : AsyncLoad<void> {
    return _loadMesh(name, loaderIdentifier).then( (mesh) => {
        const loading = [];
        const materialSystem = queryMaterialSystem();
        // preload materials
        if(materialSystem && mesh && mesh.materials && Array.isArray(mesh.materials)) {
            for(const material of mesh.materials) {

                // check if material name is loaded in material library
                const template = materialSystem.findMaterialByName(material.name);

                if(template) {
                    loading.push(materialSystem.loadMaterial(template));
                } else {
                    loading.push(materialSystem.loadMaterial(material));
                }
            }
        }

        return AsyncLoad.all(loading).then( () => {
            return AsyncLoad.resolve<void>();
        });
    },
    (err) => {
        console.error(err);
        return AsyncLoad.resolve<void>();
    });
}

registerLoadResolver("model", preloadModel);

export const MeshLibrary:IMeshSystem = {
    loadMesh: _loadMesh,
    addMesh,
    preloadModel,
    flushGPUMemory
};
registerAPI(MESHSYSTEM_API, MeshLibrary);
