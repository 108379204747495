/**
 * DataCache.ts: raw data cache
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Platform } from "../core/Platform";
import {AsyncLoad} from './AsyncLoad';
import { makeAPI } from "../plugin/Plugin";

export enum EDataCacheMode {
    // completly inactive
    Inactive = 0,
    // read to cache only
    ReadOnly = 1,
    // write to cache only
    WriteOnly = 2,
    // read and write to cache
    ReadWrite = 3,
    // use when offline and write to cache when online
    Offline = 4,
}

/**
 * can write to cache when WriteOnly, ReadWrite or Offline (when client is online)
 * @param mode current mode
 */
export function modeCanWrite(mode:EDataCacheMode) {
    return (mode === EDataCacheMode.WriteOnly || mode === EDataCacheMode.ReadWrite) ||
            (mode === EDataCacheMode.Offline && Platform.get().isOnline);
}

/**
 * can read from cache when ReadOnly, ReadWrite or Offline (when client is offline)
 * @param mode current mode
 */
export function modeCanRead(mode:EDataCacheMode) {
    return (mode === EDataCacheMode.ReadOnly || mode === EDataCacheMode.ReadWrite) ||
           (mode === EDataCacheMode.Offline && !Platform.get().isOnline);
}

export interface IDataCache {
    /** init data cache, always resolves */
    init() : AsyncLoad<void>;

    /** set cache element */
    set(name:string, data:any) : void;

    /** get cache element */
    get(url:string) : AsyncLoad<any>;

    /** clear cache */
    clear() : AsyncLoad<void>;

    /** set data cache mode */
    setMode(cacheMode:EDataCacheMode) : void;
}
export const DATACACHE_API = makeAPI("IDataCache");
