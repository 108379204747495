///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class DebugComponent extends AbstractBlockComponent {

    protected onInit():void{
        this._block.onCenterDistanceChange.subscribe((distance)=>{
            console.log("centerDistance",distance);
        });

        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }

}