/**
 * App.ts: Application logic
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Clock } from "../../lib/threejs/core/Clock";
import { build } from '../core/Build';
import { math } from '../core/Math';
import { EventOneArg, EventTwoArg } from '../core/Events';
import { ShaderLibrary } from '../render/ShaderLibrary';
import { Render } from '../render/Render';
import { devMarkTimelineEnd, devMarkTimelineStart, devProfileStart, devProfileStop } from '../core/Debug';
import { mouseButtonDown, mouseButtonUp, MouseButtonState, resetMouseState } from "../core/Input";
import { FileStat } from "../io/AssetInfo";
import { RenderSize } from "../render/Config";
import { tick, nextFrameCalls, performanceTest, redInternalSpeedTest, OnUpdate, OnPostUpdate } from "./Tick";
import { AppDelegate, Mouse } from "./AppDelegate";

// DEFAULT APIS
import { AssetManager } from './AssetManager';
import { TextureLibrary } from "../framework-apis/TextureLibrary";
import { MaterialLibrary } from './MaterialLibrary';
import { MeshLibrary } from "../framework-apis/MeshLibrary";
import "../framework-apis/PrefabLibrary";

/**
 * current application state
 */
export enum ApplicationState {
    Startup = 0,
    Initialize,
    Preloading,
    Loading,
    Running,
    //Paused, TODO
    Destroying
}

/**
 * application class.
 * Handles Main loop and events.
 *
 * call appInit() with your custom delegate to start application
 */
export class Application {

    //DEPRECATED: use global OnUpdate instead
    public OnUpdate = new EventOneArg<number>();

    public OnTouchStart = new EventOneArg<Event>();
    public OnTouchMove = new EventOneArg<Event>();
    public OnTouchEnd = new EventOneArg<Event>();
    public OnMouseEnter = new EventOneArg<Event>();
    public OnMouseLeave = new EventOneArg<Event>();
    public OnMouseDown = new EventOneArg<Event>();
    public OnMouseMove = new EventOneArg<Event>();
    public OnMouseWheel = new EventOneArg<Event>();
    public OnMouseUp = new EventOneArg<Event>();
    public OnDragOver = new EventOneArg<DragEvent>();
    public OnDrop = new EventOneArg<DragEvent>();
    public OnWindowResize = new EventTwoArg<Event, RenderSize>();

    /** is application in viewport */
    public get isInViewport() : boolean {
        return !this._useViewportRendering || this._isInViewport;
    }

    /** only render when in viewport */
    public set useViewportRendering(value:boolean) {
        this._useViewportRendering = value;
    }
    public get useViewportRendering() : boolean {
        return this._useViewportRendering;
    }

    /** current application state */
    public get state() : ApplicationState {
        return this._state;
    }

    /** delegate access */
    get delegate() : AppDelegate {
        return this._delegate;
    }

    /** dom element */
    public container:Element;
    /** mouse information */
    public mouse:Mouse;
    private _mouseButtonState:MouseButtonState;
    /** renderer */
    public renderer:Render = null;
    /** render settings */
    private renderAtPreloading:boolean = false;
    private renderAtLoading:boolean = true;
    /** internal application state */
    private _state:ApplicationState = ApplicationState.Startup;
    private _loadingCounter:number = 0;
    private _delegate:AppDelegate = null;
    private _isInViewport:boolean = true;
    private _useViewportRendering:boolean = false;

    /**
     * construction on document load
     */
    constructor(delegate:AppDelegate) {
        this._mouseButtonState = [false, false, false, false, false];
        this._state = -1;

        // reload build configuration
        build._initBuildSettings();
        console.info("Application: initialization");

        this._switchState(ApplicationState.Startup);

        // mouse pos
        //FIXME: init to -1?
        this.mouse = {
            leftButton: false,
            middleButton: false,
            rightButton: false,
            x: -1,
            y: -1,
            normalizedX: 0.0,
            normalizedY: 0.0,
            screenX: -1,
            screenY: -1,
            normalizedScreenX: 0.0,
            normalizedScreenY: 0.0,
            isTouchDevice: false
        };

        // assign delegate
        this._delegate = delegate;

        //TODO: remove... apply element directly and not by name?
        this.container = delegate.containerElement();

        if(!this.container) {
            console.error("Application: No container element found.");
        }

        // setup container
        this._initContainer();

        // setup asset manager
        AssetManager.LoadStarted.on(this._loadStart);
        AssetManager.LoadFinished.on(this._loadFinished);
        AssetManager.LoadFailed.on(this._loadFailed);
        AssetManager.LoadProgress.on(this._loadProgress);
    }

    /** cleanup application */
    public destroy() {
        console.info("Application: destroying");
        this._state = ApplicationState.Destroying;

        // remove from assetManager
        AssetManager.LoadStarted.off(this._loadStart);
        AssetManager.LoadFinished.off(this._loadFinished);
        AssetManager.LoadFailed.off(this._loadFailed);
        AssetManager.LoadProgress.off(this._loadProgress);

        this.OnUpdate.clearAll();
        this.OnTouchStart.clearAll();
        this.OnTouchMove.clearAll();
        this.OnTouchEnd.clearAll();
        this.OnMouseEnter.clearAll();
        this.OnMouseLeave.clearAll();
        this.OnMouseDown.clearAll();
        this.OnMouseMove.clearAll();
        this.OnMouseWheel.clearAll();
        this.OnMouseUp.clearAll();
        this.OnDragOver.clearAll();
        this.OnDrop.clearAll();

        this._destroyContainer();

        if(this._delegate) {
            this._delegate.destroy();
        }
        this._delegate = null;

        // flush used gpu memory
        MaterialLibrary.flushGPUMemory();
        TextureLibrary.flushGPUMemory();
        MeshLibrary.flushGPUMemory();

        if(this.renderer) {
            this.renderer.destroy();
        }
        this.renderer = null;

        // free DOM element references
        this.container = null;
    }

    /**
     * initialization code
     * will be called while preloading
     */
    public initialize() {

        this._switchState(ApplicationState.Initialize);

        // start first loading phase
        //FIXME: with loading screen?
        this.startLoading(true);

        try {

            // start preloading (will call application preloading)
            if(this._delegate) {
                this._delegate.onPreloadItems({
                    startLoading: () => this.startLoading(),
                    finishLoading: () => this.finishLoading()
                });
                this._delegate.onPreInitialization();
            }

            // no 3d renderer on unittesting
            if(build.Options.isUnittest) {
                // finish loading
                this.finishLoading();
                return;
            }

            // default renderer
            const renderSettings = this._delegate ? this._delegate.renderSetup() : null;
            this.renderer = new Render(renderSettings);

            // apply camera stuff and render settings to dom element size
            this.onWindowResize();

        } catch(err) {
            // FATAL ERROR
            console.error(err);
            this.destroy();
            return;
        }

        // finish loading
        this.finishLoading();
    }

    /**
     * toggle to fullscreen
     * this can only be called from the user input
     * so this needs to be attached to a button
     */
    public fullscreen() {
        if(this.renderer) {
            this.renderer.fullscreen();
        }
    }

    /**
     * set application to loading phase
     */
    public startLoading(loadingScreen:boolean = false) {
        const lastState = this._state;

        //console.warn(`START_LOADING ${this._loadingCounter} with state ${ApplicationState[this._state]}`);
        this._loadingCounter++;

        // in startup phase, completly ignoring
        if(this.state === ApplicationState.Startup) {
            return;
        }

        if(this._loadingCounter === 1) {

            //this should never happen
            if(this._state === ApplicationState.Loading) {
                console.warn("App: Invalid Loading State");
            }

            // switch to loading state
            if(this.state === ApplicationState.Initialize) {
                this._switchState(ApplicationState.Preloading);
            } else if(this._state !== ApplicationState.Preloading) {
                this._switchState(ApplicationState.Loading);
            }

            // notify loading
            if(this._delegate) {
                this._delegate.onLoad(loadingScreen);
            }
        } else if(this.state === ApplicationState.Initialize) {
            // force switch from initializing to preloading
            if(this._loadingCounter > 0) {
                this._switchState(ApplicationState.Preloading);
            }
        }
    }

    /**
     * finish loading (return to running state?)
     */
    public finishLoading() {
        const lastState = this._state;

        if(this._loadingCounter <= 0) {
            console.warn("Application: loading counter wrong!!!");
        }

        //console.warn(`FINISH_LOADING ${this._loadingCounter} with state ${ApplicationState[this._state]}`);
        this._loadingCounter = Math.max(0, this._loadingCounter - 1);

        // in startup phase, completly ignoring
        if(this.state === ApplicationState.Startup) {
            return;
        }

        if(this._loadingCounter === 0) {

            //this should never happen
            if(this._state === ApplicationState.Running) {
                console.warn("App: Invalid Loading State");
                return;
            }

            // preloading phase
            if(this._state === ApplicationState.Preloading) {

                // start loading (without notify)
                // to prevent reentrant into start/finish loading
                // because delegate.init can invoke startLoading / finishLoading
                this._loadingCounter++;

                try {

                    // still loading but not in preloading phase any more
                    // the next finish loading will switch from loading state
                    // to running when there is no more data to load
                    this._switchState(ApplicationState.Loading);

                    // init callback
                    if(this._delegate) {
                        this._delegate.onInitialization();
                    }

                    // render for uploading deferred stuff
                    this.render(true);

                } catch(error) {
                    console.warn(error);

                    //FIXME: set to error state...
                    this._switchState(ApplicationState.Loading);
                }

                // dispatch async to prevent reentrant
                // finishes loading phase and will switch to running
                setTimeout(() => this.finishLoading());

            } else if(this._state === ApplicationState.Loading) {

                // loading phase finished?
                if(this._loadingCounter === 0) {
                    this._switchState(ApplicationState.Running);

                    // on load finished should never invoke a start/finish loading
                    if(this._delegate) {
                        this._delegate.onLoadFinished();
                    }
                }
            }
        }
    }

    /**
     * update loop
     * deltaTime in milliseconds (1/60)
     * FIXME: remove force param?
     */
    public update(deltaTime:number, force:boolean = false) {
        // global playing mode (editor stops updating...)
        if(!build.Options.playing && !force) {
            return;
        }

        // update is not allowed at startup or initialization phase
        if(this._state === ApplicationState.Startup ||
            this._state === ApplicationState.Initialize) {
            return;
        }

        //FIXME: always???
        //ADD SOME PAUSE STUFF??
        if(this._state === ApplicationState.Running || this._state === ApplicationState.Loading || force) {

            // size change??
            let sizeChange = false;
            if(this.renderer) {
                const renderSize = this.renderer.size;

                if(this.renderer.container) {

                    sizeChange = this.renderer.container.clientWidth !== renderSize.clientWidth ||
                                 this.renderer.container.clientHeight !== renderSize.clientHeight;
                } else if(this.container) {

                    sizeChange = this.container.clientWidth !== renderSize.clientWidth ||
                                 this.container.clientHeight !== renderSize.clientHeight;
                } else {
                    sizeChange = window.innerWidth !== renderSize.clientWidth || window.innerHeight !== renderSize.clientHeight;
                }
            }

            if(sizeChange) {
                this.onWindowResize(null);
            }

            // animation update
            if(build.Options.libraries.TWEEN.available) {
                TWEEN.update();
            }

            // update callback
            if(this._delegate) {
                this._delegate.update(deltaTime);
            }

            // event update
            this.OnUpdate.trigger(deltaTime);
        }
    }

    /**
     * render loop
     */
    public render(force:boolean = false) {
        // never render in startup or initialization phase???
        if(this._state === ApplicationState.Startup ||
        this._state === ApplicationState.Initialize) {
            return;
        }

        // not in viewport or at pre loading and no force to draw
        if(!this.isInViewport && !force) {
            return;
        }

        // pre loading or loading and do not want to draw
        if( (!this.renderAtPreloading && this._state === ApplicationState.Preloading && !force) ||
        (!this.renderAtLoading && this._state === ApplicationState.Loading && !force)) {
            return;
        }

        // callback
        if(this._delegate && this.renderer) {
            this._delegate.render(this.renderer);
        }
    }

    /**
     * mouse down event
     */
    public onMouseDown = (event:MouseEvent) => {

        // not reliable
        //if(Platform.get().isTouchDevice) {
        //    return;
        //}

        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if(this.renderer && this.renderer.container) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        // set mouse state (polyfill or older browsers)
        const buttons = mouseButtonDown(event, this._mouseButtonState);
        this.mouse.leftButton = (buttons & 1) === 1;
        this.mouse.rightButton = (buttons & 2) === 2;
        this.mouse.middleButton = (buttons & 4) === 4;

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnMouseDown.trigger(event);
        if(this._delegate) {
            this._delegate.onMouseDown(event);
        }
    }

    /**
     * mouse up event
     */
    public onMouseUp = (event:MouseEvent) => {

        // not reliable
        //if(Platform.get().isTouchDevice) {
        //    return;
        //}

        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if(this.renderer && this.renderer.container) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        // set mouse state (polyfill or older browsers)
        const buttons = mouseButtonUp(event, this._mouseButtonState);
        this.mouse.leftButton = !((buttons & 1) !== 1);
        this.mouse.rightButton = !((buttons & 2) !== 2);
        this.mouse.middleButton = !((buttons & 4) !== 4);

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnMouseUp.trigger(event);
        if(this._delegate) {
            this._delegate.onMouseUp(event);
        }
    }

    /**
     * mouse movement event
     */
    public onMouseMove = (event:MouseEvent) => {

        // not reliable
        //if(Platform.get().isTouchDevice) {
        //    return;
        //}

        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if(this.renderer && this.renderer.container) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnMouseMove.trigger(event);

        if(this._delegate) {
            this._delegate.onMouseMove(event);
        }
    }

    /** mouse wheel event */
    public onMouseWheel = (event:MouseEvent) => {

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnMouseWheel.trigger(event);
        if(this._delegate) {
            this._delegate.onMouseWheel(event);
        }
    }

    /**
     * mouse enter
     */
    public onMouseEnter = (event:MouseEvent) => {

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnMouseEnter.trigger(event);
        if(this._delegate) {
            this._delegate.onMouseEnter(event);
        }
    }

    /**
     * mouse leave
     */
    public onMouseLeave = (event:MouseEvent) => {

        //TODO: reset state
        resetMouseState(this._mouseButtonState);
        this.mouse.leftButton = false;
        this.mouse.rightButton = false;
        this.mouse.middleButton = false;

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnMouseLeave.trigger(event);
        if(this._delegate) {
            this._delegate.onMouseLeave(event);
        }
    }

    /**
     * mouse click event
     */
    public onMouseClick = (event:MouseEvent) => {

        // not reliable
        //if(Platform.get().isTouchDevice) {
        //    return;
        //}

        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        this.mouse.normalizedX = this.mouse.normalizedScreenX;
        this.mouse.normalizedY = this.mouse.normalizedScreenY;

        if(this.renderer && this.renderer.container) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        }

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        //FIXME: add OnMouseClick event??

        if(this._delegate) {
            this._delegate.onMouseClick(event);
        }
    }

    /** drag and drop over event */
    public onDragOver = (event:DragEvent) => {

        // FORCE to update mouse informations while dragging over
        this.mouse.screenX = event.clientX;
        this.mouse.screenY = event.clientY;

        this.mouse.normalizedScreenX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
        this.mouse.normalizedScreenY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;

        if(this.renderer && this.renderer.container) {
            const rect = this.renderer.container.getBoundingClientRect();
            this.mouse.x = event.clientX - rect.left;
            this.mouse.y = event.clientY - rect.top;

            this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
        } else {
            //FULLSCREEN or not initialized yet
            this.mouse.x = event.clientX;
            this.mouse.y = event.clientY;

            this.mouse.normalizedX = (event.clientX / window.innerWidth) * 2.0 - 1.0;
            this.mouse.normalizedY = -(event.clientY / window.innerHeight) * 2.0 + 1.0;
        }

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnDragOver.trigger(event);

        if(this._delegate) {
            this._delegate.onDragOver(event);
        }
    }

    /** drop event */
    public onDrop = (event:DragEvent) => {

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnDrop.trigger(event);

        if(this._delegate) {
            this._delegate.onDrop(event);
        }
    }

    /**
     * touch event
     */
    public onTouchStart = (event:TouchEvent) => {
        // mark mouse from touch
        this.mouse.isTouchDevice = true;

        // set mouse position on one finger tip
        // simulate mouse
        if(event.touches.length === 1) {
            this.mouse.screenX = event.touches[0].pageX;
            this.mouse.screenY = event.touches[0].pageY;

            this.mouse.normalizedScreenX = (this.mouse.screenX / window.innerWidth) * 2.0 - 1.0;
            this.mouse.normalizedScreenY = -(this.mouse.screenY / window.innerHeight) * 2.0 + 1.0;

            if(this.renderer && this.renderer.container) {
                const rect = this.renderer.container.getBoundingClientRect();

                this.mouse.x = event.touches[0].pageX - rect.left;
                this.mouse.y = event.touches[0].pageY - rect.top;

                this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
            } else {
                //FULLSCREEN or not initialized yet
                this.mouse.x = event.touches[0].pageX;
                this.mouse.y = event.touches[0].pageY;

                this.mouse.normalizedX = (this.mouse.x / window.innerWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / window.innerHeight) * 2.0 + 1.0;
            }
        }

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        this.OnTouchStart.trigger(event);

        if(this._delegate) {
            this._delegate.onTouchStart(event);
        }
    }

    /**
     * touch move event
     */
    public onTouchMove = (event:TouchEvent) => {
        // mark mouse from touch
        this.mouse.isTouchDevice = true;

        // simulate mouse
        if(event.touches.length === 1) {
            this.mouse.screenX = event.touches[0].pageX;
            this.mouse.screenY = event.touches[0].pageY;

            this.mouse.normalizedScreenX = (this.mouse.screenX / window.innerWidth) * 2.0 - 1.0;
            this.mouse.normalizedScreenY = -(this.mouse.screenY / window.innerHeight) * 2.0 + 1.0;

            if(this.renderer && this.renderer.container) {
                const rect = this.renderer.container.getBoundingClientRect();
                this.mouse.x = event.touches[0].pageX - rect.left;
                this.mouse.y = event.touches[0].pageY - rect.top;

                this.mouse.normalizedX = (this.mouse.x / this.renderer.container.clientWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / this.renderer.container.clientHeight) * 2.0 + 1.0;
            } else {
                //FULLSCREEN or not initialized yet
                this.mouse.x = event.touches[0].pageX;
                this.mouse.y = event.touches[0].pageY;

                this.mouse.normalizedX = (this.mouse.x / window.innerWidth) * 2.0 - 1.0;
                this.mouse.normalizedY = -(this.mouse.y / window.innerHeight) * 2.0 + 1.0;
            }
        }

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        //console.log("TOUCH MOVE");
        this.OnTouchMove.trigger(event);

        if(this._delegate) {
            this._delegate.onTouchMove(event);
        }
    }

    /** touch event */
    public onTouchEnd = (event:TouchEvent) => {
        // mark mouse from touch
        this.mouse.isTouchDevice = true;

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        //console.log("TOUCH END");
        this.OnTouchEnd.trigger(event);

        if(this._delegate) {
            this._delegate.onTouchEnd(event);
        }
    }

    /** KeyboardEvent */
    public onKeyDown = (event:KeyboardEvent) => {

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        if(this._delegate) {
            this._delegate.onKeyDown(event);
        }
    }

    public onKeyUp = (event:KeyboardEvent) => {

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        if(this._delegate) {
            this._delegate.onKeyUp(event);
        }
    }

    /** scrolling event */
    public onScroll = (event:Event) => {
        this._updateViewportInView();

        //FIXME: register events at initialization phase
        // this prevent inputs receiving too soon
        // checking on ApplicationState can go wrong:
        // mouseDown in init phase, mouseUp in running phase...
        if(this._state !== ApplicationState.Running) {
            return;
        }

        if(this._delegate) {
            this._delegate.onScroll(event);
        }
    }

    public onWindowResizeEvent = (event:Event) => {
        this.onWindowResize(event);
    }

    /**
     * process dom element resize event
     */
    public onWindowResize(event?:Event) {
        if(build.Options.debugApplicationOutput && event) {
            console.log("Application::onWindowResize: window resize", event);
        }

        if(this.renderer) {
            this.renderer.onWindowResize();
        }

        const domSize = this.DOMRenderSize();

        if(build.Options.debugApplicationOutput) {
            console.log("App::onWindowResize: dom size ", domSize);
        }

        this._updateViewportInView();

        // notify all (FIXME: before delegate?)
        this.OnWindowResize.trigger(event, domSize);

        //FIXME: always send event or when ApplicationState is running??
        if(this._delegate && this._delegate.onWindowResize) {
            this._delegate.onWindowResize(domSize);
        } else {
            console.warn("App::onWindowResize: invalid delegate");
        }
    }

    /** get DOM element size (prefers render container) */
    public DOMRenderSize() : RenderSize {
        if(this.renderer) {
            return { clientWidth: this.renderer.size.clientWidth,
                     clientHeight: this.renderer.size.clientHeight,
                     dpr: this.renderer.size.dpr,
                     width: this.renderer.size.width,
                     height: this.renderer.size.height };
        } else if(this.container) {
            return { clientWidth: this.container.clientWidth,
                     clientHeight: this.container.clientHeight,
                     dpr: window.devicePixelRatio,
                     width: Math.floor(this.container.clientWidth * window.devicePixelRatio),
                     height: Math.floor(this.container.clientHeight * window.devicePixelRatio)};
        } else {
            return { clientWidth: window.innerWidth,
                     clientHeight: window.innerHeight,
                     dpr: window.devicePixelRatio,
                     width: Math.floor(window.innerWidth * window.devicePixelRatio),
                     height: Math.floor(window.innerHeight * window.devicePixelRatio) };
        }
    }

    private _switchState(state:ApplicationState) {
        const lastState = this._state;
        // check for state switch
        if(lastState !== state) {
            this._state = state;

            // tools
            if(lastState !== -1 && lastState !== ApplicationState.Running) {
                devMarkTimelineEnd(ApplicationState[lastState]);
            }

            if(this._state !== ApplicationState.Running) {
                devMarkTimelineStart(ApplicationState[this._state]);
            }

            if(build.Options.debugApplicationOutput) {
                console.info(`App: Switching State: ${ApplicationState[lastState]} to State: ${ApplicationState[this._state]}`);
            }
        }
    }

    /** update viewport in user view */
    private _updateViewportInView() : void {

        // assuming in viewport if no container is available
        if(!this.container) {
            if(build.Options.debugApplicationOutput) {
                console.info("App: forcing isInViewport because no container found");
            }
            this._isInViewport = true;
            return;
        }

        const rect = this.container.getBoundingClientRect();
        const scrollTop = document.body.scrollTop;
        const windowHeight = window.innerHeight;
        const windowHeightHalf = windowHeight / 2;

        const elementTop = scrollTop + rect.top;
        const elementBottom = elementTop + rect.height;
        const winTop = window.scrollY;
        const winBottom = winTop + windowHeight;

        const isUpper = elementTop + (rect.height / 2) < window.scrollY + windowHeightHalf;

        const overlapY = Math.max(0, Math.min(elementBottom,winBottom) - Math.max(elementTop,winTop));
        const overlapPc = (1 - overlapY / Math.min(windowHeight, rect.height)) * (isUpper ? -1 : 1);

        const centerDistance = overlapPc;

        if(build.Options.debugApplicationOutput) {
            const newIsInViewport = centerDistance < 1.0 && centerDistance > -1.0;

            if(this._isInViewport !== newIsInViewport) {
                console.info("App: switching is in viewport from "+this._isInViewport+" to "+newIsInViewport);
            }
        }

        this._isInViewport = centerDistance < 1.0 && centerDistance > -1.0;
    }

    /** asset started loading again */
    private _loadStart = () => {
        //console.warn("App: _loadStart");
        this.startLoading();
    }

    /**
     * loading progress feedback (one item)
     */
    private _loadProgress = (stats:FileStat) => {
        if(this._delegate) {
            this._delegate.onLoadProgress(stats);
        }
    }

    /**
     * loading failed for asset manager (one item)
     */
    private _loadFailed = () => {
        if(this._delegate) {
            this._delegate.onLoadFailed();
        }
    }

    /**
     * loading finished for asset manager
     */
    private _loadFinished = () => {
        //console.warn("App: _loadFinished");
        this.finishLoading();
    }

    /**
     * connect application to container view
     */
    private _initContainer() {
        // browser environment?!
        if(!document || !window) {
            return;
        }

        if(this.container) {
            this.container.addEventListener('click', this.onMouseClick);
            this.container.addEventListener('mousedown', this.onMouseDown);
            this.container.addEventListener('mouseup', this.onMouseUp);
            this.container.addEventListener('mousemove', this.onMouseMove);
            this.container.addEventListener('mouseleave', this.onMouseLeave);
            this.container.addEventListener('mouseenter', this.onMouseEnter);

            this.container.addEventListener('wheel', this.onMouseWheel);
            //FIXME: check if needed any more?
            this.container.addEventListener('MozMousePixelScroll', this.onMouseWheel); // firefox

            this.container.addEventListener('touchstart', this.onTouchStart);
            this.container.addEventListener('touchend', this.onTouchEnd);
            this.container.addEventListener('touchcancel', this.onTouchEnd);
            this.container.addEventListener('touchmove', this.onTouchMove);

            // drag & drop
            this.container.addEventListener('dragenter', this.onMouseEnter);
            this.container.addEventListener('dragleave', this.onMouseLeave);
            this.container.addEventListener('dragover', this.onDragOver);
            this.container.addEventListener('drop', this.onDrop);
        }

        document.addEventListener("scroll", this.onScroll);
        window.addEventListener("keydown", this.onKeyDown, true);
        window.addEventListener("keyup", this.onKeyUp, true);

        //FIXME: add to container?!
        window.addEventListener("resize", this.onWindowResizeEvent, true);

        this._updateViewportInView();
    }

    private _destroyContainer() {
        // browser environment?!
        if(!document || !window) {
            return;
        }

        if(this.container) {
            this.container.removeEventListener('click', this.onMouseClick);
            this.container.removeEventListener('mousedown', this.onMouseDown);
            this.container.removeEventListener('mouseup', this.onMouseUp);
            this.container.removeEventListener('mousemove', this.onMouseMove);
            this.container.removeEventListener('mouseleave', this.onMouseLeave);
            this.container.removeEventListener('mouseenter', this.onMouseEnter);

            this.container.removeEventListener('wheel', this.onMouseWheel);
            //FIXME: check if needed any more?
            this.container.removeEventListener('MozMousePixelScroll', this.onMouseWheel); // firefox

            this.container.removeEventListener('touchstart', this.onTouchStart);
            this.container.removeEventListener('touchend', this.onTouchEnd);
            this.container.removeEventListener('touchcancel', this.onTouchEnd);
            this.container.removeEventListener('touchmove', this.onTouchMove);

            // drag & drop
            this.container.removeEventListener('dragenter', this.onMouseEnter);
            this.container.removeEventListener('dragleave', this.onMouseLeave);
            this.container.removeEventListener('dragover', this.onDragOver);
            this.container.removeEventListener('drop', this.onDrop);
        }

        document.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("keydown", this.onKeyDown, true);
        window.removeEventListener("keyup", this.onKeyUp, true);

        //FIXME: add to container?!
        window.removeEventListener("resize", this.onWindowResizeEvent, true);
    }

}

// application references
let application:Application = null;

// rendering
function redRender() {
    if(build.Options.development) {
        devProfileStart("redRender");
    }

    try {
        application.render();
    } catch(err) {
        console.error(err);
    }

    if(build.Options.development) {
        devProfileStop("redRender");
    }
}

// global update
function redTick() {
    // got reseted to false, do not produce
    // a frame
    if(tick.needTick === false) {
        //FIXME: process remaining calls?
        return;
    }

    tick.inFrame = true;

    // new frame
    math.mathTemporaryReset();

    // time
    const timeAtThisFrame = performance.now();
    const timeSinceLastTick = (timeAtThisFrame - tick.timeAtLastFrame);
    let timeSinceLastDoLogic = timeSinceLastTick + tick.leftover;
    let catchUpFrameCount = Math.floor(timeSinceLastDoLogic / tick.IdealTimePerFrame);

    // double of max catchup
    // too far away from realtime (reset)
    if(catchUpFrameCount > (tick.MaxCatchUp<<1)) {
        timeSinceLastDoLogic = tick.IdealTimePerFrame;
    }

    // catch up?
    if(catchUpFrameCount > tick.MaxCatchUp) {
        //console.log("Catching up: ", catchUpFrameCount);
        catchUpFrameCount = Math.min(catchUpFrameCount, tick.MaxCatchUp);
    } else {
        catchUpFrameCount = Math.max(catchUpFrameCount, 1);
    }

    // logic update
    for(let i = 0 ; i < catchUpFrameCount; i++) {
        // new game frame
        tick.gameFrameCount++;

        // call on next frame call
        if(nextFrameCalls.length) {
            const length = nextFrameCalls.length;
            for(let j = 0; j < length; ++j) {
                nextFrameCalls[j]();
            }
            nextFrameCalls.splice(0, length);
        }

        // call application
        try {
            application.update(tick.IdealDeltaTime);
        } catch(err) {
            console.error(err);
        }

        if(OnUpdate.hasHandlers) {
            OnUpdate.trigger(tick.IdealDeltaTime);
        }
    }

    redRender();

    tick.leftover = Math.max(0.0, timeSinceLastDoLogic - (catchUpFrameCount * tick.IdealTimePerFrame));
    tick.timeAtLastFrame = timeAtThisFrame;

    tick.inFrame = false;

    if(OnPostUpdate.hasHandlers) {
        setTimeout(() => OnPostUpdate.trigger(), 0);
    }

    // next frame
    tick.frameCount++;

    // profile frame
    tick.performanceMeasurement.minTime = Math.min(tick.performanceMeasurement.minTime, timeSinceLastTick);
    tick.performanceMeasurement.maxTime = Math.max(tick.performanceMeasurement.maxTime, timeSinceLastTick);
    tick.performanceMeasurement.count++;
    tick.performanceMeasurement.totalTime += timeSinceLastTick;
    tick.performanceMeasurement.averageTime = tick.performanceMeasurement.totalTime / tick.performanceMeasurement.count;
    // speed test result
    if(performanceTest.performanceTest) {
        redInternalSpeedTest();
    }

    // request new frame when there are applications
    if(tick.needTick) {
        requestAnimationFrame(redTick);
    }
}

let redInitialized = false;
let appDeferredInit:Application = null;

/** global initialization */
function redInit() {
    if(redInitialized) {
        console.warn("redInit: already running framework");
        return;
    }
    tick.globalClock = new Clock();

    // load build configuration
    build._initBuildSettings();

    // in development mode, register some functions at global window scope
    if(build.Options.development && typeof window !== 'undefined') {
        window.RED = window.RED || {};
        window.RED.appInit = appInit;
        window.RED.appDestroy = appDestroy;
        window.RED.appGet = appGet;
        window.RED.appNeedTick = appNeedTick;
    }

    //TODO: remove this chain and integrate ShaderLibrary into preloading step
    // init shader system
    AssetManager.init().then( () => {
        return ShaderLibrary.init();
    }).then(() => {
        // init material system
        MaterialLibrary.init();

        redInitialized = true;
        if(build.Options.debugApplicationOutput) {
            console.info("redTyped Framework init");
        }

        // find deferred application inits
        if(appDeferredInit) {
            application = appDeferredInit;
            appDeferredInit.initialize();
        }
        appDeferredInit = null;
        tick.needTick = true;

        // at a target 60 FPS.
        tick.timeAtLastFrame = performance.now();
        redTick();
    });
}

/** set frame update */
export function appNeedTick(value:boolean) {

    // not initalized, ignore value
    if(!redInitialized) {
        console.warn("Application: tick cannot be altered when not initialized.");
        return;
    }
    // value change
    if(tick.needTick !== value) {
        // switching to no tick any more
        if(tick.needTick) {
            tick.needTick = value;
        } else {
            // switching to ticking
            tick.needTick = value;

            // request a new frame
            tick.timeAtLastFrame = performance.now();
            requestAnimationFrame(redTick);
        }
    }
}

/** initializing code */
export function appInit(delegate:AppDelegate) {
    try {
        if(!delegate) {
            throw new Error("Invalid Application delegate");
        }

        //TODO: we call this before global initialization
        // as many application sets a base path for the assetManager
        // this should be changed, build settings should handle base pathes etc.
        const app = new Application(delegate);

        // auto init
        if(!redInitialized) {
            redInit();
        } else if(tick.needTick === false) {
            // redInit called but no application yet
            // request a tick
            tick.needTick = true;
            tick.timeAtLastFrame = performance.now();
            requestAnimationFrame(redTick);
        }

        if(redInitialized) {
            application = app;
            // initialize application
            application.initialize();
        } else {
            // deferred startup
            appDeferredInit = app;
            // wait for deferred init
            tick.needTick = false;
        }
    } catch (error) {
        console.warn(error);
    }
}

/**
 * access application
 */
export function appGet() : Application {
    return application;
}

/**
 * application destroy
 */
export function appDestroy(delegate:AppDelegate) {
    // destroying in pre-setup phase
    if(appDeferredInit) {
        appDeferredInit = null;
        // no more applications left
        tick.needTick = false;
        return;
    }
    // check warning
    console.assert(application, "application not yet initialized");
    console.assert(delegate === application.delegate, "delegate mismatch");

    // no more applications left
    tick.needTick = false;
    //
    const app = application;

    // remove from global update
    application = null;

    // finally destroy
    app.destroy();
}
