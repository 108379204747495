import { FileStat } from "../io/AssetInfo";
import { Application, appGet } from "./App";
import { IWorld } from "./WorldAPI";
import { Render } from "../render/Render";
import { RenderInitSetup, createPlatformRenderInitConfig } from "../render/Config";
import { IONotifier } from "../io/Interfaces";
import { RenderQuality } from "../render/QualityLevels";
import { World } from "./World";
import { CameraComponent } from "../framework-components/CameraComponent";
import { RedCamera } from "../render/Camera";

/** mouse position data */
export interface Mouse {
    /** button clicks */
    leftButton:boolean;
    rightButton:boolean;
    middleButton:boolean;
    /** mouse position in DOM element */
    x:number;
    y:number;
    /** normalized position in DOM element */
    normalizedX:number;
    normalizedY:number;
    /** mouse position in screen coordinates */
    screenX:number;
    screenY:number;
    /** normalized (-1.0 - 1.0) screen coordinates */
    normalizedScreenX:number;
    normalizedScreenY:number;
    /** touch device detection */
    isTouchDevice:boolean;
}

/**
 * application delegate class.
 *
 * Overwrite this class for your application.
 */
export class AppDelegate {

    /** access application server */
    public get app() : Application {
        return appGet();
    }

    /** global world instance */
    get world() : IWorld {
        return World;
    }

    /** renderer */
    public get renderer() : Render {
        return this.app.renderer;
    }

    /** mouse position */
    public get mouse(): Mouse {
        return this.app.mouse;
    }

    /** builtin data */
    protected _renderConfig:RenderInitSetup;

    /** construction */
    constructor() {
        this._renderConfig = null;
    }

    /**
     * preload callback
     */
    public onPreloadItems(ioNotifier:IONotifier) {
    }

    /**
     * pre initialization
     * called during preloading
     * IMPORTANT: no render device available at this point
     */
    public onPreInitialization() {

        if(!this._renderConfig) {
            const element = this.containerElement();

            RenderQuality.qualityLevel = RenderQuality.HighQuality;

            this._renderConfig = createPlatformRenderInitConfig({
                DOMElement: element,
                qualityLevel: RenderQuality.HighQuality,
                renderShadowMaps: true,
                renderAntialias: true,
                renderOffscreen: false,
                renderSize: null
            });
        }
    }

    /**
     * init callback
     * called after preloading
     * IMPORTANT: render device initialized at this point
     */
    public onInitialization() {
        const settings = this.renderSetup();
        const size = settings.renderSize || this.renderer.size;

        // write back to correct rendering size
        settings.renderSize = size;

        // INITIALIZE WORLD
        World.init();
    }

    /**
     * called before destroying
     */
    public destroy() {
        // clear world
        World.destroy(true, {
            noGeometry: false,
            noMaterial: false
        });
    }

    /**
     * returns the default rendering configuration
     * will be called before initialization
     * overwrite to support custom settings
     */
    public renderSetup() : RenderInitSetup {
        if(!this._renderConfig) {
            console.error("AppDelegate: accessing invalid rendering configuration");
        }
        return this._renderConfig;
    }

    /**
     * DOM Element this app is using
     */
    public containerElement():HTMLElement {
        return document.getElementById("scene");
    }

    /** update callback */
    public update(deltaTime:number) {

        if(World && World.isValid()) {
            World.think(deltaTime);
        }
    }

    /** pre render callback */
    public preRender(render:Render, camera?:RedCamera) {
        // passing main camera
        const cameraComponent = CameraComponent.Main;
        camera = camera || cameraComponent ? cameraComponent.sceneCamera : null;

        // prepare world for rendering
        if(World && World.isValid()) {
            World.preRender(render, camera);
        }
    }

    /** rendering callback */
    public render(render:Render) {
        // passing main camera
        const cameraComponent = CameraComponent.Main;
        const camera = cameraComponent ? cameraComponent.sceneCamera : null;

        this.preRender(render, camera);

        if(World && World.isValid()) {
            World.render(render, camera);
        }
    }

    /** mouse events */
    public onMouseClick(event:MouseEvent) {
    }
    public onMouseDown(event:MouseEvent) {
    }
    public onMouseUp(event:MouseEvent) {
    }
    public onMouseMove(event:MouseEvent) {
    }
    public onMouseWheel(event:MouseEvent) {
    }
    public onMouseEnter(event:MouseEvent) {
    }
    public onMouseLeave(event:MouseEvent) {
    }

    /** touch events */
    public onTouchStart(event:TouchEvent) {}
    public onTouchMove(event:TouchEvent) {}
    public onTouchEnd(event:TouchEvent) {}

    /** key events */
    public onKeyDown(event:KeyboardEvent) {
    }
    public onKeyUp(event:KeyboardEvent) {
    }

    /** drag & drop */
    public onDragOver(event:DragEvent) {
        // per default prevent default
        event.preventDefault();
    }
    public onDrop(event:DragEvent) {
        // per default prevent default
        event.preventDefault();
    }

    /** scrolling */
    public onScroll(event:Event) {
    }

    /** window resize event */
    public onWindowResize(domSize:any) {
    }

    /**
     * application is going to load state
     * should never invoke a start/finish loading (UNTESTED)
     */
    public onLoad(loadingScreen:boolean) {
    }

    /**
     * loading failed
     * should never invoke a start/finish loading (UNTESTED)
     */
    public onLoadFailed() {
    }

    /**
     * loading finished
     * should never invoke a start/finish loading (UNTESTED)
     */
    public onLoadFinished() {
    }

    /** progress callback */
    public onLoadProgress(stats:FileStat) {
    }

}
