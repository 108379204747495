/**
 * Texture.ts: Texture helper code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { ResolveCallback } from "../io/Interfaces";
import { Texture } from "../../lib/threejs/textures/Texture";
import { UVMapping, RepeatWrapping, NearestFilter, LinearEncoding, RGBAFormat, UnsignedByteType, LinearFilter, LinearMipMapLinearFilter, RGBFormat, CubeReflectionMapping } from "../../lib/threejs/constants";
import { DataTexture } from "../../lib/threejs/textures/DataTexture";
import { CubeTexture } from "../../lib/threejs/textures/CubeTexture";
import { _Math as THREEMath } from "../../lib/threejs/math/Math";
import { WebGLRenderTarget } from "../../lib/threejs/renderers/WebGLRenderTarget";

/**
 * 1x1 white image
 * return a intermediate image
 * TODO: add this to AssetManager?
 */
export function whiteImage() : HTMLImageElement {
    if(!whiteImage['_instance']) {
        whiteImage['_instance'] = new Image(1,1);
        whiteImage['_instance'].src = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==";
    }
    return whiteImage['_instance'];
}

/**
 * 4x4 black image
 * return a intermediate image
 * TODO: add this to AssetManager?
 */
export function blackImage() : HTMLImageElement {
    if(!blackImage['_instance']) {
        blackImage['_instance'] = new Image(4,4);
        blackImage['_instance'].src = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/2wBDAQMDAwQDBAgEBAgQCwkLEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBD/wAARCAAEAAQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAn/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AJVAA//Z";
    }
    return blackImage['_instance'];
}

/**
 * TODO: re-check
 * normal mapped
 */
export function normalImage() : HTMLImageElement {
    if(!normalImage['_instance']) {
        normalImage['_instance'] = new Image(4,4);
        normalImage['_instance'].src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAE0lEQVR42mOsr///nwEJMJIuAADafQv1Sx48WwAAAABJRU5ErkJggg==";
    }
    return normalImage['_instance'];
}

/**
 * return a intermediate texture
 * TODO: add this to AssetManager?
 */
export function whiteTexture() : any {
    if(!whiteTexture['_instance']) {
        // const data = new Uint8Array( 4 * 1 );
        // data[0] = 255;
        // data[1] = 255;
        // data[2] = 255;
        // data[3] = 255;

        // whiteTexture['_instance'] = new DataTexture(data, 1, 1, RGBAFormat, UnsignedByteType, UVMapping, RepeatWrapping, RepeatWrapping, NearestFilter, NearestFilter, 0, LinearEncoding);
        // whiteTexture['_instance'].name = "White Texture";
        // whiteTexture['_instance'].needsUpdate = true;
        whiteTexture['_instance'] = new Texture(whiteImage(), UVMapping, RepeatWrapping, RepeatWrapping, NearestFilter, NearestFilter, undefined, undefined, 1, LinearEncoding);
        whiteTexture['_instance'].name = "White Texture";
        whiteTexture['_instance'].needsUpdate = true;
    }
    return whiteTexture['_instance'];
}

/**
 * return a intermediate texture
 * TODO: add this to AssetManager?
 */
export function blackTexture() : Texture {
    if(!blackTexture['_instance']) {
        const data = new Uint8Array( 4 * 1 );
        data[0] = 0;
        data[1] = 0;
        data[2] = 0;
        data[3] = 255;

        blackTexture['_instance'] = new DataTexture(data, 1, 1, RGBAFormat, UnsignedByteType, UVMapping, RepeatWrapping, RepeatWrapping, LinearFilter, LinearFilter, 0, LinearEncoding);
        blackTexture['_instance'].name = "Black Texture";
        blackTexture['_instance'].needsUpdate = true;
    }
    return blackTexture['_instance'];
}

/**
 * return a intermediate texture
 * TODO: add this to AssetManager?
 */
export function blackTextureCube() : Texture {
    if(!blackTextureCube['_instance']) {
        const faces = [blackImage(),blackImage(),blackImage(),blackImage(),blackImage(),blackImage()];

        blackTextureCube['_instance'] = new CubeTexture(faces, CubeReflectionMapping, RepeatWrapping, RepeatWrapping, LinearFilter, LinearMipMapLinearFilter, RGBFormat);
        blackTextureCube['_instance'].name = "Black Texture Cube";
        blackTextureCube['_instance'].generateMipmaps = true;
        blackTextureCube['_instance'].needsUpdate = true;
    }
    return blackTextureCube['_instance'];
}

/**
 * return a intermediate texture
 * TODO: add this to AssetManager?
 */
export function normalTexture() : Texture {
    if(!normalTexture['_instance']) {
        const data = new Uint8Array( 4 * 1 * 1 );
        for(let i = 0; i < 1; ++i) {
            data[(i * 4) + 0] = 128;
            data[(i * 4) + 1] = 128;
            data[(i * 4) + 2] = 255;
            data[(i * 4) + 3] = 255;
        }

        // normalTexture['_instance'] = new DataTexture(data, 1, 1, RGBAFormat, UnsignedByteType, UVMapping, RepeatWrapping, RepeatWrapping, LinearFilter, LinearFilter, 0, LinearEncoding);
        // normalTexture['_instance'].name = "Normal Texture";
        // normalTexture['_instance'].needsUpdate = true;

        normalTexture['_instance'] = new Texture(normalImage(), UVMapping, RepeatWrapping, RepeatWrapping, NearestFilter, NearestFilter, RGBAFormat, UnsignedByteType, 0, LinearEncoding);
        normalTexture['_instance'].name = "Normal Texture";
        normalTexture['_instance'].needsUpdate = true;
    }
    return normalTexture['_instance'];
}

export function maxMipLevels(texture:Texture) {

    // setup max mip level
    let maxSize = 0;

    if(texture.image && (texture.image.width || texture.image.height)) {
        // mimick three.js behaviour
        const width = THREEMath.isPowerOfTwo(texture.image.width) ? texture.image.width : THREEMath.floorPowerOfTwo(texture.image.width);
        const height = THREEMath.isPowerOfTwo(texture.image.height) ? texture.image.height : THREEMath.floorPowerOfTwo(texture.image.height);

        maxSize = Math.max(width, height);
    } else if(texture.mipmaps && texture.mipmaps.length) {
        maxSize = 1 << (texture.mipmaps.length - 1);
    }

    return Math.max(1, Math.floor(Math.log2(maxSize) - 1));
}

export function maxMipLevelsTarget(target:WebGLRenderTarget) {

    // setup max mip level
    let maxSize = 0;
    if(target.width || target.height) {
        // mimick three.js behaviour
        const width = THREEMath.isPowerOfTwo(target.width) ? target.width : THREEMath.floorPowerOfTwo(target.width);
        const height = THREEMath.isPowerOfTwo(target.height) ? target.height : THREEMath.floorPowerOfTwo(target.height);

        maxSize = Math.max(width, height);
    } else if(target.texture) {
        return maxMipLevels(target.texture);
    }

    return Math.max(1, Math.floor(Math.log2(maxSize) - 1));
}

/** cache state */
export enum ETextureCacheState {
    UNKNOWN = 1,
    ERROR = 2,
    FALLBACK_LOADED = 3,
    FULLY_LOADED = 4
}

/** runtime state */
export enum ETextureCacheLoad {
    UNKNOWN = 1,
    FALLBACK = 2,
    FULLY = 3
}

/**
 * cache entry for textures
 */
export interface TextureCache {
    texture: Texture;
    state: ETextureCacheState;
    load: ETextureCacheLoad;
    resolver:Array<ResolveCallback>;
}
