///<reference path="../js_bindings.d.ts"/>

import * as Block from './../block/Import';
import * as Sidebar from './../sidebar/Import';
import {ColorTheme} from './ColorTheme';
import {Breadcrumb} from './Breadcrumb';

import { klaroConfig } from "../gdpr/config";


export class ContentLoader{
    private _isLoading:boolean = false;
    private _lastUrl:string = "";
    private _blockManager:Block.Manager;
    private _colorTheme:ColorTheme;
    private _breadcrumb:Breadcrumb;
    private _sidebarManager:Sidebar.Manager;
    // private _historyjs: any = <any>History;
    private _preloaderElement;
    private _requestId:number = 0;


    /* Scrollindicator stuff */
    private _scrollIndicatorElement: HTMLElement;
    private _scrollIndicatorTimeoutId: number;
    private _scrollIndicatorStartTime: number;
    private _disabledScrollIndicator:boolean = false;

    constructor(blockManager:Block.Manager, colorTheme:ColorTheme, breadcrumb:Breadcrumb, sidebarManager:Sidebar.Manager){
        this._blockManager = blockManager;
        this._colorTheme = colorTheme;
        this._breadcrumb = breadcrumb;
        this._sidebarManager = sidebarManager;

        this._preloaderElement = document.querySelector('.preloader');
        this._scrollIndicatorElement = document.querySelector('.scroll_indicator') as HTMLElement;
        this._scrollIndicatorElement.addEventListener('click',()=>{
            let scrollTargetY = this._scrollIndicatorElement.getBoundingClientRect().top * 0.5;
            let currentScrollX = window.scrollX;
            window.setTimeout(()=>{
                window.scroll({
                    top: scrollTargetY,
                    left: currentScrollX,
                    behavior: 'smooth'
                });
            },1);
        });

        this._disabledScrollIndicator = this._scrollIndicatorElement.classList.contains('disabled');
        document.addEventListener("scroll", this.stopScrollIndicatorByScroll);

        //console.log(window.location);
        //hacky fix for the redplant.de// bug
        let currentUrl = window.location.pathname.replace("//","/");
        let currentHash = window.location.hash;
        try{
            window.history.pushState(
                {
                    url:currentUrl+currentHash
                },
                document.title,
                currentUrl+currentHash
            );
        }
        catch(error){
            console.warn("history error", error);
        }


        window.addEventListener('popstate', (event)=>{
            //Prevent ugly scrolljumps at backbutton clicks
            var state = event.state;

            if(state){
                let currentScrollY = window.scrollY;
                let currentScrollX = window.scrollX;
                window.setTimeout(()=>{
                    window.scrollTo(currentScrollX,currentScrollY);
                },1);


                let stateUrl = state.url;
                if(stateUrl !== this._lastUrl){
                    this.loadContent(stateUrl);
                }
            }
        });


        // let allLinks = document.querySelectorAll('a');
        // document.querySelector('body').addEventListener('click',(event:MouseEvent)=>{
        //     console.log(event);
        // });

        $('body').on('click', 'a',  (event)=> {
            let atag = $(event.target).closest( "a" );
            let isAsync = atag.attr('data-linktype') == "async";

            if(isAsync){
                let url = atag.attr('href');

                let hasHash = url.match(/#.*/gi);
                if(hasHash){
                    let urlWithoutHash = url.replace(/#.*/gi, "");
                    let lastUrlWithoutHash = this._lastUrl.replace(/#.*/gi, "");
                    if(urlWithoutHash == lastUrlWithoutHash){
                        //prevent loading on hash change
                        return true;
                    }
                }

                event.preventDefault();
                //console.log("load dyn:", url,this._lastUrl);
                this.loadContent(url, true);
                return false;
            }

            return true;
        });


        const findAncestor = (el:HTMLElement, cls:string) => {
            while ((el = el.parentElement) && !el.classList.contains(cls));
            return el;
        }

        this._blockManager.onUpdateReady.subscribe(()=>{
            this.stopAnim();

            klaro.getManager().applyConsents();

            let scrollHash = window.location.hash;
            if(scrollHash.length > 0){
                setTimeout(()=>{
                    //console.log("ScrollHash",scrollHash);
                    // take the element with the id || take the element with the name (without the #)
                    let scrollTarget = document.querySelector(`${scrollHash} , [name="${scrollHash.replace('#','')}"]`) as HTMLElement;

                    let scrollTranslationCorrection = 0;

                    let blockWithScrolltransform = findAncestor(scrollTarget,'block_content');
                    if(blockWithScrolltransform){
                        const matrix = window.getComputedStyle(blockWithScrolltransform).transform;
                        if(matrix != 'none'){
                            scrollTranslationCorrection = Number(matrix.substring(matrix.lastIndexOf(',')+2,matrix.length-1));
                        }
                    }

                    let viewportHeightCorrection = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) / 4;

                    if(scrollTarget){
                        let offsetTop = scrollTarget.getBoundingClientRect().top;
                        window.scroll({
                            top: offsetTop - scrollTranslationCorrection - viewportHeightCorrection,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                },350);
            }

            this.startScrollIndicator();
            this.stopScrollIndicator();
            //console.log("ContentLoader blockManager ready");
        });

        this._blockManager.update();

        //set the lasturl to current
        this._lastUrl = location.pathname + location.hash ;
    }

    private startAnim = (callback:() => void):void => {
        this._isLoading = true;
        this._preloaderElement.classList.add('opened');
        this._preloaderElement.classList.add('visible');
        callback();
    }

    private stopAnim = ():void => {
        window.scroll(0, 0);
        this._preloaderElement.classList.remove('visible');
         window.setTimeout(()=>{
             this._preloaderElement.classList.remove('opened');
        },300);

        this._isLoading = false;
    }

    private startScrollIndicator = ():void => {
        clearTimeout(this._scrollIndicatorTimeoutId);
        this._scrollIndicatorStartTime = Date.now();
        this._scrollIndicatorElement.classList.add('opened');
    }

    private stopScrollIndicator = ():void => {
        clearTimeout(this._scrollIndicatorTimeoutId);

        this._scrollIndicatorTimeoutId = setTimeout(()=>{
            this._scrollIndicatorElement.classList.remove('opened');
        },5000);
    }

    private stopScrollIndicatorByScroll = ():void => {
        if(this._isLoading){
            return;
        }

        let indicatorOpenDuration = Date.now()-this._scrollIndicatorStartTime;
        if(indicatorOpenDuration < 500){
            return;
        }

        clearTimeout(this._scrollIndicatorTimeoutId);
        this._scrollIndicatorElement.classList.remove('opened');
    }

    private enableScrollIndicator = ():void => {
        this._disabledScrollIndicator = false;
        this._scrollIndicatorElement.classList.remove('disabled');
    }

    private disableScrollIndicator = ():void => {
        this._disabledScrollIndicator = true;
        this._scrollIndicatorElement.classList.add('disabled');
    }

    public loadContent(url, toHistory:boolean = false) {

        var param = '&ajax=1';
        let ajaxUrl = (url.indexOf(param) == -1) ? url + '&ajax=1' : url;
        let cleanUrl = url.replace(new RegExp(param + '$'), '');
        let startTime = Date.now();
        let currentRequestId = ++this._requestId;
        //console.log("loadContent:", url, toHistory);

        this._sidebarManager.sidebar.isOpen = false;

        this.startAnim(()=>{
            $.ajax(ajaxUrl)
                .done((response) => {
                    let delay = Math.max(800 - (Date.now()-startTime),0);

                    //console.log("Is Current Request?:", currentRequestId, this._requestId);
                    if(currentRequestId != this._requestId){
                        console.warn("Skipped Request:", ajaxUrl);
                        return;
                    }


                    this._lastUrl = url;
                    if(toHistory){
                        //console.log("loadContent toHistory:", url);
                        try{
                            window.history.pushState(
                                {
                                    url:url
                                },
                                document.title,
                                url
                            );
                        }
                        catch(error){
                            console.warn("history error", error);
                        }
                    }

                    document.title = response.title;

                    $('main').html(response.content);
                    $('.nav_content').html(response.navigation);
                    $('.locale').html(response.navigationLocale);

                    /** Refresh meta data */
                    document.querySelector('meta[name="keywords"]').setAttribute("content", response.meta.keywords);
                    document.querySelector('meta[name="description"]').setAttribute("content", response.meta.description);

                    //Open Graph
                    document.querySelector('meta[property="og:title"]').setAttribute("content", response.title);
                    document.querySelector('meta[property="og:url"]').setAttribute("content", response.meta.url);
                    document.querySelector('meta[property="og:image"]').setAttribute("content", response.meta.facebook.image);
                    document.querySelector('meta[property="og:site_name"]').setAttribute("content", response.title);
                    document.querySelector('meta[property="og:description"]').setAttribute("content", response.meta.facebook.description);

                    //Twitter
                    document.querySelector('meta[name="twitter:url"]').setAttribute("content", response.meta.url);
                    document.querySelector('meta[name="twitter:title"]').setAttribute("content", response.title);
                    document.querySelector('meta[name="twitter:description"]').setAttribute("content", response.meta.twitter.description);
                    document.querySelector('meta[name="twitter:image"]').setAttribute("content", response.meta.twitter.image);

                    //Facebook
                    document.querySelector('meta[itemprop="name"]').setAttribute("content", response.title);
                    document.querySelector('meta[itemprop="description"]').setAttribute("content", response.meta.facebook.description);
                    document.querySelector('meta[itemprop="image"]').setAttribute("content", response.meta.facebook.image);

                    //Scroll animation
                    if(response.disableScrollIndicator  === true){
                        this.disableScrollIndicator();
                    }
                    else{
                        this.enableScrollIndicator();
                    }

                    this._breadcrumb.apply(response.breadcrumb);
                    this._colorTheme.apply(response.dyncss);

                    this._blockManager.update();

                    if (typeof ga === 'function'){
                        ga('set', 'page', url);
                        ga('send', 'pageview');
                    }

                    //console.log("loaded:", url, response);
                })
                .fail((xhr) => {
                    alert('Error: ' + xhr.responseText);
                    this.stopAnim();
                })
                .always( () => {
                    // re-render klaro on async content load
                    klaro.render(klaroConfig);
                });
        });
    };
}