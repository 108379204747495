/**
 * DepthPass.ts: render depth pass
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { MeshDepthMaterial } from "../../lib/threejs/materials/MeshDepthMaterial";
import { RGBADepthPacking, NoBlending, LinearFilter } from "../../lib/threejs/constants";
import { WebGLRenderTarget } from "../../lib/threejs/renderers/WebGLRenderTarget";

// make this more Render integrated

export class DepthPass {

    // if set to true, the pass clears its buffer before rendering
    public clear = false;

    // if set to true, the result of the pass is rendered to screen
    public renderToScreen = false;

    public depthMaterial:any;
    public depthRenderTarget:any;

    public camera:any;
    public scene:any;

    constructor() {
        this.depthRenderTarget = null;

        this.camera = null;
        this.scene = null;
        // Setup depth pass
        this.depthMaterial = new MeshDepthMaterial();
        this.depthMaterial.depthPacking = RGBADepthPacking;
        this.depthMaterial.blending = NoBlending;
    }

    public render(renderer:any) {

        if(!this.scene || !this.camera) {
            return;
        }

        const tmpMaterial = this.scene.overrideMaterial;
        this.scene.overrideMaterial = this.depthMaterial;

        if(this.renderToScreen) {
            renderer.render(this.scene, this.camera);
        } else {
            this._lazyTargetInit();
            renderer.render(this.scene, this.camera, this.depthRenderTarget, this.clear);
        }

        this.scene.overrideMaterial = tmpMaterial;
    }

    private _lazyTargetInit() {
        const pars = { minFilter: LinearFilter, magFilter: LinearFilter };
        if(!this.depthRenderTarget) {
            //TODO: get size?!
            this.depthRenderTarget = new WebGLRenderTarget( window.innerWidth, window.innerHeight, pars );
            this.depthRenderTarget.texture.name = "DepthPass.rt";
        }
    }
}
