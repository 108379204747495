/**
 * CollisionSystem.ts: collision handling
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Object3D } from "../../lib/threejs/core/Object3D";
import { Box3 } from "../../lib/threejs/math/Box3";
import { Raycaster } from "../../lib/threejs/core/Raycaster";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { BoxGeometry } from "../../lib/threejs/geometries/BoxGeometry";
import { MeshBasicMaterial } from "../../lib/threejs/materials/MeshBasicMaterial";
import {ComponentId, componentIdGetIndex, createComponentId} from "../framework/Component";
import { Entity } from "../framework/Entity";
import { Mesh } from "../render/Mesh";
import { StaticModel } from "../render/Model";
import { IWorld } from "../framework/WorldAPI";
import { registerAPI } from "../plugin/Plugin";
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { ERayCastQuery, CollisionResult, ICollisionSystem, COLLISIONSYSTEM_API, CollisionLayer, CollisionLayerDefaults, ECollisionBehaviour } from "../framework/CollisionAPI";
import { registerWorldSystemModule } from "../framework/System";
import { Line } from "../render-line/Line";
import { math } from "../core/Math";
import { appGet } from "../framework/App";

/** internal representation of one collision object */
interface CollisionObject {
    id: ComponentId;
    /** scene tree reference */
    node: Object3D;
    /** object reference */
    objectRef: Mesh | StaticModel | Line;

    /** TODO: add support for various boundings */
    worldBounds: Box3;
    //worldSphere: THREE.Sphere;

    active: boolean;
    /** collision presentation */
    collision: ECollisionBehaviour;
    /** Collision bitmask layer */
    layer?: CollisionLayer;
    collidesWith?: CollisionLayer;
}

/**
 * global collision system handling
 */

function setDebugHelper(value:boolean) {
    if(_debugHelper !== value) {
        _debugHelper = value;
        _buildDebug(!_debugHelper);
    }
}

/** current list of collision objects */
let _collisionObjects:CollisionObject[] = [];
let _version:number = 1;

/** debugging helper */
let _debugSceneEntity:Entity = null;
let _debugHelper:boolean = false;

/** destruction */
function destroy() {

    // cleanup helper
    if(_debugSceneEntity) {

        _debugSceneEntity.destroy();
        _debugSceneEntity = null;
    }

    _debugHelper = false;

    // clear all callbacks
    _collisionObjects = [];
    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function init(world:IWorld) {}

function registerCollisionModel(model:StaticModel, node:any, collisionBehaviour: ECollisionBehaviour, collisionLayer: CollisionLayer = CollisionLayerDefaults.Default) : ComponentId {
    if (!collisionBehaviour) {
        console.warn("CollisionSystem: missing collision behaviour, defaulting to Bounds");
        collisionBehaviour = ECollisionBehaviour.Bounds;
    }

    const id = _registerCollisionObjectGeneric();
    const index = componentIdGetIndex(id);

    _collisionObjects[index].objectRef = model;
    _collisionObjects[index].node = node;
    _collisionObjects[index].active = true;
    _collisionObjects[index].collision = collisionBehaviour;
    _collisionObjects[index].layer = collisionLayer;

    _updateBounds_Model(index);

    // rebuilt debug infos
    _buildDebug(false);

    return id;
}

function registerCollisionMesh(mesh:Mesh, node:any, collisionBehaviour: ECollisionBehaviour, collisionLayer: CollisionLayer = CollisionLayerDefaults.Default) : ComponentId {
    if (!collisionBehaviour) {
        console.warn("CollisionSystem: missing collision behaviour, defaulting to Bounds");
        collisionBehaviour = ECollisionBehaviour.Bounds;
    }

    const id = _registerCollisionObjectGeneric();
    const index = componentIdGetIndex(id);

    _collisionObjects[index].objectRef = mesh;
    _collisionObjects[index].node = node;
    _collisionObjects[index].active = true;
    _collisionObjects[index].collision = collisionBehaviour;
    _collisionObjects[index].layer = collisionLayer;

    _updateBounds_Mesh(index);

    // rebuilt debug infos
    _buildDebug(false);

    return id;
}

function registerCollisionLine(mesh:any, node:any, collisionBehaviour: ECollisionBehaviour, collisionLayer: CollisionLayer = CollisionLayerDefaults.Default) : ComponentId {
    if (!collisionBehaviour) {
        console.warn("CollisionSystem: missing collision behaviour, defaulting to Bounds");
        collisionBehaviour = ECollisionBehaviour.Bounds;
    }

    const id = _registerCollisionObjectGeneric();
    const index = componentIdGetIndex(id);

    _collisionObjects[index].objectRef = mesh;
    _collisionObjects[index].node = node;
    _collisionObjects[index].active = true;
    _collisionObjects[index].collision = collisionBehaviour;
    _collisionObjects[index].layer = collisionLayer;

    _updateBounds_Line(index);

    // rebuilt debug infos
    _buildDebug(false);

    return id;
}

function removeCollisionObject(id:ComponentId) {
    if(!_validateId(id)) {
        return;
    }

    // clear debug infos
    _buildDebug(true);

    const index = componentIdGetIndex(id);

    // cleanup
    _collisionObjects[index].id = 0;
    _collisionObjects[index].objectRef = null;
    _collisionObjects[index].node = null;
    _collisionObjects[index].collision = ECollisionBehaviour.None;

    // increase version
    _version = (_version + 1) & 0x000000FF;

    // clear debug infos
    _buildDebug(false);
}

function updateCollisionObjectLayer(id: ComponentId, layer?: CollisionLayer, collidesWith?: CollisionLayer) {
    if(!_validateId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    _collisionObjects[index].layer = layer !== undefined ? layer : CollisionLayerDefaults.Default;
    _collisionObjects[index].collidesWith = collidesWith !== undefined ? collidesWith : CollisionLayerDefaults.None;
}

function updateTransform(id:ComponentId) {
    if(!_validateId(id)) {
        return;
    }
    const index = componentIdGetIndex(id);

    if(StaticModel.isStaticModel(_collisionObjects[index].objectRef)) {
        _updateBounds_Model(index);
    } else if(Mesh.isMesh(_collisionObjects[index].objectRef)) {
        _updateBounds_Mesh(index);
    } else {
        //TODO: supply new transformation data
    }

    // rebuilt debug output
    _buildDebug(false);
}

/**
 * ray cast against world objects
 * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
 * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
 * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
 * @param normalizedScreenX screen position in ndc
 * @param normalizedScreenY screen position in ndx
 * @param camera camera reference
 * @param result hit results
 * @param query query options
 * @return true for any hit
 */
function rayCast(normalizedScreenX:number, normalizedScreenY:number, camera:any, query:ERayCastQuery, result:CollisionResult[], layerToRaycast?: CollisionLayer) : boolean {
    const raycaster:any = new Raycaster();
    raycaster.setFromCamera( {x:normalizedScreenX, y:normalizedScreenY}, camera);

    return rayCastWorld(raycaster.ray.origin, raycaster.ray.direction, query, result, layerToRaycast);
}

/**
 * ray cast against world objects
 * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
 * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
 * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
 *
 * @param origin origin vec3
 * @param direction direction vec3
 * @param result hit results
 * @param query query options
 * @return true for any hit
 */
function rayCastWorld(origin:Vector3, direction:Vector3, query:ERayCastQuery, result:CollisionResult[], layerToRaycast?: CollisionLayer) : boolean {
    let anyHit:boolean = false;
    let objectHit:boolean = false;
    const raycaster:any = new Raycaster(origin, direction, 0, 10000);

    // make sure result is set
    result = result || [];

    // debugging
    _resetHitInfo();

    for(let i = 0; i < _collisionObjects.length; ++i) {
        if(!_collisionObjects[i].id || !_collisionObjects[i].active) {
            continue;
        }
        const object = _collisionObjects[i];

        // ignore collisionObjects not in raycast layer
        if (layerToRaycast) {
            if ((object.layer & layerToRaycast) !== object.layer) {
                continue;
            }
        }

        objectHit = false;

        // custom object query parameters
        let objectQuery = query;
        if(object.collision === ECollisionBehaviour.Bounds) {
            objectQuery |= ERayCastQuery.OnlyBounds;
        }

        if(StaticModel.isStaticModel(object.objectRef)) {
            objectHit = _rayCast_Model(raycaster, object, result, objectQuery);
        } else if(Mesh.isMesh(object.objectRef)) {
            objectHit = _rayCast_Mesh(raycaster, object, result, objectQuery);
        } else {
            objectHit = _rayCast_Object(raycaster, object, result, objectQuery);
        }

        if(objectHit) {
            _updateHitInfo(i);
        }

        anyHit = objectHit || anyHit;

        // got first hit, just return
        if(anyHit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
            return anyHit;
        }
    }

    // sort to first hit
    if((query & ERayCastQuery.FirstHit) === ERayCastQuery.FirstHit) {
        result.sort(ascSort);
    }

    // filter double objects
    if((query & ERayCastQuery.OneHitPerObject) === ERayCastQuery.OneHitPerObject) {
        result = filterOneObject(result);
    }

    return anyHit;
}

function checkBounds(bounds:Box3, query:ERayCastQuery, result:CollisionResult[], layer?: CollisionLayer) : boolean {
    let anyHit:boolean = false;
    let objectHit:boolean = false;
    //const raycaster:any = new Raycaster(origin, direction, 0, 10000);

    // make sure result is set
    result = result || [];

    // debugging
    _resetHitInfo();

    for(let i = 0; i < _collisionObjects.length; ++i) {
        if(!_collisionObjects[i].id || !_collisionObjects[i].active) {
            continue;
        }
        const object = _collisionObjects[i];

        // ignore collisionObjects not in raycast layer
        if (layer) {
            if ((object.layer & layer) !== object.layer) {
                continue;
            }
        }

        objectHit = false;

        // custom object query parameters
        let objectQuery = query;
        if(object.collision === ECollisionBehaviour.Bounds) {
            objectQuery |= ERayCastQuery.OnlyBounds;
        }

        if(StaticModel.isStaticModel(object.objectRef)) {
            objectHit = _boundsCheck_Model(bounds, object, result, objectQuery);
        } else if(Mesh.isMesh(object.objectRef)) {
            objectHit = _boundsCheck_Mesh(bounds, object, result, objectQuery);
        } else {
            objectHit = _boundsCheck_Object(bounds, object, result, objectQuery);
        }

        if(objectHit) {
            _updateHitInfo(i);
        }

        anyHit = objectHit || anyHit;

        // got first hit, just return
        if(anyHit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
            return anyHit;
        }
    }

    // sort to first hit
    if((query & ERayCastQuery.FirstHit) === ERayCastQuery.FirstHit) {
        result.sort(ascSort);
    }

    // filter double objects
    if((query & ERayCastQuery.OneHitPerObject) === ERayCastQuery.OneHitPerObject) {
        result = filterOneObject(result);
    }

    return anyHit;
}

/**
 * generic ray cast test (only using worldBounds)
 * @param raycaster three.js raycaster
 * @param object collision object
 * @param result resulting array
 * @param query query options
 */
function _rayCast_Object(raycaster:any, object:CollisionObject, result:CollisionResult[], query:ERayCastQuery) : boolean {

    // generic world bounds
    const hitPoint = new Vector3();
    const ray = raycaster.ray;
    if(ray.intersectBox(object.worldBounds, hitPoint) !== null) {
        const distance = hitPoint.sub(ray.origin).length();

        //TODO: add query flags here... like visible

        // add to results
        result.push({
            id: object.id,
            entity: object.node as Entity,
            // fill in data
            distance: distance,
            point: hitPoint,
            // mesh setup
            intersect: {
                face: null,
                faceIndex: null,
                indices: null,
                object: object.node,
                mesh: object.objectRef,
                model: object.objectRef,
                meshName: null,
                // material hit
                material: null,
                materialGroup: null,
                materialName: ""
            }
        });

        return true;
    }

    return false;
}

function _boundsCheck_Object(bounds:Box3, object:CollisionObject, result:CollisionResult[], query:ERayCastQuery) : boolean {

    // generic world bounds
    if(bounds.intersectsBox(object.worldBounds) || bounds.containsBox(object.worldBounds)) {
        //TODO: add query flags here... like visible
        const distance = bounds.getCenter(math.tmpVec3()).distanceTo(object.worldBounds.getCenter(math.tmpVec3()));
        // add to results
        result.push({
            id: object.id,
            entity: object.node as Entity,
            // fill in data
            distance,
            point: null,
            // mesh setup
            intersect: {
                face: null,
                faceIndex: null,
                indices: null,
                object: object.node,
                mesh: object.objectRef,
                model: object.objectRef,
                meshName: null,
                // material hit
                material: null,
                materialGroup: null,
                materialName: ""
            }
        });

        return true;
    }

    return false;
}

/**
 * ray cast against single mesh
 * @param raycaster
 * @param object
 * @param result
 * @param query
 */
function _rayCast_Mesh(raycaster:any, object:CollisionObject, result:CollisionResult[], query:ERayCastQuery):boolean {

    const mesh = object.objectRef as Mesh;

    const hitPoint = new Vector3();
    const ray = raycaster.ray;

    // only visible meshes
    if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        if(mesh.visible === false) {
            return false;
        }
    }

    if(ray.intersectBox(object.worldBounds, hitPoint) !== null) {

        // test submeshes for accurate hit testing
        if((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {

            // directly return
            const distance = hitPoint.sub(ray.origin).length();

            // add to results
            result.push({
                id: object.id,
                entity: object.node as Entity,
                // fill in data
                distance: distance,
                point: hitPoint,
                intersect: {
                    // mesh setup
                    face: null,
                    faceIndex: null,
                    indices: null,
                    object: mesh,
                    mesh: mesh,
                    model: mesh,
                    meshName: mesh.name,
                    // material hit
                    material: null,
                    materialGroup: null,
                    materialName: mesh.materialName
                }
            });

            return true;
        } else {
            const inverseMatrix = new Matrix4();
            const tempRay = raycaster.ray.clone();
            // transform ray into local space as object.objectRef should be in local world space

            // create local ray
            inverseMatrix.getInverse(object.node.matrixWorld);
            raycaster.ray.applyMatrix4(inverseMatrix);

            // find intersection with node and triangles
            //const intersects = raycaster.intersectObject(object.objectRef, false);
            const intersects = mesh.rayCastLocal(raycaster);

            // restore raycaster
            raycaster.ray.copy(tempRay);

            if(intersects && intersects.length > 0) {
                const hitResults:CollisionResult[] = [];

                for(let i = intersects.length-1; i >= 0; --i) {

                    // only visible meshes
                    if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                        if(intersects[i].object.visible === false) {
                            continue;
                        }
                    }

                    const hitResult:CollisionResult = {
                        id: object.id,
                        entity: object.node as Entity,
                        distance: intersects[i].distance,
                        point: intersects[i].point,
                        intersect: {
                            face: intersects[i].face,
                            faceIndex: intersects[i].faceIndex || intersects[i].index,
                            indices: intersects[i].indices,
                            object: intersects[i].object,
                            mesh: object.objectRef,
                            model: null,
                            meshName: intersects[i].object.name,
                            // material hit
                            material: null,
                            materialName: ""
                        }
                    };

                    // find material data
                    if(intersects[i].object === mesh) {
                        hitResult.intersect.material = mesh.redMaterial;
                        hitResult.intersect.materialName = mesh.materialName;
                    }

                    // fill in data
                    hitResult.intersect.meshName = intersects[i].object.name;

                    hitResults.push(hitResult);
                }

                if(hitResults.length > 0) {
                    result.push.apply(result, hitResults);
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 * ray cast against single mesh
 * @param raycaster
 * @param object
 * @param result
 * @param query
 */
function _boundsCheck_Mesh(bounds:Box3, object:CollisionObject, result:CollisionResult[], query:ERayCastQuery):boolean {

    const mesh = object.objectRef as Mesh;

    // only visible meshes
    if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        if(mesh.visible === false) {
            return false;
        }
    }

    if(bounds.intersectsBox(object.worldBounds) || bounds.containsBox(object.worldBounds)) {
        // test submeshes for accurate hit testing
        if((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {

            const distance = bounds.getCenter(math.tmpVec3()).distanceTo(object.worldBounds.getCenter(math.tmpVec3()));

            // add to results
            result.push({
                id: object.id,
                entity: object.node as Entity,
                // fill in data
                distance: distance,
                point: null,
                intersect: {
                    // mesh setup
                    face: null,
                    faceIndex: null,
                    indices: null,
                    object: mesh,
                    mesh: mesh,
                    model: mesh,
                    meshName: mesh.name,
                    // material hit
                    material: null,
                    materialGroup: null,
                    materialName: mesh.materialName
                }
            });

            return true;
        } else {
            const inverseMatrix = new Matrix4();
            const tempBounds = bounds.clone();
            // transform ray into local space as object.objectRef should be in local world space

            // create local ray
            inverseMatrix.getInverse(object.node.matrixWorld);
            bounds.applyMatrix4(inverseMatrix);

            // find intersection with node and triangles
            const intersects = mesh.boundsCheckLocal(bounds);

            // restore raycaster
            bounds.copy(tempBounds);

            if(intersects && intersects.length > 0) {
                const hitResults:CollisionResult[] = [];

                for(let i = intersects.length-1; i >= 0; --i) {

                    // only visible meshes
                    if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                        if(intersects[i].object.visible === false) {
                            continue;
                        }
                    }

                    const hitResult:CollisionResult = {
                        id: object.id,
                        entity: object.node as Entity,
                        distance: intersects[i].distance,
                        point: intersects[i].point,
                        intersect: {
                            face: intersects[i].face,
                            faceIndex: intersects[i].faceIndex || intersects[i].index,
                            indices: intersects[i].indices,
                            object: intersects[i].object,
                            mesh: object.objectRef,
                            model: null,
                            meshName: intersects[i].object.name,
                            // material hit
                            material: null,
                            materialName: ""
                        }
                    };

                    // find material data
                    if(intersects[i].object === mesh) {
                        hitResult.intersect.material = mesh.redMaterial;
                        hitResult.intersect.materialName = mesh.materialName;
                    }

                    // fill in data
                    hitResult.intersect.meshName = intersects[i].object.name;

                    hitResults.push(hitResult);
                }

                if(hitResults.length > 0) {
                    result.push.apply(result, hitResults);
                    return true;
                }
            }
        }
    }
    return false;
}

/**
 *
 * @param index
 */
function _updateBounds_Mesh(index:number) {
    const mesh = _collisionObjects[index].objectRef as Mesh;
    const entity = _collisionObjects[index].node;
    // this can be instantiated so use local bounds and transform to world
    // the meshes should transform their local bounds with their transformation applied
    const boundingBox = mesh.localBounds;
    const newBounding = new Box3();
    const tempVector = new Vector3();

    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z)));

    _collisionObjects[index].worldBounds.copy(newBounding);
    //_collisionObjects[index].worldBounds.copy(mesh.worldBounds());
}

/**
 * ray cast against single mesh
 * @param raycaster
 * @param object
 * @param result
 * @param query
 */
function _rayCast_Line(raycaster:any, object:CollisionObject, result:CollisionResult[], query:ERayCastQuery):boolean {

    const mesh = object.objectRef as Line;

    const hitPoint = new Vector3();
    const ray = raycaster.ray;

    // only visible meshes
    if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
        if(mesh.visible === false) {
            return false;
        }
    }

    if(ray.intersectBox(object.worldBounds, hitPoint) !== null) {

        // test submeshes for accurate hit testing
        if((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {

            // directly return
            const distance = hitPoint.sub(ray.origin).length();

            // add to results
            result.push({
                id: object.id,
                entity: object.node as Entity,
                // fill in data
                distance: distance,
                point: hitPoint,
                intersect: {
                    // mesh setup
                    face: null,
                    faceIndex: null,
                    indices: null,
                    object: mesh,
                    mesh: mesh,
                    model: mesh,
                    meshName: mesh.name,
                    // material hit
                    material: null,
                    materialGroup: null,
                    materialName: mesh.materialName
                }
            });

            return true;
        } else {
            const inverseMatrix = new Matrix4();
            const tempRay = raycaster.ray.clone();
            // transform ray into local space as object.objectRef should be in local world space

            // create local ray
            inverseMatrix.getInverse(object.node.matrixWorld);
            raycaster.ray.applyMatrix4(inverseMatrix);

            // find intersection with node and triangles
            //const intersects = raycaster.intersectObject(object.objectRef, false);
            const intersects = mesh.rayCastLocal(raycaster);

            // restore raycaster
            raycaster.ray.copy(tempRay);

            if(intersects && intersects.length > 0) {
                const hitResults:CollisionResult[] = [];

                for(let i = intersects.length-1; i >= 0; --i) {

                    // only visible meshes
                    if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                        if(intersects[i].object.visible === false) {
                            continue;
                        }
                    }

                    const hitResult:CollisionResult = {
                        id: object.id,
                        entity: object.node as Entity,
                        distance: intersects[i].distance,
                        point: intersects[i].point,
                        intersect: {
                            face: intersects[i].face,
                            faceIndex: intersects[i].faceIndex || intersects[i].index,
                            indices: intersects[i].indices,
                            object: intersects[i].object,
                            mesh: object.objectRef,
                            model: null,
                            meshName: intersects[i].object.name,
                            // material hit
                            material: null,
                            materialName: ""
                        }
                    };

                    // find material data
                    if(intersects[i].object === mesh) {
                        hitResult.intersect.material = mesh.redMaterial;
                        hitResult.intersect.materialName = mesh.materialName;
                    }

                    // fill in data
                    hitResult.intersect.meshName = intersects[i].object.name;

                    hitResults.push(hitResult);
                }

                if(hitResults.length > 0) {
                    result.push.apply(result, hitResults);
                    return true;
                }
            }
        }
    }
    return false;
}

function _updateBounds_Line(index:number) {
    const mesh = _collisionObjects[index].objectRef as Line;
    const entity = _collisionObjects[index].node;
    // this can be instantiated so use local bounds and transform to world
    // the meshes should transform their local bounds with their transformation applied
    const boundingBox = mesh.localBounds;
    const newBounding = new Box3();
    const tempVector = new Vector3();

    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z)));

    _collisionObjects[index].worldBounds.copy(newBounding);
    //_collisionObjects[index].worldBounds.copy(mesh.worldBounds());
}

/**
 * raycast against model data
 * TODO: add ERayCastQuery for AnyHit (break on recursive intersection test)
 */
function _rayCast_Model(raycaster:any, object:CollisionObject, result:CollisionResult[], query:ERayCastQuery):boolean {

    if(!result) {
        console.warn("MODEL: raycast result buffer empty");
        return false;
    }

    // temporary values
    const hitPoint = new Vector3();
    const model = object.objectRef as StaticModel;
    const ray = raycaster.ray;

    function recursiveRaycast(node, local:Matrix4, raycasterI:any, intersects:any) {

        if(node.geometry && node.material) {

            if((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {

                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);

                const tmpRay = raycasterI.ray.clone();
                tmpRay.applyMatrix4(inverseMatrix);

                // test intersection with local ray
                if(tmpRay.intersectBox(node.geometry.boundingBox, hitPoint) !== null) {

                    // transform to world
                    hitPoint.applyMatrix4(local);
                    const distance = tmpRay.origin.distanceTo(hitPoint);

                    intersects.push({
                        point: hitPoint,
                        distance,
                        object: node
                    });

                    return true;
                }

            } else {

                // save tmp
                const tmpIntersectionCount = intersects.length;
                const tmpRay = raycasterI.ray.clone();

                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);
                raycasterI.ray.applyMatrix4(inverseMatrix);

                // reset to identity matrix (so this is in local space)
                const tmpWorldMatrix = node.matrixWorld.clone();
                // local transformations are world matrix
                node.matrixWorld.copy(node.matrix);

                //FIX: in view space some distances can get far
                raycasterI.far = Infinity;
                raycasterI.intersectObject(node, false, intersects);

                // restore raycaster
                raycasterI.ray.copy(tmpRay);
                // restore matrix
                node.matrixWorld.copy(tmpWorldMatrix);

                // process intersections
                for(let i = tmpIntersectionCount; i < intersects.length; ++i) {
                    // transform to world
                    intersects[i].point.applyMatrix4(local);
                    intersects[i].distance = tmpRay.origin.distanceTo( intersects[i].point );
                }

                // got some hits
                if(tmpIntersectionCount < intersects.length) {
                    return true;
                }
            }

            return false;

        } else {
            let hit = false;
            // apply local matrix
            local = local.clone().multiply(node.matrix);
            // process all childs
            for(const child of node.children) {
                hit = recursiveRaycast(child, local, raycasterI, intersects) || hit;

                if(hit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
                    return true;
                }
            }
            return hit;
        }
    }

    if(ray.intersectBox(object.worldBounds, hitPoint) !== null) {
        // only visible meshes
        if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
            if(object.node.visible === false) {
                return false;
            }
        }

        // create local ray from object node
        const inverseMatrix = new Matrix4();
        inverseMatrix.copy(object.node.matrixWorld);

        const intersects = [];
        // find intersection with submeshes
        recursiveRaycast(model.getHierarchy(), inverseMatrix, raycaster, intersects);

        if(intersects && intersects.length > 0) {
            const hitResults:CollisionResult[] = [];

            for(let i = intersects.length-1; i >= 0; --i) {

                // only visible meshes
                if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                    if(intersects[i].object.visible === false) {
                        continue;
                    }
                }

                const hitResult:CollisionResult = {
                    id: object.id,
                    entity: object.node as Entity,
                    distance: intersects[i].distance,
                    point: intersects[i].point,
                    intersect: {
                        face: intersects[i].face,
                        faceIndex: intersects[i].faceIndex || intersects[i].index,
                        indices: intersects[i].indices,
                        object: intersects[i].object,
                        mesh: intersects[i].object,
                        model: model,
                        meshName: intersects[i].object.name,
                        // material hit
                        material: null,
                        materialGroup: null,
                        materialName: ""
                    }
                };

                // find material data
                if(Mesh.isMesh(intersects[i].object)) {
                    hitResult.intersect.material = intersects[i].object.redMaterial;
                    hitResult.intersect.materialGroup = "DEPRECATED";
                    hitResult.intersect.materialName = intersects[i].object.materialRef.ref;
                } else {
                    for(let j = 0; j < model.meshes.length; ++j) {
                        if(intersects[i].object === model.meshes[j]) {
                            hitResult.intersect.material = model.meshes[j].redMaterial;
                            hitResult.intersect.materialGroup = "DEPRECATED";
                            hitResult.intersect.materialName = model.meshes[j].materialRef.ref;
                            break;
                        }
                    }
                }

                // fill in data
                hitResult.intersect.meshName = intersects[i].object.name;

                hitResults.push(hitResult);
            }

            if(hitResults.length > 0) {
                result.push.apply(result, hitResults);
                return true;
            }
        }

    }
    return false;
}

function _boundsCheck_Model(bounds:Box3, object:CollisionObject, result:CollisionResult[], query:ERayCastQuery):boolean {

    if(!result) {
        console.warn("MODEL: raycast result buffer empty");
        return false;
    }

    // temporary values
    const model = object.objectRef as StaticModel;

    function recursiveBoundsCheck(node, local:Matrix4, worldBounds:Box3, intersects:any) {

        if(node.geometry && node.material) {

            if((query & ERayCastQuery.OnlyBounds) === ERayCastQuery.OnlyBounds) {

                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);

                const tmpBounds = worldBounds.clone();
                tmpBounds.applyMatrix4(inverseMatrix);

                // test intersection with local ray
                if(tmpBounds.intersectsBox(node.geometry.boundingBox) || tmpBounds.containsBox(node.geometry.boundingBox)) {
                    const distance = tmpBounds.getCenter(math.tmpVec3()).distanceTo(node.geometry.boundingBox.getCenter(math.tmpVec3()));
                    intersects.push({
                        point: null,
                        distance,
                        object: node
                    });
                    return true;
                }

            } else {

                // save tmp
                const tmpIntersectionCount = intersects.length;
                const tmpBounds = worldBounds.clone();

                // create local ray
                const inverseMatrix = new Matrix4();
                inverseMatrix.getInverse(local);
                tmpBounds.applyMatrix4(inverseMatrix);

                // reset to identity matrix (so this is in local space)
                const tmpWorldMatrix = node.matrixWorld.clone();
                // local transformations are world matrix
                node.matrixWorld.copy(node.matrix);

                //FIX: in view space some distances can get far
                //raycasterI.far = Infinity;
                //TODO: check bounds
                if(Mesh.isMesh(node)) {
                    const meshIntersects = node.boundsCheckLocal(tmpBounds);
                    // need to push it into so reference is the same
                    for(const i of meshIntersects) {
                        intersects.push(i);
                    }
                } else {
                    console.error("Not yet implemented");
                }

                // restore matrix
                node.matrixWorld.copy(tmpWorldMatrix);

                // process intersections
                for(let i = tmpIntersectionCount; i < intersects.length; ++i) {
                    // transform to world
                    //intersects[i].point.applyMatrix4(local);
                    intersects[i].distance = 0.0; //tmpBounds.distanceTo( intersects[i].point );
                }

                // got some hits
                if(tmpIntersectionCount < intersects.length) {
                    return true;
                }
            }

            return false;

        } else {
            let hit = false;
            // apply local matrix
            local = local.clone().multiply(node.matrix);
            // process all childs
            for(const child of node.children) {
                hit = recursiveBoundsCheck(child, local, worldBounds, intersects) || hit;

                if(hit && (query & ERayCastQuery.AnyHit) === ERayCastQuery.AnyHit) {
                    return true;
                }
            }
            return hit;
        }
    }

    if(bounds.intersectsBox(object.worldBounds) || bounds.containsBox(object.worldBounds)) {
        // only visible meshes
        if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
            if(object.node.visible === false) {
                return false;
            }
        }

        // create local ray from object node
        const inverseMatrix = new Matrix4();
        inverseMatrix.copy(object.node.matrixWorld);

        const intersects = [];
        // find intersection with submeshes
        recursiveBoundsCheck(model.getHierarchy(), inverseMatrix, bounds, intersects);

        if(intersects && intersects.length > 0) {
            const hitResults:CollisionResult[] = [];

            for(let i = intersects.length-1; i >= 0; --i) {

                // only visible meshes
                if((query & ERayCastQuery.OnlyVisible) === ERayCastQuery.OnlyVisible) {
                    if(intersects[i].object.visible === false) {
                        continue;
                    }
                }

                const hitResult:CollisionResult = {
                    id: object.id,
                    entity: object.node as Entity,
                    distance: intersects[i].distance,
                    point: intersects[i].point,
                    intersect: {
                        face: intersects[i].face,
                        faceIndex: intersects[i].faceIndex || intersects[i].index,
                        indices: intersects[i].indices,
                        object: intersects[i].object,
                        mesh: intersects[i].object,
                        model: model,
                        meshName: intersects[i].object.name,
                        // material hit
                        material: null,
                        materialGroup: null,
                        materialName: ""
                    }
                };

                // find material data
                if(Mesh.isMesh(intersects[i].object)) {
                    intersects[i].material = intersects[i].object.redMaterial;
                    intersects[i].materialGroup = "DEPRECATED";
                    intersects[i].materialName = intersects[i].object.materialRef.ref;
                } else {
                    for(let j = 0; j < model.meshes.length; ++j) {
                        if(intersects[i].object === model.meshes[j]) {
                            intersects[i].material = model.meshes[j].redMaterial;
                            intersects[i].materialGroup = "DEPRECATED";
                            intersects[i].materialName = model.meshes[j].materialRef.ref;
                            break;
                        }
                    }
                }

                // fill in data
                hitResult.intersect.meshName = intersects[i].object.name;

                hitResults.push(hitResult);
            }

            if(hitResults.length > 0) {
                result.push.apply(result, hitResults);
                return true;
            }
        }

    }
    return false;
}

/**
 * recalculate bounding box of model
 * @param index index into collision object
 */
function _updateBounds_Model(index:number) {
    const model = _collisionObjects[index].objectRef as StaticModel;
    const entity = _collisionObjects[index].node;
    // this can be instantiated so use local bounds and transform to world
    // the meshes should transform their local bounds with their transformation applied
    let boundingBox = null; // model.localBounds;
    const newBounding = new Box3();
    const tempVector = new Vector3();

    const localBoundingBox = new Box3();

    function recursive(node, local:Matrix4) {
        if(node.geometry && node.material) {
            // mesh bounding box
            boundingBox = node.geometry.boundingBox;

            localBoundingBox.expandByPoint(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z).applyMatrix4(local));
            localBoundingBox.expandByPoint(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z).applyMatrix4(local));
            localBoundingBox.expandByPoint(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z).applyMatrix4(local));
            localBoundingBox.expandByPoint(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z).applyMatrix4(local));
            localBoundingBox.expandByPoint(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z).applyMatrix4(local));
            localBoundingBox.expandByPoint(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z).applyMatrix4(local));
            localBoundingBox.expandByPoint(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z).applyMatrix4(local));
            localBoundingBox.expandByPoint(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z).applyMatrix4(local));

        } else {
            // apply local matrix
            local = local.clone().multiply(node.matrix);
            for(const child of node.children) {
                recursive(child, local);
            }
        }
    }

    const root = model.getHierarchy();
    recursive(root, new Matrix4().identity());

    boundingBox = localBoundingBox;

    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z)));
    newBounding.expandByPoint(entity.localToWorld(tempVector.set(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z)));

    _collisionObjects[index].worldBounds.copy(newBounding);
    //_collisionObjects[index].worldBounds.copy(model.worldBounds);
}

/** create new collision object entry */
function _registerCollisionObjectGeneric(worldBounds?:Box3) : ComponentId {
    let index = -1;

    for(let i = 0; i < _collisionObjects.length; ++i) {
        if(!_collisionObjects[i].id) {
            index = i;
            break;
        }
    }

    // new entry
    if(index === -1) {
        index = _collisionObjects.length;
        _collisionObjects[index] = {
            id: 0,
            objectRef: null,
            node: null,
            active: false,
            worldBounds: worldBounds || new Box3(),
            collision: ECollisionBehaviour.None,
            layer: CollisionLayerDefaults.None

        };
    }

    _collisionObjects[index].id = createComponentId(index, _version);
    if(worldBounds) {
        _collisionObjects[index].worldBounds.copy(worldBounds);
    }

    return _collisionObjects[index].id;
}

/** valid component id */
function _validateId(id:ComponentId) {
    const index = componentIdGetIndex(id);
    if(index >= 0 && index < _collisionObjects.length) {
        return _collisionObjects[index].id === id;
    }
    return false;
}

/** debug */
function _buildDebug(clear:boolean) {
    // initial setup
    if(!_debugSceneEntity) {
        // nothing to build
        if(!_debugHelper) {
            return;
        }

        const app = appGet();

        if (!app || !app.delegate || !app.delegate.world.isValid) {
            return;
        }

        _debugSceneEntity = app.delegate.world.instantiateEntity("collision_system_debug");
        _debugSceneEntity.transient = true;
        _debugSceneEntity.persistent = true;
    }

    for(let i = 0; i < _collisionObjects.length; ++i) {
        if(!_collisionObjects[i].id) {
            continue;
        }
        const object = _collisionObjects[i];

        // helper code
        const boundsHelper = _debugSceneEntity.getObjectByName("Bounds_" + object.id);
        if(boundsHelper) {
            if(clear || !_debugHelper) {
                _debugSceneEntity.remove(boundsHelper);
            } else {
                const c = math.tmpVec3();
                const s = math.tmpVec3();
                _collisionObjects[i].worldBounds.getCenter(c);
                _collisionObjects[i].worldBounds.getSize(s);
                // need some size
                s.x = Math.max(s.x, 0.01);
                s.y = Math.max(s.y, 0.01);
                s.z = Math.max(s.z, 0.01);

                boundsHelper.position.copy(c);
                boundsHelper.scale.copy(s);
            }
            continue;
        }

        // create new
        const center = math.tmpVec3();
        const size = math.tmpVec3();
        _collisionObjects[i].worldBounds.getCenter(center);
        _collisionObjects[i].worldBounds.getSize(size);
        // need some size
        size.x = Math.max(size.x, 0.01);
        size.y = Math.max(size.y, 0.01);
        size.z = Math.max(size.z, 0.01);

        const geometry = new BoxGeometry(1.0, 1.0, 1.0);
        const material = new MeshBasicMaterial({ color: 0x0000FF, wireframe:true });
        const debugBox = new THREEMesh(geometry, material);
        debugBox.name = "Bounds_" + object.id;
        debugBox.position.copy(center);
        debugBox.scale.copy(size);
        _debugSceneEntity.add(debugBox);

    }
}

function _resetHitInfo() {
    if(!_debugSceneEntity) {
        return;
    }

    for(let i = 0; i < _collisionObjects.length; ++i) {
        if(!_collisionObjects[i].id) {
            continue;
        }
        const object = _collisionObjects[i];
        const boundsHelper = _debugSceneEntity.getObjectByName("Bounds_" + object.id);
        if(boundsHelper) {
            const material = (boundsHelper as any).material;
            material.color.set(0x0000FF);
        }
    }
}

function _updateHitInfo(index:number) {
    if(!_debugSceneEntity) {
        return;
    }

    if(!_collisionObjects[index].id) {
        return;
    }

    const object = _collisionObjects[index];
    const boundsHelper = _debugSceneEntity.getObjectByName("Bounds_" + object.id);
    if(boundsHelper) {
        const material = (boundsHelper as any).material;
        material.color.set(0xFF0000);
    }

}

function ascSort(a:CollisionResult, b:CollisionResult) {
    return a.distance - b.distance;
}

function filterOneObject(input:CollisionResult[]) : CollisionResult[] {
    const set:ComponentId[] = [];

    return input.sort(ascSort).filter( (value) => {
        if(set.indexOf(value.id) !== -1) {
            return false;
        }
        set.push(value.id);
        return true;
    });
}

const collisionSystem:ICollisionSystem = {
    init,
    destroy,
    rayCast,
    rayCastWorld,
    checkBounds,
    registerCollisionMesh,
    registerCollisionModel,
    registerCollisionLine,
    removeCollisionObject,
    setDebugHelper,
    updateTransform,
    updateCollisionObjectLayer
};
// register at api
registerAPI<ICollisionSystem>(COLLISIONSYSTEM_API, collisionSystem);
// register at world
registerWorldSystemModule("RED", "CollisionSystem", COLLISIONSYSTEM_API, collisionSystem);
