/**
 * CameraComponent.ts: camera representation
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { LinearMipMapLinearFilter, LinearFilter, RGBAFormat, UnsignedShortType, UnsignedIntType, FloatType, RGBFormat, NearestFilter, UnsignedByteType } from "../../lib/threejs/constants";
import { WebGLRenderTarget } from "../../lib/threejs/renderers/WebGLRenderTarget";
import { DepthTexture } from "../../lib/threejs/textures/DepthTexture";
import { Color } from "../../lib/threejs/math/Color";
import { Vector2 } from "../../lib/threejs/math/Vector2";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import {GraphicsDisposeSetup} from '../core/Globals';
import {build} from '../core/Build';
import {Entity} from '../framework/Entity';
import {IONotifier} from '../io/Interfaces';
import {Component, registerComponent, ComponentData} from '../framework/Component';
import { allRenderLayerMask, ERenderLayer, defaultRenderLayerMask } from "../render/Layers";
import { Render } from "../render/Render";
import { BloomEffectParams, buildKernelConvolution } from "../render/Bloom";
import { exposure, PhysicalCamera, RedCamera, OrthoCamera, ECameraProjection} from "../render/Camera";
import { RenderSize, RenderConfiguration } from "../render/Config";
import { RenderState } from "../render/State";
import { ShaderLibrary } from "../render/ShaderLibrary";
import { EUniformType } from "../render/Uniforms";
import { OrbitControls } from "../framework-logic/OrbitController";
import { Platform } from "../core/Platform";
import { BlurRenderJob } from "../render/Filter";
import { ShaderVariant } from "../render/Shader";
import { DepthPass } from "../render/DepthPass";
import { ShaderPass } from "../render/ShaderPass";
import { RedMaterial } from "../render/Material";
import { math } from "../core/Math";

// BUILTIN SHADER (auto include)
import "../render/shader/FXAA";
import "../render/shader/SSAO";
import "../render/shader/Bloom";

interface SSRData {
    depthFramebuffer: WebGLRenderTarget;
    normalFramebuffer: WebGLRenderTarget;
    positionFramebuffer: WebGLRenderTarget;
    specularRoughnessFramebuffer: WebGLRenderTarget;
    indirectSpecularFramebuffer: WebGLRenderTarget;
    raytracingBuffer: WebGLRenderTarget;
    depthTexture: DepthTexture;
    depthLinearShader: any;
    viewNormalShader: any;
    pipelineState: RenderState;
    ssrRaytraceShader: any;
    ssrResolveShader: any;
}

export interface SSROptions {
    stride: number;
    thickness: number;
    jitter: number;
}

/**
 * CameraComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *     "module": "RED",
 *     "type": "CameraComponent",
 *     "parameters": {
 *
 *     }
 * }
 * ~~~~
 */
export class CameraComponent extends Component {

    public static get Main() {
        return CameraComponent._mainCamera;
    }
    private static _mainCamera:CameraComponent = null;

    /** orbit controller */
    public get orbit(): OrbitControls {
        return this._controls;
    }

    public get target() : Vector3 {
        return this._controls ? this._controls.target : null;
    }

    /** render callback overrides */
    public onPreRender:(render:Render) => void;
    public onRender:(render:Render) => void;
    public onPostRender:(render:Render) => void;

    public get sceneCamera(): RedCamera | OrthoCamera | PhysicalCamera {
        return this._sceneCamera;
    }

    /** global access to dom element */
    public get container() : Element {
        return this.world.getApp().container;
    }

    /** field of view setup */
    public get fov() : number {
        return this.perspectiveCamera.fov;
    }

    public set fov(value:number) {
        if(this.perspectiveCamera.fov !== value) {
            this.perspectiveCamera.fov = value;
            this._sceneCamera.updateProjectionMatrix();
        }
    }

    /** aspect ratio setup */
    public get aspect() : number {
        return this.perspectiveCamera.aspect;
    }

    public set aspect(value:number) {
        if(this.perspectiveCamera.aspect !== value) {
            this.perspectiveCamera.aspect = value;
            this._sceneCamera.updateProjectionMatrix();
        }
    }
    /** near plane setup */
    public get near() : number {
        return this._sceneCamera.near;
    }

    public set near(value:number) {
        if(this._sceneCamera.near !== value) {
            this._sceneCamera.near = value;
            this._sceneCamera.updateProjectionMatrix();
        }
    }

    /** far plane setup */
    public get far() : number {
        return this._sceneCamera.far;
    }

    public set far(value:number) {
        if(this._sceneCamera.far !== value) {
            this._sceneCamera.far = value;
            this._sceneCamera.updateProjectionMatrix();
        }
    }

    public get zoom() : number {
        return this.orthoCamera.zoom || 1.0;
    }
    public set zoom(value:number) {
        this.orthoCamera.zoom = value;
        this._sceneCamera.updateProjectionMatrix();
    }

    public get left() : number {
        return this.orthoCamera.left;
    }
    public set left(value:number) {
        this.orthoCamera.left = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    public get right() : number {
        return this.orthoCamera.right;
    }
    public set right(value:number) {
        this.orthoCamera.right = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    public get top() : number {
        return this.orthoCamera.top;
    }
    public set top(value:number) {
        this.orthoCamera.top = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    public get bottom() : number {
        return this.orthoCamera.bottom;
    }
    public set bottom(value:number) {
        this.orthoCamera.bottom = value;
        this.orthoCamera.updateProjectionMatrix();
    }

    /** exposure setup */
    public get exposure() : number {
        return exposure(this._aperture, this._shutterSpeed, this._sensitivity);
    }

    public get aperture() : number {
        return this._aperture;
    }

    public set aperture(value:number) {
        if(this._aperture !== value) {
            this._aperture = value;
            this._sceneCamera.exposure = this.exposure;
        }
    }

    public get shutterSpeed() : number {
        return this._shutterSpeed;
    }

    public set shutterSpeed(value:number) {
        if(this._shutterSpeed !== value) {
            this._shutterSpeed = value;
            this._sceneCamera.exposure = this.exposure;
        }
    }

    public get sensitivity() : number {
        return this._sensitivity;
    }

    public set sensitivity(value:number) {
        if(this._sensitivity !== value) {
            this._sensitivity = value;
            this._sceneCamera.exposure = this.exposure;
        }
    }

    /** whitepoint setup */
    public get whitepoint() : number {
        return this._sceneCamera.whitepoint || 1.0;
    }
    public set whitepoint(value:number) {
        if(this._sceneCamera.whitepoint !== value) {
            this._sceneCamera.whitepoint = value;
        }
    }

    /** ssr options setup */
    public get ssrOptions() : SSROptions {
        return this._ssrOtions;
    }

    /** main camera control */
    public set main(value:boolean) {
        if(value) {
            CameraComponent._mainCamera = this;
            this._sceneCamera['main'] = true;
        } else {
            this._sceneCamera['main'] = false;
            if(CameraComponent._mainCamera === this) {
                CameraComponent._mainCamera = null;
            }
        }
    }

    public get main() : boolean {
        return CameraComponent._mainCamera === this;
    }

    public get renderToTarget() : boolean {
        return this._pipelineState.renderTarget || this._pipelineState.postProcessTargets.length > 0;
    }

    /** helper setup */
    public set showHelper(value:boolean) {
        this._updateHelper(value);
    }

    public get showHelper() : boolean {
        return !!this._cameraHelper;
    }

    protected get perspectiveCamera(): PhysicalCamera {
        return this._sceneCamera as PhysicalCamera;
    }

    protected get orthoCamera(): OrthoCamera {
        return this._sceneCamera as OrthoCamera;
    }

    /** three js debug reference */
    private _cameraHelper:any = null;

    /** lazy init state */
    protected _lazyInit:boolean;

    /** camera instance */
    protected _sceneCamera: RedCamera;

    /** runtime pipeline */
    protected _pipelineState:RenderState;

    /** post processing effects */
    protected _bloomEnabled:boolean;
    protected _bloomParams:BloomEffectParams;
    protected _glassEnabled:boolean;
    protected _glassPipelineState:RenderState;
    protected _ssrEnabled:boolean;
    protected _ssrData: SSRData;
    protected _ssrOtions: SSROptions;

    protected _depthPass: DepthPass; //TODO: unify with shadow mapping depth pass
    protected _ssaoPass: ShaderPass;
    protected _fxaaPostProcess: ShaderPass;

    protected _bloomPass: ShaderPass;
    protected _convolutionPass: ShaderPass;
    protected _convolutionPassThreshold?: ShaderPass;
    // blit shader threshold
    protected _blitShaderThreshold: RedMaterial;
    // blending shader
    protected _blendShader: RedMaterial;

    /** physical camera */
    protected _aperture:number;
    protected _shutterSpeed:number;
    protected _sensitivity :number;

    /** rendering configuration */
    private _renderConfig:RenderConfiguration;

    /** camera controller */
    protected _controls:any = null;

    /** construct */
    constructor(entity:Entity) {
        super(entity);

        this._aperture = 16.0;
        this._shutterSpeed = 1.0 / 125.0;
        this._sensitivity = 100.0;

        this._lazyInit = false;
        this.onPreRender = null;
        this.onRender = null;
        this.onPostRender = null;
        this.needsRender = true;
        this._bloomEnabled = false;
        this._bloomParams = {
            sigma: 4.0,
            strength: 0.4,
            threshold: 0.98,
            size: undefined
        };
        this._glassEnabled = false;
        this._glassPipelineState = null;
        this._ssrEnabled = false;
        this._ssrData = null;
        this._ssrOtions = {
            stride: 4.0,
            jitter: 1.0,
            thickness: 1.0
        };

        this._renderConfig = RenderConfiguration.Default();

        //TODO: add target setup

        // setup pipeline (default)
        this._pipelineState = new RenderState();
        this._pipelineState.setupFromConfiguration(this._renderConfig);

        this._controls = null;

        // check if world or app exit (unit testing)
        if(this.world && this.world.getApp()) {
            this.world.getApp().OnWindowResize.on(this._onWindowResize);
        } else if(!build.Options.isUnittest) {
            console.error("Application not initialized correctly");
        }
    }

    public destroy(dispose?:GraphicsDisposeSetup) {
        this._destroyControls();

        if(this.world && this.world.getApp()) {
            this.world.getApp().OnWindowResize.off(this._onWindowResize);
        }

        // remove helper
        this._updateHelper(false);

        // destroy pipeline
        this._pipelineState.destroy();
        this._pipelineState = null;

        if(this._glassPipelineState) {
            this._glassPipelineState.destroy();
            this._glassPipelineState = null;
        }

        super.destroy(dispose);
    }

    public setupPerspective(fov?:number, near?:number, far?:number) {
        if(this._sceneCamera) {
            this._sceneCamera = null;
        }
        this._destroyControls();

        fov = fov || this.fov;
        near = near || this.near;
        far = far || this.far;

        this._buildInstancePerspective(fov, near, far);
    }

    public setupOrthographic(left?:number, right?:number, top?:number, bottom?:number, near = 0.1, far = 100.0) {
        if(this._sceneCamera) {
            this._sceneCamera = null;
        }
        this._destroyControls();

        const domSize = this._DOMRenderSize(Render.Main);
        const _left = left || -domSize.width * 0.5;
        const _right = right || domSize.width * 0.5;
        const _top = top || domSize.height * 0.5;
        const _bottom = bottom || -domSize.height * 0.5;

        this._buildInstanceOrtho(_left, _right, _top, _bottom, near, far);
    }

    public capture(render:Render, bufferSize?:any, bufferType?:string) {
        //TODO: support device pixel ratio
        bufferSize = bufferSize || {x: 640, y: 480 };
        bufferType = bufferType || "image/jpg";

        let currentSize:RenderSize = null;

        if(this._pipelineState.renderTarget) {
            // RENDER TARGET
            currentSize = {
                clientWidth: this._pipelineState.renderTarget.width,
                clientHeight: this._pipelineState.renderTarget.height,
                width: this._pipelineState.renderTarget.width,
                height: this._pipelineState.renderTarget.height,
                dpr: 1.0
            };

            if(currentSize.clientWidth !== bufferSize.x || currentSize.clientHeight !== bufferSize.y) {
                this._pipelineState.renderTarget.setSize(bufferSize.x, bufferSize.y);
            }

        } else {
            // SWAP CHAIN
            currentSize = this._DOMRenderSize(render);

            if(currentSize.clientWidth !== bufferSize.x || currentSize.clientHeight !== bufferSize.y) {
                //FIXME: update style???
                render.resize(bufferSize.x, bufferSize.y, 1.0, false);
            }
        }

        // render callback
        this.render(render, this.sceneCamera);

        let dataUrl = "";
        if(this._pipelineState.renderTarget) {
            const pixelBuffer = new Uint8Array( 4 * this._pipelineState.renderTarget.width * this._pipelineState.renderTarget.height );
            render.webGLRender.readRenderTargetPixels(this._pipelineState.renderTarget, 0, 0, this._pipelineState.renderTarget.width, this._pipelineState.renderTarget.height, pixelBuffer);

            // Create a 2D canvas to store the result
            const canvas = document.createElement('canvas');
            canvas.width = this._pipelineState.renderTarget.width;
            canvas.height = this._pipelineState.renderTarget.height;
            const context = canvas.getContext('2d');

            // Copy the pixels to a 2D canvas
            const imageData = context.createImageData(this._pipelineState.renderTarget.width, this._pipelineState.renderTarget.height);
            imageData.data.set(pixelBuffer);
            context.putImageData(imageData, 0, 0);

            // flip y
            context.scale(1, -1);
            context.drawImage(canvas, 0, -this._pipelineState.renderTarget.height);

            dataUrl = canvas.toDataURL(bufferType);

            this._pipelineState.renderTarget.setSize(currentSize.clientWidth, currentSize.clientHeight);
        } else {
            // SWAP CHAIN
            dataUrl = render.webGLRender.domElement.toDataURL(bufferType);

            // restore size
            // forcing container resize to apply changes to DOM
            //this._internalResize(currentSize.clientWidth, currentSize.clientHeight, currentSize.dpr, true);
            render.resize(currentSize.clientWidth, currentSize.clientHeight, currentSize.dpr, true);

        }
        if(build.Options.debugRenderOutput) {
            console.log("RENDER: capture screen to: ", dataUrl);
        }

        return dataUrl;
    }

    public setupOrbitController(element?:Element) {

        this._destroyControls();

        this._initOrbitControl(element);

        this.needsThink = true;
    }

    /** game loop */
    public setupBloom(threshold:number, strength:number) {
        const pipelineChange = !this._bloomEnabled;

        // setup bloom parameters
        this._bloomEnabled = true;
        this._bloomParams.sigma = 4.0;
        this._bloomParams.threshold = threshold;
        this._bloomParams.strength = strength;

        if(this._lazyInit && pipelineChange) {
            //
            const domSize:RenderSize = this._DOMRenderSize(Render.Main);
            // bloom init
            this._pipelineState.setupRenderToTexture(domSize);
        }
    }

    public setupGlass() {
        const pipelineChange = !this._glassEnabled;

        this._glassEnabled = true;

        if(!this._glassPipelineState) {
            this._glassPipelineState = new RenderState();
        }

        // setup render to target
        if(this._lazyInit && pipelineChange) {
            //
            const domSize:RenderSize = this._DOMRenderSize(Render.Main);

            domSize.width = 1024;
            domSize.height = 1024;
            domSize.dpr = 1;
            domSize.clientWidth = 1024;
            domSize.clientHeight = 1024;

            // render to target
            this._glassPipelineState.setupRenderToTexture(domSize, {
                minFilter: LinearMipMapLinearFilter,
                magFilter: LinearFilter,
                format: RGBAFormat,
                depthBuffer: true,
                generateMipmaps: true,
                stencilBuffer: false
            });
        }
    }

    /** setup to render to target */
    public setupRenderToTarget(mipmaps?:boolean) {
        const pipelineChange = !this._pipelineState.renderTarget;

        if(pipelineChange) {
            //
            const domSize:RenderSize = this._DOMRenderSize(Render.Main);

            // FIXME: default to nearest?!
            let minFilter = LinearFilter;
            let magFilter = LinearFilter;

            if(mipmaps) {
                minFilter = LinearMipMapLinearFilter;
                magFilter = LinearFilter;
            }

            // bloom init
            this._pipelineState.setupRenderToTexture(domSize, {
                minFilter,
                magFilter,
                format: RGBAFormat,
                stencilBuffer: false,
                generateMipmaps: mipmaps
            });

        }
    }

    /** setup to render to canvas */
    public setupRenderToSwapChain() {
        const pipelineChange = this._pipelineState.renderTarget;
        if(pipelineChange) {
            //
            this._pipelineState.setupFromConfiguration(this._renderConfig);
        }
    }

    public setupSSR() {
        const pipelineChange = !this._ssrEnabled;

        this._ssrEnabled = true;

        if(pipelineChange) {
            // build initial data
            this._ssrData = {
                depthFramebuffer: null,
                normalFramebuffer: null,
                positionFramebuffer: null,
                specularRoughnessFramebuffer: null,
                indirectSpecularFramebuffer: null,
                raytracingBuffer: null,
                depthTexture: null,
                depthLinearShader: null,
                viewNormalShader: null,
                pipelineState: null,
                ssrRaytraceShader: null,
                ssrResolveShader: null
            };

            //
            const domSize:RenderSize = this._DOMRenderSize(Render.Main);

            this._ssrData.pipelineState = new RenderState();
            this._ssrData.pipelineState.clearColor = new Color(0,0,0);

            // depth linear
            this._ssrData.depthLinearShader = ShaderLibrary.createShader("redLinearDepth");
            // view space normal mapping shader
            this._ssrData.viewNormalShader = ShaderLibrary.createShader("redViewNormal");
            // fullscreen ssr shader
            this._ssrData.ssrRaytraceShader = ShaderLibrary.createShader("redSSR_Raytrace");
            // fullscreen ssr shader
            this._ssrData.ssrResolveShader = ShaderLibrary.createShader("redSSR_Resolve");

            // shared depth texture
            this._ssrData.depthTexture = new DepthTexture(domSize.width, domSize.height);
            this._ssrData.depthTexture.type = UnsignedShortType;
            this._ssrData.depthTexture.type = UnsignedIntType;

            // Create a render target sharing depth buffer
            this._ssrData.depthFramebuffer = new WebGLRenderTarget( domSize.width, domSize.height, {type: FloatType} );
            this._ssrData.depthFramebuffer.texture.format = RGBFormat;
            this._ssrData.depthFramebuffer.texture.type = FloatType;
            this._ssrData.depthFramebuffer.texture.minFilter = NearestFilter;
            this._ssrData.depthFramebuffer.texture.magFilter = NearestFilter;
            this._ssrData.depthFramebuffer.texture.generateMipmaps = false;
            this._ssrData.depthFramebuffer.stencilBuffer = false;
            this._ssrData.depthFramebuffer.depthBuffer = true;
            this._ssrData.depthFramebuffer.depthTexture = this._ssrData.depthTexture;

            // Create a render target sharing depth buffer
            this._ssrData.normalFramebuffer = new WebGLRenderTarget( domSize.width, domSize.height, {type: FloatType} );
            this._ssrData.normalFramebuffer.texture.format = RGBFormat;
            this._ssrData.normalFramebuffer.texture.type = FloatType;
            this._ssrData.normalFramebuffer.texture.minFilter = NearestFilter;
            this._ssrData.normalFramebuffer.texture.magFilter = NearestFilter;
            this._ssrData.normalFramebuffer.texture.generateMipmaps = false;
            this._ssrData.normalFramebuffer.stencilBuffer = false;
            this._ssrData.normalFramebuffer.depthBuffer = true;
            this._ssrData.normalFramebuffer.depthTexture = this._ssrData.depthTexture;

            // Create a render target sharing depth buffer
            this._ssrData.positionFramebuffer = new WebGLRenderTarget( domSize.width, domSize.height, {type: FloatType} );
            this._ssrData.positionFramebuffer.texture.format = RGBFormat;
            this._ssrData.positionFramebuffer.texture.type = FloatType;
            this._ssrData.positionFramebuffer.texture.minFilter = NearestFilter;
            this._ssrData.positionFramebuffer.texture.magFilter = NearestFilter;
            this._ssrData.positionFramebuffer.texture.generateMipmaps = false;
            this._ssrData.positionFramebuffer.stencilBuffer = false;
            this._ssrData.positionFramebuffer.depthBuffer = true;
            this._ssrData.positionFramebuffer.depthTexture = this._ssrData.depthTexture;

            this._ssrData.specularRoughnessFramebuffer = new WebGLRenderTarget( domSize.width, domSize.height, {type: FloatType} );
            this._ssrData.specularRoughnessFramebuffer.texture.format = RGBAFormat;
            this._ssrData.specularRoughnessFramebuffer.texture.type = FloatType;
            this._ssrData.specularRoughnessFramebuffer.texture.minFilter = NearestFilter;
            this._ssrData.specularRoughnessFramebuffer.texture.magFilter = NearestFilter;
            this._ssrData.specularRoughnessFramebuffer.texture.generateMipmaps = false;
            this._ssrData.specularRoughnessFramebuffer.stencilBuffer = false;
            this._ssrData.specularRoughnessFramebuffer.depthBuffer = true;
            this._ssrData.specularRoughnessFramebuffer.depthTexture = this._ssrData.depthTexture;

            // no depth buffer
            this._ssrData.raytracingBuffer = new WebGLRenderTarget( domSize.width, domSize.height, {type: FloatType} );
            this._ssrData.raytracingBuffer.texture.format = RGBAFormat;
            this._ssrData.raytracingBuffer.texture.type = FloatType;
            this._ssrData.raytracingBuffer.texture.minFilter = NearestFilter;
            this._ssrData.raytracingBuffer.texture.magFilter = NearestFilter;
            this._ssrData.raytracingBuffer.texture.generateMipmaps = false;
            this._ssrData.raytracingBuffer.stencilBuffer = false;
            this._ssrData.raytracingBuffer.depthBuffer = false;

            this._ssrData.indirectSpecularFramebuffer = new WebGLRenderTarget( domSize.width, domSize.height, {type: UnsignedByteType} );
            this._ssrData.indirectSpecularFramebuffer.texture.format = RGBAFormat;
            this._ssrData.indirectSpecularFramebuffer.texture.type = UnsignedByteType;
            this._ssrData.indirectSpecularFramebuffer.texture.minFilter = NearestFilter;
            this._ssrData.indirectSpecularFramebuffer.texture.magFilter = NearestFilter;
            this._ssrData.indirectSpecularFramebuffer.texture.generateMipmaps = false;
            this._ssrData.indirectSpecularFramebuffer.stencilBuffer = false;
            this._ssrData.indirectSpecularFramebuffer.depthBuffer = true;
            this._ssrData.indirectSpecularFramebuffer.depthTexture = this._ssrData.depthTexture;

            // rendering to texture
            this.setupRenderToTarget(false);
        }
    }

    public think() {
        // orbit control or custom controller
        if(this._controls && this._controls.update) {
            // update controller
            this._controls.update();
            this._sceneCamera.updateMatrixWorld(true);

            // copy values from sceneCamera
            this._entityRef.position.copy(this._sceneCamera.position);
            this._entityRef.quaternion.copy(this._sceneCamera.quaternion);

        } else {
            // custom control
            //TODO: apply to camera
        }
    }

    /** rendering world callback */
    public render(render:Render, camera:RedCamera) {
        // no rendering in editor or when not valid
        if(!this.isValid || build.Options.isEditor) {
            return;
        }

        this._preRender(render);
        this._render(render);
        this._postRender(render);
    }

    /** pre render callback */
    protected _preRender(render:Render) {
        if(!this._lazyInit) {
            this._doLazyInit();
        }

        if(this.onPreRender) {
            this.onPreRender(render);
            return;
        }

        //FIXME: update always?!
        if(this._sceneCamera) {
            if(this._sceneCamera.isOrthographicCamera) {

            } else if(this._sceneCamera.isPerspectiveCamera) {
                //TODO: auto register on window resize and apply to camera??
                const domSize:RenderSize = this._DOMRenderSize(render);
                this.perspectiveCamera.aspect  = domSize.width / domSize.height;
                this._sceneCamera.updateProjectionMatrix();
            }
        }

        if(this._pipelineState) {
            // reset pipeline
            this._pipelineState.postProcessTarget = 0;
        }

        if(this._glassEnabled) {
            // pre render scene into frame buffer texture

            const tempMask = this._sceneCamera.layers.mask;

            this._sceneCamera.layers.disable(ERenderLayer.World);

            //this.layers.disable(ELayerMask.GlassObject);
            this._glassPipelineState.clearTarget = true;

            if(this.world && this.world.isValid()) {
                this.world.renderWorld(render, this._sceneCamera, this._glassPipelineState);
            }

            // blur framebuffer (TODO: need power of two here!)
            //this._blurFramebuffer(render);

            ShaderLibrary.setGlobalParameter("framebuffer2DTex", this._glassPipelineState.renderTarget.texture, EUniformType.TEXTURE);

            this._sceneCamera.layers.mask = tempMask;
        }

        if(this._ssrEnabled) {
            const pipeline = this._ssrData.pipelineState as RenderState;

            const originalMask = this._sceneCamera.layers.mask;
            // no debug object output
            this._sceneCamera.layers.mask = defaultRenderLayerMask();

            // draw depth linear
            pipeline.clearDepthStencil = true;
            pipeline.clearTarget = true;
            pipeline.overrideMaterial = this._ssrData.depthLinearShader;

            pipeline.renderTarget = this._ssrData.depthFramebuffer;

            if(this.world && this.world.isValid()) {
                this.world.renderWorld(render, this._sceneCamera, pipeline, null);
            }

            // draw normals (view space)
            pipeline.clearDepthStencil = false;
            pipeline.clearTarget = true;
            pipeline.overrideMaterial = this._ssrData.viewNormalShader;

            pipeline.renderTarget = this._ssrData.normalFramebuffer;

            if(this.world && this.world.isValid()) {
                this.world.renderWorld(render, this._sceneCamera, pipeline, null);
            }

            // draw specular roughness
            pipeline.clearDepthStencil = false;
            pipeline.clearTarget = true;
            pipeline.overrideMaterial = null;
            pipeline.renderTarget = this._ssrData.specularRoughnessFramebuffer;
            pipeline.overrideShaderVariant = ShaderVariant.SPECULAR_ROUGHNESS;
            if(this.world && this.world.isValid()) {
                this.world.renderWorld(render, this._sceneCamera, pipeline, null);
            }
            pipeline.overrideShaderVariant = ShaderVariant.DEFAULT;

            // draw indirect fallback buffer
            pipeline.clearDepthStencil = false;
            pipeline.clearTarget = true;
            pipeline.overrideMaterial = null;
            pipeline.renderTarget = this._ssrData.indirectSpecularFramebuffer;
            pipeline.overrideShaderVariant = ShaderVariant.INDIRECT_SPECULAR;
            if(this.world && this.world.isValid()) {
                this.world.renderWorld(render, this._sceneCamera, pipeline, null);
            }
            pipeline.overrideShaderVariant = ShaderVariant.DEFAULT;
            // restore mask
            this._sceneCamera.layers.mask = originalMask;
        }

    }

    /** render callback */
    protected _render(render:Render) {
        if(this.onRender) {
            this.onRender(render);
            return;
        }

        if(this.world && this.world.isValid()) {
            const last = this._pipelineState.overrideShaderVariant;

            if(this._ssrEnabled) {
                this._pipelineState.overrideShaderVariant = ShaderVariant.LIGHTING_DIFFUSE;
            }

            this.world.renderWorld(render, this._sceneCamera, this._pipelineState);

            this._pipelineState.overrideShaderVariant = last;
        }
    }

    /** post render callback */
    protected _postRender(render:Render) {
        if(this.onPostRender) {
            this.onPostRender(render);
            return;
        }

        if(this._ssrEnabled) {
            const pipeline = this._ssrData.pipelineState as RenderState;

            this._sceneCamera.updateMatrixWorld(true);

            // render fullscreen shader (blending?!)
            pipeline.clearDepthStencil = false;
            pipeline.clearTarget = false;
            pipeline.overrideMaterial = this._ssrData.ssrRaytraceShader;
            pipeline.renderTarget = this._ssrData.raytracingBuffer;

            // RAYTRACE SHADER
            this._ssrData.ssrRaytraceShader.uniforms['pixelPosition'].value.set(0, 0);
            this._ssrData.ssrRaytraceShader.uniforms['screenSize'].value.set(render.size.width, render.size.height);
            this._ssrData.ssrRaytraceShader.uniforms['pixelSize'].value.set(render.size.width, render.size.height);

            this._ssrData.ssrRaytraceShader.uniforms['depthBuffer'].value = this._ssrData.depthFramebuffer.texture;
            this._ssrData.ssrRaytraceShader.uniforms['normalBuffer'].value = this._ssrData.normalFramebuffer.texture;

            this._ssrData.ssrRaytraceShader.uniforms['projMatrix'].value.copy(this._sceneCamera.projectionMatrix);
            this._ssrData.ssrRaytraceShader.uniforms['invProjectionMatrix'].value.copy((this._sceneCamera as any).projectionMatrixInverse);

            this._ssrData.ssrRaytraceShader.uniforms['near'].value = this._sceneCamera.near;
            this._ssrData.ssrRaytraceShader.uniforms['resolution'].value.set(render.size.width, render.size.height);

            this._ssrData.ssrRaytraceShader.uniforms['stride'].value = this._ssrOtions.stride;
            this._ssrData.ssrRaytraceShader.uniforms['thickness'].value = this._ssrOtions.thickness;
            this._ssrData.ssrRaytraceShader.uniforms['jitter'].value = this._ssrOtions.jitter;

            render.renderFullscreenQuad(pipeline, this._ssrData.ssrRaytraceShader);

            // RESOLVE SHADER
            this._ssrData.ssrResolveShader.uniforms['pixelPosition'].value.set(0, 0);
            this._ssrData.ssrResolveShader.uniforms['screenSize'].value.set(render.size.width, render.size.height);
            this._ssrData.ssrResolveShader.uniforms['pixelSize'].value.set(render.size.width, render.size.height);

            this._ssrData.ssrResolveShader.uniforms['sourceBuffer'].value = this._pipelineState.renderTarget.texture;
            this._ssrData.ssrResolveShader.uniforms['raytraceBuffer'].value = this._ssrData.raytracingBuffer.texture;
            this._ssrData.ssrResolveShader.uniforms['specularRoughnessMap'].value = this._ssrData.specularRoughnessFramebuffer.texture;
            this._ssrData.ssrResolveShader.uniforms['fallbackBuffer'].value = this._ssrData.indirectSpecularFramebuffer.texture;

            this._ssrData.ssrResolveShader.uniforms['resolution'].value.set(render.size.width, render.size.height);

            pipeline.clearDepthStencil = true;
            pipeline.clearTarget = true;
            pipeline.renderTarget = null;
            pipeline.overrideMaterial = this._ssrData.ssrResolveShader;

            render.renderFullscreenQuad(pipeline, this._ssrData.ssrResolveShader);
        }

        if(this._bloomEnabled) {
            this._renderBloom(render, this._pipelineState, this._bloomParams, false);
            render.renderBlit(this._pipelineState, true);
        }
        const renderConfig = this._renderConfig;

        if(renderConfig.renderSSAO) {
            const blitToScreen = renderConfig.renderFXAA === false;
            this._renderSSAO(render, this._pipelineState, this._sceneCamera, this.world.scene, blitToScreen);
        }

        if(renderConfig.renderFXAA) {
            this._renderFXAA(render, this._pipelineState, true);
        }
    }

    /** render fxaa post process effect */
    private _renderFXAA(render:Render, pipeState:RenderState, blitToScreen:boolean) {
        // new pipeline state?
        pipeState = pipeState || this._pipelineState;

        if(!pipeState) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        render.applyPipelineState(pipeState);

        //TODO... check for render target
        const size = render.size;

        // want to render anti alias but cannot use msaa
        if(!this._fxaaPostProcess) {
            const material = ShaderLibrary.createMaterialShader("fxaa", { shader: "redFXAA"});
            this._fxaaPostProcess = new ShaderPass(material);
        }
        //TODO: option...
        this._fxaaPostProcess.renderToScreen = blitToScreen;
        this._fxaaPostProcess.uniforms['resolution'].value.set(1.0 / size.width, 1.0 / size.height);

        const mask = 0;

        this._fxaaPostProcess.render(render.webGLRender, pipeState.writeBuffer, pipeState.readBuffer, mask);
        pipeState.swapPostProcessTargets();

    }

    /** render SSAO post process effect */
    private _renderSSAO(render:Render, pipeState:RenderState, camera:any, scene:any, blitToScreen:boolean, onlyAO:boolean = false) {

        // new pipeline state?
        pipeState = pipeState || this._pipelineState;

        if(!pipeState) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        render.applyPipelineState(pipeState);

        // get destination buffer size
        const size = {
            width: 0,
            height: 0
        };

        if(this._pipelineState.writeBuffer) {
            size.width = this._pipelineState.writeBuffer.width;
            size.height = this._pipelineState.writeBuffer.height;
        } else {
            size.width = render.size.width;
            size.height = render.size.height;
        }

        const mask = 0;

        //TODO:
        if(!this._depthPass) {

            const depthRenderTarget = new WebGLRenderTarget(size.width, size.height, {
                minFilter: LinearFilter,
                magFilter: LinearFilter
            });

            this._depthPass = new DepthPass();
            this._depthPass.depthRenderTarget = depthRenderTarget;
            //FIXME: always?
            this._depthPass.clear = true;
        }

        if(this._depthPass.depthRenderTarget.width !== size.width ||
           this._depthPass.depthRenderTarget.height !== size.height) {
            this._depthPass.depthRenderTarget.setSize(size.width, size.height);
        }

        //TODO: save in render state??
        this._depthPass.scene = scene;
        this._depthPass.camera = camera;

        this._depthPass.render(render.webGLRender);

        //pipeState.swapPostProcessTargets();

        if(!this._ssaoPass) {
            const material = ShaderLibrary.createMaterialShader("ssao", { shader: "redSSAO"});
            this._ssaoPass = new ShaderPass(material);
        }
        //TODO: blit
        this._ssaoPass.renderToScreen = blitToScreen;

        //ssaoPass.uniforms[ "tDiffuse" ].value will be set by ShaderPass
        this._ssaoPass.uniforms['tDepth'].value = this._depthPass.depthRenderTarget.texture;
        this._ssaoPass.uniforms['size'].value.set(size.width, size.height);
        //set this before rendering?!
        this._ssaoPass.uniforms['cameraNear'].value = camera.near;
        this._ssaoPass.uniforms['cameraFar'].value = camera.far;
        this._ssaoPass.uniforms['onlyAO'].value = onlyAO === true ? 1 : 0;
        this._ssaoPass.uniforms['aoClamp'].value = 0.5;
        this._ssaoPass.uniforms['lumInfluence'].value = 0.5;

        this._ssaoPass.render(render.webGLRender, pipeState.writeBuffer, pipeState.readBuffer, mask);
        pipeState.swapPostProcessTargets();

    }

    private _renderBloom(render:Render, pipeState:RenderState, params:BloomEffectParams, blitToScreen:boolean) {

        // new pipeline state?
        pipeState = pipeState || this._pipelineState;

        if(!pipeState) {
            console.warn("Render: no pipeline state, deprecated...");
            return;
        }

        render.applyPipelineState(pipeState);

        //TODO... check for render target
        const size = render.size;
        const mask = 0;

        let material = null;
        if(!this._convolutionPass) {
            const sigma = 4.0;
            material = ShaderLibrary.createMaterialShader("_postpro_convolution", {shader: "redConvolution"});
            material.uniforms[ "kernel" ].value = buildKernelConvolution( params.sigma || sigma );
            this._convolutionPass = new ShaderPass(material);
        } else {
            material = this._convolutionPass.material;
        }

        // resolve read target
        const readTarget = pipeState.readBuffer || pipeState.renderTarget;

        const targetSize:math.Size = params.size || { width: readTarget.width, height: readTarget.height };

        // write render targets
        const pars = { minFilter: LinearFilter, magFilter: LinearFilter, format: RGBAFormat };
        const renderTargetX = pipeState.requestTemporaryTarget({ width: targetSize.width, height: targetSize.height}, pars);
        const renderTargetY = pipeState.requestTemporaryTarget({ width: targetSize.width, height: targetSize.height}, pars);

        // pre pass (copy threshold)
        if(!this._blitShaderThreshold) {
            this._blitShaderThreshold = ShaderLibrary.createMaterialShader("_postpro_blit_threshold", {shader: "redBlitThreshold"});
        }
        this._blitShaderThreshold.uniforms["threshold"].value = params.threshold || 0.9;
        render.renderQuad(this._blitShaderThreshold, renderTargetY, readTarget);

        // pass one (x axis)
        material.uniforms[ "imageIncrement" ].value = new Vector2( 0.001953125, 0.0 );
        this._convolutionPass.render(render.webGLRender, renderTargetX, renderTargetY, mask);

        // pass two (y axis)
        material.uniforms[ "imageIncrement" ].value = new Vector2( 0.0, 0.001953125 );
        this._convolutionPass.render(render.webGLRender, renderTargetY, renderTargetX, mask);

        // FIXME: always?!
        pipeState.swapPostProcessTargets();

        // blend on first target
        if(!this._blendShader) {
            this._blendShader = ShaderLibrary.createMaterialShader("_postpro_blend", {shader: "redBlend" });
        }
        this._blendShader.uniforms["opacity"].value = params.strength || 1.0;
        render.renderQuad(this._blendShader, pipeState.writeBuffer, renderTargetY);

        // release memory
        pipeState.returnTemporaryTarget(renderTargetX);
        pipeState.returnTemporaryTarget(renderTargetY);

        // bloom texture should be in target
        if(blitToScreen) {
            //TODO: copy shader or use blend to screen

        }

    }

    /**
     * init post processing shader etc
     */
    private _applyPostProcessing(render:Render, settings:RenderConfiguration) {

        //TODO... check for render target??
        const size = settings.renderSize || render.size;

        // want to render anti alias but cannot use msaa
        if(settings.renderFXAA) {
            const material = ShaderLibrary.createMaterialShader("fxaa", { shader: "redFXAA"});
            this._fxaaPostProcess = new ShaderPass(material);
            this._fxaaPostProcess.uniforms['resolution'].value.set(1.0 / size.width, 1.0 / size.height);
        }

        // Setup SSAO pass
        if(settings.renderSSAO) {

            // SSAO
            const depthRenderTarget = new WebGLRenderTarget(size.width, size.height, {
                minFilter: LinearFilter,
                magFilter: LinearFilter
            });

            this._depthPass = new DepthPass();
            this._depthPass.depthRenderTarget = depthRenderTarget;
            this._depthPass.clear = true;

            const material = ShaderLibrary.createMaterialShader("ssao", {shader: "redSSAO"});
            this._ssaoPass = new ShaderPass(material);
            //this.ssaoPass.renderToScreen = true;
            //ssaoPass.uniforms[ "tDiffuse" ].value will be set by ShaderPass
            this._ssaoPass.uniforms['tDepth'].value = depthRenderTarget;
            this._ssaoPass.uniforms['size'].value.set(size.width, size.height);
            //set this before rendering?!
            this._ssaoPass.uniforms['cameraNear'].value = 1.0;
            this._ssaoPass.uniforms['cameraFar'].value = 1000.0;
            this._ssaoPass.uniforms['onlyAO'].value = 0;
            //this.ssaoPass.uniforms[ 'aoClamp' ].value = 0.3;
            this._ssaoPass.uniforms['lumInfluence'].value = 0.1;
        }

        if(settings.renderDOF) {
            console.warn("Render: reintegrate depth of field shader!!!");
            /*
            this._postEffects.dofSimple.pass = new THREE.BokehPass(null, null, {
                focus: 1.0,
                aperture: 0.025,
                maxblur: 1.0,
                aspect: 1.0,

                width: size.width,
                height: size.height
            });
            */
        }

        if(settings.renderTAA) {
            /**
             * TO DO: Temporal Anti Aliasing
             */
        }

    }

    private _blurFramebuffer(render:Render) {
        const blurJob = BlurRenderJob.get(render);

        blurJob.filterLod0 = false;
        this._pipelineState.renderTarget = blurJob.copy(this._pipelineState.renderTarget);
    }

    public onTransformUpdate() {
        if(!this._controls) {
            //FIXME: add scene camera to entity directly?!
            if(this._sceneCamera) {
                this._sceneCamera.position.copy(this.entity.positionWorld);
                this._sceneCamera.quaternion.copy(this.entity.rotationWorld);
            }
        }
    }

    /** load component */
    public load(data:ComponentData, ioNotifier?:IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        // cleanup
        this._lazyInit = false;

        const debugHelper = data.parameters.debugHelper || build.Options.isEditor || false;

        const near = data.parameters.near || 0.1;
        const far = data.parameters.far || 100.0;

        const aperture = data.parameters.aperture || 16.0;
        const shutterSpeed = data.parameters.shutterSpeed || (1.0 / 125.0);
        const sensitivity = data.parameters.sensitivity || 100.0;

        const whitepoint = data.parameters.whitepoint || 1.0;

        if(data.parameters.type === ECameraProjection.Orthographic) {
            const domSize = this._DOMRenderSize(Render.Main);
            const left = data.parameters.left || -domSize.width * 0.5;
            const right = data.parameters.right || domSize.width * 0.5;
            const top = data.parameters.top || domSize.height * 0.5;
            const bottom = data.parameters.bottom || -domSize.height * 0.5;

            this._buildInstanceOrtho(left, right, top, bottom, near, far);
        } else {
            const fov = data.parameters.fov || 90.0;
            this._buildInstancePerspective(fov, near, far);
        }

        this.aperture = aperture;
        this.shutterSpeed = shutterSpeed;
        this.sensitivity = sensitivity;
        this.whitepoint = whitepoint;

        if(data.parameters.main === true) {
            this.main = true;
        }

        if(data.parameters.bloom) {
            this._bloomParams = data.parameters.bloom;
        }
        //setup bloom
        if(data.parameters.bloomEnabled) {
            this.setupBloom(this._bloomParams.threshold, this._bloomParams.strength);
        }

        this._updateHelper(debugHelper);
    }

    /** replication */
    public save() {
        const node = {
            module: "RED",
            type: "CameraComponent",
            parameters: {
                main: false,
                debugHelper: false,
                // type and settings
                type: ECameraProjection.Perspective,
                near: 0.1,
                far: 100.0,
                fov: 90.0,
                left: undefined,
                right: undefined,
                top: undefined,
                bottom: undefined,
                // camera
                aperture: 16.0,
                shutterSpeed: 1.0 / 125.0,
                sensitivity: 100,
                // tonemap
                whitepoint: 1.0,
                // bloom
                bloomEnabled: false,
                bloom: null
            }
        };

        if(this._sceneCamera) {
            node.parameters.main = this._sceneCamera['main'];

            if(this._sceneCamera instanceof PhysicalCamera) {
                node.parameters.fov = this._sceneCamera.fov;
                node.parameters.type = ECameraProjection.Perspective;
            } else if(this._sceneCamera instanceof OrthoCamera) {
                node.parameters.type = ECameraProjection.Orthographic;
                node.parameters.left = this._sceneCamera.left;
                node.parameters.right = this._sceneCamera.right;
                node.parameters.top = this._sceneCamera.top;
                node.parameters.bottom = this._sceneCamera.bottom;
            }

            node.parameters.near = this._sceneCamera.near;
            node.parameters.far = this._sceneCamera.far;

            node.parameters.aperture = this._aperture;
            node.parameters.shutterSpeed = this._shutterSpeed;
            node.parameters.sensitivity = this._sensitivity;
            node.parameters.whitepoint = this._sceneCamera['whitepoint'];

            node.parameters.bloomEnabled = this._bloomEnabled;

            if(this._bloomParams) {
                node.parameters.bloom = this._bloomParams;
            }
        }

        return node;
    }

    private _buildInstancePerspective(fov:number, near:number, far:number) {
        if(this.sceneCamera) {
            console.warn("CameraComponent: scene camera already exists");
            return;
        }

        this._sceneCamera = new PhysicalCamera(fov, 1.0, near, far);
        this._sceneCamera.name = "Camera";
        this._sceneCamera.layers.mask = allRenderLayerMask();

        // defaults
        this._sceneCamera['main'] = false;
        this._sceneCamera.exposure = this.exposure;
        this._sceneCamera.whitepoint = 1.0;

        // take current spot
        this._sceneCamera.position.copy(this.entity.positionWorld);
        this._sceneCamera.quaternion.copy(this.entity.rotationWorld);
    }

    private _buildInstanceOrtho(left:number, right:number, top:number, bottom:number, near:number, far:number) {
        if(this.sceneCamera) {
            console.warn("CameraComponent: scene camera already exists");
            return;
        }

        this._sceneCamera = new OrthoCamera(left, right, top, bottom, near, far);
        this._sceneCamera.name = "Camera";
        this._sceneCamera.layers.mask = allRenderLayerMask();

        // defaults
        this._sceneCamera['main'] = false;
        this._sceneCamera.exposure = this.exposure;
        this._sceneCamera.whitepoint = 1.0;

        // take current spot
        this._sceneCamera.position.copy(this.entity.positionWorld);
        this._sceneCamera.quaternion.copy(this.entity.rotationWorld);
    }

    private _onWindowResize = (event:Event, domSize:RenderSize) => {
        if(this._sceneCamera) {

            if(this._sceneCamera instanceof PhysicalCamera) {
                this._sceneCamera.aspect = domSize.width / domSize.height;

            } else if(this._sceneCamera instanceof OrthoCamera) {
                this._sceneCamera.left = -domSize.width * 0.5;
                this._sceneCamera.right = domSize.width * 0.5;
                this._sceneCamera.top = domSize.height * 0.5;
                this._sceneCamera.bottom = -domSize.height * 0.5;
            }

            this._sceneCamera.updateProjectionMatrix();
        }
    }
    /** get DOM element size (prefers render container) */
    protected _DOMRenderSize(render:Render) : RenderSize {
        if(render) {
            return { clientWidth: render.size.clientWidth,
                        clientHeight: render.size.clientHeight,
                        dpr: render.size.dpr,
                        width: render.size.width,
                        height: render.size.height };
        } else if(this.container) {
            return { clientWidth: this.container.clientWidth,
                        clientHeight: this.container.clientHeight,
                        dpr: window.devicePixelRatio,
                        width: Math.floor(this.container.clientWidth * window.devicePixelRatio),
                        height: Math.floor(this.container.clientHeight * window.devicePixelRatio)};
        } else {
            return { clientWidth: window.innerWidth,
                        clientHeight: window.innerHeight,
                        dpr: window.devicePixelRatio,
                        width: Math.floor(window.innerWidth * window.devicePixelRatio),
                        height: Math.floor(window.innerHeight * window.devicePixelRatio) };
        }
    }

    /** lazy init callback */
    protected _init(render:Render) {

        const domSize:RenderSize = this._DOMRenderSize(render);

        if(this._bloomEnabled) {
            // bloom init
            this._pipelineState.setupRenderToTexture(domSize);
        }

        if(this._glassEnabled) {
            if(!this._glassPipelineState) {
                this._glassPipelineState = new RenderState();
            }

            domSize.width = 1024;
            domSize.height = 1024;
            domSize.dpr = 1;
            domSize.clientWidth = 1024;
            domSize.clientHeight = 1024;

            // render to target
            this._glassPipelineState.setupRenderToTexture(domSize, {
                minFilter: LinearMipMapLinearFilter,
                magFilter: LinearFilter,
                format: RGBAFormat,
                depthBuffer: true,
                generateMipmaps: true,
                stencilBuffer: false
            });
        }
    }

    protected _updateHelper(debugHelper:boolean) {
        //TODO: add camera helper
        if(debugHelper) {

        } else {

        }
    }

    /** deferred lazy init */
    protected _doLazyInit() {
        if(this._lazyInit) {
            return;
        }
        this._lazyInit = true;
        if(!this._pipelineState) {
            // setup pipeline (default)
            this._pipelineState = new RenderState();
            this._pipelineState.setupFromConfiguration(this._renderConfig);
        }
        this._init(Render.Main);
    }

    /** init orbit control */
    protected _initOrbitControl(container?:Element) {
        if(build.Options.development) {
            console.log("CameraControl: Orbit Camera");
        }

        container = container || this.container;

        // take current spot
        this._sceneCamera.position.copy(this.entity.positionWorld);
        this._sceneCamera.quaternion.copy(this.entity.rotationWorld);

        // now orbit controller takes over
        this._controls = new OrbitControls(this._sceneCamera, container);
        this._controls.enableKeys = false;

        // default settings
        this._controls.enableDamping = true;
        this._controls.dampingFactor = 0.2;
        this._controls.enableZoom = true;
        this._controls.enablePan = true;
        this._controls.minDistance = 1.0;
        this._controls.maxDistance = 1000.0;

        const dpr = window.devicePixelRatio !== undefined ? window.devicePixelRatio : 1.0;

        this._controls.zoomSpeed = Platform.get().isTouchDevice ? 1.0 / dpr : 1.0;
        this._controls.rotateSpeed = Platform.get().isTouchDevice ? 0.1 : 0.25;

        this._sceneCamera.updateMatrixWorld(true);
    }

    private _destroyControls() {
        if(this._controls) {
            // deactivate
            this._controls.enabled = false;

            //TODO: add orbit control to typescript to unify controller design
            if(this._controls.destroy) {
                this._controls.destroy();
            }
        }
        this._controls = null;
    }
}

/** register component */
registerComponent("RED", "CameraComponent", CameraComponent);
