///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class DisqusComponent extends AbstractBlockComponent {

    private static _disqusScript;
    private static _inited:boolean = false;
    private static _disqusScriptLoaded:boolean;
    private static _disqusScriptCallbacks:Array<()=>void> = [];
    private _disqusScriptCallbackRef:()=>void;

    protected onInit():void{
        this._params = _.defaults(this._params, {});

        //for the first start
        window["disqus_config"] = function () {
            this.language = this._params.language;
            this.page.identifier =  this._params.identifier;
            this.page.url =  this._params.url;
        };

        //console.log("Disqus", this._params);

        this._disqusScriptCallbackRef = this.onDisqusScriptLoaded.bind(this);

        if(DisqusComponent._disqusScript == null){
            DisqusComponent._disqusScriptCallbacks.push(this._disqusScriptCallbackRef);
            DisqusComponent._disqusScript = document.createElement('script');
            DisqusComponent._disqusScript.async = true;
            DisqusComponent._disqusScript.defer = true;
            DisqusComponent._disqusScript.src = "https://redplant-realtime-studio.disqus.com/embed.js";
            DisqusComponent._disqusScript.setAttribute('data-timestamp', +new Date());

            DisqusComponent._disqusScript.onload =  ()=> {
                DisqusComponent._disqusScriptLoaded = true;
                console.log("disqus loaded");
                _.each(DisqusComponent._disqusScriptCallbacks,(callback)=>{callback()});
            };
            document.body.appendChild(DisqusComponent._disqusScript);
        }
        else if(!DisqusComponent._disqusScriptLoaded){
            DisqusComponent._disqusScriptCallbacks.push(this._disqusScriptCallbackRef);
        }
        else{
            this.onDisqusScriptLoaded();
        }
    }

    private onDisqusScriptLoaded():void{
        this.state = ComponentState.READY;

        if(DisqusComponent._inited === true){
            console.log("Disqus reset",this._params);
            DISQUS.reset({
                reload: true,
                config: function () {
                    this.language = this._params.language;
                    this.page.identifier =  this._params.identifier;
                    this.page.url =  this._params.url;
                }
            });
        }

        DisqusComponent._inited = true;
    }

    protected onDestroy():void{
        DisqusComponent._disqusScriptCallbacks = _.without(DisqusComponent._disqusScriptCallbacks,this._disqusScriptCallbackRef);
        this.state = ComponentState.DESTROYED;
    }
}