/**
 * Camera.ts: camera representation
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Quaternion } from "../../lib/threejs/math/Quaternion";
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { Euler } from "../../lib/threejs/math/Euler";
import { PerspectiveCamera } from "../../lib/threejs/cameras/PerspectiveCamera";
import { OrthographicCamera } from "../../lib/threejs/cameras/OrthographicCamera";
import { CubeCamera } from "../../lib/threejs/cameras/CubeCamera";

export function ev100(aperture:number, shutterSpeed:number, sensitivity:number) : number {
    // With N = aperture, t = shutter speed and S = sensitivity,
    // we can compute EV100 knowing that:
    //
    // EVs = log2(N^2 / t)
    // and
    // EVs = EV100 + log2(S / 100)
    //
    // We can therefore find:
    //
    // EV100 = EVs - log2(S / 100)
    // EV100 = log2(N^2 / t) - log2(S / 100)
    // EV100 = log2((N^2 / t) * (100 / S))
    //
    // Reference: https://en.wikipedia.org/wiki/Exposure_value
    return Math.log2((aperture * aperture * 100.0) / (shutterSpeed * sensitivity));
}

export function exposure(aperture:number, shutterSpeed:number, sensitivity:number) : number {
    // This is equivalent to calling exposure(ev100(N, t, S))
    // By merging the two calls we can remove extra pow()/log2() calls
    const e = (aperture * aperture * 100.0) / (shutterSpeed * sensitivity);
    return 1.0 / (1.2 * e);
}

/**
 *
 * @param ev ev100 value
 */
export function exposureFromEV(ev:number) : number {
    // The photometric exposure H is defined by:
    //
    // H = (q * t / (N^2)) * L
    //
    // Where t is the shutter speed, N the aperture, L the incident luminance
    // and q the lens and vignetting attenuation. A typical value of q is 0.65
    // (see reference link below).
    //
    // The value of H as recorded by a sensor depends on the sensitivity of the
    // sensor. An easy way to find that value is to use the saturation-based
    // sensitivity method:
    //
    // S_sat = 78 / H_sat
    //
    // This method defines the maximum possible exposure that does not lead to
    // clipping or blooming.
    //
    // The factor 78 is chosen so that exposure settings based on a standard
    // light meter and an 18% reflective surface will result in an image with
    // a grey level of 18% * sqrt(2) = 12.7% of saturation. The sqrt(2) factor
    // is used to account for an extra half a stop of headroom to deal with
    // specular reflections.
    //
    // Using the definitions of H and S_sat, we can derive the formula to
    // compute the maximum luminance to saturate the sensor:
    //
    // H_sat = 78 / S_stat
    // (q * t / (N^2)) * Lmax = 78 / S
    // Lmax = (78 / S) * (N^2 / (q * t))
    // Lmax = (78 / (S * q)) * (N^2 / t)
    //
    // With q = 0.65, S = 100 and EVs = log2(N^2 / t) (in this case EVs = EV100):
    //
    // Lmax = (78 / (100 * 0.65)) * 2^EV100
    // Lmax = 1.2 * 2^EV100
    //
    // The value of a pixel in the fragment shader can be computed by
    // normalizing the incident luminance L at the pixel's position
    // with the maximum luminance Lmax
    //
    // Reference: https://en.wikipedia.org/wiki/Film_speed
    return 1.0 / (1.2 * Math.pow(2.0, ev));
}

/*
 * Get an exposure using the Saturation-based Speed method.
 */
export function getSaturationBasedExposure(aperture:number, shutterSpeed:number, iso:number) {
    const lMax = (7800.0 / 65.0) * (aperture * aperture) / (iso * shutterSpeed);
    return 1.0 / lMax;
}

/*
 * Get an exposure using the Standard Output Sensitivity method.
 * Accepts an additional parameter of the target middle grey.
 */
export function getStandardOutputBasedExposure(aperture:number, shutterSpeed:number, iso:number, middleGrey:number = 0.18) {
    const lAvg = (1000.0 / 65.0) * (aperture*aperture) / (iso * shutterSpeed);
    return middleGrey / lAvg;
}

/*

#if CUBEMAP_EDGE_FIXUP == 1
vec3 fixCubemapLookup(vec3 v, float lod) {
    vec3 r = abs(v);
    float M = max(max(v.x, v.y), v.z);
    float scale = 1.0 - exp2(lod) * (1.0 / 256.0);
    if (v.x != M) v.x *= scale;
    if (v.y != M) v.y *= scale;
    if (v.z != M) v.z *= scale;
    return v;
}
#endif
 */

export enum ECameraProjection {
    Orthographic = 1,
    Perspective = 2
}

export interface RedCamera {
    isRedCamera: boolean;
    isOrthographicCamera: boolean;
    isPerspectiveCamera: boolean;

    name: string;
    layers: any;

    /** physical exposure */
    exposure: number;
    /** physical whitepoint */
    whitepoint: number;

    near: number;
    far: number;
    /** perspective view */
    fov?: number;
    aspect?: number;
    /** orthographic view */
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
    zoom?: number;

    position: Vector3;
    quaternion: Quaternion;
    rotation: Euler;

    matrixWorld: Matrix4;
    matrixWorldInverse: Matrix4;
    projectionMatrix: Matrix4;
    //projectionMatrixInverse: Matrix4;

    updateProjectionMatrix: () => void;
    updateMatrixWorld: (force: boolean) => void;
    lookAt(vector:Vector3) : void;
}

/**
 * physical camera setup
 */
export class PhysicalCamera extends PerspectiveCamera implements RedCamera {

    public isRedCamera:boolean;
    public isOrthographicCamera: boolean;
    public exposure:number;
    public whitepoint:number;

    constructor(fov?: number, aspect?: number, near?: number, far?: number) {
        super(fov, aspect, near, far);
        this.isRedCamera = true;
        this.isOrthographicCamera = false;
        this.exposure = 1.0;
        this.whitepoint = 1.0;
    }
}

/**
 * orthogonal camera setup
 */
export class OrthoCamera extends OrthographicCamera implements RedCamera {

    public isRedCamera:boolean;
    public isPerspectiveCamera:boolean;
    public exposure:number;
    public whitepoint:number;

    constructor(left: number, right: number, top: number, bottom: number, near: number, far: number) {
        super(left, right, top, bottom, near, far);
        this.isRedCamera = true;
        this.isPerspectiveCamera = false;
        this.exposure = 1.0;
        this.whitepoint = 1.0;
    }
}

/**
 * physical cube camera setup
 */
export class PhysicalCubeCamera extends CubeCamera {

    public isRedCubeCamera:boolean;
    public isRedCamera:boolean;

    public exposure:number;
    public whitepoint:number;

    constructor(near?: number, far?: number, cubeResolution?: number) {
        super(near, far, cubeResolution);
        this.isRedCubeCamera = true;
        this.isRedCamera = true;
        this.exposure = 1.0;
        this.whitepoint = 1.0;
    }
}
