/**
 * InstanceSystem.ts
 * Management of Instanced Objects
 *
 * Copyright redPlant GmbH 2018
 * @author Monia Arrada
 * @author Lutz Hören
 */
import { Matrix4 } from "../../lib/threejs/math/Matrix4";
import { InstancedBufferAttribute } from "../../lib/threejs/core/InstancedBufferAttribute";
import { Entity } from "../framework/Entity";
import { Mesh } from "../render/Mesh";
import { loadModel, StaticModel } from "../render/Model";
import { MaterialRef } from "../render/Geometry";
import { createComponentId, componentIdGetIndex, ComponentId } from "../framework/Component";
import { AsyncLoad } from "../io/AsyncLoad";
import { Render } from "../render/Render";
import { IInstancingSystem, INSTANCINGSYSTEM_API } from "../framework/InstancingAPI";
import { registerAPI } from "../plugin/Plugin";

/** callback in order to wait for model to be loaded */
export type getModelCallback = (model: StaticModel) => void;

/** generic parameters for an instance */
interface InstanceParams {
    instanceName: string; // allows creation of multiple instances using same model but different materials
    filename: string;
    instanceCount: number;
    instanceBufferRef: {
        mcol0: InstancedBufferAttribute,
        mcol1: InstancedBufferAttribute,
        mcol2: InstancedBufferAttribute
    };
    // map the unique ID of each instance to their position in buffer
    indicesMap: {[key:number]:number};
    // object reference
    object: StaticModel | Mesh;
    // non gpu instancing instances
    objects: Entity[];
    // load callbacks
    callback: getModelCallback[];
}

interface InstanceObject {
    id: ComponentId;
    instanceRef: string;
}

/**
 * global Instancing system handling
 * @class InstanceWrapperSystem
 * [[include:sourceDoc/Instancing.md]]
 */

/** entity parent to all instanced objects in the scene */
let _wrapperEntity: Entity = null;
/** minimum instance buffer size */
const _minBufferSize = 4;

/** current map of objects registered */
let _meshes:{[key:string]:InstanceParams} = {};

let _gpuInstancing: boolean = false;

/** current list of instances */
let _instances:InstanceObject[] = [];
let _version:number = 1;

/** initialisation by instancing the wrapperEntity */
function init(world: any) {
    console.assert(!_wrapperEntity, "called twice");
    if(!_wrapperEntity) {
        _wrapperEntity = world.instantiateEntity("Instances_Entity");
        _wrapperEntity.transient = true;
        _wrapperEntity.persistent = true;
        _wrapperEntity.hideInHierarchy = true;
    }

    if(Render.Main) {
        _gpuInstancing = Render.Main.capabilities.instancing || false;
    } else {
        console.info("InstancingSystem: renderer not initialized yet");
        _gpuInstancing = false;
    }
}

/** destruction */
function destroy() {
    if(_wrapperEntity) {
        _wrapperEntity.destroy();
        _wrapperEntity = null;
    }
    _instances = [];
    _meshes = {};
    _version = 1;
}

/**
 * Creation of the InstanceObject for the pool and call for creation of the instance
 * @param filename name of the model to load
 * @param materialRefs if needed
 * @param instanceName name of instance in pool
 */
function registerModel(filename: string, materialRefs?:MaterialRef[], instanceName?: string) : AsyncLoad<StaticModel> {
    instanceName = instanceName || filename;

    // already registered
    if(_meshes[instanceName]) {
        //TODO: make sure is a static model

        if(_meshes[instanceName].object) {
            // finished loading already, resolve directly
            return AsyncLoad.resolve(_meshes[instanceName].object as StaticModel);
        } else {
            // callback at a later time (is loading)
            return new AsyncLoad<StaticModel>( (resolve, reject) => {
                _meshes[instanceName].callback.push(resolve);
            });
        }
    }

    let instanceSet: InstanceParams;
    instanceSet = {
        filename: filename,
        instanceName: instanceName,
        instanceCount: 0,
        instanceBufferRef: {
            mcol0: new InstancedBufferAttribute(
                new Float32Array( _minBufferSize * 4 ), 4, 1),
            mcol1: new InstancedBufferAttribute(
                new Float32Array( _minBufferSize * 4 ), 4, 1),
            mcol2: new InstancedBufferAttribute(
                new Float32Array( _minBufferSize * 4 ), 4, 1)
        },
        indicesMap: {},
        object: null,
        objects: [],
        callback: []
    };

    // assign
    _meshes[instanceName] = instanceSet;

    //TODO: preload materials
    return loadModel(filename, undefined, false, false).then((model) => {
        if(model) {
            instanceSet.object = model;

            if(_gpuInstancing) {

                const mcol0 = instanceSet.instanceBufferRef.mcol0;
                const mcol1 = instanceSet.instanceBufferRef.mcol1;
                const mcol2 = instanceSet.instanceBufferRef.mcol2;

                model.setMaterialRefs(materialRefs);
                model.setInstancing(instanceSet.instanceCount, [ {name: "mcol0", buffer: mcol0}, {name: "mcol1", buffer: mcol1}, {name: "mcol2", buffer: mcol2}]);
                model.setInstanceCount(instanceSet.instanceCount);

                // add to scene
                if(instanceSet.instanceCount) {
                    _wrapperEntity.add(model.getHierarchy());
                }
            } else {
                model.setMaterialRefs(materialRefs);

                for(const entry of instanceSet.objects) {
                    // FIXME: clone?!

                    loadModel(filename, undefined, false, false).then((staticModel) => {

                        staticModel.setMaterialRefs(materialRefs);

                        entry.add(staticModel.getHierarchy());
                    });

                }

            }

        }

        // call waiting callbacks
        for(const callback of instanceSet.callback) {
            callback(model);
        }

        return model;
    });
}

/**
 * Creation of the InstanceObject for the pool and call for creation of the instance
 * @param filename name of primitive
 * @param mesh primitive mesh created by component directly if no mesh is attached
 * @param transform transform of entity attached to it
 * @param instanceName name of instance in pool
 */
function registerMesh(name:string, mesh:Mesh, materialRefs?:MaterialRef[]) {

    // already registered
    if(_meshes[name]) {
        return;
    }

    let instanceSet: InstanceParams;
    instanceSet = {
        filename: name,
        instanceName: name,
        instanceCount: 0,
        instanceBufferRef: {
            mcol0: new InstancedBufferAttribute(
                new Float32Array( _minBufferSize * 4 ), 4, 1),
            mcol1: new InstancedBufferAttribute(
                new Float32Array( _minBufferSize * 4 ), 4, 1),
            mcol2: new InstancedBufferAttribute(
                new Float32Array( _minBufferSize * 4 ), 4, 1)
        },
        indicesMap: {},
        object: null,
        objects: [],
        callback: []
    };

    // assign
    _meshes[name] = instanceSet;
    instanceSet.object = mesh;

    if(_gpuInstancing) {
        const mcol0 = instanceSet.instanceBufferRef.mcol0;
        const mcol1 = instanceSet.instanceBufferRef.mcol1;
        const mcol2 = instanceSet.instanceBufferRef.mcol2;

        mesh.setMaterialRef(materialRefs);
        mesh.setInstancing(instanceSet.instanceCount, [ {name: "mcol0", buffer: mcol0}, {name: "mcol1", buffer: mcol1}, {name: "mcol2", buffer: mcol2}]);
        mesh.setInstanceCount(instanceSet.instanceCount);

        //TODO: has to be instanced buffer geometry
        // const geometry = mesh.geometry as InstancedBufferGeometry;

        // geometry.addAttribute("mcol0", mcol0);
        // geometry.addAttribute("mcol1", mcol1);
        // geometry.addAttribute("mcol2", mcol2);

        // add to scene
        if(instanceSet.instanceCount) {
            _wrapperEntity.add(mesh);
        }
    } else {
        // TODO: clone support
        for(const entry of instanceSet.objects) {
            entry.add(mesh.clone());
        }
    }

}

function registerInstance(name:string, transform:Matrix4) {
    if(!_meshes[name]) {
        return 0;
    }

    // call before adding instance to model
    const id = _registerInstanceGeneric(name);

    // add to existing model
    _addInstance(id, transform);

    //FIXME: not needed
    updateInstance(id, transform);

    return id;
}

function removeInstance(id:ComponentId) {
    if(!_validateId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);
    const instanceName = _instances[index].instanceRef;

    _removeInstanceObject(id, instanceName);

    // cleanup
    _instances[index].id = 0;
    _instances[index].instanceRef = null;

    // increase version
    _version = (_version + 1) & 0x000000FF;
}

/**
 * Check if the name corresponds to anything in the pool
 * @param name
 */
function isAttached(name:string) : boolean {
    return (_meshes[name] !== undefined);
}

/**
 * Get Mesh from pool based on primitive name
 * @param name name of primitive (string)
 */
function getMesh(name:string) : Mesh | StaticModel {
    if(_meshes[name] !== undefined) {
        return _meshes[name].object;
    } else {
        return null;
    }
}

/**
 * Called by component on update of Entity transform. Applies new transform to geometry
 * @param indexMap index of instance in Buffer
 * @param filename name of instance in poolMesh
 * @param transform new transform
 */
function updateInstance(id: ComponentId, transform: Matrix4) {

    const instanceIndex = componentIdGetIndex(id);
    const instanceName = _instances[instanceIndex].instanceRef;

    const instanceData = _meshes[instanceName];
    const index = instanceData.indicesMap[id];

    if(_gpuInstancing) {

        if(instanceData) {
            const mcol0 = instanceData.instanceBufferRef.mcol0;
            const mcol1 = instanceData.instanceBufferRef.mcol1;
            const mcol2 = instanceData.instanceBufferRef.mcol2;

            mcol0.setXYZW( index, transform.elements[0], transform.elements[1], transform.elements[2], transform.elements[12]);
            mcol1.setXYZW( index, transform.elements[4], transform.elements[5], transform.elements[6], transform.elements[13] );
            mcol2.setXYZW( index, transform.elements[8], transform.elements[9], transform.elements[10], transform.elements[14] );

            mcol0.needsUpdate = true;
            mcol1.needsUpdate = true;
            mcol2.needsUpdate = true;
        }
    } else {
        // get object and update matrix directly
        const data = instanceData.objects[index];

        if(data) {
            transform.decompose(data.position, data.quaternion, data.scale);
            data.updateTransform();
        }
    }

}

/**
 * Create new instance of already existing model/mesh
 * @param indexMap ID (unique) of the InstancingComponent
 * @param modelSet Instance data object
 * @param transform Transform of the entity carrying the new component
 */
function _addInstance(id:ComponentId, transform: Matrix4) {

    const instanceIndex = componentIdGetIndex(id);
    const instanceName = _instances[instanceIndex].instanceRef;

    const instanceData = _meshes[instanceName];

    if(_gpuInstancing) {

        const oldBuffer = instanceData.instanceBufferRef.mcol0;
        const oldSize = oldBuffer.count;

        if(instanceData.instanceCount+1 < oldBuffer.count) { // if the buffer still has room, add directly in buffer

            // add to entry
            const index = instanceData.instanceCount;
            instanceData.indicesMap[id] = index;

            const mcol0 = instanceData.instanceBufferRef.mcol0;
            const mcol1 = instanceData.instanceBufferRef.mcol1;
            const mcol2 = instanceData.instanceBufferRef.mcol2;

            mcol0.setXYZW( index, transform.elements[0], transform.elements[1], transform.elements[2], transform.elements[12]);
            mcol1.setXYZW( index, transform.elements[4], transform.elements[5], transform.elements[6], transform.elements[13] );
            mcol2.setXYZW( index, transform.elements[8], transform.elements[9], transform.elements[10], transform.elements[14] );

            mcol0.needsUpdate = true;
            mcol1.needsUpdate = true;
            mcol2.needsUpdate = true;

            instanceData.instanceCount++;

            // object is loaded
            if(instanceData.object) {
                instanceData.object.setInstanceCount(instanceData.instanceCount);
            }

        } else { // allocate new space in the buffer

            const mcol0 = instanceData.instanceBufferRef.mcol0 as InstancedBufferAttribute;
            const mcol1 = instanceData.instanceBufferRef.mcol1 as InstancedBufferAttribute;
            const mcol2 = instanceData.instanceBufferRef.mcol2 as InstancedBufferAttribute;

            const mcol0Array = new Float32Array( oldSize * 2 * 4 );
            mcol0Array.set(mcol0.array);

            const mcol1Array = new Float32Array( oldSize * 2 * 4 );
            mcol1Array.set(mcol1.array);

            const mcol2Array = new Float32Array( oldSize * 2 * 4 );
            mcol2Array.set(mcol2.array);

            mcol0.setArray(mcol0Array);
            mcol1.setArray(mcol1Array);
            mcol2.setArray(mcol2Array);

            mcol0.needsUpdate = true;
            mcol1.needsUpdate = true;
            mcol2.needsUpdate = true;

            return _addInstance(id, transform);
        }

        // newly added
        if(instanceData.instanceCount === 1 && instanceData.object) {
            const model = instanceData.object;

            if(model instanceof StaticModel) {
                _wrapperEntity.add(model.getHierarchy());
            } else {
                _wrapperEntity.add(model);
            }
        }
    } else {
        // add to entry
        const index = instanceData.instanceCount;
        instanceData.indicesMap[id] = index;
        instanceData.instanceCount++;

        // set object reference
        if(!instanceData.objects[index]) {
            instanceData.objects[index] = new Entity(instanceName + "_" + index);
            _wrapperEntity.add(instanceData.objects[index]);
        } else {
            instanceData.objects[index].name = instanceName + "_" + index;
        }

        transform.decompose(instanceData.objects[index].position, instanceData.objects[index].quaternion, instanceData.objects[index].scale);
        instanceData.objects[index].updateTransform();

        // add real instance of object (when already loaded)
        //TODO: cloning ?! for better load performance

        const model = instanceData.object;
        if(model && model instanceof StaticModel) {
            //TODO: preload materials
            loadModel(instanceData.filename, undefined, false, false).then((staticModel) => {
                if(staticModel) {
                    //TODO: check if index is valid anymore

                    // add to scene
                    if(instanceData.instanceCount) {
                        instanceData.objects[index].add(staticModel.getHierarchy());
                    }
                }
            });
        } else if(model && model instanceof Mesh) {
            // TODO: clone support
            instanceData.objects[index].add(model.clone());
        }

    }
}

/** create new collision object entry */
function _registerInstanceGeneric(name:string) : ComponentId {
    let index = -1;

    for(let i = 0; i < _instances.length; ++i) {
        if(!_instances[i].id) {
            index = i;
            break;
        }
    }

    // new entry
    if(index === -1) {
        index = _instances.length;
        _instances[index] = {
            id: 0,
            instanceRef: name
        };
    }

    _instances[index].id = createComponentId(index, _version);
    _instances[index].instanceRef = name;

    return _instances[index].id;
}

/**
 * Remove instance with specific ID
 * @param filename name of instance in poolMesh
 * @param indexMap ID (unique) of the InstancingComponent
 */
function _removeInstanceObject(id: ComponentId, name: string) {

    const instanceData = _meshes[name];

    if(_gpuInstancing) {

        if(instanceData && instanceData.indicesMap[id] !== undefined) {
            if(instanceData.instanceCount === 1) {
                // delete instance completely and remove model from scene
                // if(_isModelInstance(modelSet)) {
                //     instanceData.model.destroy();
                // } else {
                //     (modelSet as PrimitiveInstanceParams).primitive.destroy();
                // }
                delete instanceData.indicesMap;
                delete _meshes[name];

            } else {
                const idBuffer = instanceData.indicesMap[id];

                const mcol0 = instanceData.instanceBufferRef.mcol0 as InstancedBufferAttribute;
                const mcol1 = instanceData.instanceBufferRef.mcol1;
                const mcol2 = instanceData.instanceBufferRef.mcol2;

                for(const otherId in instanceData.indicesMap) {
                    if(instanceData.indicesMap[otherId] <= idBuffer) {
                        continue;
                    }

                    const oldIndex = instanceData.indicesMap[otherId];
                    const newIndex = instanceData.indicesMap[otherId] = oldIndex - 1;

                    mcol0.setXYZW(newIndex, mcol0.getX(oldIndex), mcol0.getY(oldIndex), mcol0.getZ(oldIndex), mcol0.getW(oldIndex));
                    mcol1.setXYZW(newIndex, mcol1.getX(oldIndex), mcol1.getY(oldIndex), mcol1.getZ(oldIndex), mcol1.getW(oldIndex));
                    mcol2.setXYZW(newIndex, mcol2.getX(oldIndex), mcol2.getY(oldIndex), mcol2.getZ(oldIndex), mcol2.getW(oldIndex));
                }

                delete instanceData.indicesMap[id];

                instanceData.instanceCount--;
                instanceData.object.setInstanceCount(instanceData.instanceCount);
            }
        }
    } else {

        if(instanceData && instanceData.indicesMap[id] !== undefined) {
            if(instanceData.instanceCount === 1) {
                // delete instance completely and remove model from scene
                // if(_isModelInstance(modelSet)) {
                //     instanceData.model.destroy();
                // } else {
                //     (modelSet as PrimitiveInstanceParams).primitive.destroy();
                // }
                delete instanceData.indicesMap;
                delete _meshes[name];

            } else {
                const bufferIndex = instanceData.indicesMap[id];
                // remap
                for(const otherId in instanceData.indicesMap) {
                    if(instanceData.indicesMap[otherId] <= bufferIndex) {
                        continue;
                    }

                    const oldIndex = instanceData.indicesMap[otherId];
                    const newIndex = instanceData.indicesMap[otherId] = oldIndex - 1;

                    // apply to object
                    instanceData.objects[newIndex].position.copy(instanceData.objects[oldIndex].position);
                    instanceData.objects[newIndex].quaternion.copy(instanceData.objects[oldIndex].quaternion);
                    instanceData.objects[newIndex].scale.copy(instanceData.objects[oldIndex].scale);
                    instanceData.objects[newIndex].updateTransform();
                }

                // remove entity
                const entity = instanceData.objects.pop();
                entity.destroy();

                delete instanceData.indicesMap[id];

                instanceData.instanceCount--;
            }
        }
    }
}

/** valid component id */
function _validateId(id:ComponentId) {
    const index = componentIdGetIndex(id);
    if(index >= 0 && index < _instances.length) {
        return _instances[index].id === id;
    }
    return false;
}

const inputSystem:IInstancingSystem = {
    init: init,
    destroy: destroy,
    getMesh,
    isAttached,
    registerInstance,
    registerMesh,
    registerModel,
    removeInstance,
    updateInstance
};

registerAPI<IInstancingSystem>(INSTANCINGSYSTEM_API, inputSystem);
