///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class LightboxComponent extends AbstractBlockComponent {

    private static _lightbox:any;

    protected onInit():void{
        console.log("LightboxComponent",this._block.domElement);

        let contents = this._block.domElement.querySelectorAll('.media_content');
        let options = {
            beforeOpen: () =>{
                return window.innerWidth >= 640;
            }
        };

        _.each(contents, (content) => {
            let picture = content.querySelector("picture");
            if(picture) {
                let url = picture.getAttribute("data-imgraw");
                $(content).featherlight(url, options);
            }
        });

        this.state = ComponentState.READY;
    }

    protected onDestroy():void{
        this.state = ComponentState.DESTROYED;
    }
}