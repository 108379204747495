/**
 * World.ts: World Logic
 *
 * [[include:sourceDoc/World.md]]
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Mesh as THREEMesh } from "../../lib/threejs/objects/Mesh";
import { UVMapping, EquirectangularReflectionMapping, MirroredRepeatWrapping, CubeReflectionMapping, CubeRefractionMapping, EquirectangularRefractionMapping } from "../../lib/threejs/constants";
import { Scene } from "../../lib/threejs/scenes/Scene";
import { Vector4 } from "../../lib/threejs/math/Vector4";
import { Object3D } from "../../lib/threejs/core/Object3D";
import { Color } from "../../lib/threejs/math/Color";
import { OrthographicCamera } from "../../lib/threejs/cameras/OrthographicCamera";
import { PlaneBufferGeometry } from "../../lib/threejs/geometries/PlaneGeometry";
import { SphereBufferGeometry } from "../../lib/threejs/geometries/SphereGeometry";
import { Fog } from "../../lib/threejs/scenes/Fog";
import {build} from '../core/Build';
import {EventNoArg} from '../core/Events';
import {destroyObject3D, GraphicsDisposeSetup} from '../core/Globals';
import {IONotifier, attachAsyncToNotifier, registerLoadResolver} from '../io/Interfaces';
import {AsyncLoad} from '../io/AsyncLoad';
import {constructComponent} from './Component';
import {AssetManager} from './AssetManager';
import {ShaderLibrary} from '../render/ShaderLibrary';
import {Render} from '../render/Render';
import {ERenderLayer} from '../render/Layers';
import {appGet} from "./App";
import {tick} from './Tick';
import {Entity} from './Entity';
import { Mesh } from "../render/Mesh";
import { PhysicalCamera, RedCamera } from "../render/Camera";
import { ShaderVariant } from "../render/Shader";
import { RenderState } from "../render/State";
import { queryTextureSystem, getImportSettingsTexture } from "./TextureAPI";
import { WorldSystemRegister } from "./System";
import { BackgroundMode, EnvironmentSetup, WorldFile, PreloadedWorld, WorldFileNode } from "../framework-types/WorldFileFormat";
import { math } from "../core/Math";
import { IWorld, WorldEnvironment, WorldSystem, WORLD_API } from "./WorldAPI";
import { queryPrefabSystem } from "./PrefabAPI";
import { ERayCastQuery, CollisionResult, ICollisionSystem, COLLISIONSYSTEM_API } from "./CollisionAPI";
import { queryRenderSystem, IRenderSystem, RENDERSYSTEM_API } from "./RenderAPI";
import { IComponentUpdateSystem, queryComponentUpdateSystem, COMPONENTUPDATESYSTEM_API } from "./UpdateAPI";
import { ISpatialSystem, querySpatialSystem, SPATIALSYSTEM_API } from "./SpatialAPI";
import { ILightSystem, queryLightSystem, LIGHTSYSTEM_API } from "./LightAPI";
import { queryInputSystem, IInputSystem, INPUTSYSTEM_API } from "./InputAPI";
import { queryTaggingSystem, ITaggingSystem, TAGGINGSYSTEM_API } from "./TaggingAPI";
import { IInstancingSystem, queryInstancingSystem, INSTANCINGSYSTEM_API } from "./InstancingAPI";
import { queryMeshSystem } from "./MeshAPI";
import { PluginId, registerAPI } from "../plugin/Plugin";

// DEFAULT APIS
import "../framework-apis/PrefabLibrary";

//BUILTIN SYSTEMS
import '../framework-systems/ComponentUpdateSystem';
import '../framework-systems/InputPropogate';
import "../framework-systems/InstanceSystem";
import "../framework-systems/TaggingSystem";
import "../framework-systems/SpatialSystem";
import "../framework-systems/LightSystem";
import "../framework-systems/RenderSystem";

// BUILTIN SHADER (auto include)
import "../render/shader/Background";
import { createEnvironment, cleanupEnvironment } from "./EnvironmentBuilder";

interface SystemEntry {
    api: number;
    system: WorldSystem;
    initialized: boolean;
}

interface NodeConstruction extends WorldFileNode {
    entity:Entity;
}

export type EntityCallback = (entity:Entity) => void;

/** world loaded event */
const OnWorldLoaded:EventNoArg = new EventNoArg();
/** world destroyed event */
const OnWorldDestroyed:EventNoArg = new EventNoArg();

/** world has environment (renderer clears to this) */
function hasEnvironment() : boolean {
    return _environment !== null && _environment !== undefined;
}

/** setup environment of this world */
function setEnvironment(value:EnvironmentSetup) {
    _processEnvironment(value);
}

/** get setup environment of this world */
function getEnvironment():EnvironmentSetup {
    return _internalEnvironment;
}

/** root entities */
function getEntities() : Array<Entity> {
    if(!_scene) {
        return [];
    }
    return _scene.children.filter( (value) => {
        return value['isEntity'] === true && value['hideInHierarchy'] !== true;
    }) as Entity[];
}

/** world validation */
function isValid() : boolean {
    return _scene != null && !isLoading();
}

/** loading indicator */
function isLoading() : boolean {
    return _loadingCounter > 0;
}

/** input propogation */
let _input:IInputSystem;
/** scene reference (THREE.JS scene) */
let _scene:Scene = null;
/** environment settings */
let _environment:WorldEnvironment = null;
/** memory saving */
let _envScene:Scene = null;

/** loaded scene data */
let _internalData:WorldFile = null;
let _internalEnvironment:EnvironmentSetup = null;
/** internal loading counter */
let _loadingCounter:number = 0;
let _loadingVersion:number;
/** loading */
let _worldLoader:WorldLoadNotifier;
let _loadingAsync:AsyncLoad<IWorld>;
let _asyncResolver:any = null;
let _isError:boolean = false;
/** last frame transform update */
let _lastTransformUpdate:number;
/** last frame render update */
let _lastFrameRender:number;
/** dirty state */
let _frameDirty:boolean;
/** component update system */
let _updateSystem: IComponentUpdateSystem;
//let _collisionSystem: ICollisionSystem;
let _spatialSystem: ISpatialSystem;
let _instanceSystem: IInstancingSystem;
let _taggingSystem: ITaggingSystem;
let _lightSystem: ILightSystem;
let _renderSystem: IRenderSystem;
let _systems: SystemEntry[];

/** init from THREE.js scene/camera */
function constructor(scene?:Scene) {

    _loadingVersion = 1;
    _worldLoader = null;
    _lastFrameRender = -1;
    _lastTransformUpdate = -1;
    _frameDirty = true;

    if(scene) {
        _scene = scene;
        _scene['_world'] = World;
    } else {
        // default scene
        _scene = new Scene();
        _scene.name = "Scene_Root";
        _scene['_world'] = World;
    }
    _scene.autoUpdate = false;
    // apply new reference
    World.scene = _scene;

    _envScene = new Scene();
    _envScene.name = "environment_root";
    _envScene['_world'] = World;

    _systems = [];
    _initSystems();

    //for THREE.js inspector (chrome extension)
    if(build.Options.development) {
        window['scene'] = _scene;
    }
    // preload empty world
    load("empty");
}

/**
 * destroy world objects
 * @param forceAll force all scene objects to delete (not only entities)
 */
function destroy(forceAll?:boolean, dispose?:GraphicsDisposeSetup) : void {
    _cleanupScene(forceAll === true, dispose);

    // at last remove all systems
    _destroySystems();

    OnWorldDestroyed.trigger();
}

/** update loop */
function think(deltaSeconds:number) {
    // update all components
    _updateSystem.think(deltaSeconds);
}

/** access to systems */
function getSystem<T extends WorldSystem>(api:number|PluginId) {
    let apiId:number;
    if(typeof api === 'number') {
        apiId = api;
    } else {
        apiId = api.api;
    }

    for(let i = 0; i < _systems.length; ++i) {
        if(_systems[i].api === apiId) {
            return _systems[i].system as T;
        }
    }
    return null;
}

/** add a new system */
function registerSystem(api:number|PluginId, instance:WorldSystem) {
    let apiId:number;
    if(typeof api === 'number') {
        apiId = api;
    } else {
        apiId = api.api;
    }

    try {
        let register = true;
        // find already registered system with api
        for(const entry of _systems) {
            if(entry.api === apiId) {
                // not the same instance
                if(entry.system !== instance) {
                    // destroy when already registered
                    if(entry.initialized) {
                        if(entry.system.destroy) {
                            entry.system.destroy();
                        }
                        entry.initialized = false;
                    }
                    // override old
                    entry.system = instance;
                }
                register = false;
            }
        }

        if(register) {
            _systems.push({
                system: instance,
                api: apiId,
                initialized: false
            });
        }
        // now construct all missing ones
        _initSystems();

    } catch(err) {
        console.error(err);
    }
}

/** prepare for rendering */
function preRender(renderer:Render, mainCamera:RedCamera) {
    _conditionalTransformUpdate();

    // call all render entities
    _renderSystem.preRender(renderer, mainCamera);
}

/**
 * render world and entities
 * @param renderer render device to render to
 * @param pipeState render pipeline state to use
 * @param camera optional camera to render to
 */
function render(renderer:Render, mainCamera:RedCamera) {
    //FIXME: make sure this is called ?
    _conditionalTransformUpdate();

    // call all render entities
    _renderSystem.render(renderer, mainCamera);

    // update last frame count
    _lastFrameRender = tick.frameCount;
}

/**
 * render environment setup to pipeline state
 * @param renderer render device
 * @param camera camera to render environment to
 * @param pipeState pipeline state
 * @param environment environment description
 */
function renderEnvironment(renderer:Render, camera:RedCamera, pipeState:RenderState, environment:WorldEnvironment) {

    // get target to render to
    const targetTexture = pipeState ? pipeState.renderTarget : null;
    const targetTextureBind = pipeState.renderTargetBind;
    const overrideShaderVariant = pipeState.overrideShaderVariant;
    //TODO: HDR support
    pipeState.overrideShaderVariant = ShaderVariant.DEFAULT;

    if(environment.backgroundColor) {
        //TODO: add support for alpha??
        let environmentAlpha = 1.0;
        if(environment.backgroundAlpha !== undefined && environment.backgroundAlpha !== null) {
            environmentAlpha = environment.backgroundAlpha;
        }
        renderer.setClearColor(environment.backgroundColor, environmentAlpha);

        // colored clear
        renderer.clear(true, true, true, targetTexture, targetTextureBind.activeCubeFace, targetTextureBind.acitveMipMapLevel);
    } else if(environment.backgroundScene) {

        // setup texture tiling mode
        if(environment.backgroundTexture) {
            const material = environment.backgroundMesh.material;

            if(environment.backgroundTextureMode === BackgroundMode.Tile) {

                const texWidth = environment.backgroundTexture.image.width;
                const texHeight = environment.backgroundTexture.image.height;
                let targetWidth = texWidth;
                let targetHeight = texHeight;

                //TODO: better interface for this...
                // this can be a render target or canvas
                if(pipeState && pipeState.renderTarget) {
                    targetWidth = pipeState.renderTarget.width;
                    targetHeight = pipeState.renderTarget.height;
                } else {
                    //TODO: use renderer internal width/height to see if pixel ratio is used
                    targetWidth = renderer.container.clientWidth * window.devicePixelRatio;
                    targetHeight = renderer.container.clientHeight * window.devicePixelRatio;
                }

                const repeatX = targetWidth / texWidth;
                const repeatY = targetHeight / texHeight;

                material.offsetRepeat = new Vector4(0.0, 0.0, repeatX, repeatY);
                material.uniforms['offsetRepeat'].value = new Vector4(0.0, 0.0, repeatX, repeatY);
            } else if(environment.backgroundTextureMode === BackgroundMode.Cover) {

                let texWidth = environment.backgroundTexture.image.width;
                let texHeight = environment.backgroundTexture.image.height;
                let targetWidth = texWidth;
                let targetHeight = texHeight;

                //TODO: better interface for this...
                // this can be a render target or canvas
                if(pipeState && pipeState.renderTarget) {
                    targetWidth = pipeState.renderTarget.width;
                    targetHeight = pipeState.renderTarget.height;
                } else {
                    //TODO: use renderer internal width/height to see if pixel ratio is used
                    targetWidth = renderer.container.clientWidth * window.devicePixelRatio;
                    targetHeight = renderer.container.clientHeight * window.devicePixelRatio;
                }

                // scale to fit
                if(texWidth < targetWidth && texHeight < targetHeight) {

                    const scaleFactorWidth = targetWidth / texWidth;
                    const scaleFactorHeight = targetHeight / texHeight;

                    if(scaleFactorWidth > scaleFactorHeight) {
                        texWidth = targetWidth;
                        texHeight = texHeight * scaleFactorWidth;
                    } else {
                        texHeight = targetHeight;
                        texWidth = texWidth * scaleFactorHeight;
                    }
                }

                // scale both sizes
                const topOffset = Math.max(0.0, (texHeight - targetHeight) / 2.0 / texHeight);
                const leftOffset = Math.max(0.0, (texWidth - targetWidth) / 2.0 / texWidth);

                material.uniforms['offsetRepeat'].value.set(leftOffset, topOffset, 1.0 - leftOffset * 2.0, 1.0 - topOffset * 2.0);
            }
        } else if(environment.isEnvironmentMap) {
            // copy rotation from other camera
            environment.backgroundCamera.quaternion.copy(camera.quaternion);
            environment.backgroundCamera.matrixWorldNeedsUpdate = true;

            // copy projection settings (FIXME: ignore near and far plane)
            environment.backgroundCamera.near = camera.near;
            environment.backgroundCamera.far = camera.far;
            environment.backgroundCamera.aspect = camera.aspect;
            environment.backgroundCamera.fov = camera.fov;
            environment.backgroundCamera.updateProjectionMatrix();

            environment.backgroundCamera['exposure'] = camera['exposure'];
            environment.backgroundCamera['whitepoint'] = camera['whitepoint'];
        }

        // only depth/stencil
        renderer.clear(false, true, true, targetTexture, targetTextureBind.activeCubeFace, targetTextureBind.acitveMipMapLevel);

        //
        renderer.render(environment.backgroundScene, environment.backgroundCamera, pipeState);
    } else {
        //ERROR
        console.warn("World: invalid environment settings");
    }

    // copy back
    pipeState.overrideShaderVariant = overrideShaderVariant;
}

/**
 * render world only
 * @param renderer render device to render to
 * @param pipeState render pipeline state to use
 * @param camera optional camera to render to
 * @param environment optional environment setup (null for no environment, undefined for default environment)
 */
function renderWorld(renderer:Render, camera:RedCamera, pipeState:RenderState, environment?:WorldEnvironment) {
    pipeState = pipeState || null;
    environment = environment === undefined ? _environment : environment;

    // update light cache
    _lightSystem.updateLightCache(camera);

    // apply environment setup
    if(environment) {

        // render environment
        renderEnvironment(renderer, camera, pipeState, environment);

        // clone pipeState here and reset clear state
        const clearTarget = pipeState.clearTarget;
        const clearDepthStencil = pipeState.clearDepthStencil;

        pipeState.clearTarget = false;
        pipeState.clearDepthStencil = false;

        // render world scene
        renderer.render(_scene, camera, pipeState);

        // set to old values
        pipeState.clearTarget = clearTarget;
        pipeState.clearDepthStencil = clearDepthStencil;

    } else {

        // render world scene
        renderer.render(_scene, camera, pipeState);

    }
}

/**
 * load world
 * @param file filename or preloaded world name
 * @param forceAllCleanup cleanup all scene objects (not only three.js)
 */
function load(file:string, forceAllCleanup?:boolean) {

    if(_loadingCounter !== 0) {
        console.warn("World: loading while loading... failure");
        return AsyncLoad.reject<IWorld>();
    }

    //
    ++_loadingVersion;
    _worldLoader = new WorldLoadNotifier(_loadingVersion);
    _worldLoader.loadingURL = file;
    _worldLoader.startLoading();

    // start background loading
    _loadingAsync = new AsyncLoad<IWorld>((resolve, reject) => {

        // start loading
        _asyncResolver = resolve;

        // cleanup old stuff
        _cleanupScene(forceAllCleanup, undefined);

        // loaded already?
        if(PreloadedWorld[file]) {

            _internalData = PreloadedWorld[file];
            _upgradeLoad();

            // check error state
            if(_isError) {
                console.warn("World: invalid scene data, unknown version", _internalData);
                reject(new Error("World: invalid scene data"));
                return;
            }

            // start preloading
            _processPreload();

            // process scene
            const success = _processScene();

            if(!success) {
                //FIXME: load empty scene?
            }

            // go through all entities and create components
            _initializeComponents();

            _worldLoader.finishLoading();

        } else {

            // dynamic load scene
            AssetManager.loadText(file).then((data) => {
                let success = false;
                if(data) {
                    _internalData = JSON.parse(data);
                    _upgradeLoad();

                    // check error state
                    if(_isError) {
                        console.warn("World: invalid scene data, unknown version", _internalData);
                        reject(new Error("World: invalid scene data"));
                        return;
                    }

                    // start preloading
                    _processPreload();

                    success = _processScene();
                }

                if(!success) {
                    //FIXME: load empty scene?
                }

                // go through all entities and create components
                _initializeComponents();

                _worldLoader.finishLoading();
            }).catch( (err) => {
                reject(err);
            });
        }

    });

    return _loadingAsync;
}

/**
 * find entity by name
 * will return the first found entity
 */
function findByName(name:string, root?:Entity) : Entity {
    // no name, no object
    if(!name) {
        console.warn("World::findByName: no name given");
        return null;
    }

    function recursiveFind(entityRef:Object3D) : Entity {
        if(entityRef['isEntity'] && entityRef.name === name) {
            return entityRef as Entity;
        }

        // child nodes
        if(entityRef.children) {
            let obj:Entity = null;
            for(let i = 0; i < entityRef.children.length; ++i) {
                obj = recursiveFind(entityRef.children[i]);
                if(obj) {
                    return obj;
                }
            }
        }
        return null;
    }

    let entity:Entity = null;

    if(root) {
        // optional root to start search entity for
        entity = recursiveFind(root);

        if(entity) {
            return entity;
        }
    } else {
        // whole world search
        for(let i = 0; i < _scene.children.length; ++i) {
            entity = recursiveFind(_scene.children[i]);

            if(entity) {
                return entity;
            }
        }
    }

    return entity;
}

/**
 * find entities by tag
 * will return the list of entities with this tag
 */
function findByTag(tag:number, root?:Entity) : Entity[] {
    // no name, no object
    if(!tag) {
        console.warn("World::findByName: no name given");
        return [];
    }

    if(root) {
        return root.findByTag(tag, true);
    } else {
        let result:Entity[] = [];
        // whole world search
        for(let i = 0; i < _scene.children.length; ++i) {
            if(_scene.children[i]['isEntity']) {
                const entity = _scene.children[i] as Entity;
                const entities = entity.findByTag(tag, true);

                if(entities.length > 0) {
                    result = result.concat(entities);
                }
            }

        }
        return result;
    }
}

function findByPredicate(callback:(entity:Entity) => boolean, root?:Entity) : Entity[] {
    // no name, no object
    if(!callback) {
        console.warn("World::findByName: no name given");
        return [];
    }

    if(root) {
        return root.findByPredicate(callback, true);
    } else {
        let result:Entity[] = [];
        // whole world search
        for(let i = 0; i < _scene.children.length; ++i) {
            if(_scene.children[i]['isEntity']) {
                const entity = _scene.children[i] as Entity;
                const entities = entity.findByPredicate(callback, true);

                if(entities.length > 0) {
                    result = result.concat(entities);
                }
            }
        }
        return result;
    }
}

/**
 * @see CollisionSystem
 * ray cast against world objects
 * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
 * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
 * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
 * @returns any hit
 */
function rayCastWorld(origin:any, direction:any, query:ERayCastQuery, results:CollisionResult[]) : boolean {
    results = results || [];

    const collisionSystem = getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
    if(!collisionSystem) {
        console.warn("World: no collision system detected");
        return false;
    }

    return collisionSystem.rayCastWorld(origin, direction, query, results);
}

/**
 * @see CollisionSystem
 * ray cast against world objects
 * When using ERayCastQuery.AnyHit this will return any hit detection (results into one object)
 * When using ERayCastQuery.FirstHit this will return many hits where the first one is at index 0
 * When using ERayCastQuery.OnlyBounds all objects got only tested by their bounds
 * @returns any hit
 */
function rayCast(camera:RedCamera, normalizedScreenX:number, normalizedScreenY:number, query:ERayCastQuery, results:CollisionResult[]) : boolean {
    results = results || [];

    const collisionSystem = getSystem<ICollisionSystem>(COLLISIONSYSTEM_API);
    if(!collisionSystem) {
        console.warn("World: no collision system detected");
        return false;
    }

    return collisionSystem.rayCast(normalizedScreenX, normalizedScreenY, camera, query, results);
}

/**
 * add entity
 */
function addEntity(entity:Entity, parent?:Entity) {

    // add to parent?
    if(parent) {
        parent.add(entity);
    } else {
        _scene.add(entity);
    }
    entity.instantiate();

    // update matrices
    if(parent) {
        parent.updateMatrixWorld(false);
    } else {
        entity.updateMatrixWorld(false);
    }
}

/** remove entity */
function removeEntity(entity:Entity) {
    entity.removeSelf();
}

/**
 * create entity
 */
function instantiateEntity(name:string, parent?:Entity) : Entity {

    const entity = new Entity(name);

    // add to parent?
    if(parent) {
        parent.add(entity);
    } else {
        _scene.add(entity);
    }

    entity.instantiate();

    // update matrices
    if(parent && parent.matrixWorldNeedsUpdate) {
        parent.updateTransform(true);
    } else {
        entity.updateTransform(true);
    }

    return entity;
}

/** remove from scene */
function destroyEntity(entity:Entity, dispose?:GraphicsDisposeSetup) {
    entity.destroy(dispose);
}

/**
 * create entity from prefab file
 */
function instantiatePrefab(name:string, parent:Entity, data?:any, ioNotifier?:IONotifier) : Entity {
    const PrefabManager = queryPrefabSystem();
    const template = PrefabManager.getPrefab(name);

    if(!template) {
        console.warn("World: Prefab with name '" + name + "' not found");
        return null;
    }

    // setup notifier
    const prefabLoader = new WorldLoadNotifier(1);
    prefabLoader.notifier = ioNotifier || null;

    function processNode(node:WorldFileNode|any, parentRef:Entity = null, fileReference?:string) : Entity {

        if(!node || !_validateNode(node)) {
            console.warn("World: invalid scene data, missing node");
            return null;
        }

        // create runtime node
        const entityRef = instantiateEntity(node.name, parentRef);

        // safe reference for later
        node.entity = entityRef;
        // load entity (no components will be initialized)
        entityRef.load(node, fileReference);

        // child nodes
        if(node.children && Array.isArray(node.children)) {
            for(let i = 0; i < node.children.length; ++i) {
                processNode(node.children[i], entityRef, fileReference);
            }
        }

        // add components
        if(node.components && Array.isArray(node.components)) {
            for(let i = 0; i < node.components.length; ++i) {
                const componentData = node.components[i];
                if(componentData.type) {
                    // construct component from data
                    const instanceComponent = constructComponent(componentData.type, componentData.module, entityRef, {}, World);

                    if(instanceComponent) {
                        entityRef.addComponent(instanceComponent);

                        // call load callback
                        instanceComponent.load(componentData, prefabLoader, data);
                    } else {
                        console.warn("World: failed to load component ", componentData);
                    }
                }
            }
        }
        return entityRef;
    }

    prefabLoader.startLoading();

    const filename = PrefabManager.getFileReference(name);

    const entity = processNode(template, parent, filename);

    if(!entity) {
        console.warn("Entity failed to load ", name);
        return null;
    }

    // update matrices
    if(parent) {
        parent.updateTransform(true);
    } else {
        entity.updateTransform(true);
    }

    prefabLoader.finishLoading();
    return entity;
}

/**
 * process preloading requests
 */
function _processPreload() : boolean {

    // preload requests world data
    if(_internalData.preload) {
        const preload = _internalData.preload;

        // models
        if(preload.models && Array.isArray(preload.models)) {
            for(let i = 0; i < preload.models.length; ++i) {
                attachAsyncToNotifier(_worldLoader, queryMeshSystem().preloadModel(preload.models[i]));
            }
        }

        // textures
        if(preload.textures && Array.isArray(preload.textures)) {
            for(let i = 0; i < preload.textures.length; ++i) {
                attachAsyncToNotifier(_worldLoader, queryTextureSystem().preloadTexture(preload.textures[i]));
            }
        }
    }
    return true;
}

/**
 * process scene data
 */
function _processScene() : boolean {

    if(!_internalData) {
        console.warn("World: invalid scene data", _internalData);
        return false;
    }

    if(_internalData.environment) {
        _processEnvironment(_internalData.environment);
    } else {
        // reset background as this forces no clear on framebuffer or target
        _scene.background = null;
    }

    // load world data
    if(_internalData.world && Array.isArray(_internalData.world)) {

        for(let i = 0; i < _internalData.world.length; ++i) {
            _processNode(_internalData.world[i] as NodeConstruction);
        }

    } else {
        console.warn("World: Missing world entry");
    }

    return true;
}

// setup environment from data
function _processEnvironment(environment:EnvironmentSetup) {
    const ioNotifier:IONotifier = {
        startLoading: startLoadingWorld,
        finishLoading: (err) => finishLoadingWorld()
    };

    // save internal stuff FIXME: clone object?
    _internalEnvironment = environment;

    cleanupEnvironment(_environment);
    _environment = null;

    // setup world environment
    createEnvironment(environment, _envScene, ioNotifier, _environment).then( (env) => {
        _environment = env;
    },
    (err) => {
        _environment = null;
    });

    // const sceneHadFog:boolean = _scene ? !!_scene.fog : false;

    // // cleanup old environment setup
    // if(_environment) {
    //     if(_environment.backgroundScene) {
    //         // free all objects from scene
    //         destroyObject3D(_environment.backgroundScene);
    //         // clear references directly
    //         _environment.backgroundScene = null;
    //         _environment.backgroundMesh = null;
    //     }
    //     _environment = null;
    // }

    // // custom background color
    // if(environment.color) {
    //     _environment = {
    //         backgroundColor: new Color().fromArray(environment.color),
    //         backgroundAlpha: environment.alpha,
    //         backgroundScene: null,
    //         backgroundCamera: null,
    //         backgroundMesh: null
    //     };
    // } else if(environment.texture) {
    //     startLoadingWorld();

    //     queryTextureSystem().createTexture(environment.texture, undefined, undefined).then((texture) => {

    //         _environment = {
    //             backgroundColor: null,
    //             backgroundAlpha: null,
    //             backgroundScene: null,
    //             backgroundCamera: null,
    //             backgroundMesh: null
    //         };

    //         const template = {
    //             shader: "redBackground",
    //             map: environment.texture,
    //             offsetRepeat: [0.0,0.0,1.0,1.0]
    //         };

    //         if(environment.textureMode === BackgroundMode.Tile) {
    //             template.offsetRepeat = [0.0, 0.0, 2.0, 2.0];
    //         }

    //         // setup camera
    //         _environment.backgroundCamera = new OrthographicCamera( - 1, 1, 1, - 1, 0, 1 );
    //         _environment.backgroundTexture = texture;
    //         _environment.backgroundTextureMode = environment.textureMode;
    //         // setup scene
    //         _environment.backgroundScene = _envScene;
    //         _environment.backgroundMesh = new Mesh(
    //             new PlaneBufferGeometry( 2, 2 ),
    //             template
    //         );
    //         _environment.backgroundMesh.frustumCulled = false;
    //         _environment.backgroundScene.add(_environment.backgroundMesh);

    //         finishLoadingWorld();
    //     },
    //     (err) => {

    //         // handle error
    //         console.warn("World: invalid environment texture ", environment.texture);
    //         _environment = null;

    //         finishLoadingWorld();
    //     });
    // } else if(environment.customMaterialShader) {
    //     _environment = {
    //         backgroundColor: null,
    //         backgroundAlpha: null,
    //         backgroundScene: null,
    //         backgroundCamera: null,
    //         backgroundMesh: null
    //     };

    //     // setup camera
    //     _environment.backgroundCamera = new OrthographicCamera( - 1, 1, 1, - 1, 0, 1 );

    //     // setup scene
    //     _environment.backgroundScene = _envScene;

    //     _environment.backgroundMesh = new THREEMesh(
    //         new PlaneBufferGeometry( 2, 2 ),
    //         environment.customMaterialShader
    //     );
    //     _environment.backgroundMesh.frustumCulled = false;
    //     _environment.backgroundScene.add(_environment.backgroundMesh);
    // } else {
    //     //FIXME:
    //     _environment = null;
    // }

    // // not else if as onlyMaterials can be set and
    // // the user can set the background color optionally
    // if(environment.envMap) {
    //     startLoadingWorld();

    //     const textureName = environment.envMap;

    //     //TODO: create texture needs cube map support?
    //     //TODO: support for reflection etc.
    //     queryTextureSystem().createTexture(textureName, environment.envMap, undefined).then( (texture) => {

    //         //TODO: remove this as textures should be setup before...
    //         if(texture.mapping === UVMapping) {
    //             texture.mapping = EquirectangularReflectionMapping;
    //             texture.wrapS = MirroredRepeatWrapping;
    //             texture.wrapT = MirroredRepeatWrapping;
    //             texture.flipY = true;
    //         }

    //         _environment = {
    //             backgroundColor: null,
    //             backgroundAlpha: null,
    //             backgroundScene: null,
    //             backgroundCamera: null,
    //             backgroundMesh: null
    //         };

    //         const template = {
    //             shader: "redBackground",
    //             map: textureName,
    //             offsetRepeat: [0.0,0.0,1.0,1.0]
    //         };

    //         const rgbmEncoded = getImportSettingsTexture(environment.envMap).isRGBMEncoded;

    //         let variant = ShaderVariant.DEFAULT;
    //         switch(texture.mapping) {
    //             case CubeReflectionMapping:
    //             case CubeRefractionMapping:
    //                 variant |= rgbmEncoded ? (ShaderVariant.CUBE | ShaderVariant.HDR) : ShaderVariant.CUBE;
    //                 break;
    //             case EquirectangularReflectionMapping:
    //             case EquirectangularRefractionMapping:
    //                 variant = rgbmEncoded ? (ShaderVariant.EQUIRECT | ShaderVariant.HDR) : ShaderVariant.EQUIRECT;
    //                 break;
    //             default:
    //                 // default cube
    //                 variant |= rgbmEncoded ? (ShaderVariant.CUBE | ShaderVariant.HDR) : ShaderVariant.CUBE;
    //                 break;
    //         }

    //         // setup scene
    //         _environment.backgroundScene = _envScene;
    //         //FIXME: fov
    //         _environment.backgroundCamera = new PhysicalCamera( 90.0, window.innerWidth / window.innerHeight, 1, 1100 );
    //         _environment.backgroundCamera.name = "BackgroundCamera";
    //         _environment.backgroundCamera.layers.set(ERenderLayer.Background);

    //         const geometry = new SphereBufferGeometry( 500, 60, 40, Math.PI );

    //         // force variant on mesh
    //         _environment.backgroundMesh = new Mesh(geometry, template, variant );
    //         _environment.backgroundMesh.layers.set(ERenderLayer.Background);
    //         _environment.backgroundMesh.frustumCulled = false;
    //         _environment.backgroundScene.add(_environment.backgroundMesh);
    //         _environment.isEnvironmentMap = true;

    //         finishLoadingWorld();
    //     },
    //     (err) => {

    //         // handle error
    //         _environment = null;
    //         console.warn("World: invalid environment texture ", environment.envMap);

    //         finishLoadingWorld();
    //     });

    // } else {
    //     // set shader global environment map
    //     //TODO: no world binding here can cause problems with multiple
    //     // instances
    //     //ShaderLibrary.setGlobalEnvironmentMap(null);
    // }

    //TODO: add support for fog and dynamic reloading
    if(environment.fog) {

        // linear fog
        const near = environment.fog.near || 100.0;
        const far = environment.fog.far || 1000.0;
        const color = environment.fog.color ? environment.fog.color : [1.0, 0.0, 1.0];

        _scene.fog = new Fog(new Color().fromArray(color).getHex(), near, far);

        ShaderLibrary.setUseFog(true);
    } else {
        if(ShaderLibrary.useFog()) {
            ShaderLibrary.setUseFog(false);
        }
    }

}

/** file JSON node validation */
function _validateNode(node:any) {

    if(!node.type) {
        console.warn("World: invalid node data, missing type ", node);
        return false;
    }

    if((node.type === "mesh" || node.type === "model") && (!node.filename || node.filename.length < 1)) {
        console.warn("World: invalid node data, missing filename ", node);
        return false;
    }

    return true;
}

//TODO: put this into World and more these
function _processNode(node:NodeConstruction, parent:Entity = null) : Entity {

    if(!node) {
        console.warn("World: invalid scene data, missing node");
        return null;
    }

    if(!_validateNode(node)) {
        return null;
    }

    let entity:Entity = null;

    // check for prefab
    if(node.name.indexOf("@prefab:") === 0 || node.type === "prefab") {
        // PREFAB
        if(node.type === "prefab") {
            entity = instantiatePrefab(node.name, parent);
        } else {
            entity = instantiatePrefab(node.name.substring(8), parent);
        }
    } else if(node.type === "node") {
        //NORMAL node type

        // new default entity
        entity = new Entity(node.name);

        if(!parent) {
            _scene.add(entity);
        } else {
            //ADD TO PARENT
            parent.add(entity);
        }
        // safe reference for later
        node.entity = entity;
        // load entity (no components will be initialized)
        entity.load(node);
    }

    if(!entity) {
        //WARNING
        console.warn("World: cannot load node ", node);
        return null;
    }

    // child nodes
    if(node.children && Array.isArray(node.children)) {
        for(let i = 0; i < node.children.length; ++i) {
            _processNode(node.children[i] as NodeConstruction, entity);
        }
    }

    return entity;
}

/** helper function to visualize entity tree */
function _printSceneTree() {

    function printEntity(entity:Entity, delimitier:number) {
        let tab = '';
        for(let i = 0; i < delimitier; ++i) {
            tab += '\t';
        }
        const type = entity.isEntity ? "Entity" : "Object3D";
        console.log(tab + "- " + entity.name + " (" + type + ") ("+ entity.position.x + " " + entity.position.y + " " + entity.position.z + ") ("+ math.toDegress(entity.rotation.x) + " " + math.toDegress(entity.rotation.y) + " " + math.toDegress(entity.rotation.z) + ")");
        if(!entity.world) {
            console.log(tab + " - world reference failed");
        }

        // geometry object
        if(entity['material']) {
            console.log(tab + " - mesh: renderOrder("+entity.renderOrder+")  visible("+entity.visible+")");
        }

        if(entity.childrens) {
            for(let i = 0; i < entity.childrens.length; ++i) {
                printEntity(entity.childrens[i], delimitier + 1);
            }
        }

    }
    console.log("World Entity Tree: ");
    for(let i = 0; i < _scene.children.length; ++i) {
        if(_scene.children[i]['isEntity']) {
            printEntity(_scene.children[i] as Entity, 0);
        }
    }
}

// cleanup scene
function _cleanupScene(forceAll:boolean, dispose:GraphicsDisposeSetup) {

    // clear environment stuff
    if(_environment) {
        if(_environment.backgroundScene) {
            destroyObject3D(_environment.backgroundScene);
            // clear references directly
            _environment.backgroundScene = null;
            _environment.backgroundMesh = null;
        }
        _environment = null;
    }

    // destroy all entities
    for(let i = _scene.children.length - 1; i >= 0; --i) {
        const obj3d = _scene.children[i];
        if(!obj3d['isEntity']) {
            continue;
        }

        const entity = obj3d as Entity;

        if(entity.persistent) {
            continue;
        }

        entity.destroy(dispose);
    }

    // clean remaining user added three.js objects
    if(forceAll) {
        destroyObject3D(_scene);
    }

    // flush used gpu memory
    const meshSystem = queryMeshSystem();
    if(meshSystem) {
        meshSystem.flushGPUMemory();
    }
}

/** process something for entire entity tree */
function _recursiveOperation(callback:EntityCallback) {
    function recursive(entity:Entity|any, func:EntityCallback) {

        if(entity.isEntity) {
            func(entity);
        }

        // child nodes
        if(entity.childrens) {
            for(let i = 0; i < entity.childrens.length; ++i) {
                recursive(entity.childrens[i], func);
            }
        }
    }

    for(let i = 0; i < _scene.children.length; ++i) {
        recursive(_scene.children[i], callback);
    }
}

/** IONotifier interface */

/** callback when starting to load entity */
function startLoadingWorld(version?:number) {
    const _app = appGet();
    version = version || _loadingVersion;

    if(_loadingVersion !== version) {
        return;
    }

    //FIXME: check for deferrerd init?
    if(_loadingCounter === 0 && _app) {
        _app.startLoading(true);
    }

    _loadingCounter++;
}

/** callback when finished loading one entity */
function finishLoadingWorld(version?:number) {
    const _app = appGet();
    version = version || _loadingVersion;

    if(_loadingVersion !== version) {
        return;
    }

    _loadingCounter--;

    if(_loadingCounter === 0) {

        if(!_internalData || !_internalData.world) {
            if(_app) {
                _app.finishLoading();
            }
            return;
        }

        // finished loading

        // OnWorldLoaded / _asyncResolver can trigger other resources
        // to load, make sure there is no reentrance
        _loadingVersion++;

        if(build.Options.development || build.Options.debugWorldOutput) {
            console.info("World: successfully loaded.");
        }
        if(build.Options.debugWorldOutput) {
            _printSceneTree();
        }

        // update all scene nodes
        _scene.updateMatrixWorld(true);

        // feedback trigger
        OnWorldLoaded.trigger();

        if(_asyncResolver) {
            _asyncResolver(World);
        }

        if(_loadingCounter > 0) {
            console.warn("World: loading counter increased while finishing loading.");
        }

        // cleanup
        _asyncResolver = null;
        if(_worldLoader) {
            _worldLoader.cleanup();
        }
        _worldLoader = null;

        if(_app) {
            _app.finishLoading();
        }
    }
}

/** initialize components from JSON node */
function _initializeComponents() {

    function recursive(node:NodeConstruction, callback:(node:NodeConstruction) => void) {
        if(callback) {
            callback(node);
        }
        // child nodes
        if(node.children && Array.isArray(node.children)) {
            for(let i = 0; i < node.children.length; ++i) {
                recursive(node.children[i] as NodeConstruction, callback);
            }
        }
    }

    const internalScene = _internalData.world;

    for(let i = 0; i < internalScene.length; ++i) {
        recursive(internalScene[i] as NodeConstruction, (node) => {
            // no prefab construction
            if(node.type === "prefab") {
                return;
            }

            const entity:Entity = node.entity;

            if(!entity) {
                console.warn("Entity failed to load ", node);
                return;
            }

            if(node.components && Array.isArray(node.components)) {

                for(let j = 0; j < node.components.length; ++j) {
                    const componentData = node.components[j];

                    if(componentData.type) {
                        try {

                            //FIXME: remove non enabled objects?
                            //TODO: add to entity but disable component
                            //      need to support, enabling and disabling of components...
                            if(componentData.enabled === false) {
                                console.warn("World: disabled components get not constructed for now.");
                                continue;
                            }

                            // construct component from JSON data
                            const instanceComponent = constructComponent(componentData.type, componentData.module, entity, {}, World);

                            if(instanceComponent) {
                                // add to entity
                                entity.addComponent(instanceComponent);

                                // call load callback
                                instanceComponent.load(componentData, _worldLoader, undefined);
                            } else {
                                console.warn("World: failed to load component ", componentData);
                            }
                        } catch(err) {
                            console.error(err);
                        }
                    }
                }
            }
        });
    }
}

/** make sure this is called once per frame */
function _conditionalTransformUpdate() {
    if(tick.frameCount !== _lastTransformUpdate) {
        // entities are transform dirty
        _frameDirty = Entity.TransformDirty === tick.frameCount ||
                            Entity.HierarchyDirty === tick.frameCount ||
                            Mesh.RenderStateDirty === tick.frameCount;

        //replace auto update
        if(_scene.autoUpdate === false) {
            _scene.updateMatrixWorld(false);
        }

        // update shadow maps
        if(_frameDirty && Render.Main) {
            Render.Main.updateShadowMaps();
            //FIXME: notify components?
            // so reflection probes do know when others have changed?!
        }

        _lastTransformUpdate = tick.frameCount;
    }
}

/** never call on your own */
function _removeEntity(entity:Entity, save:boolean) {

    let i;
    for(i = 0; i < _scene.children.length; ++i) {
        if(_scene.children[i] === entity) {
            _scene.remove(entity);
            i--;
            break;
        }
    }

    if(i === _scene.children.length && !save) {
        console.warn("World::_removeEntity: failed to remove entity ", entity);
    }
}

/** init internal systems */
function _initSystems() {

    if(!_updateSystem) {
        _updateSystem = queryComponentUpdateSystem();
        registerSystem(COMPONENTUPDATESYSTEM_API, _updateSystem);
    }
    if(!_spatialSystem) {
        _spatialSystem = querySpatialSystem();
        registerSystem(SPATIALSYSTEM_API, _spatialSystem);
    }
    if(!_input) {
        _input = queryInputSystem();
        registerSystem(INPUTSYSTEM_API, _input);
    }
    if(!_taggingSystem) {
        _taggingSystem = queryTaggingSystem();
        registerSystem(TAGGINGSYSTEM_API, _taggingSystem);
    }
    if(!_instanceSystem) {
        _instanceSystem = queryInstancingSystem();
        registerSystem(INSTANCINGSYSTEM_API, _instanceSystem);
    }
    if(!_lightSystem) {
        _lightSystem = queryLightSystem();
        registerSystem(LIGHTSYSTEM_API, _lightSystem);
    }
    if(!_renderSystem) {
        _renderSystem = queryRenderSystem();
        registerSystem(RENDERSYSTEM_API, _renderSystem);
    }

    //TODO: !!! DO NOT CONSTRUCT !!!
    // find auto registered systems
    for(const auto in WorldSystemRegister) {
        const registrar = WorldSystemRegister[auto];
        const added = _systems.find( (value) => value.api === registrar.api);
        if(!added) {

            let instance:WorldSystem = null;

            if(registrar.type) {
                instance = Object.create(registrar.type.prototype);
                instance.constructor.apply(instance);
            } else if(registrar.module) {
                instance = registrar.module;
            }

            if(instance) {
                _systems.push({
                    system: instance,
                    initialized: false,
                    api: registrar.api
                });
            }
        }
    }

    for(const entry of _systems) {

        if(entry.initialized) {
            continue;
        }

        try {
            if(entry.system.init) {
                entry.system.init(World);
            }
            entry.initialized = true;
        } catch(err) {
            console.error(err);
            entry.initialized = false;
        }
    }
}

/** cleanup systems */
function _destroySystems() {
    for(const entry of _systems) {
        if(!entry.initialized) {
            continue;
        }

        try {
            if(entry.system.destroy) {
                entry.system.destroy();
            }

            entry.initialized = false;
        } catch(err) {
            console.error(err);
        }
    }
}

/** save world to json */
function saveWorld() {
    const data = {
        __metadata__: {
            format: "scene",
            version : 1001
        },
        preload: {
            models: [],
            textures: []
        },
        environment: {
        },
        camera: {
        },
        world:[
        ]
    };

    // add environment settings
    if(_internalEnvironment) {
        data.environment = _internalEnvironment;
    } else {
        data.environment = {
            color: [1.0, 1.0, 1.0]
        };
    }

    for(const obj of _scene.children) {
        // do not save object 3d stuff
        if(obj['isEntity'] !== true) {
            continue;
        }
        const ent = obj as Entity;

        if(ent.transient || !ent.isEntity) {
            continue;
        }
        data.world.push(ent.save());
    }

    return data;
}

/**
 * statically preload scene file
 * @param file file reference
 */
function Preload(file:string, preloadFiles?:any[]) : AsyncLoad<void> {
    try {
        preloadFiles = preloadFiles || [];
        let sceneData:WorldFile = null;

        if(PreloadedWorld[file]) {
            sceneData = PreloadedWorld[file];

            // first process all preload data
            _PreloadLevel(sceneData, preloadFiles);

            // wait for all files loaded
            return new AsyncLoad<void>( (resolve, reject) => {
                AsyncLoad.all<void>(preloadFiles).then(() => resolve(), reject);
            });

        } else {
            // dynamic load scene
            return AssetManager.loadText(file).then( (text:string) => {
                try {
                    sceneData = JSON.parse(text);

                    // first process all preload data
                    _PreloadLevel(sceneData, preloadFiles);

                    // wait for all files loaded
                    return new AsyncLoad<void>( (resolve, reject) => {
                        AsyncLoad.all<void>(preloadFiles).then(() => resolve(), reject);
                    });
                } catch(err) {
                    console.error(err);
                    return AsyncLoad.resolve<void>();
                }
            }, (err) => console.error).then(() => {
                return AsyncLoad.resolve<void>();
            });
        }
    } catch(err) {
        console.error(err);
    }
    return AsyncLoad.resolve<void>();
}

function _PreloadLevel(sceneData:WorldFile, preloadFiles:any[]) : boolean {
    try {
        // check header
        if(!sceneData.__metadata__ ||
            !(sceneData.__metadata__.version === 1000 || sceneData.__metadata__.version === 1001) ||
            sceneData.__metadata__.format !== "scene") {
            console.warn("World::Preload: invalid scene data, unknown version", sceneData);
            return false;
        }

        // preload requests world data
        if(sceneData.preload) {
            const preload = sceneData.preload;

            // models
            if(preload.models && Array.isArray(preload.models)) {
                for(let i = 0; i < preload.models.length; ++i) {
                    preloadFiles.push(queryMeshSystem().preloadModel(preload.models[i]));
                }
            }

            // textures
            if(preload.textures && Array.isArray(preload.textures)) {
                for(let i = 0; i < preload.textures.length; ++i) {
                    preloadFiles.push(queryTextureSystem().preloadTexture(preload.textures[i]));
                }
            }
        }

        if(sceneData.environment) {
            const environment = sceneData.environment as EnvironmentSetup;

            if(environment.envMap) {
                preloadFiles.push(queryTextureSystem().preloadTexture(environment.envMap));
            }

            if(environment.texture) {
                preloadFiles.push(queryTextureSystem().preloadTexture(environment.texture));
            }
        }

        if(sceneData.world && Array.isArray(sceneData.world)) {
            for(const entity of sceneData.world) {
                Entity.Preload(entity, preloadFiles);
            }
        }

        return true;

    } catch(err) {
        return false;
    }
}

function getInternalEnvironment() {
    return _internalEnvironment;
}

function _upgradeLoad() {
    // error state
    if(!_internalData || !_internalData.__metadata__) {
        _isError = true;
        return;
    }
    // check header
    if(!(_internalData.__metadata__.version === 1000 || _internalData.__metadata__.version === 1001) ||
        _internalData.__metadata__.format !== "scene") {
        console.warn("World: invalid scene data, unknown version", _internalData);
        _isError = true;
        return;
    }
    // for old version, we create a camera component
    if(_internalData.__metadata__.version === 1000) {
        console.warn("World: outdated version: " + _internalData.__metadata__.version);

        _internalData.world.push({
            type: 'node',
            name: "mainCamera",
            flags: 0,
            components: [
                { module: "RED", type: "CameraComponent", parameters: {
                    main: true,
                    fov: 90.0,
                    near: 0.1,
                    far: 1000.0
                }}
            ]
        });
        _internalData.__metadata__.version = 1001;
    }
}

/**
 * helper tool for loading worlds and prefabs
 */
class WorldLoadNotifier implements IONotifier {

    public get version() {
        return this._version;
    }
    public set version(value:number) {
        this._version = value;
    }

    /** world location url */
    public loadingURL:string = null;

    /** optional IONotifier */
    public notifier: IONotifier = null;

    /** loading version */
    private _version:number;

    /** valid state */
    private _valid:boolean;

    /** internal loading counter */
    private _loadingCounter:number;

    constructor(version:number) {
        this._valid = true;
        this._version = version;
        this._loadingCounter = 0;
    }

    public startLoading() {
        if(this._loadingCounter === 0) {
            if(this.notifier) {
                if(this.notifier.startLoading) {
                    this.notifier.startLoading();
                }
            } else if(this._valid) {
                startLoadingWorld(this._version);
            }
        }
        this._loadingCounter++;
    }

    public finishLoading(err?:Error) {
        this._loadingCounter--;
        if(this._loadingCounter === 0 && this._valid) {
            if(this.notifier) {
                if(this.notifier.finishLoading) {
                    this.notifier.finishLoading();
                }
            } else if(this._valid) {
                finishLoadingWorld(this._version);
            }
        }
    }

    public cleanup() {
        this._valid = false;
        this.notifier = null;
    }
}

registerLoadResolver("scene", Preload);

export const World:IWorld = {
    init: constructor,
    OnWorldDestroyed,
    OnWorldLoaded,
    destroy,
    getEnvironment,
    setEnvironment,
    findByName,
    findByPredicate,
    findByTag,
    isFrameDirty: () => {return _frameDirty;},
    getSystem,
    hasEnvironment,
    isLoading: isLoading,
    isValid: isValid,
    load,
    Preload,
    preRender,
    rayCast,
    rayCastWorld,
    registerSystem,
    render,
    renderEnvironment,
    renderWorld,
    scene: _scene,
    think,
    getApp: () => { return appGet(); },
    getEntities,
    _removeEntity,
    destroyEntity,
    instantiateEntity,
    instantiatePrefab,
    addEntity,
    save: saveWorld
};
registerAPI<IWorld>(WORLD_API, World);

// ADD SOME DEBUGGING
World['_printSceneTree'] = _printSceneTree;
