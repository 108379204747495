/**
 * MaterialLibrary.ts: Material code
 *
 * [[include:sourceDoc/Materials.md]]
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { build } from '../core/Build';
import { mergeObject, cloneObject, setKeyValueSafe, parseFileExtension } from '../core/Globals';
import { EventNoArg, EventOneArg, EventTwoArg } from '../core/Events';
import { AsyncLoad } from '../io/AsyncLoad';
import { AssetManager } from './AssetManager';
import { ShaderLibrary } from '../render/ShaderLibrary';
import { MaterialTemplate, MaterialDB, MaterialGroupTemplate, processMaterialDB, restoreMaterialDB, writeToMaterialDB, updateMaterialDB, updateToMaterialDB, MaterialDBSnapshot, snapshotMaterialDB, MaterialDesc, MaterialTemplateNamed, MaterialGroupState } from '../render/Material';
import { hash } from '../core/Hash';
import { registerLoadResolver } from '../io/Interfaces';
import { MaterialAnimation } from '../animation/MaterialAnimation';
import { IMaterialSystem, MATERIALSYSTEM_API } from './MaterialAPI';
import { registerAPI } from '../plugin/Plugin';
import { queryTextureSystem } from './TextureAPI';

/**
 * material library settings
 */
export interface MaterialLibrarySettings {
    defaultDebugMaterial?:string;
    [key:string]:any;
}
//TODO: replace with declare?!
export let MaterialLibSettings: MaterialLibrarySettings;
MaterialLibSettings = MaterialLibSettings || {};

/**
 * default material for missing materials
 * overwrite this when you want to inject your own default material
 */
setKeyValueSafe(MaterialLibSettings, "defaultDebugMaterial", "debug");
/** replacement texture (FIXME: try to remove this...) */
setKeyValueSafe(MaterialLibSettings, "defaultAlbedoTex", "blank.jpg");

/** events */
const OnMaterialChanged = new EventTwoArg<MaterialTemplateNamed, number|undefined>();
const OnMaterialGroupChanged = new EventTwoArg<MaterialGroupTemplate, string|undefined>();
const OnMaterialsLoaded = new EventNoArg();
const OnMaterialGroupsLoaded = new EventNoArg();
/** notify client database has changed */
const MaterialGroupDBChanged = new EventOneArg<string>();

/** database saved as a snapshot */
let _databaseSnapshot:MaterialDBSnapshot;

/** init state */
let _initialized:boolean = false;

/** active material animations */
const _runningAnimations: { [name: string]: MaterialAnimation; } = {};

/**
 * template and runtime material settings
 *
 * #### Example
 * ~~~~
 * RED.MaterialGroupDB["example"] = {
 *      "default": "debug",
 *      "materials" : ["debug", "test"]
 * }
 * ~~~~
 */
export let MaterialGroupDB:{[key:string]:MaterialGroupTemplate} = window['MaterialGroupDB'] || {};

function init() {
    // check for double init
    if(_initialized) {
        return;
    }
    _initialized = true;

    /** material to group list (example) */
    MaterialGroupDB["exampleGroup"] = {
        default: "debug",
        materials : ["debug"]
    };

    // process databases
    processMaterialDB();
    _processMaterialGroupDB();

    // material debug
    if(build.Options.debugMaterialOutput) {
        console.log("MaterialLibrary templates: ", MaterialDB);
        console.log("MaterialLibrary groups: ", MaterialGroupDB);
    }

    _databaseSnapshot = snapshotMaterialDB();

    registerLoadResolver("material", loadMaterialFile);
    registerLoadResolver("materialProvider", loadMaterialFile);
    registerLoadResolver("materialGroup", loadMaterialGroupFile);
}
// auto init
init();

/**
 * flush memory on the gpu,
 * does not destroy memory on client side
 */
function flushGPUMemory() {
    ShaderLibrary.flushGPUMemory();
}

/**
 * flush memory on the cpu and gpu
 * uses snapshot data to restore to any point
 */
function flush() {

    // cleans local databases used by material library
    restoreMaterialDB(_databaseSnapshot);

    ShaderLibrary.flush();
}

/**
 * load plain materials (no group file)
 */
function loadMaterialFile(filename:string, preloadTextures:boolean = false) : AsyncLoad<void> {
    return new AsyncLoad<void>( (resolve, reject) => {
        AssetManager.loadText(filename).then((text) => {
            const assets:AsyncLoad<any>[] = [];

            if(text) {
                const materials = JSON.parse(text);

                let fileType = "material";
                // verify and delete for merge
                if(materials.__metadata__) {
                    // check format
                    if(materials.__metadata__.format !== "material" &&
                        materials.__metadata__.format !== "materialProvider") {
                        reject(new Error("MaterialLibrary: not a material file: " + materials.__metadata__.format));
                        return;
                    }

                    // check version
                    if(materials.__metadata__.format < 1000) {
                        reject(new Error("MaterialLibrary: material file is outdated: " + materials.__metadata__.format));
                        return;
                    }
                    // read file type
                    fileType = materials.__metadata__.format;
                    delete materials.__metadata__;
                }

                if(fileType === "material") {

                    if(!materials.name) {
                        reject(new Error("MaterialLibrary: material file has no name"));
                        return;
                    }

                    let material = materials as MaterialDesc;

                    material = fixUpMaterial(material);
                    if(preloadTextures) {
                        assets.concat(loadMaterial(material));
                    }

                    writeToMaterialDB(material.name, filename, material);

                    if(build.Options.debugMaterialOutput) {
                        console.info("MaterialLibrary: Materials loaded: ", MaterialDB);
                    }

                } else {
                    // material list
                    for(const entry in materials) {
                        let material = materials[entry] as MaterialDesc;

                        material.name = entry;
                        material = fixUpMaterial(material);
                        if(preloadTextures) {
                            assets.concat(loadMaterial(material));
                        }

                        // write to database
                        writeToMaterialDB(entry, filename, material, false, false);
                    }

                    updateMaterialDB();
                    if(build.Options.debugMaterialOutput) {
                        console.info("MaterialLibrary: Materials loaded: ", MaterialDB);
                    }
                }

                if(assets.length > 0) {
                    AsyncLoad.all(assets).then( () => {
                        resolve();
                        OnMaterialsLoaded.trigger();
                    }, reject);
                } else {
                    resolve();
                    OnMaterialsLoaded.trigger();
                }

            } else {
                console.warn("MaterialLibrary: failed to load " + filename);
                reject(new Error("MaterialLibrary: failed to load " + filename));
            }
        },
        (err) => {
            console.warn("MaterialLibrary: failed to load " + filename, err);
            reject(new Error("MaterialLibrary: failed to load " + filename));
        });
    });
}

/**
 * load material group file
 * @param name filename
 */
function loadMaterialGroupFile(name:string) : AsyncLoad<void> {
    return new AsyncLoad<void>( (resolve, reject) => {
        AssetManager.loadText(name).then((text) => {

            if(!text) {
                console.warn("MaterialLibrary: failed to load " + name);
                reject(new Error("MaterialLibrary: failed to load " + name));
                return;
            }

            const materialGroups = JSON.parse(text);

            // verify and delete for merge
            if(materialGroups.__metadata__) {

                // check format
                if(materialGroups.__metadata__.format !== "materialGroup") {
                    reject(new Error("MaterialLibrary: not a material group file"));
                    return;
                }

                // check version
                if(materialGroups.__metadata__.format < 1000) {
                    reject(new Error("MaterialLibrary: material file is outdated"));
                    return;
                }

                delete materialGroups.__metadata__;
            }

            if(materialGroups['name']) {
                const materialGroup:MaterialGroupTemplate = materialGroups as MaterialGroupTemplate;
                // setup initially runtime state
                // default is at startup current
                if(!materialGroup.globalState) {
                    materialGroup.globalState = {
                        current: materialGroup.default
                    };
                }
                materialGroup.state = materialGroup.state || {};

                // set to material group db
                MaterialGroupDB[materialGroup.name] = materialGroups;
            } else {
                for(const groupName in materialGroups) {
                    const materialGroup = materialGroups[groupName] as MaterialGroupTemplate;
                    // setup initially runtime state
                    // default is at startup current
                    if(!materialGroup.globalState) {
                        materialGroup.globalState = {
                            current: materialGroup.default
                        };
                    }
                    materialGroup.state = materialGroup.state || {};

                    // write name
                    materialGroup.name = groupName;

                    // set to material group db
                    MaterialGroupDB[groupName] = materialGroup;

                }
            }

            if(build.Options.debugMaterialOutput) {
                console.info("MaterialLibrary: MaterialGroups loaded: ", MaterialGroupDB);
            }

            // finished
            resolve();

            OnMaterialGroupsLoaded.trigger();
        },
        (err) => {
            console.warn("MaterialLibrary: failed to load " + name, err);
            reject(new Error("MaterialLibrary: failed to load " + name));
        });
    });
}

/**
 * loads all dependency of a material template
 * @param name
 */
function loadMaterial(name:string|MaterialTemplate) : AsyncLoad<void> {
    let template:MaterialTemplate = null;
    if(typeof name === 'string') {
        template = findMaterialByName(name);
    } else {
        template = name;
    }

    if(!template) {
        console.warn("MaterialLibrary: cannot load Material: " + name);
        return AsyncLoad.resolve();
    }

    try {
        const preloadFiles = [];

        for(const key in template) {
            // check for base names
            if(key === "name" || key === "shader") {
                continue;
            }

            //TODO: find shader and evaluate keys with types to know which are files to load...

            const value = template[key];

            if(typeof value === 'string'|| value instanceof String) {
                const ext = parseFileExtension(value.toString());

                if(ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "tga") {
                    preloadFiles.push(queryTextureSystem().preloadTexture(value.toString()));
                }
            }
        }

        return AsyncLoad.all(preloadFiles).then(() => AsyncLoad.resolve<void>());
    } catch(err) {
        console.error(err);
    }
    return AsyncLoad.resolve();
}

/**
 * access a material template
 * these templates are static through app lifetime
 * THESE should not be edited.
 */
function findMaterialByName(name:string, namespace?:string) : MaterialTemplate {
    // check for group
    if(isGroup(name)) {
        return getCurrentGroupMaterial(name, namespace);
    }
    const materialLayout = MaterialDB[name];
    if(!materialLayout) {
        return null;
    }
    return materialLayout;
}

/**
 * template utility function for creating templates
 * @param name template name
 * @param copyToMaterialDB save template in database
 * @param template template to copy from
 */
function createMaterial(name:string, copyToMaterialDB?:boolean, template?:MaterialTemplate) : MaterialTemplate {
    let result:MaterialTemplate = {
        shader: "redUnlit"
    };

    // copy from template
    if(template) {
        //FIXME: copy all
        result = mergeObject(template, {name: name});
    } else {
        result.shader = "redUnlit";
    }

    // apply new template to material database
    if(copyToMaterialDB) {
        writeToMaterialDB(name, "local", result);
    }

    return result;
}

/**
 * create a material group
 * @param name name of group
 * @param copyToMaterialGroupDB
 * @param materials
 * @param defaultMaterial
 */
function createMaterialGroup(name:string, copyToMaterialGroupDB?:boolean, materials?:string[], defaultMaterial?:string) : MaterialGroupTemplate {

    const result:MaterialGroupTemplate = {
        name: name,
        default: defaultMaterial || "",
        materials: materials || []
    };

    if(!result.default && result.materials.length > 0) {
        result.default = result.materials[0];
    }

    // setup initially runtime state
    // default is at startup current
    if(!result.globalState) {
        result.globalState = {
            current: result.default
        };
    }
    result.state = result.state || {};

    // set to material group db
    if(copyToMaterialGroupDB) {
        MaterialGroupDB[name] = result;
    }

    return result;
}

/**
 * returns the current active material name from a group
 */
function getCurrentGroupMaterialName(group:string, namespace?:string) : string {
    const materialGroup = MaterialGroupDB[group];

    if(!materialGroup) {
        console.warn("MaterialLibrary: cannot find material group " + group);
        return "";
    }

    // resolve current used material
    let current:string = null;
    if(namespace && materialGroup.state[namespace]) {
        current = materialGroup.state[namespace].current;
    } else {
        current = materialGroup.globalState.current;
    }

    if(!current) {
        current = materialGroup.default;
        console.warn("MaterialLibrary: cannot find current material in group " + group + "...defaulting to " + current);
    }

    if(!current) {
        console.warn("MaterialLibrary: cannot find current material in group " + group);
        return "";
    }
    return current;
}

/**
 * returns the current active material from a group
 * returns the runtime material template
 * these templates can be edited at runtime through this class
 * notify system when template got modified.
 */
function getCurrentGroupMaterial(group:string, namespace?:string) : MaterialTemplate {
    const materialGroup = MaterialGroupDB[group];

    if(!materialGroup) {
        console.warn("MaterialLibrary: cannot find material group " + group);
        return null;
    }

    // resolve current used material
    let current:string = null;
    if(namespace && materialGroup.state[namespace]) {
        current = materialGroup.state[namespace].current;
    } else {
        current = materialGroup.globalState.current;
    }

    if(!current) {
        current = materialGroup.default;
        console.warn("MaterialLibrary: cannot find current material in group " + group + "...defaulting to " + current);
    }

    if(!current) {
        console.warn("MaterialLibrary: cannot find current material in group " + group);
        return null;
    }

    // find runtime template
    let template = MaterialDB[group];

    // if no template -> generate from static data
    if(!template) {
        let originalTemplate = findMaterialByName(current);

        if(!originalTemplate) {
            console.warn("MaterialLibrary: cannot find material " + current + " in group " + group + " defaulting to " + materialGroup.default);
            originalTemplate = findMaterialByName(materialGroup.default);
        }

        if(!originalTemplate) {
            console.warn("MaterialLibrary: cannot find material " + current + " in group " + group);
            return null;
        }
        // lazy current instance
        template = cloneObject(originalTemplate);
        template.name = group;
        writeToMaterialDB(group, undefined, template, true, false);
    }

    if(!template) {
        console.warn("MaterialLibrary: cannot find material " + current + " in group " + group);
        return null;
    }

    return template;
}

/**
 * access to material group
 */
function findGroupByName(group:string) : MaterialGroupTemplate {
    const materialGroup = MaterialGroupDB[group];

    if(!materialGroup) {
        console.warn("MaterialLibrary: cannot find material group " + group);
        return null;
    }

    return materialGroup;
}

/** name is material group? */
function isGroup(name:string) : boolean {
    return !!MaterialGroupDB[name];
}

/** return true if material is in group */
function isMaterialInGroup(group:string, materialName:string) : boolean {
    const materialGroup = MaterialGroupDB[group];

    if(!materialGroup) {
        return false;
    }

    if(!materialGroup['materials']) {
        return false;
    }

    for(let i = 0; i < materialGroup['materials'].length; ++i) {
        if(materialGroup['materials'][i] === materialName) {
            return true;
        }
    }
    return false;
}

/**
 * switch material in group
 * @param group material group name
 * @param materialName material name in group and/or attached with mesh name (e.g. "test_material" or "test_material@Regenrinne")
 */
function switchMaterialGroup(group:string, materialName:string, time:number = 0.0) : MaterialAnimation|null {
    const materialGroup = MaterialGroupDB[group];

    if(!materialGroup) {
        console.warn("MaterialLibrary: cannot find material group " + group);
        return null;
    }

    // try to resolve material name attached to mesh
    const reference = _parseMaterialNameWithMesh(materialName);

    // overwrite material name
    materialName = reference.material;

    const isValid = isMaterialInGroup(group, materialName);

    if(!isValid) {
        console.warn("MaterialLibrary: material " + materialName + " not in group " + group);
        return null;
    }

    let anim:MaterialAnimation = null;

    if(reference.mesh && reference.mesh.length > 0) {
        // switch namespaces
        for(const mesh of reference.mesh) {
            // on demand state registering
            if(!materialGroup.state[mesh]) {
                materialGroup.state[mesh] = {
                    current: materialGroup.globalState.current
                };
            }
            anim = _switchMaterialGroup(materialGroup.state[mesh], false, materialGroup.name, materialName, time, mesh);
        }
    } else {
        // switch namespaces to global state
        //FIXME: make this optional
        for(const namespace in materialGroup.state) {
            // on demand state registering
            if(!materialGroup.state[namespace]) {
                materialGroup.state[namespace] = {
                    current: materialGroup.globalState.current
                };
            }
            anim = _switchMaterialGroup(materialGroup.state[namespace], false, materialGroup.name, materialName, time, namespace);
        }

        // switch group on global state
        anim = _switchMaterialGroup(materialGroup.globalState, true, materialGroup.name, materialName, time, undefined);
    }

    // notify change
    if(reference.mesh) {
        for(const mesh of reference.mesh) {
            OnMaterialGroupChanged.trigger(materialGroup, mesh);
        }
    } else {
        OnMaterialGroupChanged.trigger(materialGroup, undefined);
    }
    return anim;
}

/**
 * internal group switch on given state
 * @param state group state object (global or namespace)
 * @param global global namespace
 * @param groupName original group name
 * @param materialName material name to switch to
 * @param time animation time
 * @param namespace used namespace
 */
function _switchMaterialGroup(state:MaterialGroupState, global:boolean, groupName:string, materialName:string, time:number, namespace?:string) {
    const lastActive = state.current;
    let namespaceName: string = null;

    if(namespace) {
        namespaceName = groupName + namespace;
    } else {
        namespaceName = groupName;
    }

    // no switch, finished
    if(lastActive === materialName) {
        // hack here -> switching material groups for meshes
        // results into a global material switch
        // calling it twice with other meshes will not process the other meshes

        // return animation when running
        if(_runningAnimations[namespaceName]) {
            return _runningAnimations[namespaceName];
        }
        return null;
    }

    // write back state
    state.current = materialName;

    if(build.Options.debugMaterialOutput) {
        console.log("MaterialLibrary: changed group " + groupName + " to material " + materialName);
    }

    //TODO: make sure materialName is not an group
    const template = findMaterialByName(materialName);
    if(!template) {
        console.error("MaterialLibrary: failed template for material group " + materialName);
        return null;
    }

    // get last template
    const currentTemplate:MaterialTemplate = lastActive ? findMaterialByName(lastActive) : null;
    let animation:MaterialAnimation = null;

    if(time > 0.0) {
        // material animation system uses source name for referencing
        // start animation
        //animation = blendTo(namespaceName, template, time, namespace);

        // already a running animation
        if(_runningAnimations[namespaceName]) {

            animation = _runningAnimations[namespaceName];
            //FIXME: adjust speed?
            animation.time = time || 1.0;

            animation.blendTo(template, time, namespace);

        } else {
            // use group name as name to apply it to group material template
            animation = new MaterialAnimation(groupName, currentTemplate);
            animation.time = time || 1.0;
            animation.autoUpdate = false;
            animation.OnUpdated.on(_templateUpdate);

            _runningAnimations[namespaceName] = animation;

            animation.blendTo(template, time, namespace);
        }
    } else {
        // a animation is running??
        if(hasAnimation(namespaceName)) {
            stopAnimation(namespaceName);
        }

        // transfer data to template
        const groupTemplate = cloneObject(template);
        groupTemplate.name = groupName;
        updateMaterial(groupName, groupTemplate, global, namespace);
    }
    return animation;
}

/**
 * update material library with new template data
 * @param template template data for material
 * @param transferToLocal save to local database (new materials get these changes too)
 */
function updateMaterial(name:string, material:MaterialTemplate, transferToLocal:boolean = true, mesh?:string|string[]) : void {
    transferToLocal = transferToLocal || false;

    if(!material) {
        console.warn("MaterialLibrary::updateMaterial: invalid template");
        return;
    }

    //apply template modification to groups using the same template
    if(transferToLocal) {
        for(const group in MaterialGroupDB) {
            const materialGroup = MaterialGroupDB[group];
            if(!materialGroup) {
                console.warn("MaterialLibrary: Invalid group", materialGroup);
                continue;
            }

            // update material
            if(name === materialGroup.globalState.current) {
                // save to material database
                updateToMaterialDB(materialGroup.name, material);
            }
        }

        // save to material database
        updateToMaterialDB(name, material);
    }

    // notify change (optional only for one mesh)
    if(Array.isArray(mesh)) {
        const namedTemplate = { name, template: material };
        for(const entry of mesh) {
            const nameId = hash(entry);
            OnMaterialChanged.trigger(namedTemplate, nameId);
        }
    } else {
        const nameId = mesh ? hash(mesh) : undefined;
        OnMaterialChanged.trigger({ name, template: material }, nameId);
    }
}

/**
 * notify material library that template has been modified
 * @param name template name
 */
function notifyUpdate(name:string) {
    let material: MaterialTemplate = null;

    // save to material database
    if(MaterialDB[name]) {
        // clone (FIXME: use clone function??)
        material = MaterialDB[name];
    }

    // notify change
    OnMaterialChanged.trigger({name, template: material}, undefined);
}

/** replication */
function save() {
    return {
        MaterialDB: MaterialDB,
        MaterialGroupDB: MaterialGroupDB
    };
}

/** flush all animations that are not running */
function flushAnimations() : void {
    for(const name in _runningAnimations) {
        if(_runningAnimations[name].running) {
            _runningAnimations[name].destroy();
            delete _runningAnimations[name];
        }
    }
}

/** has animation */
function hasAnimation(name:string) : boolean {
    return _runningAnimations[name] !== undefined;
}

/** stop animation with name */
function stopAnimation(name:string) : void {
    if(!name) {
        return;
    }

    if(_runningAnimations[name]) {
        _runningAnimations[name].destroy();
        delete _runningAnimations[name];
    } else {
        console.warn("MaterialAnimationSystem: no animation with name '"+name+"' is running");
    }
}

/**
 * blend source template to destination template
 * applies only to runtime material with name
 * @param name runtime material name to blend
 * @param destination destination template
 * @param time default speed
 * @param mesh optional receiver mesh
 * @return material animation
 */
function blendTo(name:string, destination:MaterialTemplate, time:number=1.0, mesh?:string|string[]) : MaterialAnimation {
    if(!destination || !name) {
        return null;
    }

    let animation:MaterialAnimation = null;
    // already a running animation
    if(_runningAnimations[name]) {

        animation = _runningAnimations[name];
        //FIXME: adjust speed?
        animation.time = time || 1.0;
        animation.pingpong = false;

        animation.blendTo(destination, time, mesh);

    } else {
        animation = new MaterialAnimation(name);
        animation.time = time || 1.0;
        animation.autoUpdate = false;
        animation.OnUpdated.on(_templateUpdate);

        _runningAnimations[name] = animation;

        animation.blendTo(destination, time, mesh);
    }
    return animation;
}

/**
 * blend source template to destination template and back
 * applies only to runtime material with name
 * @param name runtime material name to blend to
 * @param destination destination template
 * @param time default speed
 * @param mesh optional receiver mesh
 * @return material animation
 */
function blink(name:string, destination:MaterialTemplate, time:number=1.0, mesh?:string) : MaterialAnimation {
    if(!destination || !name) {
        return null;
    }

    let animation:MaterialAnimation = null;
    // already a running animation
    if(_runningAnimations[name]) {

        animation = _runningAnimations[name];
        //FIXME: adjust speed?
        animation.time = time || 1.0;
        animation.pingpong = true;

        animation.blendTo(destination, time, mesh);

    } else {
        animation = new MaterialAnimation(name);
        animation.time = time || 1.0;
        animation.autoUpdate = false;
        animation.pingpong = true;
        animation.OnUpdated.on(_templateUpdate);

        _runningAnimations[name] = animation;

        animation.blendTo(destination, time, mesh);
    }
    return animation;
}

/** update template through animation */
function _templateUpdate(anim:MaterialAnimation) {
    updateMaterial(anim.name, anim.value, false, anim.destinationMesh);
}

/** compare defines */
function _compareDefines(definesA:{}, definesB:{}) : boolean {
    let sameDefines = true;
    for(const def in definesA) {
        if(definesA[def] !== definesB[def]) {
            sameDefines = false;
            break;
        }
    }

    for(const def in definesB) {
        if(definesA[def] !== definesB[def]) {
            sameDefines = false;
            break;
        }
    }
    return sameDefines;
}

function _parseMaterialNameWithMesh(name:string) {
    const at = name.indexOf("@");
    if(at === -1) {
        return {material: name, mesh: undefined};
    } else {
        let dot = -1;
        const meshes:string[] = [];
        let meshStr = name.substring(at + 1);

        // tslint:disable-next-line
        while((dot = meshStr.indexOf(",", 0)) !== -1) {
            meshes.push(meshStr.substring(0, dot).trim());
            meshStr = meshStr.substring(dot+1);
        }
        if(meshStr.length) {
            meshes.push(meshStr.trim());
        }

        return {material: name.substring(0, at), mesh: meshes};
    }
}

function _processMaterialGroupDB() {
    for(const groupName in MaterialGroupDB) {
        const group = MaterialGroupDB[groupName];

        // default is at startup current
        // setup initially runtime state
        if(!group.globalState) {
            group.globalState = {
                current: group.default
            };
        }
        group.state = group.state || {};

        // write name
        group.name = groupName;
    }
}

/**
 * helper function to validate material template
 * TODO: more validation
 */
function fixUpMaterial(mat:MaterialDesc) : MaterialDesc {
    // convert old style materials... deprecated
    if(mat['highQuality'] || mat['mediumQuality'] || mat['lowQuality']) {
        const tmp = mat;
        mat = mat['highQuality'] || mat['mediumQuality'] || mat['lowQuality'];
        mat.name = mat.name || tmp.name;
    }

    if(!mat.name) {
        console.warn("Material: no name associated ", mat);
    }
    if(!mat.shader) {
        console.warn("Material: no shader associated ", mat);
    }

    // convert color to array
    if(mat['diffuse'] !== undefined) {

        if(Array.isArray(mat['diffuse']) && mat['diffuse'].length === 1) {
            console.warn("MaterialLibrary: invalid color format", mat);

            let hex:number;
            if(typeof mat['diffuse'][0] === 'string' || mat['diffuse'][0] instanceof String) {
                hex = parseInt(mat['diffuse'][0].replace(/^#/, ''), 16);
            } else {
                hex = mat['diffuse'][0];
            }

            mat['diffuse'] = [( hex >> 16 & 255 ) / 255, ( hex >> 8 & 255 ) / 255, ( hex & 255 ) / 255];
        } else if(!Array.isArray(mat['diffuse'])) {
            console.warn("MaterialLibrary: invalid color format");

            let hex:number;
            if(typeof mat['diffuse'][0] === 'string' || mat['diffuse'][0] instanceof String) {
                hex = parseFloat(mat['diffuse'][0].replace(/^#/, ''));
            } else {
                hex = mat['diffuse'][0];
            }

            mat['diffuse'] = [( hex >> 16 & 255 ) / 255, ( hex >> 8 & 255 ) / 255, ( hex & 255 ) / 255];
        }
    }

    return mat;
}

export const MaterialLibrary:IMaterialSystem = {
    MaterialGroupDBChanged,
    OnMaterialChanged,
    OnMaterialGroupChanged,
    OnMaterialGroupsLoaded,
    OnMaterialsLoaded,
    blendTo,
    blink,
    createMaterial,
    createMaterialGroup,
    findGroupByName,
    findMaterialByName,
    flush,
    flushGPUMemory,
    getCurrentGroupMaterialName,
    getCurrentGroupMaterial,
    hasAnimation,
    init,
    isGroup,
    isMaterialInGroup,
    loadMaterial,
    loadMaterialFile,
    loadMaterialGroupFile,
    stopAnimation,
    save,
    switchMaterialGroup,
    updateMaterial
};
registerAPI(MATERIALSYSTEM_API, MaterialLibrary);
