///<reference path="../js_bindings.d.ts"/>

export class ColorTheme {

    private _style:any;
    // private _colorPosition:number = 0;

    constructor(css:any = null) {
        this._style = document.querySelector('style[title=dyncss]');

        if(css){
            this.apply(css);
        }
    }

    public apply(css:any):void{
        //oldschool
        if (this._style.styleSheet){
            this._style.styleSheet.cssText = css;
        }
        //the new way
        else {
            while (this._style.firstChild){
                this._style.removeChild(this._style.firstChild);
            }

            this._style.appendChild(document.createTextNode(css));
        }
    }
}