/**
 * State.ts: Render state configuration
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { RenderConfiguration } from "./Config";
import { math } from "../core/Math";
import { ShaderVariant } from "./Shader";
import { WebGLRenderTarget, WebGLRenderTargetOptions } from "../../lib/threejs/renderers/WebGLRenderTarget";
import { LinearFilter, RGBAFormat, UnsignedByteType, NearestFilter, ClampToEdgeWrapping } from "../../lib/threejs/constants";
import { WebGLRenderTargetCube } from "../../lib/threejs/renderers/WebGLRenderTargetCube";

export interface RenderTargetBind {
    target: WebGLRenderTarget | WebGLRenderTargetCube;
    acitveMipMapLevel? :number;
    activeCubeFace?: number;
}

/**
 * render state
 * setups pipeline of renderer
 */
export class RenderState {

    //FIXME: add scene and camera??

    /** using render target */
    public renderTarget: any = null;
    public renderTargetBind: RenderTargetBind;
    /** using as read buffer */
    public readTexture: any = null;
    /** global material to use when rendering */
    public overrideMaterial: any = null;
    /** custom shader variant rendering */
    public overrideShaderVariant: ShaderVariant = undefined;

    /** clear setup (TODO: check if wants to clear...) */
    public clearColor?: any;
    public clearAlpha?: number;
    public clearTarget:boolean;
    public clearDepthStencil:boolean;

    /** access to intermediate buffer */
    public postProcessTargets?:any[] = [];
    public postProcessTarget?:number = 0;

    private _renderTargets: WebGLRenderTarget[];
    private _freeTargets: WebGLRenderTarget[];

    /** default initialization */
    constructor() {
        this.clearTarget = this.clearDepthStencil = true;
        this.readTexture = null;
        this.renderTarget = null;
        this.postProcessTargets = null;
        this.postProcessTarget = 0;
        this.renderTarget = null;

        this.renderTargetBind = {
            target: null
        };
        this._renderTargets = [];
        this._freeTargets = [];
    }

    /** cleanup */
    public destroy() {
        console.assert(this._freeTargets.length === this._renderTargets.length, "missing render targets");
        this._freeTargets = [];
        // cleanup
        if(this._renderTargets) {
            for(const target of this._renderTargets) {
                target.dispose();
            }
        }
        this._renderTargets = [];
        // cleanup
        if(this.postProcessTargets) {
            for(const target of this.postProcessTargets) {
                target.dispose();
            }
        }
        this.postProcessTargets = null;
        this.postProcessTarget = 0;
        if(this.renderTarget) {
            this.renderTarget.dispose();
        }
        this.renderTarget = null;
    }

    /** swap read and write buffer */
    public swapPostProcessTargets() {
        this.postProcessTarget = this.postProcessTarget ^ 1;
    }

    /** get current read buffer */
    public get readBuffer() : any {
        if(this.postProcessTargets) {
            return this.postProcessTargets[this.postProcessTarget];
        } else {
            return this.readTexture;
        }
    }

    /** get current write buffer */
    public get writeBuffer() : any {
        if(this.postProcessTargets) {
            return this.postProcessTargets[this.postProcessTarget^1];
        } else {
            return this.renderTarget;
        }
    }

    /** setup from render configuration */
    public setupFromConfiguration(config:RenderConfiguration) : void {

        // cleanup
        if(this.postProcessTargets) {
            for(const target of this.postProcessTargets) {
                target.dispose();
            }
        }
        this.postProcessTargets = [];
        this.postProcessTarget = 0;
        if(this.renderTarget) {
            this.renderTarget.dispose();
        }
        this.renderTarget = null;

        // setup pipeline
        if(config.renderPostProcessing) {

            //TODO: add better support for this
            const parameters = {
                minFilter: LinearFilter,
                magFilter: LinearFilter,
                format: RGBAFormat,
                stencilBuffer: false
            };

            //TODO: check for quality level here
            this.postProcessTargets = [
                new WebGLRenderTarget(config.renderSize.width, config.renderSize.height, parameters),
                new WebGLRenderTarget(config.renderSize.width, config.renderSize.height, parameters)
            ];

            this.postProcessTarget = 0;

            // default render target used for scene rendering
            this.renderTarget = this.postProcessTargets[0];
        }
    }

    /** setup pipeline for rendering to texture */
    public setupRenderToTexture(renderSize:math.Size, params?:WebGLRenderTargetOptions) {
        // cleanup
        if(this.postProcessTargets) {
            for(const target of this.postProcessTargets) {
                target.dispose();
            }
        }
        this.postProcessTargets = null;
        this.postProcessTarget = 0;
        if(this.renderTarget) {
            this.renderTarget.dispose();
        }
        this.renderTarget = null;
        //TODO: add better support for this
        const parameters = params || {
            minFilter: LinearFilter,
            magFilter: LinearFilter,
            format: RGBAFormat,
            stencilBuffer: false
        };

        // default render target used for scene rendering
        this.renderTarget = new WebGLRenderTarget(renderSize.width, renderSize.height, parameters);
    }

    /**
     * retrieve temporary render targets
     */
    public requestTemporaryTarget(renderSize:math.Size, params?:WebGLRenderTargetOptions) : WebGLRenderTarget {
        let target:WebGLRenderTarget = null;
        params = params || {};

        const requestParams:WebGLRenderTargetOptions = {
            depthBuffer: false,
            anisotropy: undefined,
            format: undefined,
            type: UnsignedByteType,
            generateMipmaps: false,
            magFilter: NearestFilter,
            minFilter: NearestFilter,
            wrapS: ClampToEdgeWrapping,
            wrapT: ClampToEdgeWrapping,
            stencilBuffer: false
        };

        // fill in missing values
        if(params.depthBuffer !== undefined) {
            requestParams.depthBuffer = params.depthBuffer;
        }
        if(params.stencilBuffer !== undefined) {
            requestParams.stencilBuffer = params.stencilBuffer;
        }
        if(params.minFilter !== undefined) {
            requestParams.minFilter = params.minFilter;
        }
        if(params.magFilter !== undefined) {
            requestParams.magFilter = params.magFilter;
        }
        if(params.wrapS !== undefined) {
            requestParams.wrapS = params.wrapS;
        }
        if(params.wrapT !== undefined) {
            requestParams.wrapT = params.wrapT;
        }
        if(params.generateMipmaps !== undefined) {
            requestParams.generateMipmaps = params.generateMipmaps;
        }
        if(params.anisotropy !== undefined) {
            requestParams.anisotropy = params.anisotropy;
        }
        if(params.format !== undefined) {
            requestParams.format = params.format;
        }
        if(params.type !== undefined) {
            requestParams.type = params.type;
        }

        // find in free list
        for(let i = this._freeTargets.length - 1; i >= 0; --i) {
            const free = this._freeTargets[i];
            if(free.width !== renderSize.width || free.height !== renderSize.height) {
                continue;
            }
            //TODO: check more parameters
            if(free.texture.type !== requestParams.type) {
                continue;
            }
            //TODO: check more parameters
            if(free.depthBuffer !== requestParams.depthBuffer) {
                continue;
            }
            //FIXME: update free target to these parameters and use it?!
            if(free.texture.minFilter !== requestParams.minFilter || free.texture.magFilter !== requestParams.magFilter) {
                continue;
            }
            if(free.texture.wrapS !== requestParams.wrapS || free.texture.wrapT !== requestParams.wrapT) {
                continue;
            }

            target = this._freeTargets.splice(i , 1)[0];
            break;
        }

        if(!target) {
            target = new WebGLRenderTarget(renderSize.width, renderSize.height, requestParams);
            this._renderTargets.push(target);
        }

        console.assert(this._renderTargets.length < 64, "too many temporary render targets");
        return target;
    }

    /** return temporary render target */
    public returnTemporaryTarget(target: WebGLRenderTarget) {
        console.assert(target, "invalid target");
        this._freeTargets.push(target);
    }

    /**
     * resizes post process targets and render target
     * @param size new size
     */
    public resize(size:math.Size) {
        if(this.postProcessTargets) {
            for(const target of this.postProcessTargets) {
                target.setSize(size.width, size.height);
            }
        }
        if(this.renderTarget) {
            this.renderTarget.setSize(size.width, size.height);
        }
    }
}
