/**
 * Standard.ts: standard (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * metalness -- Metal (0-1)
 * * occRoughMetalMap -- Occlusion (R) Roughness Texture (G)  Metal Texture (B)
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Ambient Occlusion
 * * aoMap -- AO Texture (RGB)
 * * aoMapIntensity -- AO Intensity
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Vector2 } from "../../../lib/threejs/math/Vector2";
import { Vector4 } from "../../../lib/threejs/math/Vector4";
import { Color } from "../../../lib/threejs/math/Color";
import {mergeUniforms, EUniformType} from '../Uniforms';
import { applyShaderToRenderer, setValueShader, ShaderVariant, variantIsSet} from '../Shader';
import { ShaderBuilder, ShaderModule, UniformLib } from "../ShaderBuilder";
import { whiteTexture, normalTexture, blackTexture } from "../Texture";
import { Mesh } from "../Mesh";
import { Line } from "../../render-line/Line";
import { setValueShaderLights } from "../Lights";
import { Render } from "../Render";
import { setValueShaderProbe } from "../LightProbe";
import { ESpatialType, querySpatialSystem } from "../../framework/SpatialAPI";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    // first import code
    shaderBuilder.importCode(["redCommon", "redPrecision", "redFunctions", "redBSDFFunctions", "redLightFunctions", "redPacking",
                                    "redShadowFunctions", "redDirectLight_Standard", "redIndirectLight_Standard", "redStandard_Vertex", "redStandardDisplace_Vertex", "redStandard_Pixel", "redStandardAO_Pixel", "redStandardMasked_Pixel", "redStandard_Diffuse_Pixel"]).then( () => {

        /**
         * physical based shader with lighting and shadow support
         */
        shaderBuilder.createShader("redStandard", {
            redSettings: {
                lights: true,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                order: 0
            },
            selector(variant:ShaderVariant) : string | void {
                // shadow rendering
                if(variantIsSet(ShaderVariant.VSM, variant)) {
                    return "redVSMDepth";
                }
                if(variantIsSet(ShaderVariant.ESM, variant)) {
                    return "redESMDepth";
                }
                if(variantIsSet(ShaderVariant.PCF, variant)) {
                    return "redPCFDepth";
                }
                // deferred rendering
                if(variantIsSet(ShaderVariant.LIGHTING_DIFFUSE, variant)) {
                    return "redStandard_Diffuse";
                }
                if(variantIsSet(ShaderVariant.INDIRECT_SPECULAR, variant)) {
                    return "redStandard_Indirect";
                }
                if(variantIsSet(ShaderVariant.SPECULAR_ROUGHNESS, variant)) {
                    return "redStandard_SpecularRoughness";
                }
            },
            variants: [ShaderVariant.DEFAULT, ShaderVariant.INSTANCED, ShaderVariant.IBL],
            uniforms: mergeUniforms( [
                UniformLib["redLights"],
                UniformLib["lights"],
                UniformLib["fog"],
                UniformLib["sh"],
                UniformLib["hdr"],
                UniformLib["pds"],
                UniformLib["probe"],
                {
                    /** diffuse */
                    baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc)},
                    baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "metalness", material, data.metalness);
                setValueShader(shaderInterface, "roughness", material, data.roughness);
                setValueShader(shaderInterface, "reflectance", material, data.reflectance);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "normalScale", material, data.normalScale);

                // request reflection probe
                const nearestProbe = querySpatialSystem().getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

                // reset program (for globals)
                if(shaderInterface.initial) {
                    // access global parameters
                    setValueShaderLights(shaderInterface, material);
                }

                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

                setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
                setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShader: "redStandard_Pixel"
        });

        /**
         * physical based shader with lighting and shadow support
         */
        shaderBuilder.createShader("redStandardAO", {
            redSettings: {
                lights: true,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                order: 6
            },
            selector(variant:ShaderVariant) : string | void {
                if(variantIsSet(ShaderVariant.VSM, variant)) {
                    return "redVSMDepth";
                }

                if(variantIsSet(ShaderVariant.ESM, variant)) {
                    return "redESMDepth";
                }

                if(variantIsSet(ShaderVariant.PCF, variant)) {
                    return "redPCFDepth";
                }
            },
            variants: [ShaderVariant.DEFAULT, ShaderVariant.INSTANCED, ShaderVariant.IBL],
            uniforms: mergeUniforms( [
                UniformLib["redLights"],
                UniformLib["lights"],
                UniformLib["fog"],
                UniformLib["sh"],
                UniformLib["hdr"],
                UniformLib["pds"],
                UniformLib["probe"],
                {
                    /** diffuse */
                    baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc)},
                    baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** ao map */
                    aoMap : { type: EUniformType.TEXTURE, value: null, default: null },
                    aoMapIntensity : { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "metalness", material, data.metalness);
                setValueShader(shaderInterface, "roughness", material, data.roughness);
                setValueShader(shaderInterface, "reflectance", material, data.reflectance);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "normalScale", material, data.normalScale);

                setValueShader(shaderInterface, "aoMap", material, data.aoMap);
                setValueShader(shaderInterface, "aoMapIntensity", material, data.aoMapIntensity || 1.0);

                // request reflection probe
                const nearestProbe = querySpatialSystem().getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

                // reset program (for globals)
                if(shaderInterface.initial) {
                    // access global parameters
                    setValueShaderLights(shaderInterface, material);
                }

                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

                setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
                setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;
                defines['RED_USE_UV2'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShader: "redStandardAO_Pixel"
        });

        /**
         * physical based shader with lighting and shadow support
         */
        shaderBuilder.createShader("redStandardMasked", {
            redSettings: {
                lights: true,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                alphaToCoverage: true,
                order: 0
            },
            selector(variant:ShaderVariant) : string | void {
                if(variantIsSet(ShaderVariant.VSM, variant)) {
                    return "redVSMDepth";
                }

                if(variantIsSet(ShaderVariant.ESM, variant)) {
                    return "redESMDepth";
                }

                if(variantIsSet(ShaderVariant.PCF, variant)) {
                    return "redPCFDepth";
                }
            },
            variants: [ShaderVariant.DEFAULT, ShaderVariant.INSTANCED, ShaderVariant.IBL],
            uniforms: mergeUniforms( [
                UniformLib["redLights"],
                UniformLib["lights"],
                UniformLib["fog"],
                UniformLib["sh"],
                UniformLib["hdr"],
                UniformLib["pds"],
                UniformLib["probe"],
                {
                    /** diffuse */
                    baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc)},
                    baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "metalness", material, data.metalness);
                setValueShader(shaderInterface, "roughness", material, data.roughness);
                setValueShader(shaderInterface, "reflectance", material, data.reflectance);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "normalScale", material, data.normalScale);

                // request reflection probe
                const nearestProbe = querySpatialSystem().getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

                // reset program (for globals)
                if(shaderInterface.initial) {
                    // access global parameters
                    setValueShaderLights(shaderInterface, material);
                }

                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

                setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
                setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShader: "redStandardMasked_Pixel"
        });

        shaderBuilder.createShaderFrom("redStandard_Displacement", "redStandard", {
            uniforms: mergeUniforms( [
                UniformLib["redLights"],
                UniformLib["lights"],
                UniformLib["fog"],
                UniformLib["sh"],
                UniformLib["hdr"],
                UniformLib["pds"],
                UniformLib["probe"],
                {
                    /** diffuse */
                    baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc)},
                    baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** displacement mappping */
                    displacementMap : { type: EUniformType.TEXTURE, value: null, default: blackTexture() /*normalTexture()*/ },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "metalness", material, data.metalness);
                setValueShader(shaderInterface, "roughness", material, data.roughness);
                setValueShader(shaderInterface, "reflectance", material, data.reflectance);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "normalScale", material, data.normalScale);

                setValueShader(shaderInterface, "displacementMap", material, data.displacementMap);

                // request reflection probe
                const nearestProbe = querySpatialSystem().getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

                // reset program (for globals)
                if(shaderInterface.initial) {
                    // access global parameters
                    setValueShaderLights(shaderInterface, material);
                }

                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

                setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
                setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShader: "redStandardDisplace_Vertex",
        });

        /**
         * DEFERRED VARIANTS
         */

        shaderBuilder.createShaderFrom("redStandard_Diffuse", "redStandard", {
            variants: [ShaderVariant.LIGHTING_DIFFUSE],
            fragmentShader: "redStandard_Diffuse_Pixel"
        });

        shaderBuilder.createShader("redStandard_SpecularRoughness", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            variants: [ShaderVariant.SPECULAR_ROUGHNESS],
            uniforms: mergeUniforms( [
                {
                    /** diffuse */
                    baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc)},
                    baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }
                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "metalness", material, data.metalness);
                setValueShader(shaderInterface, "roughness", material, data.roughness);
                setValueShader(shaderInterface, "reflectance", material, data.reflectance);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "normalScale", material, data.normalScale);

                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShaderSource: `
                //@include "redPrecision"

                #ifndef RED_NORMAL
                #define RED_NORMAL
                #endif

                // varyings
                varying vec3 vViewPosition;
                varying vec4 vWorldPosition;
                varying vec3 vNormal;
                varying vec2 vUv;

                #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
                varying vec3 vTangent;
                varying vec3 vBitangent;
                #endif

                uniform mat4 viewMatrix;

                // common
                //@include "redCommon"
                //@include "redFunctions"
                //@include "redBSDFFunctions"
                //@include "redLightFunctions"
                //@include "redPacking"
                //@include "redShadowFunctions"

                #if defined(USE_AOMAP)
                    varying vec2 vUv2;
                #endif

                //TODO:
                #if defined(USE_NORMALMAP)
                uniform vec2 normalScale;
                uniform sampler2D normalMap;
                #endif

                // default material values
                uniform sampler2D baseColorMap;
                uniform vec3 baseColor;

                // standard shader variables
                uniform float roughness;
                uniform float metalness;
                uniform sampler2D occRoughMetalMap;
                uniform float reflectance;

                // pixel shader entry
                void main() {

                    // read base color (texture is sRGB)
                    vec3 diffuseColor = inputToLinear(texture2D(baseColorMap, vUv).rgb);
                    // assuming input color as linear
                    diffuseColor.rgb *= baseColor;

                    // read metalness / roughness
                    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);

                    float metalnessFactor = max(0.04, texelOcclusionRoughnessMetalness.b * metalness);
                    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;


                #if defined(USE_NORMALMAP)
                #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
                    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
                    // vec(1,-1) for substance painter etc.
                    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
                    mat3 tsn = mat3( vTangent, vBitangent, vNormal );
                    vec3 normal = normalize( tsn * mapN );
                #else // NO QTANGENT
                    vec3 normal = normalize(vNormal);
                    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale * vec2(1.0, -1.0), normal);
                #endif
                #endif

                    StandardMaterial material;
                    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
                    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
                #if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
                    // Assumes an interface from air to an IOR of 1.5 for dielectrics
                    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
                #else
                    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
                #endif

                    gl_FragColor = vec4(material.specularColor, material.specularRoughness);
                }
            `
        });

        shaderBuilder.createShader("redStandard_Indirect", {
            redSettings: {
                lights: false,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true
            },
            variants: [ShaderVariant.INDIRECT_SPECULAR],
            uniforms: mergeUniforms( [
                UniformLib["hdr"],
                UniformLib["probe"],
                {
                    /** diffuse */
                    baseColor : { type: EUniformType.COLOR, value: new Color(0xcccccc), default: new Color(0xcccccc)},
                    baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** standard shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.95 },
                    metalness: { type: EUniformType.FLOAT, value: 0.0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }
                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "metalness", material, data.metalness);
                setValueShader(shaderInterface, "roughness", material, data.roughness);
                setValueShader(shaderInterface, "reflectance", material, data.reflectance);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
                setValueShader(shaderInterface, "normalMap", material, data.normalMap);
                setValueShader(shaderInterface, "normalScale", material, data.normalScale);

                // request reflection probe
                const nearestProbe = querySpatialSystem().getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {
                    RED_STANDARD: 1
                };

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShaderSource: `
                //@include "redPrecision"

                #ifndef RED_NORMAL
                #define RED_NORMAL
                #endif

                // varyings
                varying vec3 vViewPosition;
                varying vec4 vWorldPosition;
                varying vec3 vNormal;
                varying vec2 vUv;

                #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
                varying vec3 vTangent;
                varying vec3 vBitangent;
                #endif

                uniform mat4 viewMatrix;

                // common
                //@include "redCommon"
                //@include "redFunctions"
                //@include "redBSDFFunctions"
                //@include "redLightFunctions"
                //@include "redPacking"
                //@include "redShadowFunctions"

                #if defined(USE_AOMAP)
                    varying vec2 vUv2;
                #endif

                //TODO:
                #if defined(USE_NORMALMAP)
                uniform vec2 normalScale;
                uniform sampler2D normalMap;
                #endif

                // default material values
                uniform sampler2D baseColorMap;
                uniform vec3 baseColor;

                // standard shader variables
                uniform float roughness;
                uniform float metalness;
                uniform sampler2D occRoughMetalMap;
                uniform float reflectance;

                //@include "redIndirectLight_Standard"

                // tonemapping
                uniform float toneMappingExposure;
                uniform float toneMappingWhitePoint;

                // pixel shader entry
                void main() {
                    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));

                    // read base color (texture is sRGB)
                    vec3 diffuseColor = inputToLinear(texture2D(baseColorMap, vUv).rgb);
                    // assuming input color as linear
                    diffuseColor.rgb *= baseColor;

                    // read metalness / roughness
                    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);

                    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
                    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;


                #if defined(USE_NORMALMAP)
                #if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
                    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
                    // vec(1,-1) for substance painter etc.
                    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
                    mat3 tsn = mat3( vTangent, vBitangent, vNormal );
                    vec3 normal = normalize( tsn * mapN );
                #else // NO QTANGENT
                    vec3 normal = normalize(vNormal);
                    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale * vec2(1.0, -1.0), normal);
                #endif
                #endif

                    StandardMaterial material;
                    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
                    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
                #if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
                    // Assumes an interface from air to an IOR of 1.5 for dielectrics
                    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
                #else
                    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
                #endif

                GeometricContext geometry;
                geometry.position = -vViewPosition;
                geometry.normal   = normal;
                geometry.viewDir  = normalize(vViewPosition);
                //geometry.world_normal = normalize(vertex_worldNormal);
                geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));

                // only using occlusion map
                float ambientOcclusion = texelOcclusionRoughnessMetalness.r;

                evaluate_indirect_specular(geometry, material, ambientOcclusion, reflectedLight);

                // gather lighting
                vec3 outgoingLight = reflectedLight.indirectDiffuse + reflectedLight.indirectSpecular;

                // tone mapping
                outgoingLight = toneMapping(outgoingLight, 1.0, toneMappingWhitePoint);

                // linear to sRGB
                outgoingLight = linearToOutput(outgoingLight);

                gl_FragColor = vec4( outgoingLight, 1.0);
                }
            `
        });
    });
});
