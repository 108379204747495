/**
 * AssetAPI.ts: asset API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { makeAPI, queryAPI } from "../plugin/Plugin";
import { ModelData } from "../framework-types/ModelFileFormat";
import { AsyncLoad } from "../io/AsyncLoad";
import { AssetInfoFile, AssetInfo, FileStat } from "../io/AssetInfo";
import { GenericCompleteCallback, LoadingManager } from "../io/LoadingManager";
import { EventNoArg, EventOneArg } from "../core/Events";
import { StorageProvider } from "../io/StorageProvider";
import { build } from "../core/Build";

/**
 * describes asset in a bundle file
 */
export interface AssetContent {
    /** filename reference */
    file:string;
    /** like mimetype */
    type:string;
    /** loader identifier */
    loaderIdentifier?:string;
    /** data type */
    dataType?:string;
    /** content reference */
    data?:any;
}

/** */
export class AssetSettings {

    /** revision tag */
    public set useRevisionTag(value:boolean) {
        this._useRevisionTag = value;
    }
    public get useRevisionTag() : boolean {
        return this._useRevisionTag;
    }

    /** cross domain origin */
    public set allowCrossDomain(value:boolean) {
        this._allowCrossDomain = value;
    }
    public get allowCrossDomain() : boolean {
        return this._allowCrossDomain;
    }

    /** base mesh url/directories */
    public set baseTexturePath(path:string) {
        this._baseTexturePath = path;
    }
    public get baseTexturePath() : string {
        return this._baseTexturePath;
    }

    /** base mesh url/directories */
    public set baseMeshPath(path:string) {
        this._baseMeshPath = path;
    }
    public get baseMeshPath() : string {
        return this._baseMeshPath;
    }

    /** base text url/directories */
    public set baseTextPath(path:string) {
        this._baseTextPath = path;
    }
    public get baseTextPath() : string {
        return this._baseTextPath;
    }

    /** base shader path */
    public set baseShaderPath(path:string) {
        this._baseShaderPath = path;
    }
    public get baseShaderPath() : string {
        return this._baseShaderPath;
    }

    /** automatically preloaded asset types */
    public get preloadAssetTypes() : string[] {
        return this._preloadAssetTypes;
    }
    public set preloadAssetTypes(types:string[]) {
        this._preloadAssetTypes = types || [];
    }

    /** preloaded asset types mutation */
    public get preloadAssetPrediction() : (settings:AssetInfoFile) => AssetInfoFile {
        return this._preloadAssetPrediction;
    }
    public set preloadAssetPrediction(mutate:(settings:AssetInfoFile) => AssetInfoFile) {
        this._preloadAssetPrediction = mutate || null;
    }

    /** asset manager access info */
    public set denyUpdateAccess(value:boolean) {
        this._denyUpdateAccess = value;
    }
    public get denyUpdateAccess() : boolean {
        return this._denyUpdateAccess;
    }

    /** base url/directories */
    private _baseTexturePath:string;
    private _baseMeshPath:string;
    private _baseTextPath:string;
    private _baseShaderPath:string;

    /** add revision tag at every request */
    private _useRevisionTag:boolean = true;
    /** assetmanager access controller */
    private _denyUpdateAccess:boolean = false;
    /** cross domain loading */
    private _allowCrossDomain:boolean = false;

    /** forced preloading items */
    private _preloadAssetTypes: string[];
    private _preloadAssetPrediction: (settings:AssetInfoFile) => AssetInfoFile;

    /** construct */
    constructor() {

        this._preloadAssetTypes = ["materialProvider", "materialGroup"];
        this._preloadAssetPrediction = null;

        // set default base pathes based on build settings
        this._baseTexturePath = build.Options.baseTexturePath || "";
        this._baseMeshPath = build.Options.baseMeshPath || "";
        this._baseTextPath = build.Options.baseTextPath || "";
        this._baseShaderPath = build.Options.baseShaderPath || "shader/";

    }

    public update(storage:StorageProvider) {

        // set provider cross domain settings
        this._allowCrossDomain = this._allowCrossDomain || storage.forceCrossDomain();

        // set default base pathes based on build settings
        this._baseTexturePath = build.Options.baseTexturePath || "";
        this._baseMeshPath = build.Options.baseMeshPath || "";
        this._baseTextPath = build.Options.baseTextPath || "";
        this._baseShaderPath = build.Options.baseShaderPath || "shader/";
    }
}

/** [[include:sourceDoc/Asset.md]] */
export interface IAssetManager {
    /** loading started */
    LoadStarted:EventNoArg;
    /** loading finished */
    LoadFinished:EventNoArg;
    /** loading failed */
    LoadFailed:EventNoArg;
    /** loading progress callback (percent) */
    LoadProgress:EventOneArg<FileStat>;

    /** asset settings */
    setup:AssetSettings;

    /** static initialization for startup */
    init();

    /** switch to asset server */
    useAssetServer();

    /**
     * create URL from path or name
     * @param path input path, can be absolute or relative
     * @param basePath base path to use (optional)
     */
    createURL(path:string, basePath?:string) : string;

    /**
     * load asset info file for preloading and settings import
     * @param filename url of asset info
     * @param mutate optional asset info mutation
     * TODO: change mutate to a more discret (asset:AssetInfo) => boolean
     */
    loadAssetInfo(filename:string, mutate?:(settings:AssetInfoFile)=>AssetInfoFile) : AsyncLoad<AssetInfoFile>;

    /**
     * add asset informations
     * @param filename
     */
    addAssetInfo(name:string|AssetInfo, size?:number);

    getAssetInfo(name:string, basePath?:string) : AssetInfo;

    getLoadingProgress(preload:boolean);

    getLoadingManager() : LoadingManager;

    /**
     * generic loading function
     * TODO: add AsyncLoad based solution
     * @param func(completeCallback)
     */
    loadGeneric(func:GenericCompleteCallback);

    /**
     * async mesh loading
     * @param filename filename
     * @param loaderIdentifier optional loader identifier
     */
    //loadMesh(filename:string, loaderIdentifier?:string) : AsyncLoad<ModelData>;

    /**
     * load binary content
     * (red, raw image, binary files)
     * @param filename
     */
    loadBinary(filename:string) : AsyncLoad<ArrayBuffer>;

    /**
     * load image (HTML)
     * @param filename
     */
    loadImage(filename:string) : AsyncLoad<any>;

    /**
     * load text content
     * (json, glsl, text files)
     * @param filename
     */
    loadText(filename:string) : AsyncLoad<string>;

    /**
     * load asset bundle from url
     * @param filename url
     * @return AssetContent files that are loading or got loaded
     */
    loadAssetBundle(filename:string) : AsyncLoad<AssetContent[]>;

    /**
     * flush all caches
     * should result in reloading all models
     */
    flushCaches();

    /**
     * add an image to asset management
     * @param name reference name
     * @param content image content
     * @param mimeType image mime type
     */
    addImage(name:string, content:string|ArrayBuffer|Blob|File|HTMLImageElement, mimeType?:string);

    /**
     * add a mesh to asset management
     * @param name reference name
     * @param content content data
     * @param type not supported right now
     */
    //addMesh(name:string, content:string|ArrayBuffer|Blob|ModelData, loaderIdentifier?:string);

    /**
     * add text to asset management
     * @param name reference name
     * @param content content string
     */
    addText(name:string, content:string|Blob);

    /**
     * add binary content to asset manager
     * @param name name of binary content
     * @param content binary data
     */
    addBinary(name:string, content:ArrayBuffer|Blob);
}
export const ASSETMANAGER_API = makeAPI("IAssetManager");

let _globalAssetManager:IAssetManager;
export function queryAssetManager() {
    if(_globalAssetManager === undefined) {
        _globalAssetManager = queryAPI<IAssetManager>(ASSETMANAGER_API);
    }
    return _globalAssetManager;
}
