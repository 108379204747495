///<reference path="../js_bindings.d.ts"/>

import * as BlockComponent from './components/Import'
import {BlockState} from './BlockState';
import {AbstractBlockComponent} from './components/AbstractBlockComponent';
import {ComponentState} from './components/ComponentState';

export class Block {

    private _initialized:boolean;
    private _started:boolean;
    private _destroyed:boolean = false;
    private _settings:Object;
    private _appeared:boolean;
    private _components:Array<BlockComponent.AbstractBlockComponent> = [];
    private _subblocks:Array<Block> = [];
    private _componentIds:Array<string> = [];
    private _used:boolean = true;
    private _centerDistance:Number = -10;
    private _topDistance:Number = 0;
    private _onCenterDistanceChange: SimpleEventDispatcher<Number> = new SimpleEventDispatcher();
    private _onCenterDistanceUpdate: SimpleEventDispatcher<Number> = new SimpleEventDispatcher();
    private _updateViewportToCenterRef;
    private _state:BlockState = BlockState.NONE;
    private _onStateChange = new EventDispatcher<Block,BlockState>();

    /** construction */
    constructor(private _domElement:HTMLElement) {
    }

    public get classes():any{
        return this._domElement.getAttribute("class")
    }

    public get currentState():BlockState{
        return this._state;
    }

    public get onStateChange(): IEvent<Block,BlockState> {
        return this._onStateChange.asEvent();
    }

    public init():void {
        if(this._initialized === true){
            this._onStateChange.dispatchAsync(this, this._state = BlockState.READY);
            return;
        }
        this._initialized = true;

        if(this._domElement.hasAttribute("data-components")){
            this._componentIds = this._domElement.getAttribute("data-components").split(";");
            _.each(this._componentIds,(componentId)=>{

                let componentParams = this.getComponentParams(componentId);

                switch(componentId){
                    case "grid":
                        this._components.push(new BlockComponent.GridComponent(this, componentParams));
                        break;
                    case "map":
                        this._components.push(new BlockComponent.MapComponent(this, componentParams));
                        break;
                    case "contact":
                        this._components.push(new BlockComponent.ContactComponent(this, componentParams));
                        break;
                    case "video":
                        this._components.push(new BlockComponent.VideoComponent(this, componentParams));
                        break;
                    case "index":
                        this._components.push(new BlockComponent.IndexComponent(this, componentParams));
                        break;
                    case "scene":
                        this._components.push(new BlockComponent.SceneComponent(this, componentParams));
                        break;
                    case "carousel":
                        this._components.push(new BlockComponent.CarouselComponent(this, componentParams));
                        break;
                    case "lightbox":
                        this._components.push(new BlockComponent.LightboxComponent(this, componentParams));
                        break;
                    case "walkway":
                        this._components.push(new BlockComponent.WalkwayComponent(this, componentParams));
                        break;
                    case "imagecompare":
                        this._components.push(new BlockComponent.ImageCompareComponent(this, componentParams));
                        break;
                    case "demand":
                        this._components.push(new BlockComponent.DemandComponent(this, componentParams));
                        break;
                    case "debug":
                        this._components.push(new BlockComponent.DebugComponent(this, componentParams));
                        break;
                    case "scrolltransform":
                        this._components.push(new BlockComponent.ScrollTransformComponent(this, {}));
                        break;
                    case "social":
                        this._components.push(new BlockComponent.SocialComponent(this, {}));
                        break;
                    case "disqus":
                        this._components.push(new BlockComponent.DisqusComponent(this, componentParams));
                        break;
                    case "code":
                        this._components.push(new BlockComponent.CodeComponent(this, {}));
                        break;
                    case "cachesvg":
                        this._components.push(new BlockComponent.SVGComponent(this, {}));
                        break;
                    case "privacy":
                        this._components.push(new BlockComponent.PrivacySettingsComponent(this, {}));
                        break;
                    }
                }
            );
        }
        
        
        this._components.push(new BlockComponent.UtilsComponent(this, {}));

        this._updateViewportToCenterRef = this.updateViewportToCenter.bind(this);

        //the block has components
        if(this._components.length > 0){

            let componentStateHandler = (component:AbstractBlockComponent, state:ComponentState)=>{
                //console.log("componentStateHandler state", this.classes ,String(component.constructor["name"]),ComponentState[state]);

                if(this._state == BlockState.READY){
                    return;
                }

                // //Is every component ready?
                let allReady = _.every(this._components, ['currentState', ComponentState.READY]);
                // console.log("componentStateHandler allReady",allReady);

                if(allReady) {
                    //remove all state listeners from components
                    _.each(this._components,(component:AbstractBlockComponent)=>{
                        component.onStateChange.unsubscribe(componentStateHandler);
                    });

                    //call the block with the ready state
                    this._state = BlockState.READY;
                    this._onStateChange.dispatchAsync(this, this._state);
                }
            };


            //subscribe
            _.each(this._components,(component:AbstractBlockComponent)=>{
                component.onStateChange.subscribe(componentStateHandler);
            });

            //Init all components
            _.each(this._components,(component:AbstractBlockComponent)=>{
                component.init();
            });
        }
        //the block has no components
        else{
            //ready to go
            this._onStateChange.dispatchAsync(this, this._state = BlockState.READY);
        }
    }

    public start():void{
        if(this._started === true){
            console.warn("Block already started",this);
            return;
        }
        this._started = true;

        // console.log("Block start");
        window.addEventListener("resize", this._updateViewportToCenterRef);
        document.addEventListener("scroll", this._updateViewportToCenterRef);


        setTimeout(()=>{
            this.updateViewportToCenter();
        },10);
    }

    public addSubBlock(subblock:Block):void{
        this._subblocks.push(subblock);
    }

    get subBlocks():Array<Block>{
        return this._subblocks;
    }

    private updateViewportToCenter():void{
        let rect = this._domElement.getBoundingClientRect();
        //console.log("rect",this._componentIds,rect);
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        let windowHeight = window.innerHeight;
        let windowHeightHalf = windowHeight / 2;

        // rect.height must be minimum 1 to avoid devide by zero errors
        let rectHeight = Math.max(1,rect.height);
        let elementTop = scrollTop + rect.top;
        let elementBottom = elementTop + rectHeight;
        let winTop = 0;

        if(window.scrollY === undefined){
             winTop = document.documentElement.scrollTop;
        }else{
            winTop = window.scrollY;
        }

        let winBottom = winTop + windowHeight;

        let isUpper = elementTop + (rectHeight / 2) < winTop + windowHeightHalf;

        let overlapY = Math.max(0, Math.min(elementBottom,winBottom) - Math.max(elementTop,winTop));
        let overlapPc = (1 - overlapY / Math.min(windowHeight, rectHeight)) * (isUpper ? -1 : 1);

        this.centerDistance = overlapPc;
    }

    private getComponentParams(id:string):Object{
        let paramsRaw = this._domElement.getAttribute(`data-${id}params`);
        return paramsRaw ? JSON.parse(paramsRaw) : {};
    }

    public markAsUnused():void{
        this._used = false;
    }

    public markAsUsed():void{
        this._used = true;
    }

    get used():boolean {
        return this._used;
    }


    get domElement():HTMLElement {
        return this._domElement;
    }

    get appeared():boolean {
        return this._appeared;
    }

    set appeared(value:boolean) {
        if(this._appeared == value) return;

        this._appeared = value;

        if(value){
            this._domElement.classList.add('appeared');

            _.each(this._components,(component)=>{
                component.appear();
            });
        }
        else{
            _.each(this._components,(component)=>{
                component.disappear();
            });
        }
    }

    get centerDistance():Number {
        return this._centerDistance;
    }

    set centerDistance(value:Number) {
        if(this._centerDistance != value){
            this._centerDistance = value;
            //fire only on value change
            this._onCenterDistanceChange.dispatchAsync(value);
        }
        //fire always
        this._onCenterDistanceUpdate.dispatchAsync(value);

        //Set the appeard value
        this.appeared = value > -1 && value < 1;
    }

    get onCenterDistanceChange(): ISimpleEvent<Number> {
        return this._onCenterDistanceChange.asEvent();
    }

    get onCenterDistanceUpdate(): ISimpleEvent<Number> {
        return this._onCenterDistanceUpdate.asEvent();
    }

    public destroy():void{
        //console.log("Block destroy");
        this._destroyed = true;

        window.removeEventListener("resize", this._updateViewportToCenterRef);
        document.removeEventListener("scroll", this._updateViewportToCenterRef);

        this._domElement.classList.add('destroyed');

        _.each(this._components,(component)=>{
            component.destroy();
        });

        //remove the dom element itself
        if(this._domElement.parentNode){
            this._domElement.parentNode.removeChild(this._domElement);
        }

        //inform manager
        this._onStateChange.dispatchAsync(this, this._state = BlockState.DESTROYED);
    }
}