
/**
 * Transparent.ts: Transparent (physical correct) shader
 *
 * physical based shader with lighting and shadow support
 * also supports transparency
 * TODO: work in progress
 *
 * ### Parameters
 * #### Albedo
 * * diffuse -- Diffuse Color (RGB) Alpha (A)
 * * map -- Diffuse Texture (RGB)
 * #### Surface Properties
 * * roughness -- Roughness
 * * metalness -- Metal (0-1)
 * * occRoughMetalMap -- Occlusion (R) Roughness Texture (G)  Metal Texture (B)
 * #### Transparency parameters
 * * Opacity
 * * Blending
 * #### Normal Mapping
 * * normalMap -- Normal Texture (RGB)
 * * normalScale -- Normal Scale (vec2)
 * #### Modification
 * * offsetRepeat -- Offset/Repeat for Textures
 *
 * Copyright redPlant GmbH  2018
 * @author Monia Arrada
 * @author Lutz Hören
 */
import { Vector2 } from "../../../lib/threejs/math/Vector2";
import { Vector4 } from "../../../lib/threejs/math/Vector4";
import { Color } from "../../../lib/threejs/math/Color";
import { AlwaysDepth, NotEqualDepth } from "../../../lib/threejs/constants";
import {mergeUniforms, EUniformType} from '../Uniforms';
import {setValueShader, applyShaderToRenderer, ShaderVariant, variantIsSet, ShaderStencilOp} from '../Shader';
import { ShaderBuilder, ShaderModule, UniformLib } from "../ShaderBuilder";
import { whiteTexture, normalTexture } from "../Texture";
import { Mesh } from "../Mesh";
import { Line } from "../../render-line/Line";
import { ESpatialType, querySpatialSystem } from "../../framework/SpatialAPI";
import { setValueShaderLights } from "../Lights";
import { Render } from "../Render";
import { setValueShaderProbe } from "../LightProbe";
// builtin shader code
import "./shader_generated";

/**
 * redPlant Shader Library for THREE.JS
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    // first import code
    shaderBuilder.importCode(["redCommon", "redPrecision", "redFunctions", "redBSDFFunctions", "redPacking",
                                    "redLightFunctions", "redShadowFunctions", "redStandard_Vertex", "redTransparent_Pixel"]).then( () => {

        shaderBuilder.createShader("redTransparent", {
            redSettings: {
                lights: true,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                blending: "normal"
            },
            selector(variant:ShaderVariant) : string | void {
                if(variantIsSet(ShaderVariant.VSM, variant)) {
                    return "redVSMDepth";
                }

                if(variantIsSet(ShaderVariant.ESM, variant)) {
                    return "redESMDepth";
                }

                if(variantIsSet(ShaderVariant.PCF, variant)) {
                    return "redPCFDepth";
                }
            },
            variants: [ShaderVariant.DEFAULT, ShaderVariant.INSTANCED, ShaderVariant.IBL],
            uniforms: mergeUniforms( [
                UniformLib["redLights"],
                UniformLib["lights"],
                UniformLib["pds"],
                UniformLib["fog"],
                UniformLib["sh"],
                UniformLib["hdr"],
                UniformLib["probe"],
                {
                    /** diffuse */
                    baseColor : { type: EUniformType.COLOR, value: new Color(1,1,1), default: new Color(1,1,1)},
                    baseColorMap : { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    /** Transparent shader */
                    roughness: { type: EUniformType.FLOAT, value: 0.045, default: 0.045 },
                    metalness: { type: EUniformType.FLOAT, value: 0, default: 0.0 },
                    occRoughMetalMap: { type: EUniformType.TEXTURE, value: null, default: whiteTexture() },
                    reflectance: { type: EUniformType.FLOAT, value: 0.5, default: 0.5 },
                    /** transparency */
                    opacity : { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
                    /** normal mapping */
                    normalMap : { type: EUniformType.TEXTURE, value: null, default: normalTexture() },
                    normalScale : { type: EUniformType.VECTOR2, value: new Vector2(1.0, 1.0), default: new Vector2(1.0, 1.0) },
                    /** uv channel transform */
                    offsetRepeat : { type: EUniformType.VECTOR4, value: new Vector4(0.0, 0.0, 1.0, 1.0), default: new Vector4(0.0, 0.0, 1.0, 1.0) },
                }
            ]),
            onPreRender(renderer:Render, camera:any, material:any, mesh:Mesh|Line, data:any):void {

                const shaderInterface = applyShaderToRenderer(renderer, material);

                // not applicable
                if(!shaderInterface) {
                    return;
                }

                setValueShader(shaderInterface, "baseColor", material, data.baseColor);
                setValueShader(shaderInterface, "metalness", material, data.metalness);
                setValueShader(shaderInterface, "roughness", material, data.roughness);
                setValueShader(shaderInterface, "reflectance", material, data.reflectance);
                setValueShader(shaderInterface, "opacity", material, data.opacity);

                setValueShader(shaderInterface, "baseColorMap", material, data.baseColorMap);
                setValueShader(shaderInterface, "occRoughMetalMap", material, data.occRoughMetalMap);
                setValueShader(shaderInterface, "normalMap", material, data.normalMap);

                // request reflection probe
                const nearestProbe = querySpatialSystem().getNearestObject(mesh.positionWorld, ESpatialType.PROBE);
                // apply probe
                setValueShaderProbe(shaderInterface, camera, material, nearestProbe);

                // reset program (for globals)
                if(shaderInterface.initial) {
                    // access global parameters
                    setValueShaderLights(shaderInterface, material);
                }

                setValueShader(shaderInterface, "offsetRepeat", material, data.offsetRepeat);

                setValueShader(shaderInterface, "toneMappingExposure", material, camera.exposure);
                setValueShader(shaderInterface, "toneMappingWhitePoint", material, camera.whitepoint);
            },
            evaluateDefines:(variant:ShaderVariant, mesh:any) => {
                const defines = {};

                if(variantIsSet(ShaderVariant.INSTANCED, variant)) {
                    defines['USE_INSTANCING'] = 1;
                }

                // TESTING
                defines['USE_NORMALMAP'] = 1;
                //defines['USE_REFRACT'] = 1;

                return defines;
            },
            vertexShader: "redStandard_Vertex",
            fragmentShader: "redTransparent_Pixel"
        });

        /**
         * Transparent without depthtest
         */

        shaderBuilder.createShaderFrom("redTransparent_Overlay", "redTransparent", {
            redSettings: {
                lights: true,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                blending: "normal",
                depthTest: false,
            }
        });

        /**
         * Transparent masking stencil buffer
         */

        shaderBuilder.createShaderFrom("redTransparent_Stencil", "redTransparent", {
            redSettings: {
                lights: true,
                derivatives: true,
                shaderTextureLOD: true,
                isRawMaterial: true,
                blending: "normal",
                depthTest: true,
                /** stencil */
                stencilTest: true,
                stencilFunc: {
                    func: NotEqualDepth,
                    ref: 1,
                    mask: 0xFFFFFFFF
                },
                stencilOp: {
                    fail: ShaderStencilOp.KEEP,
                    zfail: ShaderStencilOp.KEEP,
                    zpass: ShaderStencilOp.REPLACE
                }
            }
        });

    });
});
