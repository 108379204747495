/**
 * Debug.ts: debug interfaces
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { build } from "./Build";

/**
 * helper function to work with Chome developer tools
 */
export function devMarkTimelineStart(name:string) {
    if(build.Options.development) {
        if(console.time && console.timeEnd) {
            console.time(name);
        } else if(console.timeline && console.timelineEnd) {
            console.timeline(name);
        }
    }
}

export function devMarkTimelineEnd(name:string) {
    if(build.Options.development) {
        if(console.time && console.timeEnd) {
            console.timeEnd(name);
        } else if(console.timeline && console.timelineEnd) {
            console.timelineEnd(name);
        }
    }
}

export function devMarkSpot(name:string) {
    if(build.Options.development) {
        if(console.timeStamp) {
            console.timeStamp(name);
        } else if(console.markTimeline) {
            console.markTimeline(name);
        }
    }
}

/**
 * helper function to work with Chome performance tools
 */
export function devProfileStart(name:string) {
    if(build.Options.development && performance.mark) {
        performance.mark(name + "-start");
    }
}

/**
 * helper function to work with Chome performance tools
 */
export function devProfileStop(name:string) {
    if(build.Options.development && performance.mark) {
        performance.mark(name+ "-end");
    }
    if(build.Options.development && performance.measure) {
        performance.measure(name, name + "-start", name + "-end");
    }
}
