export default {
    name: "google_analytics",
    title: "Google Analytics",
    purposes: ["analytics"],
    translations: {
        en: {
            description: `
                Google Analytics is a service for creating detailed statistics about user behavior on the website. The cookies are used to distinguish users, throttle the request rate, link the client ID with the user's AMP client ID, store campaign-related information from and for the user, and link data from multiple page views.
                <br><br>

                <table>
                    <thead>
                        <th>Cookie-Type</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Validity period</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_ga</td>
                            <td>.</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gid</td>
                            <td>.</td>
                            <td>1 Day</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gat</td>
                            <td>.</td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>AMP_TOKEN</td>
                            <td>.</td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gac_*</td>
                            <td>.</td>
                            <td>90 Days</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gat_gtag_*</td>
                            <td>.</td>
                            <td>1 Minute</td>
                        </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Use on legal basis of: Consent
                </p>
                <p class="purposes">
                    Provider: Google Ireland Limited
                </p>
                <p class="purposes">
                    US data processing: Yes
                </p>
                <p class="purposes">
                    Privacy policy of the provider: https://policies.google.com/privacy
                </p>
            `,
        },
        de: {
            description:
                `Google Analytics ist ein Dienst zur Erstellung detaillierter Statistiken über das Nutzerverhalten auf der Website. Die Cookies werden verwendet, um Nutzer zu unterscheiden, die Anfragerate zu drosseln, die Client-ID mit der AMP-Client-ID des Nutzers zu verknüpfen, kampagnenbezogene Informationen von und für den Nutzer zu speichern und um Daten von mehreren Seitenaufrufen zu verknüpfen.
                <br><br>

                <table>
                    <thead>
                        <th>Cookie-Typ</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Gültigkeitsdauer</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_ga</td>
                            <td>.</td>
                            <td>2 Jahre</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gid</td>
                            <td>.</td>
                            <td>1 Tag</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gat</td>
                            <td>.</td>
                            <td>1 Jahr</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>AMP_TOKEN</td>
                            <td>.</td>
                            <td>1 Jahr</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gac_*</td>
                            <td>.</td>
                            <td>90 Tage</td>
                        </tr>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>_gat_gtag_*</td>
                            <td>.</td>
                            <td>1 Minute</td>
                        </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Verwendung auf gesetzlicher Grundlage von: Einwilligung
                </p>
                <p class="purposes">
                    Anbieter: Google Ireland Limited
                </p>
                <p class="purposes">
                    US-Datenverarbeitung: Ja
                </p>
                <p class="purposes">
                    Datenschutzerklärung des Anbieters: https://policies.google.com/privacy
                </p>
            `,
        },
    },
    cookies: [
        "_ga",
        "_gid",
        "_gat",
        "AMP_TOKEN",
        "_gac_*",
        "_gat_gtag_*",
    ],
};