///<reference path="../../js_bindings.d.ts"/>

import {Block} from '../Block';
import {AbstractBlockComponent} from './AbstractBlockComponent';
import {ComponentState} from './ComponentState';

export class ImageCompareComponent extends AbstractBlockComponent
{
    private _contentElement:HTMLElement;
    private _containerElement:HTMLElement;
    private _blockContentElement:HTMLElement;
    private _maxImageWidth:number = 0;

    protected onInit(): void
    {
        this._params = _.defaults(this._params, {
            default_offset_pct: 0.5
        });

        this._contentElement = this._block.domElement.querySelector('.imagecompare_content') as HTMLElement;
        this._containerElement = this._block.domElement.querySelector('.twentytwenty-container') as HTMLElement;
        this._blockContentElement = this._block.domElement.querySelector('.block_content') as HTMLElement;
        let images = this._containerElement.querySelectorAll('img');
        let imageCount = images.length;

        let imageLoadHandler = (image:HTMLImageElement)=>{
            imageCount--;
            if(imageCount == 0){
                this.initTwentytwenty();
            }
            this.handleImageSize(image);
        };

        _.each(images,(image:HTMLImageElement)=>{
            //the image is already loaded -> handle the width calculations directly
            if(image.complete === true){
                imageLoadHandler(image);
            }

            //the load listener is always needed. The picture tag changes the images on resize
            image.addEventListener('load',(event)=>{
                imageLoadHandler(image);
            });
        });


        this.state = ComponentState.READY;
    }

    private handleImageSize = (image:HTMLImageElement)=>{
        //console.log('ImageSize',image.width,image.naturalWidth);
        this._maxImageWidth = Math.max(this._maxImageWidth,image.width,image.naturalWidth);
        this._contentElement.style.maxWidth = this._maxImageWidth + 'px';

        // talk to the jquery plugin!
        window.dispatchEvent(new Event('resize.twentytwenty'));
    }

    private initTwentytwenty = ()=>{
        $(this._containerElement).twentytwenty(this._params);

        //override the ::before content with data-label attribute
        this._containerElement.querySelector('.twentytwenty-before-label').setAttribute('data-label',this._params.label_left);
        this._containerElement.querySelector('.twentytwenty-after-label').setAttribute('data-label',this._params.label_right);
    }

    protected onDestroy():void
    {
        this.state = ComponentState.DESTROYED;
    }
}