/**
 * InputAPI.ts: input API
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { WorldSystem } from "./WorldAPI";
import { EventOneArg } from "../core/Events";
import { queryAPI, makeAPI } from "../plugin/Plugin";

/**
 * input propogation class
 * helps to provide an input
 */
export interface IInputSystem extends WorldSystem {

    OnTouchStart:EventOneArg<Event>;
    OnTouchMove: EventOneArg<Event>;
    OnTouchEnd: EventOneArg<Event>;
    OnMouseEnter: EventOneArg<Event>;
    OnMouseLeave: EventOneArg<Event>;
    OnMouseDown: EventOneArg<Event>;
    OnMouseMove: EventOneArg<Event>;
    OnMouseWheel: EventOneArg<Event>;
    OnMouseUp: EventOneArg<Event>;
}
export const INPUTSYSTEM_API = makeAPI("IInputSystem");

let _globalInputSystem:IInputSystem;
export function queryInputSystem() {
    if(_globalInputSystem === undefined) {
        _globalInputSystem = queryAPI<IInputSystem>(INPUTSYSTEM_API);
    }
    return _globalInputSystem;
}
