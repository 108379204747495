export default {
    name: "klaro",
    title: "Klaro",
    purposes: ["essential"],
    required: true,
    translations: {
        en: {
            description: `
                Klaro asks website visitors for consent to set cookies and process personal data. For this purpose, each website visitor is assigned a UUID (pseudonymous identification of the user), which is valid until the cookie expires to store the consent. Within the scope of the duty of disclosure according to DSGVO, the collected consent is documented.
                <br><br>

                <table>
                    <thead>
                        <th>Cookie-Type</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Validity period</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>HTTP Cookie</td>
                        <td>klaro</td>
                        <td>.</td>
                        <td>3 Months</td>
                    </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Use on legal basis of: Fulfillment of a legal obligation
                </p>
                <p class="purposes">
                    Provider: redPlant GmbH
                </p>
                <p class="purposes">
                    Privacy policy of the provider: {privacyPolicy}
                </p>
            `,
        },
        de: {
            description: `
                Klaro bittet Website-Besucher um die Einwilligung zum Setzen von Cookies und zur Verarbeitung personenbezogener Daten. Dazu wird jedem Website-Besucher eine UUID (pseudonyme Identifikation des Nutzers) zugewiesen, die bis zum Ablauf des Cookies zur Speicherung der Einwilligung gültig ist. Im Rahmen der Darlegungspflicht nach DSGVO wird die erhobene Einwilligung dokumentiert.
                <br><br>
                
                <table>
                    <thead>
                        <th>Cookie-Typ</th>
                        <th>Cookie-Name</th>
                        <th>Cookie-Host</th>
                        <th>Gültigkeitsdauer</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>HTTP Cookie</td>
                            <td>klaro</td>
                            <td>.</td>
                            <td>3 Monate</td>
                        </tr>
                    </tbody>
                </table>
                
                <br>
                <p class="purposes">
                    Verwendung auf gesetzlicher Grundlage von: Erfüllung einer rechtlichen Verpflichtung
                </p>
                <p class="purposes">
                    Anbieter: redPlant GmbH
                </p>
                <p class="purposes">
                    Datenschutzerklärung des Anbieters: {privacyPolicy}
                </p>
            `,
        },
    },
    cookies: [
        "klaro",
    ],
};