/**
 * Globals.ts: Global/Helper code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import {Mesh as ThreeMesh} from "../../lib/threejs/objects/Mesh";

/**
 * get the image data from an image
 * format: rgba in 1d array
 */
export function getImageData(image:any) : ImageData {

    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;

    const context = canvas.getContext("2d");
    context.drawImage( image, 0, 0 );

    return context.getImageData( 0, 0, image.width, image.height );
}

/**
 * access pixel as rgba object
 */
export function getPixel(imagedata:ImageData, x:number, y:number) {
    const position:number = ( x + imagedata.width * y ) * 4;
    const data:any = imagedata.data;
    return { r: data[ position ], g: data[ position + 1 ], b: data[ position + 2 ], a: data[ position + 3 ] };
}

/**
 * compare to objects against each other
 * @http://stackoverflow.com/questions/201183/how-to-determine-equality-for-two-javascript-objects/16788517#16788517
 * returns true if same object
 */
export function objectEquals(x:any, y:any) : boolean {
    if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) { return false; }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) { return x === y; }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) { return x === y; }
    if (x === y || x.valueOf() === y.valueOf()) { return true; }
    if (Array.isArray(x) && x.length !== y.length) { return false; }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) { return false; }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) { return false; }
    if (!(y instanceof Object)) { return false; }

    // recursive object equality check
    const p = Object.keys(x);
    return Object.keys(y).every((i:any) => { return p.indexOf(i) !== -1; }) &&
        p.every((i:any) => { return objectEquals(x[i], y[i]); });
}

/**
 * deep clone an object
 * @see http://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
 */
export function cloneObject(obj:any) {
    let copy = obj;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" !== typeof obj) {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for(let i = 0, len = obj.length; i < len; i++) {
            copy[i] = cloneObject(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for(const attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = cloneObject(obj[attr]);
            }
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
}

/**
 * Overwrites obj1's values with obj2's and adds obj2's if non existent in obj1
 * http://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
 * @param obj1
 * @param obj2
 * @returns obj3 a new object based on obj1 and obj2
 */
export function mergeObject(obj1:any, obj2:any) {
    const obj3:any = {};
    for (const attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (const attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
}

/**
 * Overwrites lower indexed object-values with higher indexed object-values if already existent
 * @param objects
 * @returns obj3 a new object based on obj1 and obj2
 */
export function mergeObjects(objects:any[]) {
    console.assert(objects && objects.length && objects.length > 0);

    const obj3:any = {};
    for (const obj of objects) {
        for (const attrname in obj) { obj3[attrname] = obj[attrname]; }
    }
    return obj3;
}

/**
 * safely add key/value to object
 * mainly used to set setting variables.
 * @param force for everwriting, set to true
 */
export function setKeyValueSafe(obj:any, key:string, value:any, force?:boolean) {
    obj = obj || {};
    if(!obj[key] || force) {
        obj[key] = value;
    }
    return obj;
}

/** typescript helper mixin function */
export function applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {

            const descriptor = Object.getOwnPropertyDescriptor(baseCtor.prototype, name);

            if(descriptor) {
                Object.defineProperty(derivedCtor.prototype, name, descriptor);
            }

            //derivedCtor.prototype[name] = baseCtor.prototype[name];
        });
    });
}

/** test if valid url */
export function ValidURL(str:string) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return pattern.test(str);
}

/** check for absolute url */
export function isAbsoluteURL(str:string) {
    const pattern = new RegExp('^([a-z]+://|//)', 'i');
    return pattern.test(str);
}

/** debug three.js scene print to console */
export function printSceneTree(scene:any) {
    function recursivePrint(node:any, delimitier:number) {

        let tab = "";
        for(let i = 0; i < delimitier; ++i) {
            tab += "\t";
        }
        const name = node.name || "Empty Node";
        console.log(tab + "- " + name + " [" +
            "(" + node.position.x + "," + node.position.y + "," + node.position.z + "), " +
            "(" + node.rotation.x + "," + node.rotation.y + "," + node.rotation.z + "), " +
            "(" + node.scale.x + "," + node.scale.y + "," + node.scale.z + ")]");

        for(let i = 0; node.children && i < node.children.length; ++i) {
            recursivePrint(node.children[ i ], delimitier + 1);
        }
    }

    if(scene) {
        recursivePrint(scene, 0);
    } else {
        console.warn("printSceneTree: no scene to print");
    }
}

export function getQueryVariable(variable:string) : any {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for(let i=0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if(pair[0] === variable) {
            return pair[1];
        }
    }
    return null;
}

export function getParameterByName(name:string, url?:string) : string {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ""; }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/** update query url with new/update value */
export function UpdateQueryString(url:string, key:string, value:any) {
    const re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
    if( value === undefined ) {
        if (url.match(re)) {
            return url.replace(re, '$1$2');
        } else {
            return url;
        }
    } else {
        if (url.match(re)) {
            return url.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            const hash =  '';
            //FIXME: check for which is this needed
            //TODO: and bound hash to key
            if( url.indexOf('#') !== -1 ) {
                //hash = url.replace(/.*#/, '#');
                //url = url.replace(/#.*/, '');
            }
            const separator = url.indexOf('?') !== -1 ? "&" : "?";
            return url + separator + key + "=" + value + hash;
        }
    }
}

/**
 * If a file has no extension, this will still return the file name.
 * If there is a fragment in the URL, but no query (e.g. page.html#fragment), this will return the file extension and the fragment
 * @param filename filename
 */
export function parseFileExtension(filename:string) {
    return filename.match(/(.*)\??/i).shift().replace(/\?.*/, '').split('.').pop();
}

/** settings for gpu dispose */
export interface GraphicsDisposeSetup {
    noGeometry?:boolean;
    noMaterial?:boolean;
}

/**
 * dispose and free from scene
 */
export function destroyObject3D(obj:any, dispose?:GraphicsDisposeSetup) {
    dispose = dispose || {};
    if(obj instanceof ThreeMesh || (obj.geometry && obj.material)) {

        // RedMesh
        if(obj.isRedMesh && obj['destroy']) {
            //FIXME:
            //obj['destroy'](dispose);
            return;
        }

        // mesh geometry
        if(!dispose.noGeometry) {
            obj.geometry.dispose();
        }
        obj.geometry = null;
        if(!dispose.noMaterial) {
            if(Array.isArray(obj.material)) {
                for(const mat of obj.material) {
                    if(mat.dispose) {
                        mat.dispose();
                    }
                }
            } else if(obj.material && obj.material.dispose) {
                obj.material.dispose();
            }
        }
        obj.material = null;
        obj.onBeforeRender = function() {};
        obj.onAfterRender = function() {};

    } else {

        for(let i = 0; i < obj.children.length; ++i) {
            destroyObject3D(obj.children[i], dispose);
        }

        for(let i = obj.children.length -1; i >= 0; --i) {
            // check entities
            if(!obj.children[i]['isEntity']) {
                obj.remove(obj.children[i]);
            }
        }
    }
}

/**
 * find entities in hierarchy and destroy them
 *
 * @export
 * @param {*} obj
 * @param {GraphicsDisposeSetup} [dispose]
 */
export function destroyEntity(obj:any, dispose?:GraphicsDisposeSetup) {
    dispose = dispose || {};

    for(let i = 0; i < obj.children.length; ++i) {
        destroyEntity(obj.children[i], dispose);
    }

    const children = obj.children.slice(0);
    for(let i = children.length - 1; i >= 0; --i) {
        // check entities
        if(children[i]['isEntity']) {
            const entity = obj.children[i];
            if(!entity.persistent) {
                entity.destroy(dispose);
            }
        } else {
            obj.remove(children[i]);
        }
    }
}

export const generateUUID = (function() {
    // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/21963136#21963136
    const lut = [];
    for(let i = 0; i < 256; i ++ ) {
        lut[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );
    }

    return function _generateUUID() {

        const d0 = Math.random() * 0xffffffff | 0;
        const d1 = Math.random() * 0xffffffff | 0;
        const d2 = Math.random() * 0xffffffff | 0;
        const d3 = Math.random() * 0xffffffff | 0;
        const uuid = lut[ d0 & 0xff ] + lut[ d0 >> 8 & 0xff ] + lut[ d0 >> 16 & 0xff ] + lut[ d0 >> 24 & 0xff ] + '-' +
            lut[ d1 & 0xff ] + lut[ d1 >> 8 & 0xff ] + '-' + lut[ d1 >> 16 & 0x0f | 0x40 ] + lut[ d1 >> 24 & 0xff ] + '-' +
            lut[ d2 & 0x3f | 0x80 ] + lut[ d2 >> 8 & 0xff ] + '-' + lut[ d2 >> 16 & 0xff ] + lut[ d2 >> 24 & 0xff ] +
            lut[ d3 & 0xff ] + lut[ d3 >> 8 & 0xff ] + lut[ d3 >> 16 & 0xff ] + lut[ d3 >> 24 & 0xff ];

        // .toUpperCase() here flattens concatenated strings to save heap memory space.
        return uuid.toUpperCase();
    };
})();
