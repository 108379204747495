/**
 * Lines.ts: line rendering shader
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { EUniformType } from "../render/Uniforms";
import { ShaderBuilder, ShaderModule } from "../render/ShaderBuilder";
import { ScreenspaceLine } from "./Lines";
import { Render } from "../render/Render";
import { applyShaderToRenderer, setValueShader, ShaderVariant } from "../render/Shader";
import { math } from "../core/Math";
import { CullFaceNone } from "../../lib/threejs/constants";
import { Vector3 } from "../../lib/threejs/math/Vector3";
import { Vector2 } from "../../lib/threejs/math/Vector2";

/**
 * Line Shader implementation
 */
ShaderModule(async function(shaderBuilder:ShaderBuilder) {

    shaderBuilder.createShader('lineshader', {
        redSettings: {
            isRawMaterial: true,
            lights: false,
            fog: false,
            cullFace: CullFaceNone,
            polygonOffset: true,
            polygonOffsetFactor: ScreenspaceLine.OffsetFactor,
            polygonOffsetUnits: ScreenspaceLine.OffsetUnits
        },
        uniforms:
        {
            clipBounds: { type: EUniformType.VECTOR3_ARRAY, value: [new Vector3(), new Vector3()], default: [new Vector3(), new Vector3()] },
            color: { type: EUniformType.VECTOR3, value: new Vector3(1.0, 1.0, 1.0), default: new Vector3(1.0, 1.0, 1.0) },
            screenSize: { type: EUniformType.VECTOR2, value: new Vector2(), default: new Vector2() },
            pixelRatio: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            opacity: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
            width: { type: EUniformType.FLOAT, value: 1.0, default: 1.0 },
        },
        variants: [ShaderVariant.DEFAULT],
        onPreRender(renderer:Render, camera:any, material:any, mesh:ScreenspaceLine|any, data:any):void {
            const shaderInterface = applyShaderToRenderer(renderer, material);

            // not applicable
            if(!shaderInterface) {
                return;
            }

            //const pixelRatio = renderer.webGLRender.getPixelRatio();
            const pixelRatio = renderer.size.width / renderer.size.height;
            setValueShader(shaderInterface, "pixelRatio", material, pixelRatio);
            setValueShader(shaderInterface, "clipBounds", material, mesh.clipBounds);
            //TODO: remove memory request...
            const size = renderer.webGLRender.getSize(math.tmpVec2());
            setValueShader(shaderInterface, "screenSize", material, size);
            setValueShader(shaderInterface, "color", material, data.baseColor );
            setValueShader(shaderInterface, "opacity", material, 1.0);
            setValueShader(shaderInterface, "width", material, mesh.lineWidth || 8.0);
        },
        vertexShaderSource: `
            precision highp float;

            attribute vec3 position;
            attribute vec3 nextPosition;
            attribute float arcLength;
            attribute float lineWidth;

            uniform vec2 screenSize;
            uniform float pixelRatio;

            //uniform mat4 model, view, projection;

            uniform mat4 modelViewMatrix;
            uniform mat4 projectionMatrix;
            uniform float width;

            varying vec3 worldPosition;
            varying float pixelArcLength;

            void main() {
                vec4 projected = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                vec4 tangentClip = projectionMatrix * modelViewMatrix * vec4(nextPosition.xyz - position.xyz, 0.0);
                vec2 tangent = normalize(screenSize * tangentClip.xy);
                vec2 offset = 0.5 * vec2(pixelRatio, 1.0) * lineWidth * width * vec2(tangent.y, -tangent.x) / screenSize;

                worldPosition = position;
                pixelArcLength = arcLength;

                gl_Position = vec4(projected.xy + projected.w * offset, projected.zw);
                //gl_Position = vec4(projected.xy + vec2(lineWidth,lineWidth), projected.zw);
            }

        `,
        fragmentShaderSource: `
            precision mediump float;

            uniform vec3 clipBounds[2];
            //uniform sampler2D dashTexture;
            //uniform float dashScale;

            uniform vec3 color;
            uniform float opacity;

            varying vec3 worldPosition;
            varying float pixelArcLength;

            void main() {
                //if(any(lessThan(worldPosition, clipBounds[0])) || any(greaterThan(worldPosition, clipBounds[1]))) {
                //    discard;
                //}
                //float dashWeight = texture2D(dashTexture, vec2(dashScale * pixelArcLength, 0)).r;
                //if(dashWeight < 0.5) {
                //    discard;
                //}
                //gl_FragColor = color * opacity;
                gl_FragColor = vec4(color.rgb, opacity);
            }

        `
    });

}, []);
