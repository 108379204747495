/**
 * DirectionalLightComponent.ts: directional light
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { DirectionalLight } from '../../lib/threejs/lights/DirectionalLight';
import { Color } from '../../lib/threejs/math/Color';
import { Vector3 } from '../../lib/threejs/math/Vector3';
import { CameraHelper } from '../../lib/threejs/helpers/CameraHelper';
import { DirectionalLightHelper } from '../../lib/threejs/helpers/DirectionalLightHelper';
import { build } from '../core/Build';
import {GraphicsDisposeSetup, destroyObject3D} from '../core/Globals';
import {Entity} from '../framework/Entity';
import {IONotifier} from '../io/Interfaces';
import {Component, ComponentData, registerComponent, ComponentId} from '../framework/Component';
import { ERenderLayer } from "../render/Layers";
import { ILightComponent, ELightType, queryLightSystem } from "../framework/LightAPI";


/**
 * DirectionalLightComponent class
 *
 *
 * ### Example:
 * ~~~~
 * {
 *       "module": "RED",
 *       "type": "DirectionalLightComponent",
 *       "parameters": {
 *           "target": [0, -50, -300.0],
 *           "color": [1,1,1],
 *           "intensity": 1.0,
 *           "castShadow": true,
 *           "debugHelper": true,
 *           "shadow" : {
 *               "bias": -0.01,
 *               "textureWidth": 2048,
 *               "textureHeight": 1024,
 *               "cameraLeft": -80,
 *               "cameraRight": 80,
 *               "cameraBottom": -49,
 *               "cameraTop": 49,
 *               "cameraFar": 125
 *           }
 *       }
 *   }
 * ~~~~
 */
export class DirectionalLightComponent extends Component implements ILightComponent {

    /** three js reference */
    public directionalLight:DirectionalLight = null;

    public get castShadow() {
        if(this.directionalLight) {
            return this.directionalLight.castShadow;
        }
        return false;
    }

    private directionalLightHelper:any = null;

    /** light component id */
    private _lightId: ComponentId;

    private _luminousPower: number;

    /** construct */
    constructor(entity:Entity) {
        super(entity);

        this._luminousPower = 80000.0;

        // Directional Light
        this.directionalLight = new DirectionalLight(0xFFFFFF, 1.0);
        this.directionalLight.name = "Directional Light";
        this.directionalLight.position.set(0.0, 0.0, 0.0);
        this.directionalLight.castShadow = false;

        //FIXME: target in world space? (add to root object?)
        this.threeJSScene.add(this.directionalLight.target);
        this.entity.add(this.directionalLight);

        this._lightId = queryLightSystem().registerLight(ELightType.Builtin_Directional, this, this.entity);
    }

    public destroy(dispose?:GraphicsDisposeSetup) {
        this._updateHelper(false, false);

        queryLightSystem().removeLight(this._lightId);

        if(this.directionalLight) {
            this.threeJSScene.remove(this.directionalLight.target);
            this.entity.remove(this.directionalLight);
        }
        super.destroy(dispose);
    }

    public onTransformUpdate() {
        if(this.directionalLight) {
            const transform = new Vector3(0, 0, 1).applyMatrix4(this.entity.matrixWorld);
            this.directionalLight.target.position.copy(transform);
        }
    }

    public setIntensity(luminousPower:number) {
        // li = lp
        this._luminousPower = luminousPower;
        if(this.directionalLight) {
            this.directionalLight.intensity = this._luminousPower;
        }
    }

    /** load component */
    public load(data:ComponentData, ioNotifier?:IONotifier, prefab?: any) {
        super.load(data, ioNotifier, prefab);

        const debugHelper:boolean = data.parameters.debugHelper || build.Options.isEditor || false;
        const realtimeShadows:boolean = data.parameters.castShadow === true;
        let color = data.parameters.color || 0xffffff;

        // support for rgb color
        if(Array.isArray(color)) {
            color = new Color().fromArray(color).getHex();
        } else {
            color = new Color(color);
        }

        this._luminousPower = data.parameters.intensity === undefined ? this._luminousPower : data.parameters.intensity;

        this.directionalLight.color.fromArray(data.parameters.color);
        this.directionalLight.intensity = this._luminousPower;
        this.entity.updateTransform(true);

        if(realtimeShadows) {
            this.directionalLight.castShadow = realtimeShadows;

            if(!data.parameters.shadow) {
                data.parameters.shadow = {
                    bias: 0.0,
                    textureWidth: 512,
                    textureHeight: 512,
                    cameraWidth: 10,
                    cameraHeight: 10,
                    cameraFar: 10,
                };
            }

            this.directionalLight.shadow.bias = data.parameters.shadow.bias;
            this.directionalLight.shadow.mapSize.x = data.parameters.shadow.textureWidth;
            this.directionalLight.shadow.mapSize.y = data.parameters.shadow.textureHeight;
            this.directionalLight.shadow.camera.right = (data.parameters.shadow.cameraWidth * 0.5);
            this.directionalLight.shadow.camera.left = -(data.parameters.shadow.cameraWidth * 0.5);
            this.directionalLight.shadow.camera.top = (data.parameters.shadow.cameraHeight * 0.5);
            this.directionalLight.shadow.camera.bottom = -(data.parameters.shadow.cameraHeight * 0.5);
            this.directionalLight.shadow.camera.near = 0.1;
            this.directionalLight.shadow.camera.far = data.parameters.shadow.cameraFar;
        } else {
            this.directionalLight.castShadow = false;
        }

        if(this.directionalLightHelper && this.directionalLightHelper.update) {
            this.directionalLight.updateMatrixWorld(true);
            this.directionalLightHelper.update();
        }

        //directionalLight helper
        this._updateHelper(debugHelper, realtimeShadows);
    }

    /** save component */
    public save() : ComponentData {
        const node = {
            module: "RED",
            type: "DirectionalLightComponent",
            parameters: {
                color: [1,1,1],
                intensity: 1.0,
                castShadow: false,
                debugHelper: false,
                shadow: {
                    bias: 0.0,
                    textureWidth: 512,
                    textureHeight: 512,
                    cameraWidth: 10,
                    cameraHeight: 10,
                    cameraFar: 10,
                }
            }
        };

        if(this.directionalLight) {

            node.parameters.color[0] = this.directionalLight.color.r;
            node.parameters.color[1] = this.directionalLight.color.g;
            node.parameters.color[2] = this.directionalLight.color.b;

            node.parameters.intensity = this._luminousPower;

            node.parameters.castShadow = this.directionalLight.castShadow;

            if(this.directionalLight.castShadow) {
                node.parameters.shadow = {
                   bias: this.directionalLight.shadow.bias,
                   textureWidth: this.directionalLight.shadow.mapSize.x,
                   textureHeight: this.directionalLight.shadow.mapSize.y,
                   cameraWidth: (this.directionalLight.shadow.camera.right - this.directionalLight.shadow.camera.left),
                   cameraHeight: (this.directionalLight.shadow.camera.top - this.directionalLight.shadow.camera.bottom),
                   cameraFar: this.directionalLight.shadow.camera.far
                };
            }
        }

        return node;
    }

    private _updateHelper(debugHelper:boolean, realtimeShadows:boolean) {

        if(this.directionalLightHelper) {
            this.threeJSScene.remove(this.directionalLightHelper);
            destroyObject3D(this.directionalLightHelper);
        }

        //directionalLight helper
        if(debugHelper && realtimeShadows) {
            this.directionalLightHelper = new CameraHelper( this.directionalLight.shadow.camera );
            this.directionalLightHelper.name = "Shadow Camera DirectionalLight";
            this.directionalLightHelper.layers.set(ERenderLayer.Widgets);
            this.directionalLightHelper.update();
            this.threeJSScene.add(this.directionalLightHelper);
        } else if(debugHelper) {
            this.directionalLightHelper = new DirectionalLightHelper(this.directionalLight, 1.0);
            this.directionalLightHelper.name = "DirectionalLight Helper";
            this.directionalLightHelper.layers.set(ERenderLayer.Widgets);
            this.threeJSScene.add(this.directionalLightHelper);
        }
    }

}

/** register component */
registerComponent("RED", "DirectionalLightComponent", DirectionalLightComponent);
