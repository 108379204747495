
/**
 * Generated.ts: auto generated builtin shader code
 *
 * Copyright redPlant GmbH 2016-2017
 * @author Lutz Hören
 */
import {ShaderChunk} from '../ShaderBuilder';
/* tslint:disable */

ShaderChunk['redBackground_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
uniform vec3 diffuse;
uniform float opacity;
#ifdef SAMPLER_CUBE
uniform samplerCube map;
#else
uniform sampler2D map;
#endif
varying vec2 vUv;
#ifdef ENVMAP
varying vec3 direction;
#endif
uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
vec3 bilinearFilter (sampler2D tex, vec2 uv)
{
    const float imageWidth = 2048.0;
    const float imageHeight = 2048.0;
    const vec2 texelSize = vec2(1.0 / imageWidth, 1.0 / imageHeight);
    vec2 fUv = vec2(uv.x * imageWidth, uv.y *imageHeight);
    fUv = fract(fUv);
    uv.x = floor(uv.x * imageWidth) / imageWidth;
    uv.y = floor(uv.y * imageHeight) / imageHeight;
    vec3 tl = RGBMDecode(texture2D(tex, uv));
    vec3 tr = RGBMDecode(texture2D(tex, uv + vec2(texelSize.x, 0.0)));
    vec3 bl = RGBMDecode(texture2D(tex, uv + vec2(0.0, texelSize.y)));
    vec3 br = RGBMDecode(texture2D(tex, uv + vec2(texelSize.x, texelSize.y)));
    vec3 a = mix(tl, tr, fUv.x);
    vec3 b = mix(bl, br, fUv.x);
    return mix(a, b, fUv.y);
}
void main() {
    vec4 diffuseColor = vec4(diffuse, opacity);
    #ifdef ENVMAP
        float flipNormal = 1.0;
        #ifdef SAMPLER_CUBE
            vec3 queryReflectVec = flipNormal * direction;
            #ifdef TEXTURE_LOD_EXT
                vec4 texelColor = textureCubeLodEXT(map, queryReflectVec, 0.0);
            #else
                vec4 texelColor = textureCube(map, queryReflectVec);
            #endif
        #else
            vec2 sampleUV;
            sampleUV.y = acos(-direction.y) * RECIPROCAL_PI;
            sampleUV.x = atan(flipNormal * direction.z, flipNormal * direction.x) * RECIPROCAL_PI2 + 0.5;
            #ifdef RGBM
                #if (defined(HIGH_QUALITY) && HIGH_QUALITY == 1) || (defined(MEDIUM_QUALITY) && MEDIUM_QUALITY == 1)
                    vec3 texelColor = bilinearFilter(map, sampleUV);
                #else
                    vec3 texelColor = RGBMDecode(texture2D(tex, sampleUV));
                #endif
            #else
                #ifdef TEXTURE_LOD_EXT
                    vec3 texelColor = texture2DLodEXT(map, sampleUV, 0.0).rgb;
                #else
                    vec3 texelColor = texture2D(map, sampleUV).rgb;
                #endif
            #endif
        #endif
        texelColor.rgb *= 30000.0;
        texelColor.rgb = toneMapping(texelColor.rgb, toneMappingExposure, toneMappingWhitePoint);
        diffuseColor.rgb = linearToOutput(texelColor.rgb);
    #else
        vec4 texelColor = texture2D(map, vUv);
        texelColor.rgb = linearToOutput(texelColor.rgb);
        diffuseColor.rgb *= texelColor.rgb;
    #endif
    gl_FragColor = diffuseColor;
}
`;
ShaderChunk['redBackground_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
attribute vec3 position;
attribute vec2 uv;
varying vec2 vUv;
varying vec4 worldPosition;
uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform vec4 offsetRepeat;
#ifdef ENVMAP
	varying vec3 direction;
#endif
void main() {
	vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
	worldPosition = modelMatrix * vec4(position, 1.0);
#ifdef ENVMAP
	direction = normalize(worldPosition.xyz);
#endif
	gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redBlur_h_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
uniform sampler2D map;
uniform float widthPixel;
varying vec2 vUv;
void main() {
    vec4 sum = vec4(0.0);
    sum += texture2D(map, vec2(vUv.x - 4.0 * widthPixel, vUv.y)) * 0.051;
    sum += texture2D(map, vec2(vUv.x - 3.0 * widthPixel, vUv.y)) * 0.0918;
    sum += texture2D(map, vec2(vUv.x - 2.0 * widthPixel, vUv.y)) * 0.12245;
    sum += texture2D(map, vec2(vUv.x - 1.0 * widthPixel, vUv.y)) * 0.1531;
    sum += texture2D(map, vec2(vUv.x, vUv.y)) * 0.1633;
    sum += texture2D(map, vec2(vUv.x + 1.0 * widthPixel, vUv.y)) * 0.1531;
    sum += texture2D(map, vec2(vUv.x + 2.0 * widthPixel, vUv.y)) * 0.12245;
    sum += texture2D(map, vec2(vUv.x + 3.0 * widthPixel, vUv.y)) * 0.0918;
    sum += texture2D(map, vec2(vUv.x + 4.0 * widthPixel, vUv.y)) * 0.051;
    gl_FragColor = sum;
}`;
ShaderChunk['redBlur_v_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
uniform sampler2D map;
uniform float heightPixel;
varying vec2 vUv;
void main() {
    vec4 sum = vec4(0.0);
    sum += texture2D(map, vec2(vUv.x, vUv.y - 4.0 * heightPixel)) * 0.051;
    sum += texture2D(map, vec2(vUv.x, vUv.y - 3.0 * heightPixel)) * 0.0918;
    sum += texture2D(map, vec2(vUv.x, vUv.y - 2.0 * heightPixel)) * 0.12245;
    sum += texture2D(map, vec2(vUv.x, vUv.y - 1.0 * heightPixel)) * 0.1531;
    sum += texture2D(map, vec2(vUv.x, vUv.y)) * 0.1633;
    sum += texture2D(map, vec2(vUv.x, vUv.y + 1.0 * heightPixel)) * 0.1531;
    sum += texture2D(map, vec2(vUv.x, vUv.y + 2.0 * heightPixel)) * 0.12245;
    sum += texture2D(map, vec2(vUv.x, vUv.y + 3.0 * heightPixel)) * 0.0918;
    sum += texture2D(map, vec2(vUv.x, vUv.y + 4.0 * heightPixel)) * 0.051;
    gl_FragColor = sum;
}`;
ShaderChunk['redBlur_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
attribute vec3 position;
attribute vec2 uv;
varying vec2 vUv;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redBSDFFunctions'] = `bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
#define saturateMediump(x) min(x, 65504.0)
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
vec3 BRDF_Specular_BlinnPhong(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float shininess) {
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_BlinnPhong_Implicit();
    float D = D_BlinnPhong(shininess, dotNH);
    return F *(G * D);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
#if defined(RED_STANDARD)
struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define RE_Direct	RE_Direct_Standard
#define RE_DirectArea	RE_DirectArea_Standard
#define RE_IndirectDiffuse	RE_IndirectDiffuse_Standard
#define RE_IndirectSpecular	RE_IndirectSpecular_Standard
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
#elif defined(RED_CLEARCOAT)
struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define RE_Direct RE_Direct_ClearCoat
#define RE_DirectArea RE_DirectArea_ClearCoat
#define RE_IndirectDiffuse RE_IndirectDiffuse_ClearCoat
#define RE_IndirectSpecular RE_IndirectSpecular_ClearCoat
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)
#elif defined(RED_ANISOTROPIC)
struct AnisotropicMaterial {
    vec3 diffuseColor;
    float roughness;
    float anisotropy;
    vec3 f0;
    vec3 tangent;
    vec3 bitangent;
};
void RE_Direct_Anisotropic(const in IncidentLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_DirectArea_Anisotropic(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent, lightRadius);
}
void RE_IndirectDiffuse_Anisotropic(const in vec3 irradiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Anisotropic(const in vec3 radiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.f0, material.roughness);
}
#define RE_Direct RE_Direct_Anisotropic
#define RE_IndirectDiffuse RE_IndirectDiffuse_Anisotropic
#define RE_IndirectSpecular RE_IndirectSpecular_Anisotropic
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.roughness)
#elif defined(RED_CLOTH)
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define RE_Direct RE_Direct_Cloth
#define RE_IndirectDiffuse RE_IndirectDiffuse_Cloth
#define RE_IndirectSpecular RE_IndirectSpecular_Cloth
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#endif
`;
ShaderChunk['redCommon'] = `#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}`;
ShaderChunk['redDirectLight_Standard'] = `#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_DIR_LIGHTS > 0
uniform DirectionalLight directionalLights[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
uniform sampler2D directionalShadowMap[NUM_DIR_LIGHTS];
varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_COUNT];
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float cameraExposure, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0) && defined(RE_Direct)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(pointLight.shadow, directLight.visible)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0) && defined(RE_Direct)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(spotLight.shadow, directLight.visible)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if ((NUM_DIR_LIGHTS > 0) || (RED_LIGHTS_DIRECTIONAL_COUNT > 0)) && defined(RE_Direct)
    DirectionalLight directionalLight;
#endif
#if (NUM_DIR_LIGHTS > 0) && defined(RE_Direct)
    for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
        directionalLight = directionalLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? getShadow(directionalShadowMap[i], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0 && defined(RE_Direct)
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        #ifdef RED_USE_SHADOWMAP
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius, poissonSamples);
                #endif
                directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? shadow : 1.0;
           }
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0 && defined(RE_Direct)
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0 && defined(RE_DirectArea)
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0 && defined(RE_DirectArea)
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}`;
ShaderChunk['redFunctions'] = `#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif`;
ShaderChunk['redFXAA_Pixel'] = `uniform sampler2D tDiffuse;
uniform vec2 resolution;
#define FXAA_REDUCE_MIN   (1.0/128.0)
#define FXAA_REDUCE_MUL   (1.0/8.0)
#define FXAA_SPAN_MAX     8.0
void main() {
    vec3 rgbNW = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(-1.0, -1.0)) * resolution).xyz;
    vec3 rgbNE = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(1.0, -1.0)) * resolution).xyz;
    vec3 rgbSW = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(-1.0, 1.0)) * resolution).xyz;
    vec3 rgbSE = texture2D(tDiffuse,(gl_FragCoord.xy + vec2(1.0, 1.0)) * resolution).xyz;
    vec4 rgbaM  = texture2D(tDiffuse,  gl_FragCoord.xy  * resolution);
    vec3 rgbM  = rgbaM.xyz;
    vec3 luma = vec3(0.299, 0.587, 0.114);
    float lumaNW = dot(rgbNW, luma);
    float lumaNE = dot(rgbNE, luma);
    float lumaSW = dot(rgbSW, luma);
    float lumaSE = dot(rgbSE, luma);
    float lumaM  = dot(rgbM,  luma);
    float lumaMin = min(lumaM, min(min(lumaNW, lumaNE), min(lumaSW, lumaSE)));
    float lumaMax = max(lumaM, max(max(lumaNW, lumaNE) , max(lumaSW, lumaSE)));
    vec2 dir;
    dir.x = -((lumaNW + lumaNE) - (lumaSW + lumaSE));
    dir.y =  ((lumaNW + lumaSW) - (lumaNE + lumaSE));
    float dirReduce = max((lumaNW + lumaNE + lumaSW + lumaSE) *(0.25 * FXAA_REDUCE_MUL), FXAA_REDUCE_MIN);
    float rcpDirMin = 1.0 /(min(abs(dir.x), abs(dir.y)) + dirReduce);
    dir = min(vec2(FXAA_SPAN_MAX,  FXAA_SPAN_MAX), max(vec2(-FXAA_SPAN_MAX, -FXAA_SPAN_MAX), dir * rcpDirMin)) * resolution;
    vec4 rgbA = (1.0/2.0) * (
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (1.0/3.0 - 0.5)) +
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (2.0/3.0 - 0.5)));
    vec4 rgbB = rgbA * (1.0/2.0) + (1.0/4.0) * (
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (0.0/3.0 - 0.5)) +
        texture2D(tDiffuse,  gl_FragCoord.xy  * resolution + dir * (3.0/3.0 - 0.5)));
    float lumaB = dot(rgbB, vec4(luma, 0.0));
    if((lumaB < lumaMin) ||(lumaB > lumaMax)) {
        gl_FragColor = rgbA;
   } else {
        gl_FragColor = rgbB;
   }
}`;
ShaderChunk['redFXAA_Vertex'] = `void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redIndirectLight_Standard'] = `#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#endif
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
void evaluate_indirect_diffuse(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
}
void evaluate_indirect_specular(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
`;
ShaderChunk['redLightFunctions'] = `vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if NUM_DIR_LIGHTS > 0 || 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif`;
ShaderChunk['redNormal_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_NORMAL
#define RED_NORMAL
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
#define saturateMediump(x) min(x, 65504.0)
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
vec3 BRDF_Specular_BlinnPhong(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float shininess) {
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_BlinnPhong_Implicit();
    float D = D_BlinnPhong(shininess, dotNH);
    return F *(G * D);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
#if defined(RED_STANDARD)
struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define RE_Direct	RE_Direct_Standard
#define RE_DirectArea	RE_DirectArea_Standard
#define RE_IndirectDiffuse	RE_IndirectDiffuse_Standard
#define RE_IndirectSpecular	RE_IndirectSpecular_Standard
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
#elif defined(RED_CLEARCOAT)
struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define RE_Direct RE_Direct_ClearCoat
#define RE_DirectArea RE_DirectArea_ClearCoat
#define RE_IndirectDiffuse RE_IndirectDiffuse_ClearCoat
#define RE_IndirectSpecular RE_IndirectSpecular_ClearCoat
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)
#elif defined(RED_ANISOTROPIC)
struct AnisotropicMaterial {
    vec3 diffuseColor;
    float roughness;
    float anisotropy;
    vec3 f0;
    vec3 tangent;
    vec3 bitangent;
};
void RE_Direct_Anisotropic(const in IncidentLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_DirectArea_Anisotropic(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent, lightRadius);
}
void RE_IndirectDiffuse_Anisotropic(const in vec3 irradiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Anisotropic(const in vec3 radiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.f0, material.roughness);
}
#define RE_Direct RE_Direct_Anisotropic
#define RE_IndirectDiffuse RE_IndirectDiffuse_Anisotropic
#define RE_IndirectSpecular RE_IndirectSpecular_Anisotropic
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.roughness)
#elif defined(RED_CLOTH)
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define RE_Direct RE_Direct_Cloth
#define RE_IndirectDiffuse RE_IndirectDiffuse_Cloth
#define RE_IndirectSpecular RE_IndirectSpecular_Cloth
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#endif

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if NUM_DIR_LIGHTS > 0 || 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    return step(compare, decodeRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 0
#endif
#if PPCF_ROTATED == 1
float randNoise(vec2 co){
   return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius, const in vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES]) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randNoise(gl_FragCoord.xy) * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[0] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[0] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[1] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[1] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[2] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[2] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[3] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[3] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[4] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[4] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[5] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[5] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[6] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[6] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[7] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[7] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[8] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[8] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[9] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[9] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[10] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[10] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[11] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[11] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[12] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[12] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[13] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[13] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[14] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[14] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[15] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[15] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[16] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[16] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[17] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[17] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[18] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[18] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[19] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[19] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[20] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[20] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[21] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[21] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[22] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[22] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[23] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[23] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[24] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[24] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[25] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[25] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[26] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[26] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[27] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[27] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[28] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[28] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[29] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[29] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[30] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[30] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[31] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[31] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
    return shadow / 32.0;
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[4] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[5] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[6] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[7] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 9.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
#if defined(USE_AOMAP)
    varying vec2 vUv2;
#endif
#if defined(USE_NORMALMAP)
uniform vec2 normalScale;
uniform sampler2D normalMap;
#endif
void main() {
    vec3 normal = normalize(vNormal);
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#endif
    normal = inverseTransformDirection(normal, viewMatrix);
    gl_FragColor = vec4(normal * 0.5 + 0.5, 1.0);
}
`;
ShaderChunk['redPackedBlur_h_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
uniform sampler2D map;
uniform float widthPixel;
varying vec2 vUv;
void main() {
    float sum = 0.0;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 4.0 * widthPixel, vUv.y)))* 0.051;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 3.0 * widthPixel, vUv.y)))* 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 2.0 * widthPixel, vUv.y)))* 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x - 1.0 * widthPixel, vUv.y)))* 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y))) * 0.1633;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 1.0 * widthPixel, vUv.y)))* 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 2.0 * widthPixel, vUv.y)))* 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 3.0 * widthPixel, vUv.y)))* 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x + 4.0 * widthPixel, vUv.y)))* 0.051;
    gl_FragColor = packDepthToRGBA(sum);
}`;
ShaderChunk['redPackedBlur_v_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
uniform sampler2D map;
uniform float heightPixel;
varying vec2 vUv;
void main() {
    float sum = 0.0;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 4.0 * heightPixel))) * 0.051;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 3.0 * heightPixel))) * 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 2.0 * heightPixel))) * 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y - 1.0 * heightPixel))) * 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y))) * 0.1633;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 1.0 * heightPixel))) * 0.1531;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 2.0 * heightPixel))) * 0.12245;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 3.0 * heightPixel))) * 0.0918;
    sum += unpackRGBAToDepth(texture2D(map, vec2(vUv.x, vUv.y + 4.0 * heightPixel))) * 0.051;
    gl_FragColor = packDepthToRGBA(sum);
}`;
ShaderChunk['redPacking'] = `#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}`;
ShaderChunk['redPrecision'] = `precision highp float;
precision highp int;
precision highp sampler2D;`;
ShaderChunk['redQTangent'] = `vec3 xAxis(vec4 qQuat)
{
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwy = fTy * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxy = fTy * qQuat.x;
    float fTxz = fTz * qQuat.x;
    float fTyy = fTy * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(1.0-(fTyy+fTzz), fTxy+fTwz, fTxz-fTwy);
}
vec3 yAxis(vec4 qQuat)
{
    float fTx  = 2.0 * qQuat.x;
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwx = fTx * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxx = fTx * qQuat.x;
    float fTxy = fTy * qQuat.x;
    float fTyz = fTz * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(fTxy-fTwz, 1.0-(fTxx+fTzz), fTyz+fTwx);
}
`;
ShaderChunk['redShadowFunctions'] = `#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    return step(compare, decodeRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 0
#endif
#if PPCF_ROTATED == 1
float randNoise(vec2 co){
   return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius, const in vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES]) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randNoise(gl_FragCoord.xy) * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[0] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[0] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[1] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[1] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[2] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[2] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[3] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[3] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[4] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[4] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[5] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[5] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[6] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[6] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[7] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[7] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[8] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[8] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[9] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[9] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[10] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[10] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[11] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[11] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[12] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[12] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[13] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[13] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[14] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[14] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[15] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[15] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[16] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[16] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[17] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[17] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[18] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[18] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[19] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[19] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[20] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[20] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[21] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[21] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[22] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[22] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[23] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[23] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[24] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[24] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[25] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[25] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[26] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[26] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[27] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[27] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[28] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[28] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[29] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[29] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[30] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[30] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[31] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[31] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
    return shadow / 32.0;
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[4] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[5] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[6] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[7] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 9.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif`;
ShaderChunk['redSSAO_Pixel'] = `uniform float cameraNear;
uniform float cameraFar;
#ifdef USE_LOGDEPTHBUF
    uniform float logDepthBufFC;
#endif
uniform bool onlyAO;
uniform vec2 size;
uniform float aoClamp;
uniform float lumInfluence;
uniform sampler2D tDiffuse;
uniform sampler2D tDepth;
varying vec2 vUv;
#define DL 2.399963229728653
#define EULER 2.718281828459045
const int samples = 8;
const float radius = 5.0;
const bool useNoise = false;
const float noiseAmount = 0.0003;
const float diffArea = 0.4;
const float gDisplace = 0.4;
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
vec2 rand(const vec2 coord) {
    vec2 noise;
    if(useNoise) {
        float nx = dot(coord, vec2(12.9898, 78.233));
        float ny = dot(coord, vec2(12.9898, 78.233) * 2.0);
        noise = clamp(fract(43758.5453 * sin(vec2(nx, ny))), 0.0, 1.0);
   } else {
        float ff = fract(1.0 - coord.s *(size.x / 2.0));
        float gg = fract(coord.t *(size.y / 2.0));
        noise = vec2(0.25, 0.75) * vec2(ff) + vec2(0.75, 0.25) * gg;
   }
    return(noise * 2.0  - 1.0) * noiseAmount;
}
float readDepth(const in vec2 coord) {
    float cameraFarPlusNear = cameraFar + cameraNear;
    float cameraFarMinusNear = cameraFar - cameraNear;
    float cameraCoef = 2.0 * cameraNear;
    #ifdef USE_LOGDEPTHBUF
        float logz = unpackRGBAToDepth(texture2D(tDepth, coord));
        float w = pow(2.0, (logz / logDepthBufFC)) - 1.0;
        float z = (logz / w) + 1.0;
    #else
        float z = unpackRGBAToDepth(texture2D(tDepth, coord));
    #endif
    return cameraCoef /(cameraFarPlusNear - z * cameraFarMinusNear);
}
float compareDepths(const in float depth1, const in float depth2, inout int far) {
    float garea = 2.0;
    float diff =(depth1 - depth2) * 100.0;
    if(diff < gDisplace) {
        garea = diffArea;
   } else {
        far = 1;
   }
    float dd = diff - gDisplace;
    float gauss = pow(EULER, -2.0 * dd * dd /(garea * garea));
    return gauss;
}
float calcAO(float depth, float dw, float dh) {
    float dd = radius - depth * radius;
    vec2 vv = vec2(dw, dh);
    vec2 coord1 = vUv + dd * vv;
    vec2 coord2 = vUv - dd * vv;
    float temp1 = 0.0;
    float temp2 = 0.0;
    int far = 0;
    temp1 = compareDepths(depth, readDepth(coord1), far);
    if(far > 0) {
        temp2 = compareDepths(readDepth(coord2), depth, far);
        temp1 +=(1.0 - temp1) * temp2;
   }
    return temp1;
}
void main() {
    vec2 noise = rand(vUv);
    float depth = readDepth(vUv);
    float tt = clamp(depth, aoClamp, 1.0);
    float w =(1.0 / size.x)  / tt +(noise.x *(1.0 - noise.x));
    float h =(1.0 / size.y) / tt +(noise.y *(1.0 - noise.y));
    float ao = 0.0;
    float dz = 1.0 / float(samples);
    float z = 1.0 - dz / 2.0;
    float l = 0.0;
    for(int i = 0; i <= samples; i++) {
        float r = sqrt(1.0 - z);
        float pw = cos(l) * r;
        float ph = sin(l) * r;
        ao += calcAO(depth, pw * w, ph * h);
        z = z - dz;
        l = l + DL;
   }
    ao /= float(samples);
    ao = 1.0 - ao;
    vec3 final;
    if(onlyAO) {
        vec3 luminance = vec3(1.0);
        final = vec3(mix(vec3(ao), vec3(1.0), luminance * lumInfluence));
   } else {
        vec3 color = texture2D(tDiffuse, vUv).rgb;
        vec3 lumcoeff = vec3(0.299, 0.587, 0.114);
        float lum = dot(color.rgb, lumcoeff);
        vec3 luminance = vec3(lum);
        final = vec3(color * mix(vec3(ao), vec3(1.0), luminance * lumInfluence));
   }
    gl_FragColor = vec4(final, 1.0);
}`;
ShaderChunk['redSSAO_Vertex'] = `varying vec2 vUv;
void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;
ShaderChunk['redStandard_Diffuse_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_STANDARD
#define RED_STANDARD
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
#define saturateMediump(x) min(x, 65504.0)
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
vec3 BRDF_Specular_BlinnPhong(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float shininess) {
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_BlinnPhong_Implicit();
    float D = D_BlinnPhong(shininess, dotNH);
    return F *(G * D);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
#if defined(RED_STANDARD)
struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define RE_Direct	RE_Direct_Standard
#define RE_DirectArea	RE_DirectArea_Standard
#define RE_IndirectDiffuse	RE_IndirectDiffuse_Standard
#define RE_IndirectSpecular	RE_IndirectSpecular_Standard
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
#elif defined(RED_CLEARCOAT)
struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define RE_Direct RE_Direct_ClearCoat
#define RE_DirectArea RE_DirectArea_ClearCoat
#define RE_IndirectDiffuse RE_IndirectDiffuse_ClearCoat
#define RE_IndirectSpecular RE_IndirectSpecular_ClearCoat
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)
#elif defined(RED_ANISOTROPIC)
struct AnisotropicMaterial {
    vec3 diffuseColor;
    float roughness;
    float anisotropy;
    vec3 f0;
    vec3 tangent;
    vec3 bitangent;
};
void RE_Direct_Anisotropic(const in IncidentLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_DirectArea_Anisotropic(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent, lightRadius);
}
void RE_IndirectDiffuse_Anisotropic(const in vec3 irradiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Anisotropic(const in vec3 radiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.f0, material.roughness);
}
#define RE_Direct RE_Direct_Anisotropic
#define RE_IndirectDiffuse RE_IndirectDiffuse_Anisotropic
#define RE_IndirectSpecular RE_IndirectSpecular_Anisotropic
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.roughness)
#elif defined(RED_CLOTH)
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define RE_Direct RE_Direct_Cloth
#define RE_IndirectDiffuse RE_IndirectDiffuse_Cloth
#define RE_IndirectSpecular RE_IndirectSpecular_Cloth
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#endif

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if NUM_DIR_LIGHTS > 0 || 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    return step(compare, decodeRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 0
#endif
#if PPCF_ROTATED == 1
float randNoise(vec2 co){
   return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius, const in vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES]) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randNoise(gl_FragCoord.xy) * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[0] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[0] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[1] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[1] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[2] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[2] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[3] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[3] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[4] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[4] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[5] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[5] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[6] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[6] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[7] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[7] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[8] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[8] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[9] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[9] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[10] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[10] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[11] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[11] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[12] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[12] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[13] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[13] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[14] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[14] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[15] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[15] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[16] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[16] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[17] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[17] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[18] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[18] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[19] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[19] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[20] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[20] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[21] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[21] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[22] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[22] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[23] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[23] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[24] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[24] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[25] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[25] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[26] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[26] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[27] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[27] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[28] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[28] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[29] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[29] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[30] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[30] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[31] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[31] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
    return shadow / 32.0;
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[4] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[5] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[6] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[7] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 9.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform float reflectance;
#if defined(USE_NORMALMAP)
uniform vec2 normalScale;
uniform sampler2D normalMap;
#endif
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_DIR_LIGHTS > 0
uniform DirectionalLight directionalLights[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
uniform sampler2D directionalShadowMap[NUM_DIR_LIGHTS];
varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_COUNT];
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float cameraExposure, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0) && defined(RE_Direct)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(pointLight.shadow, directLight.visible)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0) && defined(RE_Direct)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(spotLight.shadow, directLight.visible)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if ((NUM_DIR_LIGHTS > 0) || (RED_LIGHTS_DIRECTIONAL_COUNT > 0)) && defined(RE_Direct)
    DirectionalLight directionalLight;
#endif
#if (NUM_DIR_LIGHTS > 0) && defined(RE_Direct)
    for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
        directionalLight = directionalLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? getShadow(directionalShadowMap[i], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0 && defined(RE_Direct)
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        #ifdef RED_USE_SHADOWMAP
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius, poissonSamples);
                #endif
                directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? shadow : 1.0;
           }
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0 && defined(RE_Direct)
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0 && defined(RE_DirectArea)
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0 && defined(RE_DirectArea)
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#endif
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
void evaluate_indirect_diffuse(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
}
void evaluate_indirect_specular(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}

uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec3 diffuseColor = inputToLinear(texture2D(baseColorMap, vUv).rgb);
    diffuseColor.rgb *= baseColor;
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    vec3 normal = normalize(tsn * mapN);
#else
    vec3 normal = normalize(vNormal);
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale * vec2(1.0, -1.0), normal);
#endif
#endif
    StandardMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
#if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
#else
    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
#endif
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, toneMappingExposure, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_diffuse(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
    outgoingLight = toneMapping(outgoingLight, 1.0, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
    gl_FragColor = vec4(outgoingLight, 1.0);
}
`;
ShaderChunk['redStandard_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_STANDARD
#define RED_STANDARD
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
#define saturateMediump(x) min(x, 65504.0)
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
vec3 BRDF_Specular_BlinnPhong(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float shininess) {
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_BlinnPhong_Implicit();
    float D = D_BlinnPhong(shininess, dotNH);
    return F *(G * D);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
#if defined(RED_STANDARD)
struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define RE_Direct	RE_Direct_Standard
#define RE_DirectArea	RE_DirectArea_Standard
#define RE_IndirectDiffuse	RE_IndirectDiffuse_Standard
#define RE_IndirectSpecular	RE_IndirectSpecular_Standard
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
#elif defined(RED_CLEARCOAT)
struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define RE_Direct RE_Direct_ClearCoat
#define RE_DirectArea RE_DirectArea_ClearCoat
#define RE_IndirectDiffuse RE_IndirectDiffuse_ClearCoat
#define RE_IndirectSpecular RE_IndirectSpecular_ClearCoat
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)
#elif defined(RED_ANISOTROPIC)
struct AnisotropicMaterial {
    vec3 diffuseColor;
    float roughness;
    float anisotropy;
    vec3 f0;
    vec3 tangent;
    vec3 bitangent;
};
void RE_Direct_Anisotropic(const in IncidentLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_DirectArea_Anisotropic(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent, lightRadius);
}
void RE_IndirectDiffuse_Anisotropic(const in vec3 irradiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Anisotropic(const in vec3 radiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.f0, material.roughness);
}
#define RE_Direct RE_Direct_Anisotropic
#define RE_IndirectDiffuse RE_IndirectDiffuse_Anisotropic
#define RE_IndirectSpecular RE_IndirectSpecular_Anisotropic
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.roughness)
#elif defined(RED_CLOTH)
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define RE_Direct RE_Direct_Cloth
#define RE_IndirectDiffuse RE_IndirectDiffuse_Cloth
#define RE_IndirectSpecular RE_IndirectSpecular_Cloth
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#endif

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if NUM_DIR_LIGHTS > 0 || 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    return step(compare, decodeRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 0
#endif
#if PPCF_ROTATED == 1
float randNoise(vec2 co){
   return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius, const in vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES]) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randNoise(gl_FragCoord.xy) * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[0] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[0] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[1] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[1] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[2] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[2] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[3] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[3] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[4] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[4] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[5] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[5] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[6] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[6] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[7] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[7] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[8] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[8] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[9] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[9] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[10] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[10] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[11] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[11] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[12] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[12] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[13] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[13] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[14] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[14] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[15] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[15] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[16] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[16] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[17] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[17] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[18] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[18] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[19] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[19] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[20] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[20] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[21] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[21] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[22] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[22] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[23] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[23] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[24] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[24] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[25] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[25] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[26] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[26] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[27] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[27] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[28] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[28] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[29] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[29] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[30] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[30] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[31] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[31] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
    return shadow / 32.0;
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[4] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[5] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[6] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[7] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 9.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform float reflectance;
#if defined(USE_NORMALMAP)
uniform vec2 normalScale;
uniform sampler2D normalMap;
#endif
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_DIR_LIGHTS > 0
uniform DirectionalLight directionalLights[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
uniform sampler2D directionalShadowMap[NUM_DIR_LIGHTS];
varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_COUNT];
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float cameraExposure, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0) && defined(RE_Direct)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(pointLight.shadow, directLight.visible)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0) && defined(RE_Direct)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(spotLight.shadow, directLight.visible)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if ((NUM_DIR_LIGHTS > 0) || (RED_LIGHTS_DIRECTIONAL_COUNT > 0)) && defined(RE_Direct)
    DirectionalLight directionalLight;
#endif
#if (NUM_DIR_LIGHTS > 0) && defined(RE_Direct)
    for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
        directionalLight = directionalLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? getShadow(directionalShadowMap[i], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0 && defined(RE_Direct)
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        #ifdef RED_USE_SHADOWMAP
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius, poissonSamples);
                #endif
                directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? shadow : 1.0;
           }
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0 && defined(RE_Direct)
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0 && defined(RE_DirectArea)
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0 && defined(RE_DirectArea)
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#endif
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
void evaluate_indirect_diffuse(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
}
void evaluate_indirect_specular(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}

uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec3 diffuseColor = inputToLinear(texture2D(baseColorMap, vUv).rgb);
    diffuseColor.rgb *= baseColor;
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    vec3 normal = normalize(tsn * mapN);
#else
    vec3 normal = normalize(vNormal);
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale * vec2(1.0, -1.0), normal);
#endif
#endif
    StandardMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
#if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
#else
    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
#endif
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, toneMappingExposure, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_lights(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
    outgoingLight = toneMapping(outgoingLight, 1.0, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
    gl_FragColor = vec4(outgoingLight, 1.0);
}
`;
ShaderChunk['redStandard_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_USE_QTANGENT
#define RED_USE_QTANGENT 1
#endif
attribute vec3 position;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
attribute vec4 qTangent;
#else
attribute vec3 normal;
#endif
attribute vec2 uv;
#if defined(RED_USE_UV2)
    attribute vec2 uv2;
#endif
#if defined(USE_INSTANCING)
    attribute vec4 mcol0;
    attribute vec4 mcol1;
    attribute vec4 mcol2;
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
#if defined(RED_USE_UV2)
varying vec2 vUv2;
#endif
uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform mat4 viewMatrix;
uniform mat3 normalMatrix;
uniform vec3 cameraPosition;
uniform vec4 offsetRepeat;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec3 xAxis(vec4 qQuat)
{
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwy = fTy * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxy = fTy * qQuat.x;
    float fTxz = fTz * qQuat.x;
    float fTyy = fTy * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(1.0-(fTyy+fTzz), fTxy+fTwz, fTxz-fTwy);
}
vec3 yAxis(vec4 qQuat)
{
    float fTx  = 2.0 * qQuat.x;
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwx = fTx * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxx = fTx * qQuat.x;
    float fTxy = fTy * qQuat.x;
    float fTyz = fTz * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(fTxy-fTwz, 1.0-(fTxx+fTzz), fTyz+fTwx);
}

#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
varying vec3 vReflect;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
    uniform mat4 directionalShadowMatrix[NUM_DIR_LIGHTS];
    varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
    uniform mat4 spotShadowMatrix[NUM_SPOT_LIGHTS];
    varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
    uniform mat4 pointShadowMatrix[NUM_POINT_LIGHTS];
    varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
    uniform mat4 directionalRedShadowMatrix[RED_LIGHTS_DIRECTIONAL_COUNT];
    varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void main() {
#if defined(RED_USE_UV2)
    vUv2 = uv2;
#endif
    vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
#ifdef USE_INSTANCING
    mat4 instanceMat = mat4(vec4(mcol0.xyz , 0), vec4(mcol1.xyz , 0), vec4(mcol2.xyz, 0), vec4(mcol0.w, mcol1.w, mcol2.w, 1));
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec4 qTangent_normalized = normalize(qTangent);
    vec3 vertex_normal = xAxis(qTangent_normalized);
    vec3 vertex_tangent = yAxis(qTangent_normalized);
    float biNormalReflection = sign(qTangent.w);
    vec3 vertex_binormal = cross(vertex_normal, vertex_tangent) * biNormalReflection;
    vNormal = normalize(mat3(instanceMat) * normalMatrix * vertex_normal);
    vTangent = normalize(mat3(instanceMat) * normalMatrix * vertex_tangent);
    vBitangent = normalize(mat3(instanceMat) * normalMatrix * vertex_binormal);
#else
    vec3 vertex_normal = vec3(normal);
    vec3 transformedNormal = mat3(instanceMat) * normalMatrix * vertex_normal;
    vNormal = normalize(transformedNormal);
#endif
    vec3 transformed = vec3(position);
    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4(transformed, 1.0);
    gl_Position = projectionMatrix * mvPosition;
    vViewPosition = - mvPosition.xyz;
    vWorldPosition = instanceMat * modelMatrix * vec4(transformed, 1.0);
#else
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec4 qTangent_normalized = normalize(qTangent);
    vec3 vertex_normal = xAxis(qTangent_normalized);
    vec3 vertex_tangent = yAxis(qTangent_normalized);
    float biNormalReflection = sign(qTangent.w);
    vec3 vertex_binormal = cross(vertex_normal, vertex_tangent) * biNormalReflection;
    vNormal = normalize(normalMatrix * vertex_normal);
    vTangent = normalize(normalMatrix * vertex_tangent);
    vBitangent = normalize(normalMatrix * vertex_binormal);
#else
    vec3 vertex_normal = vec3(normal);
    vec3 transformedNormal = normalMatrix * vertex_normal;
    vNormal = normalize(transformedNormal);
#endif
    vec3 transformed = vec3(position);
    vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.0);
    gl_Position = projectionMatrix * mvPosition;
    vViewPosition = - mvPosition.xyz;
    vWorldPosition = modelMatrix * vec4(transformed, 1.0);
#endif
    vertex_worldNormal = normalize(modelMatrix * vec4(vertex_normal, 0.0)).xyz;
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING) && !defined(USE_BUMPMAP) && !defined(USE_NORMALMAP)
    vec3 cameraToVertex = normalize(vWorldPosition.xyz - cameraPosition);
    vec3 worldNormal = inverseTransformDirection(vNormal, viewMatrix);
    #if defined(ENVMAP_MODE_REFRACTION) || defined(RED_TRANSPARENT)
        vReflect = refract(cameraToVertex, worldNormal, refractionRatio);
    #else
        vReflect = reflect(cameraToVertex, worldNormal);
    #endif
#endif
#ifdef RED_USE_SHADOWMAP
    #if NUM_DIR_LIGHTS > 0
        for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
            vDirectionalShadowCoord[i] = directionalShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if NUM_SPOT_LIGHTS > 0
        for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
            vSpotShadowCoord[i] = spotShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if NUM_POINT_LIGHTS > 0
        for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
            vPointShadowCoord[i] = pointShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
        for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i ++) {
            vDirectionalRedShadowCoord[i] = directionalRedShadowMatrix[i] * vWorldPosition;
       }
    #endif
#endif
}
`;
ShaderChunk['redStandardAO_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_STANDARD
#define RED_STANDARD
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
#define saturateMediump(x) min(x, 65504.0)
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
vec3 BRDF_Specular_BlinnPhong(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float shininess) {
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_BlinnPhong_Implicit();
    float D = D_BlinnPhong(shininess, dotNH);
    return F *(G * D);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
#if defined(RED_STANDARD)
struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define RE_Direct	RE_Direct_Standard
#define RE_DirectArea	RE_DirectArea_Standard
#define RE_IndirectDiffuse	RE_IndirectDiffuse_Standard
#define RE_IndirectSpecular	RE_IndirectSpecular_Standard
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
#elif defined(RED_CLEARCOAT)
struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define RE_Direct RE_Direct_ClearCoat
#define RE_DirectArea RE_DirectArea_ClearCoat
#define RE_IndirectDiffuse RE_IndirectDiffuse_ClearCoat
#define RE_IndirectSpecular RE_IndirectSpecular_ClearCoat
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)
#elif defined(RED_ANISOTROPIC)
struct AnisotropicMaterial {
    vec3 diffuseColor;
    float roughness;
    float anisotropy;
    vec3 f0;
    vec3 tangent;
    vec3 bitangent;
};
void RE_Direct_Anisotropic(const in IncidentLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_DirectArea_Anisotropic(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent, lightRadius);
}
void RE_IndirectDiffuse_Anisotropic(const in vec3 irradiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Anisotropic(const in vec3 radiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.f0, material.roughness);
}
#define RE_Direct RE_Direct_Anisotropic
#define RE_IndirectDiffuse RE_IndirectDiffuse_Anisotropic
#define RE_IndirectSpecular RE_IndirectSpecular_Anisotropic
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.roughness)
#elif defined(RED_CLOTH)
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define RE_Direct RE_Direct_Cloth
#define RE_IndirectDiffuse RE_IndirectDiffuse_Cloth
#define RE_IndirectSpecular RE_IndirectSpecular_Cloth
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#endif

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if NUM_DIR_LIGHTS > 0 || 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    return step(compare, decodeRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 0
#endif
#if PPCF_ROTATED == 1
float randNoise(vec2 co){
   return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius, const in vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES]) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randNoise(gl_FragCoord.xy) * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[0] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[0] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[1] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[1] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[2] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[2] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[3] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[3] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[4] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[4] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[5] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[5] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[6] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[6] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[7] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[7] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[8] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[8] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[9] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[9] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[10] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[10] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[11] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[11] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[12] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[12] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[13] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[13] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[14] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[14] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[15] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[15] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[16] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[16] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[17] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[17] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[18] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[18] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[19] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[19] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[20] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[20] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[21] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[21] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[22] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[22] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[23] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[23] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[24] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[24] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[25] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[25] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[26] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[26] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[27] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[27] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[28] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[28] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[29] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[29] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[30] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[30] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[31] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[31] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
    return shadow / 32.0;
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[4] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[5] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[6] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[7] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 9.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform float reflectance;
#if defined(USE_NORMALMAP)
uniform vec2 normalScale;
uniform sampler2D normalMap;
#endif
uniform sampler2D aoMap;
uniform float aoMapIntensity;
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_DIR_LIGHTS > 0
uniform DirectionalLight directionalLights[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
uniform sampler2D directionalShadowMap[NUM_DIR_LIGHTS];
varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_COUNT];
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float cameraExposure, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0) && defined(RE_Direct)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(pointLight.shadow, directLight.visible)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0) && defined(RE_Direct)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(spotLight.shadow, directLight.visible)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if ((NUM_DIR_LIGHTS > 0) || (RED_LIGHTS_DIRECTIONAL_COUNT > 0)) && defined(RE_Direct)
    DirectionalLight directionalLight;
#endif
#if (NUM_DIR_LIGHTS > 0) && defined(RE_Direct)
    for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
        directionalLight = directionalLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? getShadow(directionalShadowMap[i], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0 && defined(RE_Direct)
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        #ifdef RED_USE_SHADOWMAP
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius, poissonSamples);
                #endif
                directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? shadow : 1.0;
           }
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0 && defined(RE_Direct)
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0 && defined(RE_DirectArea)
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0 && defined(RE_DirectArea)
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#endif
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
void evaluate_indirect_diffuse(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
}
void evaluate_indirect_specular(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}

uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec3 diffuseColor = inputToLinear(texture2D(baseColorMap, vUv).rgb);
    diffuseColor.rgb *= baseColor;
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
    vec3 normal = normalize(vNormal);
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#endif
    StandardMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
#if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
#else
    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
#endif
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, toneMappingExposure, reflectedLight);
    float ambientOcclusion =(texture2D(aoMap, vUv2).r - 1.0) * aoMapIntensity + 1.0;
    ambientOcclusion *= texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_lights(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse * ambientOcclusion + reflectedLight.indirectDiffuse + reflectedLight.directSpecular * ambientOcclusion + reflectedLight.indirectSpecular;
    outgoingLight = toneMapping(outgoingLight, 1.0, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
    gl_FragColor = vec4(outgoingLight, 1.0);
}
`;
ShaderChunk['redStandardDisplace_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_USE_QTANGENT
#define RED_USE_QTANGENT 1
#endif
attribute vec3 position;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
attribute vec4 qTangent;
#else
attribute vec3 normal;
#endif
attribute vec2 uv;
#if defined(RED_USE_UV2)
    attribute vec2 uv2;
#endif
#if defined(USE_INSTANCING)
    attribute vec4 mcol0;
    attribute vec4 mcol1;
    attribute vec4 mcol2;
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
#if defined(RED_USE_UV2)
varying vec2 vUv2;
#endif
uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform mat4 viewMatrix;
uniform mat3 normalMatrix;
uniform vec3 cameraPosition;
uniform vec4 offsetRepeat;
uniform sampler2D displacementMap;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
vec3 xAxis(vec4 qQuat)
{
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwy = fTy * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxy = fTy * qQuat.x;
    float fTxz = fTz * qQuat.x;
    float fTyy = fTy * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(1.0-(fTyy+fTzz), fTxy+fTwz, fTxz-fTwy);
}
vec3 yAxis(vec4 qQuat)
{
    float fTx  = 2.0 * qQuat.x;
    float fTy  = 2.0 * qQuat.y;
    float fTz  = 2.0 * qQuat.z;
    float fTwx = fTx * qQuat.w;
    float fTwz = fTz * qQuat.w;
    float fTxx = fTx * qQuat.x;
    float fTxy = fTy * qQuat.x;
    float fTyz = fTz * qQuat.y;
    float fTzz = fTz * qQuat.z;
    return vec3(fTxy-fTwz, 1.0-(fTxx+fTzz), fTyz+fTwx);
}

#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
varying vec3 vReflect;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
    uniform mat4 directionalShadowMatrix[NUM_DIR_LIGHTS];
    varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
    uniform mat4 spotShadowMatrix[NUM_SPOT_LIGHTS];
    varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
    uniform mat4 pointShadowMatrix[NUM_POINT_LIGHTS];
    varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
    uniform mat4 directionalRedShadowMatrix[RED_LIGHTS_DIRECTIONAL_COUNT];
    varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void main() {
#if defined(RED_USE_UV2)
    vUv2 = uv2;
#endif
    vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
#ifdef USE_INSTANCING
    mat4 instanceMat = mat4(vec4(mcol0.xyz , 0), vec4(mcol1.xyz , 0), vec4(mcol2.xyz, 0), vec4(mcol0.w, mcol1.w, mcol2.w, 1));
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec4 qTangent_normalized = normalize(qTangent);
    vec3 vertex_normal = xAxis(qTangent_normalized);
    vec3 vertex_tangent = yAxis(qTangent_normalized);
    float biNormalReflection = sign(qTangent.w);
    vec3 vertex_binormal = cross(vertex_normal, vertex_tangent) * biNormalReflection;
    vNormal = normalize(mat3(instanceMat) * normalMatrix * vertex_normal);
    vTangent = normalize(mat3(instanceMat) * normalMatrix * vertex_tangent);
    vBitangent = normalize(mat3(instanceMat) * normalMatrix * vertex_binormal);
#else
    vec3 vertex_normal = vec3(normal);
    vec3 transformedNormal = mat3(instanceMat) * normalMatrix * vertex_normal;
    vNormal = normalize(transformedNormal);
#endif
    vec3 transformed = vec3(position);
	vec4 displacement = texture2D(displacementMap, vUv);
#if 1
	float scale = 0.30*displacement.x + 0.59*displacement.y + 0.11*displacement.z;
    float factor = 1.0;
	transformed = transformed + vertex_normal * scale * factor;
#else
	transformed = transformed + displacement.xyz * displacement.a;
#endif
    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4(transformed, 1.0);
    gl_Position = projectionMatrix * mvPosition;
    vViewPosition = - mvPosition.xyz;
    vWorldPosition = instanceMat * modelMatrix * vec4(transformed, 1.0);
#else
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec4 qTangent_normalized = normalize(qTangent);
    vec3 vertex_normal = xAxis(qTangent_normalized);
    vec3 vertex_tangent = yAxis(qTangent_normalized);
    float biNormalReflection = sign(qTangent.w);
    vec3 vertex_binormal = cross(vertex_normal, vertex_tangent) * biNormalReflection;
    vNormal = normalize(normalMatrix * vertex_normal);
    vTangent = normalize(normalMatrix * vertex_tangent);
    vBitangent = normalize(normalMatrix * vertex_binormal);
#else
    vec3 vertex_normal = vec3(normal);
    vec3 transformedNormal = normalMatrix * vertex_normal;
    vNormal = normalize(transformedNormal);
#endif
    vec3 transformed = vec3(position);
	vec4 displacement = texture2D(displacementMap, vUv);
#if 1
	float scale = displacement.x;
    float factor = 10.0;
	transformed = transformed + vertex_normal * scale * factor;
#else
	transformed = transformed + displacement.xyz * displacement.a;
#endif
    vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.0);
    gl_Position = projectionMatrix * mvPosition;
    vViewPosition = - mvPosition.xyz;
    vWorldPosition = modelMatrix * vec4(transformed, 1.0);
#endif
    vertex_worldNormal = normalize(modelMatrix * vec4(vertex_normal, 0.0)).xyz;
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING) && !defined(USE_BUMPMAP) && !defined(USE_NORMALMAP)
    vec3 cameraToVertex = normalize(vWorldPosition.xyz - cameraPosition);
    vec3 worldNormal = inverseTransformDirection(vNormal, viewMatrix);
    #if defined(ENVMAP_MODE_REFRACTION) || defined(RED_TRANSPARENT)
        vReflect = refract(cameraToVertex, worldNormal, refractionRatio);
    #else
        vReflect = reflect(cameraToVertex, worldNormal);
    #endif
#endif
#ifdef RED_USE_SHADOWMAP
    #if NUM_DIR_LIGHTS > 0
        for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
            vDirectionalShadowCoord[i] = directionalShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if NUM_SPOT_LIGHTS > 0
        for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
            vSpotShadowCoord[i] = spotShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if NUM_POINT_LIGHTS > 0
        for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
            vPointShadowCoord[i] = pointShadowMatrix[i] * vWorldPosition;
       }
    #endif
    #if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
        for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i ++) {
            vDirectionalRedShadowCoord[i] = directionalRedShadowMatrix[i] * vWorldPosition;
       }
    #endif
#endif
}
`;
ShaderChunk['redStandardMasked_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#ifndef RED_STANDARD
#define RED_STANDARD
#endif
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
#define saturateMediump(x) min(x, 65504.0)
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
vec3 BRDF_Specular_BlinnPhong(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float shininess) {
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_BlinnPhong_Implicit();
    float D = D_BlinnPhong(shininess, dotNH);
    return F *(G * D);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
#if defined(RED_STANDARD)
struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define RE_Direct	RE_Direct_Standard
#define RE_DirectArea	RE_DirectArea_Standard
#define RE_IndirectDiffuse	RE_IndirectDiffuse_Standard
#define RE_IndirectSpecular	RE_IndirectSpecular_Standard
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
#elif defined(RED_CLEARCOAT)
struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define RE_Direct RE_Direct_ClearCoat
#define RE_DirectArea RE_DirectArea_ClearCoat
#define RE_IndirectDiffuse RE_IndirectDiffuse_ClearCoat
#define RE_IndirectSpecular RE_IndirectSpecular_ClearCoat
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)
#elif defined(RED_ANISOTROPIC)
struct AnisotropicMaterial {
    vec3 diffuseColor;
    float roughness;
    float anisotropy;
    vec3 f0;
    vec3 tangent;
    vec3 bitangent;
};
void RE_Direct_Anisotropic(const in IncidentLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_DirectArea_Anisotropic(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent, lightRadius);
}
void RE_IndirectDiffuse_Anisotropic(const in vec3 irradiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Anisotropic(const in vec3 radiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.f0, material.roughness);
}
#define RE_Direct RE_Direct_Anisotropic
#define RE_IndirectDiffuse RE_IndirectDiffuse_Anisotropic
#define RE_IndirectSpecular RE_IndirectSpecular_Anisotropic
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.roughness)
#elif defined(RED_CLOTH)
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define RE_Direct RE_Direct_Cloth
#define RE_IndirectDiffuse RE_IndirectDiffuse_Cloth
#define RE_IndirectSpecular RE_IndirectSpecular_Cloth
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#endif

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if NUM_DIR_LIGHTS > 0 || 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    return step(compare, decodeRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 0
#endif
#if PPCF_ROTATED == 1
float randNoise(vec2 co){
   return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius, const in vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES]) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randNoise(gl_FragCoord.xy) * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[0] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[0] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[1] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[1] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[2] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[2] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[3] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[3] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[4] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[4] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[5] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[5] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[6] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[6] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[7] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[7] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[8] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[8] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[9] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[9] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[10] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[10] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[11] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[11] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[12] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[12] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[13] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[13] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[14] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[14] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[15] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[15] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[16] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[16] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[17] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[17] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[18] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[18] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[19] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[19] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[20] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[20] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[21] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[21] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[22] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[22] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[23] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[23] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[24] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[24] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[25] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[25] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[26] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[26] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[27] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[27] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[28] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[28] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[29] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[29] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[30] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[30] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[31] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[31] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
    return shadow / 32.0;
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[4] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[5] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[6] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[7] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 9.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform float reflectance;
#if defined(USE_NORMALMAP)
uniform vec2 normalScale;
uniform sampler2D normalMap;
#endif
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_DIR_LIGHTS > 0
uniform DirectionalLight directionalLights[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
uniform sampler2D directionalShadowMap[NUM_DIR_LIGHTS];
varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_COUNT];
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float cameraExposure, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0) && defined(RE_Direct)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(pointLight.shadow, directLight.visible)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0) && defined(RE_Direct)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(spotLight.shadow, directLight.visible)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if ((NUM_DIR_LIGHTS > 0) || (RED_LIGHTS_DIRECTIONAL_COUNT > 0)) && defined(RE_Direct)
    DirectionalLight directionalLight;
#endif
#if (NUM_DIR_LIGHTS > 0) && defined(RE_Direct)
    for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
        directionalLight = directionalLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? getShadow(directionalShadowMap[i], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0 && defined(RE_Direct)
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        #ifdef RED_USE_SHADOWMAP
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius, poissonSamples);
                #endif
                directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? shadow : 1.0;
           }
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0 && defined(RE_Direct)
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0 && defined(RE_DirectArea)
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0 && defined(RE_DirectArea)
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#endif
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
void evaluate_indirect_diffuse(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
}
void evaluate_indirect_specular(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}

uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec4 diffuseColor = texture2D(baseColorMap, vUv);
    if(diffuseColor.a < 0.98) {
        discard;
   }
    diffuseColor.rgb = inputToLinear(diffuseColor.rgb * baseColor);
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
    vec3 normal = normalize(vNormal);
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * vec2(1.0, -1.0) * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#endif
    StandardMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
#if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
#else
    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
#endif
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, toneMappingExposure, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_lights(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
    outgoingLight = toneMapping(outgoingLight, 1.0, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
    gl_FragColor = vec4(outgoingLight, 1.0);
}
`;
ShaderChunk['redTransparent_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define RED_STANDARD
varying vec3 vViewPosition;
varying vec4 vWorldPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec3 vertex_worldNormal;
uniform mat4 viewMatrix;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
#ifndef NORMALMAP_UV
#define NORMALMAP_UV vUv
#endif
#ifndef BUMPMAP_UV
#define BUMPMAP_UV vUv
#endif
#ifdef USE_NORMALMAP
vec3 perturbNormal2Arb(const in vec2 uv, sampler2D map, const in vec3 eye_pos, const in vec2 scale, const in vec3 surf_norm) {
    vec3 q0 = dFdx(eye_pos.xyz);
    vec3 q1 = dFdy(eye_pos.xyz);
    vec2 st0 = dFdx(uv.st);
    vec2 st1 = dFdy(uv.st);
    vec3 S = normalize(q0 * st1.t - q1 * st0.t);
    vec3 T = normalize(-q0 * st1.s + q1 * st0.s);
    vec3 N = normalize(surf_norm);
    vec3 mapN = texture2D(map, uv).xyz * 2.0 - 1.0;
    mapN.xy = vec2(1.0, -1.0) * scale * mapN.xy;
    mat3 tsn = mat3(S, T, N);
    return normalize(tsn * mapN);
}
#endif
#ifdef USE_BUMPMAP
vec2 dHdxy_fwd(const in vec2 uv, sampler2D map, const in float scale) {
    vec2 dSTdx = dFdx(uv.st);
    vec2 dSTdy = dFdy(uv.st);
    float Hll = scale * texture2D(map, uv).x;
    float dBx = scale * texture2D(map, uv + dSTdx).x - Hll;
    float dBy = scale * texture2D(map, uv + dSTdy).x - Hll;
    return vec2(dBx, dBy);
}
vec3 perturbNormalArb(vec3 surf_pos, vec3 surf_norm, vec2 dHdxy) {
    vec3 vSigmaX = dFdx(surf_pos);
    vec3 vSigmaY = dFdy(surf_pos);
    vec3 vN = surf_norm;
    vec3 R1 = cross(vSigmaY, vN);
    vec3 R2 = cross(vN, vSigmaX);
    float fDet = dot(vSigmaX, R1);
    vec3 vGrad = sign(fDet) *(dHdxy.x * R1 + dHdxy.y * R2);
    return normalize(abs(fDet) * surf_norm - vGrad);
}
#endif
#ifdef HAS_DERIVATES
float mip_map_level(const in vec2 texture_coordinate) {
    vec2  dx_vtc        = dFdx(texture_coordinate);
    vec2  dy_vtc        = dFdy(texture_coordinate);
    float delta_max_sqr = max(dot(dx_vtc, dx_vtc), dot(dy_vtc, dy_vtc));
    return 0.5 * log2(delta_max_sqr);
}
#endif
bool testLightInRange(const in float lightDistance, const in float cutoffDistance) {
    return any(bvec2(cutoffDistance == 0.0, lightDistance < cutoffDistance));
}
float calcLightAttenuation(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
      return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
   }
    return 1.0;
}
float punctualLightIntensityToIrradianceFactor(const in float lightDistance, const in float cutoffDistance, const in float decayExponent) {
    if(decayExponent > 0.0) {
#if defined(PHYSICALLY_CORRECT_LIGHTS)
        float distanceFalloff = 1.0 / max(pow(lightDistance, decayExponent), 0.01);
        float maxDistanceCutoffFactor = pow2(saturate(1.0 - pow4(lightDistance / cutoffDistance)));
        return distanceFalloff * maxDistanceCutoffFactor;
#else
        return pow(saturate(-lightDistance / cutoffDistance + 1.0), decayExponent);
#endif
   }
    return 1.0;
}
vec3 BRDF_Diffuse_Lambert(const in vec3 diffuseColor) {
    return RECIPROCAL_PI * diffuseColor;
}
vec3 F_Schlick(const in vec3 specularColor, const in float dotLH) {
    float fresnel = exp2((-5.55473 * dotLH - 6.98316) * dotLH);
    return(1.0 - specularColor) * fresnel + specularColor;
}
vec3 F_Schlick(const vec3 f0, float f90, float VoH) {
    float f = pow5(1.0 - VoH);
    return f + f0 * (f90 - f);
}
float F_Schlick(float f0, float f90, float VoH) {
    return f0 + (f90 - f0) * pow5(1.0 - VoH);
}
vec3 fresnel(const vec3 f0, float LoH) {
    float f90 = saturate(dot(f0, vec3(50.0 * 0.33)));
    return F_Schlick(f0, f90, LoH);
}
float G_GGX_Smith(const in float alpha, const in float dotNL, const in float dotNV) {
    float a2 = alpha * alpha;
    float gl = dotNL + pow(a2 +(1.0 - a2) * dotNL * dotNL, 0.5);
    float gv = dotNV + pow(a2 +(1.0 - a2) * dotNV * dotNV, 0.5);
    return 1.0 /(gl * gv);
}
#define saturateMediump(x) min(x, 65504.0)
float G_SmithGGXCorrelated_Anisotropic(float at, float ab, float ToV, float BoV, float ToL, float BoL, float NoV, float NoL) {
    float lambdaV = NoL * length(vec3(at * ToV, ab * BoV, NoV));
    float lambdaL = NoV * length(vec3(at * ToL, ab * BoL, NoL));
    float v = 0.5 / (lambdaV + lambdaL);
    return saturateMediump(v);
}
float D_GGX(const in float alpha, const in float dotNH) {
    float a2 = alpha * alpha;
    float denom = dotNH * dotNH *(a2 - 1.0) + 1.0;
    return RECIPROCAL_PI * a2 /(denom * denom);
}
float D_GGX_Anisotropic(float NoH, float ToH, float BoH, float at, float ab) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / PI);
}
float D_GGX_Area(const in float alpha, const in float dotNH, const in float lightDist, const in float lightRadius) {
    float a2 = alpha * alpha;
    float d = dotNH * dotNH * (a2 - 1.0) + 1.0;
    d = max(d, 0.000001);
    float aP = saturate(lightRadius / (lightDist*2.0) + alpha);
    float aP2 = aP * aP;
    return a2 * a2 / (PI * d * d * aP2);
}
float D_GGX_Anisotropic_Area(float NoH, float ToH, float BoH, float at, float ab, const in float lightDist, const in float lightRadius) {
    float a2 = at * ab;
    vec3 v = vec3(ab * ToH, at * BoH, a2 * NoH);
    float aP = saturate(lightRadius / (lightDist*2.0) + a2);
    float aP2 = aP * aP;
    return a2 * pow2(a2 / dot(v, v)) * (1.0 / (PI * aP2));
}
void RE_Direct_Scattering_Colin(const in IncidentLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Colin_Area(const in IncidentAreaLight directLight, const in float thicknessScalar, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    vec3 thickness = RECIPROCAL_PI * subsurfaceColor * thicknessScalar;
    float thicknessDistortion = 0.4;
    float thicknessPower = 8.0;
    float thicknessScale = subsurfacePower;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
    reflectedLight.directDiffuse += scatteringDot * thickness * directLight.color;
}
void RE_Direct_Scattering_Filament(const in IncidentLight directLight, const in float thickness, const in float subsurfacePower, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float scatterVoH = saturate(dot(geometry.viewDir, -directLight.direction));
    float forwardScatter = exp2(scatterVoH * subsurfacePower - subsurfacePower);
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5;
    float subsurface = mix(backScatter, 1.0, forwardScatter) * (1.0 - thickness);
    reflectedLight.directDiffuse += subsurfaceColor * (subsurface * 1.0/PI) * directLight.color;
}
void RE_Direct_Scattering_Custom_Area(const in IncidentAreaLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 1.0 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Direct_Scattering_Custom(const in IncidentLight directLight, const in float thickness, const in float roughness, const in vec3 subsurfaceColor, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    float rough = max(0.04, min(0.96, roughness * roughness));
    float power = 1.0 + (1.0 - rough) * 256.0;
    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * 0.1));
    float backScatter = saturate(dotNL * thickness + (1.0 - thickness)) * 0.5 * rough;
    float forwardScatter = exp2(saturate(dot(geometry.viewDir, -scatteringHalf)) * power - power) * (1.0 - rough);
    reflectedLight.directDiffuse += (1.0 - thickness) * subsurfaceColor * (forwardScatter + backScatter) * directLight.color;
}
void RE_Indirect_Subsurface(float thickness, const in vec3 subsurfaceColor, const in float subsurfacePower, const in vec3 diffuseIrradiance, const in vec3 specularRadiance, inout ReflectedLight reflectedLight) {
    float attenuation = (1.0 - thickness) / (2.0 * PI) * subsurfacePower;
    reflectedLight.indirectDiffuse += subsurfaceColor * (diffuseIrradiance + specularRadiance) * attenuation;
}
float D_Ashikhmin(float linearRoughness, float NoH) {
    float a2 = linearRoughness * linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    float sin4h = sin2h * sin2h;
    float cot2 = -cos2h / (a2 * sin2h);
    return 1.0 / (PI * (4.0 * a2 + 1.0) * sin4h) * (4.0 * exp(cot2) + sin4h);
}
float D_Charlie(float linearRoughness, float NoH) {
    float invAlpha  = 1.0 / linearRoughness;
    float cos2h = NoH * NoH;
    float sin2h = max(1.0 - cos2h, 0.0078125);
    return (2.0 + invAlpha) * pow(sin2h, invAlpha * 0.5) / (2.0 * PI);
}
float V_Neubelt(float NoV, float NoL) {
    return saturateMediump(1.0 / (4.0 * (NoL + NoV - NoL * NoV)));
}
float distributionCloth(float linearRoughness, float NoH) {
    return D_Ashikhmin(linearRoughness, NoH);
}
float visibilityCloth(float NoV, float NoL) {
    return V_Neubelt(NoV, NoL);
}
vec3 BRDF_Specular_GGX(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX(alpha, dotNH);
    return F *(G * D);
}
float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
vec3 rand3(vec2 co){
    return vec3(fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453),
                fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453));
}
vec3 BRDF_Specular_GGX_Anisotropic(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic(dotNH, ToH, BoH, at, ab);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area_Anisotropic(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float anisotropy, const in vec3 t, const in vec3 b, const in float lightRadius) {
    float alpha = roughness * roughness;
    float at = max(alpha * (1.0 + anisotropy), 0.001);
    float ab = max(alpha * (1.0 - anisotropy), 0.001);
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    float dotTV = dot(t, geometry.viewDir);
    float dotBV = dot(b, geometry.viewDir);
    float dotTL = dot(t, incidentLight.direction);
    float dotBL = dot(b, incidentLight.direction);
    float ToH = dot(t, halfDir);
    float BoH = dot(b, halfDir);
    vec3 F = fresnel(f0, dotLH);
    float G = G_SmithGGXCorrelated_Anisotropic(at, ab, dotTV, dotBV, dotTL, dotBL, dotNV, dotNL);
    float D = D_GGX_Anisotropic_Area(dotNH, ToH, BoH, at, ab, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Area(const in IncidentAreaLight incidentLight, const in GeometricContext geometry, const in vec3 f0, const in float roughness, const in float lightRadius) {
    float alpha = roughness * roughness;
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNL = saturate(incidentLight.ndotl);
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(f0, dotLH);
    float G = G_GGX_Smith(alpha, dotNL, dotNV);
    float D = D_GGX_Area(alpha, dotNH, incidentLight.distance, lightRadius);
    return F *(G * D);
}
vec3 BRDF_Specular_GGX_Environment(const in GeometricContext geometry, const in vec3 specularColor, const in float roughness) {
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    const vec4 c0 = vec4(- 1, - 0.0275, - 0.572, 0.022);
    const vec4 c1 = vec4(1, 0.0425, 1.04, - 0.04);
    vec4 r = roughness * c0 + c1;
    float a004 = min(r.x * r.x, exp2(- 9.28 * dotNV)) * r.x + r.y;
    vec2 AB = vec2(-1.04, 1.04) * a004 + r.zw;
    return specularColor * AB.x + AB.y;
}
float Fd_Wrap(float NoL, float w) {
    return saturate((NoL + w) / pow2(1.0 + w));
}
float Fd_Burley(float linearRoughness, float NoV, float NoL, float LoH) {
    float f90 = 0.5 + 2.0 * linearRoughness * LoH * LoH;
    float lightScatter = F_Schlick(1.0, f90, NoL);
    float viewScatter  = F_Schlick(1.0, f90, NoV);
    return lightScatter * viewScatter * (1.0 / PI);
}
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, const in vec3 subsurfaceColor, out vec3 directSpecular, out vec3 directDiffuse) {
#else
void Cloth_Surface_Shading(const in GeometricContext geometry, const in IncidentLight incidentLight, const in vec3 diffuseColor, const in float linearRoughness, const in vec3 sheenColor, out vec3 directSpecular, out vec3 directDiffuse) {
#endif
    float dotNL = saturate(dot(geometry.normal, incidentLight.direction));
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3  F = fresnel(sheenColor, dotLH);
    float G = visibilityCloth(dotNV, dotNL);
    float D = distributionCloth(linearRoughness, dotNH);
    vec3 Fr = F * (G * D);
    float diffuse = Fd_Burley(linearRoughness, dotNV, dotNL, dotLH);
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    diffuse *= Fd_Wrap(dot(geometry.normal, incidentLight.direction), 0.5);
#endif
    vec3 Fd = diffuse * diffuseColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Fd *= saturate(subsurfaceColor + dotNL);
    vec3 irradiance = incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr * dotNL;
#else
    vec3 irradiance = dotNL * incidentLight.color;
    directDiffuse = irradiance * Fd;
    directSpecular = irradiance * Fr;
#endif
}
float G_BlinnPhong_Implicit() {
    return 0.25;
}
float D_BlinnPhong(const in float shininess, const in float dotNH) {
    return RECIPROCAL_PI *(shininess * 0.5 + 1.0) * pow(dotNH, shininess);
}
vec3 BRDF_Specular_BlinnPhong(const in IncidentLight incidentLight, const in GeometricContext geometry, const in vec3 specularColor, const in float shininess) {
    vec3 halfDir = normalize(incidentLight.direction + geometry.viewDir);
    float dotNH = saturate(dot(geometry.normal, halfDir));
    float dotLH = saturate(dot(incidentLight.direction, halfDir));
    vec3 F = F_Schlick(specularColor, dotLH);
    float G = G_BlinnPhong_Implicit();
    float D = D_BlinnPhong(shininess, dotNH);
    return F *(G * D);
}
float GGXRoughnessToBlinnExponent(const in float ggxRoughness) {
    return(2.0 / square(ggxRoughness + 0.0001) - 2.0);
}
#if defined(RED_STANDARD)
struct StandardMaterial {
    vec3 diffuseColor;
    float specularRoughness;
    vec3 specularColor;
};
void RE_Direct_Standard(const in IncidentLight directLight, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
}
void RE_DirectArea_Standard(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in StandardMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
}
void RE_IndirectDiffuse_Standard(const in vec3 irradiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Standard(const in vec3 radiance, const in GeometricContext geometry, const in StandardMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
}
#define RE_Direct	RE_Direct_Standard
#define RE_DirectArea	RE_DirectArea_Standard
#define RE_IndirectDiffuse	RE_IndirectDiffuse_Standard
#define RE_IndirectSpecular	RE_IndirectSpecular_Standard
#define Material_BlinnShininessExponent(material) GGXRoughnessToBlinnExponent(material.specularRoughness)
#elif defined(RED_CLEARCOAT)
struct ClearCoatMaterial {
    vec3 diffuseColor;
    vec3 specularColor;
    float specularRoughness;
    float clearCoat;
    float clearCoatRoughness;
};
#define DEFAULT_SPECULAR_COEFFICIENT 0.04
#define MAXIMUM_SPECULAR_COEFFICIENT 0.16
float clearCoatDHRApprox(const in float roughness, const in float dotNL){
    return DEFAULT_SPECULAR_COEFFICIENT + (1.0 - DEFAULT_SPECULAR_COEFFICIENT) * (pow(1.0 - dotNL, 5.0) * pow(1.0 - roughness, 2.0));
}
void RE_Direct_ClearCoat(const in IncidentLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.directSpecular += (1.0 - clearCoatDHR) * irradiance * BRDF_Specular_GGX(directLight, geometry, material.specularColor, material.specularRoughness);
    reflectedLight.directDiffuse += (1.0 - clearCoatDHR) * irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
void RE_DirectArea_ClearCoat(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in ClearCoatMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, directLight.ndotl);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area(directLight, geometry, material.specularColor, material.specularRoughness, lightRadius);
    reflectedLight.directSpecular += irradiance * material.clearCoat * BRDF_Specular_GGX_Area(directLight, geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness, lightRadius);
}
void RE_IndirectDiffuse_ClearCoat(const in vec3 irradiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_ClearCoat(const in vec3 radiance, const in vec3 clearCoatRadiance, const in GeometricContext geometry, const in ClearCoatMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    float dotNL = dotNV;
    float clearCoatDHR = material.clearCoat * clearCoatDHRApprox(material.clearCoatRoughness, dotNL);
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.specularColor, material.specularRoughness);
    reflectedLight.indirectSpecular += clearCoatRadiance * material.clearCoat * BRDF_Specular_GGX_Environment(geometry, vec3(DEFAULT_SPECULAR_COEFFICIENT), material.clearCoatRoughness);
}
#define RE_Direct RE_Direct_ClearCoat
#define RE_DirectArea RE_DirectArea_ClearCoat
#define RE_IndirectDiffuse RE_IndirectDiffuse_ClearCoat
#define RE_IndirectSpecular RE_IndirectSpecular_ClearCoat
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#define Material_ClearCoat_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.clearCoatRoughness)
#elif defined(RED_ANISOTROPIC)
struct AnisotropicMaterial {
    vec3 diffuseColor;
    float roughness;
    float anisotropy;
    vec3 f0;
    vec3 tangent;
    vec3 bitangent;
};
void RE_Direct_Anisotropic(const in IncidentLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight){
    float dotNL = saturate(dot(geometry.normal, directLight.direction));
    vec3 irradiance = dotNL * directLight.color;
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent);
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_DirectArea_Anisotropic(const in IncidentAreaLight directLight, const in GeometricContext geometry, const in AnisotropicMaterial material, const in float lightRadius, inout ReflectedLight reflectedLight) {
    vec3 irradiance = directLight.ndotl * PI * directLight.color;
    reflectedLight.directDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
    reflectedLight.directSpecular += irradiance * BRDF_Specular_GGX_Area_Anisotropic(directLight, geometry, material.f0, material.anisotropy, material.roughness, material.tangent, material.bitangent, lightRadius);
}
void RE_IndirectDiffuse_Anisotropic(const in vec3 irradiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor);
}
void RE_IndirectSpecular_Anisotropic(const in vec3 radiance, const in GeometricContext geometry, const in AnisotropicMaterial material, inout ReflectedLight reflectedLight) {
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.f0, material.roughness);
}
#define RE_Direct RE_Direct_Anisotropic
#define RE_IndirectDiffuse RE_IndirectDiffuse_Anisotropic
#define RE_IndirectSpecular RE_IndirectSpecular_Anisotropic
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.roughness)
#elif defined(RED_CLOTH)
struct ClothMaterial {
    vec3 diffuseColor;
    vec3 sheenColor;
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    vec3 subsurfaceColor;
#endif
    float specularRoughness;
    float linearRoughness;
};
void RE_Direct_Cloth(const in IncidentLight directLight, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, material.subsurfaceColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#else
    Cloth_Surface_Shading(geometry, directLight, material.diffuseColor, material.linearRoughness, material.sheenColor, reflectedLight.directSpecular, reflectedLight.directDiffuse);
#endif
}
void RE_IndirectDiffuse_Cloth(const in vec3 irradiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    float dotNV = abs(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectDiffuse += irradiance * BRDF_Diffuse_Lambert(material.diffuseColor * saturate((dotNV + 0.5) / 2.25));
#if defined(RED_MATERIAL_HAS_SUBSURFACE)
    reflectedLight.indirectDiffuse *= Fd_Wrap(dotNV, 0.5);
    reflectedLight.indirectDiffuse *= saturate(material.subsurfaceColor + dotNV);
#endif
}
void RE_IndirectSpecular_Cloth(const in vec3 radiance, const in GeometricContext geometry, const in ClothMaterial material, inout ReflectedLight reflectedLight){
    reflectedLight.indirectSpecular += radiance * BRDF_Specular_GGX_Environment(geometry, material.sheenColor, material.specularRoughness);
}
#define RE_Direct RE_Direct_Cloth
#define RE_IndirectDiffuse RE_IndirectDiffuse_Cloth
#define RE_IndirectSpecular RE_IndirectSpecular_Cloth
#define Material_BlinnShininessExponent(material)   GGXRoughnessToBlinnExponent(material.specularRoughness)
#endif

vec3 getAmbientLightIrradiance(const in vec3 ambientLightColor) {
    return PI * ambientLightColor;
}
#ifndef RED_LIGHTS_DIRECTIONAL_COUNT
#define RED_LIGHTS_DIRECTIONAL_COUNT 0
#endif
#if NUM_DIR_LIGHTS > 0 || 0 < RED_LIGHTS_DIRECTIONAL_COUNT
struct DirectionalLight {
    vec3 direction;
    vec3 color;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getDirectionalDirectLight(const in DirectionalLight directionalLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    directLight.color = directionalLight.color;
    directLight.direction = directionalLight.direction;
    directLight.visible = true;
    return directLight;
}
#endif
#if NUM_POINT_LIGHTS > 0
struct PointLight {
    vec3 position;
    vec3 color;
    float distance;
    float decay;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
    float shadowCameraNear;
    float shadowCameraFar;
};
IncidentLight getPointDirectLight(const in PointLight pointLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = pointLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
    if(testLightInRange(lightDistance, pointLight.distance)) {
        directLight.color = pointLight.color;
        directLight.color *= calcLightAttenuation(lightDistance, pointLight.distance, pointLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_SPOT_LIGHTS > 0
struct SpotLight {
    vec3 position;
    vec3 direction;
    vec3 color;
    float distance;
    float decay;
    float coneCos;
    float penumbraCos;
    int shadow;
    float shadowBias;
    float shadowRadius;
    vec2 shadowMapSize;
};
IncidentLight getSpotDirectLight(const in SpotLight spotLight, const in GeometricContext geometry) {
    IncidentLight directLight;
    vec3 lVector = spotLight.position - geometry.position;
    directLight.direction = normalize(lVector);
    float lightDistance = length(lVector);
	float angleCos = dot(directLight.direction, spotLight.direction);
    if(angleCos > spotLight.coneCos) {
        float spotEffect = smoothstep(spotLight.coneCos, spotLight.penumbraCos, angleCos);
        directLight.color = spotLight.color;
        directLight.color *= spotEffect * punctualLightIntensityToIrradianceFactor(lightDistance, spotLight.distance, spotLight.decay);
        directLight.visible = true;
   } else {
        directLight.color = vec3(0.0);
        directLight.visible = false;
   }
    return directLight;
}
#endif
#if NUM_HEMI_LIGHTS > 0
struct HemisphereLight {
    vec3 direction;
    vec3 skyColor;
    vec3 groundColor;
};
vec3 getHemisphereLightIrradiance(const in HemisphereLight hemiLight, const in GeometricContext geometry) {
    float dotNL = dot(geometry.normal, hemiLight.direction);
    float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
    return PI * mix(hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight);
}
#endif
#if defined(RED_PROBE_LIGHTING)
#ifndef ENVMAP_TYPE_EQUIREC
#define ENVMAP_TYPE_EQUIREC 0
#endif
#ifndef ENVMAP_TYPE_CUBE
#define ENVMAP_TYPE_CUBE 1
#endif
#if ENVMAP_TYPE_EQUIREC == 1
#define EnvMapSampler sampler2D
#else
#define EnvMapSampler samplerCube
#endif
struct SpecularLightProbe {
    float envMapIntensity;
    float maxMipLevel;
};
float getSpecularMIPLevel(const in float blinnShininessExponent, const in float maxMIPLevelScalar) {
    float desiredMIPLevel = maxMIPLevelScalar - 0.79248 - 0.5 * log2(square(blinnShininessExponent) + 1.0);
    return clamp(desiredMIPLevel, 0.0, maxMIPLevelScalar);
}
float mix(float x, float y, bool a) {
    return a ? y : x;
}
vec3 getLightProbeIndirectIrradiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, const in highp vec3 worldNormal) {
    #ifdef ENVMAP_TYPE_CUBE
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = textureCube(envMap, worldNormal, specularLightProbe.maxMipLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(-worldNormal.y) * RECIPROCAL_PI;
        sampleUV.x = atan(worldNormal.z, worldNormal.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularLightProbe.maxMipLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularLightProbe.maxMipLevel + 2.0);
        #endif
    #else
        vec4 envMapColor = vec4(0.0);
    #endif
    return PI * envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
vec3 getLightProbeIndirectRadiance_Offset(EnvMapSampler envMap, const in SpecularLightProbe specularLightProbe, vec3 reflectVec, const in float roughness, const in float offset) {
    #ifdef DOUBLE_SIDED
        float flipNormal =(float(gl_FrontFacing) * 2.0 - 1.0);
    #else
        float flipNormal = 1.0;
    #endif
    float specularMIPLevel = roughness * (specularLightProbe.maxMipLevel - 1.0);
    #ifdef ENVMAP_TYPE_CUBE
        vec3 queryReflectVec = flipNormal * reflectVec;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = textureCubeLodEXT(envMap, queryReflectVec, specularMIPLevel + offset);
        #else
            vec4 envMapColor = textureCube(envMap, queryReflectVec, specularMIPLevel + 2.0);
        #endif
    #elif defined(ENVMAP_TYPE_EQUIREC)
        vec2 sampleUV;
        sampleUV.y = acos(flipNormal * -reflectVec.y) * RECIPROCAL_PI;
        sampleUV.x = atan(flipNormal * reflectVec.z, flipNormal * reflectVec.x) * RECIPROCAL_PI2 + 0.5;
        #ifdef TEXTURE_LOD_EXT
            vec4 envMapColor = texture2DLodEXT(envMap, sampleUV, specularMIPLevel + offset);
        #else
            vec4 envMapColor = texture2D(envMap, sampleUV, specularMIPLevel + 2.0);
        #endif
    #endif
    return envMapColor.rgb * specularLightProbe.envMapIntensity;
}
#endif
float computeSpecularOcclusion(const in float dotNV, const in float ambientOcclusion, const in float roughness) {
    return saturate(pow(dotNV + ambientOcclusion, exp2(- 16.0 * roughness - 1.0)) - 1.0 + ambientOcclusion);
}
vec3 irradcoeffs(vec3 L00, vec3 L1_1, vec3 L10, vec3 L11,
                    vec3 L2_2, vec3 L2_1, vec3 L20, vec3 L21, vec3 L22,
                    vec3 n) {
    float x2;
    float y2;
    float z2;
    float xy;
    float yz;
    float xz;
    float x;
    float y;
    float z;
    vec3 col;
    const float c1 = 0.429043;
    const float c2 = 0.511664;
    const float c3 = 0.743125;
    const float c4 = 0.886227;
    const float c5 = 0.247708;
    x = n[0]; y = n[1]; z = n[2];
    x2 = x*x; y2 = y*y; z2 = z*z;
    xy = x*y; yz = y*z; xz = x*z;
    col = c1*L22*(x2-y2) + c3*L20*z2 + c4*L00 - c5*L20
        + 2.0*c1*(L2_2*xy + L21*xz + L2_1*yz)
        + 2.0*c2*(L11*x+L1_1*y+L10*z);
    return col;
}
#if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
    uniform vec4 cAr;
    uniform vec4 cAg;
    uniform vec4 cAb;
    uniform vec4 cBr;
    uniform vec4 cBg;
    uniform vec4 cBb;
    uniform vec4 cC;
    vec3 ShadeIrradiance(const vec3 normal) {
        vec3 x1, x2, x3;
        x1.r = dot(cAr,vec4(normal, 1.0));
        x1.g = dot(cAg,vec4(normal, 1.0));
        x1.b = dot(cAb,vec4(normal, 1.0));
        vec4 vB = normal.xyzz * normal.yzzx;
        x2.r = dot(cBr,vB);
        x2.g = dot(cBg,vB);
        x2.b = dot(cBb,vB);
        float vC = normal.x*normal.x - normal.y*normal.y;
        x3 = cC.rgb * vC;
        return x1+x2+x3;
   }
#endif
    float AttenuationToZero(float distSqr)
    {
        float d = sqrt(distSqr);
        float kDefaultPointLightRadius = 0.25;
        float atten = 1.0 / pow(1.0 +   d/kDefaultPointLightRadius, 2.0);
        float kCutoff = 1.0 / pow(1.0 + 1.0/kDefaultPointLightRadius, 2.0);
        atten = (atten - kCutoff) / (1.0 - kCutoff);
        if (d >= 1.0) {
            atten = 0.0;
       }
        return atten;
   }
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
    struct redSphereLight {
        vec3 color;
        vec3 position;
        float decay;
        float distance;
        float radius;
   };
    IncidentAreaLight getSphereDirectLight(const in redSphereLight sphereLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        vec3 Lunormalized = sphereLight.position - geometry.position;
        vec3 centerToRay = dot(Lunormalized, reflectVec) * reflectVec - Lunormalized;
        vec3 closestPoint = Lunormalized + centerToRay * clamp(sphereLight.radius / length(centerToRay), 0.0, 1.0);
        float lightDistance = length(closestPoint);
        directLight.direction = closestPoint / lightDistance;
        directLight.distance = lightDistance;
        float distLSq = dot(Lunormalized, Lunormalized);
        directLight.ndotl = saturate(dot(geometry.normal, Lunormalized / sqrt(distLSq)));
        if(testLightInRange(lightDistance, sphereLight.distance)) {
            directLight.color = sphereLight.color;
            float rangeSqInv = 1.0 / (sphereLight.distance * sphereLight.distance);
            float distNorm = distLSq * rangeSqInv;
            directLight.color *= AttenuationToZero(distNorm);
            directLight.visible = true;
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
    struct redTubeLight {
        vec3 color;
        vec3 position;
        vec3 lightAxis;
        float decay;
        float distance;
        float radius;
        float size;
   };
    IncidentAreaLight getTubeDirectLight(const in redTubeLight tubeLight, const in GeometricContext geometry) {
        IncidentAreaLight directLight;
        float halfWidth = tubeLight.size * 0.5;
        vec3 R = reflect(-geometry.viewDir, geometry.normal);
        vec3 L0 = (tubeLight.position + tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 L1 = (tubeLight.position - tubeLight.lightAxis * halfWidth) - geometry.position;
        vec3 Ld = L1 - L0;
        float RoL0 = dot(R, L0);
        float RoLd = dot(R, Ld);
        float L0oLd = dot(L0, Ld);
        float sqrDistLd = dot(Ld, Ld);
        float t =(RoL0 * RoLd - L0oLd) /(sqrDistLd - RoLd * RoLd);
        vec3 Lunormalized = L0 + saturate(t) * Ld;
        float tubeRad = tubeLight.radius * (1.0 / PI);
        vec3 centerToRay = dot(Lunormalized, R) * R - Lunormalized;
        Lunormalized = Lunormalized + centerToRay * clamp(tubeRad / length(centerToRay), 0.0, 1.0);
        float distLight = length(Lunormalized);
        directLight.direction = Lunormalized / distLight;
        float rangeSqInv = 1.0 / (tubeLight.distance * tubeLight.distance);
        float distL0 = sqrt(dot(L0,L0));
        float distL1 = length(L1);
        float distNorm = 0.5 * (distL0 * distL1 + dot(L0, L1)) * rangeSqInv;
        float NdotL0 = dot(L0, geometry.normal) / (2.0 * distL0);
        float NdotL1 = dot(L1, geometry.normal) / (2.0 * distL1);
        directLight.ndotl = saturate(NdotL0 + NdotL1);
        directLight.distance = distLight;
        directLight.color = tubeLight.color;
        directLight.color *= AttenuationToZero(distNorm);
        directLight.visible = true;
        return directLight;
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
    struct redIESLight {
        vec3 color;
        vec3 position;
        float distance;
        float decay;
   };
    #define REDLIGHT_RAD2DEG 57.29578
    IncidentLight getIESDirectLight(const in sampler2D iesProfile, const in redIESLight iesLight, const in GeometricContext geometry) {
        IncidentLight directLight;
        vec3 lVector = iesLight.position - geometry.position;
        directLight.direction = normalize(lVector);
        float lightDistance = length(lVector);
        if(testLightInRange(lightDistance, iesLight.distance)) {
            directLight.color = iesLight.color;
            directLight.color *= calcLightAttenuation(lightDistance, iesLight.distance, iesLight.decay);
            directLight.visible = true;
            float _IESMult = 180.0;
            vec3 lightUp = (viewMatrix * vec4(0.0, 1.0, 0.0, 0.0)).xyz;
            float deg = dot(lightUp, directLight.direction);
            float angle = acos(deg) * REDLIGHT_RAD2DEG;
            float candela = texture2D(iesProfile, vec2(angle / _IESMult, 0.0)).x;
            directLight.color *= vec3(candela);
       } else {
            directLight.color = vec3(0.0);
            directLight.visible = false;
       }
        return directLight;
   }
#endif
#ifndef DEPTH_FLOAT_TEXTURES
#define DEPTH_FLOAT_TEXTURES 0
#endif
vec3 packNormalToRGB(const in vec3 normal)
{
    return normalize(normal) * 0.5 + 0.5;
}
vec3 unpackRGBToNormal(const in vec3 rgb)
{
    return 1.0 - 2.0 * rgb.xyz;
}
const float PackUpscale = 256. / 255.;
const float UnpackDownscale = 255. / 256.;
const vec3 PackFactors = vec3(256. * 256. * 256., 256. * 256., 256.);
const vec4 UnpackFactors = UnpackDownscale / vec4(PackFactors, 1.);
const float ShiftRight8 = 1. / 256.;
vec4 packDepthToRGBA(const in float v) {
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
}
float unpackRGBAToDepth(const in vec4 v) {
    return dot(v, UnpackFactors);
}
vec4 encodeDepthToRGBA(const in float v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return vec4(v);
#else
    vec4 r = vec4(fract(v * PackFactors), v);
    r.yzw -= r.xyz * ShiftRight8;
    r *= PackUpscale;
    return r * PackUpscale;
#endif
}
float decodeRGBAToDepth(const in vec4 v) {
#if DEPTH_FLOAT_TEXTURES == 1
    return v.x;
#else
    return dot(v, UnpackFactors);
#endif
}
float viewZToOrthographicDepth(const in float viewZ, const in float near, const in float far)
{
    return (viewZ + near) / (near - far);
}
float orthographicDepthToViewZ(const in float linearClipZ, const in float near, const in float far)
{
    return linearClipZ * (near - far) - near;
}
float viewZToPerspectiveDepth(const in float viewZ, const in float near, const in float far)
{
    return ((near + viewZ) * far) / ((far - near) * viewZ);
}
float perspectiveDepthToViewZ(const in float invClipZ, const in float near, const in float far)
{
    return (near * far) / ((far - near) * invClipZ - far);
}
vec4 encodeFloatRGBA(const in float v) {
  vec4 enc = vec4(1.0, 255.0, 65025.0, 16581375.0) * v;
  enc = fract(enc);
  enc -= enc.yzww * vec4(1.0/255.0,1.0/255.0,1.0/255.0,0.0);
  return enc;
}
float decodeFloatRGBA(const in vec4 rgba) {
  return dot(rgba, vec4(1.0, 1.0/255.0, 1.0/65025.0, 1.0/16581375.0));
}
#ifdef RED_USE_SHADOWMAP
#define RED_SHADOW_QUALITY_LOW 0
#define RED_SHADOW_QUALITY_MEDIUM 1
#define RED_SHADOW_QUALITY_HIGH 2
#ifndef RED_SHADOW_QUALITY
#define RED_SHADOW_QUALITY RED_SHADOW_QUALITY_HIGH
#endif
#ifndef RED_SHADOW_MIN
#define RED_SHADOW_MIN 0.0
#endif
float texture2DCompare(sampler2D depths, vec2 uv, float compare) {
    return step(compare, unpackRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerp(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompare(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompare(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompare(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompare(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float texture2DCompareRED(sampler2D depths, vec2 uv, float compare) {
    return step(compare, decodeRGBAToDepth(texture2D(depths, uv)));
}
float texture2DShadowLerpRED(sampler2D depths, vec2 size, vec2 uv, float compare) {
    const vec2 offset = vec2(0.0, 1.0);
    vec2 texelSize = vec2(1.0) / size;
    vec2 centroidUV = floor(uv * size + 0.5) / size;
    float lb = texture2DCompareRED(depths, centroidUV + texelSize * offset.xx, compare);
    float lt = texture2DCompareRED(depths, centroidUV + texelSize * offset.xy, compare);
    float rb = texture2DCompareRED(depths, centroidUV + texelSize * offset.yx, compare);
    float rt = texture2DCompareRED(depths, centroidUV + texelSize * offset.yy, compare);
    vec2 f = fract(uv * size + 0.5);
    float a = mix(lb, lt, f.y);
    float b = mix(rb, rt, f.y);
    float c = mix(a, b, f.x);
    return c;
}
float getShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord) {
    float shadow = 1.0;
    shadowCoord.xyz /= shadowCoord.w;
    shadowCoord.z += shadowBias;
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    if(frustumTest) {
    #if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DCompare(shadowMap, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
        vec2 texelSize = vec2(1.0) / shadowMapSize;
        float dx0 = - texelSize.x * shadowRadius;
        float dy0 = - texelSize.y * shadowRadius;
        float dx1 = + texelSize.x * shadowRadius;
        float dy1 = + texelSize.y * shadowRadius;
        shadow = (
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy, shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, 0.0), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(0.0, dy1), shadowCoord.z) +
            texture2DShadowLerp(shadowMap, shadowMapSize, shadowCoord.xy + vec2(dx1, dy1), shadowCoord.z)
       ) *(1.0 / 9.0);
    #else
        shadow = texture2DCompare(shadowMap, shadowCoord.xy, shadowCoord.z);
    #endif
   }
    return shadow;
}
float linstep(float low, float high, float v) {
    return clamp((v-low)/(high-low), 0.0, 1.0);
}
float VSM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    vec2 moments = texture2D(depths, uv).xy;
    float p = smoothstep(depth-0.02, depth, moments.x);
    float variance = max(moments.y - moments.x*moments.x, -0.001);
    float d = depth - moments.x;
    float p_max = linstep(shadowRadius, 1.0, variance / (variance + d*d));
    return clamp(max(p, p_max), RED_SHADOW_MIN, 1.0);
}
float ESM(sampler2D depths, vec2 uv, float depth, float shadowRadius) {
    float depthExp = decodeRGBAToDepth(texture2D(depths, uv));
    return clamp(exp(shadowRadius * 100.0 *(depthExp - depth)), 0.0, 1.0);
}
float PCF(sampler2D depths, vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius) {
    float shadow = 1.0;
    vec2 texelSize = vec2(1.0) / shadowMapSize;
    float dx0 = - texelSize.x * shadowRadius;
    float dy0 = - texelSize.y * shadowRadius;
    float dx1 = + texelSize.x * shadowRadius;
    float dy1 = + texelSize.y * shadowRadius;
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_LOW
    shadow = (
        texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
        texture2DCompareRED(depths, uv, depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
        texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
        texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    shadow = (
            texture2DCompareRED(depths, uv + vec2(dx0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, 0.0), depth) +
            texture2DCompareRED(depths, uv, depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1, dy1), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy0 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, 0.0), depth) +
            texture2DCompareRED(depths, uv + vec2(dx0 * 0.5, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(0.0, dy1 * 0.5), depth) +
            texture2DCompareRED(depths, uv + vec2(dx1 * 0.5, dy1 * 0.5), depth)
       ) *(1.0 / 17.0);
#else
    shadow = (
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv, depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, 0.0), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(0.0, dy1), depth) +
        texture2DShadowLerpRED(depths, shadowMapSize, uv + vec2(dx1, dy1), depth)
   ) *(1.0 / 9.0);
#endif
    return shadow;
}
#ifndef DISK_MAX_POISSON_SAMPLES
#define DISK_MAX_POISSON_SAMPLES 32
#endif
#ifndef PPCF_ROTATED
#define PPCF_ROTATED 0
#endif
#if PPCF_ROTATED == 1
float randNoise(vec2 co){
   return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}
#endif
float PPCF(sampler2D depths, const in vec2 shadowMapSize, vec2 uv, float depth, float shadowRadius, const in vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES]) {
#if RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH
    float shadow = 0.0;
#if PPCF_ROTATED == 1
    float noise = randNoise(gl_FragCoord.xy) * PI2;
    mat2 rotationMatrix = mat2(cos(noise), -sin(noise), sin(noise), cos(noise));
#endif
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[0] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[0] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[1] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[1] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[2] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[2] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[3] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[3] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[4] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[4] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[5] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[5] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[6] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[6] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[7] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[7] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[8] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[8] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[9] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[9] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[10] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[10] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[11] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[11] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[12] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[12] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[13] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[13] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[14] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[14] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[15] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[15] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[16] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[16] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[17] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[17] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[18] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[18] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[19] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[19] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[20] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[20] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[21] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[21] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[22] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[22] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[23] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[23] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[24] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[24] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[25] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[25] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[26] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[26] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[27] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[27] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[28] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[28] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[29] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[29] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[30] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[30] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
#if PPCF_ROTATED == 1
        sampleOffset = poissonSamples[31] * rotationMatrix * sampleScale;
#else
        sampleOffset = poissonSamples[31] * sampleScale;
#endif
        shadow += texture2DCompareRED(depths, uv + sampleOffset, depth);
    return shadow / 32.0;
#elif RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[4] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[5] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[6] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[7] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 9.0);
#else
    float shadow = 0.0;
    vec2 sampleScale = (8.0 * 0.5 * vec2(shadowRadius, shadowRadius)) / shadowMapSize;
    vec2 sampleOffset;
    sampleOffset = uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[0] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[1] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[2] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    sampleOffset = poissonSamples[3] * sampleScale + uv;
    shadow += texture2DCompareRED(depths, sampleOffset, depth);
    return shadow * (1.0 / 5.0);
#endif
}
bool inFrustum(vec4 shadowCoord) {
    bvec4 inFrustumVec = bvec4(shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0);
    bool inFrustum = all(inFrustumVec);
    bvec2 frustumTestVec = bvec2(inFrustum, shadowCoord.z <= 1.0);
    bool frustumTest = all(frustumTestVec);
    return frustumTest;
}
vec2 cubeToUV(vec3 v, float texelSizeY) {
    vec3 absV = abs(v);
    float scaleToCube = 1.0 / max(absV.x, max(absV.y, absV.z));
    absV *= scaleToCube;
    v *= scaleToCube *(1.0 - 2.0 * texelSizeY);
    vec2 planar = v.xy;
    float almostATexel = 1.5 * texelSizeY;
    float almostOne = 1.0 - almostATexel;
    if(absV.z >= almostOne) {
        if(v.z > 0.0)
            planar.x = 4.0 - v.x;
   } else if(absV.x >= almostOne) {
        float signX = sign(v.x);
        planar.x = v.z * signX + 2.0 * signX;
   } else if(absV.y >= almostOne) {
        float signY = sign(v.y);
        planar.x = v.x + 2.0 * signY + 2.0;
        planar.y = v.z * signY - 2.0;
   }
    return vec2(0.125, 0.25) * planar + vec2(0.375, 0.75);
}
float getPointShadow(sampler2D shadowMap, vec2 shadowMapSize, float shadowBias, float shadowRadius, vec4 shadowCoord, float shadowCameraNear, float shadowCameraFar) {
    vec2 texelSize = vec2(1.0) /(shadowMapSize * vec2(4.0, 2.0));
    vec3 lightToPosition = shadowCoord.xyz;
    vec3 bd3D = normalize(lightToPosition);
    float dp =(length(lightToPosition) - shadowCameraNear) /(shadowCameraFar - shadowCameraNear);
    dp += shadowBias;
    #if defined(RED_SHADOW_QUALITY) && (RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_HIGH || RED_SHADOW_QUALITY == RED_SHADOW_QUALITY_MEDIUM)
        vec2 offset = vec2(- 1, 1) * shadowRadius * texelSize.y;
        return (
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yyx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxy, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.xxx, texelSize.y), dp) +
            texture2DCompare(shadowMap, cubeToUV(bd3D + offset.yxx, texelSize.y), dp)
       ) *(1.0 / 9.0);
    #else
        return texture2DCompare(shadowMap, cubeToUV(bd3D, texelSize.y), dp);
    #endif
}
#endif
#if defined(USE_AOMAP)
    varying vec2 vUv2;
#endif
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
varying vec3 vTangent;
varying vec3 vBitangent;
#endif
uniform sampler2D baseColorMap;
uniform vec3 baseColor;
uniform float roughness;
uniform float metalness;
uniform sampler2D occRoughMetalMap;
uniform float reflectance;
uniform float opacity;
#if defined(USE_NORMALMAP)
uniform vec2 normalScale;
uniform sampler2D normalMap;
#endif
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#elif defined(RED_ANISOTROPIC)
#define MATERIAL_PARAM AnisotropicMaterial
#endif
#endif
#if NUM_DIR_LIGHTS > 0
uniform DirectionalLight directionalLights[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform SpotLight spotLights[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform PointLight pointLights[NUM_POINT_LIGHTS];
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform DirectionalLight directionalRedLights[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0
uniform redSphereLight sphereLights[RED_LIGHTS_SPHERE_COUNT];
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0
uniform redTubeLight tubeLights[RED_LIGHTS_TUBE_COUNT];
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0
uniform redIESLight iesLights[RED_LIGHTS_IES_COUNT];
uniform sampler2D iesLightsProfile;
#endif
#ifdef RED_USE_SHADOWMAP
#if NUM_DIR_LIGHTS > 0
uniform sampler2D directionalShadowMap[NUM_DIR_LIGHTS];
varying vec4 vDirectionalShadowCoord[NUM_DIR_LIGHTS];
#endif
#if NUM_SPOT_LIGHTS > 0
uniform sampler2D spotShadowMap[NUM_SPOT_LIGHTS];
varying vec4 vSpotShadowCoord[NUM_SPOT_LIGHTS];
#endif
#if NUM_POINT_LIGHTS > 0
uniform sampler2D pointShadowMap[NUM_POINT_LIGHTS];
varying vec4 vPointShadowCoord[NUM_POINT_LIGHTS];
#endif
#define DISK_MAX_POISSON_SAMPLES 32
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0
uniform sampler2D directionalRedShadowMap[RED_LIGHTS_DIRECTIONAL_COUNT];
uniform vec2 poissonSamples[DISK_MAX_POISSON_SAMPLES];
varying vec4 vDirectionalRedShadowCoord[RED_LIGHTS_DIRECTIONAL_COUNT];
#endif
#endif
void evaluate_direct_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float cameraExposure, inout ReflectedLight reflectedLight) {
    IncidentLight directLight;
#if (NUM_POINT_LIGHTS > 0) && defined(RE_Direct)
    PointLight pointLight;
    for(int i = 0; i < NUM_POINT_LIGHTS; i ++) {
        pointLight = pointLights[i];
        directLight = getPointDirectLight(pointLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(pointLight.shadow, directLight.visible)) ? getPointShadow(pointShadowMap[i], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[i], pointLight.shadowCameraNear, pointLight.shadowCameraFar) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if (NUM_SPOT_LIGHTS > 0) && defined(RE_Direct)
    SpotLight spotLight;
    for(int i = 0; i < NUM_SPOT_LIGHTS; i ++) {
        spotLight = spotLights[i];
        directLight = getSpotDirectLight(spotLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(spotLight.shadow, directLight.visible)) ? getShadow(spotShadowMap[i], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if ((NUM_DIR_LIGHTS > 0) || (RED_LIGHTS_DIRECTIONAL_COUNT > 0)) && defined(RE_Direct)
    DirectionalLight directionalLight;
#endif
#if (NUM_DIR_LIGHTS > 0) && defined(RE_Direct)
    for(int i = 0; i < NUM_DIR_LIGHTS; i ++) {
        directionalLight = directionalLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        directLight.color *= cameraExposure;
        #ifdef RED_USE_SHADOWMAP
            directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? getShadow(directionalShadowMap[i], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[i]) : 1.0;
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_DIRECTIONAL_COUNT) && RED_LIGHTS_DIRECTIONAL_COUNT > 0 && defined(RE_Direct)
    for(int i = 0; i < RED_LIGHTS_DIRECTIONAL_COUNT; i++) {
        directionalLight = directionalRedLights[i];
        directLight = getDirectionalDirectLight(directionalLight, geometry);
        #ifdef RED_USE_SHADOWMAP
            vec4 shadowCoord = vDirectionalRedShadowCoord[i];
            shadowCoord.xyz /= shadowCoord.w;
            shadowCoord.z += directionalLight.shadowBias;
            if(inFrustum(shadowCoord)) {
                float shadow = 1.0;
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 1
                    shadow = ESM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 2
                    shadow = VSM(directionalRedShadowMap[i], shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 3
                    shadow = PCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius);
                #endif
                #if defined(USE_RED_SHADOW_TYPE) && USE_RED_SHADOW_TYPE == 4
                    shadow = PPCF(directionalRedShadowMap[i], directionalLight.shadowMapSize, shadowCoord.xy, shadowCoord.z, directionalLight.shadowRadius, poissonSamples);
                #endif
                directLight.color *= all(bvec2(directionalLight.shadow, directLight.visible)) ? shadow : 1.0;
           }
        #endif
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_IES_COUNT) && RED_LIGHTS_IES_COUNT > 0 && defined(RE_Direct)
    redIESLight iesLight;
    for(int i = 0; i < RED_LIGHTS_IES_COUNT; i++) {
        iesLight = iesLights[i];
        directLight = getIESDirectLight(iesLightsProfile, iesLight, geometry);
        RE_Direct(directLight, geometry, material, reflectedLight);
   }
#endif
    IncidentAreaLight areaLight;
#if defined(RED_LIGHTS_SPHERE_COUNT) && RED_LIGHTS_SPHERE_COUNT > 0 && defined(RE_DirectArea)
    redSphereLight sphereLight;
    for(int i = 0; i < RED_LIGHTS_SPHERE_COUNT; i++) {
        sphereLight = sphereLights[i];
        areaLight = getSphereDirectLight(sphereLight, geometry);
        RE_DirectArea(areaLight, geometry, material, sphereLight.radius, reflectedLight);
   }
#endif
#if defined(RED_LIGHTS_TUBE_COUNT) && RED_LIGHTS_TUBE_COUNT > 0 && defined(RE_DirectArea)
    redTubeLight tubeLight;
    for(int i = 0; i < RED_LIGHTS_TUBE_COUNT; i++) {
        tubeLight = tubeLights[i];
        areaLight = getTubeDirectLight(tubeLight, geometry);
        RE_DirectArea(areaLight, geometry, material, tubeLight.radius, reflectedLight);
   }
#endif
}
#ifndef MATERIAL_PARAM
#ifdef RED_STANDARD
#define MATERIAL_PARAM StandardMaterial
#endif
#endif
#if LOW_QUALITY && defined(RED_PROBE_LIGHTING)
    varying vec3 vReflect;
#endif
vec3 getSpecularDominantDirection(vec3 n, vec3 r, float linearRoughness) {
    float s = 1.0 - linearRoughness;
    return mix(n, r, s * (sqrt(s) + linearRoughness));
}
struct LightProbe {
    int mipLevels;
    float iblLuminance;
    float shLuminance;
    vec3 boxMin;
    vec3 boxMax;
};
uniform LightProbe reflectionProbe;
uniform EnvMapSampler reflectionProbeMap;
void evaluate_indirect_lights(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}
void evaluate_indirect_diffuse(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && (defined(RE_IndirectSpecular) || defined(RE_IndirectDiffuse))
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RE_IndirectDiffuse)
    vec3 irradiance = vec3(0.0);
    #if defined(RED_SH_LIGHTING) && RED_SH_LIGHTING == 1
        irradiance += ShadeIrradiance(geometry.world_normal) * reflectionProbe.shLuminance;
    #elif defined(RED_PROBE_LIGHTING) && RED_PROBE_LIGHTING == 1
        irradiance += getLightProbeIndirectIrradiance(reflectionProbeMap, specularLightProbe, geometry.world_normal);
    #endif
    RE_IndirectDiffuse(irradiance, geometry, material, reflectedLight);
#endif
    reflectedLight.indirectDiffuse *= ambientOcclusion;
}
void evaluate_indirect_specular(const in GeometricContext geometry, const in MATERIAL_PARAM material, const in float ambientOcclusion, inout ReflectedLight reflectedLight) {
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    SpecularLightProbe specularLightProbe;
    specularLightProbe.envMapIntensity = reflectionProbe.iblLuminance;
    specularLightProbe.maxMipLevel = float(reflectionProbe.mipLevels);
#endif
#if defined(RED_PROBE_LIGHTING) && defined(RE_IndirectSpecular)
    #if HIGH_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = getSpecularDominantDirection(geometry.normal, reflectVec, material.specularRoughness * material.specularRoughness);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif MEDIUM_QUALITY
        vec3 reflectVec = reflect(-geometry.viewDir, geometry.normal);
        reflectVec = inverseTransformDirection(reflectVec, viewMatrix);
    #elif LOW_QUALITY
        vec3 reflectVec = vReflect;
    #endif
    #if defined(RED_ENVMAP_BOX_PROJECTED) && RED_ENVMAP_BOX_PROJECTED > 0
        vec3 rbmax = (reflectionProbe.boxMax - vWorldPosition.xyz)/reflectVec;
        vec3 rbmin = (reflectionProbe.boxMin - vWorldPosition.xyz)/reflectVec;
        vec3 rbminmax = vec3(reflectVec.x > 0.0 ? rbmax.x : rbmin.x, reflectVec.y > 0.0 ? rbmax.y : rbmin.y, reflectVec.z > 0.0 ? rbmax.z : rbmin.z);
        float fa      = min(min(rbminmax.x, rbminmax.y), rbminmax.z);
        vec3 posonbox = vWorldPosition.xyz + reflectVec * fa;
        reflectVec    = normalize(posonbox - (reflectionProbe.boxMin + reflectionProbe.boxMax)*0.5);
    #endif
    vec3 radiance = getLightProbeIndirectRadiance(reflectionProbeMap, specularLightProbe, reflectVec, material.specularRoughness);
    RE_IndirectSpecular(radiance, geometry, material, reflectedLight);
#endif
    float dotNV = saturate(dot(geometry.normal, geometry.viewDir));
    reflectedLight.indirectSpecular *= computeSpecularOcclusion(dotNV, ambientOcclusion, material.specularRoughness);
}

uniform float toneMappingExposure;
uniform float toneMappingWhitePoint;
void main() {
    ReflectedLight reflectedLight = ReflectedLight(vec3(0.0), vec3(0.0), vec3(0.0), vec3(0.0));
    vec4 diffuseColorMap = texture2D(baseColorMap, vUv);
    diffuseColorMap.rgb = inputToLinear(diffuseColorMap.rgb * diffuseColorMap.a);
    vec4 diffuseColor;
    diffuseColor.rgb = (opacity * baseColor.rgb) * diffuseColorMap.rgb;
    float outOpacity = opacity * diffuseColorMap.a;
    vec4 texelOcclusionRoughnessMetalness = texture2D(occRoughMetalMap, vUv);
    float metalnessFactor = texelOcclusionRoughnessMetalness.b * metalness;
    float roughnessFactor = texelOcclusionRoughnessMetalness.g * roughness;
    vec3 normal = normalize(vNormal);
#if defined(USE_NORMALMAP)
#if defined(RED_USE_QTANGENT) && RED_USE_QTANGENT > 0
    vec3 mapN = texture2D(normalMap, vUv).xyz * 2.0 - 1.0;
    mapN.xy = normalScale * mapN.xy;
    mat3 tsn = mat3(vTangent, vBitangent, vNormal);
    normal = normalize(tsn * mapN);
#else
    normal = perturbNormal2Arb(vUv, normalMap, -vViewPosition, normalScale, normal);
#endif
#endif
    StandardMaterial material;
    material.diffuseColor      = diffuseColor.rgb * (1.0 - metalnessFactor);
    material.specularRoughness = clamp(roughnessFactor, 0.04, 1.0);
#if HIGH_QUALITY == 1 || MEDIUM_QUALITY == 1
    material.specularColor = 0.16 * reflectance * reflectance * (1.0 - metalnessFactor) + diffuseColor.rgb * metalnessFactor;
#else
    material.specularColor = mix(vec3(0.04), diffuseColor.rgb, metalnessFactor);
#endif
    GeometricContext geometry;
    geometry.position = -vViewPosition;
    geometry.normal   = normal;
    geometry.viewDir  = normalize(vViewPosition);
    geometry.world_normal = normalize(inverseTransformDirection(normal, viewMatrix));
    evaluate_direct_lights(geometry, material, toneMappingExposure, reflectedLight);
    float ambientOcclusion = texelOcclusionRoughnessMetalness.r;
    evaluate_indirect_lights(geometry, material, ambientOcclusion, reflectedLight);
    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular;
    outgoingLight = toneMapping(outgoingLight, 1.0, toneMappingWhitePoint);
    outgoingLight = linearToOutput(outgoingLight);
    gl_FragColor = vec4(outgoingLight, outOpacity);
}
`;
ShaderChunk['redUnlit_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
varying vec2 vUv;
uniform vec3 baseColor;
uniform sampler2D baseColorMap;
#ifdef USE_FOG
    uniform vec3 fogColor;
    #ifdef FOG_EXP2
        uniform float fogDensity;
    #else
        uniform float fogNear;
        uniform float fogFar;
    #endif
#endif
void main() {
    vec4 diffuseColor = vec4(baseColor.rgb, 1.0);
    vec4 texelColor = texture2D(baseColorMap, vUv);
    texelColor.xyz = inputToLinear(texelColor.xyz);
    diffuseColor.rgb *= texelColor.rgb;
#ifdef USE_FOG
    #ifdef USE_LOGDEPTHBUF_EXT
        float depth = gl_FragDepthEXT / gl_FragCoord.w;
    #else
        float depth = gl_FragCoord.z / gl_FragCoord.w;
    #endif
    #ifdef FOG_EXP2
        float fogFactor = whiteCompliment(exp2(- fogDensity * fogDensity * depth * depth * LOG2));
    #else
        float fogFactor = smoothstep(fogNear, fogFar, depth);
    #endif
    diffuseColor.rgb = mix(diffuseColor.rgb, fogColor, fogFactor);
#endif
    gl_FragColor = diffuseColor;
}
`;
ShaderChunk['redUnlit_Vertex'] = `precision highp float;
precision highp int;
precision highp sampler2D;
attribute vec3 position;
attribute vec2 uv;
#if defined(USE_INSTANCING)
    attribute vec4 mcol0;
    attribute vec4 mcol1;
    attribute vec4 mcol2;
#endif
varying vec2 vUv;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform vec4 offsetRepeat;
void main() {
    vUv = uv * offsetRepeat.zw + offsetRepeat.xy;
#ifdef USE_INSTANCING
    mat4 instanceMat = mat4(vec4(mcol0.xyz , 0), vec4(mcol1.xyz , 0), vec4(mcol2.xyz, 0), vec4(mcol0.w, mcol1.w, mcol2.w, 1));
    vec4 mvPosition = viewMatrix * instanceMat * modelMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
#else
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * mvPosition;
#endif
}`;
ShaderChunk['redUnlitTransparent_Pixel'] = `precision highp float;
precision highp int;
precision highp sampler2D;
#define PI 3.14159
#define PI2 6.28318
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
#define saturate(a) clamp(a, 0.0, 1.0)
#endif
#define whiteCompliment(a)(1.0 - saturate(a))
#ifndef GAMMA_FACTOR
#define GAMMA_FACTOR 2.2
#endif
#ifndef RED_USE_GAMMA_CORRECTED
#define RED_USE_GAMMA_CORRECTED 1
#endif
#ifndef LOW_QUALITY
#define LOW_QUALITY 0
#endif
#ifndef MEDIUM_QUALITY
#define MEDIUM_QUALITY 0
#endif
#ifndef HIGH_QUALITY
#define HIGH_QUALITY 1
#endif
#define ToneMappingHelper(x) max(((x *(0.15 * x + 0.10 * 0.50) + 0.20 * 0.02) /(x *(0.15 * x + 0.50) + 0.20 * 0.30)) - 0.02 / 0.30, vec3(0.0))
float square(const in float x){return x*x;}
float average(const in vec3 color){return dot(color, vec3(0.3333));}
float pow2(const in float x){return x*x;}
float pow3(const in float x){return x*x*x;}
float pow4(const in float x){float x2 = x*x; return x2*x2;}
float pow5(const in float x){return pow4(x)*x;}
struct IncidentLight {
    vec3 color;
    vec3 direction;
    bool visible;
};
struct IncidentAreaLight {
    vec3 color;
    vec3 direction;
    float ndotl;
    float distance;
    bool visible;
};
struct ReflectedLight {
    vec3 directDiffuse;
    vec3 directSpecular;
    vec3 indirectDiffuse;
    vec3 indirectSpecular;
};
struct GeometricContext {
    vec3 position;
    vec3 normal;
    vec3 viewDir;
    vec3 world_normal;
};
vec3 transformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((matrix * vec4(dir, 0.0)).xyz);
}
vec3 inverseTransformDirection(in vec3 dir, in mat4 matrix) {
    return normalize((vec4(dir, 0.0) * matrix).xyz);
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    float distance = dot(planeNormal, point - pointOnPlane);
    return - distance * planeNormal + point;
}
float sideOfPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal) {
    return sign(dot(point - pointOnPlane, planeNormal));
}
vec3 linePlaneIntersect(in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal) {
    return lineDirection *(dot(planeNormal, pointOnPlane - pointOnLine) / dot(planeNormal, lineDirection)) + pointOnLine;
}
vec3 inputToLinear(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(float(GAMMA_FACTOR)));
#endif
}
vec3 linearToOutput(in vec3 a) {
#if (MEDIUM_QUALITY == 1 || LOW_QUALITY == 1)
    return a;
#else
    return pow(a, vec3(1.0 / float(GAMMA_FACTOR)));
#endif
}
vec3 toneMapping(vec3 color, float exposure, float whitepoint) {
	color *= exposure;
	return saturate(ToneMappingHelper(color) / ToneMappingHelper(vec3(whitepoint)));
}
vec3 RGBMDecode(vec4 rgbm) {
  return 8.0 * rgbm.rgb * rgbm.a;
}
varying vec2 vUv;
uniform vec3 baseColor;
uniform sampler2D baseColorMap;
uniform float opacity;
#ifdef USE_FOG
    uniform vec3 fogColor;
    #ifdef FOG_EXP2
        uniform float fogDensity;
    #else
        uniform float fogNear;
        uniform float fogFar;
    #endif
#endif
void main() {
    vec4 diffuseColorMap = texture2D(baseColorMap, vUv);
    diffuseColorMap.rgb = inputToLinear(diffuseColorMap.rgb * diffuseColorMap.a);
    vec4 diffuseColor;
    diffuseColor.rgb = (opacity * baseColor.rgb) * diffuseColorMap.rgb;
    float outOpacity = opacity * diffuseColorMap.a;
#ifdef USE_FOG
    #ifdef USE_LOGDEPTHBUF_EXT
        float depth = gl_FragDepthEXT / gl_FragCoord.w;
    #else
        float depth = gl_FragCoord.z / gl_FragCoord.w;
    #endif
    #ifdef FOG_EXP2
        float fogFactor = whiteCompliment(exp2(- fogDensity * fogDensity * depth * depth * LOG2));
    #else
        float fogFactor = smoothstep(fogNear, fogFar, depth);
    #endif
    diffuseColor.rgb = mix(diffuseColor.rgb, fogColor, fogFactor);
#endif
    diffuseColor.rgb = linearToOutput(diffuseColor.rgb);
    gl_FragColor = vec4(diffuseColor.xyz, outOpacity);
}
`;
