
import * as THREE from "redTyped/three"
import { Camera } from "redTyped/three";

import {Block} from '../../block/Block';

import {seed, simplex2} from './PerlinNoise';
import {StaticValueController,StaticValue} from '../../helper/StaticValueController';

import './hexagonShader_Top';
import { AppDelegate } from "redTyped/framework/AppDelegate";
import { NumberAnimation, EValueInterpolation, Vector3Animation } from "redTyped/animation/ValueAnimation";
import { RenderQuality } from "redTyped/render/QualityLevels";
import { createPlatformRenderInitConfig } from "redTyped/render/Config";
import { CameraComponent } from "redTyped/framework-components/CameraComponent";
import { ShaderLibrary } from "redTyped/render/ShaderLibrary";
import { math } from "redTyped/core/Math";
import { FileStat } from "redTyped/io/AssetInfo";


/** Application */
export class Hexagon3D_Top extends AppDelegate {

    public static maxHeight = 5.0;
    public static hexaCount = 22;
    public static instances = Hexagon3D_Top.hexaCount * Hexagon3D_Top.hexaCount;
    public static radius = 5.0;
    public static gap = 0.015;
    public static gridAnimSpeed = 8.0;
    //public static HexagonInitColor = [0.039, 0.113, 0.3392];
    //public static HexagonBackgroundColor = [0.0, 0.02, 0.07];

    public static HexagonInitColor = [10/255, 50/255, 110/255];
    //public static HexagonBackgroundColor = [10/255, 50/255, 110/255];
    public static HexagonBackgroundColor = [9/255, 45/255, 100/255];

    /** point light */
    private _pointLight:any;

    private xLightPos:number = -50;
    private xLightTargetPos:number = 100;
    private xLightVelocity:number = 0;
    private zLightPos:number = 0;
    private zLightTargetPos:number = 0;
    private zLightVelocity:number = 0;

    /** hexa */
    public hexagons = [];
    public hexagonGeometry;
    public cylinderMesh;
    public hexagonMesh;

    /** random animation */
    private _noiseXOffset:number = 0;
    private _noiseYOffset:number = 0;
    private _noiseXSign:NumberAnimation = new NumberAnimation(EValueInterpolation.Linear, 0.25);
    private _noiseYSign:NumberAnimation = new NumberAnimation(EValueInterpolation.Linear, 0.25);

    private rangeAnim:NumberAnimation = new NumberAnimation(EValueInterpolation.Linear, 1.0);

    private _lastNoiseChangeTime:number = 0;

    /** DOM */
    private _sceneElement:Element;
    private _competenceRootElement:HTMLElement = null;
    private _competenceContentElement:HTMLElement = null;
    private _competenceBraketLeftElement:HTMLElement = null;
    private _competenceBraketRightElement:HTMLElement = null;
    private _staticValues:Array<StaticValue>;

    /** construction */
    constructor(domElement:Element, private _block:Block, params:any) {
        super();

        this._sceneElement = domElement || document.querySelector(".scene");

        let qualityLevel = RenderQuality.HighQuality;

        this._renderConfig = createPlatformRenderInitConfig({
            DOMElement: this.containerElement(),
            qualityLevel: qualityLevel,
            renderShadowMaps: true,
            renderAntialias: true,
            renderOffscreen: false
        });

        this._competenceRootElement =  this._block.domElement.querySelector('.competence_switcher') as HTMLElement;
        if(this._competenceRootElement) {
            this._competenceContentElement = this._competenceRootElement.querySelector('.competence_switcher_content') as HTMLElement;

            let brakets = this._competenceRootElement.querySelectorAll('.competence_switcher_braket');
            this._competenceBraketLeftElement =  brakets[0] as HTMLElement;
            this._competenceBraketRightElement =  brakets[1] as HTMLElement;
        }
    }

    /**
     * preload callback
     */
    onPreloadItems() {

    }

    onInitialization() {
        super.onInitialization();

        const cameraComponent = this.world.findByName("mainCamera").getComponent<CameraComponent>(CameraComponent);

        // orbit camera
        // cameraComponent.setupOrbitController(this._sceneElement);

        // cameraComponent.orbit.enableDamping = true;
        // cameraComponent.orbit.dampingFactor = 0.2;
        // cameraComponent.orbit.enablePan = true;

        // var dpr = window.devicePixelRatio !== undefined ? window.devicePixelRatio : 1.0;
        // cameraComponent.orbit.zoomSpeed = Platform.get().isTouchDevice ? 2.0 / dpr : 2.0;
        // cameraComponent.orbit.rotateSpeed = Platform.get().isTouchDevice ? 0.15 : 0.25;
        // cameraComponent.orbit.minDistance = 1.0;
        // cameraComponent.orbit.maxDistance = 300.0;

        //cameraComponent.target.set(0.0, 0.0, 0.0);

        cameraComponent.sceneCamera.position.set(0.0, 90.0, 0.0);
        cameraComponent.sceneCamera.rotation.set(-Math.PI*0.5,0.0,Math.PI*0.0);
        cameraComponent.entity.position.set(0.0, 90.0, 0.0);
        cameraComponent.entity.rotation.set(-Math.PI*0.5,0.0,Math.PI*0.0);
        cameraComponent.entity.updateTransform();
        cameraComponent.near = 1.0;
        cameraComponent.far = 400.0;
        cameraComponent.fov = 60.0;

        this.world.setEnvironment({
            color: [0, 26.0/255.0, 66.0/255.0]
        });

        this._initGroundPlane();
        this._initHexagonGrid();
    }

    containerElement():HTMLElement {
        return this._sceneElement as HTMLElement;
    }

    _initGroundPlane() {

        // let geometry = new THREE.PlaneGeometry(179, 156, 1);
        // const planeHeight = 4.95;
        // //const planeHeight = 5.05;
        // let material = new THREE.MeshBasicMaterial( {color: 0x24536f} );
        // //let material = new THREE.MeshBasicMaterial( {color: 0x333631} );
        // //let material = new THREE.MeshBasicMaterial( {color: 0x333631, transparent: true, opacity:0.99} );
        // //let material = new THREE.MeshStandardMaterial( {color: 0x030601, roughness: 0.6, metalness: 0.0} );
        // let plane = new THREE.Mesh( geometry, material );
        // plane.position.set(0,planeHeight,0);
        // plane.rotation.set(-Math.PI*0.5,0,0);
        // this.world.scene.add(plane);

        let geometryGrey = new THREE.PlaneGeometry(1000, 1000, 1);
        const planeHeightGrey = 4.95;
        //const planeHeightGrey = 10.05;
        //let materialGrey = new THREE.MeshBasicMaterial( {color: 0x040b1e} );
        //let materialGrey = new THREE.MeshBasicMaterial( {color: 0x071b3b} );
        let materialGrey = new THREE.MeshPhongMaterial({color: 0x001a42, shininess: 1.0, specular: 0x001a42} );


        //let materialGrey = new THREE.MeshBasicMaterial( {color: 0x333631, transparent: true, opacity:0.99} );
        //let materialGrey = new THREE.MeshStandardMaterial( {color: 0x030601, roughness: 0.6, metalness: 0.0} );
        let planeGrey = new THREE.Mesh( geometryGrey, materialGrey );
        planeGrey.position.set(0,planeHeightGrey,0);
        planeGrey.rotation.set(-Math.PI*0.5,0,0);
        this.world.scene.add(planeGrey);


        this._pointLight = new THREE.PointLight(0x888888, 4.0, 50.0);
        this._pointLight.position.set( 0, 30.0, 0 );
        this._pointLight.castShadow = false;
        this.world.scene.add( this._pointLight );

    }


    _initHexagonGrid() {

        const stepWidth = (2*Hexagon3D_Top.radius*Math.cos(Math.PI/6))*(1+Hexagon3D_Top.gap);
        const stepHeight = (Hexagon3D_Top.radius + Hexagon3D_Top.radius*Math.sin(Math.PI/6))*(1+Hexagon3D_Top.gap);

        this.hexagonGeometry = new THREE.InstancedBufferGeometry();
        this.hexagonGeometry.maxInstancedCount = Hexagon3D_Top.instances;
        this.hexagonGeometry.dynamic = true;

        let cylinderGeometry = new THREE.CylinderBufferGeometry(Hexagon3D_Top.radius, Hexagon3D_Top.radius, 10, 6 );
        this.cylinderMesh = new THREE.Mesh( cylinderGeometry);

        let hexagonVertices = new THREE.BufferAttribute(new Float32Array(Array.prototype.slice.call(cylinderGeometry.attributes.position.array)), 3);
        let hexagonNormals =  new THREE.BufferAttribute(new Float32Array(Array.prototype.slice.call(cylinderGeometry.attributes.normal.array)), 3);

        let vertexCount = hexagonVertices.count;

        let hexagonIndex = new THREE.BufferAttribute(new Uint16Array(Array.prototype.slice.call(cylinderGeometry.index.array)), 1);
        let hexagonNeighbours0Array = new Float32Array(Hexagon3D_Top.instances * 3);
        let hexagonNeighbours0 = new THREE.InstancedBufferAttribute(hexagonNeighbours0Array, 3, 1 );
        let hexagonNeighbours1Array = new Float32Array(Hexagon3D_Top.instances * 3);
        let hexagonNeighbours1 = new THREE.InstancedBufferAttribute(hexagonNeighbours1Array, 3, 1 );
        let hexagonOffsetsArray = new Float32Array(Hexagon3D_Top.instances * 3);
        let hexagonOffsets = new THREE.InstancedBufferAttribute(hexagonOffsetsArray, 3, 1 );
        let hexagonColorsArray = new Float32Array(Hexagon3D_Top.instances * 3);
        let hexagonColors = new THREE.InstancedBufferAttribute(hexagonColorsArray, 3, 1 );

        let sizeX = (Hexagon3D_Top.hexaCount-1) * stepWidth + 3 * Hexagon3D_Top.radius * Math.cos(Math.PI/6) + 2 * Hexagon3D_Top.gap;
        let sizeY = (Hexagon3D_Top.hexaCount-1) * stepHeight + Hexagon3D_Top.radius * 2;

        for(let y=0; y < Hexagon3D_Top.hexaCount; y++) {

            this.hexagons[y] = [];

            let yPosition = y*stepHeight;
            let xOffset = (y & 1) ? stepWidth * 0.5 : 0.0;

            for(let x=0; x < Hexagon3D_Top.hexaCount; x++) {

                let xPosition = (x * stepWidth) + xOffset;
                let index = y * Hexagon3D_Top.hexaCount * 3 + (x * 3);
                let position = new THREE.Vector3(xPosition+Hexagon3D_Top.radius*Math.cos(Math.PI/6) - (sizeX*0.5), 5.0, yPosition+Hexagon3D_Top.radius - (sizeY*0.5));

                hexagonOffsetsArray[index] = position.x;
                hexagonOffsetsArray[index+1] = position.y;
                hexagonOffsetsArray[index+2] =  position.z;

                hexagonColorsArray[index] = Hexagon3D_Top.HexagonInitColor[0];
                hexagonColorsArray[index+1] = Hexagon3D_Top.HexagonInitColor[1];
                hexagonColorsArray[index+2] =  Hexagon3D_Top.HexagonInitColor[2];

                this.hexagons[y][x] = {
                    offset: new THREE.Vector2(position.x, position.z),
                    height: new NumberAnimation(EValueInterpolation.SmoothDamp, position.y),
                    color: new Vector3Animation(EValueInterpolation.Linear, new THREE.Vector3(1,1,1)),
                    neighborHeights: [],
                    sprite: null
                };

                this.hexagons[y][x].height.speed = Hexagon3D_Top.gridAnimSpeed;
                this.hexagons[y][x].color.speed = Hexagon3D_Top.gridAnimSpeed;
                this.hexagons[y][x].color.value = new THREE.Vector3(Hexagon3D_Top.HexagonInitColor[0], Hexagon3D_Top.HexagonInitColor[1], Hexagon3D_Top.HexagonInitColor[2]);

                hexagonNeighbours0Array[index] = 0.0;
                hexagonNeighbours0Array[index+1] = 0.0;
                hexagonNeighbours0Array[index+2] = 0.0;

                hexagonNeighbours1Array[index+0] = 0.0;
                hexagonNeighbours1Array[index+1] = 0.0;
                hexagonNeighbours1Array[index+2] = 0.0;

                //let edges = new THREE.VertexNormalsHelper(cylinder, 2, 0x00ff00, 1 );
                //this.scene.add( edges );
            }
        }

        this.hexagonGeometry.addAttribute('position', hexagonVertices);
        this.hexagonGeometry.addAttribute('normal', hexagonNormals);
        this.hexagonGeometry.addAttribute('offset', hexagonOffsets);
        this.hexagonGeometry.addAttribute('color', hexagonColors);
        this.hexagonGeometry.addAttribute('neighborHeights0', hexagonNeighbours0);
        this.hexagonGeometry.addAttribute('neighborHeights1', hexagonNeighbours1);

        this.hexagonGeometry.setIndex(hexagonIndex);

        let matShader = this._initHexagonMaterial();

        // add mesh
        this.hexagonMesh = new THREE.Mesh(this.hexagonGeometry, matShader);
        this.hexagonMesh.castShadow = true;
        this.hexagonMesh.receiveShadow = true;
        this.world.scene.add(this.hexagonMesh);

    }


    private _initHexagonMaterial() {

        const stepWidth = (2*Hexagon3D_Top.radius*Math.cos(Math.PI/6))*(1+Hexagon3D_Top.gap);
        const stepHeight = (Hexagon3D_Top.radius + Hexagon3D_Top.radius*Math.sin(Math.PI/6))*(1+Hexagon3D_Top.gap);

        let sizeX = (Hexagon3D_Top.hexaCount-1) * stepWidth + 3 * Hexagon3D_Top.radius * Math.cos(Math.PI/6) + 2 * Hexagon3D_Top.gap;
        let sizeY = (Hexagon3D_Top.hexaCount-1) * stepHeight + Hexagon3D_Top.radius * 2;

        // generate texture
        //let template = MaterialLibrary.findMaterialByName("matHexagonTop");
        let template = {
            shader: "hexagonTop",
            offsetRepeat: [
                0.0,
                0.0,
                1.0,
                1.0
            ],
            diffuse: [
                1.0,
                1.0,
                1.0
            ],
            transparent: false,
            metalness: 0.0,
            roughness: 0.6
        };

        template['gridSize'] = [2.0/sizeX, 2.0/sizeY];
        template['background'] = new THREE.Color(Hexagon3D_Top.HexagonBackgroundColor[0], Hexagon3D_Top.HexagonBackgroundColor[1], Hexagon3D_Top.HexagonBackgroundColor[2]);

        let matShader = ShaderLibrary.createMaterialShader("hexagonTop", template);
        matShader.flatShading = true;

        return matShader;
    }


   private _startLightAnim(){
       setInterval(()=>{
           this.xLightTargetPos = (Math.random() -0.5) * 200;
           this.zLightTargetPos = (Math.random() -0.5) * 150;
       },2000);
   }

   private _animLight(deltaTime:number) {
       let resultX = math.SmoothDamp(this.xLightPos,this.xLightTargetPos,this.xLightVelocity,3,999,deltaTime);
       this.xLightPos = resultX.value;
       this.xLightVelocity = resultX.currentVelocity;

       let resultZ = math.SmoothDamp(this.zLightPos,this.zLightTargetPos,this.zLightVelocity,3,999,deltaTime);
       this.zLightPos = resultZ.value;
       this.zLightVelocity = resultZ.currentVelocity;

       this._pointLight.position.fromArray([this.xLightPos,this._pointLight.position.y, this.zLightPos]);
   }

   public onMouseMove(event:Event) {

        const mainCamera = CameraComponent.Main;

        this.xLightTargetPos = this.mouse.normalizedScreenX * 200;
        this.zLightTargetPos = this.mouse.normalizedScreenY * -150;

        if(mainCamera) {

            let vector = new THREE.Vector3();

            let depth = (mainCamera.far-4.95) / mainCamera.far;

            vector.set(
                this.mouse.normalizedScreenX,
                this.mouse.normalizedScreenY,
                depth );

            vector.unproject( mainCamera.sceneCamera as Camera );

            this.xLightTargetPos = vector.x;
            this.zLightTargetPos = vector.z;
        }

        //console.log(vector);
    }

    /** frame tick */
    update(delta:number) {
        super.update(delta);

        this._animLight(delta);

        this._randomAnimGrid();

        this._updateGrid();


    }

    private _randomAnimGrid() {
        // every n seconds we change the translation
        const SignChangeTime = 10.0;
        const lastNoiseChange = (Date.now()-this._lastNoiseChangeTime)/1000;

        if(lastNoiseChange > SignChangeTime) {
            //this._noiseXSign.value = Math.random() > 0.5 ? 1.0 : 0.75;
            //this._noiseYSign.value = Math.random() > 0.5 ? 1.0 : 0.75;

            this.rangeAnim.value = Math.random();
            this.rangeAnim.speed = 0.1;

            this._lastNoiseChangeTime = Date.now();
        }

        const speed = 0.05;

        //const range = 7.5;
        const range = this.rangeAnim.smoothValue * 0.5 + 7.5;

        const blockHeight = 10.0;

        const REDcolor = new THREE.Vector3().fromArray(Hexagon3D_Top.HexagonInitColor);
        const GREYcolor = new THREE.Vector3().fromArray(Hexagon3D_Top.HexagonBackgroundColor);

        const lerpVector = new THREE.Vector3();
        const halfElements = Math.floor(this.hexagons.length / 2.0);

        for(let y = 0; y < this.hexagons.length; ++y) {
            for(let x = 0; x < this.hexagons[y].length; ++x) {


                let value = simplex2((this._noiseXOffset + x)/range, (this._noiseYOffset + y)/range) + 1.0 * 0.5;
                value = Math.min(1.0, Math.max(0.0, value));


                let distanceToCenter = (x - halfElements) * (x - halfElements) + (y - halfElements) * (y - halfElements);
                distanceToCenter /= (halfElements * halfElements);
                distanceToCenter = Math.min(1.0, Math.max(0.0, distanceToCenter));
                value *= 1.0 - distanceToCenter;

                //console.log(value);
                const height = value * blockHeight;

                this.hexagons[y][x].height.value = 5.0 + height;


                lerpVector.lerpVectors(GREYcolor, REDcolor, value);
                this.hexagons[y][x].color.value = lerpVector.clone();

                //this.hexagons[y][x].color.value = new THREE.Vector3((1.0 - value) * 0.2 + value * 1.0, this.hexagons[y][x].color.value.y, this.hexagons[y][x].color.value.z);
            }
        }

        this._noiseXOffset += this._noiseXSign.smoothValue * speed;
        this._noiseYOffset += this._noiseYSign.smoothValue * speed;
    }

    private _updateGrid() {

        if(this.hexagonGeometry.boundingSphere != null){
            this.hexagonGeometry.boundingSphere.radius = 200;
        }

        for(let y = 0; y < this.hexagons.length; ++y) {

            for(let x = 0; x < this.hexagons[y].length; ++x) {

                let index = y * Hexagon3D_Top.hexaCount * 3 + (x * 3);

                //if(y%2==0){
                if((y & 1) == 0) {
                    this.hexagons[y][x].neighborHeights = [
                        this._getHeightNeighbor(this.hexagons, y-1, x-1),
                        this._getHeightNeighbor(this.hexagons, y-1, x),
                        this._getHeightNeighbor(this.hexagons, y, x+1),
                        this._getHeightNeighbor(this.hexagons, y+1, x),
                        this._getHeightNeighbor(this.hexagons, y+1, x-1),
                        this._getHeightNeighbor(this.hexagons, y, x-1)
                    ];
                } else {
                        this.hexagons[y][x].neighborHeights = [
                        this._getHeightNeighbor(this.hexagons, y-1, x),
                        this._getHeightNeighbor(this.hexagons, y-1, x+1),
                        this._getHeightNeighbor(this.hexagons, y, x+1),
                        this._getHeightNeighbor(this.hexagons, y+1, x+1),
                        this._getHeightNeighbor(this.hexagons, y+1, x),
                        this._getHeightNeighbor(this.hexagons, y, x-1)
                    ];
                }


                this.hexagonGeometry.attributes.color.array[index] = this.hexagons[y][x].color.smoothValue.x;
                this.hexagonGeometry.attributes.color.array[index+1] = this.hexagons[y][x].color.smoothValue.y;
                this.hexagonGeometry.attributes.color.array[index+2] = this.hexagons[y][x].color.smoothValue.z;

                this.hexagonGeometry.attributes.offset.array[index] = this.hexagons[y][x].offset.x;
                this.hexagonGeometry.attributes.offset.array[index+1] = this.hexagons[y][x].height.smoothValue - 5.0;
                this.hexagonGeometry.attributes.offset.array[index+2] = this.hexagons[y][x].offset.y;

                this.hexagonGeometry.attributes.neighborHeights0.array[index] = this.hexagons[y][x].neighborHeights[0];
                this.hexagonGeometry.attributes.neighborHeights0.array[index+1] = this.hexagons[y][x].neighborHeights[1];
                this.hexagonGeometry.attributes.neighborHeights0.array[index+2] = this.hexagons[y][x].neighborHeights[2];

                this.hexagonGeometry.attributes.neighborHeights1.array[index] = this.hexagons[y][x].neighborHeights[3];
                this.hexagonGeometry.attributes.neighborHeights1.array[index+1] = this.hexagons[y][x].neighborHeights[4];
                this.hexagonGeometry.attributes.neighborHeights1.array[index+2] = this.hexagons[y][x].neighborHeights[5];

                if(this.hexagons[y][x].sprite) {
                    this.hexagons[y][x].sprite.position.set(this.hexagons[y][x].offset.x, this.hexagons[y][x].height.smoothValue + 1.0, this.hexagons[y][x].offset.y);
                }

            }
        }

        this.hexagonGeometry.attributes.offset.needsUpdate = true;
        this.hexagonGeometry.attributes.color.needsUpdate = true;
        this.hexagonGeometry.attributes.neighborHeights0.needsUpdate = true;
        this.hexagonGeometry.attributes.neighborHeights1.needsUpdate = true;
    }

    private _getHeightNeighbor(hexagons, y, x) {
        if(y >= 0 && y < hexagons.length) {
            if(x >= 0 && x < hexagons[y].length) {
                //return hexagons[y][x].height;
                return hexagons[y][x].height.smoothValue;
            }
        }
        return -1.0;
    }

    onLoad(loadingScreen:boolean) {
    }

	onLoadProgress(stats:FileStat) {

    }

    onLoadFinished() {
        // window.addEventListener("mousemove",this.handleMouseMove);
        window.addEventListener("resize", this.layoutBrakets);
        window.addEventListener("orientationchange",this.layoutBrakets,false);
        this.layoutBrakets();

        let preloader = this.containerElement().querySelector(".scene_preloader");
        jQuery(preloader).fadeOut();

        //TODO
        this._startLightAnim();

        StaticValueController.getValues([
            "/^scene\.home\.competence\.value..*$/"
        ]).then((values)=>{

            this._staticValues = values;

            setTimeout(()=>{
                this._startCompetenceAnimation();
            },2000);
        }).catch((error)=>{
        });
    }

    private layoutBrakets = () => {
        if(!this._competenceContentElement) {
            return;
        }

        let contentRect = this._competenceContentElement.getBoundingClientRect();
        let offsetLeft = this._competenceContentElement.offsetLeft;
        let offsetTop = this._competenceContentElement.offsetTop;

        let braketRect = this._competenceBraketLeftElement.getBoundingClientRect();
        let braketWidth = braketRect.width;
        let braketHeight = braketRect.height;
        let braketTop = (offsetTop + (contentRect.height * 0.5) - (braketHeight * 0.5));

        let braketLeftCenter  = offsetLeft + (contentRect.width * 0.5) - braketWidth;
        let braketRightCenter = offsetLeft + (contentRect.width * 0.5);

        let translateXLeft = (contentRect.width * -0.5) - 15;
        let translateXRight = (contentRect.width * 0.5) + 15;

        let braketLeftRect = this._competenceBraketLeftElement.getBoundingClientRect();
        this._competenceBraketLeftElement.style.position = "absolute";
        this._competenceBraketLeftElement.style.lineHeight = "1";
        this._competenceBraketLeftElement.style.left = braketLeftCenter + "px";
        this._competenceBraketLeftElement.style.top = braketTop + "px";
        this._competenceBraketLeftElement.style.transform = `translateX(${translateXLeft}px)`;
        this._competenceBraketLeftElement.style.transition = "transform 0.3s ease";

        let braketRightRect = this._competenceBraketRightElement.getBoundingClientRect();
        this._competenceBraketRightElement.style.position = "absolute";
        this._competenceBraketRightElement.style.lineHeight = "1";
        this._competenceBraketRightElement.style.left = braketRightCenter + "px";
        this._competenceBraketRightElement.style.top = braketTop + "px";
        this._competenceBraketRightElement.style.transform = `translateX(${translateXRight}px)`;
        this._competenceBraketRightElement.style.transition = "transform 0.3s ease";

        this._competenceContentElement.style.transition = "opacity 0.3s ease";
    }

    private _startCompetenceAnimation = () => {
        if(!this._competenceContentElement || !this._competenceRootElement) {
            return;
        }

        this._competenceContentElement.style.opacity = "0";

        setTimeout(()=>{
            let staticValue = this._staticValues.shift();
            this._staticValues.push(staticValue);
            this._competenceContentElement.innerHTML = staticValue.value;

            let contentRect = this._competenceContentElement.getBoundingClientRect();
            let translateXLeft = (contentRect.width * -0.5) - 15;
            let translateXRight = (contentRect.width * 0.5) + 15;

            this._competenceBraketLeftElement.style.transform = `translateX(0)`;
            this._competenceBraketRightElement.style.transform = `translateX(0)`;

            setTimeout(()=>{
                this._competenceBraketLeftElement.style.transform = `translateX(${translateXLeft - 50}px)`;
                this._competenceBraketRightElement.style.transform = `translateX(${translateXRight + 50}px)`;

                setTimeout(()=>{
                    this._competenceBraketLeftElement.style.transform = `translateX(${translateXLeft}px)`;
                    this._competenceBraketRightElement.style.transform = `translateX(${translateXRight}px)`;
                    this._competenceContentElement.style.opacity = "1";

                    setTimeout(()=>{
                        this._startCompetenceAnimation();
                    },2000);
                },300);
            },250);
        },300);
    }
}