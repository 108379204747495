/**
 * TextureLibrary.ts: texture management
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { RepeatWrapping, LinearMipMapLinearFilter, LinearFilter,
    LinearEncoding, UVMapping, EquirectangularReflectionMapping } from "../../lib/threejs/constants";
import { Texture as THREETexture } from "../../lib/threejs/textures/Texture";
import { CanvasTexture } from "../../lib/threejs/textures/CanvasTexture";
import { TextureCache, ETextureCacheState, ETextureCacheLoad, whiteImage } from "../render/Texture";
import { build } from "../core/Build";
import { AssetManager } from "../framework/AssetManager";
import { Render } from "../render/Render";
import { AssetProcessing } from "../io/AssetProcessor";
import { AsyncLoad } from "../io/AsyncLoad";
import { TextureDB, AssetInfo } from "../io/AssetInfo";
import { registerLoadResolver, registerFileSizeResolver } from "../io/Interfaces";
import { ITextureLibrary, TEXTURELIBRARY_API, TextureImportDB, applyImportSettingsTexture, getImportSettingsTexture } from "../framework/TextureAPI";
import { registerAPI } from "../plugin/Plugin";

/** cache */
const TexturesCache:{[ref:string]: TextureCache;} = {};

/** current emitting state */
let _isEmittingState:number = 0;

/** resolve texture loading for fallback textures */
let _resolveForFallback:boolean = true;

/** preload fallback textures */
let _preloadFallbackTexture:boolean = true;

registerLoadResolver("image", preloadTexture);
registerFileSizeResolver("image", fileSize);

/**
 * flush memory on the gpu,
 * does not destroy memory on client side
 */
function flushGPUMemory() {

    for(const i in TexturesCache) {
        if(TexturesCache[i].texture) {
            TexturesCache[i].texture.dispose();
        }
    }
}

/**
 * flush all caches
 * should result in reloading all models
 */
function flushCaches() {
    flushGPUMemory();

    for(const item in TexturesCache) {
        delete TexturesCache[item];
    }
}

/**
 * resolve texture loading for fallback textures
 * @param value
 */
function setResolveForFallbackTextures(value:boolean) {
    _resolveForFallback = value;
}

/**
 * set preload fallback texture
 * @param value preload fallback texture against original
 */
function setPreloadFallbackTextures(value:boolean) {
    _preloadFallbackTexture = value;
}

/**
 * create a texture object from image object
 * not using promises for better performance (this will be called often)
 * @param textureName aka texture name
 * @param imageName aka image filename
 * @param anisotropy texture ansitropy level
 */
function createTexture(textureName:string, imageName?:string, anisotropy?:number) : AsyncLoad<any> {

    // default to texture name
    imageName = imageName || textureName;

    if(!textureName || !imageName) {
        console.warn("ASSET: called with invalid image name " + textureName);
        return AsyncLoad.reject(new Error("ASSET: called with invalid image name " + textureName));
    }

    return new AsyncLoad<any>((resolve, reject) => {

        if(TexturesCache[textureName] === undefined) {

            // NEW ENTRY
            const cache = _createTextureCache(textureName, imageName, anisotropy);

            // TODO: fixme: only when imageName is set?!
            // FIXME: check for resolving fallback texture?
            if(cache.state === ETextureCacheState.FULLY_LOADED) {
                // image got loaded directly
                resolve(cache.texture);
            } else if(cache.state === ETextureCacheState.ERROR) {
                // image has error directly
                reject(new Error("failed to create texture '" + textureName + "' from image: " + imageName));
            } else {
                // add to waiting list
                TexturesCache[textureName].resolver.push({ resolve: resolve, reject: reject });
            }

        } else {

            // start load or resolve directly
            _emitLoadingRequest(textureName, resolve, reject);
        }
    });
}

/** directly added three.js texture instance */
function addTexture(textureName:string, tex:any) {
    if(!textureName) {
        console.warn("AssetManager: invalid name for content ", textureName);
        return;
    }

    if(!tex) {
        console.warn("AssetManager: empty data for '"+textureName+"'");
        return;
    }

    if(TexturesCache[textureName] && build.Options.debugAssetOutput) {
        // overwriting cache entry
        console.info("AssetManager: overwriting cache entry '"+textureName+"'");
    }

    if(!TexturesCache[textureName]) {
        // generate simple loading cache entry
        TexturesCache[textureName] = {
            texture: null,
            state: ETextureCacheState.UNKNOWN,
            load: ETextureCacheLoad.UNKNOWN,
            resolver: []
        };
    }

    TexturesCache[textureName].texture = tex;
    TexturesCache[textureName].state = ETextureCacheState.FULLY_LOADED;
    TexturesCache[textureName].load = ETextureCacheLoad.FULLY;

    _emitLoadingState();
}

/**
 * create a texture object from image object
 * not using promises for better performance (this will be called often)
 * @param textureName aka texture name
 * @param anisotropy texture ansitropy level
 * @return THREE.Texture
 */
function getTexture(textureName:string, anisotropy?:number) : THREETexture {

    // check texture name
    if(!textureName) {
        console.warn("ASSET: called with invalid image name " + textureName);
        return null;
    }

    if(TexturesCache[textureName] === undefined) {
        // NEW ENTRY
        _createTextureCache(textureName, textureName, anisotropy);
    }

    // not already loaded -> check fallback texture
    if(TexturesCache[textureName].state !== ETextureCacheState.FULLY_LOADED) {
        const fallbackTexture = getFallbackTexture(textureName);
        return fallbackTexture || TexturesCache[textureName].texture;
    } else if(TexturesCache[textureName].load !== ETextureCacheLoad.FULLY) {
        // start loading of original
        createTexture(textureName, textureName, anisotropy);
    }

    // don't check any state, just return
    return TexturesCache[textureName].texture;
}

/**
 * handles texture preloading
 *  - could preload only fallback texture
 * @param textureName textureName (= image name)
 */
function preloadTexture(textureName:string) : AsyncLoad<THREETexture> {
    return new AsyncLoad<THREETexture>((resolve, reject) => {
        // not preloaded
        if(TexturesCache[textureName] === undefined) {
            let cache:TextureCache = null;

            // check for fallback texture preloading
            if(_preloadFallbackTexture) {
                const fallbackTextureName = getFallbackTextureName(textureName);

                // create texture entry with fallback image name
                if(fallbackTextureName) {
                    cache = _createTextureCache(textureName, fallbackTextureName, undefined, true);
                }
            }

            // NEW ENTRY
            if(!cache) {
                cache = _createTextureCache(textureName, textureName, undefined);
            }

            // TODO: fixme: only when imageName is set?!
            if(cache.state === ETextureCacheState.FULLY_LOADED || cache.state === ETextureCacheState.FALLBACK_LOADED) {
                // image got loaded directly
                resolve(cache.texture);
            } else if(cache.state === ETextureCacheState.ERROR) {
                // image has error directly
                reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
            } else {
                // add to waiting list
                TexturesCache[textureName].resolver.push({ resolve: resolve, reject: reject });
            }

        } else {
            // multiple preloading requests
            const cache = TexturesCache[textureName];

            // check for fallback texture and return this texture
            if(_preloadFallbackTexture) {
                if(cache.state === ETextureCacheState.FULLY_LOADED || cache.state === ETextureCacheState.FALLBACK_LOADED) {
                    // image got loaded directly
                    resolve(cache.texture);
                } else if(cache.state === ETextureCacheState.ERROR) {
                    // image has error directly
                    reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
                } else {
                    // add to waiting list
                    TexturesCache[textureName].resolver.push({ resolve: resolve, reject: reject });
                }
                return;
            }

            // start load or resolve directly
            _emitLoadingRequest(textureName, resolve, reject);
        }
    });
}

function fileSize(asset:AssetInfo, assets:{[key:string]:AssetInfo}) : number {

    const db = asset.runtimeImports as TextureDB;
    // TODO: check if fallback preloading is on
    if(asset.preload && _preloadFallbackTexture && db.fallbackTexture && assets[db.fallbackTexture]) {
        return assets[db.fallbackTexture].size;
    }

    return asset.size;
}

/** preprocessing texture data */
function _processTexture(texture:any, importName:string) : any {
    // run through processors
    texture = AssetProcessing.get().processTexture(texture, TextureImportDB[importName]);
    return texture;
}

/**
 * emit all loading states to other
 */
function _emitLoadingState() {

    _isEmittingState += 1;

    // already emitting
    if(_isEmittingState > 1) {
        return;
    }

    function finishCache(cache:{[ref: string]: TextureCache}) {
        for(const element in cache) {
            const obj = cache[element];

            if(obj.state === ETextureCacheState.FALLBACK_LOADED) {
                if(_resolveForFallback) {
                    // call waiting list
                    if(obj.resolver) {
                        // call waiting list
                        for(let i = 0; i < obj.resolver.length; ++i) {
                            if(obj.texture) {
                                obj.resolver[i].resolve(obj.texture);
                            } else {
                                //FIXME: reject?
                                obj.resolver[i].resolve(null);
                            }
                        }

                        obj.resolver.length = 0;
                    }
                }
            } else if(obj.state === ETextureCacheState.FULLY_LOADED) {
                // call waiting list
                if(obj.resolver) {
                    // call waiting list
                    for(let i = 0; i < obj.resolver.length; ++i) {
                        if(obj.texture) {
                            obj.resolver[i].resolve(obj.texture);
                        } else {
                            //FIXME: reject?
                            obj.resolver[i].resolve(null);
                        }
                    }

                    obj.resolver.length = 0;
                }
            } else if(obj.state === ETextureCacheState.ERROR) {
                // call waiting list
                for(let i = 0; i < obj.resolver.length; ++i) {
                    if(obj.resolver[i].reject) {
                        obj.resolver[i].reject(new Error("file not found"));
                    }
                }

                obj.resolver.length = 0;
            }
        }
    }

    // resolve all elements in right order
    while(_isEmittingState > 0) {
        finishCache(TexturesCache);
        _isEmittingState -= 1;
    }
}

/**
 * register loading request (only original teture)
 * @param textureName cache name
 * @param resolve resolve callback
 * @param reject reject callback
 */
function _emitLoadingRequest(textureName:string, resolve, reject) {
    const cache:TextureCache = TexturesCache[textureName];

    if(cache.state === ETextureCacheState.UNKNOWN) {
        // currently loading

        // loading original
        if(cache.load === ETextureCacheLoad.FULLY) {

            // add to waiting list
            cache.resolver.push({ resolve: resolve, reject: reject });

        } else if(cache.load === ETextureCacheLoad.FALLBACK) {
            // start loading of original file
            _loadImage(textureName, textureName, false);

            // resolve for fallback or when fully loaded
            if(_resolveForFallback || TexturesCache[textureName].state === ETextureCacheState.FULLY_LOADED) {
                // image got loaded directly
                resolve(cache.texture);
            } else if(TexturesCache[textureName].state === ETextureCacheState.ERROR) {
                // image has error directly
                reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
            } else {
                // add to waiting list
                cache.resolver.push({ resolve: resolve, reject: reject });
            }
        } else {
            //ERROR
        }
    } else if(cache.state === ETextureCacheState.FALLBACK_LOADED) {
        // start loading of original file
        _loadImage(textureName, textureName, false);

        // resolve for fallback or when fully loaded
        if(_resolveForFallback || TexturesCache[textureName].state === ETextureCacheState.FULLY_LOADED) {
            // image got loaded directly
            resolve(cache.texture);
        } else if(TexturesCache[textureName].state === ETextureCacheState.ERROR) {
            // image has error directly
            reject(new Error("failed to create texture '" + textureName + "' from image: " + textureName));
        } else {
            // add to waiting list
            cache.resolver.push({ resolve: resolve, reject: reject });
        }
    } else if(cache.state === ETextureCacheState.FULLY_LOADED) {
        // already loaded?
        resolve(cache.texture);
    } else {
        // failed to load
        console.warn("AssetManager: request texture that failed to load '" + textureName + "'.");
        reject(new Error("failed"));
    }
}

/**
 * load image for texture
 * @param textureName texture name
 * @param imageName image filename
 * @param fallback is fallback image
 */
function _loadImage(textureName:string, imageName:string, fallback?:boolean) {

    // update load state
    TexturesCache[textureName].load = fallback ? ETextureCacheLoad.FALLBACK : ETextureCacheLoad.FULLY;

    // start async image loading
    return AssetManager.loadImage(imageName).then( (img) => {
        let tex = TexturesCache[textureName].texture;

        // loading fallback (but original already loaded)
        if(fallback && TexturesCache[textureName].state === ETextureCacheState.FULLY_LOADED) {
            return;
        }

        // assign image
        tex.image = img;
        TexturesCache[textureName].texture = tex;

        // does not apply needsUpdate
        applyImportSettingsTexture(tex, _resolveTextureName(textureName));

        // first process texture
        tex = _processTexture(tex, textureName);

        // got updated
        tex.needsUpdate = true;

        // when not fallback, this is fully loaded
        // else fallback textures are treated like "default" texture
        if(!fallback) {
            TexturesCache[textureName].state = ETextureCacheState.FULLY_LOADED;
        } else {
            TexturesCache[textureName].state = ETextureCacheState.FALLBACK_LOADED;
        }

        _emitLoadingState();
    },
    (err) => {
        console.warn("AssetManager: failed to load image " + textureName);

        // set this to error
        TexturesCache[textureName].state = ETextureCacheState.ERROR;
    });
}

function _createTextureCache(textureName:string, imageName:string, anisotropy?:number, fallback?:boolean) {
    // NEW ENTRY
    if(build.Options.debugAssetOutput) {
        console.info("ASSET: generate texture object for " + textureName);
    }

    TexturesCache[textureName] = {
        state: ETextureCacheState.UNKNOWN,
        load: ETextureCacheLoad.UNKNOWN,
        resolver: [],
        texture: null
    };

    // check for fallback texture
    const fallbackTexture = getFallbackTexture(textureName);
    const tex = new THREETexture();
    tex.name = textureName;
    TexturesCache[textureName].texture = tex;

    if(fallbackTexture) {
        // use fallback image (will be replaced when loaded)
        tex.image = fallbackTexture.image;

        // set loaded when resolving for fallback texture is active
        if(_resolveForFallback) {
            TexturesCache[textureName].state = ETextureCacheState.FALLBACK_LOADED;
        }

    } else {
        //FIXME: fill always with white texture?!
        tex.image = whiteImage();
    }

    // setup anistropy
    tex.anisotropy = anisotropy || Render.DefaultTexAnsitropy;

    // got updated
    tex.needsUpdate = true;

    // load image
    if(imageName) {
        _loadImage(textureName, imageName, fallback);
    }

    return TexturesCache[textureName];
}

function resolveForShader(value:string, defaultValue:THREETexture) : THREETexture {

    if(TexturesCache[value]) {
        defaultValue = (TexturesCache[value].state === ETextureCacheState.FULLY_LOADED || TexturesCache[value].state === ETextureCacheState.FALLBACK_LOADED) ? TexturesCache[value].texture : defaultValue;

        // start loading of full image
        if(TexturesCache[value].load !== ETextureCacheLoad.FULLY) {
            // slow path: start loading of texture data
            createTexture(value);
        }
    } else {
        // slow path: start loading of texture data
        createTexture(value);
    }

    return defaultValue;
}

/**
 *
 * @param textureName original name
 */
function getFallbackTexture(textureName:string) : THREETexture {
    const fallbackTexture = getImportSettingsTexture(_resolveTextureName(textureName)).fallbackTexture;
    if(fallbackTexture && TexturesCache[fallbackTexture]) {

        if(TexturesCache[fallbackTexture].state === ETextureCacheState.FULLY_LOADED) {
            return TexturesCache[fallbackTexture].texture;
        }
    }
    return null;
}

function getFallbackTextureName(textureName:string) : string {
    const fallbackTexture = getImportSettingsTexture(_resolveTextureName(textureName)).fallbackTexture;
    if(fallbackTexture) {
        return fallbackTexture;
    }
    return null;
}

function printTextureCache() {
    console.info("Texture Cache:");
    for(const entry in TexturesCache) {
        const cache = TexturesCache[entry];

        console.info(entry + ": State '" + ETextureCacheState[cache.state] + "'  Load '" + ETextureCacheLoad[cache.load] + "'");
    }
    console.info("-----------------");
}

function _resolveTextureName(name:string) {
    // texture names are often url names
    // and there can be special url ones like "#/assets/texture.jpg" (absolute url)
    if(name.charCodeAt(0) === 35) {
        return name.substring(1);
    }
    return name;
}

/** library interface */
export const TextureLibrary:ITextureLibrary = {
    addTexture,
    createTexture,
    flushCaches,
    flushGPUMemory,
    getTexture,
    preloadTexture,
    printTextureCache,
    setPreloadFallbackTextures,
    setResolveForFallbackTextures,
    resolveForShader,
    destroyTexture: _destroyTexture,
    createTextureCanvas: _createTextureCanvas
};
registerAPI(TEXTURELIBRARY_API, TextureLibrary);

/** default for blank texture */
TextureImportDB['blank.jpg'] = {

    /** texture wrappingMode */
    wrappingMode: RepeatWrapping,

    /** filtering mode */
    minFilter: LinearMipMapLinearFilter,
    magFilter: LinearFilter,

    /** mip mapping support */
    mipmaps: true,

    /** flip y coordinate */
    flipY: true,

    /** encoding */
    encoding: LinearEncoding,

    /** convert to cubemap */
    convertToCubemap: false,

    /** is a equirectangular texture */
    isEquirectangular: false,

    /** is rgbm encoded */
    isRGBMEncoded: false,

    /** fallback texture */
    fallbackTexture: ""
};

/** helper function */
function applyValue(value:any, defaultValue:any) {
    if(value !== undefined) {
        return value;
    } else {
        return defaultValue;
    }
}

function _createTextureCanvas(name:string, width:number, height:number) {
    const cached = TexturesCache[name];

    if(cached) {
        return cached.texture;
    }

    const canvas = document.createElement('canvas');
    canvas.id = name;
    canvas.width = width || 1;
    canvas.height = height || 1;

    const canvasTexture = new CanvasTexture(canvas);
    canvasTexture.name = name;

    TexturesCache[name] = TexturesCache[name] || {load: ETextureCacheLoad.UNKNOWN, resolver: [], state:ETextureCacheState.ERROR, texture: null};
    TexturesCache[name].load = ETextureCacheLoad.FULLY;
    TexturesCache[name].state = ETextureCacheState.FULLY_LOADED;
    TexturesCache[name].texture = canvasTexture;

    applyImportSettingsTexture(canvasTexture, name);

    return canvasTexture;
}

function _destroyTexture(name: string) {
    const cached = TexturesCache[name];

    if(cached) {

        if(cached.texture['isCanvasTexture']) {
            // free canvas or reuse it later
        }

        if(cached.texture) {
            cached.texture.dispose();
        }

        delete TexturesCache[name];
    }
}
