/**
 * ComponentUpdateSystem.ts: Entity Component code
 *
 * Copyright redPlant GmbH 2016-2018
 * @author Lutz Hören
 */
import { Render } from "../render/Render";
import { IWorld } from "../framework/WorldAPI";
import { ComponentId, componentIdGetIndex, createComponentId } from "../framework/Component";
import { registerAPI } from "../plugin/Plugin";
import { IComponentUpdateSystem, COMPONENTUPDATESYSTEM_API } from "../framework/UpdateAPI";

/**
 * Component Update System
 */
type ComponentThinkCallback = () => void;

interface UpdateCallback {
    id: ComponentId;
    needsThink: boolean;
    update: ComponentThinkCallback;
}

let _registeredCallbacks:UpdateCallback[] = [];
let _version:number = 1;

function destroy() {
    // clear all callbacks
    _registeredCallbacks = [];
    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function init(world:IWorld) {

}

/** needs think state */
function isActive(id:ComponentId) {
    if(_validId(id)) {
        const index = componentIdGetIndex(id);
        return _registeredCallbacks[index].needsThink;
    }
    return false;
}

function activate(id:ComponentId) {
    if(_validId(id)) {
        const index = componentIdGetIndex(id);
        _registeredCallbacks[index].needsThink = true;
    }
}

function deactivate(id:ComponentId) {
    if(_validId(id)) {
        const index = componentIdGetIndex(id);
        _registeredCallbacks[index].needsThink = false;
    }
}

function registerCallback(think:() => void) {
    let index = -1;

    for(let i = 0; i < _registeredCallbacks.length; ++i) {
        if(!_registeredCallbacks[i].id) {
            index = i;
            break;
        }
    }

    // new entry
    if(index === -1) {
        index = _registeredCallbacks.length;
        _registeredCallbacks[index] = {
            id: 0,
            needsThink: false,
            update: null
        };
    }

    _registeredCallbacks[index].id = createComponentId(index, _version);
    _registeredCallbacks[index].update = think;
    _registeredCallbacks[index].needsThink = false;

    return _registeredCallbacks[index].id;
}

function removeCallback(id:ComponentId) {
    if(!_validId(id)) {
        return;
    }

    const index = componentIdGetIndex(id);

    // cleanup
    _registeredCallbacks[index].id = 0;
    _registeredCallbacks[index].needsThink = false;
    _registeredCallbacks[index].update = null;

    // increase version
    _version = (_version + 1) & 0x000000FF;
}

function _think(deltaSeconds:number) {
    for(const cb of _registeredCallbacks) {
        if(cb.needsThink) {
            cb.update();
        }
    }
}

function _validId(id:ComponentId) {
    const index = componentIdGetIndex(id);
    if(index >= 0 && index < _registeredCallbacks.length) {
        return _registeredCallbacks[index].id === id;
    }
    return false;
}

const componentUpdateSystem:IComponentUpdateSystem = {
    init, destroy,
    registerCallback, removeCallback,
    activate, deactivate, isActive, think: _think
};
registerAPI<IComponentUpdateSystem>(COMPONENTUPDATESYSTEM_API, componentUpdateSystem);
