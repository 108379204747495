export default {
    name: "google_maps",
    title: "Google Maps",
    purposes: ["functional"],
    translations: {
        en: {
            description: `
                Google Maps displays maps on the website as an iframe or directly embedded as part of the website via JavaScript. No cookies in the technical sense are set on the user's client device, but technical and personal data such as the IP address are transferred from the client to the service provider's server to enable use of the service.
                <br><br>

                This service does not set technical cookies on the visitor's client, but embeds a script, for example.
                
                <br><br>
                Use on the legal basis of: Consent
                <br>
                Provider: Google Ireland Limited
                <br>
                US data processing: Yes
                <br>
                Provider privacy policy: https://policies.google.com/privacy
            `,
        },
        de: {
            description: `
                Google Maps zeigt Karten auf der Website als Iframe oder über JavaScript direkt eingebettet als Teil der Website an. Auf dem Client-Gerät des Nutzers werden keine Cookies im technischen Sinne gesetzt, aber es werden technische und persönliche Daten wie z.B. die IP-Adresse vom Client an den Server des Diensteanbieters übertragen, um die Nutzung des Dienstes zu ermöglichen.
                <br><br>

                Dieser Service setzt keine technischen Cookies auf dem Client des Besuchers, aber bindet z. B. ein Script ein.
                <br><br>

                <p class="purposes">
                    Verwendung auf gesetzlicher Grundlage von: Einwilligung
                </p>
                <p class="purposes">
                    Anbieter: Google Ireland Limited
                </p>
                <p class="purposes">
                    US-Datenverarbeitung: Ja
                </p>
                <p class="purposes">
                    Datenschutzerklärung des Anbieters: https://policies.google.com/privacy
                </p>
            `,
        },
    },
};